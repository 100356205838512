import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { TaskAssetComponent } from './task-asset.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    FileSizeModule
  ],
  declarations: [
    TaskAssetComponent
  ],
  exports: [
    TaskAssetComponent
  ]
})
export class TaskAssetModule { }
