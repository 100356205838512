import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DocSizeError } from '@common/html/constants/doc-size-error.constant';
import { RichTextSchema } from '@common/rich-text/rich-text-schema';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { RichTextEditorToolbarComponent } from '@portal-core/text-editor/components/rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { SoloFileTextEditorComponent } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.component';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { EditorToolbarDropdownMenuClosedEvent } from '@portal-core/ui/editor/types/editor-toolbar-dropdown-menu-closed-event.type';
import { keymap } from 'prosemirror-keymap';
import { Command, EditorState, Plugin } from 'prosemirror-state';
import { EditorView, NodeViewConstructor } from 'prosemirror-view';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mc-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorComponent implements OnInit, AfterViewInit {
  @Input() language?: string;
  @Input() content: string;

  @Output() editorStateChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();

  @ViewChild('editorContainer', { static: true }) editorContainerElementRef: ElementRef<HTMLElement>;
  @ViewChild('editorContainer', { static: true, read: CdkScrollable }) editorScrollable: CdkScrollable;
  @ViewChild(RichTextEditorToolbarComponent, { static: true }) richTextEditorToolbar: RichTextEditorToolbarComponent;
  @ViewChild(SoloFileTextEditorComponent, { static: true }) soloFileTextEditor: SoloFileTextEditorComponent;

  @HostBinding('class.mc-border-toolbar') borderToolbarClass: boolean = true;

  get editorState(): EditorState {
    return this.soloFileTextEditor ? this.soloFileTextEditor.editorState : null;
  }

  get editorView(): EditorView {
    return this.soloFileTextEditor ? this.soloFileTextEditor.editorView : null;
  }

  get viewPluginOverlay(): HTMLElement {
    return this.soloFileTextEditor?.viewPluginOverlay ?? null;
  }

  constructor(private fileSizeService: FileSizeService, private snackBar: MatSnackBar, private textEditorService: TextEditorService) { }

  schema: RichTextSchema = new RichTextSchema();
  /**
  * Emits when the plugin overlay element is ready to use.
  * This is a BehaviorSubject because the editor may destroy and create its plugin during its lifecycle so this needs to re-emit each time a new plugin is created and subscribes.
  */
  private viewPluginOverlay$: BehaviorSubject<HTMLElement> = new BehaviorSubject<HTMLElement>(null);
  plugins: Plugin[];
  nodeViews: Dictionary<NodeViewConstructor> = this.textEditorService.createRichTextNodeViews();
  getEditorState: () => EditorState = () => this.editorState;

  ngOnInit() {
    this.plugins = this.textEditorService.createRichTextPlugins(this.schema, {
      pasteNormalizer: {
        onError: (error: Error) => {
          if (error.name === DocSizeError) {
            this.snackBar.open(`Changes ignored because they exceed ${this.fileSizeService.format(this.textEditorService.flareTextEditorDocMaxCharLength, 0)} size limit.`, 'OK');
          }
        }
      },
      view: {
        overlay$: this.viewPluginOverlay$,
        scrollable: this.editorScrollable
      }
    });

    this.plugins.push(
      keymap({
        'Mod-k': () => {
          this.richTextEditorToolbar.openRichTextLinkPropertiesDialog(this.editorState)
          return true;
        }
      })
    );

    this.nodeViews = this.textEditorService.createRichTextNodeViews()
  }

  ngAfterViewInit() {
    // The view plugin overlay is not available until after the view has been initialized so emit it here
    this.viewPluginOverlay$.next(this.viewPluginOverlay);
  }

  getContent(): string {
    return this.schema.nodeToCode(this.editorState.doc);
  }

  onDispatch(command: Command | Command[]) {
    // Return focus to the editor before running a command from the toolbar
    this.editorView.focus();
    if (Array.isArray(command)) {
      command.some(c => c(this.editorState, this.editorView.dispatch));
    } else {
      command(this.editorState, this.editorView.dispatch);
    }
  }

  onToolbarDropdownMenuClosed(event: EditorToolbarDropdownMenuClosedEvent<Command>) {
    // Only give focus to the editor if an item wasn't clicked and the menu wasn't closed because the user tabbed away
    if (!event.item && event.source !== 'tab') {
      // A toolbar dropdown was closed so give focus back to the editor
      this.editorView.focus();
    }
  }

  onEditorStateChanged(event: EditorChangeEvent) {
    this.richTextEditorToolbar?.updateState(event.newEditorState);
    this.editorStateChange.emit(event);
  }

  onEditorLoaded() {
    // A workaround to keep the editor from scrolling down slightly when the initial content is a heading, etc.
    // This issue appears to go away in a newer version of prosemirror-view at which point this workaround can be removed.
    if (this.editorContainerElementRef?.nativeElement) {
      this.editorContainerElementRef.nativeElement.scrollTop = 0;
    }
  }

  onEditorReady(event: EditorChangeEvent) {
    this.richTextEditorToolbar?.updateState(event.newEditorState);
  }
}
