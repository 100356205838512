<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close" [mat-dialog-close]="true"></mat-icon>
</button>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>

<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="taskBoard$ | async as taskBoard">
      <div class="mc-spring-board-header">
        <mc-task-board-avatar [disabled]="true" [taskBoard]="taskBoard"></mc-task-board-avatar>
      </div>

      <div class="mc-spring-board-body">
        <ul class="mc-property-list">
          <li>
            <span class="mc-property-name">Active Tasks: </span>
            <span class="mc-property-value">{{ taskBoard?.AllActiveTasksCount}}</span>
          </li>
          <li>
            <span class="mc-property-name">Inactive Tasks: </span>
            <span class="mc-property-value">{{taskBoard?.AllInActiveTasksCount}}</span>
          </li>
        </ul>
      </div>

      <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
        <a href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': taskBoardProfileTab === TaskBoardProfileTab.Settings }" (click)="taskBoardProfileTab = TaskBoardProfileTab.Settings">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-gear" color="primary"></mat-icon> Settings
        </a>
        <a href="#" mat-list-item mcPreventDefault="click" [ngClass]="{ 'mc-list-item-selected': taskBoardProfileTab === TaskBoardProfileTab.Delete }" (click)="taskBoardProfileTab = TaskBoardProfileTab.Delete">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-trash" color="primary"></mat-icon> Delete
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item matDialogClose [routerLink]="['/', taskBoard.LicenseId, 'tasks', 'boards', taskBoard.Id, 'board']">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-kanban" color="primary"></mat-icon> Board
        </a>
        <a mat-list-item matDialogClose [routerLink]="['/', taskBoard.LicenseId, 'tasks', 'boards', taskBoard.Id, 'grid']">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-grid" color="primary"></mat-icon> Grid
        </a>
        <a mat-list-item matDialogClose [routerLink]="['/', taskBoard.LicenseId, 'tasks', 'boards', taskBoard.Id, 'calendar']">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-calendar" color="primary"></mat-icon> Calendar
        </a>
        <a mat-list-item matDialogClose [routerLink]="['/', taskBoard.LicenseId, 'tasks', 'boards', taskBoard.Id, 'backlog']">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-openbuildlogcommand" color="primary"></mat-icon> Backlog
        </a>
        <a mat-list-item matDialogClose [routerLink]="['/', taskBoard.LicenseId, 'tasks', 'boards', taskBoard.Id, 'archive']">
          <mat-icon matListIcon fontSet="project-titan" fontIcon="icon-archive" color="primary"></mat-icon> Archive
        </a>
      </mat-nav-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="taskBoardProfileTab" class="mc-spring-board">
    <mat-tab-group *ngSwitchCase="TaskBoardProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-task-board-settings-form [taskBoard]="taskBoard$ | async" [userId]="userId" (cancel)="onCancel()"></mc-task-board-settings-form>
      </mat-tab>
    </mat-tab-group>
    <mat-tab-group *ngSwitchCase="TaskBoardProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete">
        <mc-task-board-delete-form [taskBoard]="taskBoard$ | async" (cancel)="onCancel()" (delete)="onDelete()"></mc-task-board-delete-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>
