import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamAsyncPipe } from './team-async.pipe';

@NgModule({
  declarations: [TeamAsyncPipe],
  imports: [
    CommonModule
  ],
  exports: [TeamAsyncPipe],
  providers: [TeamAsyncPipe]
})
export class TeamAsyncModule {}
