import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteItemModule } from '@portal-core/ui/autocomplete/directives/autocomplete-item/autocomplete-item.module';
import { ClickModule } from '@portal-core/ui/clicking/directives/click/click.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { AutocompleteInputComponent } from './autocomplete-input.component';

@NgModule({
  declarations: [
    AutocompleteInputComponent
  ],
  imports: [
    AutocompleteItemModule,
    ClickModule,
    CommonModule,
    PopupModule,
    ReactiveFormsModule
  ],
  exports: [
    AutocompleteInputComponent,
    AutocompleteItemModule
  ]
})
export class AutocompleteInputModule { }
