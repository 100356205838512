import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { join } from '@common/util/path';
import { environment } from '@env/environment';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { ScriptService } from '@portal-core/general/services/script.service';
import { IdleLogoutDialogComponent } from '@portal-core/idle-logout/components/idle-logout-dialog/idle-logout-dialog.component';
import { IdleLogoutService } from '@portal-core/idle-logout/services/idle-logout.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { WINDOW } from '@portal-core/util/window.provider';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe()
export class AppRootComponent implements OnInit, OnDestroy {
  production: boolean = environment.production;
  idleDialogIsVisible: boolean = false;
  idleTimerSubscription: Subscription;
  queryParamSubscription: Subscription;

  constructor(
    @Inject(WINDOW) private window: Window,
    private scriptService: ScriptService,
    private idleLogoutService: IdleLogoutService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog,
    private authUrlService: AuthUrlService
  ) { }

  ngOnInit() {
    // Third party auth redirect urls usually cannot include a hashtag (#). Because our Angular routing system uses the hash we can't handle these auth redirects with our router.
    // So check here if the URL's subdomain is 'confirm' which is our reserved subdomain and standard location for third party auth redirect urls.
    // TODO: Once we switch away from using the hashtag routing strategy we can remove the code that inserts the hashtag (#) into the url. We will still need to redirect to the correct subdomain though.
    // e.g. https://confirm.qa.madcaprnd.com/client/dog/1234/confirm/slack?code=[...]&state=[...] -> https://dog.qa.madcaprnd.com/client/#/1234/confirm/slack?code=[...]&state=[...]

    // when running locally, nginx isn't redirecting /confirm-slack/ to the 'confirm' subdomain, we have to do that manually here:
    if (!environment.externalWebServerConfirmRedirect) {
      const currentUrl = new URL(this.window.location.href);
      const confirmString = '/confirm-slack/';
      if (currentUrl.pathname.startsWith(confirmString)) {
        const confUrl = new URL(`${join([this.authUrlService.buildSubdomainUrl(environment.centralConfirmSubdomain), currentUrl.pathname.substring(confirmString.length - 1)])}${currentUrl.search}${currentUrl.hash}`);
        this.window.location.href = confUrl.href;
        return;
      }
    }

    // If this request is on the confirm subdomain
    if (this.authUrlService.getSubdomain() === environment.centralConfirmSubdomain) {
      // Grab the current URL
      const currentUrl = new URL(this.window.location.href);
      // Build the portal's base url with the confirm subdomain
      const portalBaseUrlWithSubdomain = this.authUrlService.buildSubdomainUrl(environment.centralConfirmSubdomain);
      // Strip the querystring and hash from the url because we are only interested in the domain and path atm
      const currentUrlWithoutQuerystringAndHash = new URL(currentUrl.href);
      currentUrlWithoutQuerystringAndHash.hash = '';
      currentUrlWithoutQuerystringAndHash.search = '';
      // Strip the portal's base url from the current url. This is necessary because the base url may contain a path. The base path needs to be removed to grab the subdomain and build the redirect path
      const pathWithoutBase = currentUrlWithoutQuerystringAndHash.href.replace(new RegExp('^' + portalBaseUrlWithSubdomain), '');
      // Grab the redirect subdomain and the path
      const [subdomain, ...newPaths] = pathWithoutBase.replace(/^\//, '').split('/'); // Remove a leading slash if its there so that its not split on
      // Build the redirect url using the subdomain and by inserting the hashtag (#) for the angular routing system
      const redirectUrl = new URL(`${join([this.authUrlService.buildSubdomainUrl(subdomain), '#', ...newPaths])}${currentUrl.search}${currentUrl.hash}`);

      // Redirect the user
      this.window.location.href = redirectUrl.href;
      return;
    }

    this.analyticsService.init(environment.googleAnalyticsMeasurementId);

    this.scriptService.inject(environment.authorizeSandbox ? 'https://jstest.authorize.net/v1/Accept.js' : 'https://js.authorize.net/v1/Accept.js');
    this.scriptService.inject(`https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsMeasurementId}`, true);

    this.idleLogoutService.initIdleTimer();
    this.listenToIdleTimer();
  }

  listenToIdleTimer() {
    this.idleTimerSubscription = this.idleLogoutService.idleTimer$.subscribe(() => {
      if (!this.idleDialogIsVisible) {
        this.idleDialogIsVisible = true;
        this.dialog.open(IdleLogoutDialogComponent, {
          ...IdleLogoutDialogComponent.DialogConfig
        }).afterClosed().subscribe(() => this.idleDialogIsVisible = false);
      }
    });
  }

  ngOnDestroy() {
    this.analyticsService.destroy();
  }
}
