import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '@portal-core/general/services/image.service';

@Pipe({
  name: 'mcMadCapImageUrl'
})
export class MadCapImageUrlPipe implements PipeTransform {
  constructor(private imageService: ImageService) { }

  transform(storageId: string, extension: string): string {
    if (storageId && extension) {
      return this.imageService.getMadCapImageUrl(storageId, extension);
    }
  }
}
