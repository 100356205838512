import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';

@Pipe({
  name: 'mcReviewFileStatusText'
})
export class ReviewFileStatusTextPipe implements PipeTransform {
  transform(status: ReviewFileStatus | null | undefined): string {
    switch (status) {
      case ReviewFileStatus.Open:
        return 'Open';
      case ReviewFileStatus.Closed:
        return 'Closed';
      case ReviewFileStatus.Accepted:
        return 'Accepted';
    }
  }
}
