export enum AuthRoute {
  ExpiredPassword = 'expired-password',
  ForgotPassword = 'forgot-password',
  Home = 'home',
  LastActivatedRoute = 'last-activated-route',
  LicenseList = 'license-list',
  LicenseUrl = 'license-url',
  Login = 'login',
  Logout = 'logout',
  AuthLoginGuardError = 'auth-login-guard-error',
  InstanceCodeGuardError = 'instance-code-guard-error',
  SubdomainGuardError = 'subdomain-guard-error'
}
