<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    <nav mat-tab-nav-bar class="mc-arrow-tabs">
      <a href="#" mat-tab-link [active]="fileMode === 'text'" (click)="onFlareEditModeClicked($event, 'text')" matTooltip="Please fix the errors listed to access the Content tab" [matTooltipDisabled]="projectFlareFileEditor?.valid">Edit Snippet Content</a>
      <a href="#" mat-tab-link [active]="fileMode === 'code'" (click)="onFlareEditModeClicked($event, 'code')" matTooltip="Please fix the errors listed to access the Code tab" [matTooltipDisabled]="projectFlareFileEditor?.textValid">Edit Code</a>
    </nav>

    <ng-container *ngIf="filePath">
      <mat-divider [vertical]="true"></mat-divider>
      <span class="mc-toolbar-spacer-tab"></span>
      <mc-self-truncating-path [filePath]="filePath"></mc-self-truncating-path>
      <span class="mc-toolbar-spacer-divider"></span>
    </ng-container>

    <span class="mc-fill-remaining-flex-space"></span>

    <mc-maximize-dialog-button [dialogRef]="dialogRef" maximized="true"></mc-maximize-dialog-button>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <ng-container *ngIf="projectFile$ | async as projectFile">
      <mc-project-flare-file class="mc-project-files-edit-snippet-dialog-editor" #projectFlareFileEditor [trackedChanges]="trackedChanges" [showConditions]="showConditions" [branchName]="branch" [licenseUser]="licenseUser" [mode]="fileMode" [projectFile]="projectFile" [projectId]="projectId" [projectLanguage]="projectLanguage$ | async" [readonly]="readonly" (modeChange)="onFlareFileModeChanged($event)" [wrapLines]="wrapLines" (change)="onContentChange($event)" (navToFile)="onNavToFile($event)">
      </mc-project-flare-file>
    </ng-container>

    <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

    <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
      <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async" (retry)="onRetryLoadFile()"></mc-errors-expansion-panel>
    </div>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button [disabled]="!snippetIsDirty || !projectFlareFileEditor?.textValid" (click)="onCommitClicked()" color="primary" [matTooltip]="!snippetIsDirty ? 'Please make changes to file before committing' : 'Please fix the errors listed before committing'" [matTooltipDisabled]="snippetIsDirty && projectFlareFileEditor?.textValid">Commit</button>
  </div>
</div>