import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PluralItemsService {
  itemMaps = {
    'item': {
      '=0': 'no items',
      '=1': 'item',
      'other': '# items'
    },
    'item-numeric': {
      '=0': '0 items',
      '=1': '1 item',
      'other': '# items'
    },
    'project': {
      '=0': 'no projects',
      '=1': 'project',
      'other': '# projects'
    },
    'project-numeric': {
      '=0': '0 projects',
      '=1': '1 project',
      'other': '# projects'
    },
    'file-numeric': {
      '=0': '0 files',
      '=1': '1 file',
      'other': '# files'
    },
    'license-user': {
      '=0': 'no users',
      '=1': 'user',
      'other': '# users'
    },
    'license-user-numeric': {
      '=0': '0 users',
      '=1': '1 user',
      'other': '# users'
    },
    'team': {
      '=0': 'no teams',
      '=1': 'team',
      'other': '# teams'
    },
    'team-numeric': {
      '=0': '0 teams',
      '=1': '1 team',
      'other': '# teams'
    },
    'checklist': {
      '=0': 'no checklists',
      '=1': 'checklist',
      'other': '# checklists'
    },
    'task-numeric': {
      '=0': '0 tasks',
      '=1': '1 task',
      'other': '# tasks'
    },
    'task-board-numeric': {
      '=0': '0 task boards',
      '=1': '1 task board',
      'other': '# task boards'
    },
    'checklist-template': {
      '=0': 'no templates',
      '=1': 'template',
      'other': '# templates'
    },
    'day': {
      '=0': 'days',
      '=1': '1 day',
      'other': '# days'
    },
    'line': {
      '=0': 'no lines',
      '=1': '1 line',
      'other': '# lines'
    },
    'key-numeric': {
      '=0': '0 keys',
      '=1': '1 key',
      'other': '# keys'
    },
    'review-file': {
      '=0': 'no review files',
      '=1': 'review file',
      'other': '# review files'
    },
    'review-file-numeric': {
      '=0': '0 review files',
      '=1': '1 review file',
      'other': '# review files'
    },
    'review-package': {
      '=0': 'no review packages',
      '=1': 'review package',
      'other': '# review packages'
    },
    'review-package-numeric': {
      '=0': '0 review packages',
      '=1': '1 review package',
      'other': '# review packages'
    },
    'reviewer': {
      '=0': 'no reviewers',
      '=1': 'reviewer',
      'other': '# reviewers'
    },
    'reviewer-numeric': {
      '=0': '0 reviewers',
      '=1': '1 reviewer',
      'other': '# reviewers'
    },
    'user': {
      '=0': 'no users',
      '=1': 'user',
      'other': '# users'
    },
    'branch-numeric': {
      '=0': '0 branches',
      '=1': '1 branch',
      'other': '# branches'
    },
    'package-numeric': {
      '=0': '0 packages',
      '=1': '1 package',
      'other': '# packages'
    },
    'target-numeric': {
      '=0': '0 targets',
      '=1': '1 target',
      'other': '# targets'
    },
    'target-whole-numeric': {
      '=0': 'Whole Project',
      '=1': '1 target',
      'other': '# targets'
    },
    'variable-numeric': {
      '=0': '0 variables',
      '=1': '1 variable',
      'other': '# variables'
    },
    'language-numeric': {
      '=0': '0 languages',
      '=1': '1 language',
      'other': '# languages'
    }

  };

  constructor() { }

  getMapItem(itemType: string): { [count: string]: string; } {
    const itemMap = this.itemMaps[itemType];
    return itemMap || this.itemMaps['item'];
  }
}
