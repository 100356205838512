import { Subscription } from 'rxjs';

interface AutoUnsubscribeOptions {
  event?: string;
  excludes?: string[];
}

/**
 * Automatically calls unsubscribe on all Subscription properties on a component when the component is destroyed.
 */
export function AutoUnsubscribe({ excludes = [], event = 'ngOnDestroy' }: AutoUnsubscribeOptions = {}) {
  return function (constructor: Function) {
    const original: Function = constructor.prototype[event];

    constructor.prototype[event] = function (...args: any[]) {
      for (const propName in this) {
        if (excludes.includes(propName)) {
          continue;
        }

        const property = this[propName];
        if (property instanceof Subscription) {
          property.unsubscribe();
        }
      }

      if (typeof original === 'function') {
        original.apply(this, args);
      }
    };
  };
}
