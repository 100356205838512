import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { AccessTreeNode } from '@portal-core/general/models/access-tree-node.model';
import { AccessTreeService } from '@portal-core/general/services/access-tree.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { UsersService } from '@portal-core/users/services/users.service';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, combineLatest, first, map, tap } from 'rxjs';

enum UserAccessTreeNodeType {
  TeamsNode = 'TeamsNode',
  ProjectsNode = 'ProjectsNode',
  TeamNode = 'TeamNode',
  ProjectNode = 'ProjectNode'
}

@Component({
  selector: 'mc-user-access-form',
  templateUrl: './user-access-form.component.html',
  styleUrls: ['./user-access-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AccessTreeService]
})
export class UserAccessFormComponent implements OnInit, OnChanges {
  @Input() licenseUser: LicenseUser;
  @Input() userProjects: Project[];
  @Input() userTeams: Team[];
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  @InputObservable('licenseUser') licenseUser$: Observable<LicenseUser>;

  get dirty(): boolean {
    // Using this.editing isn't entirely accurate but is close enough
    return this.editing;
  }

  LicenseUserSeatType: typeof LicenseUserSeatType = LicenseUserSeatType;
  UserAccessTreeNodeType: typeof UserAccessTreeNodeType = UserAccessTreeNodeType;

  canAssignToProjects$: Observable<boolean>;
  canEdit$: Observable<boolean>;
  dataSource: MatTreeNestedDataSource<AccessTreeNode> = new MatTreeNestedDataSource<AccessTreeNode>();
  editing: boolean = false;
  licenseProjects: Project[];
  licenseTeams: Team[];
  loadingState: LoadingState<string> = new LoadingState<string>();
  nestedTreeControl: NestedTreeControl<AccessTreeNode>;
  savingState: LoadingState<string> = new LoadingState<string>();


  hasNestedChild = (index: number, node: AccessTreeNode): boolean => {
    return node.type === UserAccessTreeNodeType.TeamsNode || node.type === UserAccessTreeNodeType.ProjectsNode ||
      node.type === UserAccessTreeNodeType.TeamNode;
  };

  getChildren = (node: AccessTreeNode): AccessTreeNode[] => {
    if (node.type === UserAccessTreeNodeType.TeamsNode || node.type === UserAccessTreeNodeType.ProjectsNode || node.type === UserAccessTreeNodeType.TeamNode) {
      return node.children;
    }
  };

  constructor(
    private teamsService: TeamsService,
    private projectsService: ProjectsService,
    private permissionsService: PermissionsService,
    private usersService: UsersService,
    private errorService: ErrorService,
    private accessTreeService: AccessTreeService,
    private pageFilterService: PageFilterService
  ) {
    this.dataSource.data = [];
  }

  ngOnInit() {
    // Create an observable on whether editing is allowed
    this.canEdit$ = this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageTeamsProjects);

    // Create an observable on whether this user can be assigned to projects
    this.canAssignToProjects$ = this.licenseUser$.pipe(
      map(licenseUser => licenseUser.SeatType === LicenseUserSeatType.Author)
    );

    this.nestedTreeControl = new NestedTreeControl<AccessTreeNode>(this.getChildren);
    this.loadCurrentTree();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.userProjects && this.userProjects) || (changes.userTeams && this.userTeams) && !this.editing) {
      this.loadCurrentTree();
    }
  }

  onEditClicked() {
    this.editing = true;
    this.loadLicenseTree();
  }

  onCancelClicked() {
    this.editing = false;
    this.loadCurrentTree();

    // Remove any save errors now that we are leaving the editing mode
    this.savingState.update(false);
  }

  onLeafNodeClicked(node: AccessTreeNode) {
    node.selected = !node.selected;
    this.updateCheckbox(node.parent);
  }

  onSubmit() {
    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      const selectedTeamIds = this.dataSource.data[0].children.filter(node => node.selected)
        .map(node => node.value.teamId);

      const selectedProjectIds = this.dataSource.data[1].children.filter(node => node.selected)
        .map(node => node.value.projectId);

      this.usersService.updateUserProjectsAndTeams$(this.licenseUser.User.Id, this.licenseUser.LicenseId, selectedProjectIds, selectedTeamIds).subscribe(() => {
        this.editing = false;
        this.loadCurrentTree();
        this.savingState.update(false);
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to assign the teams and projects to the user.', this.errorService.getErrorMessages(error));
      });
    }
  }

  selectAllChanged(node: AccessTreeNode) {
    this.accessTreeService.selectAll(node);
  }

  updateCheckbox(node: AccessTreeNode) {
    this.accessTreeService.updateSelectAll(node);
  }

  private loadCurrentTree() {
    this.dataSource.data = this.getCurrentAccessTreeData();
  }

  private loadLicenseTree() {
    this.loadingState.update(true);

    // Build a filter for the projects that includes projects that are active/locked or are directly associated with this user
    const projectsFilter: PageFilter = {
      ...this.pageFilterService.create({
        Id: 'license-projects',
        Type: PageFilterGroupType.Custom
      }).custom({
        Id: 'projects',
        Type: PageFilterGroupType.Custom,
        Operator: PageFilterOperator.Or,
        Filters: [{
          FilterType: PageFilterType.Equals,
          PropertyName: 'UserId',
          PropertyType: PageDataType.ProjectUsers,
          PropertyValue: this.licenseUser.User.Id
        }, {
          FilterType: PageFilterType.Equals,
          PropertyName: 'Status',
          PropertyType: PageDataType.Select,
          PropertyValue: ProjectStatus.Active
        }, {
          FilterType: PageFilterType.Equals,
          PropertyName: 'Status',
          PropertyType: PageDataType.Select,
          PropertyValue: ProjectStatus.Locked
        }]
      }).value,
      OrderBy: 'Name',
      OrderDirection: 'asc',
      PageNumber: 0,
      PerPage: -1
    };

    combineLatest([
      this.projectsService.getProjectsPageByLicenseId$(this.licenseUser.LicenseId, projectsFilter, 0).pipe(
        map(page => page.Items),
        tap(projects => this.projectsService.addItems$(projects))
      ),
      this.teamsService.getTeamsByLicenseId$(this.licenseUser.LicenseId, { forceApiRequest: true }).pipe(
        first(teams => !!teams)
      )
    ]).subscribe(([projects, teams]) => {
      this.licenseProjects = projects;
      this.licenseTeams = teams;

      this.dataSource.data = this.getLicenseAccessTreeData();
      this.accessTreeService.initializeTree(this.dataSource);
      this.accessTreeService.expandNodes(this.nestedTreeControl, this.dataSource.data);

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the teams and projects.', this.errorService.getErrorMessages(error));
    });
  }

  private getLicenseAccessTreeData(): AccessTreeNode[] {
    const teamsNode: AccessTreeNode = {
      name: 'Teams',
      type: UserAccessTreeNodeType.TeamsNode
    };

    const projectsNode: AccessTreeNode = {
      name: 'Projects',
      type: UserAccessTreeNodeType.ProjectsNode
    };

    teamsNode.children = this.licenseTeams.map(team => this.buildTeamNode(teamsNode, team.Id, this.userTeams));
    projectsNode.children = this.licenseProjects.map(project => this.buildProjectNode(projectsNode, project, this.userProjects));

    return [teamsNode, projectsNode];
  }

  private getCurrentAccessTreeData(): AccessTreeNode[] {
    const teamsNode: AccessTreeNode = {
      name: 'Teams',
      type: UserAccessTreeNodeType.TeamsNode
    };

    const projectsNode: AccessTreeNode = {
      name: 'Projects',
      type: UserAccessTreeNodeType.ProjectsNode
    };

    teamsNode.children = this.userTeams.map(team => this.buildTeamNode(teamsNode, team.Id));
    projectsNode.children = this.userProjects.map(project => this.buildProjectNode(projectsNode, project));

    return [teamsNode, projectsNode];
  }

  private buildProjectNode(parent: AccessTreeNode, project: Project, userProjects?: Project[]): AccessTreeNode {
    return {
      disabled: project.Status === ProjectStatus.Archived,
      parent,
      selected: userProjects?.some(userProject => userProject.Id === project.Id) ?? true,
      type: UserAccessTreeNodeType.ProjectNode,
      value: {
        projectId: project.Id
      }
    };
  }

  private buildTeamNode(parent: AccessTreeNode, teamId: number, userTeams?: Team[]): AccessTreeNode {
    return {
      parent,
      selected: userTeams?.some(team => team.Id === teamId) ?? true,
      type: UserAccessTreeNodeType.TeamNode,
      value: { teamId }
    };
  }
}
