<div class="mc-task-comment-group-container">
  <mc-divider class="text-heavy-weight mc-task-comment-group-divider">{{discussionHeader}}</mc-divider>

  <div class="mc-task-comment-group-body">
    <ng-container *ngFor="let comment of commentGroup.Comments">
      <ng-container *ngIf="showDelete || !comment.IsDeleted">
        <mc-task-comment #commentTemp [comment]="comment" [level]="taskCommentLevel.Parent" [licenseId]="licenseId"
          (delete)="onCommentDelete($event)" [canEditAllTasks]="canEditAllTasks"
          [checkIsDirty]="checkIsDirty" (isDirtyChanged)="onCommentDirtyChanged()"></mc-task-comment>
        <ng-container *ngFor="let childComment of comment.ChildComments">
          <mc-task-comment *ngIf="showDelete || !childComment.IsDeleted" [comment]="childComment"
            [level]="taskCommentLevel.Child" [licenseId]="licenseId" (delete)="onCommentDelete($event)"
            [canEditAllTasks]="canEditAllTasks" [checkIsDirty]="checkIsDirty" (isDirtyChanged)="onCommentDirtyChanged()"></mc-task-comment>
        </ng-container>
        <div #replyComment>
          <mc-comment-box #commentBox *ngIf="commentTemp.level === taskCommentLevel.Parent && commentTemp.showReply"
            [comment]="commentTemp.replyComment" [reduceSize]="true" [canEditAllTasks]="canEditAllTasks"
            [isReplyText]="true" (created)="onReplyCommentCreated(replyComment, commentBox)"
            [checkIsDirty]="checkIsDirty" (isDirtyChanged)="onCommentDirtyChanged()"></mc-comment-box>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
