import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { PermissionsTreeComponent } from '@portal-core/permissions/components/permissions-tree/permissions-tree.component';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { PermissionsFormControl } from '@portal-core/permissions/util/permissions-form-control';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { uniqBy } from 'lodash';
import { Observable, Subscription, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';

interface PermissionsData {
  disabledPermissions: Permissions[];
  selectedPermissions: Permissions[];
}

@Component({
  selector: 'mc-permissions-form',
  templateUrl: './permissions-form.component.html',
  styleUrls: ['./permissions-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class PermissionsFormComponent implements OnInit {
  @Input() licenseId: number;
  @Input() readonly?: boolean = false;
  @Input() entityId: ModelId;
  @Input() projectIds: number[];
  @Input() permissionsFormControl: PermissionsFormControl;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(PermissionsTreeComponent, { static: true }) permissionsTree: PermissionsTreeComponent;

  /** Whether or not the form is dirty. */
  get dirty(): boolean {
    return this.permissionsTree?.dirty;
  }

  /** The id for the global permissions item in the dropdown. */
  readonly LicenseLevelId: number = -1;
  /** The disabled permissions for the currently selected permissions level. Only used for project permissions. */
  disabledPermissions: Permissions[] = [];
  /** The currently selected permissions level from the dropdown.  */
  levelId: number;
  /** Keep track of the loading state and its errors. */
  loadingState: LoadingState<string> = new LoadingState<string>();
  /** Keep track of the saving state and its errors. */
  savingState: LoadingState<string> = new LoadingState<string>();
  /** The selected permissions for the currently selected permissions level. */
  selectedPermissions: Permissions[] = [];

  /** The subscription for loading permissions. */
  private permissionsSubscription: Subscription;

  /** Observable for the parameters required to load the permissions. */
  @InputObservable(['licenseId', 'entityId']) permissionsParameters$: Observable<[number, ModelId]>;
  /** Observable for the currently selected permissions level. */
  @PropertyObservable('levelId') levelId$: Observable<number>;

  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private permissionsService: PermissionsService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.permissionsSubscription = this.levelId$.pipe(
      tap(() => this.loadingState.update(true)),
      tap(() => this.permissionsTree.markAsPristine()),
      switchMap<number, Observable<PermissionsData>>(levelId => {
        if (levelId === this.LicenseLevelId) {
          return this.permissionsParameters$.pipe(
            switchMap(([licenseId, entityId]) => {
              return this.permissionsFormControl.getPermissionsOnLicenseLevel$(entityId, licenseId).pipe(
                map(selectedPermissions => {
                  return {
                    disabledPermissions: null,
                    selectedPermissions
                  };
                }),
                tap(() => this.loadingState.update(false)),
                catchError(error => {
                  this.loadingState.update(false, 'Unable to load the permissions.', this.errorService.getErrorMessages(error));
                  return of(null);
                })
              );
            })
          );
        } else if (typeof levelId === 'number') {
          return this.permissionsParameters$.pipe(
            switchMap(([licenseId, entityId]) => {
              return combineLatest([
                this.permissionsFormControl.getPermissionsOnProjectLevel$(entityId, levelId),
                this.permissionsFormControl.getPermissionsOnLicenseLevel$(entityId, licenseId),
                this.permissionsService.getSubPermissionCategories$('Administrative')
              ]).pipe(
                map(([projectPermissions, globalPermissions, adminPermissionCategories]) => {
                  // Convert the admin permission categories into permissions
                  const allAdminPermissions: Permissions[] = adminPermissionCategories.map(category => {
                    return {
                      Id: null,
                      PermissionCategoryId: category.Id
                    };
                  });

                  return {
                    // The admin permissions are always disabled for projects
                    disabledPermissions: uniqBy([...allAdminPermissions, ...globalPermissions], permission => permission.PermissionCategoryId),
                    selectedPermissions: uniqBy([...globalPermissions, ...projectPermissions], permission => permission.PermissionCategoryId)
                  };
                }),
                tap(() => this.loadingState.update(false)),
                catchError(error => {
                  this.loadingState.update(false, 'Unable to load the permissions.', this.errorService.getErrorMessages(error));
                  return of(null);
                })
              );
            })
          );
        } else {
          this.loadingState.update(false);
          return of(null);
        }
      })
    ).subscribe(permissionsData => {
      this.disabledPermissions = permissionsData?.disabledPermissions;
      this.selectedPermissions = permissionsData?.selectedPermissions;
      this.cdr.markForCheck();
    }, error => {
      this.loadingState.update(false, 'Unable to load the permissions.', this.errorService.getErrorMessages(error));
      this.disabledPermissions = null;
      this.selectedPermissions = null;
      this.cdr.markForCheck();
    });


    // Lastly initial the selected level
    this.levelId = this.LicenseLevelId;
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onPermissionsLevelSelectionChanged(event: MatSelectChange) {
    if (this.levelId !== event.value) {
      if (this.dirty) {
        this.dialog.open(ConfirmDialogComponent, {
          ...ConfirmDialogComponent.DialogConfig,
          disableClose: true, // Only allow the user to choose one of the action buttons
          data: {
            action: 'Switch',
            actionColor: 'warn',
            cancelAction: 'Keep Open',
            cancelActionColor: 'primary',
            prompt: 'You have not saved your changes. Are you sure you want to switch to a different level of permissions? All changes will be discarded.',
            showCloseButton: false // Only allow the user to choose one of the action buttons
          }
        }).afterClosed().subscribe(shouldSwitch => {
          if (shouldSwitch) {
            this.levelId = event.value;
          }
        });
      } else {
        this.levelId = event.value;
      }
    }
  }

  onSubmit() {
    if (this.readonly) {
      return;
    }

    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      let request$: Observable<Permissions[]>;
      const permissions: Permissions[] = this.permissionsTree.getSelectedPermissions().map(permission => ({ Id: 0, PermissionCategoryId: permission.Id }));

      if (this.levelId === this.LicenseLevelId) {
        request$ = this.permissionsFormControl.savePermissionsOnLicenseLevel$(this.entityId, this.licenseId, permissions);
      } else {
        // Only save selected permissions on project level which are not already selected on the global level
        request$ = this.permissionsFormControl.savePermissionsOnProjectLevel$(this.entityId, this.levelId, permissions.filter(selectedPermission => !this.disabledPermissions.find(disabledPermission => disabledPermission.PermissionCategoryId === selectedPermission.PermissionCategoryId)));
      }

      request$.subscribe(() => {
        this.savingState.update(false);
        this.permissionsTree.markAsPristine();
        this.snackBar.open('Permissions saved.', 'OK', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to save the permissions.', this.errorService.getErrorMessages(error));
      });
    }
  }
}
