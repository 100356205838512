import { DOWN_ARROW, ENTER, ESCAPE, SPACE, UP_ARROW } from '@angular/cdk/keycodes';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { PopupBase } from '@portal-core/ui/popup/util/popup-base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subscription, filter, fromEvent, merge } from 'rxjs';

/**
 * mcPopupTriggerFor
 * Opens a popup when triggered by an event from the element it is used on.
 * If the element is an in input element then the popup is triggered on focus, input, or the up/down arrow key is pressed.
 * If on any other element the popup is triggered when the element is clicked.
 */
@Directive({
  selector: '[mcPopupTriggerFor]',
  exportAs: 'mcPopupTrigger'
})
@AutoUnsubscribe()
export class PopupTriggerDirective implements OnInit, OnDestroy {
  /** A CSS class name that is added to the popup's panel element. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupClass') popupClass: string | string[];

  /** The height of the popup in pixels or 'width' to make the height the same as the width. Defaults to undefined which causes the popup to grow to the fit its content. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupHeight') popupHeight: number | 'width';

  /** The width of the popup in pixels. Defaults to the width of the trigger element. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupWidth') popupWidth: number;

  /** Whether or not focus should be restored to the trigger element after the popup is closed. Defaults to true. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupRestoreFocus') popupRestoreFocus: boolean = true;

  /** Whether or not the trigger is disabled. Defaults to false. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupTriggerDisabled') popupDisabled: boolean = false;

  /** Whether or not the popup should close when there is a click outside of the popup or trigger element. Defaults to true. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupTriggerAutoClose') popupAutoClose: boolean = true;

  /** The overlay container element for the popup. The popup is inserted into the overlay container element. Defaults to Material's viewport overlay container. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupOverlayContainer') popupOverlayContainer?: HTMLElement;

  /** The scrollables that the popup should scroll with to stay in the same relative position. Usually there is only one scrollable if any. Defaults to no scrollables. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupScrollables') popupScrollables?: CdkScrollable[];

  /** A reference to the popup instance that the trigger is associated with. */
  @Input('mcPopupTriggerFor')
  get popup(): PopupComponent {
    return this._popup;
  }
  set popup(popup: PopupComponent) {
    if (popup === this._popup) {
      return;
    }

    this._popup = popup;

    if (popup) {
      popup.registerTrigger(this);
    }

    // The popup closed so shutdown the close popup subscription
    this.closePopupSubscription = null;
  }
  private _popup: PopupComponent;

  /** Whether or not the popup should be opened when the trigger element gains focus. Defaults to false. */
  // tslint:disable-next-line: no-input-rename
  @Input('mcPopupTriggerOnFocus') popupTriggerOnFocus: boolean = false;

  /** Whether or not the popup is open. */
  get opened(): boolean {
    return this.popup?.opened ?? false;
  }

  /** Whether the popup can open the next time the trigger is focused. Used to prevent a focused, closed autocomplete from being reopened if the user switches to another browser tab and then comes back. */
  private canOpenOnNextFocus: boolean = true;
  /** The subscription for listening to popup close events. */
  @SubscriptionProperty() private closePopupSubscription: Subscription;
  /** Holds the unlisteners for the DOM events that subscribed to so they can be unsubscribed from later. */
  private eventUnlisteners: Function[];
  /** Whether or not the trigger is an input element. */
  private isInputElement: boolean;

  constructor(@Inject(DOCUMENT) private document: Document, @Optional() private formField: MatFormField, private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.isInputElement = elementRef.nativeElement instanceof HTMLInputElement;
  }

  /** Sets up the DOM event listeners on the trigger's element. */
  ngOnInit() {
    this.eventUnlisteners = [
      this.renderer.listen(this.elementRef.nativeElement, 'keydown', this.onKeydown.bind(this)),
      this.renderer.listen(this.elementRef.nativeElement, 'click', this.onClick.bind(this))
    ];

    if (this.popupTriggerOnFocus) {
      this.eventUnlisteners.push(this.renderer.listen(this.elementRef.nativeElement, 'focus', this.onFocus.bind(this)));
      this.eventUnlisteners.push(this.renderer.listen('window', 'blur', this.onWindowBlur.bind(this)))
    }

    if (this.isInputElement) {
      this.eventUnlisteners.push(this.renderer.listen(this.elementRef.nativeElement, 'input', this.onInput.bind(this)));
    }
  }

  /** Stops listening to the DOM events on the trigger's element. */
  ngOnDestroy() {
    if (this.eventUnlisteners) {
      this.eventUnlisteners.forEach(unlistener => unlistener());
      this.eventUnlisteners = null;
    }
  }

  /** Handles the window blur event to keep the popup closed when the window regains focus. */
  private onWindowBlur() {
    // If the user blurred the window while the trigger is focused, it means that it'll be refocused when they come back.
    // In this case we want to skip the first focus event, if the popup was closed, in order to avoid reopening it unintentionally.
    this.canOpenOnNextFocus = this.document.activeElement !== this.elementRef.nativeElement || this.popup.opened;
  }

  /** Opens the popup when the trigger's element gains focus. */
  private onFocus() {
    if (!this.canOpenOnNextFocus) {
      this.canOpenOnNextFocus = true;
    } else {
      this.open();
    }
  }

  /** Opens the popup when the trigger's element has input. */
  private onInput(event: KeyboardEvent) {
    // If the trigger has focus then open the popup
    if (this.document.activeElement === event.target) {
      this.open();
    }
  }

  /** Opens the popup when the trigger's element has the up or down arrow pressed in it. */
  private onKeydown(event: KeyboardEvent) {
    // If the popup isn't open already
    if (!this.opened) {
      // If the up/down arrow is pressed or if this is not an input element and the enter/space key is pressed
      if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW || (!this.isInputElement && (event.keyCode === ENTER || event.keyCode === SPACE))) {
        this.open();
      }
    } else if (this.opened) {
      if (event.keyCode === ESCAPE) {
        this.close();
        // Prevent default and stop propagation should be done because this popup could be in another popup and we only want this popup to close from the escape key
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  /** Opens the popup when the trigger's element is clicked. */
  private onClick() {
    this.open();
  }

  /** Opens the trigger's popup. */
  open() {
    const element = this.elementRef.nativeElement as HTMLInputElement;
    if (this.popupDisabled || element.readOnly || element.disabled) {
      return;
    }

    // Update the size before opening the menu to make sure its up to date
    this.popup.updateSize();
    this.popup.open();
  }

  /** Closes the trigger's popup. */
  close() {
    this.popup.close();
    this.closePopupSubscription?.unsubscribe();
  }

  /** Updates the position of the trigger's popup. This can be called to ensure the popup is connected to the trigger element. */
  updatePosition() {
    this.popup.updatePosition();
  }

  /**
   * Creates a stream of events that are clicks outside of the popup and the trigger.
   * If the trigger is inside of a mat-form-field then clicks inside the mat-form-field are considered inside the trigger.
   * */
  private outsideClick$(popup: PopupBase<any>): Observable<any> {
    return merge(
      fromEvent(this.document, 'click') as Observable<MouseEvent>,
      fromEvent(this.document, 'auxclick') as Observable<MouseEvent>,
      fromEvent(this.document, 'touchend') as Observable<TouchEvent>
    ).pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
        const formField = this.formField?._elementRef.nativeElement as HTMLElement;

        // If the popup is open
        return this.opened
          // And the click target is still in the document
          && this.document.contains(clickTarget)
          // And the click target is not in the trigger element
          && !this.elementRef.nativeElement.contains(clickTarget)
          // And the click target is not in the form field
          && (!formField || !formField.contains(clickTarget))
          // And the click target is not in the popup
          && (!popup.popupRef?.overlayElement?.contains(clickTarget))
          // And the click target is not in an overlay opened above this popup
          && !this.isElementInsideSubOverlay(popup, clickTarget);
      })
    );
  }

  /**
   * Returns true to close the popup if Alt+up was pressed.
   * Called by mc-popup.
   * @param event The keyboard event that occurred.
   */
  filterKeyDownForClosingThePopup(event: KeyboardEvent): boolean {
    return (this.isInputElement || this.formField) && event.altKey && event.keyCode === UP_ARROW;
  }

  /**
   * Uses the trigger's form field as the popup's connected origin if it exists. Otherwise the trigger's element is used.
   * Called by mc-popup.
   */
  getConnectedOverlayOrigin(): ElementRef {
    return this.formField?.getConnectedOverlayOrigin() ?? this.elementRef;
  }

  /**
   * Returns the element that the popup should be inserted into.
   * Called by mc-popup.
   */
  getOverlayContainerElement(): HTMLElement {
    return this.popupOverlayContainer;
  }

  /**
   * Returns the scrollables that the popup should observe for scroll events in order to scroll the popup with the scrollable element.
   * Called by mc-popup.
   */
  getOverlayScrollables(): CdkScrollable[] {
    return this.popupScrollables;
  }

  /**
   * Returns the CSS class name to put on the popup's panel element.
   * Called by mc-popup.
   */
  getPopupPanelClass(): string | string[] {
    return this.popupClass;
  }

  /**
   * The popup should restore focus to the trigger on close if the trigger is on an input element.
   * Called by mc-popup.
   */
  getPopupShouldRestoreFocusOnClose(): boolean {
    return this.popupRestoreFocus;
  }

  getPopupSize(): { width: number, height: number } {
    const triggerWidth = this.popupWidth ?? (this.getConnectedOverlayOrigin().nativeElement.offsetWidth || undefined);

    return {
      width: triggerWidth,
      height: this.popupHeight === 'width' ? triggerWidth : this.popupHeight
    };
  }

  /**
   * Begins listening to outside clicks in order to close the popup.
   * Called by mc-popup.
   */
  initPopup(popup: PopupBase<any>) {
    // Listen for events to close the popup
    this.closePopupSubscription = this.outsideClick$(popup).subscribe(event => {
      if (this.popupAutoClose) {
        if (event) {
          event.preventDefault();
        }

        this.close();
      }
    });
  }

  private isElementInsideSubOverlay(popup: PopupBase<any>, targetElement: HTMLElement): boolean {
    if (popup.popupRef?.overlayElement) {
      const overlayContainerElement = popup.popupRef.overlayElement.closest('.cdk-overlay-container');

      if (overlayContainerElement) {
        let overlayIndex: number;
        let targetOverlayIndex: number;

        for (let i = 0; i < overlayContainerElement.children.length; i += 1) {
          if (overlayContainerElement.children[i].contains(popup.popupRef.overlayElement)) {
            overlayIndex = i;
          } else if (overlayContainerElement.children[i].contains(targetElement)) {
            targetOverlayIndex = i;
          }
        }

        if (typeof overlayIndex === 'number' && typeof targetOverlayIndex === 'number') {
          return targetOverlayIndex > overlayIndex;
        }
      }
    }

    return false;
  }
}
