import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteListModule } from '@portal-core/ui/list/components/infinite-list/infinite-list.module';
import { AllItemsLoadedListModule } from '@portal-core/ui/list/directives/all-items-loaded-list/all-items-loaded-list.module';
import { EmptyListModule } from '@portal-core/ui/list/directives/empty-list/empty-list.module';
import { ListItemModule } from '@portal-core/ui/list/directives/list-item/list-item.module';
import { NavListComponent } from './nav-list.component';

@NgModule({
  declarations: [
    NavListComponent
  ],
  imports: [
    AllItemsLoadedListModule,
    CommonModule,
    EmptyListModule,
    InfiniteListModule,
    ListItemModule
  ],
  exports: [
    NavListComponent
  ]
})
export class NavListModule { }
