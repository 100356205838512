import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { GutterItem, GutterItemType } from '@common/prosemirror/plugins/gutter.plugin';
import { DomService } from '@portal-core/general/services/dom.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { AnnotationCommentChangeEvent } from '@portal-core/project-files/models/annotation-comment-change-event.model';
import { GutterLayoutObjectModel } from '@portal-core/project-files/util/gutter-layout-object-model';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { SVG, Svg } from '@svgdotjs/svg.js';
import { Subscription } from 'rxjs';

enum GutterLineType {
  Hover = 'hover',
  Select = 'select'
}

interface ResolvedItem {
  index?: number;
  item?: GutterItem;
  itemElementRef?: ElementRef;
}

@Component({
  selector: 'mc-flare-file-text-editor-gutter',
  templateUrl: './flare-file-text-editor-gutter.component.html',
  styleUrls: ['./flare-file-text-editor-gutter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FlareFileTextEditorGutterComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChildren('gutterItem') itemElements: QueryList<ElementRef>;
  @Input() items: GutterItem[];
  @Input() licenseUser?: LicenseUser;
  @Input() readonly: boolean;
  @Input() selectedItemId: string;
  @Input() targetsContainer: HTMLElement;

  @Output() annotationCommentChange: EventEmitter<AnnotationCommentChangeEvent> = new EventEmitter<AnnotationCommentChangeEvent>();
  @Output() itemHover: EventEmitter<GutterItem> = new EventEmitter<GutterItem>();
  @Output() itemSelect: EventEmitter<GutterItem> = new EventEmitter<GutterItem>();

  ChangeType: typeof ChangeType = ChangeType;
  GutterItemType: typeof GutterItemType = GutterItemType;

  documentMouseDownEventHandler: EventListener;
  targetLinesGutterOffset: number = 16;
  targetLinesOffset: number = 4;
  hoveredResolvedItem: ResolvedItem;
  itemElementsSubscription: Subscription;
  lom: GutterLayoutObjectModel;
  svg: Svg;

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef, private ngZone: NgZone, private domService: DomService) { }

  ngOnInit() {
    this.documentMouseDownEventHandler = this.onDocumentMouseDown.bind(this);

    // Use the native addEventListener method because Angular does not support event options (eg capture phase).
    // Use the capture phase so that this mousedown handler runs before "normal" mousedown handlers in the app.
    // We want this behavior because the editor toolbar executes commands on mousedown.
    // And in the case of creating an annotation the toolbar sets the selected gutter item.
    // Since this mouse down event handler clears the selected gutter item it needs to run before the toolbar mousedown handler
    //   so that it does not clear out the selection that the toolbar's makes on mouse down
    this.document.addEventListener('mousedown', this.documentMouseDownEventHandler, { capture: true });
  }

  ngOnDestroy() {
    if (this.documentMouseDownEventHandler) {
      this.document.removeEventListener('mousedown', this.documentMouseDownEventHandler, { capture: true });
      this.documentMouseDownEventHandler = null;
    }

    if (this.svg) {
      this.svg.remove();
      this.svg = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      // Clear the svg canvas
      if (this.svg) {
        this.svg.clear();
      }

      // Reset the gutter height
      this.elementRef.nativeElement.style.height = `auto`;

      // If there are items create a layout object for them
      if (this.items) {
        if (this.lom) {
          this.lom.reset(this.items);
        } else {
          this.lom = new GutterLayoutObjectModel(this.items, this.domService, this.ngZone);
        }
      } else {
        this.lom = null;
      }
    }

    if (changes.selectedItemId) {
      if (this.selectedItemId) {
        this.selectItem(this.selectedItemId);
      } else {
        this.deselectItem();
      }
    }
  }

  ngAfterViewInit() {
    // Create a helper function to run the layout and update the selected item
    const runLayout = () => {
      this.layout();

      // After the item elements change make sure the selected item's state is up to date
      if (this.selectedItemId) {
        const resolvedItem = this.resolveItem(this.selectedItemId);
        if (resolvedItem.item && resolvedItem.itemElementRef) {
          this.updateItemUIState(resolvedItem.itemElementRef, resolvedItem.item);
        }
      }
    };

    this.itemElementsSubscription = this.itemElements.changes.subscribe(() => {
      runLayout();
    });

    // Run the initial layout
    runLayout();
  }

  onAnnotationCommentBlurred(item: GutterItem) {
    // Only deselect the current item if it is the same as the annotation comment that just lost focus
    if (item.id === this.selectedItemId) {
      this.deselectItem();
    }
  }

  onAnnotationCommentChanged(item: GutterItem) {
    this.annotationCommentChange.emit({
      annotation: item.data
    });
  }

  onAnnotationCommentFocused(item: GutterItem) {
    this.selectItem(item.id);
  }

  onAnnotationCommentInput(item: GutterItem) {
    const resolvedItem = this.resolveItem(item.id);

    if (resolvedItem.item && resolvedItem.itemElementRef) {
      // When the comment changes the item's layout needs to be recalculated in case the item's height has changed
      this.layout(resolvedItem.index);

      // Update the target lines in case the annotation gutter item's size changed
      this.drawItemTargetLines(resolvedItem.itemElementRef.nativeElement, resolvedItem.item, GutterLineType.Select);

      if (this.hoveredResolvedItem && this.hoveredResolvedItem.item && this.hoveredResolvedItem.itemElementRef) {
        this.drawItemTargetLines(this.hoveredResolvedItem.itemElementRef.nativeElement, this.hoveredResolvedItem.item, GutterLineType.Hover);
      }
    }
  }

  onItemClicked(item: GutterItem) {
    this.selectItem(item.id);
  }

  onItemMouseEnter(item: GutterItem) {
    this.hoverItem(item);
  }

  onItemMouseLeave(item: GutterItem) {
    this.dehoverItem(item);
  }

  onDocumentMouseDown(event: MouseEvent) {
    const itemElement = (event.target as Element).closest('.mc-flare-file-text-editor-gutter-item');

    if (!itemElement) {
      this.deselectItem();
    }
  }

  selectItem(itemId: string) {
    this.deselectItem();

    this.selectedItemId = itemId;

    if (this.selectedItemId) {
      const resolvedItem = this.resolveItem(this.selectedItemId);

      if (resolvedItem.item) {
        if (resolvedItem.itemElementRef) {
          this.drawItemTargetLines(resolvedItem.itemElementRef.nativeElement, resolvedItem.item, GutterLineType.Select);
          this.updateItemUIState(resolvedItem.itemElementRef, resolvedItem.item);
        }

        this.itemSelect.emit(resolvedItem.item);
      }
    }
  }

  deselectItem() {
    if (this.selectedItemId) {
      this.clearItemTargetLines(GutterLineType.Select);

      const resolvedItem = this.resolveItem(this.selectedItemId);
      this.selectedItemId = null;

      if (resolvedItem.item) {
        if (resolvedItem.itemElementRef) {
          this.updateItemUIState(resolvedItem.itemElementRef, resolvedItem.item);
        }

        this.itemSelect.emit(null);
      }
    }
  }

  hoverItem(item: GutterItem) {
    this.hoveredResolvedItem = this.resolveItem(item.id);

    if (this.hoveredResolvedItem.itemElementRef) {
      this.hoveredResolvedItem.itemElementRef.nativeElement.classList.add('mc-flare-file-text-editor-gutter-item-hovered');
      this.drawItemTargetLines(this.hoveredResolvedItem.itemElementRef.nativeElement, item, GutterLineType.Hover);
    }

    this.itemHover.emit(item);
  }

  dehoverItem(item: GutterItem) {
    this.hoveredResolvedItem = null;

    const resolvedItem = this.resolveItem(item.id);

    if (resolvedItem.itemElementRef) {
      resolvedItem.itemElementRef.nativeElement.classList.remove('mc-flare-file-text-editor-gutter-item-hovered');
    }

    this.clearItemTargetLines(GutterLineType.Hover);
    this.itemHover.emit(null);
  }

  trackGutterItemsBy(index: number, item: GutterItem): any {
    return item ? item.id : undefined;
  }

  layout(itemIndex: number = 0) {
    this.lom.layout(this.items, this.itemElements.toArray(), this.targetsContainer, itemIndex);

    // Update the height of the gutter itself
    this.updateGutterHeight();
  }

  forceLayout() {
    if (this.lom) {
      this.lom.forceLayout(this.items, this.itemElements.toArray(), this.targetsContainer);

      // Update the height of the gutter itself
      this.updateGutterHeight();
    }
  }

  private updateGutterHeight() {
    // Giving a height to the gutter makes the editor's divider always fill the space in the editor and helps with the SVG target lines
    const gutterHeight = this.lom.getHeight();
    const gutterHeightStyle = gutterHeight === 0 ? 'auto' : `${gutterHeight}px`;
    if (this.elementRef.nativeElement.style.height !== gutterHeightStyle) {
      this.elementRef.nativeElement.style.height = gutterHeightStyle;
    }
  }

  private resolveItem(itemId: string): ResolvedItem {
    if (this.itemElements) {
      const index = this.items.findIndex(gutterItem => gutterItem.id === itemId);

      if (index !== -1) {
        let itemElementRef = this.itemElements.toArray()[index];

        // Ensure the element at index has the same item id
        if (!itemElementRef || itemId !== (itemElementRef.nativeElement as Element).getAttribute('data-id')) {
          itemElementRef = undefined;
        }

        return {
          index,
          item: this.items[index],
          itemElementRef
        };
      }
    }

    return {};
  }

  private updateItemUIState(itemElementRef: ElementRef, item: GutterItem) {
    const itemElement: HTMLElement = itemElementRef.nativeElement;
    const itemIsSelected = item.id === this.selectedItemId;

    // Update the selected class on the item
    if (itemIsSelected) {
      itemElement.classList.add('mc-flare-file-text-editor-gutter-item-selected');
    } else {
      itemElement.classList.remove('mc-flare-file-text-editor-gutter-item-selected');
    }

    // If an annotation item is selected then give the comment textarea focus
    if (item.type === GutterItemType.Annotation) {
      if (itemIsSelected) {
        const textareaNativeElement = itemElement.querySelector('textarea');

        if (textareaNativeElement) {
          // Focus after the gutter collapses and redraws it's height based on the new items
          // By waiting until the gutter is drawn before focusing, it keeps the item in view.
          setTimeout(() => textareaNativeElement.focus(), 0);
        }
      }
    }
  }

  private drawItemTargetLines(itemElement: HTMLElement, item: GutterItem, lineType: GutterLineType) {
    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        // Draw the connecting lines between the gutter item and the item's target
        if (this.targetsContainer) {
          // Grab the position and dimension of the the item
          const itemPos = this.domService.offsetFrom(itemElement, this.targetsContainer);
          const itemRect = itemElement.getBoundingClientRect();

          // Grab the svg canvas to draw on
          this.svg = this.svg || SVG().addTo(this.targetsContainer).size('100%', '100%').addClass('mc-editor-gutter-canvas');

          // Remove the old polylines
          this.svg.find(`[data-line-type="${lineType}"]`).forEach(line => line.remove());

          // Loop through all the targets and draw a line from the item to each target
          this.targetsContainer.querySelectorAll(item.targetSelector).forEach((targetElement: HTMLElement) => {
            const targetPos = this.domService.offsetFrom(targetElement, this.targetsContainer);
            const targetRect = targetElement.getBoundingClientRect();

            // Draw the new polyline
            this.svg.polyline([
              targetPos.left + targetRect.width / 2, targetPos.top + targetRect.height,
              targetPos.left + targetRect.width / 2, targetPos.top + targetRect.height + this.targetLinesOffset,
              itemPos.left - this.targetLinesGutterOffset, targetPos.top + targetRect.height + this.targetLinesOffset,
              itemPos.left - this.targetLinesGutterOffset, itemPos.top + itemRect.height / 2,
              itemPos.left, itemPos.top + itemRect.height / 2,
              itemPos.left, itemPos.top,
              itemPos.left + itemRect.width, itemPos.top,
              itemPos.left + itemRect.width, itemPos.top + itemRect.height,
              itemPos.left, itemPos.top + itemRect.height,
              itemPos.left, itemPos.top + itemRect.height / 2
            ]).attr({
              'data-line-type': lineType
            }).addClass('mc-editor-gutter-line');
          });
        }
      });
    });
  }

  private clearItemTargetLines(lineType: GutterLineType) {
    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        if (this.targetsContainer) {
          // Grab the svg canvas to draw on
          this.svg = this.svg || SVG().addTo(this.targetsContainer).size('100%', '100%').addClass('mc-editor-gutter-canvas');

          // Remove the old polylines
          this.svg.find(`[data-line-type="${lineType}"]`).forEach(oldLine => oldLine.remove());
        }
      });
    });
  }
}
