import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapTogglerSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcaptoggler: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        targets: { default: undefined },
      },
      parseDOM: [{
        tag: 'MadCap\\:toggler',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['targets']);
        },
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-toggler' }, 0]; },
      tagName: 'MadCap:toggler'
    }
  };
}
