import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapVariableSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapvariable: {
      group: 'inline',
      inline: true,
      attrs: {
        name: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:variable',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['name']);
        }
      }, {
        tag: 'span',
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-variable')) {
            return false;
          }

          return getSchemaAttrs(dom, ['name']);
        }
      }],
      tagName: 'MadCap:variable',
      isVoid: true
    }
  };
}
