import { NodeType } from '@common/html/enums/node-type.enum';
import { SimpleDomDocument, SimpleDomNode } from '@common/html/simple-dom/node';

/**
 * Converts a DOM node into a SimpleDOM node.
 * Used by fromDOM to recursively convert child nodes.
 * Typically fromDOM should be used to convert a DOM node to a SimpleDOM node.
 * @param node The DOM node to convert.
 * @param doc The document to add the node to.
 * @returns The SimpleDOM node.
 */
export function copyDOMNode(node: Node, doc: SimpleDomDocument): SimpleDomNode {
  // DOM nodes have all upper case node names but SimpleDOM uses the original casing and in the case of the standard HTML elements they are lowercase.
  // So make the nodeName lowercase.
  const newNode = new SimpleDomNode(doc, node.nodeName.toLowerCase(), node.nodeType);

  if (node.nodeType === NodeType.TEXT_NODE || node.nodeType === NodeType.CDATA_SECTION_NODE || node.nodeType === NodeType.COMMENT_NODE) {
    newNode.nodeValue = node.nodeValue;
  } else if (node.nodeType === NodeType.ELEMENT_NODE) {
    for (let i = 0; i < node.childNodes.length; i += 1) {
      newNode.appendChild(copyDOMNode(node.childNodes[i], doc));
    }

    for (let i = 0; i < (node as Element).attributes.length; i++) {
      const attr = (node as Element).attributes[i];
      newNode.attributes.push({ nodeName: attr.name, nodeType: NodeType.ATTRIBUTE_NODE, nodeValue: attr.value });
    }
  }

  return newNode;
}

/**
 * Converts a DOM content type to a SimpleDOM content type.
 * @param contentType The content type to convert.
 * @returns The SimpleDOM content type, either 'text/html' or 'text/xml'.
 */
export function domContentTypeToSimpleDomContentType(contentType: string): 'text/html' | 'text/xml' {
  if (contentType === 'text/html') {
    return 'text/html';
  } else {
    return 'text/xml';
  }
}

/**
 * Converts a DOM node into a SimpleDOM node.
 * NodeNames are converted to lowercase when creating the SimpleDOM nodes.
 * @param dom The DOM node to convert.
 * @returns The SimpleDOM node.
 */
export function fromDOM(dom: Node, contentType?: 'text/html' | 'text/xml'): SimpleDomNode {
  // Create a document for the new SimpleDOM nodes
  const doc = new SimpleDomDocument(null, '#document', NodeType.DOCUMENT_NODE);
  if (contentType) {
    doc.contentType = contentType;
  } else {
    doc.contentType = domContentTypeToSimpleDomContentType(dom.ownerDocument.contentType);
  }

  return copyDOMNode(dom, doc);
}
