import { Injectable } from '@angular/core';
import { ChecklistTemplateColumn } from '@portal-core/checklist-templates/models/checklist-template-column.model';
import { ChecklistTemplateItem } from '@portal-core/checklist-templates/models/checklist-template-item.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProjectChecklistItemStatus } from '@portal-core/project-checklists/enums/project-checklist-item-status.enum';
import { BuildProjectChecklistAnalyticsOptions } from '@portal-core/project-checklists/models/build-project-checklist-analytics-options.model';
import { ProjectChecklistAnalyticsFileCount } from '@portal-core/project-checklists/models/project-checklist-analytics-file-count.model';
import { ProjectChecklistColumn } from '@portal-core/project-checklists/models/project-checklist-column.model';
import { ProjectChecklistItem } from '@portal-core/project-checklists/models/project-checklist-item.model';
import { ProjectChecklist } from '@portal-core/project-checklists/models/project-checklist.model';
import { ProjectChecklistsApiService } from '@portal-core/project-checklists/services/project-checklists-api.service';
import { ProjectChecklistsDataService } from '@portal-core/project-checklists/services/project-checklists-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { find, forEach } from 'lodash';
import { Observable, Subject, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ProjectChecklistsService extends CollectionServiceBase<ProjectChecklist> {

  refreshItems$: Subject<void> = new Subject<void>();
  constructor(
    private projectChecklistsDataService: ProjectChecklistsDataService,
    private projectChecklistsApiService: ProjectChecklistsApiService,
    protected dataService: DataService
  ) {
    super(projectChecklistsDataService, dataService);
  }

  protected fetchItemById$(checklistId: number): Observable<ProjectChecklist> {
    return this.projectChecklistsApiService.getChecklistById$(checklistId).pipe(map((checklist) => {
      delete checklist.Analytics;
      return checklist;
    }));
  }

  getChecklistsByProjectId$(projectId: number, options?: GetDataOptions): Observable<ProjectChecklist[]> {
    return this.dataService.getDataListItems$<ProjectChecklist>('Projects', projectId, this.projectChecklistsDataService, {
      fetch: () => this.projectChecklistsApiService.getChecklistsByProjectId$(projectId).pipe(
        map(checklists => this.buildChecklistsAnalytics(checklists)),
        map(checklists => checklists.sort((checklistA, checklistB) => checklistA.Name.localeCompare(checklistB.Name)))
      )
    }, options);
  }
  getChecklistsNameAndIdByProjectId$(projectId: number, options?: GetDataOptions): Observable<ProjectChecklist[]> {
    return this.dataService.getDataListItems$<ProjectChecklist>('Projects', projectId, this.projectChecklistsDataService, {
      fetch: () => this.projectChecklistsApiService.getChecklistsNameAndIdByProjectId$(projectId).pipe(
        map(checklists => this.buildChecklistsAnalytics(checklists)),
        map(checklists => checklists.sort((checklistA, checklistB) => checklistA.Name.localeCompare(checklistB.Name)))
      )
    }, options);
  }


  createProjectChecklist$(projectId: number, checklist: ProjectChecklist): Observable<ProjectChecklist> {
    return this.projectChecklistsApiService.upsertProjectChecklist$(projectId, checklist).pipe(
      tap(newChecklist => {
        newChecklist = this.buildChecklistAnalytics(newChecklist);
        this.projectChecklistsDataService.addItems$([newChecklist]);
        this.projectChecklistsDataService.addItemsToListById$('Projects', projectId, [newChecklist]);
      })
    );
  }

  updateProjectChecklist$(projectId: number, checklist: ProjectChecklist): Observable<any> {
    return this.projectChecklistsApiService.upsertProjectChecklist$(projectId, checklist).pipe(
      tap(updatedChecklist => this.projectChecklistsDataService.updateItems$([updatedChecklist]))
    );
  }

  deleteProjectChecklist$(checklistId: number, projectId: number): Observable<any> {
    return this.projectChecklistsApiService.deleteProjectChecklist$(checklistId, projectId).pipe(
      tap(() => this.projectChecklistsDataService.removeItems$([checklistId]))
    );
  }

  buildChecklistsAnalytics(checklists: ProjectChecklist[], options?: BuildProjectChecklistAnalyticsOptions): ProjectChecklist[] {
    return checklists.map(checklist => this.buildChecklistAnalytics(checklist, options));
  }

  buildChecklistAnalytics(checklist: ProjectChecklist, options: BuildProjectChecklistAnalyticsOptions = {}) {
    if (!checklist.Analytics) {
      return checklist;
    }
    // Add up the number of newly invalid status and the number of newly completed statuses
    let invalidStatusCount = 0,
      completedStatusCount = 0;

    if (Array.isArray(options.changedStatuses)) {
      options.changedStatuses.forEach(status => {
        invalidStatusCount -= (status.oldValue === ProjectChecklistItemStatus.NA ? 1 : 0);
        invalidStatusCount += (status.newValue === ProjectChecklistItemStatus.NA ? 1 : 0);

        completedStatusCount -= (status.oldValue === ProjectChecklistItemStatus.Completed ? 1 : 0);
        completedStatusCount += (status.newValue === ProjectChecklistItemStatus.Completed ? 1 : 0);
      });
    }

    checklist.Analytics.TotalStatusCount -= invalidStatusCount;

    // Update the total status file counts
    if (Array.isArray(checklist.Analytics.TotalStatusFileCounts)) {
      checklist.Analytics.TotalStatusFileCounts.forEach(statusInfo => {
        let statusCount = 0;

        // Go through each changed status in order to update the count
        if (Array.isArray(options.changedStatuses)) {
          options.changedStatuses.forEach(status => {
            // Update the count for this status based on the old and new value
            statusCount -= (status.oldValue === statusInfo.Status ? 1 : 0);
            statusCount += (status.newValue === statusInfo.Status ? 1 : 0);
          });
        }

        // Update the status with the new count
        statusInfo.Count += statusCount;
        if (checklist.Analytics.TotalStatusCount <= 0) {
          if (statusInfo.Legend === 'Completed') {
            statusInfo.Percent = 100;
          } else {
            statusInfo.Percent = 0;
          }
        } else {
          statusInfo.Percent = (statusInfo.Count / checklist.Analytics.TotalStatusCount) * 100;
        }
      });
    }
    // Update the column status file counts
    forEach(checklist.Analytics.ColumnFileCounts, (columnStatuses: ProjectChecklistAnalyticsFileCount[], columnId: string) => {
      const columnCompletedStatusInfo = find(columnStatuses, statusInfo => statusInfo.Status === ProjectChecklistItemStatus.Completed);
      if (columnCompletedStatusInfo) {
        // If changes were made to this column
        if (parseInt(columnId, 10) === options.changedColumnId) { // == on purpose because columnId is a string but options.columnId is a number
          columnCompletedStatusInfo.Count += completedStatusCount;
          columnCompletedStatusInfo.Total -= invalidStatusCount;
        }

        columnCompletedStatusInfo.Percent = columnCompletedStatusInfo.Total <= 0 ? 100 : (columnCompletedStatusInfo.Count / columnCompletedStatusInfo.Total) * 100;
      }
    });


    // Update the completed percentage
    let completedStatusInfo;

    if (Array.isArray(checklist.Analytics.TotalStatusFileCounts)) {
      completedStatusInfo = checklist.Analytics.TotalStatusFileCounts.find(statusInfo => statusInfo.Status === ProjectChecklistItemStatus.Completed);
    }

    if (checklist.Analytics.TotalStatusCount > 0) {
      checklist.Analytics.CompletedPercent = ((completedStatusInfo ? completedStatusInfo.Count : 0) / checklist.Analytics.TotalStatusCount) * 100;
    } else {
      checklist.Analytics.CompletedPercent = 0;
    }
    // Return the checklist
    return checklist;
  }

  checklistNameUnique$(checklistName: string, checklistId: number, projectId: number): Observable<boolean> {
    return this.getChecklistsByProjectId$(projectId).pipe(
      map(checklists => {
        return !checklists.some(checklist => checklist.Name === checklistName && checklist.Id !== checklistId);
      })
    );
  }

  mapToChecklistColumn(templateColumn: ChecklistTemplateColumn, checklistId: number = 0): ProjectChecklistColumn {
    return {
      ChecklistId: checklistId,
      Name: templateColumn.Name,
      Order: templateColumn.Order,
    } as ProjectChecklistColumn;
  }

  mapToChecklistItem(templateItem: ChecklistTemplateItem, checklistId: number = 0): ProjectChecklistItem {
    return {
      ChecklistId: checklistId,
      Name: templateItem.Name,
      Order: templateItem.Order,
      Type: templateItem.Type,
      Note: templateItem.Note
    } as ProjectChecklistItem;
  }

  mapToTemplateColumn(checklistColumn: ProjectChecklistColumn, templateId: number = 0): ChecklistTemplateColumn {
    return {
      ChecklistTemplateId: templateId,
      Name: checklistColumn.Name,
      Order: checklistColumn.Order
    } as ChecklistTemplateColumn;
  }

  mapToTemplateItem(checklistItem: ProjectChecklistItem): ChecklistTemplateItem {
    return {
      Name: checklistItem.Name,
      Type: checklistItem.Type,
      Order: checklistItem.Order,
      Note: checklistItem.Note
    } as ChecklistTemplateItem;
  }
}
