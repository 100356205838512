import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModuleConfig } from '@portal-core/layout/models/layout-module-config';
import { LayoutModuleConfigService } from '@portal-core/layout/services/layout-module-config.service';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class LayoutServicesModule {
  static forRoot(config: LayoutModuleConfig): ModuleWithProviders<LayoutServicesModule> {
    return {
      ngModule: LayoutServicesModule,
      providers: [
        {
          provide: LayoutModuleConfigService,
          useValue: config
        }
      ]
    };
  }
}
