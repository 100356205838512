import { Observable } from 'rxjs';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';

/**
 * ListControl
 * Defines the collection data store and fetch method used in a DataList.
 */
export class ListControl<T = any> {
  /**
   * constructor
   * @param collectionService The service for the collection redux store that stores the items in this list.
   * @param fetchDataListPage$ A method that can fetch a Page of items and returns it in an Observable.
   */
  constructor(public collectionService: CollectionServiceBase<T>, public fetchDataListPage$: (filter: PageFilter) => Observable<Page<T>>) { }
}
