<div class="mc-dialog-card-content-container">
  <div class="mc-dialog-card-main">
    <mat-toolbar class="mc-dialog-header-bar" color="secondary">
      <h2>Notifications</h2>

      <span class="mc-fill-remaining-flex-space"></span>

      <button mat-icon-button tabindex="-1" matDialogClose>
        <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </button>
    </mat-toolbar>

    <div class="mc-dialog-card-content mc-border-divider" [ngSwitch]="dialogMode">
      <mc-my-notification-list *ngSwitchCase="AlertCenterDialogMode.MyNotifications" [licenseId]="licenseId"></mc-my-notification-list>
      <mc-task-notification-list *ngSwitchCase="AlertCenterDialogMode.TaskNotifications" [licenseId]="licenseId" [taskBoardId]="taskBoardId"></mc-task-notification-list>
    </div>
  </div>
</div>