import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicenseUsersPageFilterConfig } from '@portal-core/license-users/util/license-users-page-filter-config';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { of } from 'rxjs';

@Component({
  selector: 'mc-license-users-select-list',
  templateUrl: './license-users-select-list.component.html',
  styleUrls: ['./license-users-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as LicenseUsersSelectListComponent so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => LicenseUsersSelectListComponent) }]
})
export class LicenseUsersSelectListComponent extends SelectListBase<LicenseUser> implements OnChanges {
  /** The license id that the projects belong to. */
  @Input() licenseId: number;
  /** The license user seat types to filter by. Defaults to showing all seat types. */
  @Input() seatTypes?: LicenseUserSeatType[];

  filterConfig: LicenseUsersPageFilterConfig = new LicenseUsersPageFilterConfig(['User.FullName', 'User.Email']);

  listControl: SelectionListControl<LicenseUser> = new SelectionListControl<LicenseUser>(this.licenseUsersService, filter => {
    if (typeof this.licenseId === 'number') {
      return this.licenseUsersService.getLicenseUsersPageByLicenseId$(this.licenseId, filter);
    } else {
      return of(null);
    }
  }, licenseUser => {
    return licenseUser.Id;
  });

  constructor(private licenseUsersService: LicenseUsersService, private pageFilterService: PageFilterService) {
    super(licenseUsersService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.seatTypes) {
      this.applyFilter$('license-users-select-list-seat-types', this.pageFilterService.create({
        Id: 'license-users- select-list-seat-types',
        Type: PageFilterGroupType.Select
      }).select('SeatType', this.seatTypes).value);
    }

    if (changes.licenseId) {
      this.hardReload();
    }
  }
}
