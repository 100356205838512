import { ChangeDetectionStrategy, Component, EventEmitter, Inject, InjectionToken, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { propertyValue } from '@common/util/properties';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { environment } from '@env/environment';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { MaxAvatarImageFileSizeBytes } from '@portal-core/data/common/constants/max-avatar-image-file-size.constant';
import { SizeBytes } from '@portal-core/data/enums/size-bytes.enum';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ImagePickerValue } from '@portal-core/forms/models/image-picker-value.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseSettings } from '@portal-core/licenses/models/license-settings.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { WINDOW } from '@portal-core/util/window.provider';
import { Observable, Subscription, combineLatest, first, of, startWith, switchMap } from 'rxjs';

export interface LicenseSettingsFormOptions {
  showAdminFields?: boolean | (() => boolean);
  redirectOnVanityChange?: boolean | (() => boolean);
}
export const MC_LICENSE_SETTINGS_FORM_OPTIONS = new InjectionToken<LicenseSettingsFormOptions>('LicenseSettingsFormOptions');

@Component({
  selector: 'mc-license-settings-form',
  templateUrl: './license-settings-form.component.html',
  styleUrls: ['./license-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicenseSettingsFormComponent implements OnInit, OnChanges {
  @Input() license: License;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @PropertyObservable('license') license$: Observable<License>;

  SizeBytes: typeof SizeBytes = SizeBytes;

  adminLicenseDataSubscription: Subscription;
  centralPortalDomain: string = new URL(environment.centralPortalBaseUri).host;
  changingVanity: boolean = false;
  licenseSettingsForm: UntypedFormGroup;
  loadingState: LoadingState<string> = new LoadingState<string>();
  outputDomain$: Observable<string>;
  redirectOnVanityChange: boolean = true;
  savingState: LoadingState<string> = new LoadingState<string>();
  showAdminFields: boolean = false;

  constructor(
    @Optional() @Inject(MC_LICENSE_SETTINGS_FORM_OPTIONS) private licenseSettingsFormOptions: LicenseSettingsFormOptions,
    @Inject(WINDOW) private window: Window,
    private formBuilder: UntypedFormBuilder,
    private licensesService: LicensesService,
    private errorService: ErrorService,
    private licenseStorageService: LicenseStorageService,
    private formsService: FormsService,
    private authUrlService: AuthUrlService,
    private sitesService: SitesService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.showAdminFields = propertyValue(this.licenseSettingsFormOptions, 'showAdminFields');
    this.redirectOnVanityChange = propertyValue(this.licenseSettingsFormOptions, 'redirectOnVanityChange', true);

    this.outputDomain$ = this.licenseSettingsForm.get('vanityBaseUrl').valueChanges.pipe(
      startWith(this.licenseSettingsForm.get('vanityBaseUrl').value),
      switchMap(vanityBaseUrl => this.sitesService.getLiveSiteDomain$(vanityBaseUrl))
    );

    // Load the license storage data whenever the license changes and track whether the current user is an admin
    this.adminLicenseDataSubscription = this.license$.pipe(
      switchMap(license => license ? this.licenseStorageService.getItemById$(this.license.Id) : of(null))
    ).subscribe(licenseStorage => {
      this.rebuildForm(this.showAdminFields, this.license || {} as License, licenseStorage);
    });

    this.loadingState.update(true);

    // Show the loader while the required initial data is loaded
    combineLatest([
      this.license$,
      this.license$.pipe(
        switchMap(license => license ? this.licenseStorageService.getItemById$(this.license.Id) : of(null))
      )
    ]).pipe(
      first()
    ).subscribe(() => {
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'There was a problem loading the license settings.', this.errorService.getErrorMessages(error));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.license) {
      this.resetForm(this.license || {} as License);
    }
  }

  onSubmit() {
    if (!this.licenseSettingsForm.valid) {
      return;
    }

    this.savingState.update(true);

    let request$: Observable<MadCloudResult>;
    const newLicenseSettings: LicenseSettings = {
      KeyLabel: this.licenseSettingsForm.get('keyLabel').value,
      VanityBaseUrl: this.licenseSettingsForm.get('vanityBaseUrl').value
    };
    const avatarValue: ImagePickerValue = this.licenseSettingsForm.value['avatar'];

    if (this.showAdminFields) {
      request$ = this.licensesService.changeAdminLicenseSettings$(this.license.Id, {
        ...newLicenseSettings,
        MaxStorageSize: this.licenseSettingsForm.get('maxStorage').value * this.licenseSettingsForm.get('bytes').value,
        AuthorSeats: this.licenseSettingsForm.get('maxAuthorSeats').value,
        SmeSeats: this.licenseSettingsForm.get('maxSmeSeats').value
      }, avatarValue ? avatarValue.file : null);
    } else {
      request$ = this.licensesService.changeLicenseSettings$(this.license.Id, newLicenseSettings, avatarValue ? avatarValue.file : null);
    }

    this.changingVanity = newLicenseSettings.VanityBaseUrl !== this.license?.VanityBaseUrl;

    // Submit the settings
    request$.subscribe(() => {
      // Only restore the form and emit saved when not changing the vanity. OR if the user is a sys admin since that means they are using portal-admin.
      // We don't want any extra HTTP requests going out if the vanity was changed because they could result in a 403 sending the user to the alert page.
      // Keep the loader up while redirecting to the new url.

      if (this.redirectOnVanityChange && this.changingVanity) {
        // If the vanity changed then the user needs to be sent to the new subdomain
        this.window.location.href = this.authUrlService.buildSubdomainUrl(newLicenseSettings.VanityBaseUrl);
      } else {
        this.savingState.update(false);
        this.licenseSettingsForm.markAsPristine();
        this.saved.emit();
      }
    }, error => {
      this.savingState.update(false, 'Unable to update the license settings.', this.errorService.getErrorMessages(error));
    });
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  private buildForm() {
    this.licenseSettingsForm = this.formBuilder.group({
      avatar: new UntypedFormControl(null, this.formsService.createFileSizeValidator(MaxAvatarImageFileSizeBytes)),
      keyLabel: new UntypedFormControl(null, Validators.required),
      vanityBaseUrl: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[A-Za-z0-9-]+$'), Validators.maxLength(128)])
    });
  }

  private resetForm(license: License) {
    this.licenseSettingsForm.reset({
      avatar: license.Avatar ? this.formsService.buildImagePickerValue(license.Avatar) : null,
      keyLabel: license.KeyLabel ?? '',
      vanityBaseUrl: license.VanityBaseUrl ?? '',
      maxAuthorSeats: license.AuthorSeats ?? 0,
      maxSmeSeats: license.SmeSeats ?? 0,
      maxStorage: (license.MaxStorageSize / SizeBytes.Gigabyte) ?? 0,
      bytes: SizeBytes.Gigabyte
    });
  }

  private rebuildForm(showAdminFields: boolean, license: License, licenseStorage: LicenseStorage) {
    if (showAdminFields) {
      this.licenseSettingsForm.addControl('maxAuthorSeats', new UntypedFormControl(license.AuthorSeats ?? 0, [Validators.required, Validators.min(licenseStorage.UsedAuthorSeats), Validators.max(10000)]));
      this.licenseSettingsForm.addControl('maxSmeSeats', new UntypedFormControl(license.SmeSeats ?? 0, [Validators.required, Validators.min(licenseStorage.UsedSmeSeats), Validators.max(10000)]));
      this.licenseSettingsForm.addControl('maxStorage', new UntypedFormControl((license.MaxStorageSize / SizeBytes.Gigabyte) ?? 0, [Validators.required, this.formsService.createStorageSizeValidator('bytes', licenseStorage.UsedStorageSpace)]));
      this.licenseSettingsForm.addControl('bytes', new UntypedFormControl(SizeBytes.Gigabyte));
    } else {
      this.licenseSettingsForm.removeControl('maxAuthorSeats');
      this.licenseSettingsForm.removeControl('maxSmeSeats');
      this.licenseSettingsForm.removeControl('maxStorage');
      this.licenseSettingsForm.removeControl('bytes');
    }
  }
}
