import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ProjectPageFilterConfig } from '@portal-core/projects/util/project-page-filter-config';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { of } from 'rxjs';

@Component({
  selector: 'mc-projects-select-list',
  templateUrl: './projects-select-list.component.html',
  styleUrls: ['./projects-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as ProjectsSelectListComponent so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => ProjectsSelectListComponent) }]
})
export class ProjectsSelectListComponent extends SelectListBase<Project> implements OnInit, OnChanges {
  /** The license id that the projects belong to. */
  @Input() licenseId: number;
  /** The project statuses to filter by. Defaults to showing only active projects. */
  @Input() projectStatuses?: ProjectStatus[] = [ProjectStatus.Active];
  /** The source of the project lists. Defaults to projects from the license. */
  @Input() type?: 'license' | 'review' = 'license';
  /** Whether or not to filter the projects to only show the projects a user is a member of. */
  @Input() userId?: string;

  filterConfig: ProjectPageFilterConfig = new ProjectPageFilterConfig(['Name', 'Initials']);

  listControl: SelectionListControl<Project> = new SelectionListControl<Project>(this.projectsService, filter => {
    if (typeof this.licenseId === 'number') {
      if (this.type === 'review') {
        return this.projectsService.getReviewProjectsPageByLicenseId$(this.licenseId, filter);
      } else {
        return this.projectsService.getProjectsPageByLicenseId$(this.licenseId, filter, 0);
      }
    } else {
      return of(null);
    }
  }, project => {
    return project.Id;
  });

  constructor(private projectsService: ProjectsService, private pageFilterService: PageFilterService) {
    super(projectsService);
  }

  ngOnInit() {
    this.applyFilter$('projects-select-list-project-statuses', this.pageFilterService.create({
      Id: 'projects-select-list-project-statuses',
      Type: PageFilterGroupType.Select
    }).select('Status', this.projectStatuses).value);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Because projectStatuses has a default value it needs to be set in ngOnInit which means we should ignore the first change so that the filter does not get applied twice
    if (changes.projectStatuses && !changes.projectStatuses.isFirstChange()) {
      this.applyFilter$('projects-select-list-project-statuses', this.pageFilterService.create({
        Id: 'projects-select-list-project-statuses',
        Type: PageFilterGroupType.Select
      }).select('Status', this.projectStatuses).value);
    }

    if (changes.userId) {
      this.applyFilter$('projects-select-list-my-projects', this.pageFilterService.create({
        Id: 'projects-select-list-my-projects',
        Type: PageFilterGroupType.Custom
      }).custom({
        FilterType: PageFilterType.Equals,
        PropertyName: 'UserId',
        PropertyType: PageDataType.ProjectUserHasAccess,
        PropertyValue: this.userId
      }).value);
    }

    if (changes.licenseId || changes.type) {
      this.hardReload();
    }
  }
}
