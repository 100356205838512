import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/*
 * @PropertyObservable()
 * Creates an observable property that emits a new value whenever its property changes values.
 * First declare a property on the class, then declare a @PropertyObservable property passing in the name of the first property.
 * This binds the @PropertyObservable property to the first property causing the @PropertyObservable property to emit a value whenever the first property's value changes.
 * @PropertyObservable can be used with any property including simple properties, properties with getters/setters, and @Input properties.
 *
 * Example:
 * name: string;
 * @PropertyObservable('name') name$: Observable<string>;
 *
 * @Input() reviewFile: ReviewFile;
 * @PropertyObservable('reviewFile') reviewFile$: Observable<ReviewFile>;
 */
export function PropertyObservable(propertyName: string, useDistinctUntilChanged: boolean = true): PropertyDecorator {
  return (target: Object, propertyKey: string) => {
    // The property keys for the new "private" properties on the object instance
    const decoratorKey = 'PropertyObservable';
    const propertyValueKey = `mc${decoratorKey}${propertyName}Value`;
    const subjectKey = `mc${decoratorKey}${propertyKey}Subject`;
    const observableKey = `mc${decoratorKey}${propertyKey}Observable`;

    // Makes sure the properties for the subject and observable exist on the object instance
    function ensureSubjectExists() {
      if (!this[subjectKey]) {
        this[subjectKey] = new ReplaySubject(1);
        this[subjectKey].next(this[propertyName]);
        this[observableKey] = this[subjectKey].asObservable();

        if (useDistinctUntilChanged) {
          this[observableKey] = this[observableKey].pipe(
            distinctUntilChanged()
          );
        }
      }
    }

    // Change the property being observed into a getter/setter property in order to "listen" in on the value changing
    const originalPropertyDescriptor = Object.getOwnPropertyDescriptor(target, propertyName);
    // Copy over the initial value so it is not lost
    target[propertyValueKey] = target[propertyName];
    // Remove the existing property
    delete target[propertyName];
    // Define a new property
    Object.defineProperty(target, propertyName, {
      get() {
        // Call the original get function if it exists
        return originalPropertyDescriptor && originalPropertyDescriptor.get ? originalPropertyDescriptor.get.call(this) : this[propertyValueKey];
      },
      set(value: any) {
        // Call the original set function if it exists
        if (originalPropertyDescriptor && originalPropertyDescriptor.set) {
          originalPropertyDescriptor.set.call(this, value);
        } else {
          this[propertyValueKey] = value;
        }

        ensureSubjectExists.call(this);
        this[subjectKey].next(value);
      }
    });

    // Change the @PropertyObservable property into a getter property
    delete target[propertyKey];
    Object.defineProperty(target, propertyKey, {
      get() {
        ensureSubjectExists.call(this);
        return this[observableKey];
      }
    });
  };
}
