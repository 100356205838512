import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcLicenseAsync'
})
export class LicenseAsyncPipe implements PipeTransform {
  constructor(private licensesService: LicensesService) { }

  transform(licenseId: number | null | undefined, allowApiRequest: boolean = false): Observable<ReviewFile | null> {
    if (!licenseId) {
      return of(null);
    }

    return this.licensesService.getItemById$(licenseId, { allowApiRequest });
  }
}
