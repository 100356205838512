import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { ReviewFileUser } from '@common/reviews/models/review-file-user.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewFileUsersApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getReviewFileUserById$(reviewFileUserId: number): Observable<ReviewFileUser> {
    return this.http.get<ReviewFileUser>(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileUserId}/User`);
  }

  @SharePendingRequest()
  getMyReviewFileUser$(reviewFileId: number): Observable<ReviewFileUser> {
    return this.http.get<ReviewFileUser>(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileId}/Me`);
  }

  @SharePendingRequest()
  getReviewFileUsersPage$(reviewFileId: number, filter?: PageFilter): Observable<Page<ReviewFileUser>> {
    return this.http.post<Page<ReviewFileUser>>(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileId}/Reviewers`, filter);
  }

  updateMyReviewFileUserStatus$(reviewFileId: number, status: ReviewFileUserStatus): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/Reviews/${reviewFileId}/ReviewerStatus/${status}`, null);
  }
}
