import { Pipe, PipeTransform } from '@angular/core';
import { normalizeFlareXML } from '@common/flare/util/normalize';

/**
 * Normalizes markup to be Flare XML compliant by wrapping the content in an html and body tag if they are not present.
 * And prepends the markup with an XML declaration if it is not there.
 *
 * Usage:
 *   `string | mcNormalizeFlareXML`
 *
 * Example:
 * ```
 * '<p>Hello! How can I assist you today?</p>' | mcNormalizeFlareXML
 * ```
 *
 * Returns
 * ```
 * <?xml version="1.0" encoding="utf-8"?><html xmlns:MadCap="http://www.madcapsoftware.com/Schemas/MadCap.xsd"><body><p>Hello! How can I assist you today?</p></body></html>
 * ```
*/
@Pipe({
  name: 'mcNormalizeFlareXML'
})
export class NormalizeFlareXMLPipe implements PipeTransform {
  transform(content: string): string {
    return normalizeFlareXML(content);
  }
}
