import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { ReviewFileListComponent } from './review-file-list.component';

@NgModule({
  declarations: [ReviewFileListComponent],
  exports: [ReviewFileListComponent],
  imports: [
    CommonModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    ReviewFileAvatarModule
  ]
})
export class ReviewFileListModule { }
