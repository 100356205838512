import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { propertyValue } from '@common/util/properties';
import { ApiService } from '@portal-core/auth/services/api.service';
import { CommittedItem } from '@portal-core/project-files/models/committed-item.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaDataApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getMetaData$(groupNames: string[], committedItem: CommittedItem): Observable<MetaDataValues> {
    return this.http.post<any>(`${this.apiService.collabAuthorityUrl}/api/metaData/get`, {
      commitId: propertyValue(committedItem, 'commitId'),
      fileId: propertyValue(committedItem, 'fileId'),
      fileType: propertyValue(committedItem, 'fileType'),
      projectId: propertyValue(committedItem, 'projectId'),
      groupNames
    });
  }

}
