import { HttpErrorResponse } from '@common/http/models/http-error-response.model';
import { MadCloudError } from '@common/http/models/mad-cloud-error.model';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { WrappedError } from '@common/http/models/wrapped-error.model';

export function instanceOfHttpErrorResponse(obj: any): obj is HttpErrorResponse {
  return obj && typeof obj === 'object' && ('error' in obj || 'statusText' in obj);
}

export function instanceOfMadCloudResult<T = any>(obj: any): obj is MadCloudResult<T> {
  return obj && typeof obj === 'object' && 'Succeeded' in obj;
}

export function instanceOfMadCloudError(obj: any): obj is MadCloudError {
  return obj && typeof obj === 'object' && 'Message' in obj;
}

export function instanceOfWrappedError(obj: any): obj is WrappedError {
  return obj && typeof obj === 'object' && 'error' in obj && obj.error instanceof Error;
}
