import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LanguagesState } from '@portal-core/languages/services/languages-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LanguagesState])
  ]
})
export class LanguagesServiceModule { }
