import { FocusMonitor } from '@angular/cdk/a11y';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FoundNodeInfo, createNodeBuckets } from '@common/prosemirror/model/node';
import { DocumentBucket } from '@portal-core/project-files/enums/document-bucket.enum';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { ProseMirrorNode } from 'prosemirror-model';

export interface DocumentBucketNode extends FoundNodeInfo {
  name?: string;
  children?: DocumentBucketNode[];
  topOfTheDocument?: boolean;
  value?: DocumentBucketNode;
}

@Component({
  selector: 'mc-document-node-selector-input',
  templateUrl: './document-node-selector-input.component.html',
  styleUrls: ['./document-node-selector-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-document-node-selector-input-disabled]': 'disabled',
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DocumentNodeSelectorInputComponent }
  ]
})

export class DocumentNodeSelectorInputComponent extends CustomInputBase<DocumentBucketNode> implements OnInit {

  /** The height of the popup in pixels. Defaults to 200 pixels **/
  @Input() popupHeight?: number | 'width' = 200;
  /** The node to create the document buckets with  **/
  @Input() document: ProseMirrorNode;
  /** A reference to the popup component **/
  @ViewChild('popup', { static: true }) popup: PopupComponent;
  @ViewChild('trigger', { static: true }) triggerElementRef: ElementRef<HTMLElement>;

  /** mat tree required functions **/
  treeControl = new NestedTreeControl<Partial<DocumentBucketNode>>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Partial<DocumentBucketNode>>();
  hasChild = (_: number, node: Partial<DocumentBucketNode>) => !!node.children && node.children.length > 0;

  /** Whether the file picker has no value. Required by MatFormFieldControl **/
  get empty(): boolean {
    return !this.value;
  }

  /** Required by CustomInputBase **/
  readonly controlType: string = 'mc-document-node-selector-input';

  /** Required by MatFormFieldControl **/
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || this.popup?.opened;
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  ngOnInit() {
    const buckets = createNodeBuckets(this.document, node => {
      // if a bookmark we dont care if it has textContent, if its not has to have textContent and a linkBucket
      // or needs to be a body tag because that's how to get the Top of The Document later on
      return (!node.textContent && node.type.name === 'madcapbookmark') || (node.textContent && this.checkIfTextBlockExists(node) && (node.type.name === 'list_item' || node.type.spec.linkBucket) || node.type.name === 'body');
    }, (node, parent, pos) => {
      const currentParentBucket = parent.type.spec.linkBucket;
      const currentParentChildBucket = parent.type.spec.childLinkBucket;
      const currentNodeBucket = node.type.spec.linkBucket;

      // grab the body tag to get the Top Of The Document Node later
      if (node.type.name === 'body') {
        return 'body';
      }
      // p elements under any of li, ul, ol, td, dropdown, or expanding text belong in those sections
      if (currentNodeBucket === 'paragraph') {

        // paragraph acting normally cases
        if (currentParentBucket === 'heading' || currentParentBucket === 'bookmark' || currentParentBucket === 'micro_content') {
          return currentNodeBucket;
        }
        //list item, grab the grandparent
        else if (parent.type.name === 'list_item') {
          const resolvedPos = this.document.resolve(pos);
          const grandparent = resolvedPos.node(resolvedPos.depth - 1);
          return grandparent.type.spec.childLinkBucket;
        }

        else if (currentParentBucket)
          return currentParentBucket;
      }
      return currentParentChildBucket || currentNodeBucket;
    });

    this.dataSource.data = this.createTreeData(buckets);

    // have bookmark bucket open if the link we editing is not in the top of the document.
    if (this.value && !this.value.topOfTheDocument) {
      this.treeControl.expand(this.dataSource.data[1]);
    }
  }

  private createTreeData(buckets: Dictionary<FoundNodeInfo[]>): Partial<DocumentBucketNode>[] {
    const data: Partial<DocumentBucketNode>[] = [];

    // make the buckets be alphabetical
    const sortedEntries = Object.entries(buckets).sort((bucketA, bucketB) => bucketA[0].localeCompare(bucketB[0]));

    for (const [name, nodes] of sortedEntries) {
      let bucketName = '';
      let topOfTreeInfo;
      switch (name) {
        case DocumentBucket.Headings:
          bucketName = 'Headings';
          break;

        case DocumentBucket.Paragraphs:
          bucketName = 'Paragraphs';
          break;

        case DocumentBucket.DefinitionListItems:
          bucketName = 'Definition List Items';
          break;

        case DocumentBucket.UnorderedListItems:
          bucketName = 'Unordered List Items';
          break;

        case DocumentBucket.OrderedListItems:
          bucketName = 'Ordered List Items';
          break;

        case DocumentBucket.Bookmarks:
          bucketName = 'Bookmarks';
          break;

        case DocumentBucket.Dropdowns:
          bucketName = 'Dropdowns';
          break;

        case DocumentBucket.ExpandingText:
          bucketName = 'Expanding Text';
          break;

        case DocumentBucket.MicroContent:
          bucketName = 'Micro Content';
          break;
        case DocumentBucket.TableContent:
          bucketName = 'Table Content';
          break;
        // this is for Top Of The Document
        case DocumentBucket.Body:
          bucketName = 'Top Of The Document';
          const bodyNodeInfo = nodes[0];
          const firstChildNode = bodyNodeInfo.node.firstChild;
          topOfTreeInfo = { parent: bodyNodeInfo.node, node: firstChildNode, pos: bodyNodeInfo.pos + 1, topOfTheDocument: true };
          break;
        default:
          bucketName = 'Unknown';
          break;
      }

      let treeNode = topOfTreeInfo ? { name: bucketName, value: topOfTreeInfo } : { name: bucketName, children: nodes };


      if (treeNode.name !== 'Unknown') {
        data.push(treeNode);
      }
    }
    return data;
  }

  /** Handles the document node selected event to update the node selector input value. */
  onNodeSelected(bucketNode: DocumentBucketNode) {
    this.valueChanged(bucketNode.value ?? bucketNode);
    this.popup.close();
  }

  private focus() {
    this.triggerElementRef.nativeElement.focus();
  }

  private open() {
    if (!this.disabled) {
      this.popup.open();
    }
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return 'Node';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return this.triggerElementRef;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent): void {
    this.focus();
    this.open();
  }

  onFileChipRemoved() {
    this.valueChanged(null);
    this.focus();
  }

  valueChanged(value: DocumentBucketNode) {
    this.value = value;
    this.onChange(value); // So Angular Forms knows this countrol's value has changed
    this.onTouched(); // So Angular Forms knows this control has been touched
  }

  private checkIfTextBlockExists(parent: ProseMirrorNode): boolean {
    let textBlockFound = false;
    parent.forEach(child => textBlockFound ? true : textBlockFound = child.isText || (child.type.name === 'mcCentralContainer' && child.isTextblock) ? true : false);
    return textBlockFound;
  }
}
