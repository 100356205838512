import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input } from '@angular/core';

/**
 * A directive for preventing the default behavior of events on an element.
 * The `mcPreventDefault` directive prevents the default behavior of any number of events on an element.
 * `mcPreventDefault` can take an event name string or an array of event name strings as input.
 */
@Directive({
  selector: '[mcPreventDefault]'
})
export class PreventDefaultDirective implements OnInit, OnDestroy {
  @Input('mcPreventDefault') events: string | string[];
  eventUnlisteners: Function[];

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    let events = this.events;

    if (typeof events === 'string') {
      events = [events];
    }

    if (Array.isArray(events)) {
      this.eventUnlisteners = events.map(event => this.renderer.listen(this.element.nativeElement, event, this.onEvent));
    }
  }

  ngOnDestroy() {
    if (this.eventUnlisteners) {
      this.eventUnlisteners.forEach(unlistener => unlistener());
      this.eventUnlisteners = null;
    }
  }

  onEvent(event) {
    event.preventDefault();
  }
}
