import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { ProjectVariableDefinitionView } from '@portal-core/project-files/variables/models/project-variable-definition-view.model';
import { ProjectVariableSetView } from '@portal-core/project-files/variables/models/project-variable-set-view.model';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { get } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, map, tap } from 'rxjs';

@Component({
  selector: 'mc-variables-grid',
  templateUrl: './variables-grid.component.html',
  styleUrls: ['./variables-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariablesGridComponent implements OnInit, OnChanges {
  @Input() variableSetView: ProjectVariableSetView;
  @Input() initialVariableName?: string;
  @Input() multiple?: boolean = false;


  @InputObservable('variableSetView') variableSetView$: Observable<ProjectVariableSetView>;

  @Output() variableSelect = new EventEmitter<ProjectVariableDefinitionView | null>();
  @Output() setOneVariableInSet = new EventEmitter<ProjectVariableDefinitionView>();

  columns: GridColumn[];
  defaultSort: Sort;
  items$: Observable<ProjectVariableDefinitionView[]>;
  itemCount$: Observable<number>;
  pageIndex: number = 0;
  pageSize: number = 25;
  selectedVariable: ProjectVariableDefinitionView | null = null;
  visibleColumns: string[];

  private pageItemsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private sortGridSubject: BehaviorSubject<Sort | null>;

  TriSelectedState: typeof TriSelectedState = TriSelectedState;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.defineColumns();
    this.defaultSort = { active: this.columns[1].name, direction: 'asc' };
    this.sortGridSubject = new BehaviorSubject(this.defaultSort);
    this.setItems();
    this.selectInitial();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variableSet) {
      this.selectVariable(null);
    }
  }

  onVariableSelected(variable: ProjectVariableDefinitionView, checkboxChange: MatCheckboxChange = null) {

    if (this.multiple) {
      this.setOneVariableInSet.emit(variable);
    } else {
      let newSelection = this.selectedVariable?.Data.LongName !== variable.Data.LongName
        ? variable
        // Unselect selected variable
        : null;

      this.selectVariable(newSelection);
    }

  }

  onPageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    // Trigger the items to be updated for the new page index/size
    this.pageItemsSubject.next();
  }

  onSortChange(sort: Sort) {
    this.sortGridSubject.next(sort);
  }

  private defineColumns() {
    this.columns = [
      {
        name: 'Select',
        title: '',
        type: PageDataType.Select
      },
      {
        name: 'Name',
        title: 'Name',
        sortEnabled: true,
        disableClear: true,
        type: PageDataType.String
      },
      {
        name: 'Data.Definition',
        title: 'Definition',
        sortEnabled: true,
        disableClear: true,
        type: PageDataType.String
      },
      {
        name: 'Data.Comment',
        title: 'Comment',
        sortEnabled: true,
        disableClear: true,
        type: PageDataType.String
      }
    ];

    this.visibleColumns = this.columns.map(column => column.name);
  }

  private selectVariable(variableDefinition: ProjectVariableDefinitionView | null) {
    this.selectedVariable = variableDefinition;
    this.variableSelect.emit(this.selectedVariable);
  }

  private selectInitial() {
    if (!this.initialVariableName) {
      return;
    }

    const variableDefinition = this.variableSetView.Definitions.find(
      variableDefinition => variableDefinition.Data.Name === this.initialVariableName
    );

    if (variableDefinition) {
      this.selectVariable(variableDefinition);
    }
  }

  private setItems() {
    this.itemCount$ = this.variableSetView$.pipe(
      map(variableSet => variableSet?.Definitions.length)
    );

    // Create an observable that will emit the variable sets definitions but more importantly resets the page index when the variable set changes.
    // This is done in conjunction with the variable definitions to ensure the page index is updated before items$ builds the page.
    const definitions$ = this.variableSetView$.pipe(
      map(variableSet => variableSet?.Definitions),
      tap(() => {
        // Reset the page index when the variable set changes
        this.pageIndex = 0;
        // Make sure the grid's paginator updates to the new page index
        this.cdr.markForCheck();
      })
    );

    // Create an observable of all the items sorted
    const sortedItems$ = combineLatest([this.sortGridSubject.asObservable(), definitions$]).pipe(
      map(([sort, definitions]) => {
        return [
          ...definitions?.sort((left: ProjectVariableDefinitionView, right: ProjectVariableDefinitionView) => {
            if (!sort) {
              return 0;
            }

            const multiplier = sort.direction === 'asc' ? -1 : 1;
            // have all comparisionFields start with a 'Data.'
            const comparisonField = sort.active.indexOf('Data.') > -1 ? sort.active : 'Data.' + sort.active;

            return get(left, comparisonField)?.toString().toUpperCase() < get(right, comparisonField)?.toString().toUpperCase()
              ? 1 * multiplier
              : -1 * multiplier;
          })
        ];
      })
    );

    // Create an observable of the items for the current page
    this.items$ = combineLatest([
      sortedItems$,
      this.pageItemsSubject.asObservable()
    ]).pipe(
      map(([items]) => {
        const start = this.pageIndex * this.pageSize;
        return items.slice(start, start + this.pageSize);
      })
    );
  }

  setAllForCurrentSet(checked: boolean) {
    this.variableSetView.TotalSelected = checked ? this.variableSetView.Definitions.length : 0;
    this.variableSetView.Definitions.forEach(definition => definition.Checked = checked);
    this.cdr.markForCheck();
  }

}
