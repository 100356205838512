import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TargetAsyncPipe } from './target-async.pipe';

@NgModule({
  declarations: [TargetAsyncPipe],
  imports: [CommonModule],
  exports: [TargetAsyncPipe],
  providers: [TargetAsyncPipe]
})
export class TargetAsyncModule {}
