import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { FilterBuilder } from '@common/paged-data/util/filter-builder';
import { Action, StateContext } from '@ngxs/store';
import { DataList } from '@portal-core/data/collection/models/data-list.model';
import { GridPage, GridState } from '@portal-core/data/collection/models/grid-state.model';
import { PagedDataList } from '@portal-core/data/collection/models/paged-data-list.model';
import { AddDataListPage, AddGridPage, AddItems, AddItemsToListById, ClearGrid, ClearGridFilters, ClearLists, ClearPagedDataList, DeleteItems, RemoveItems, Reset, ResetGrid, ResetPagedDataList, SetGridFilterByName, SetGridOrder, SetGridPageSize, SetGridVisibleColumns, SetListById, SetPagedDataListFilterByName, SetPagedDataListOrder } from '@portal-core/data/collection/types/collection-state.type';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { forEach, isEmpty, keyBy, mapValues, omit, omitBy, union } from 'lodash';

// Return true if newObj has property values that differ from obj
// function hasNewValues(obj: object, newObj: object): boolean {
//   if (!obj && newObj || obj && !newObj) {
//     return true;
//   }

//   // tslint:disable-next-line:forin
//   for (const key in newObj) {
//     const objValue = obj[key];
//     const newValue = newObj[key];

//     if (isPlainObject(objValue) && isPlainObject(newValue)) {
//       if (hasNewValues(objValue, newValue)) {
//         return true;
//       }
//     } else {
//       if (objValue !== newValue) {
//         return true;
//       }
//     }
//   }

//   return false;
// }

export abstract class CollectionStateBase {
  static source: string;
  static defaultItems: any[];

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  // these method stubs are populated by the inherited class' CollectionStateSelectors decorator
  //  this is needed to allow for the various @Selector methods to specify the state model to load in
  //  the undefined methods below are defined within that decorator
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  static itemsSelector(): Dictionary { return undefined; }
  static itemByIdSelector(): (itemId: ModelId) => any { return undefined; }
  static itemByPropertiesSelector(): (...args) => any { return undefined; }
  static itemsByPropertiesSelector(): (...args) => any { return undefined; }
  static listsSelector(): Dictionary<Dictionary<DataList>> { return undefined; }
  static listByIdSelector(): (listName: string, listId: ModelId) => DataList { return undefined; }
  static pagedDataListItems(): (pagedDataListId: string) => any[] { return undefined; }
  static pagedDataListLength(): (pagedDataListId: string) => number { return undefined; }
  static pagedDataListPageIndex(): (pagedDataListId: string) => number { return undefined; }
  static pagedDataListTotal(): (pagedDataListId: string) => number { return undefined; }
  static pagedDataListAllPagesLoaded(): (pagedDataListId: string) => boolean { return undefined; }
  static pagedDataListFilters(): (pagedDataListId: string) => Dictionary<PageFilterGroup> { return undefined; }
  static pagedDataListFilterByName(): (pagedDataListId: string, name: string) => PageFilterGroup { return undefined; }
  static pagedDataListOrder(): (pagedDataListId: string) => PageSort { return undefined; }
  static gridItems(): (gridId: string, pageIndex: number) => any[] { return undefined; }
  static gridCursor(): (gridId: string, pageIndex: number) => number { return undefined; }
  static gridPage(): (gridId: string, pageIndex: number) => GridPage { return undefined; }
  static gridTotal(): (gridId: string) => number { return undefined; }
  static gridLimitedTotal(): (gridId: string) => number { return undefined; }
  static gridFilters(): (gridId: string) => Dictionary<PageFilterGroup> { return undefined; }
  static gridFilterByName(): (gridId: string, name: string) => PageFilterGroup { return undefined; }
  static gridPageSize(): (gridId: string) => number { return undefined; }
  static gridOrder(): (gridId: string) => PageSort { return undefined; }
  static gridVisibleColumns(): (gridId: string) => string[] { return undefined; }

  grids: Dictionary<GridState>;
  items: Dictionary;
  lists: Dictionary<Dictionary<DataList>>;
  pagedLists: Dictionary<PagedDataList>;

  private buildAddItemsState(ctx: StateContext<CollectionStateBase>, action: AddItems): Partial<CollectionStateBase> {
    const existingItems = ctx.getState().items;
    const items = action.payload.items;
    const now = new Date();
    const state = this.getAddItemsState(ctx, action);

    const updatedItems = mapValues(items, (item, itemId) => {
      // Always create a new object so that changes are detected. Update the mcCachedAt value
      return Object.assign({}, existingItems ? existingItems[itemId] : null, item, { mcCachedAt: now });
    });

    return {
      ...state,
      items: {
        ...existingItems,
        // Merge the new item with the existing items
        ...updatedItems
      }
    };
  }

  @Action(AddItems)
  addItems(ctx: StateContext<CollectionStateBase>, action: AddItems) {
    if (action.source !== this.getSource()) {
      return;
    }
    const existingItems = ctx.getState().items;
    const items = action.payload.items;
    const now = new Date();
    const state = this.getAddItemsState(ctx, action);

    // Add the old items to the action
    action.oldItems = mapValues(items, (item, itemId) => existingItems ? existingItems[itemId] : undefined);

    const updatedItems = mapValues(items, (item, itemId) => {
      // Always create a new object so that changes are detected. Update the mcCachedAt value
      return Object.assign({}, existingItems ? existingItems[itemId] : null, item, { mcCachedAt: now });
    });

    ctx.patchState({
      ...state,
      items: {
        ...existingItems,
        // Merge the new item with the existing items
        ...updatedItems
      }
    });

    /*
     * The code below only updates the state if there are actual changes. The code base isn't quite ready for this change.
     * It requires all calls to addItems$/updateItems$ to not pass a copy of the models from the store.
     */
    // Gather the items that have actual changes
    // const updatedItems = {};
    // forEach(items, (item, itemId) => {
    //   // If the new item is different from the existing item
    //   if (!existingItems || hasNewValues(existingItems[itemId], item)) {
    //     // Always create a new object so that changes are detected. Update the mcCachedAt value
    //     updatedItems[itemId] = Object.assign({}, existingItems ? existingItems[itemId] : null, item, { mcCachedAt: now });
    //   }
    // });

    // // If there are updates then patch the state
    // if (state || Object.keys(updatedItems).length > 0) {
    //   ctx.patchState({
    //     ...state,
    //     items: {
    //       ...existingItems,
    //       // Merge the new item with the existing items
    //       ...updatedItems
    //     }
    //   });
    // }
  }

  @Action([RemoveItems, DeleteItems])
  removeOrDeleteItems(ctx: StateContext<CollectionStateBase>, action: RemoveItems | DeleteItems) {
    if (action.source !== this.getSource()) {
      return;
    }
    const existingItems = ctx.getState().items;
    const itemIds = action.payload.itemIds;

    if (!existingItems || !itemIds) {
      return;
    }

    // Make a new copy of the existing items so as not to directly modify the value in the store
    const newItems = {
      ...existingItems
    };

    // Delete the items from the collection
    itemIds.forEach(itemId => delete newItems[itemId]);

    const state = action instanceof RemoveItems ? this.getRemoveItemsState(ctx, action) : this.getDeleteItemsState(ctx, action)

    ctx.patchState({
      ...state,
      items: {
        ...newItems
      }
    });
  }

  @Action(SetListById)
  setListById(ctx: StateContext<CollectionStateBase>, action: SetListById) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = this.buildAddItemsState(ctx, {
      source: action.source,
      payload: {
        ...action.payload,
        items: keyBy(action.payload.items, 'Id')
      }
    });
    const lists = ctx.getState().lists;
    const newList: DataList = {
      Id: action.payload.listId,
      Items: Array.isArray(action.payload.items) ? action.payload.items.map(item => item.Id) : [],
      mcCachedAt: new Date()
    };

    ctx.patchState({
      ...state,
      lists: {
        ...lists,
        [action.payload.listName]: {
          ...(lists ? lists[action.payload.listName] : null),
          [newList.Id]: newList
        }
      }
    });
  }

  @Action(AddItemsToListById)
  AddItemsToListById(ctx: StateContext<CollectionStateBase>, action: AddItemsToListById) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = this.buildAddItemsState(ctx, {
      source: action.source,
      payload: {
        ...action.payload,
        items: keyBy(action.payload.items, 'Id')
      }
    });

    const lists = ctx.getState().lists;
    const listsByName = lists ? lists[action.payload.listName] : null;
    const existingList = listsByName ? listsByName[action.payload.listId] : null;

    const newList: DataList = {
      Id: action.payload.listId,
      Items: union(existingList ? existingList.Items : [], action.payload.items.map(item => item.Id)), // use union to remove duplicates
      mcCachedAt: new Date()
    };

    ctx.patchState({
      ...state,
      lists: {
        ...lists,
        [action.payload.listName]: {
          ...(lists ? lists[action.payload.listName] : null),
          [newList.Id]: newList
        }
      }
    });
  }

  @Action(ClearLists)
  clearLists(ctx: StateContext<CollectionStateBase>, action: ClearLists) {
    if (action.source !== this.getSource()) {
      return;
    }

    ctx.patchState({
      lists: null
    });
  }

  @Action(Reset)
  reset(ctx: StateContext<CollectionStateBase>, action: Reset) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = this.getResetState(ctx, action);

    let items = null;

    const defaultItems = this.getDefaultItems();
    if (Array.isArray(defaultItems)) {
      const now = new Date();

      items = keyBy(defaultItems.map(item => {
        // Add the mcCachedAt value
        return {
          ...item,
          mcCachedAt: now
        };
      }), 'Id');
    }

    ctx.patchState({
      ...state,
      items,
      lists: null
    });
  }

  /*
   * Paged Data Lists
   */
  @Action(SetPagedDataListFilterByName)
  setPagedDataListFilterByName(ctx: StateContext<CollectionStateBase>, action: SetPagedDataListFilterByName) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const dataListId = action.payload.dataListId;
    const pagedLists = state.pagedLists;
    const pagedDataList = (pagedLists ? pagedLists[dataListId] : null) || {} as PagedDataList;

    // Remove filters that are set to a falsey value (primarily for null and undefined)
    const filters = omitBy({
      ...(pagedDataList.config ? pagedDataList.config.filters : undefined),
      [action.payload.name]: action.payload.value
    }, filter => !filter);

    ctx.patchState({
      pagedLists: {
        ...pagedLists,
        // Update the paged data list
        [dataListId]: {
          ...pagedDataList,
          config: {
            ...pagedDataList.config,
            // Only set the filters to an object if its not empty so that an empty object isn't stored in local storage
            filters: isEmpty(filters) ? undefined : filters
          }
        }
      }
    });
  }

  @Action(SetPagedDataListOrder)
  setPagedDataListOrder(ctx: StateContext<CollectionStateBase>, action: SetPagedDataListOrder) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const dataListId = action.payload.dataListId;
    const pagedLists = state.pagedLists;
    const pagedDataList = state.pagedLists ? state.pagedLists[dataListId] : null;

    let order: PageSort;
    // Only set the order if both the by and dir are set
    if (action.payload.order && action.payload.order.by && action.payload.order.dir) {
      order = action.payload.order;
    }

    ctx.patchState({
      pagedLists: {
        ...pagedLists,
        // Update the paged data list
        [dataListId]: {
          ...pagedDataList,
          config: {
            ...pagedDataList.config,
            order
          }
        }
      }
    });
  }

  @Action(AddDataListPage)
  addDataListPage(ctx: StateContext<CollectionStateBase>, action: AddDataListPage) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const dataListId = action.payload.dataListId;
    const page = action.payload.page;
    const existingItems = state.items;
    const items = page.Items;
    const pagedLists = state.pagedLists;
    const pagedDataList = (pagedLists ? pagedLists[dataListId] : null) || {} as PagedDataList;
    const newPagedDataList = {} as PagedDataList;

    // If this page has already been loaded then ignore this request
    if (page.Page <= pagedDataList.pageIndex) {
      return;
    }

    // Build the paged data list
    newPagedDataList.itemIds = (pagedDataList.itemIds || []).concat(items.map(item => item.Id));
    newPagedDataList.allPagesLoaded = page.AllPagesLoaded;
    newPagedDataList.pageIndex = page.Page;
    newPagedDataList.total = page.Total;

    // Add the old items to the action
    action.oldItems = items.reduce((oldItems, item) => {
      oldItems[item.Id] = existingItems?.[item.Id];
      return oldItems;
    }, {});

    // Build the new items for the collection
    const now = new Date();

    const updatedItems = keyBy(items.map(item => {
      // Always create a new object so that changes are detected. Update the mcCachedAt value
      return Object.assign({}, existingItems ? existingItems[item.Id] : null, item, { mcCachedAt: now });
    }), 'Id');

    // Handle any invalid property names
    if (pagedDataList && pagedDataList.config && Array.isArray(page.PropertyNameErrors) && page.PropertyNameErrors.length > 0) {
      // Copy over the grid's current config so it can be updated
      newPagedDataList.config = {
        ...pagedDataList.config,
        filters: pagedDataList.config.filters ? { ...pagedDataList.config.filters } : undefined,
        order: pagedDataList.config.order ? { ...pagedDataList.config.order } : undefined
      };

      // Remove all the invalid property names from the filters
      if (pagedDataList.config.filters) {
        // Remove any filter groups that contain the property name
        forEach(pagedDataList.config.filters, (filterGroup, name) => {
          const fb = new FilterBuilder(filterGroup);
          fb.remove(page.PropertyNameErrors);
          newPagedDataList.config.filters[name] = fb.value;
        });

        // And remove any filters with the same name as the property name
        newPagedDataList.config.filters = omit(newPagedDataList.config.filters, page.PropertyNameErrors);
      }

      // If the OrderBy property is an invalid property name then clear out the orderBy value
      if (pagedDataList.config.order && page.PropertyNameErrors.includes(pagedDataList.config.order.by)) {
        newPagedDataList.config.order = undefined;
      }
    }

    ctx.patchState({
      items: {
        ...existingItems,
        // Merge the new items with the existing items
        ...updatedItems
      },
      pagedLists: {
        ...pagedLists,
        // Update the paged data list
        [dataListId]: {
          ...pagedDataList,
          ...newPagedDataList
        }
      }
    });
  }

  @Action(ResetPagedDataList)
  resetPagedDataList(ctx: StateContext<CollectionStateBase>, action: ResetPagedDataList) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();

    ctx.patchState({
      pagedLists: {
        ...state.pagedLists,
        [action.payload.dataListId]: undefined
      }
    });
  }

  @Action(ClearPagedDataList)
  clearPagedDataList(ctx: StateContext<CollectionStateBase>, action: ClearPagedDataList) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const dataListId = action.payload.dataListId;
    const pagedLists = state.pagedLists;
    const pagedDataList = pagedLists ? pagedLists[dataListId] : null;

    ctx.patchState({
      pagedLists: {
        ...pagedLists,
        [action.payload.dataListId]: {
          ...pagedDataList,
          allPagesLoaded: undefined,
          itemIds: undefined,
          pageIndex: undefined,
          total: undefined
        }
      }
    });
  }

  /*
   * Grids
   */
  @Action(SetGridFilterByName)
  setGridFilterByName(ctx: StateContext<CollectionStateBase>, action: SetGridFilterByName) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;

    // Remove filters that are set to a falsey value (primarily for null and undefined)
    const filters = omitBy({
      ...(grid.config ? grid.config.filters : undefined),
      [action.payload.name]: action.payload.value
    }, filter => !filter);

    ctx.patchState({
      grids: {
        ...grids,
        // Update the grid
        [gridId]: {
          ...grid,
          config: {
            ...grid.config,
            // Only set the filters to an object if its not empty so that an empty object isn't stored in local storage
            filters: isEmpty(filters) ? undefined : filters
          }
        }
      }
    });
  }

  @Action(ClearGridFilters)
  clearGridFilters(ctx: StateContext<CollectionStateBase>, action: SetGridFilterByName) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;

    ctx.patchState({
      grids: {
        ...grids,
        // Update the grid
        [gridId]: {
          ...grid,
          config: {
            ...grid.config,
            filters: undefined
          }
        }
      }
    });
  }

  @Action(SetGridPageSize)
  setGridPageSize(ctx: StateContext<CollectionStateBase>, action: SetGridPageSize) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;

    ctx.patchState({
      grids: {
        ...grids,
        // Update the grid
        [gridId]: {
          ...grid,
          config: {
            ...grid.config,
            pageSize: action.payload.pageSize
          }
        }
      }
    });
  }

  @Action(SetGridOrder)
  setGridOrder(ctx: StateContext<CollectionStateBase>, action: SetGridOrder) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;

    let order: PageSort;
    // Only set the order if both the by and dir are set
    if (action.payload.order && action.payload.order.by && action.payload.order.dir) {
      order = action.payload.order;
    }

    ctx.patchState({
      grids: {
        ...grids,
        // Update the grid
        [gridId]: {
          ...grid,
          config: {
            ...grid.config,
            order
          }
        }
      }
    });
  }

  @Action(SetGridVisibleColumns)
  setGridVisibleColumns(ctx: StateContext<CollectionStateBase>, action: SetGridVisibleColumns) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;

    ctx.patchState({
      grids: {
        ...grids,
        // Update the grid
        [gridId]: {
          ...grid,
          config: {
            ...grid.config,
            visibleColumns: action.payload.visibleColumns
          }
        }
      }
    });
  }

  @Action(AddGridPage)
  addGridPage(ctx: StateContext<CollectionStateBase>, action: AddGridPage) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const page = action.payload.page;
    const pageSize = action.payload.pageSize;
    const existingItems = state.items;
    const items = page.Items;
    const grids = state.grids;
    const grid = (grids ? grids[gridId] : null) || {} as GridState;
    const newGrid = {} as GridState;
    // Build the page in the grid state's pages
    newGrid.pages = {
      ...grid.pages,
      [page.Page]: {
        cursor: page.Cursor,
        index: page.Page,
        itemIds: page.Items.map(item => item.Id)
      }
    };
    newGrid.total = page.Total;
    newGrid.limitedTotal = page.LimitedTotal;

    // If the total number of items is unknown (-1) AND all the pages are now loaded (aka this is the last page) THEN we have all the information we need to know the total item count
    if (page.Total === -1 && page.AllPagesLoaded) {
      // Set the count based on the page index, page size, and this page's item count
      newGrid.total = (page.Page * pageSize) + page.Items.length;
    }

    // Add the old items to the action
    action.oldItems = items.reduce((oldItems, item) => {
      oldItems[item.Id] = existingItems?.[item.Id];
      return oldItems;
    }, {});

    // Build the new items for the collection
    const now = new Date();

    const updatedItems = keyBy(items.map(item => {
      // Always create a new object so that changes are detected. Update the mcCachedAt value
      return Object.assign({}, existingItems ? existingItems[item.Id] : null, item, { mcCachedAt: now });
    }), 'Id');

    // Handle any invalid property names
    if (grid && grid.config && Array.isArray(page.PropertyNameErrors) && page.PropertyNameErrors.length > 0) {
      // Copy over the grid's current config so it can be updated
      newGrid.config = {
        ...grid.config,
        filters: grid.config.filters ? { ...grid.config.filters } : undefined,
        order: grid.config.order ? { ...grid.config.order } : undefined
      };

      // Remove all the invalid property names from the filters
      if (grid.config.filters) {
        // Remove any filter groups that contain the property name
        forEach(grid.config.filters, (filterGroup, name) => {
          const fb = new FilterBuilder(filterGroup);
          fb.remove(page.PropertyNameErrors);
          newGrid.config.filters[name] = fb.value;
        });

        // And remove any filters with the same name as the property name
        newGrid.config.filters = omit(newGrid.config.filters, page.PropertyNameErrors);
      }

      // If the order by property is an invalid property name then clear out the sort order
      if (grid.config.order && page.PropertyNameErrors.includes(grid.config.order.by)) {
        newGrid.config.order = undefined;
      }
    }

    ctx.patchState({
      items: {
        ...existingItems,
        // Merge the new items with the existing items
        ...updatedItems
      },
      grids: {
        ...grids,
        // Update the paged data list
        [gridId]: {
          ...grid,
          ...newGrid
        }
      }
    });
  }

  @Action(ResetGrid)
  resetGrid(ctx: StateContext<CollectionStateBase>, action: ResetGrid) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;

    ctx.patchState({
      grids: {
        ...state.grids,
        [gridId]: undefined
      }
    });
  }

  @Action(ClearGrid)
  clearGrid(ctx: StateContext<CollectionStateBase>, action: ClearGrid) {
    if (action.source !== this.getSource()) {
      return;
    }

    const state = ctx.getState();
    const gridId = action.payload.gridId;
    const grids = state.grids;
    const grid = grids ? grids[gridId] : undefined;

    ctx.patchState({
      grids: {
        ...state.grids,
        [gridId]: {
          ...grid,
          pages: undefined
        }
      }
    });
  }

  abstract getSource(): string;
  protected getDefaultItems(): any[] { return null; }
  protected getAddItemsState(ctx: StateContext<CollectionStateBase>, action: AddItems): any { }
  protected getRemoveItemsState(ctx: StateContext<CollectionStateBase>, action: RemoveItems): any { }
  protected getDeleteItemsState(ctx: StateContext<CollectionStateBase>, action: DeleteItems): any { }
  protected getResetState(ctx: StateContext<CollectionStateBase>, action: Reset): any { }
}
