import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationsState } from '@portal-core/notifications/services/notifications-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([NotificationsState])
  ]
})
export class NotificationsServicesModule { }
