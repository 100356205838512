import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ProjectChecklistType } from '@portal-core/project-checklists/enums/project-checklist-type.enum';
import { ProjectChecklistGridItemPathFilter } from '@portal-core/project-checklists/models/project-checklist-grid-item-path-filter.model';
import { ProjectChecklistItem } from '@portal-core/project-checklists/models/project-checklist-item.model';
import { ProjectChecklistUpdate } from '@portal-core/project-checklists/models/project-checklist-update.model';
import { ProjectChecklist } from '@portal-core/project-checklists/models/project-checklist.model';
import { UpdateChecklistItemNote } from '@portal-core/project-checklists/models/update-checklist-item-note.model';
import { UpdateChecklistItemStatuses } from '@portal-core/project-checklists/models/update-checklist-item-statuses.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectChecklistsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getChecklistById$(checklistId: number): Observable<ProjectChecklist> {
    return this.http.get<ProjectChecklist>(`${this.apiService.centralApiBaseUri}/api/checklists/getChecklist/${checklistId}`).
      pipe(map(checklist => { checklist.Columns = checklist.Columns.sort((col1, col2) => col1.Order - col2.Order); return checklist; }));
  }

  @SharePendingRequest()
  getChecklistsByProjectId$(projectId: number): Observable<ProjectChecklist[]> {
    return this.http.get<ProjectChecklist[]>(`${this.apiService.centralApiBaseUri}/api/checklists/project/${projectId}/false`);
  }

  @SharePendingRequest()
  getChecklistsNameAndIdByProjectId$(projectId: number): Observable<ProjectChecklist[]> {
    return this.http.get<ProjectChecklist[]>(`${this.apiService.centralApiBaseUri}/api/checklists/project/${projectId}/NameAndID`);
  }

  @SharePendingRequest()
  getChecklistGridItemsById$(projectChecklistId: number, checklistType: ProjectChecklistType, filtersWithPath: ProjectChecklistGridItemPathFilter): Observable<Page<ProjectChecklistItem>> {
    return this.http.post<Page<ProjectChecklistItem>>(
      `${this.apiService.centralApiBaseUri}/api/checklists/${projectChecklistId}/ChecklistGridItems/`,
      filtersWithPath
    ).pipe(tap(gridPage => {
      gridPage.Items.map(item => {
        // set because we are using virtual nodes.
        if (checklistType === ProjectChecklistType.ProjectFiles) {
          item.Id = projectChecklistId + item.Path;
          item.ChecklistId = projectChecklistId;
        }
        return item;
      });
    }));
  }

  updateChecklistItemStatus$(projectId: number, checklistItemsToUpdate: UpdateChecklistItemStatuses[]): Observable<ProjectChecklistUpdate> {
    return this.http.put<ProjectChecklistUpdate>(`${this.apiService.centralApiBaseUri}/api/checklists/Project/${projectId}/projectChecklistItemStatuses/`, checklistItemsToUpdate);
  }

  updateItemNote$(projectId: number, item: UpdateChecklistItemNote, checklistId: number): Observable<any> {
    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/checklists/Project/${projectId}/projectChecklistItemNote/?checklistId=${checklistId}`, item);
  }

  upsertProjectChecklist$(projectId: number, checklist: ProjectChecklist): Observable<ProjectChecklist> {
    return this.http.put<ProjectChecklist>(`${this.apiService.centralApiBaseUri}/api/checklists/Project/${projectId}`, checklist);
  }

  deleteProjectChecklist$(checklistId: number, projectId: number): Observable<any> {
    return this.http.delete(`${this.apiService.centralApiBaseUri}/api/checklists/${checklistId}/project/${projectId}`);
  }
}
