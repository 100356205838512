import { resolvePath } from '@common/util/path';
import { SnippetNodeViewComponent } from '@portal-core/text-editor/components/snippet-node-view/snippet-node-view.component';
import { NodeViewType } from '@portal-core/text-editor/enums/node-view-type.enum';
import { SnippetNodeViewOptions } from '@portal-core/text-editor/models/snippet-node-view-options.model';
import { ComponentNodeView } from '@portal-core/text-editor/node-views/component.node-view';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { Decoration, EditorView, NodeViewConstructor } from 'prosemirror-view';
import { Subscription } from 'rxjs';

export class SnippetNodeView extends ComponentNodeView {
  private toggleAppliedConditionsSubscription: Subscription;

  constructor(
    node: ProseMirrorNode,
    editorView: EditorView,
    getPos: GetPosForNodeView,
    decorations: Decoration[],
    create: DynamicViewComponentInjector,
    protected options?: SnippetNodeViewOptions
  ) {
    super(SnippetNodeViewComponent, node, editorView, getPos, decorations, create, options);
  }

  refreshSubscription: Subscription;

  get snippetNodeEditorView() {
    return (this.component.componentRef.instance as SnippetNodeViewComponent).soloFileTextEditorComponent.editorView;
  }

  stopEvent(event: Event): boolean {
    // Allow drag events to happen
    if (event.type.startsWith('drag')) {
      return false;
    } else {
      return true;
    }
  }

  destroy() {
    super.destroy();
    this.refreshSubscription?.unsubscribe();
    this.toggleAppliedConditionsSubscription?.unsubscribe();
  }

  onComponentCreated() {
    if (this.options.refresh$) {
      this.refreshSubscription = this.options.refresh$.subscribe(() => {
        this.update(this.node, this.decorations);
      })
    }

    // Resolve the full path of the snippet in the project for use by the nodeViews and plugins
    const resolvedPath = resolvePath(this.options.parentFilePath ?? '', this.node.attrs.src ?? '');
    const isRecursive = this.options.parentSnippetPaths.includes(resolvedPath) || this.options.parentFilePath === resolvedPath;

    let nodeViews: Dictionary<NodeViewConstructor>;
    if (typeof this.options.nodeViews === 'function') {
      const parentSnippetPaths = [...this.options.parentSnippetPaths, this.options.parentFilePath];
      nodeViews = this.options.nodeViews(resolvedPath, parentSnippetPaths);
    } else {
      nodeViews = this.options.nodeViews;
    }

    let plugins: Plugin[];
    if (typeof this.options.plugins === 'function') {
      plugins = this.options.plugins(resolvedPath, () => this.snippetNodeEditorView);
    } else {
      plugins = this.options.plugins;
    }

    // Set the component's data
    this.inputs.nodeViews = nodeViews;
    this.inputs.plugins = plugins;
    this.inputs.schema = this.options.schema;
    this.inputs.isRecursive = isRecursive;
    this.inputs.isOuterSnippet = this.options.parentSnippetPaths.length === 0;

    // Listen to component events
    this.outputs.load = () => this.emitLoaded({ type: NodeViewType.Snippet });
  }

  updateComponent() {
    let src: string;
    if (this.options && typeof this.options.buildContentApiUri === 'function') {
      src = this.options.buildContentApiUri(this.node.attrs.src ?? '');
    }

    // Update the component's data
    this.inputs.inline = this.node.isInline;
    this.inputs.src = src;
  }
}
