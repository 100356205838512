import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LogoutReason } from '@portal-core/auth/enums/logout-reason.enum';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { IdleLogoutService } from '@portal-core/idle-logout/services/idle-logout.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'mc-idle-logout-dialog',
  templateUrl: './idle-logout-dialog.component.html',
  styleUrls: ['./idle-logout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class IdleLogoutDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '36rem',
    disableClose: true // Don't allow click off - must choose a button to close.
  };

  initialTimeTillLogout: number;
  logoutTimerSubscription: Subscription;

  constructor(
    private idleLogoutService: IdleLogoutService,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<IdleLogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.initialTimeTillLogout = this.idleLogoutService.warningOffsetMilliseconds;
    this.logoutTimerSubscription = timer(this.initialTimeTillLogout).subscribe(() => {
      this.authService.logout(LogoutReason.IdleTimeExpiration);
    });
  }

  onSignOutClicked() {
    this.authService.logout();
  }

  onExtendIdleTimerClicked() {
    this.idleLogoutService.extendIdleTimer();
    this.dialogRef.close();
  }
}
