import { BlockElementTagNames } from '@common/html/constants/block-element-tag-names.constant';
import { NodeType } from '@common/html/enums/node-type.enum';
import { cloneElementWithNewName, descendants, nbsp } from '@common/html/util/dom';

/**
 * Normalizes an HTML document by fixing <br> tags pasted from Google Docs.
 * Removes <br> tags with the 'Apple-interchange-newline' CSS class.
 * Replaces <br> tags that are between block nodes with `<p>&nbsp;<p>` tags.
 * e.g.
 * ```html
 * <p>foo</p>
 * <br />
 * <p>bar</p>
 * ```
 * to
 * ```html
 * <p>foo</p>
 * <p>&nbsp;</p>
 * <p>bar</p>
 * ```
 */
export class BlockBrNormalizer {
  /**
   * Normalizes an HTML document by fixing <br> tags pasted from Google Docs.
   * @param doc The HTML doc to normalize.
   */
  normalize(doc: Document) {
    descendants(doc.documentElement, (element: HTMLElement) => {
      if (element.nodeName?.toLowerCase() === 'br') {
        // Remove <br> tags with the class Apple-interchange-newline
        if (element.classList.contains('Apple-interchange-newline')) {
          element.remove();
        }
        // Replace <br> tags that are between block nodes with <p> tags
        else {
          const prevNode = this.sibling(element, 'prev');
          const nextNode = this.sibling(element, 'next');

          if (this.isBlockElement(prevNode) || this.isBlockElement(nextNode)) {
            const p = cloneElementWithNewName(element, 'p');
            p.textContent = nbsp;
            element.replaceWith(p);
          }
        }
      }
    }, { depthFirst: true, reverse: true });
  }

  /**
   * Gets the next or previous sibling node that is a non-empty text node or block node.
   * @param element The element to get the sibling of.
   * @param dir Whether to get the next or previous sibling. Must be 'next' or 'prev'.
   * @returns The matching sibling node.
   */
  private sibling(element: Element, dir: 'next' | 'prev'): Node {
    function next(node: Node): Node {
      return dir === 'next' ? node.nextSibling : node.previousSibling
    }

    let sibling = next(element);

    while (sibling) {
      if (this.isNonEmptyTextNode(sibling) || BlockElementTagNames.includes(sibling.nodeName.toLowerCase())) {
        break;
      } else {
        sibling = next(sibling);
      }
    }

    return sibling;
  }

  /**
   * Returns whether or not a node is a text node with non-whitespace text content.
   * @param node The node to check.
   * @returns Returns true if the node is a text node with non-whitespace text content.
   */
  private isNonEmptyTextNode(node: Node): boolean {
    return node.nodeType === NodeType.TEXT_NODE && !!node.textContent.trim();
  }

  /**
   * Returns whether a node is a block element.
   * @param node The node to check.
   * @returns Returns true if the node is a block element.
   */
  private isBlockElement(node: Node): boolean {
    return node && node.nodeType === NodeType.ELEMENT_NODE && BlockElementTagNames.includes(node.nodeName.toLowerCase());
  }
}
