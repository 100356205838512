import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CommitAction } from '@portal-core/commits/models/commit-action.model';
import { CommitsService } from '@portal-core/commits/services/commits.service';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { McValidators } from '@portal-core/forms/services/mc-validators';
import { MaxFilePathLength } from '@portal-core/project-files/constants/file-path-length.constants';
import { FilePathRegex } from '@portal-core/project-files/constants/file-regexes.constants';
import { MaxCommitMessageLength } from '@portal-core/project-files/constants/max-commit-message-length.constant';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';

export interface CommitFileDialogData {
  projectId: number;
  branchName: string;
  newFile: boolean;
  filePath: string;
  content: string;
  lastCommitId: string;
}

export interface CommitFileDialogResult {
  newFilePath: string;
  newCommitId: string;
}

@Component({
  selector: 'mc-commit-file-dialog',
  templateUrl: './commit-file-dialog.component.html',
  styleUrls: ['./commit-file-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommitFileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '50rem',
    minHeight: '34rem',
    autoFocus: '.mc-commit-file-dialog-textarea'
  };

  branchName: string;
  content: string;
  filePath: string;
  lastCommitId: string;
  licenseId: number;
  newCommitForm: FormGroup;
  newFile: boolean;
  projectId: number;
  savingState: LoadingState<string> = new LoadingState<string>();

  get requirePromptOnClose(): boolean {
    return this.newCommitForm?.dirty;
  }

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CommitFileDialogData,
    protected dialogRef: MatDialogRef<CommitFileDialogComponent>,
    private commitsService: CommitsService,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private currentService: CurrentService,
    private formsService: FormsService,
    private formBuilder: FormBuilder
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.projectId = this.data.projectId;
    this.branchName = this.data.branchName;
    this.filePath = this.data.filePath;
    this.newFile = this.data.newFile;
    this.content = this.data.content;
    this.lastCommitId = this.data.lastCommitId;
    this.buildForm();
  }

  onSubmit() {
    const currentUser = this.currentService.getLicenseUser();
    let fileAction: CommitAction[];

    this.savingState.update(true);

    if (this.newFile) {
      fileAction = [
        {
          'action': 'create',
          'file_path': this.newCommitForm.controls['file-path'].value,
          'content': this.content
        }
      ];
    } else {
      fileAction = [
        {
          'action': 'update',
          'file_path': this.filePath,
          'content': this.content,
          'last_commit_id': this.data.lastCommitId
        }
      ];
    }

    this.commitsService.postNewCommit$(this.projectId, this.branchName, fileAction, this.newCommitForm.controls.commit.value, currentUser?.User?.Email, currentUser?.User?.FullName).subscribe((commit) => {
      this.savingState.update(false);
      this.snackBar.open('Changes have been committed.', 'OK', { duration: 2500 });

      const result: CommitFileDialogResult = {
        newFilePath: commit?.case_corrected_paths ? commit.case_corrected_paths[0] : this.newCommitForm.controls['file-path'].value,
        newCommitId: commit?.id
      };

      this.closeDialog(result, true);
    }, error => {
      this.savingState.update(false, 'Unable to commit the changes.', this.errorService.getErrorMessages(error))
    });
  }

  private buildForm() {
    this.newCommitForm = this.formBuilder.group({
      'file-path': new FormControl({ value: this.filePath, disabled: !this.newFile }, [Validators.required, Validators.maxLength(MaxFilePathLength), Validators.pattern(FilePathRegex), McValidators.notOnlyWhitespace, McValidators.validFilePath, McValidators.notOnlyWhiteSpaceFileName, this.formsService.createValidFileExtensionValidator(true)]),
      'commit': new FormControl('', [Validators.required, Validators.maxLength(MaxCommitMessageLength), McValidators.notOnlyWhitespace])
    });
  }
}
