import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreService } from '@portal-client/core/core.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-app-about-dialog',
  templateUrl: './app-about-dialog.component.html',
  styleUrls: ['./app-about-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAboutDialogComponent implements OnInit {
  currentYear = new Date().getFullYear();
  appVersionAndBuildDate$: Observable<string>;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.appVersionAndBuildDate$ = this.coreService.getVersionAndBuildDate$();
  }
}
