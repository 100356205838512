import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TranslationPackagesState } from '@portal-core/translation-packages/services/translation-packages-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([TranslationPackagesState])
  ]
})
export class TranslationPackagesServicesModule { }
