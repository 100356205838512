import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ImagePickerModule } from '@portal-core/forms/components/image-picker/image-picker.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { TeamSettingsFormComponent } from './team-settings-form.component';

@NgModule({
  declarations: [TeamSettingsFormComponent],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormControlErrorMessageModule,
    FormsModule,
    ImagePickerModule,
    LoaderModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  exports: [
    TeamSettingsFormComponent
  ]
})
export class TeamSettingsFormModule { }
