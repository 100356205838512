<button type="button" mat-icon-button class="mc-dialog-floating-close-button" tabindex="-1" (click)="closeDialog()">
  <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
</button>

<button *ngIf="dialogMode === DialogMode.AllConversations || dialogMode === DialogMode.UnreadConversations" mat-mini-fab color="primary" class="mc-new-conversation-button" matTooltip="Start conversation" (click)="onStartNewConversationClicked()">
  <mat-icon fontSet="project-titan" fontIcon="icon-add"></mat-icon>
</button>

<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <ng-container *ngIf="dialogMode !== DialogMode.Loading && !errorsLoading">
      <mat-toolbar class="mc-spring-board-header mc-toolbar-header" color="secondary-vivid">
        <button mat-button color="primary" class="mc-message-center-view-mode-button mc-message-center-view-mode-inbox-button" [ngClass]="{ 'mc-active': dialogMode === DialogMode.AllConversations }" (click)="onShowInboxClicked()">Inbox</button>
        <span class="mc-primary">&nbsp;|&nbsp;</span>
        <button mat-button color="primary" class="mc-message-center-view-mode-button mc-message-center-view-mode-unread-button" [ngClass]="{ 'mc-active': dialogMode === DialogMode.UnreadConversations }" (click)="onShowUnreadOnlyClicked()">Unread ({{ unreadCount$ | async }})</button>
      </mat-toolbar>

      <mat-list class="mc-spring-board-body mc-list-wrap mc-list-dense mc-list-text-weight-light mc-list-bg-dark mc-gutter-list-icons mc-message-center-dialog-conversation-list" [ngClass]="{ 'mc-active': dialogMode === DialogMode.AllConversations }">
        <mat-list-item *ngFor="let conversation of conversations$ | async" matRipple class="mc-message-center-dialog-conversation-item" [ngClass]="{ 'mc-list-item-selected': selectedConversationId$.value === conversation.Id }" (click)="onConversationClicked(conversation)">
          <mat-icon *ngIf="conversation.CountUnread > 0" matListIcon fontSet="project-titan" fontIcon="icon-dot" class="mc-list-icon-xxx-small"></mat-icon>
          <span matLine>{{ conversation.Subject }}</span>

          <span class="mc-toolbar-spacer-small"></span>
          <span class="mc-fill-remaining-flex-space"></span>

          <button mat-icon-button color="primary" class="mc-button-small" matTooltip="Remove conversation" mcStopPropagation="click" (click)="onRemoveConversationClicked(conversation)">
            <mat-icon class="mc-task-profile-small-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
          </button>
        </mat-list-item>
      </mat-list>

      <mat-list class="mc-spring-board-body mc-list-wrap mc-list-dense mc-list-text-weight-light mc-list-bg-dark mc-gutter-list-icons mc-message-center-dialog-conversation-list" [ngClass]="{ 'mc-active': dialogMode === DialogMode.UnreadConversations }">
        <mat-list-item *ngFor="let conversation of unreadConversations$ | async" matRipple class="mc-message-center-dialog-conversation-item" [ngClass]="{ 'mc-list-item-selected': selectedConversationId$.value === conversation.Id }" (click)="onConversationClicked(conversation)">
          <mat-icon *ngIf="conversation.CountUnread > 0" matListIcon fontSet="project-titan" fontIcon="icon-dot" class="mc-list-icon-xxx-small"></mat-icon>
          <span matLine>{{ conversation.Subject }}</span>

          <span class="mc-toolbar-spacer-small"></span>
          <span class="mc-fill-remaining-flex-space"></span>

          <button mat-icon-button color="primary" class="mc-button-small" matTooltip="Remove conversation" mcStopPropagation="click" (click)="onRemoveConversationClicked(conversation)">
            <mat-icon class="mc-task-profile-small-icon" [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="dialogMode" class="mc-spring-board">
    <div *ngSwitchCase="DialogMode.Loading">
      <mc-loader></mc-loader>
    </div>

    <div *ngSwitchCase="DialogMode.ErrorLoading">
      <div class="error-pane">
        <mc-errors-expansion-panel generalError="There was a problem loading the messages." [detailedErrors]="errorsLoading"></mc-errors-expansion-panel>
      </div>
    </div>

    <div *ngSwitchDefault class="mc-spring-board mc-message-center-dialog-card-conversation">
      <div class="mc-spring-board-header">
        <div [hidden]="contentMode !== ContentMode.ViewingConversation" class="mc-conversation-subject-title">
          <ng-container *ngIf="conversation$ | async as conversation">
            <h2 class="mc-message-center-conversation-title mc-truncate">
              <span [matTooltip]="conversation.Subject">{{ conversation.Subject }}</span>
            </h2>
            <mat-divider></mat-divider>
          </ng-container>
        </div>

        <div [hidden]="contentMode !== ContentMode.CreatingConversation">
          <mat-form-field color="primary-vivid" class="mc-form-field mc-message-center-participants-form-field">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let participantOption of newConversationParticipants$ | async" [selectable]="true" [removable]="true" (removed)="onAutocompleteOptionRemoved(participantOption)">
                {{ participantOption.Description }}
                <mat-icon matChipRemove fontSet="project-titan" fontIcon="icon-close"></mat-icon>
              </mat-chip>
              <input placeholder="Add a team or users..." #participantsInput [formControl]="addParticipantsToConversationCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAutocompleteOptionSelected($event)">
              <mat-option *ngFor="let item of autocompleteFilteredItems$ | async" [value]="item">
                {{ item.Description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div #conversationMessages class="mc-spring-board-body">
        <div *ngIf="conversation$ | async as conversation else noConversation" class="mc-list-container" [ngClass]="{ 'mc-hidden': contentMode !== ContentMode.ViewingConversation }">
          <mat-progress-bar [ngClass]="{ 'mc-visibly-hidden': !loadingMoreMessages && !loadingMessages }" class="mc-list-loading-more-progress-bar-top" mode="indeterminate" color="notice"></mat-progress-bar>
          <div class="mc-list-header mc-message-center-messages-header" *ngIf="!loadingMessages">
            <button *ngIf="!allMessagesLoaded" mat-stroked-button (click)="loadMoreMessages(conversation.Id)" [disabled]="loadingMoreMessages">Load More</button>
            <span *ngIf="allMessagesLoaded" class="mc-text-muted">This is the very beginning of your message history with {{ conversation.Subject }}</span>
            <span *ngIf="loadingMoreMessagesError" class="mc-list-loading-more-error mc-error-message">{{ loadingMoreMessagesError }}</span>
          </div>

          <mat-list class="mc-message-center-message-list" [ngClass]="{ 'mc-hidden': loadingMessages }">
            <mat-list-item *ngFor="let message of messages$ | async" class="mc-message-center-message-item">
              <div class="mc-message-center-message">
                <mc-license-user-avatar class="mc-message-center-message-avatar" [licenseUser]="message.Sender.Id | mcLicenseUserForLicenseAsync: conversation.LicenseId | async" [showOnlineStatus]="true" [showName]="false"></mc-license-user-avatar>
                <div class="mc-message-center-message-wrapper">
                  <mat-toolbar class="mc-toolbar-normal mc-message-center-message-toolbar" color="secondary-invert">
                    <span class="mc-toolbar-text" [matTooltip]="message.Sender.FullName" matTooltipClass="mc-tooltip-snug">
                      <span class="mc-toolbar-text-truncate">{{ message.Sender.FullName }}</span>
                    </span>
                    <span class="mc-toolbar-spacer-x-small"></span>
                    <span class="text-light-weight text-small">{{ message.CreatedOn | date: 'MM/dd/yyyy h:mm a' }}</span>
                    <span class="mc-toolbar-spacer-small"></span>
                    <span class="mc-fill-remaining-flex-space"></span>
                    <button mat-icon-button class="mc-button-small" matTooltip="Delete message" (click)="onRemoveMessageClicked(message)">
                      <mat-icon [fontSet]="'project-titan'" [fontIcon]="'icon-close'"></mat-icon>
                    </button>
                  </mat-toolbar>
                  <div class="mc-message-center-message-body">{{ message.Body }}</div>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>

        <ng-template #noConversation>
          <div class="mc-message-center-conversation-empty" [ngClass]="{ 'mc-hidden': contentMode !== ContentMode.ViewingConversation }">
            <p>
              Click a conversation to the left to view it
              <br>
              or click <mat-icon fontSet="project-titan" fontIcon="icon-add" class="mc-baseline-text-icon"></mat-icon> above to start a conversation.
            </p>
          </div>
        </ng-template>
      </div>

      <div class="mc-spring-board-footer">
        <mat-form-field color="primary-vivid" class="mc-form-field" [ngClass]="{ 'mc-hidden': contentMode === ContentMode.ViewingConversation && !(conversation$ | async) }">
          <input type="text" class="message-input" matInput #messageInput (keydown.enter)="onMessageEnterKeyDown()" placeholder="Write a message (Enter to send)">
        </mat-form-field>
      </div>
    </div>
  </mc-switch-board-content>
</mc-switch-board>