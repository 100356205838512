import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfTruncatingPathComponent } from '@portal-core/general/components/self-truncating-path/self-truncating-path.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LifeCycleModule } from '@portal-core/general/directives/life-cycle/life-cycle.module';

@NgModule({
  declarations: [SelfTruncatingPathComponent
  ],
  imports: [
    CommonModule,
    LifeCycleModule,
    MatTooltipModule
  ],
  exports: [
    SelfTruncatingPathComponent
  ]
})
export class SelfTruncatingPathModule { }
