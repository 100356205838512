import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewPackageStatusTextPipe } from './review-package-status-text.pipe';

@NgModule({
  declarations: [ReviewPackageStatusTextPipe],
  imports: [CommonModule],
  exports: [ReviewPackageStatusTextPipe]
})
export class ReviewPackageStatusTextModule { }
