import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrentService } from '@portal-core/current/services/current.service';
import { StringService } from '@portal-core/general/services/string.service';
import { Project } from '@portal-core/projects/models/project.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-project-file-avatar',
  templateUrl: './project-file-avatar.component.html',
  styleUrls: ['./project-file-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ProjectFileAvatarComponent extends AvatarBase implements OnInit {
  @Input() branchName?: string;
  @Input() filePath: string;
  @Input() project?: Project;
  @Input() showTooltipRedirectMessage?: boolean = false;

  currentUserIsAuthor: boolean = false;
  private isAuthorSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef, private currentService: CurrentService, private stringService: StringService) {
    super();
  }

  ngOnInit() {
    this.isAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      this.currentUserIsAuthor = isAuthor;
      this.cdr.markForCheck();
    });
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return this.currentUserIsAuthor;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.filePath;
  }

  isLink(): boolean {
    return super.isLink() && this.hasPermission();
  }

  encodeURIComponent(component: string): string {
    return this.stringService.encodeURIComponent(component);
  }
}
