import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { ProjectNotificationListModule } from '@portal-core/notifications/components/project-notification-list/project-notification-list.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { ProjectAccessFormModule } from '@portal-core/projects/components/project-access-form/project-access-form.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectDeletionFormModule } from '@portal-core/projects/components/project-deletion-form/project-deletion-form.module';
import { ProjectOverviewModule } from '@portal-core/projects/components/project-overview/project-overview.module';
import { ProjectSettingsFormModule } from '@portal-core/projects/components/project-settings-form/project-settings-form.module';
import { ProjectLinkModule } from '@portal-core/projects/pipes/project-link/project-link.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { ProjectProfileComponent } from './project-profile.component';

@NgModule({
  declarations: [
    ProjectProfileComponent
  ],
  exports: [
    ProjectProfileComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    PreventDefaultModule,
    ProfileTabModule,
    ProjectAccessFormModule,
    ProjectAvatarModule,
    ProjectDeletionFormModule,
    ProjectLinkModule,
    ProjectNotificationListModule,
    ProjectOverviewModule,
    ProjectSettingsFormModule,
    RouterModule,
    SwitchBoardModule
  ]
})
export class ProjectProfileModule { }
