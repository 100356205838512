import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  // Iterates over the nodes in a tree (depth-first) returning the first node predicate returns true for.
  // The predicate is invoked with one argument: node
  find<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): T {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result = predicate(node) ? node : undefined;

      if (typeof result === 'undefined' && children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.find(children[i], childrenName, predicate);

          if (typeof result !== 'undefined') {
            break;
          }
        }
      }

      return result;
    }
  }

  findChild<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): T {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result;

      if (children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.find(children[i], childrenName, predicate);

          if (typeof result !== 'undefined') {
            break;
          }
        }
      }

      return result;
    }
  }

  every<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): boolean {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result = predicate(node);

      if (result && children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.every(children[i], childrenName, predicate);

          if (!result) {
            break;
          }
        }
      }

      return result;
    }
  }

  everyChild<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): boolean {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result;

      if (children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.every(children[i], childrenName, predicate);

          if (!result) {
            break;
          }
        }
      }

      return result;
    }
  }

  some<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): boolean {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result = predicate(node);
      if (!result && children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.some(children[i], childrenName, predicate);

          if (result) {
            break;
          }
        }
      }

      return result;
    }
  }

  someChild<T>(node: T, childrenName: keyof T, predicate: (node: T) => boolean): boolean {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      let result;

      if (children) {
        for (let i = 0; i < children.length; i += 1) {
          result = this.some(children[i], childrenName, predicate);

          if (result) {
            break;
          }
        }
      }

      return result;
    }
  }

  forEach<T>(node: T, childrenName: keyof T, iteratee: (node: T) => void) {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];
      iteratee(node);

      if (children) {
        for (let i = 0; i < children.length; i += 1) {
          this.forEach(children[i], childrenName, iteratee);
        }
      }
    }
  }

  forEachChild<T>(node: T, childrenName: keyof T, iteratee: (node: T) => void) {
    if (node) {
      const children: T[] = node[childrenName] as unknown as T[];

      if (children) {
        for (let i = 0; i < children.length; i += 1) {
          this.forEach(children[i], childrenName, iteratee);
        }
      }
    }
  }

  forEachAncestor<T>(node: T, parentName: keyof T, iteratee: (node: T) => void) {
    if (node) {
      const parent: T = node[parentName] as unknown as T;
      if (parent) {
        iteratee(parent);
        this.forEachAncestor(parent, parentName, iteratee);
      }
    }
  }

  flatten<T>(node: T, childrenName: keyof T): T[] {
    const flatArray: T[] = [];
    this.forEach(node, childrenName, child => flatArray.push(child));
    return flatArray;
  }

  flattenChildren<T>(node: T, childrenName: keyof T): T[] {
    const flatArray: T[] = [];
    this.forEachChild(node, childrenName, child => flatArray.push(child));
    return flatArray;
  }
}
