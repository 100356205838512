import { ContentMatch } from 'prosemirror-model'

/*
 * The functions in this file are taken directly from ProseMirror.
 * The functions are unedited and only exist here because ProseMirror does not export them.
 */

export function defaultBlockAt(match: ContentMatch) {
  for (let i = 0; i < match.edgeCount; i++) {
    let { type } = match.edge(i)
    if (type.isTextblock && !type.hasRequiredAttrs()) return type
  }
  return null
}
