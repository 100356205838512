import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';
import { BehaviorSubject, catchError, map, Observable, of, Subject } from 'rxjs';
import { ProjectFilesChecklistService } from './project-files-checklist.service';

@Injectable()
/**
 *  Only meant to be used by ProjectFilesTreeComponent as a provider.
 *  This service is used to create/manipulate normal Nodes for the tree used in ProjectFilesTreeComponent.
**/
export class ProjectFilesTreeDataService {

  projectId: number;
  includeFiles: boolean;
  fileFilter: string;
  dataChange: BehaviorSubject<ProjectFileNode[]> = new BehaviorSubject<[]>([]);
  nodeMap: Map<string, ProjectFileNode> = new Map<string, ProjectFileNode>();
  retry$: Subject<void> = new Subject();
  treeControl: FlatTreeControl<ProjectFileFlatNode>;
  toggleTreeNode: BehaviorSubject<ProjectFileFlatNode> = new BehaviorSubject(null);

  constructor(private projectFilesService: ProjectFilesService, private projectFilesChecklistService: ProjectFilesChecklistService, private cdr: ChangeDetectorRef) {
  }

  initialize(projectFiles: ProjectFile[]): ProjectFileNode[] {
    return this.generateNodes(projectFiles);
  }

  getNode(key: string): ProjectFileNode {
    return this.nodeMap.get(key);
  }

  setTree(processedNodes: ProjectFileNode[], pathFilters: string[], ignoreInitialFilters: boolean) {
    this.dataChange.next(processedNodes);
    processedNodes.forEach(node => this.projectFilesChecklistService.setSelection(node, pathFilters, ignoreInitialFilters));
  }

  setTreeSelection(pathFilters: string[]) {
    this.nodeMap.forEach(node => this.projectFilesChecklistService.setSelection(node, pathFilters ? pathFilters : []));
  }

  private generateNodes(projectFiles: ProjectFile[]): ProjectFileNode[] {
    const nodes: ProjectFileNode[] = [];
    projectFiles.forEach(file => {
      const existingNode = this.nodeMap.get(file.Path);
      if (existingNode) {
        nodes.push(existingNode);
      } else {
        const node = new ProjectFileNode(file.Name, file.Id, file.Path, null, null, file.Type === 0);
        this.nodeMap.set(file.Path, node);
        nodes.push(node);
      }
    });
    return nodes;
  }

  getChildren$(path: string, branchName: string): Observable<ProjectFileNode[]> {
    return this.projectFilesService.getProjectFileTree$(this.projectId, branchName, path, this.includeFiles, this.fileFilter).pipe(
      map(projectFiles => this.generateNodes(projectFiles)),
      catchError(() => {
        const flatNode = this.getNode(path)?.flatFileNode;
        if (flatNode) {
          flatNode.loaded = false;
          flatNode.isLoading = false;
          flatNode.error = true;
          this.toggleTreeNode.next(flatNode);
        }
        return of(null);
      }));
  }

  addChildrenToFolderNode(node: ProjectFileNode, nodes: ProjectFileNode[], pathFilters: string[], ignoreInitialFilters: boolean) {
    node.flatFileNode.error = false;
    node.flatFileNode.isLoading = false;
    node.flatFileNode.loaded = true;
    node.childrenChange.next(nodes);
    this.dataChange.next(this.dataChange.value);
    if (!ignoreInitialFilters) {
      nodes.forEach(childNode => { childNode.parentNode = node; this.projectFilesChecklistService.setSelection(childNode, pathFilters); });
    }
  }

  addNewFileNodeToTree(node: ProjectFileFlatNode, newProjectFileNode: ProjectFileNode) {
    const parent = this.getNode(node.path);
    parent.children.push(newProjectFileNode);
    parent.childrenChange.next(parent.children);
    this.dataChange.next(this.dataChange.value);
  }
}
