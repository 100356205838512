import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MaxAvatarImageFileSizeBytes } from '@portal-core/data/common/constants/max-avatar-image-file-size.constant';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ImagePickerValue } from '@portal-core/forms/models/image-picker-value.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-team-settings-form',
  templateUrl: './team-settings-form.component.html',
  styleUrls: ['./team-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSettingsFormComponent implements OnChanges {
  @Input() team: Team;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    return this.teamSettingsForm?.dirty;
  }

  savingState: LoadingState<string> = new LoadingState<string>();
  teamSettingsForm: UntypedFormGroup;

  constructor(private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private snackBar: MatSnackBar,
    private teamsService: TeamsService) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.team) {
      this.resetForm(this.team || {} as Team);
    }
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    this.savingState.update(true);

    const team: Team = {
      Id: this.team.Id,
      LicenseId: this.team.LicenseId,
      Name: formGroup.value.Name,
      Description: formGroup.value.Description,
      Initials: this.team.Initials
    };

    const avatarValue: ImagePickerValue = this.teamSettingsForm.value.Avatar;

    this.teamsService.updateTeamSettings$(team, avatarValue ? avatarValue.file : null)
      .subscribe(() => {
        this.savingState.update(false);
        this.teamSettingsForm.markAsPristine();
        this.snackBar.open('The settings have been saved.', 'Ok', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to update the team settings.', this.errorService.getErrorMessages(error));
      });
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  protected buildForm() {
    this.teamSettingsForm = this.formBuilder.group({
      Avatar: new UntypedFormControl(null, this.formsService.createFileSizeValidator(MaxAvatarImageFileSizeBytes)),
      Name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
      Description: new UntypedFormControl(null),
    });
  }

  protected resetForm(team: Team) {
    this.teamSettingsForm.reset({
      Avatar: team.Avatar ? this.formsService.buildImagePickerValue(team.Avatar) : null,
      Name: team.Name || '',
      Description: team.Description || ''
    });
  }
}
