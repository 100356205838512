import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileStatusColorClassPipe } from './review-file-status-color-class.pipe';

@NgModule({
  declarations: [ReviewFileStatusColorClassPipe],
  imports: [CommonModule],
  exports: [ReviewFileStatusColorClassPipe],
  providers: [ReviewFileStatusColorClassPipe]
})
export class ReviewFileStatusColorClassModule { }
