import { Pipe, PipeTransform } from '@angular/core';
import { removeLeadingSlash } from '@common/util/path';

/*
 * Remove leading slash
 * Takes the path of the file and remove one leading slash if it exists
 * Usage:
 *   filePath | mcRemoveLeadingSlash
 * Example:
 *   {{ '/Project/Variables/General.flvar' |  mcRemoveLeadingSlash }}
 *    retuns 'Project/Variables/General.flvar'
*/
@Pipe({
  name: 'mcRemoveLeadingSlash'
})
export class RemoveLeadingSlashPipe implements PipeTransform {

  transform(filePath: string): string {
    return removeLeadingSlash(filePath);
  }
}
