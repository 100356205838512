import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { CollabFileTextEditorStatusBarComponent } from './collab-file-text-editor-status-bar.component';

@NgModule({
  declarations: [
    CollabFileTextEditorStatusBarComponent
  ],
  imports: [
    CommonModule,
    FileSizeModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [
    CollabFileTextEditorStatusBarComponent
  ]
})
export class CollabFileTextEditorStatusBarModule { }
