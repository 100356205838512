<div class="mc-avatar-tooltip-container" [matTooltip]="tooltip$ | async" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !project">
  <button *ngIf="showAvatar" type="button" class="mc-avatar-container" [disabled]="isImageDisabled()" (click)="onAvatarClicked()">
    <div *ngIf="project?.Status === ProjectStatus.Archived || project?.Status === ProjectStatus.Locked" class="mc-project-avatar-status-indicator" [ngClass]="project?.Status === ProjectStatus.Locked ? 'mc-bg-caution' : 'mc-bg-warn'">
      <mat-icon *ngIf="project?.Status === ProjectStatus.Locked" class="mc-image-icon" fontSet="project-titan" fontIcon="icon-lock-solid"></mat-icon>
      <mat-icon *ngIf="project?.Status === ProjectStatus.Archived" class="mc-image-icon" fontSet="project-titan" fontIcon="icon-archive"></mat-icon>
    </div>

    <svg class="mc-avatar-svg mc-fill-avatar" [style.fill]="project?.Color" viewBox="0 0 510 448">
      <path d="M239.85 112.34L175.623 16H16V367.359C16 403.25 44.3354 431.586 80.2269 431.586H431.586C466.533 431.586 494.868 403.25 494.868 367.359V112.34H239.85Z" />
      <path class="mc-stroke-avatar mc-avatar-svg-active-stroke" d="M239.85 112.34L175.623 16H16V367.359C16 403.25 44.3354 431.586 80.2269 431.586H431.586C466.533 431.586 494.868 403.25 494.868 367.359V112.34H239.85Z" stroke-width="32" />
    </svg>

    <span class="mc-avatar-initials">
      {{ project?.Initials | uppercase }}
    </span>
  </button>

  <a *ngIf="showName && nameLinksToPage && !isNameDisabled()" href="#" class="mc-avatar-name" [routerLink]="['/', project?.LicenseId, 'projects', project?.Id]">{{ project?.Name }}<span *ngIf="showId" class="mc-text-muted"> ({{ project?.Id }})</span></a>

  <mc-inline-button *ngIf="showName && (!nameLinksToPage || isNameDisabled())" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">{{ project?.Name }}<span *ngIf="showId" class="mc-text-muted"> ({{ project?.Id }})</span></mc-inline-button>
</div>