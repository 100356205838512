import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridBooleanFilterModule } from '@portal-core/ui/grid/components/grid-boolean-filter/grid-boolean-filter.module';
import { GridDateFilterModule } from '@portal-core/ui/grid/components/grid-date-filter/grid-date-filter.module';
import { GridHeaderMenuModule } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.module';
import { GridNumberFilterModule } from '@portal-core/ui/grid/components/grid-number-filter/grid-number-filter.module';
import { GridSelectFilterModule } from '@portal-core/ui/grid/components/grid-select-filter/grid-select-filter.module';
import { GridStringFilterModule } from '@portal-core/ui/grid/components/grid-string-filter/grid-string-filter.module';
import { GridHeaderCellComponent } from './grid-header-cell.component';

@NgModule({
  imports: [
    CommonModule,
    GridBooleanFilterModule,
    GridDateFilterModule,
    GridHeaderMenuModule,
    GridNumberFilterModule,
    GridSelectFilterModule,
    GridStringFilterModule
  ],
  declarations: [
    GridHeaderCellComponent
  ],
  exports: [
    GridHeaderCellComponent
  ]
})
export class GridHeaderCellModule { }
