import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseUserAsyncPipe } from './license-user-async.pipe';

@NgModule({
  declarations: [LicenseUserAsyncPipe],
  imports: [CommonModule],
  exports: [LicenseUserAsyncPipe],
  providers: [LicenseUserAsyncPipe]
})
export class LicenseUserAsyncModule {}
