<div class="mc-avatar-tooltip-container" [matTooltip]="tooltip ?? (licenseUser?.Status | mcLicenseUserAvatarTooltip : licenseUser?.IsDeleted : licenseUser?.SeatType : licenseUser?.User?.FullName)" [matTooltipClass]="tooltipClass ?? (licenseUser?.Status | mcLicenseUserAvatarTooltipClass)" [matTooltipDisabled]="disabledTooltip || !licenseUser">
  <button *ngIf="showAvatar && !isImageDisabled()" type="button" class="mc-avatar-container" (click)="onAvatarClicked()">
    <mat-icon *ngIf="showAdmin && (isAdmin$ | async)" class="mc-avatar-indicator-top-left mc-text-caution-vivid" fontSet="project-titan" fontIcon="icon-crown"></mat-icon>
    <mat-icon *ngIf="showOnlineStatus && licenseUser" class="mc-avatar-indicator-bottom-right" [ngClass]="onlineStatusClass$ | async" fontSet="project-titan" fontIcon="icon-dot"></mat-icon>
    <img *ngIf="licenseUser?.User?.Avatar" class="mc-avatar-image" alt="User Avatar" [src]="licenseUser?.User?.Avatar?.StorageId | mcMadCapImageUrl : licenseUser?.User?.Avatar?.Extension">
    <span *ngIf="!licenseUser?.User?.Avatar" class="mc-avatar-initials">{{ licenseUser?.User?.Initials | uppercase }}</span>
    <ng-container *ngIf="avatarIndicator" [ngTemplateOutlet]="avatarIndicator.templateRef"></ng-container>
  </button>

  <div *ngIf="showAvatar && isImageDisabled()" class="mc-avatar-container">
    <mat-icon *ngIf="showAdmin && (isAdmin$ | async)" class="mc-avatar-indicator-top-left mc-text-caution-vivid" fontSet="project-titan" fontIcon="icon-crown"></mat-icon>
    <mat-icon *ngIf="showOnlineStatus && licenseUser" class="mc-avatar-indicator-bottom-right" [ngClass]="onlineStatusClass$ | async" fontSet="project-titan" fontIcon="icon-dot"></mat-icon>
    <img *ngIf="licenseUser?.User?.Avatar" class="mc-avatar-image" alt="User Avatar" [src]="licenseUser?.User?.Avatar?.StorageId | mcMadCapImageUrl : licenseUser?.User?.Avatar?.Extension">
    <span *ngIf="!licenseUser?.User?.Avatar" class="mc-avatar-initials">{{ licenseUser?.User?.Initials | uppercase }}</span>
    <ng-container *ngIf="avatarIndicator" [ngTemplateOutlet]="avatarIndicator.templateRef"></ng-container>
  </div>

  <mc-inline-button *ngIf="showName && !isEmpty()" class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">
    {{ licenseUser?.User?.FullName }}
    <ng-container *ngIf="showSeatType">
      ({{ LicenseUserSeatType[licenseUser?.SeatType] }})
    </ng-container>
  </mc-inline-button>
</div>