<mc-filter-bar display="grid" [filterable]="this"></mc-filter-bar>
<mc-grid [cardBreakpoint]="cardBreakpoint" [columns]="gridControl?.columns" [columnInputs]="columnInputs" [detailedErrorMessages]="loadingState.detailedErrors$ | async" [errorMessage]="loadingState.generalError$ | async" [expandableRows]="expandableRows" [filterable]="this" [filters]="filters$ | async" [gridControl]="gridControl" [indeterminatePages]="indeterminatePages" [items]="items$ | async" [itemCount]="itemTotal$ | async" [(lastDays)]="lastDays" [limitedItemCount]="itemLimitedTotal$ | async" [(topRows)]="topRows" [lazyLoadExpandableRows]="lazyLoadExpandableRows" [loadingData]="loadingState.loading$ | async" [orderBy]="(order$ | async)?.by" [orderDirection]="(order$ | async)?.dir" [pageIndex]="pageIndex$ | async" [pageSize]="pageSize$ | async" [rowDisabledPredicate]="rowDisabledPredicate" [scrollHorizontally]="scrollHorizontally" [selectable]="selectable" [selectedItemId]="selectedItemId" [showLastDays]="showLastDays" [showTopRows]="showTopRows" [showRowBorder]="showRowBorder" [visibleColumns]="visibleColumns$ | async" (filtersCleared)="onFiltersCleared()" (lastDaysChange)="onLastDaysChanged($event)" (topRowsChange)="onTopRowsChanged($event)" (pageChanged)="onPageChanged($event)" (retryClicked)="onRetryClicked()" (selectedRowsChange)="onSelectedRowsChange($event)" (sortChanged)="onSortChanged($event)">
  <ng-template *ngFor="let gridHeaderMenuDirective of gridHeaderMenuDirectives" [mcGridHeaderMenu]="gridHeaderMenuDirective.templateName" let-column>
    <ng-container [ngTemplateOutlet]="gridHeaderMenuDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: column }"></ng-container>
  </ng-template>

  <ng-template *ngFor="let gridCellDirective of gridCellDirectives" [mcGridCell]="gridCellDirective.templateName" let-item let-columnName="columnName">
    <ng-container [ngTemplateOutlet]="gridCellDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: item, columnName: columnName }"></ng-container>
  </ng-template>

  <ng-template *ngIf="contextMenuItemsDirective" mcContextMenuItems let-item>
    <ng-container [ngTemplateOutlet]="contextMenuItemsDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  </ng-template>

  <ng-template *ngFor="let emptyGridDirective of emptyGridDirectives" [mcEmptyGrid]="emptyGridDirective.templateName" let-filters>
    <ng-container [ngTemplateOutlet]="emptyGridDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: filters }"></ng-container>
  </ng-template>

  <ng-template *ngIf="expandedRowDetailDirective" mcExpandedRowDetail let-item>
    <ng-container [ngTemplateOutlet]="expandedRowDetailDirective?.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  </ng-template>
</mc-grid>