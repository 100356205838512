import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import { ListOptionSelectionChangeEvent } from '@portal-core/ui/list/types/list-option-selection-change-event.type';

/**
 * ListOptionComponent
 *
 * Represents an option in a list item such as in an autocomplete infinite list.
 */
@Component({
  selector: 'mc-list-option, [mc-list-option]',
  templateUrl: './list-option.component.html',
  styleUrls: ['./list-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListOptionComponent implements Highlightable {
  /** Gives the host element an aria role value of option. */
  @HostBinding('attr.role')
  @Input() role?: string = 'option';

  /** Whether the option is disabled. */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      // this.cdr.markForCheck();
    }
  }
  private _disabled: boolean = false;

  /** The id for the option's DOM element. Primarily used for a11y like with aria-activedescendant. */
  @HostBinding('id')
  @Input() id?: string;

  /** Whether the option is selected. */
  @Input()
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected = value;
    this.selectedChange.emit({ option: this });
  }
  private _selected: boolean = false;

  /** The options value. */
  @Input() value: any;

  /** Emits whenever the option's selected value changes. */
  @Output() selectedChange: EventEmitter<ListOptionSelectionChangeEvent> = new EventEmitter<ListOptionSelectionChangeEvent>();

  /** Gives the host element the mc-list-option CSS class. */
  @HostBinding('class.mc-list-option') _hostClass: boolean = true;

  /** Gives the host element the mc-list-item-active CSS class when the option is active. */
  @HostBinding('class.mc-list-item-active')
  get _activeClassName() {
    return this.active;
  }

  /** Gives the host element the mc-list-item-disabled CSS class when the option is disabled. */
  @HostBinding('class.mc-list-item-disabled')
  get _disabledClassName() {
    return this.disabled;
  }

  /** Whether or not the option is the active option in its list. */
  active: boolean = false;

  constructor(private elementRef: ElementRef<HTMLElement>, private cdr: ChangeDetectorRef) { }

  /** Sets the option as selected when its clicked. */
  @HostListener('click')
  onClicked() {
    this.selected = true;
  }

  /** Sets the option as selected when enter or space is pressed. */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if ((event.keyCode === ENTER || event.keyCode === SPACE) && !hasModifierKey(event)) {
      this.selected = true;

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();

      // Pressing enter on an anchor mc-list-option doesn't navigate to the link so you emulate a click when that happens
      if (this.elementRef.nativeElement.tagName.toLowerCase() === 'a') {
        this.click();
      }
    }
  }

  /** Emulates a click on the component's host element. */
  click() {
    this.elementRef.nativeElement.click();
  }

  /** Sets the option as active. Required by FocusKeyManager. */
  setActiveStyles() {
    if (!this.active) {
      this.active = true;
      this.cdr.markForCheck();
    }
  }
  /** Sets the option as inactive. Required by FocusKeyManager. */
  setInactiveStyles() {
    if (this.active) {
      this.active = false;
      this.cdr.markForCheck();
    }
  }

  /** Returns the list item's text label. Required by FocusKeyManager. */
  getLabel(): string {
    return (this.elementRef.nativeElement.textContent ?? '').trim();
  }

  /** Return a reference to the list option's host element. */
  getElementRef(): ElementRef<HTMLElement> {
    return this.elementRef;
  }
}
