import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridHeaderFilterMenuDirective } from './grid-header-filter-menu.directive';

@NgModule({
  declarations: [
    GridHeaderFilterMenuDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GridHeaderFilterMenuDirective
  ]
})
export class GridHeaderFilterMenuModule { }
