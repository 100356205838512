import { ChangeTracker, ChangeTrackerUser, GetChangeTrackerUser } from '@common/prosemirror/changeset/change-tracker';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';

export const TrackedChangesSetMetaKey: string = 'trackedChanges.set';
export const TrackedChangesPluginKey: PluginKey = new PluginKey('tracked-changes');

export interface TrackedChangesPluginOptions {
  enabled: boolean;
  user: ChangeTrackerUser | GetChangeTrackerUser;
}

export interface TrackedChangesPluginState {
  enabled: boolean;
}

// Creates and returns a trackedChanges plugin instance
export function trackedChangesPlugin(options: TrackedChangesPluginOptions): Plugin {
  return new Plugin({
    key: TrackedChangesPluginKey,

    state: {
      init(): TrackedChangesPluginState {
        return {
          enabled: !!options.enabled
        };
      },
      apply(tr: Transaction, stateValue: TrackedChangesPluginState): TrackedChangesPluginState {
        const trackedChangesConfig: TrackedChangesPluginState = tr.getMeta(TrackedChangesSetMetaKey);
        if (trackedChangesConfig) {
          stateValue = trackedChangesConfig;
        }
        return stateValue;
      }
    },

    appendTransaction(transactions: Transaction[], oldState: EditorState, newState: EditorState): Transaction {
      if ((TrackedChangesPluginKey.getState(newState) as TrackedChangesPluginState)?.enabled) {
        const tr = newState.tr;

        if (Array.isArray(transactions)) {
          transactions.forEach(transaction => {
            // Do not handle tracked change, collab, history, and anno transactions as they already have tracked changes applied or are not allowed
            // TODO: is the trackedChange meta data check needed?
            if (transaction.docChanged && !transaction.getMeta('trackedChange') && transaction.getMeta('trackChange') !== false && !transaction.getMeta('trackedChangesCleanUp') && !transaction.getMeta('trackedChangesMarkFixer') && !transaction.getMeta('collab$') &&
              !transaction.getMeta('history$') && !transaction.getMeta('anno')) {
              const tracker = new ChangeTracker(transaction, oldState, newState, options.user);
              tracker.applyChange(tr);
            }
          });

          // If any changes were made then return the transaction
          if (tr.docChanged) {
            tr.setMeta('trackedChange', true);
            return tr;
          }
        }
      }
    }
  });
}
