import { Attrs, NodeRange, NodeType, ProseMirrorNode, ResolvedPos } from 'prosemirror-model';
import { Transform, canSplit, findWrapping } from 'prosemirror-transform';
import { closestBlockAncestor } from '../model/node';

/**
Gets whether the given range is inside a single parent inline content block node.
*/
export function isInlineRange($from: ResolvedPos, $to: ResolvedPos): boolean {
  const fromBlock = closestBlockAncestor($from);
  if (!fromBlock?.node.inlineContent) {
    return false;
  }
  const toBlock = closestBlockAncestor($to);
  if (!toBlock?.node.inlineContent) {
    return false;
  }
  return fromBlock.pos === toBlock.pos;
}

/**
Calculates a new positions for the given range assume the range will be wrapped with inline node.
if the range position is in text (not adjusted with start or end of an inline node) it won't be changed
assume there will be execute a split operation for the wrapping node.
Check whether the given range is inline with 'IsInlineRange' function before use this method.
Side effect: in situations like [[^text^]] or [[^text][text^]] the function returns the most top node.
Probably we need an addition argument to control the function behavior.
*/
export function inlineRangeForWrapping($from: ResolvedPos, $to: ResolvedPos): { $from: ResolvedPos, $to: ResolvedPos } {
  if ($from.pos === $to.pos) return { $from: $from, $to: $to };

  // try to move range position to bounds of up level nodes
  // we should move the deepest position first, since our goal is top most allowed position,
  // it will allow don't move the second (hi level) position because it will face the first moved position
  let $left = $from, $right = $to;
  if ($left.depth >= $right.depth) {
    $left = tryMoveBefore($left, $right, true);
    $right = tryMoveAfter($right, $left, true);
  } else {
    $right = tryMoveAfter($right, $left, true);
    $left = tryMoveBefore($left, $right, true);
  }

  // try to reduce the left range position to the start position of an inline node that is completely within the range
  for (let node = $left.nodeAfter; !!node; node = $left.nodeAfter) {
    if ($left.pos === $right.pos) break;
    if (!node.isInline || node.isText) break;
    const end = $left.pos + node.nodeSize;
    // check if the end of the previous node is within the range
    if (end <= $right.pos) break;
    // if no, we should excluded this node from the range
    $left = $left.doc.resolve($left.pos + 1);
  }

  // try to reduce the right position to the end position of an inline node that is completely within the range
  for (let node = $right.nodeBefore; !!node; node = $right.nodeBefore) {
    if ($left.pos === $right.pos) break;
    if (!node.isInline || node.isText) break;
    const start = $right.pos - node.nodeSize;
    // check if the start of the next node is within the range
    if (start >= $left.pos) break;
    // if no, we should excluded this node from the range
    $right = $right.doc.resolve($right.pos - 1);
  }

  return { $from: $left, $to: $right };
}

/**
Wraps the given inline range by an inline node type with specified type and attributes.
Use 'inlineRangeForWrapping' function to prepare the range for wrapping.
@param out - function that returns newly created node around the given range.
*/
export function wrapInlineRange(tr: Transform, range: { $from: ResolvedPos, $to: ResolvedPos }, nodeType: NodeType, attrs?: Attrs,
  out?: (node: ProseMirrorNode, pos: number) => void): Transform {
  const nodeRange = fixInlineRangeForWrapping(tr, range);
  // should be the only wrapping
  const wrappings = findWrapping(nodeRange, nodeType, attrs);
  if (!wrappings)
    throw new RangeError(`Unable to wrap the specified range into the ${nodeType.name}`);
  tr.wrap(nodeRange, wrappings);
  if (out) {
    // get the newly created node
    // start position of the created node is not changed
    const $start = tr.doc.resolve(nodeRange.$from.pos);
    out($start.nodeAfter, $start.pos);
  }
  return tr;
}

/**
Tries to move the given position left if the position of up level nodes is right before the given position.
@param tryAfter - whether to try to move the position in opposite direction if the main direction did not move the position.
*/
function tryMoveBefore($pos: ResolvedPos, $stop: ResolvedPos, tryAfter: boolean): ResolvedPos {
  let $res = $pos;
  let moved = false;
  for (let depth = $res.depth; depth >= 0; depth--) {
    if ($res.pos === $stop.pos) break;
    if (!$res.node(depth).isInline) break;
    const $before = $res.doc.resolve($res.before(depth));
    // if the position before the up level node is not right before the current position break loop
    if ($before.pos !== $res.pos - 1) break;
    $res = $before;
    moved = true;
  }
  if (!moved && tryAfter)
    $res = tryMoveAfter($pos, $stop, false);
  return $res;
}

/**
Tries to move the given position left if the position of up level nodes is right before the given position.
@param tryBefore - whether to try to move the position in opposite direction if the main direction did not move the position.
*/
function tryMoveAfter($pos: ResolvedPos, $stop: ResolvedPos, tryBefore: boolean): ResolvedPos {
  let $res = $pos;
  let moved = false;
  for (let depth = $res.depth; depth >= 0; depth--) {
    if ($res.pos === $stop.pos) break;
    if (!$res.node(depth).isInline) break;
    const $after = $res.doc.resolve($res.after(depth));
    // if the position after the up level node is not right after the current position break loop
    if ($after.pos !== $res.pos + 1) break;
    $res = $after;
    moved = true;
  }
  if (!moved && tryBefore)
    $res = tryMoveBefore($pos, $stop, false);
  return $res;
}

/**
Makes splits in the given inline range to prepare the range for wrapping and returns a correct NodeRange.
Use 'inlineRangeForWrapping' function to prepare the range for splitting.
*/
function fixInlineRangeForWrapping(tr: Transform, range: { $from: ResolvedPos, $to: ResolvedPos }): NodeRange {
  const { $from, $to } = range;
  if ($from.sameParent($to)) {
    return new NodeRange($from, $to, $from.depth);
  }
  let from = $from.pos;
  let to = $to.pos;
  if (!$to.parent.isBlock && $to.parentOffset) {
    const depth = $to.depth - $to.sharedDepth(from);
    if (depth > 0) {
      if (!canSplit(tr.doc, to, depth))
        throw new RangeError('Unable to split content at the specified position');
      tr.split(to, depth);
      // we cannot rely on the mapping feature because it moves the original position before or after splitter
      // we need the position exactly between newly created positions
      to += depth;
    }
  }
  if (!$from.parent.isBlock && $from.parentOffset) {
    const depth = $from.depth - $from.sharedDepth(to);
    if (depth > 0) {
      if (!canSplit(tr.doc, from, depth))
        throw new RangeError('Unable to split content at the specified position');
      tr.split(from, depth);
      // we cannot rely on the mapping feature because it moves the original position before or after splitter
      // we need the position exactly between newly created positions
      from += depth;
      to += depth * 2;
    }
  }
  // use the last document version from the transaction
  const doc = tr.doc;
  const $newFrom = doc.resolve(from);
  const $newTo = doc.resolve(to);
  return new NodeRange($newFrom, $newTo, $newFrom.depth);
}

