import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class TargetPageFilterConfig extends PageFilterConfigBase {
  constructor(enabledColumnNames: string[]) {
    super(enabledColumnNames, [
      {
        name: 'Path',
        title: 'Target Path',
        filterEnabled: true,
        sortEnabled: true,
        type: PageDataType.String
      },
      {
        name: 'TargetPath',
        title: 'Target Path',
        filterEnabled: true,
        sortEnabled: true,
        type: PageDataType.String
      }
    ]);
  }
}
