import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ReviewFileGridItem } from '@portal-core/reviews/review-files/models/review-file-grid-item.model';
import { ReviewFilesApiService } from '@portal-core/reviews/review-files/services/review-files-api.service';
import { ReviewFilesDataService } from '@portal-core/reviews/review-files/services/review-files-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ReviewFilesService extends CollectionServiceBase<ReviewFile> {
  constructor(
    private reviewFilesApiService: ReviewFilesApiService,
    private reviewFilesDataService: ReviewFilesDataService,
    protected dataService: DataService
  ) {
    super(reviewFilesDataService, dataService);
  }

  protected fetchItemById$(reviewFileId: number): Observable<ReviewFile> {
    return this.reviewFilesApiService.getReviewFileById$(reviewFileId);
  }

  protected fetchItemsById$(reviewFileIds: number[]): Observable<ReviewFile[]> {
    return this.reviewFilesApiService.getReviewFilesByIds$(reviewFileIds);
  }

  getReviewFilesPage$(licenseId: number, reviewersListLimit: number, filter?: PageFilter): Observable<Page<ReviewFileGridItem>> {
    return this.reviewFilesApiService.getReviewFilesPage$(licenseId, reviewersListLimit, filter);
  }

  getReviewFilesPageByPackageId$(reviewPackageId: number, filters: PageFilter): Observable<Page<ReviewFileGridItem>> {
    return this.reviewFilesApiService.getReviewFilesPageByPackageId$(reviewPackageId, filters);
  }

  updateReviewFileStatus$(reviewFileId: number, reviewFileStatus: ReviewFileStatus, resetReviewerStatus: boolean): Observable<MadCloudResult> {
    return this.reviewFilesApiService.updateReviewFileStatus$(reviewFileId, reviewFileStatus, resetReviewerStatus).pipe(
      switchMap(() => this.updateItems$({
        [reviewFileId]: {
          Status: reviewFileStatus
        }
      }))
    );
  }

  bulkUpdateReviewFileStatus$(reviewIds: number[], status: ReviewFileStatus, resetReviewerStatus: boolean): Observable<any> {
    return this.reviewFilesApiService.bulkUpdateReviewFileStatus$(reviewIds, status, resetReviewerStatus).pipe(
      // Insert the new review data into the collection store
      switchMap(() => this.updateItems$(reviewIds.map(reviewId => {
        return {
          Id: reviewId,
          Status: status
        };
      })))
    );
  }

  addReviewFiles$(reviewPackageId: number, paths: string[]): Observable<any> {
    return this.reviewFilesApiService.addReviewFiles$(reviewPackageId, paths);
  }

  deleteReviewFile$(reviewPackageId: number, reviewFileId: number): Observable<any> {
    return this.reviewFilesApiService.deleteReviewFile$(reviewPackageId, reviewFileId).pipe(
      tap(() => this.removeItems$([reviewFileId]))
    );
  }
}
