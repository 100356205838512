import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilterOptions } from '@common/paged-data/types/page-filter-options.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { TaskBoardsFilterService } from '@portal-core/task-boards/services/task-boards-filter.service';
import { TaskFilterDialogComponent } from '@portal-core/tasks/components/task-filter-dialog/task-filter-dialog.component';
import { TaskProfileDialogComponent } from '@portal-core/tasks/components/task-profile-dialog/task-profile-dialog.component';
import { TaskFilterGroup, TaskFilterGroupByBoard, TaskFilters } from '@portal-core/tasks/models/task-filters.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { BehaviorSubject, first, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksFilterService {
  keyChanged$: BehaviorSubject<LicenseUserClientSideInfoKey> = new BehaviorSubject<LicenseUserClientSideInfoKey>(null);
  showOnlyCurrentUserTasksChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private allTasksFilter: TaskFilterGroup;
  private currentKey: LicenseUserClientSideInfoKey;
  private filters: Map<LicenseUserClientSideInfoKey, TaskFilterGroupByBoard> = new Map<LicenseUserClientSideInfoKey, TaskFilterGroupByBoard>();
  constructor(
    private taskBoardsFilterService: TaskBoardsFilterService,
    private licenseUsersService: LicenseUsersService,
    private currentService: CurrentService,
    private errorService: ErrorService,
    private dialog: MatDialog) { }

  setCurrentKey(key: LicenseUserClientSideInfoKey) {
    this.currentKey = key;
    if (key && !this.getTaskFilters(key)) {
      const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
      if (!taskBoard) {
        return;
      }

      this.initializeCurrentTaskFilters(key, taskBoard.Id);
    }
    this.keyChanged$.next(key);
  }

  setCurrentTaskFilters(taskFilters: TaskFilters) {
    this.setTaskFilters(this.currentKey, taskFilters);
  }

  setTaskFilters(key: LicenseUserClientSideInfoKey, taskFilters: TaskFilters) {
    if (key === LicenseUserClientSideInfoKey.AllTaskPages) {
      this.initializeAllTaskTaskFilters();
      this.allTasksFilter.TaskFilters = taskFilters;
      this.generateFilterModel(this.allTasksFilter.TaskFilters, this.allTasksFilter.FilterModel$);
      return;
    }

    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    if (!taskBoard) {
      return;
    }

    this.initializeCurrentTaskFilters(key, taskBoard.Id, taskFilters);
    const boardGroup = this.filters.get(key);
    boardGroup[taskBoard.Id].TaskFilters = taskFilters;
    this.generateFilterModel(boardGroup[taskBoard.Id].TaskFilters, boardGroup[taskBoard.Id].FilterModel$);
  }

  getCurrentTaskFilters(): TaskFilters {
    return this.getTaskFilters(this.currentKey);
  }

  getTaskFilters(key: LicenseUserClientSideInfoKey): TaskFilters {
    if (key === LicenseUserClientSideInfoKey.AllTaskPages) {
      this.initializeAllTaskTaskFilters();
      return this.allTasksFilter.TaskFilters;
    }
    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    if (!taskBoard) {
      return null;
    }
    if (this.filters.has(key)) {
      const boardGroup = this.filters.get(key);
      if (boardGroup[taskBoard.Id]) {
        return boardGroup[taskBoard.Id].TaskFilters;
      }
    }
    return null;
  }

  getCurrentFilterModel$(): Observable<PageFilter> {
    return this.getFilterModel$(this.currentKey);
  }

  getFilterModel$(key: LicenseUserClientSideInfoKey): Observable<PageFilter> {
    if (key === LicenseUserClientSideInfoKey.AllTaskPages) {
      this.initializeAllTaskTaskFilters();
      return this.allTasksFilter.FilterModel$;
    }
    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    if (!taskBoard) {
      return of(null);
    }
    this.initializeCurrentTaskFilters(key, taskBoard.Id);

    return this.filters.get(key)[taskBoard.Id].FilterModel$;
  }

  getCurrentTaskFiltersFromApi$(licenseId: number): Observable<TaskFilters> {
    return this.getTaskFiltersFromApi$(this.currentKey, licenseId);
  }

  updateAllFiltersByGlobalSearch(searchValue: string = null, showOnlyCurrentUserTasks: boolean = null) {
    let taskFilters = this.getTaskFilters(LicenseUserClientSideInfoKey.AllTaskPages);
    if (!taskFilters) {
      taskFilters = {
        ShowOnlyCurrentUserTasks: false
      };
    }
    if (searchValue !== null) {
      taskFilters.Search = searchValue;
    }
    if (showOnlyCurrentUserTasks !== null) {
      taskFilters.ShowOnlyCurrentUserTasks = showOnlyCurrentUserTasks;
    }
    this.setTaskFilters(LicenseUserClientSideInfoKey.AllTaskPages, taskFilters);

    let setCurrentKey = false;
    Object.values(LicenseUserClientSideInfoKey).forEach((value: string) => {
      const key = LicenseUserClientSideInfoKey[value];
      let taskFilters = this.getTaskFilters(key);
      if (!taskFilters || key === LicenseUserClientSideInfoKey.AllTaskPages) {
        return;
      }
      if (key == this.currentKey) {
        setCurrentKey = true;
      }
      this.setTaskFilters(key, taskFilters);
    });
    if (!setCurrentKey) {
      this.setTaskFilters(this.currentKey, {});
    }
  }

  getTaskFiltersFromApi$(key: LicenseUserClientSideInfoKey, licenseId: number): Observable<TaskFilters> {
    return key !== 0 && (key === undefined || key === null)
      ? of(null)
      : this.licenseUsersService.getClientSideSettings$(licenseId, key)
        .pipe(map(madCloudResult => {
          let taskFilters: TaskFilters = null;
          if (!madCloudResult.Succeeded) {
            this.dialog.open(ErrorDialogComponent, {
              ...ErrorDialogComponent.DialogConfig,
              data: {
                title: 'Error Client Side Settings',
                message: 'An error occurred while trying to retrieve client side settings',
                errors: this.errorService.getErrorMessages(madCloudResult)
              }
            });
          } else if (madCloudResult.Result && madCloudResult.Result.Settings) {
            const taskFiltersByBoard = <TaskFilterGroupByBoard>JSON.parse(madCloudResult.Result.Settings);
            const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
            if (taskFiltersByBoard && Object.keys(taskFiltersByBoard).some(k => !!Number(k))) {
              if (taskBoard && taskFiltersByBoard[taskBoard.Id]) {
                taskFilters = taskFiltersByBoard[taskBoard.Id].TaskFilters;
              }
            } else {
              taskFilters = JSON.parse(madCloudResult.Result.Settings);
              if (taskFilters && key !== LicenseUserClientSideInfoKey.AllTaskPages) {
                const filtersCopy: TaskFilterGroupByBoard = {};
                filtersCopy[taskBoard.Id] = {};
                filtersCopy[taskBoard.Id].TaskFilters = taskFilters;
                this.saveSettings(licenseId, key, filtersCopy);
              }
            }
            this.setTaskFilters(key, taskFilters);
          }
          return taskFilters;
        }));
  }

  filterCurrentTasks(tasks: Task[]): Task[] {
    if ((!this.filters.has(this.currentKey) && !this.filters.has(LicenseUserClientSideInfoKey.AllTaskPages))
      || !tasks) {
      return tasks;
    }

    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    const filter = this.filters.get(this.currentKey)[taskBoard.Id].TaskFilters;
    const allTasksFilter = this.getTaskFilters(LicenseUserClientSideInfoKey.AllTaskPages) || {};
    const userId = this.currentService.getLicenseUser().User.Id;
    if (!allTasksFilter
      && (!filter
        || (filter.Priorities && filter.Priorities.length === 0
          && !filter.CustomStartDate
          && !filter.CustomDueDate
          && filter.Dates && filter.Dates.length === 0
          && filter.Users && filter.Users.length === 0
          && filter.Projects && filter.Projects.length === 0
          && filter.Statuses && filter.Statuses.length === 0
          && filter.Milestones && filter.Milestones.length === 0))) {

      return tasks;
    }
    const date = new Date();
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    const dayAfterTomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
    const startDate = filter && filter.CustomStartDate ? new Date(filter.CustomStartDate) : undefined;
    const endDate = filter && filter.CustomDueDate ? new Date(filter.CustomDueDate) : undefined;
    const lowerCaseSearch = allTasksFilter.Search ? allTasksFilter.Search.toLocaleLowerCase() : undefined;
    try {
      return tasks.filter(
        task => {
          const isInAllTaskFilters = (!allTasksFilter
            || (!allTasksFilter.ShowOnlyCurrentUserTasks || task.AssignedUserId === userId))
            && (!lowerCaseSearch ||
              (lowerCaseSearch.length > 0
                && (task.Title.toLocaleLowerCase().search(lowerCaseSearch) !== -1
                  || task.Description.toLocaleLowerCase().search(lowerCaseSearch) !== -1)));
          if (!filter) {
            return isInAllTaskFilters;
          }
          const dueDate = task.DueDate ? new Date(task.DueDate) : undefined;
          let isInDateFilter = true;
          if (filter.Dates
            && filter.Dates.length > 0) {
            isInDateFilter = false;
            if (dueDate) {
              filter.Dates.forEach(filterType => {
                switch (filterType) {
                  case PageFilterType.Overdue:
                    isInDateFilter = isInDateFilter || dueDate < date;
                    break;
                  case PageFilterType.Today:
                    isInDateFilter = isInDateFilter
                      || (dueDate >= today && dueDate < tomorrow);
                    break;
                  case PageFilterType.Tomorrow:
                    isInDateFilter = isInDateFilter
                      || (dueDate >= tomorrow && dueDate < dayAfterTomorrow);
                    break;
                  default:
                    throw new Error('Unexpected Date Filter Type. Expected Overdue, Today, or Tomorrow');
                }
              });
            }
          }
          return isInDateFilter
            && (isInAllTaskFilters)
            && (!startDate || (dueDate && dueDate > startDate))
            && (!endDate || (dueDate && dueDate < endDate))
            && (!filter.Priorities || filter.Priorities.some(priority => task.TaskPriority === priority))
            && (!filter.Users || filter.Users.some(licenseUserId => task.AssignedUserId === licenseUserId))
            && (!filter.Projects || filter.Projects.some(projectId => task.ProjectId === projectId))
            && (!filter.Statuses || filter.Statuses.some(status => task.Status === status))
            && (!filter.Milestones || filter.Milestones.some(milestone => task.Milestone === milestone));
        }
      );
    } catch (error) {
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Error Filtering Tasks',
          message: 'An unexpected error happened while filtering tasks.',
          errors: this.errorService.getErrorMessages(error)
        }
      });
    }
  }

  clearCurrentFilters(licenseId: number) {
    this.setCurrentTaskFilters(null);
    this.licenseUsersService.saveClientSideSettings$(licenseId, this.currentKey, null)
      .pipe(first())
      .subscribe(result => {
        if (!result.Succeeded) {
          this.dialog.open(ErrorDialogComponent, {
            ...ErrorDialogComponent.DialogConfig,
            data: {
              title: 'Error Saving Client Settings',
              message: 'An error occurred while attempting to save the client settings',
              errors: this.errorService.getErrorMessages(result)
            }
          });
        }
      });
  }

  openCreateTaskDialog(assignedUserId?: string): MatDialogRef<TaskProfileDialogComponent> {
    return this.dialog.open(TaskProfileDialogComponent, {
      ...TaskProfileDialogComponent.DialogConfig,
      data: { taskId: 0, assignedUserId: assignedUserId }
    });
  }

  openFilterDialog(licenseId: number): MatDialogRef<TaskFilterDialogComponent> {
    const data = {
      licenseId: licenseId,
      currentInfoKey: this.currentKey,
      filters: this.getCurrentTaskFilters()
    };
    const config = this.currentKey === LicenseUserClientSideInfoKey.CalendarTaskPage
      ? TaskFilterDialogComponent.CalendarDialogConfig
      : TaskFilterDialogComponent.DialogConfig;
    const filterDialog = this.dialog.open(TaskFilterDialogComponent, {
      ...config,
      data: data
    });
    filterDialog.afterClosed()
      .subscribe((filters: TaskFilters) => {
        if (!filters) {
          return;
        }
        this.setCurrentTaskFilters(filters);

        const taskBoardFilters = this.filters.get(this.currentKey);
        const filtersCopy: TaskFilterGroupByBoard = {};
        // tslint:disable-next-line: forin
        for (const id in taskBoardFilters) {
          filtersCopy[id] = {};
          filtersCopy[id].TaskFilters = taskBoardFilters[id].TaskFilters;
        }
        this.saveSettings(licenseId, this.currentKey, filtersCopy);
      });

    return filterDialog;
  }

  saveSettings(licenseId: number, key: LicenseUserClientSideInfoKey, settings: any) {
    this.licenseUsersService.saveClientSideSettings$(
      licenseId,
      key,
      settings).pipe(first()).subscribe(result => {
        if (!result.Succeeded) {
          this.dialog.open(ErrorDialogComponent, {
            ...ErrorDialogComponent.DialogConfig,
            data: {
              title: 'Error Saving Client Settings',
              message: 'An unexpected error happened while attempting to save the client settings.',
              errors: this.errorService.getErrorMessages(result)
            }
          });
        }
      });
  }

  private generateFilterModel(taskFilters: TaskFilters, filterModel$: BehaviorSubject<PageFilter>) {
    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    let taskBoardFilterGroup: PageFilterGroup = null;
    if (taskBoard) {
      const taskBoardIdOption: PageFilterOptions = {
        FilterType: PageFilterType.Equals,
        PropertyName: 'TaskBoardId',
        PropertyType: PageDataType.Int,
        PropertyValue: taskBoard.Id
      };
      taskBoardFilterGroup = {
        Id: 'tasks-filter-service-board-id',
        Type: PageFilterGroupType.Custom,
        Operator: PageFilterOperator.Or,
        Filters: [taskBoardIdOption]
      };
    }
    if (!taskFilters
      && (!this.allTasksFilter || !this.allTasksFilter.TaskFilters || (!this.allTasksFilter.TaskFilters.ShowOnlyCurrentUserTasks && !this.allTasksFilter.TaskFilters.Search))) {
      const filter: PageFilter = {
        Id: 'tasks-filter-service',
        Type: PageFilterGroupType.Custom,
        FilterGroups: [],
        PerPage: -1,
        OrderBy: 'Title',
        OrderDirection: 'desc'
      };
      if (taskBoardFilterGroup) {
        filter.FilterGroups.push(taskBoardFilterGroup);
      }
      filterModel$.next(filter);
      return;
    }

    const filterGroups = [];
    if (taskBoardFilterGroup) {
      filterGroups.push(taskBoardFilterGroup);
    }
    if (taskFilters) {
      if (taskFilters.Priorities) {
        const filterOptions: PageFilterOptions[] = taskFilters.Priorities.map(priority => {
          return {
            FilterType: PageFilterType.Equals,
            PropertyName: 'TaskPriority',
            PropertyType: PageDataType.Select,
            PropertyValue: priority
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-priority',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.CustomStartDate) {
        const filterOption: PageFilterOptions = {
          FilterType: PageFilterType.GreaterThan,
          PropertyName: 'DueDate',
          PropertyType: PageDataType.Date,
          PropertyValue: new Date(taskFilters.CustomStartDate).toISOString(),
          TimeZoneOffset: new Date().getTimezoneOffset()
        };
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-start-date',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [filterOption]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.CustomDueDate) {
        const filterOption: PageFilterOptions = {
          FilterType: PageFilterType.LessThan,
          PropertyName: 'DueDate',
          PropertyType: PageDataType.Date,
          PropertyValue: new Date(taskFilters.CustomDueDate).toISOString(),
          TimeZoneOffset: new Date().getTimezoneOffset()
        };
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-due-date',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [filterOption]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.Dates) {
        const filterOptions: PageFilterOptions[] = taskFilters.Dates.map(filterType => {
          return {
            FilterType: filterType,
            PropertyName: 'DueDate',
            PropertyType: PageDataType.Date,
            PropertyValue: new Date().toISOString(),
            TimeZoneOffset: new Date().getTimezoneOffset()
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-dates',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.Users) {
        const filterOptions: PageFilterOptions[] = taskFilters.Users.map(userId => {
          return {
            FilterType: PageFilterType.Equals,
            PropertyName: 'AssignedUserId',
            PropertyType: PageDataType.String,
            PropertyValue: userId
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-users',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.Projects) {
        const filterOptions: PageFilterOptions[] = taskFilters.Projects.map(projectId => {
          return {
            FilterType: PageFilterType.Equals,
            PropertyName: 'ProjectId',
            PropertyType: PageDataType.Select,
            PropertyValue: projectId
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-project-id',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.Statuses) {
        const filterOptions: PageFilterOptions[] = taskFilters.Statuses.map(status => {
          return {
            FilterType: PageFilterType.Equals,
            PropertyName: 'Status',
            PropertyType: PageDataType.Select,
            PropertyValue: status
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-statuses',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (taskFilters.Milestones) {
        const filterOptions: PageFilterOptions[] = taskFilters.Milestones.map(status => {
          return {
            FilterType: PageFilterType.Equals,
            PropertyName: 'Milestone',
            PropertyType: PageDataType.Select,
            PropertyValue: status
          };
        });
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-milestones',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: [...filterOptions]
        };
        filterGroups.push(filterGroup);
      }
    }
    if (this.allTasksFilter && this.allTasksFilter.TaskFilters) {
      if (this.allTasksFilter.TaskFilters.ShowOnlyCurrentUserTasks) {
        const userId = this.currentService.getLicenseUser().User.Id;
        const filterOptions: PageFilterOptions = {
          FilterType: PageFilterType.Equals,
          PropertyName: 'AssignedUserId',
          PropertyType: PageDataType.String,
          PropertyValue: userId
        };
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-assigned-current-user',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.And,
          Filters: [filterOptions]
        };
        filterGroups.push(filterGroup);
      }
      if (this.allTasksFilter.TaskFilters.Search && this.allTasksFilter.TaskFilters.Search.length > 0) {
        const filterOptions: PageFilterOptions[] = [{
          FilterType: PageFilterType.Contains,
          PropertyName: 'Title',
          PropertyType: PageDataType.String,
          PropertyValue: this.allTasksFilter.TaskFilters.Search
        }, {
          FilterType: PageFilterType.Contains,
          PropertyName: 'Description',
          PropertyType: PageDataType.String,
          PropertyValue: this.allTasksFilter.TaskFilters.Search
        }];
        const filterGroup: PageFilterGroup = {
          Id: 'tasks-filter-service-search',
          Type: PageFilterGroupType.Custom,
          Operator: PageFilterOperator.Or,
          Filters: filterOptions
        };
        filterGroups.push(filterGroup);
      }
    }

    const filter: PageFilter = {
      Id: 'tasks-filter-service',
      Type: PageFilterGroupType.Custom,
      FilterGroups: filterGroups,
      PerPage: -1,
      OrderBy: 'Title',
      OrderDirection: 'desc'
    };

    filterModel$.next(filter);
  }

  private initializeCurrentTaskFilters(key: LicenseUserClientSideInfoKey, taskBoardId: number, taskFilters: TaskFilters = null) {
    let boardGroup: TaskFilterGroupByBoard;
    const group: TaskFilterGroup = {
      TaskFilters: taskFilters
    };
    const taskBoard = this.taskBoardsFilterService.currentTaskBoard$.getValue();
    const taskBoardIdOption: PageFilterOptions = {
      FilterType: PageFilterType.Equals,
      PropertyName: 'TaskBoardId',
      PropertyType: PageDataType.Int,
      PropertyValue: taskBoard.Id
    };
    const taskBoardFilterGroup: PageFilterGroup = {
      Id: 'tasks-filter-service-current-board-id',
      Type: PageFilterGroupType.Custom,
      Operator: PageFilterOperator.Or,
      Filters: [taskBoardIdOption]
    };
    const filter: PageFilter = {
      Id: 'tasks-filter-service-current',
      Type: PageFilterGroupType.Custom,
      FilterGroups: [taskBoardFilterGroup],
      PerPage: -1,
      OrderBy: 'Title',
      OrderDirection: 'desc'
    };

    if (this.filters.has(key)) {
      boardGroup = this.filters.get(key);
      let boardFilterValue;
      if (boardGroup[taskBoardId] && boardGroup[taskBoardId].FilterModel$) {
        boardFilterValue = boardGroup[taskBoardId].FilterModel$.getValue();
      }
      if (!boardGroup[taskBoardId]) {
        group.FilterModel$ = new BehaviorSubject<PageFilter>(filter);
        boardGroup[taskBoardId] = group;
        this.filters.set(key, boardGroup);
      } else if (!boardGroup[taskBoardId].FilterModel$) {
        group.FilterModel$ = new BehaviorSubject<PageFilter>(filter);
      } else if (taskFilters === null && !boardFilterValue) {
        boardGroup[taskBoardId].FilterModel$.next(filter);
      }
    } else {
      group.FilterModel$ = new BehaviorSubject<PageFilter>(filter);
      boardGroup = {
        [taskBoardId]: group
      };
      this.filters.set(key, boardGroup);
    }
  }

  private initializeAllTaskTaskFilters() {
    if (!this.allTasksFilter) {
      this.allTasksFilter = {};
    }
    if (!this.allTasksFilter.FilterModel$) {
      this.allTasksFilter.FilterModel$ = new BehaviorSubject<PageFilter>(null);
    }
  }
}
