/**
 * Normalizes an HTML document by removing bold wrappers from the top level.
 */
export class BoldWrapperNormalizer {
  /**
   * Normalizes an HTML document by removing bold wrappers from the top level.
   * @param doc The HTML doc to normalize.
   */
  normalize(doc: Document) {
    const body = doc.querySelector('body');
    const childNodesLength = body.childNodes.length;

    for (let i = childNodesLength - 1; i >= 0; i--) {
      const node = body.childNodes[i] as HTMLElement;

      if (node.nodeName.toLowerCase() === 'b' && node.style['font-weight'] === 'normal') {
        node.replaceWith(...node.childNodes);
      }
    }
  }
}
