import { descendants, hasAttributeStyle } from '@common/html/util/dom';

/**
 * A class for normalizing Microsoft comments by removing the comments and unwrapping the commented content.
 */
export class MsCommentNormalizer {
  /**
   * Normalizes Microsoft comments by removing the comments and unwrapping the commented content.
   * @param doc The document to modify.
   */
  normalize(doc: Document) {
    // Do a reverse, depth first descent into the DOM normalizing comment nodes
    descendants(doc.documentElement, (element: Element) => {
      if (hasAttributeStyle(element, 'mso-comment-reference')) {
        // Unwrap the commented content
        element.replaceWith(...element.childNodes);
      } else if (element.classList?.contains('MsoCommentReference')) {
        // Remove the comment node
        element.remove();
      } else if (hasAttributeStyle(element, 'mso-element', 'comment-list')) {
        // Remove the comment list node
        element.remove();
      }
    }, { depthFirst: true, reverse: true });
  }
}
