import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Message } from '@portal-core/messages/models/message.model';

@CollectionStateSelectors({
  stateType: MessagesState
})
@State<MessagesState>({
  name: MessagesState.source
})
@Injectable()
export class MessagesState extends CollectionStateBase {
  static source = 'Messages';
  getSource(): string {
    return MessagesState.source;
  }
}


@Injectable({
  providedIn: 'root'
})
export class MessagesDataService extends CollectionDataServiceBase<Message> {
  constructor(protected store: Store) {
    super(store, MessagesState);
  }
}
