<div *ngIf="canEditAllTasks" class="mc-task-profile-attachments-add-container">
  <input #fileInput class="mc-hidden" multiple="multiple" type="file" (change)="onAddAttachmentsChanged($event)">
  <button mat-raised-button color="primary-vivid" (click)="fileInput.click()">
    <mat-icon fontSet="project-titan" fontIcon="icon-attach"></mat-icon>
    Add Attachment
  </button>
</div>
<div class="mc-task-profile-attachments-body">
  <mc-grid [columns]="columns" [items]="commentAssets$ | async" [visibleColumns]="visibleColumns" [showPagination]="false" (sortChanged)="onSortChanged($event)">
    <ng-template mcGridCell="FileName" let-data>
      <mc-task-asset [asset]="data.Asset" [canEditAllTasks]="canEditAllTasks" (delete)="onAssetDelete($event)">
      </mc-task-asset>
    </ng-template>
    <ng-template mcGridCell="CreatedOn" let-data>
      <span *ngIf="data.Asset.Id !== 0; else pending">
        {{data.Asset.CreatedOn | date:'MMM dd yyyy'}}
      </span>
      <ng-template #pending>
        Pending
      </ng-template>
    </ng-template>
    <ng-template mcGridCell="UploadedByUserId" let-data>
      <mc-license-user-avatar [licenseUser]="data.Asset.UploadedByUserId | mcLicenseUserForLicenseAsync: license.Id | async" [size]="'small'" [truncate]="true"></mc-license-user-avatar>
    </ng-template>
  </mc-grid>
</div>