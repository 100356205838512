import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppNavbarLoggedOutLaunchPadComponent } from '@env/app/app/components/app-navbar-logged-out-launch-pad/app-navbar-logged-out-launch-pad.component';
import { AppDrawerGutterComponent } from '@portal-client/app/components/app-drawer-gutter/app-drawer-gutter.component';
import { AppDrawerNavComponent } from '@portal-client/app/components/app-drawer-nav/app-drawer-nav.component';
import { AppDrawerUserLaunchPadComponent } from '@portal-client/app/components/app-drawer-user-launch-pad/app-drawer-user-launch-pad.component';
import { AppFullNavbarHeadingComponent } from '@portal-client/app/components/app-full-navbar-heading/app-full-navbar-heading.component';
import { AppGutterComponent } from '@portal-client/app/components/app-gutter/app-gutter.component';
import { AppNavComponent } from '@portal-client/app/components/app-nav/app-nav.component';
import { AppNavbarUserLaunchPadComponent } from '@portal-client/app/components/app-navbar-user-launch-pad/app-navbar-user-launch-pad.component';
import { AuthUserDataResolverService } from '@portal-client/core/auth/auth-user-data-resolver.service';
import { LicenseDefaultPageResolverService } from '@portal-client/core/auth/license-default-page-resolver.service';
import { AuthGuardService } from '@portal-core/auth/guards/auth-guard.service';
import { BrowserHostVersionGuardService } from '@portal-core/auth/guards/browser-host-version-guard.service';
import { CentralInstanceSubdomainGuardService } from '@portal-core/auth/guards/central-instance-subdomain-guard.service';
import { pageNotFoundGuard } from '@portal-core/auth/guards/page-not-found.guard';
import { SubdomainLoginGuardService } from '@portal-core/auth/guards/subdomain-login-guard.service';
import { FlareLoginEmitterResolverService } from '@portal-core/auth/resolvers/flare-login-emitter-resolver.service';
import { dataStreamInitResolver } from '@portal-core/data-stream/resolvers/data-stream-init.resolver';
import { EmptyComponent } from '@portal-core/general/components/empty/empty.component';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { FullPageComponent } from '@portal-core/layout/components/full-page/full-page.component';
import { SubPageFillComponent } from '@portal-core/layout/components/sub-page-fill/sub-page-fill.component';
import { AuthorAccessResolverGuardService } from '@portal-core/user-access/services/author-access-resolver-guard.service';
import { AuthorOrSmeAccessResolverGuardService } from '@portal-core/user-access/services/author-or-sme-access-resolver-guard.service';

const appRoutes: Routes = [
  /** This route is used when the subdomain is invalid. It is at the root because it does not require a valid subdomain. */
  {
    path: 'licenseNotFound',
    data: {
      type: AlertType.LicenseNotFound
    },
    component: FullPageComponent,
    children: [
      {
        path: '',
        outlet: 'full-navbar-heading',
        component: AppFullNavbarHeadingComponent
      },
      {
        path: '',
        loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
      }
    ]
  },
  /** The root route for the rest of the pages in the app. */
  {
    path: '',
    canActivate: [BrowserHostVersionGuardService],
    children: [
      {
        path: '',
        canActivate: [CentralInstanceSubdomainGuardService],
        resolve: {
          dataStream: dataStreamInitResolver
        },
        children: [
          /**
           * This route is used to logout a user and does not require the auto-login guard or auth-user-data resolver.
           * Both of which can redirect the user if there is an auth error.
           * We don't want redirects to happen.
           * We just want to get the user fully logged out and back to square one.
           */
          {
            path: 'logout',
            component: FullPageComponent,
            children: [
              {
                path: '',
                outlet: 'full-navbar-heading',
                component: AppFullNavbarHeadingComponent
              },
              {
                path: '',
                loadChildren: () => import('../logout/logout.module').then(m => m.LogoutModule)
              }
            ]
          },
          {
            path: 'alert',
            component: FullPageComponent,
            children: [
              {
                path: '',
                outlet: 'full-navbar-heading',
                component: AppFullNavbarHeadingComponent
              },
              {
                path: '',
                loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule)
              }
            ]
          },
          {
            path: '',
            canActivate: [SubdomainLoginGuardService],
            resolve: {
              auth: AuthUserDataResolverService
            },
            // Always run the resolver so that the auth user data gets reloaded when the user logs in
            runGuardsAndResolvers: 'always',
            children: [
              /* Anonymous Routes */
              {
                path: 'login',
                component: FullPageComponent,
                children: [
                  {
                    path: '',
                    children: [
                      {
                        path: '',
                        outlet: 'full-navbar-heading',
                        component: AppFullNavbarHeadingComponent
                      },
                      {
                        path: '',
                        loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
                      }
                    ]
                  }
                ]
              },
              {
                path: 'forgot-password',
                component: FullPageComponent,
                children: [
                  {
                    path: '',
                    outlet: 'full-navbar-heading',
                    component: AppFullNavbarHeadingComponent
                  },
                  {
                    path: '',
                    loadChildren: () => import('../forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
                  }
                ]
              },
              {
                path: 'reset-password',
                component: FullPageComponent,
                children: [
                  {
                    path: '',
                    outlet: 'full-navbar-heading',
                    component: AppFullNavbarHeadingComponent
                  },
                  {
                    path: '',
                    loadChildren: () => import('../reset-password/reset-password.module').then(m => m.ResetPasswordModule)
                  }
                ]
              },
              {
                path: 'expired-password',
                component: FullPageComponent,
                children: [
                  {
                    path: '',
                    outlet: 'full-navbar-heading',
                    component: AppFullNavbarHeadingComponent
                  },
                  {
                    path: '',
                    loadChildren: () => import('../expired-password/expired-password.module').then(m => m.ExpiredPasswordModule)
                  }
                ]
              },
              {
                path: 'confirm',
                component: FullPageComponent,
                children: [
                  {
                    path: '',
                    outlet: 'full-navbar-heading',
                    component: AppFullNavbarHeadingComponent
                  },
                  {
                    path: '',
                    outlet: 'app-navbar-launch-pad',
                    component: AppNavbarLoggedOutLaunchPadComponent
                  },
                  {
                    path: '',
                    children: [
                      {
                        path: 'sso',
                        loadChildren: () => import('../confirm/sso/sso.module').then(m => m.SsoModule)
                      },
                      {
                        path: 'sso-email',
                        loadChildren: () => import('../confirm/new-sso-user/new-sso-user.module').then(m => m.NewSsoUserModule)
                      },
                      {
                        path: '',
                        loadChildren: () => import('../confirm/new-user/new-user.module').then(m => m.NewUserModule)
                      }
                    ]
                  }
                ]
              },
              /* Authenticated Routes */
              {
                path: ':licenseId',
                canActivate: [AuthGuardService],
                resolve: {
                  loginEmitter: FlareLoginEmitterResolverService
                },
                data: {
                  hasLicenseId: true,
                  requiresAuth: true
                },
                children: [
                  {
                    path: 'confirm',
                    component: FullPageComponent,
                    children: [
                      {
                        path: '',
                        outlet: 'full-navbar-heading',
                        component: AppFullNavbarHeadingComponent
                      },
                      {
                        path: '',
                        outlet: 'app-navbar-launch-pad',
                        component: AppNavbarUserLaunchPadComponent
                      },
                      {
                        path: '',
                        children: [
                          {
                            path: 'slack',
                            loadChildren: () => import('../confirm/slack/slack.module').then(m => m.SlackModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                        ]
                      }
                    ]
                  },
                  {
                    path: '',
                    component: SubPageFillComponent,
                    children: [
                      {
                        path: '',
                        outlet: 'app-nav',
                        component: AppNavComponent
                      },
                      {
                        path: '',
                        outlet: 'app-navbar-launch-pad',
                        component: AppNavbarUserLaunchPadComponent
                      },
                      {
                        path: '',
                        outlet: 'app-drawer-nav',
                        component: AppDrawerNavComponent
                      },
                      {
                        path: '',
                        outlet: 'app-drawer-gutter',
                        component: AppDrawerGutterComponent
                      },
                      {
                        path: '',
                        outlet: 'app-drawer-launch-pad',
                        component: AppDrawerUserLaunchPadComponent
                      },
                      {
                        path: '',
                        outlet: 'app-gutter',
                        component: AppGutterComponent
                      },
                      {
                        path: '',
                        children: [
                          {
                            path: '',
                            resolve: {
                              defaultPage: LicenseDefaultPageResolverService
                            },
                            component: EmptyComponent
                          },
                          {
                            path: 'home',
                            loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'projects',
                            loadChildren: () => import('../projects/projects.module').then(m => m.ProjectsModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'reviews',
                            loadChildren: () => import('../reviews/reviews.module').then(m => m.ReviewsModule),
                            resolve: {
                              allowedAccess: AuthorOrSmeAccessResolverGuardService
                            }
                          },
                          {
                            path: 'teams',
                            loadChildren: () => import('../teams/teams.module').then(m => m.TeamsModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'users',
                            loadChildren: () => import('../users/users.module').then(m => m.UsersModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'tasks',
                            loadChildren: () => import('../tasks/tasks.module').then(m => m.TasksModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'analytics',
                            loadChildren: () => import('../output-analytics/output-analytics.module').then(m => m.OutputAnalyticsModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          },
                          {
                            path: 'sites',
                            loadChildren: () => import('../sites/sites.module').then(m => m.SitesModule),
                            resolve: {
                              allowedAccess: AuthorAccessResolverGuardService
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              /* No matching routes */
              {
                path: '**',
                canActivate: [pageNotFoundGuard],
                component: AppNavComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule],
  providers: [AuthUserDataResolverService]
})
export class AppRoutingModule { }
