import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttr, modifyDomOutputSpec } from '@common/util/schema';
import { MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export const madcapConditionsAttrName = 'MadCap:conditions';
export const madcapExcludeActionAttrName = 'MadCap:excludeAction';

export class MadCapConditionSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapconditionaltext: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:conditionalText',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-conditional-text' }, 0]; },
      tagName: 'MadCap:conditionalText'
    }
  };

  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    Object.entries(nodes).forEach(([name, node]) => {
      this.assignConditionsAttributes(name, node);
    });
  }

  private assignConditionsAttributes(name: string, spec: MarkSpec | NodeSpec) {
    if (name !== 'text') {
      // Add the default attributes
      spec.attrs = Object.assign(spec.attrs || {}, {
        [madcapConditionsAttrName]: { default: undefined },
        [madcapExcludeActionAttrName]: { default: undefined },
      });

      if (Array.isArray(spec.parseDOM)) {
        spec.parseDOM.forEach(parser => {
          const originalGetAttrs = parser.getAttrs;

          parser.getAttrs = function (dom: Element) {
            let attrs = originalGetAttrs ? originalGetAttrs.apply(this, arguments) : {};

            // Do not modify the attrs if it is a false match (aka not a match)
            if (attrs === false) {
              return false;
            }

            const nodeCondition = getSchemaAttr(dom, madcapConditionsAttrName);
            if (nodeCondition) {
              attrs = attrs || {}; // Ensure attrs exists
              attrs[madcapConditionsAttrName] = nodeCondition;
            }

            const nodeExcludeAction = getSchemaAttr(dom, madcapExcludeActionAttrName);
            if (nodeExcludeAction) {
              attrs = attrs || {}; // Ensure attrs exists
              attrs[madcapExcludeActionAttrName] = nodeExcludeAction;
            }

            return attrs;
          };
        });
      }

      const originalToDOM = spec.toDOM;
      spec.toDOM = function (node: ProseMirrorNode) {
        const dom = originalToDOM.apply(this, arguments);

        const nodeCondition = node.attrs[madcapConditionsAttrName];
        const nodeExcludeAction = node.attrs[madcapExcludeActionAttrName];

        if (nodeCondition || nodeExcludeAction) {
          modifyDomOutputSpec(dom, {
            attrs: {
              add: {
                [madcapConditionsAttrName]: nodeCondition,
                [madcapExcludeActionAttrName]: nodeExcludeAction,
              }
            }
          });
        }

        return dom;
      };
    }
  }
}
