import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class DefinitionListSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    definition_list: {
      group: 'block',
      content: 'definition_list_item+',
      list: true,
      parseDOM: [{
        tag: 'dl'
      }],
      toDOM() { return ['dl', 0]; },
      tagName: 'dl',
      specContent: 'definition_term'
    },

    definition_term: {
      group: 'definition_list_item',
      content: 'mcCentralContainer | block+',
      linkBucket: 'definiton_list_item',
      defining: true,
      listItem: true,
      defaultContentToNbsp: true,
      parseDOM: [{
        tag: 'dt'
      }],
      toDOM() { return ['dt', 0]; },
      tagName: 'dt',
      specContext: 'definition_list'
    },

    definition_description: {
      group: 'definition_list_item',
      content: 'mcCentralContainer | block+',
      linkBucket: 'definiton_list_item',
      defining: true,
      listItem: true,
      defaultContentToNbsp: true,
      parseDOM: [{
        tag: 'dd'
      }],
      toDOM() { return ['dd', 0]; },
      tagName: 'dd',
      specContext: 'definition_list'
    }
  };
}
