import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { forEach } from 'lodash';
import { Plugin, PluginKey, PluginView, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Observable, Subject, Subscription } from 'rxjs';

export interface MetaDataState {
  metaData: Dictionary<Dictionary>;
  metaDataVersion: number;
}

export const metaDataKey = new PluginKey<MetaDataState>('metaData');

export interface MetaDataPluginOptions {
  metaData$?: Observable<MetaDataValues>;
}

class MetaDataPluginView implements PluginView {
  private metaDataSubscription: Subscription = null;

  constructor(editorView: EditorView, private metaData$?: Observable<MetaDataValues>) {
    // In some cases an error raised in collab mode if the 'init' function is called without setTimeout().
    // Need to study the issue more deeply.
    setTimeout(() => this.init(editorView));
  }

  destroy() {
    this.metaDataSubscription?.unsubscribe();
  }

  private init(editorView: EditorView) {
    this.metaDataSubscription = this.metaData$
      .subscribe(metaDataValues => {
        // If metaDataValues is missing it means that the editor has started to loading a topic
        // and has emitted an initial null value to reset previous this.metaData$ value.
        if (!metaDataValues)
          return;
        // Its possible that the text editor was destroyed since this request was made so check that it still exists
        if (!editorView.isDestroyed) {
          // Update the meta data with the provided data
          const tr = editorView.state.tr;
          tr.setMeta('metaData.update', metaDataValues.metaData);
          editorView.dispatch(tr);
        }
      });
  }
}

/*
 * metaDataPlugin
 * Handles transactions that are requesting that the document's meta data be set or update.
 * Updates the meta data in the EditorState based on the transaction.
 *
 */
export function metaDataPlugin(options: MetaDataPluginOptions): Plugin<MetaDataState> {
  const updateSource$: Subject<MetaDataState> = new Subject<MetaDataState>();

  return new Plugin({
    key: metaDataKey,

    update$: updateSource$.asObservable(),

    state: {
      init: function (): MetaDataState {
        return {
          metaData: {},
          metaDataVersion: 0
        };
      },
      apply: function (tr: Transaction, stateValue: MetaDataState): MetaDataState {
        // Check for a metaData.update message
        const metaDataUpdate = tr.getMeta('metaData.update');
        if (metaDataUpdate) {
          // Loop through each update group setting the group on the metaData
          forEach(metaDataUpdate, (dictionary, groupName) => {
            stateValue.metaData[groupName] = dictionary;
          });

          updateSource$.next(stateValue);
        }

        return stateValue;
      }
    },

    view(view: EditorView): PluginView {
      return new MetaDataPluginView(view, options.metaData$);
    }
  });
}
