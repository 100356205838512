import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CommentBoxComponent } from '@portal-core/tasks/components/comment-box/comment-box.component';
import { TaskCommentLevel } from '@portal-core/tasks/enums/task-comment-level.enum';
import { TaskCommentDelete } from '@portal-core/tasks/models/task-comment-delete.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { TaskCommentsDialogService } from '@portal-core/tasks/services/task-comments-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskCommentComponent implements OnInit {
  taskCommentLevel: any = TaskCommentLevel;
  @ViewChild(CommentBoxComponent, { static: true }) commentComp: CommentBoxComponent;
  @Input() comment: TaskComment;
  @Input() level: TaskCommentLevel;
  @Input() licenseId: number;
  @Input() canEditAllTasks: boolean;
  @Input() checkIsDirty: boolean = false;
  @Output() delete: EventEmitter<TaskCommentDelete> = new EventEmitter();
  @Output() isDirtyChanged: EventEmitter<any> = new EventEmitter();
  showReply: boolean = false;
  licenseUser$: Observable<LicenseUser>;
  replyComment: TaskComment;
  isDirty: boolean = false;

  constructor(
    private licenseUsersService: LicenseUsersService,
    private taskCommentsDialogService: TaskCommentsDialogService) { }

  ngOnInit() {
    this.licenseUser$ = this.licenseUsersService.getLicenseUserByUserId$(this.comment.CreatedByUserId, this.licenseId);
    this.replyComment = this.taskCommentsDialogService.comments.find(c => c.ParentTaskCommentId === this.comment.Id);
    this.showReply = !!this.replyComment;
  }

  onReplyClicked() {
    if (this.showReply) {
      return;
    }

    this.replyComment = this.taskCommentsDialogService.addComment(this.comment);
    this.showReply = true;
  }

  onDeleteClicked() {
    this.delete.emit({ Comment: this.comment });
  }

  onCommentBoxDirtyChanged() {
    this.isDirty = this.commentComp.isDirty;
    this.isDirtyChanged.emit();
  }
}
