import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapPopupSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcappopup: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      defining: true,
      parseDOM: [{
        tag: 'MadCap\\:popup',
      }],
      toDOM() { return ['span', { class: 'mc-madcap-popup' }, 0]; },
      tagName: 'MadCap:popup'
    },

    madcappopuphead: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      defining: true,
      parseDOM: [{
        tag: 'MadCap\\:popupHead',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-popup-head' }, 0]; },
      tagName: 'MadCap:popupHead'
    },

    madcappopupbody: {
      group: 'inline',
      inline: true,
      code: true,
      content: 'inline*',
      defining: true,
      parseDOM: [{
        tag: 'MadCap\\:popupBody',
        preserveWhitespace: 'full'
      }],
      toDOM() { return ['span', { class: 'mc-madcap-popup-body' }, 0]; },
      tagName: 'MadCap:popupBody'
    }
  };
}
