import { PasteNormalizer } from '@common/clipboard/paste-normalizer';
import { HtmlPasteNormalizerOptions } from '@common/clipboard/types/html-paste-normalizer-options.type';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

const PasteNormalizerKey = new PluginKey('PasteNormalizer');

export interface PasteNormalizerPluginOptions extends HtmlPasteNormalizerOptions {
  onError?: (error: Error) => void;
};

/**
 * A ProseMirror plugin for extracting and cleaning html from the clipboard.
 * Handles CF_HTML, MS_HTML (from Microsoft Word), Excel, and Google Doc formats.
 */
export function pasteNormalizerPlugin(options?: PasteNormalizerPluginOptions): Plugin {
  return new Plugin({
    key: PasteNormalizerKey,

    props: {
      transformPastedHTML(html: string, view: EditorView) {
        try {
          return new PasteNormalizer().normalize(html, options);
        } catch (error) {
          options.onError?.(error);
          // There was a problem normalizing the pasted content and we don't want to paste invalid HTML into the editor so just return an empty string so nothing is pasted
          return '';
        }
      }
    }
  });
}
