import { McError } from '@common/errors/mc-error';
import { PackagedError } from '@common/errors/types/packaged-error.type';
import { instanceOfHttpErrorResponse, instanceOfMadCloudError, instanceOfMadCloudResult, instanceOfWrappedError } from '@common/errors/util/types';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { HttpErrorResponse } from '@common/http/models/http-error-response.model';
import { array } from '@common/util/array';

export function getErrorMessages(error: PackagedError): string[] {
  let errorMessages: string[];

  if (!error) {
    return errorMessages;
  }

  if (error instanceof McError) {
    errorMessages = getErrorMessages((error.name ? error.name + ': ' : '') + (error.message ?? '') + (typeof error.code === 'number' ? ` (${error.code})` : ''));
  } else if (error instanceof Error) {
    errorMessages = getErrorMessages((error.name ? error.name + ': ' : '') + error.message ?? '');
  } else if (instanceOfWrappedError(error)) {
    errorMessages = getErrorMessages(error.error);
  } else if (instanceOfHttpErrorResponse(error)) {
    errorMessages = getErrorMessages(array(error.error?.ModelState?.errors) ?? error.error?.Message ?? array(error.error?.Errors) ?? error.error?.error ?? error.statusText);
  } else if (instanceOfMadCloudError(error)) {
    errorMessages = getErrorMessages(error.Message);
  } else if (instanceOfMadCloudResult(error)) {
    errorMessages = getErrorMessages(error.Errors);
  } else if (Array.isArray(error)) {
    errorMessages = error.flatMap(err => getErrorMessages(err));
  } else if (typeof error === 'string') {
    error = error.trim();
    errorMessages = error ? [error] : undefined;
  }

  // Remove empty messages
  if (Array.isArray(errorMessages)) {
    errorMessages = errorMessages.filter(message => !!message);
  }

  // Return undefined if there are no messages
  return errorMessages?.length > 0 ? errorMessages : undefined;
}

export function getMcErrors(error: PackagedError): McError[] {
  if (instanceOfHttpErrorResponse(error) && error.status === 404) {
    return [new McError('Not found.', ErrorCode.NotFoundError)];
  } else if (instanceOfHttpErrorResponse(error) && instanceOfMadCloudResult(error.error)) {
    if (Array.isArray(error.error.Errors)) {
      return error.error.Errors.filter(madCloudError => Number.isInteger(madCloudError.Code)).map(madCloudError => new McError(madCloudError.Message, madCloudError.Code));
    }
  } else if (instanceOfMadCloudResult(error)) {
    if (Array.isArray(error.Errors)) {
      return error.Errors.filter(madCloudError => Number.isInteger(madCloudError.Code)).map(madCloudError => new McError(madCloudError.Message, madCloudError.Code));
    }
  } else if (error instanceof McError) {
    return [error];
  } else if (Array.isArray(error)) {
    return error.map(err => {
      if (err instanceof McError) {
        return err;
      } else if (instanceOfMadCloudError(err) && Number.isInteger(err.Code)) {
        return new McError(err.Message, err.Code);
      }
    }).filter(err => !!err);
  }

  return [new McError('Unknown error.', ErrorCode.UnknownError)];
}

export function getErrorCodes(error: PackagedError): ErrorCode[] {
  return getMcErrors(error).map(err => err.code);
}

export function hasErrorCode(error: PackagedError, errorCode: ErrorCode): boolean {
  return getMcErrors(error).some(err => err.code === errorCode);
}

export function getErrorMessageForCode(error: PackagedError, errorCode: ErrorCode): string {
  return getMcErrors(error).find(err => err.code === errorCode)?.message;
}

export function hasHttpStatus(error: HttpErrorResponse, statusCode: number): boolean {
  if (error?.status === statusCode || error?.error?.HttpStatusCode === statusCode) {
    return true;
  }
}
