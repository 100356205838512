import { NodeType } from '@common/html/enums/node-type.enum';

const RtlLanguageCodes = {
  ar: 'arabic',
  arc: 'aramaic',
  dv: 'divehi',
  fa: 'persian',
  ha: 'hausa',
  he: 'hebrew',
  khw: 'khowar',
  ks: 'kashmiri',
  ku: 'kurdish',
  ps: 'pashto',
  ur: 'urdu',
  yi: 'yiddish'
};

export const nbsp = String.fromCharCode(160);
export const nbspEntityName = '&nbsp;';
export const nbspEntityNumber = '&#160;';

export function isRtl(languageCode: string): boolean {
  if (!languageCode) {
    return false;
  }

  // Separate the primary and subcode
  const index = languageCode.indexOf('-');
  const primaryCode = index === -1 ? languageCode : languageCode.substring(0, index);
  return !!RtlLanguageCodes[primaryCode];
}

/**
 * Returns an element's attribute values for a set of names.
 * If no names are given then all the attributes are returned.
 * @param element The element to get the attributes from.
 * @param attrs The list of attribute names to get.
 * @returns A dictionary of attribute name/value pairs.
 */
export function getAttrs(element: Element, attrs?: string[]): Dictionary<string> {
  const newAttrs = {};

  if (Array.isArray(attrs)) {
    for (let i = 0, length = attrs.length; i < length; i += 1) {
      if (element.hasAttribute(attrs[i])) {
        newAttrs[attrs[i]] = element.getAttribute(attrs[i]);
      } else if (element.hasAttribute(attrs[i].toLowerCase())) {
        newAttrs[attrs[i]] = element.getAttribute(attrs[i].toLowerCase());
      }
    }
  } else {
    for (let i = 0, length = element.attributes.length; i < length; i += 1) {
      const attr = element.attributes[i];

      newAttrs[attr.nodeName] = attr.nodeValue;
    }
  }

  return newAttrs;
}

export function removeAttrs(element: Element, attrs: string[]) {
  if (Array.isArray(attrs)) {
    for (let i = 0, length = attrs.length; i < length; i += 1) {
      element.removeAttribute(attrs[i]);
    }
  }
}

/**
 * Removes classes from an element.
 * If the predicate returns truthy then that class is removed.
 * If the element has no more classes after processing all the classes then the class attribute is removed from the element.
 * @param element The element to remove the classes from.
 * @param predicate The function called for each class on the element.
 */
export function removeClasses(element: Element, predicate: (className: string) => boolean) {
  if (element.classList) {
    // Remove the unwanted classes. Some elements do not have classList (eg text nodes)
    element.classList.remove(...Array.from(element.classList).filter(className => predicate(className)));

    // Remove the class attribute if empty
    if (element.classList.length === 0) {
      element.removeAttribute('class');
    }
  }
}

/**
 * Removes styles from an element.
 * If the predicate returns truthy then that style is removed.
 * If the element has no more styles after processing all the styles then the style attribute is removed from the element.
 * @param element The element to remove the styles from.
 * @param predicate The function called for each style property.
 */
export function removeStyles(element: Element, predicate: (name: string, value: string) => boolean) {
  // Get the style attribute of the element. Some elements do not have getAttribute (eg text nodes)
  const style = element.getAttribute?.('style');
  if (!style) {
    return;
  }

  // Split the styles into an array
  const styles = style.split(';');

  // Filter out the unwanted styles
  const filteredStyles = styles.filter(style => {
    // Split the style into the name and value
    const [name, value] = style.split(':').map(str => str.trim());
    return !predicate(name, value);
  });

  // Join the remaining styles back into a string
  const newStyle = filteredStyles.join(';');

  // Set the style attribute of the element to the new string or remove it if empty
  if (newStyle) {
    element.setAttribute('style', newStyle);
  } else {
    element.removeAttribute('style');
  }
}

/**
 * Replaces style values on an element.
 * The iteratee function can return a new value, null, or undefined.
 * If undefined is returned then the style's value is unchanged.
 * If null is returned then the style is removed.
 * If a new value is returned then the style's value is changed to the new value.
 * If the element has no more styles after processing all the styles then the style attribute is removed from the element.
 * @param element The element to replace the styles on.
 * @param iteratee The function called for each style property.
 */
export function replaceStyles(element: Element, iteratee: (name: string, value: string) => string | null | undefined) {
  // Get the style attribute of the element. Some elements do not have getAttribute (eg text nodes)
  const style = element.getAttribute?.('style');
  if (!style) {
    return;
  }

  // Split the styles into an array
  const styles = style.split(';');

  // Map the styles to their new value and remove any that are empty
  const newStyles = styles.map(style => {
    // Split the style into the name and value
    const [name, value] = style.split(':').map(str => str.trim());
    const newValue = iteratee(name, value);
    if (typeof newValue === 'undefined') {
      return style.trim();
    } else if (newValue) {
      return (name + ':' + newValue).trim();
    } else {
      return null;
    }
  }).filter(style => !!style);

  // Join the remaining styles back into a string
  const newStyle = newStyles.join('; ');

  // Set the style attribute of the element to the new string or remove it if empty
  if (newStyle) {
    element.setAttribute('style', newStyle);
  } else {
    element.removeAttribute('style');
  }
}

/**
 * Returns the value of a style from the style attribute (as opposed to the style property).
 * @param element The element to get the style from.
 * @param styleName The name of the style to get.
 * @returns The value of the style.
 */
export function getAttributeStyle(element: Element, styleName: string): { name: string, value: string } {
  return element.getAttribute?.('style')?.split(';')?.map(style => {
    const split = style.split(':');
    return {
      name: split[0]?.trim(),
      value: split[1]?.trim()
    };
  })?.find(pair => pair.name === styleName);
}

/**
 * Returns whether an element has a style in its style attribute (as opposed to the style property).
 * If a value is given then it will also check that the style has the given value.
 * @param element The element to check.
 * @param styleName The name of the style to check.
 * @param styleValue Optionally the value of the style to check.
 * @returns Whether the element has the style.
 */
export function hasAttributeStyle(element: Element, styleName: string, styleValue?: string): boolean {
  const style = getAttributeStyle(element, styleName);
  return style && style.name === styleName && (typeof styleValue !== 'undefined' ? style.value === styleValue : true);
}

/**
 * Deep clones an element with a new tag name.
 * @param element The element to clone.
 * @param tagName The new tag name.
 * @returns The cloned element.
 */
export function cloneElementWithNewName(element: HTMLElement, tagName: string): HTMLElement {
  // Create a new element
  const newElement = element.ownerDocument.createElement(tagName);

  // Copy the attributes
  for (let i = 0; i < element.attributes.length; i += 1) {
    const attr = element.attributes[i];
    newElement.setAttribute(attr.nodeName, attr.nodeValue);
  }

  // Copy the children
  for (let i = 0; i < element.childNodes.length; i += 1) {
    newElement.appendChild(element.childNodes[i].cloneNode(true));
  }

  return newElement;
}

/**
 * Splits the DOM tree at cutNode by moving cutNode to be a direct child of boundNode.
 * Moving everything before cutNode into its own branch before cutNode and everything after cutNode into its own branch after cutNode.
 * If the bound node is not found then the node will not be split.
 * e.g. cutting the `<br>` node in the following DOM tree
 * ```html
 * <p>
 *   foo
 *   <br>
 *   bar
 * </p>
 * ```
 * will become
 * ```html
 * <p>foo</p>
 * <br>
 * <p>bar</p>
 * ```
 * @param boundNode The node to stop splitting at. This node will not be split.
 * @param cutNode The node to split at. This node will be moved to be a direct child of boundNode.
 */
export function splitNodeAt(cutNode: Node, boundNode: Node) {
  // Check that the cut node is a descendant of the bound node
  if (!closestNode(cutNode, ancestor => ancestor === boundNode)) {
    return;
  }

  let grandparent: ParentNode;

  for (let parent = cutNode.parentNode; boundNode !== parent; parent = grandparent) {
    const right = parent.cloneNode(false);
    while (cutNode.nextSibling) {
      right.appendChild(cutNode.nextSibling);
    }

    grandparent = parent.parentNode;
    grandparent.insertBefore(right, parent.nextSibling);
    grandparent.insertBefore(cutNode, right);
  }
}

/**
 * Unwraps a node from all its ancestors up to but not including boundNode.
 * If the bound node is not found then the node will not be unwrapped.
 * @param node The node to unwrap.
 * @param boundNode The ancestor node to stop the unwrapping at.
 */
export function unwrapNode(node: Node, boundNode: Node) {
  let parentElement = node.parentNode;
  while (parentElement && parentElement?.parentNode !== boundNode) {
    parentElement = parentElement.parentNode;
  }

  if (parentElement) {
    (parentElement as Element).replaceWith(node);
  }
}

/**
 * Returns the closest ancestor node that matches the predicate.
 * @param node The node to start searching from.
 * @param predicate A function to call for each ancestor node. If the function returns true then that ancestor is returned.
 * @returns The closest ancestor node that matches the predicate or `undefined` if no ancestor matches.
 */
export function closestNode(node: Node, predicate: (ancestor: Node) => boolean): Node {
  let parent = node.parentNode;

  while (parent) {
    if (predicate(parent)) {
      return parent;
    }

    parent = parent.parentNode;
  }
}

/**
 * Uses preorder traversal to create an array of all the nodes in the tree.
 * The array will not include the node that is passed in.
 * @param node The node to start traversing from.
 * @returns An array of all the nodes in the tree.
 */
export function flattenNode(node: Node): Node[] {
  const nodes: Node[] = [];

  function traverse(tNode: Node) {
    if (tNode !== node) {
      nodes.push(tNode);
    }
    tNode.childNodes.forEach(traverse);
  }

  traverse(node);

  return nodes;
}

/**
 * Iterates over all the descendent nodes of a node.
 * @param node The node to descend into.
 * @param iteratee A function to call for each descendant node.
 * @param options Options for how to iterate. Set reverse to true to iterate over children in reverse order.
 */
export function descendants(node: Node, iteratee: (node: Node) => boolean | void, options?: { depthFirst?: boolean, reverse?: boolean }) {
  const childNodes = node.childNodes;
  const childNodesLength = childNodes.length;

  if (options?.reverse) {
    for (let i = childNodesLength - 1; i >= 0; i -= 1) {
      const child = childNodes[i];

      if (options?.depthFirst) {
        descendants(child, iteratee, options);
        iteratee(child);
      } else {
        const stopDescending = iteratee(child);
        if (stopDescending) {
          continue;
        }
        descendants(child, iteratee, options);
      }
    }
  } else {
    for (let i = 0; i < childNodesLength; i += 1) {
      const child = childNodes[i];

      if (options?.depthFirst) {
        descendants(child, iteratee, options);
        iteratee(child);
      } else {
        const stopDescending = iteratee(child);
        if (stopDescending) {
          continue;
        }
        descendants(child, iteratee, options);
      }
    }
  }
}

/**
 * Trims the whitespace from the left side of a node.
 * Removes text nodes if they contain only whitespace.
 * Removes any whitespace on the left side of a text node that contains more than just whitespace.
 * Modifies the node that is passed in.
 * @param node The node to trim.
 * @returns The node that was passed in.
 */
export function trimNodeLeft(node: Node): Node {
  const childNodes = node.childNodes;
  const childNodesLength = childNodes.length;

  // Because SimpleDom does not use live lists we have to use code that does not depend on live lists
  const nodeIndicesToRemove: number[] = [];

  for (let i = 0; i < childNodesLength; i += 1) {
    const childNode = childNodes[i];

    if (childNode.nodeType === NodeType.TEXT_NODE) {
      if (childNode.nodeValue.trim() === '') {
        nodeIndicesToRemove.push(i);
      } else {
        childNode.nodeValue = childNode.nodeValue.trimLeft();
        break;
      }
    } else {
      break;
    }
  }

  if (nodeIndicesToRemove.length > 0) {
    // Remove the nodes in reverse order so that the indices don't change from a live list
    for (let i = nodeIndicesToRemove.length - 1; i >= 0; i -= 1) {
      node.removeChild(childNodes[nodeIndicesToRemove[i]]);
    }
  }

  return node;
}

/**
 * Trims the whitespace from the right side of a node.
 * Removes text nodes if they contain only whitespace.
 * Removes any whitespace on the right side of a text node that contains more than just whitespace.
 * Modifies the node that is passed in.
 * @param node The node to trim.
 * @returns The node that was passed in.
 */
export function trimNodeRight(node: Node): Node {
  const childNodes = node.childNodes;
  const childNodesLength = childNodes.length;

  for (let i = childNodesLength - 1; i >= 0; i -= 1) {
    const childNode = childNodes[i];

    if (childNode.nodeType === NodeType.TEXT_NODE) {
      if (childNode.nodeValue.trim() === '') {
        node.removeChild(childNode);
      } else {
        childNode.nodeValue = childNode.nodeValue.trimRight();
        break;
      }
    } else {
      break;
    }
  }

  return node;
}

/**
 * Trims the whitespace from the left and right side of a node.
 * Removes text nodes if they contain only whitespace.
 * Removes any whitespace on the left and right side of a text node that contains more than just whitespace.
 * Modifies the node that is passed in.
 * @param node The node to trim.
 * @returns The node that was passed in.
 */
export function trimNode(node: Node): Node {
  return trimNodeRight(trimNodeLeft(node));
}
