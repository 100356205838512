import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mc-app-nav-has-visited-chip',
  templateUrl: './app-nav-has-visited-chip.component.html',
  styleUrls: ['./app-nav-has-visited-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNavHasVisitedChipComponent { }
