import { Injectable } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { Conversation } from '@portal-core/messages/models/conversation.model';
import { MessagePage } from '@portal-core/messages/models/message-page.model';
import { ConversationsApiService } from '@portal-core/messages/services/conversations-api.service';
import { ConversationsDataService } from '@portal-core/messages/services/conversations-data.service';
import { MessagesService } from '@portal-core/messages/services/messages.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ConversationsService extends CollectionServiceBase<Conversation> {
  constructor(private conversationsApiService: ConversationsApiService,
    private conversationsDataService: ConversationsDataService,
    private messagesService: MessagesService,
    protected dataService: DataService) {
    super(conversationsDataService, dataService);
  }

  // protected fetchItemById$(itemId: number): Observable<Conversation> {
  //   return this.conversationsApiService.getConversationById$(itemId, true);
  // }

  getMyConversationUnreadCountByLicenseId$(licenseId: number): Observable<number> {
    return this.conversationsApiService.getMyConversationUnreadCountByLicenseId$(licenseId);
  }

  getConversationsByLicenseId$(licenseId: number, options: GetDataOptions = null): Observable<Conversation[]> {
    return this.dataService.getData<Conversation[]>({
      get: () => this.conversationsDataService.getItemsByProperties$('LicenseId', licenseId),
      fetch: () => this.conversationsApiService.getConversationsByLicenseId$(licenseId),
      set: data => {
        this.conversationsDataService.addItems$(data);
      },
    }, options);
  }

  getConversationMessages$(conversationId: number, markRead: boolean, count: number, messageId?: number): Observable<MessagePage> {
    return this.conversationsApiService.getConversationMessages$(conversationId, markRead, count + 1, messageId).pipe(
      map(conversation => {
        return {
          AllMessagesLoaded: conversation.Messages.length < count + 1,
          Conversation: conversation,
          Messages: conversation.Messages.slice(0, count)
        };
      }),
      tap(messagePage => {
        this.updateItems$([messagePage.Conversation]);

        if (messagePage.Messages.length > 0) {
          this.messagesService.addItems$(messagePage.Messages);
        }
      })
    );
  }

  addMessageToConversation$(conversationId: number, message: string): Observable<Conversation> {
    return this.conversationsApiService.addMessageToConversation$(conversationId, message).pipe(
      tap(conversation => {
        const newMessage = conversation.Messages[conversation.Messages.length - 1];
        if (newMessage) {
          this.messagesService.addItems$([newMessage]);
        }
      })
    );
  }

  createConversation$(userId: string, licenseId: number, message: string, teamId?: number, recipientIds?: string[]): Observable<Conversation> {
    const conversation = {
      RecipientIds: recipientIds,
      TeamId: teamId,
      Body: message,
      OwnerId: userId,
      LicenseId: licenseId
    };

    return this.conversationsApiService.createConversation$(conversation).pipe(
      tap(newConversation => this.addItems$([newConversation]))
    );
  }

  hideConversation$(conversationId: number): Observable<any> {
    return this.conversationsApiService.hideConversation$(conversationId).pipe(
      tap(() => this.conversationsDataService.removeItems$([conversationId]))
    );
  }

  deleteMessage$(messageId: number): Observable<any> {
    return this.conversationsApiService.deleteMessage$(messageId).pipe(
      tap(() => this.messagesService.removeItems$([messageId]))
    );
  }
}
