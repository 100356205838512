import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BranchesService } from '@portal-core/branches/services/branches.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { NonBatchTargetFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { TranslationPackageUpdate } from '@portal-core/translation-packages/models/translation-package-update.model';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { LoadingState } from '@portal-core/util/loading-state';

interface TranslationPackageSettingsForm {
  PackageDescription: FormControl<string>;
  DueDate: FormControl<Date>;
  Branch: FormControl<string[]>;
  Targets: FormControl<string[]>;
}

@Component({
  selector: 'mc-translation-package-settings-form',
  templateUrl: './translation-package-settings-form.component.html',
  styleUrls: ['./translation-package-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageSettingsFormComponent implements OnChanges {
  @Input() translationPackage: TranslationPackage;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    return this.translationPackageSettingsForm?.dirty;
  }

  NonBatchTargetFileFilter: string = NonBatchTargetFileFilter;
  translationPackageSettingsForm: FormGroup<TranslationPackageSettingsForm>;
  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private translationPackagesService: TranslationPackagesService,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private branchesService: BranchesService,
    private snackBar: MatSnackBar) {
    // Do this in the constructor so that it is ready for ngOnChanges
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.translationPackage) {
      this.resetForm(this.translationPackage || {} as TranslationPackage);
    }
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    this.savingState.update(true);

    const translationPackageUpdate: TranslationPackageUpdate = {
      ProjectDescription: formGroup.value['PackageDescription'],
      Id: this.translationPackage.Id,
      DueDate: !!formGroup.value['DueDate'] ? formGroup.value['DueDate']?.toISOString() : null,
      FlattenSnippets: false,
      CreateLanguageSkin: false,
      TargetPaths: formGroup.value['Targets'].join('|'),
      SourceBranchName: this.translationPackage.SourceBranchName
    }

    this.translationPackagesService.updatePackage$(translationPackageUpdate)
      .subscribe(() => {
        this.savingState.update(false);
        this.translationPackageSettingsForm.markAsPristine();
        this.snackBar.open('The settings have been saved.', 'Ok', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to update the translation package settings.', this.errorService.getErrorMessages(error));
      });
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  protected buildForm() {
    this.translationPackageSettingsForm = this.fb.group({
      PackageDescription: new FormControl(null),
      DueDate: new FormControl(null),
      Branch: new FormControl(null),
      Targets: new FormControl([])
    });
  }

  protected resetForm(translationPackage: TranslationPackage) {
    this.translationPackageSettingsForm.reset({
      DueDate: translationPackage.DueDate ? new Date(translationPackage.DueDate) : null,
      PackageDescription: translationPackage.ProjectDescription || '',
      Branch: [this.branchesService.buildBranchId(translationPackage.ProjectId, translationPackage.SourceBranchName)],
      Targets: translationPackage.TargetPaths ? translationPackage.TargetPaths.split('|') : []
    });
    if (translationPackage.UseMadTranslations)
      this.translationPackageSettingsForm.disable();
    else {
      this.translationPackageSettingsForm.controls['Branch'].disable();
    }
  }

  getBranchNameFromId(branchId: string): string {
    return this.branchesService.getBranchNameFromId(branchId);
  }

}
