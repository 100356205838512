/** object representation for style sizings, good to know the unit from the measurement separately */
export interface SplitMeasurement {
  measurement: string;
  unit: string;
}

const unitSplitterRegex = /(-?[\d.]+)([a-z%]*)/;

/**
 * Filters out styles that are not whitelisted from a style string.
 * If whitelistedStyle is empty then empty string will be returned.
 * @param styleStr The original style string.
 * @param whitelistedStyles A list of styles that acceptable in styleStr.
 * @returns returns styleStr but only with styles that are in whiltelistedStyles.
 */
export function filterStyleString(styleStr: string, whitelistedStyles: string[]): string {
  const styleDict = parseStyles(styleStr);
  let filteredStyleStr = '';
  for (const [key, value] of Object.entries(styleDict)) {
    if (whitelistedStyles.includes(key)) {
      filteredStyleStr += key + ': ' + value + '; ';
    }
  }
  return filteredStyleStr.trim();
}

/**
 * Used to more easily update a style string with new values.
 * @param styleStr The original style string.
 * @returns A dictionary that represents the styleStr.
 */
export function parseStyles(styleStr: string): Dictionary<string> {
  const styleDict = {};
  if (styleStr) {
    styleStr.split(';').forEach((declaration) => {
      const [name, value] = declaration.split(':').map(str => str.trim());
      if (name && value) {
        styleDict[name] = value;
      }
    });
  }

  return styleDict;
}

/**
 * Used to change a style dict to a string to use with prosemirror.
 * @param styleDict The keys/values in this dict are what makes up the return value.
 * @returns styleDict as a string.
 */
export function stringifyStyles(styleDict: Dictionary): string {
  let styleString = '';

  for (const property in styleDict) {
    if (styleDict.hasOwnProperty(property) && typeof styleDict[property] === 'string') {
      styleString += property + ': ' + styleDict[property] + '; ';
    }
  }

  return styleString.trim();
}

/**
 * Merges two style dictionaries together. Any null, undefined, or empty string values will be removed.
 * Any null, undefined, or empty string values in style2 will overwrite the values in style1.
 * @param style1 The first style dictionary to merge.
 * @param style2 The second style dictionary to merge.
 * @returns A new dictionary that is the result of merging style1 and style2.
 */
export function mergeStyles(style1: Dictionary, style2: Dictionary): Dictionary {
  const styles = {
    ...style1,
    ...style2
  };

  // Filter out any null, undefined, or empty string values
  return Object.fromEntries(Object.entries(styles).filter(([key, value]) => {
    return value !== null && typeof value !== 'undefined' && value !== '';
  }));
}

/**
 * Splits a string value that is made out of a length and a unit.
 * If the string is empty will return undefined.
 * If the string is not empty but failed to split it to length and a unit, it returns the original string in the measurement field.
 * @param measurementStr A string that is number followed by a unit such as (px, em , in, mm, etc), or just a string.
 * @return returns SplitMeasurement which is a representation of measurementStr but split
 * based on its numeric value and unit. So for instance splitMeasurementFromUnit('30px') -> {measurement: 30, unit: 'px'};
 * It returns the original string if the the split failed.
 */
export function splitMeasurementFromUnit(measurementStr: string): SplitMeasurement {
  if (measurementStr) {
    const res = measurementStr.match(unitSplitterRegex);
    if (res && res.index === 0) {
      return { measurement: res[1], unit: res[2] };
    } else {
      return { measurement: measurementStr, unit: null };
    }
  }
}
