import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormControlErrorCodeModule } from '@portal-core/forms/components/form-control-error-code/form-control-error-code.module';
import { GridHeaderFilterModule } from '@portal-core/ui/grid/components/grid-header-filter/grid-header-filter.module';
import { GridHeaderFilterFormModule } from '@portal-core/ui/grid/directives/grid-header-filter-form/grid-header-filter-form.module';
import { GridHeaderFilterMenuModule } from '@portal-core/ui/grid/directives/grid-header-filter-menu/grid-header-filter-menu.module';
import { GridDateFilterComponent } from './grid-date-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormControlErrorCodeModule,
    FormsModule,
    GridHeaderFilterFormModule,
    GridHeaderFilterMenuModule,
    GridHeaderFilterModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  declarations: [
    GridDateFilterComponent
  ],
  exports: [
    GridDateFilterComponent
  ]
})
export class GridDateFilterModule { }
