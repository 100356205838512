import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';
import { NodeToolbarPopupPluginViewComponent } from './node-toolbar-popup-plugin-view.component';

@NgModule({
  declarations: [
    NodeToolbarPopupPluginViewComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    PopupModule,
    StopPropagationModule
  ],
  exports: [
    NodeToolbarPopupPluginViewComponent
  ]
})
export class NodeToolbarPopupPluginViewModule { }
