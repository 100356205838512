import { Injectable } from '@angular/core';
import { Auth } from '@common/auth/models/auth.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LogoutReason } from '@portal-core/auth/enums/logout-reason.enum';
import { AuthUserData } from '@portal-core/auth/models/auth-user-data.model';
import { SubdomainLicense } from '@portal-core/auth/models/subdomain-license.model';
import { Observable } from 'rxjs';

/* AuthStateModel */
export class AuthStateModel {
  accessTokensByInstanceCode: Dictionary<string>;
  accessToken: string;
  auth: Auth; // This is only in the data store so that it can be given to Flare after the login completes
  authUserData: AuthUserData;
  autoLoginAttempted: boolean;
  daysUntilPasswordExpires: number;
  lastActivatedRoute: string[];
  maxIdleTimeMinutes: number;
  instanceCode: string;
  subdomainLicense: SubdomainLicense;
  userId: string;
  userName: string;
}

/* Actions */
export class AddAccessTokenByInstanceCode {
  static readonly type: string = '[Auth] AddAccessTokenByInstanceCode';
  constructor(public payload: { instanceCode: string, token: string }) { }
}

export class RemoveAccessTokenByInstanceCode {
  static readonly type: string = '[Auth] RemoveAccessTokenByInstanceCode';
  constructor(public payload: { instanceCode: string }) { }
}

export class Login {
  static readonly type: string = '[Auth] Login';
  constructor(public payload: { auth: Auth, instanceCode: string }) { }
}

export class Logout {
  static readonly type: string = '[Auth] Logout';
  constructor(public payload: { reason: LogoutReason, instanceCode: string }) { }
}

export class SetAccessToken {
  static readonly type: string = '[Auth] SetAccessToken';
  constructor(public payload: { accessToken: string }) { }
}

export class SetAuthUserData {
  static readonly type: string = '[Auth] SetAuthUserData';
  constructor(public payload: { authUserData: AuthUserData }) { }
}

export class SetAutoLoginAttempted {
  static readonly type: string = '[Auth] SetAutoLoginAttempted';
  constructor(public payload: { autoLoginAttempted: boolean }) { }
}

export class SetInstanceCode {
  static readonly type: string = '[Auth] SetInstanceCode';
  constructor(public payload: { instanceCode: string }) { }
}

export class SetLastActivatedRoute {
  static readonly type: string = '[Auth] SetLastActivatedRoute';
  constructor(public payload: { lastActivatedRoute: string[] }) { }
}

export class SetSubdomainLicense {
  static readonly type: string = '[Auth] SetSubdomainLicense';
  constructor(public payload: { subdomainLicense: SubdomainLicense }) { }
}

export class SwitchCentralInstance {
  static readonly type: string = '[Auth] SwitchCentralInstance';
  constructor(public payload: { instanceCode: string }) { }
}

/* AuthState */
@State<AuthStateModel>({
  name: 'authState'
})
@Injectable()
export class AuthState {
  @Selector()
  static accessToken(state: AuthStateModel): string { return state.accessToken; }

  @Selector()
  static accessTokensByInstanceCode(state: AuthStateModel): Dictionary<string> { return state.accessTokensByInstanceCode; }

  @Selector()
  static auth(state: AuthStateModel): Auth { return state.auth; }

  @Selector()
  static authUserData(state: AuthStateModel): AuthUserData { return state.authUserData; }

  @Selector()
  static autoLoginAttempted(state: AuthStateModel): boolean { return state.autoLoginAttempted; }

  @Selector()
  static daysUntilPasswordExpires(state: AuthStateModel): number { return state.daysUntilPasswordExpires; }

  @Selector()
  static lastActivatedRoute(state: AuthStateModel): string[] { return state.lastActivatedRoute; }

  @Selector()
  static maxIdleTimeMinutes(state: AuthStateModel): number { return state.maxIdleTimeMinutes; }

  @Selector()
  static instanceCode(state: AuthStateModel): string { return state.instanceCode; }

  @Selector()
  static subdomainLicense(state: AuthStateModel): SubdomainLicense { return state.subdomainLicense; }

  @Selector()
  static userId(state: AuthStateModel): string { return state.userId; }

  @Selector()
  static userName(state: AuthStateModel): string { return state.userName; }

  @Action(AddAccessTokenByInstanceCode)
  addAccessTokenByInstanceCode(ctx: StateContext<AuthStateModel>, action: AddAccessTokenByInstanceCode) {
    const accessTokensByInstanceCode = ctx.getState().accessTokensByInstanceCode ?? {};
    accessTokensByInstanceCode[action.payload.instanceCode] = action.payload.token;
    ctx.patchState({
      accessTokensByInstanceCode
    });
  }

  @Action(RemoveAccessTokenByInstanceCode)
  removeAccessTokenByInstanceCode(ctx: StateContext<AuthStateModel>, action: RemoveAccessTokenByInstanceCode) {
    const accessTokensByInstanceCode = ctx.getState().accessTokensByInstanceCode ?? {};
    delete accessTokensByInstanceCode[action.payload.instanceCode];
    ctx.patchState({
      accessTokensByInstanceCode
    });
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    ctx.patchState({
      accessToken: action.payload.auth?.access_token ?? null,
      auth: action.payload.auth,
      daysUntilPasswordExpires: action.payload.auth?.daysUntilPasswordExpires ? parseInt(action.payload.auth.daysUntilPasswordExpires, 10) : null,
      maxIdleTimeMinutes: action.payload.auth?.maxIdleTimeMinutes ? parseInt(action.payload.auth.maxIdleTimeMinutes, 10) : null,
      instanceCode: action.payload.instanceCode,
      userId: action.payload.auth?.userId,
      userName: action.payload.auth?.userName
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout) {
    ctx.patchState({
      accessToken: null,
      auth: null,
      authUserData: null,
      autoLoginAttempted: false, // The user logged out so reset this flag
      daysUntilPasswordExpires: null,
      maxIdleTimeMinutes: null,
      instanceCode: null,
      userId: null,
      userName: null
    });
  }

  @Action(SwitchCentralInstance)
  switchInstance(ctx: StateContext<AuthStateModel>, action: SwitchCentralInstance) {
    const accessToken = ctx.getState().accessTokensByInstanceCode?.[action.payload.instanceCode];

    // Patch the state the same way as the Logout action because we are essentially logging out of the current instance
    ctx.patchState({
      accessToken: accessToken ?? null,
      auth: null,
      authUserData: null,
      autoLoginAttempted: false, // The user logged out so reset this flag
      daysUntilPasswordExpires: null,
      maxIdleTimeMinutes: null,
      instanceCode: accessToken ? action.payload.instanceCode : null,
      userId: null,
      userName: null
    });
  }

  @Action(SetAccessToken)
  setAccessToken(ctx: StateContext<AuthStateModel>, action: SetAccessToken) {
    ctx.patchState({
      accessToken: action.payload.accessToken
    });
  }

  @Action(SetAuthUserData)
  setAuthUserData(ctx: StateContext<AuthStateModel>, action: SetAuthUserData) {
    ctx.patchState({
      authUserData: action.payload.authUserData
    });
  }

  @Action(SetAutoLoginAttempted)
  setAutoLoginAttempted(ctx: StateContext<AuthStateModel>, action: SetAutoLoginAttempted) {
    ctx.patchState({
      autoLoginAttempted: action.payload.autoLoginAttempted
    });
  }

  @Action(SetInstanceCode)
  setInstanceCode(ctx: StateContext<AuthStateModel>, action: SetInstanceCode) {
    ctx.patchState({
      instanceCode: action.payload.instanceCode
    });
  }

  @Action(SetLastActivatedRoute)
  setLastActivatedRoute(ctx: StateContext<AuthStateModel>, action: SetLastActivatedRoute) {
    ctx.patchState({
      lastActivatedRoute: action.payload.lastActivatedRoute
    });
  }

  @Action(SetSubdomainLicense)
  setSubdomainLicense(ctx: StateContext<AuthStateModel>, action: SetSubdomainLicense) {
    ctx.patchState({
      subdomainLicense: action.payload.subdomainLicense
    });
  }
}

/* AuthDataService */
@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  constructor(private store: Store) { }

  /* Access Token */
  getAccessToken(): string {
    return this.store.selectSnapshot(AuthState.accessToken);
  }

  getAccessToken$(): Observable<string> {
    return this.store.select(AuthState.accessToken);
  }

  setAccessToken$(accessToken: string): Observable<any> {
    return this.store.dispatch(new SetAccessToken({ accessToken }));
  }

  /* Access Tokens by Instance Code */
  getAccessTokensByInstanceCode(): Dictionary<string> {
    return this.store.selectSnapshot(AuthState.accessTokensByInstanceCode);
  }

  getAccessTokensByInstanceCode$(): Observable<Dictionary<string>> {
    return this.store.select(AuthState.accessTokensByInstanceCode);
  }

  addAccessTokenByInstanceCode$(instanceCode: string, token: string): Observable<any> {
    return this.store.dispatch(new AddAccessTokenByInstanceCode({ instanceCode, token }));
  }

  removeAccessTokenByInstanceCode$(instanceCode: string): Observable<any> {
    return this.store.dispatch(new RemoveAccessTokenByInstanceCode({ instanceCode }));
  }

  /* Auth */
  getAuth(): Auth {
    return this.store.selectSnapshot(AuthState.auth);
  }

  getAuth$(): Observable<Auth> {
    return this.store.select(AuthState.auth);
  }

  /* Auth User Data */
  getAuthUserData(): AuthUserData {
    return this.store.selectSnapshot(AuthState.authUserData);
  }

  getAuthUserData$(): Observable<AuthUserData> {
    return this.store.select(AuthState.authUserData);
  }

  setAuthUserData$(authUserData: AuthUserData): Observable<any> {
    return this.store.dispatch(new SetAuthUserData({ authUserData }));
  }

  /* Auto Login Attempted */
  getAutoLoginAttempted(): boolean {
    return this.store.selectSnapshot(AuthState.autoLoginAttempted);
  }

  getAutoLoginAttempted$(): Observable<boolean> {
    return this.store.select(AuthState.autoLoginAttempted);
  }

  setAutoLoginAttempted$(autoLoginAttempted: boolean): Observable<any> {
    return this.store.dispatch(new SetAutoLoginAttempted({ autoLoginAttempted }));
  }

  /* Days Until Password Expires */
  getDaysUntilPasswordExpires(): number {
    return this.store.selectSnapshot(AuthState.daysUntilPasswordExpires);
  }

  getDaysUntilPasswordExpires$(): Observable<number> {
    return this.store.select(AuthState.daysUntilPasswordExpires);
  }

  /* Last Selected Route */
  getLastActivatedRoute(): string[] {
    return this.store.selectSnapshot(AuthState.lastActivatedRoute);
  }

  getLastActivatedRoute$(): Observable<string[]> {
    return this.store.select(AuthState.lastActivatedRoute);
  }

  setLastActivatedRoute$(lastActivatedRoute: string[]): Observable<any> {
    return this.store.dispatch(new SetLastActivatedRoute({ lastActivatedRoute }));
  }

  /* Max Idle Time Minutes */
  getMaxIdleTimeMinutes(): number {
    return this.store.selectSnapshot(AuthState.maxIdleTimeMinutes);
  }

  getMaxIdleTimeMinutes$(): Observable<number> {
    return this.store.select(AuthState.maxIdleTimeMinutes);
  }

  /* Instance Code */
  getInstanceCode(): string {
    return this.store.selectSnapshot(AuthState.instanceCode);
  }

  getInstanceCode$(): Observable<string> {
    return this.store.select(AuthState.instanceCode);
  }

  setInstanceCode$(instanceCode: string): Observable<any> {
    return this.store.dispatch(new SetInstanceCode({ instanceCode }));
  }

  /* Subdomain License */
  getSubdomainLicense(): SubdomainLicense {
    return this.store.selectSnapshot(AuthState.subdomainLicense);
  }

  getSubdomainLicense$(): Observable<SubdomainLicense> {
    return this.store.select(AuthState.subdomainLicense);
  }

  setSubdomainLicense$(subdomainLicense: SubdomainLicense): Observable<any> {
    return this.store.dispatch(new SetSubdomainLicense({ subdomainLicense }));
  }

  /* User Id */
  getUserId(): string {
    return this.store.selectSnapshot(AuthState.userId);
  }

  getUserId$(): Observable<string> {
    return this.store.select(AuthState.userId);
  }

  /* User Name */
  getUserName(): string {
    return this.store.selectSnapshot(AuthState.userName);
  }

  getUserName$(): Observable<string> {
    return this.store.select(AuthState.userName);
  }

  /* Login */
  login$(auth: Auth, instanceCode: string): Observable<any> {
    return this.store.dispatch(new Login({ auth, instanceCode }));
  }

  /* Logout */
  logout$(reason: LogoutReason, instanceCode: string): Observable<any> {
    return this.store.dispatch(new Logout({ reason, instanceCode }));
  }

  /* Switch Instance */
  switchCentralInstance$(instanceCode: string): Observable<any> {
    return this.store.dispatch(new SwitchCentralInstance({ instanceCode }));
  }
}
