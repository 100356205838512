import { mergeFlareNodeAttrs, updateFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { ImageActionType, ImagePlaceholderAction, ImageUploadMetaKey, ImageUploadPluginMetaData, findImageUploadPos } from '@common/prosemirror/plugins/image-upload.plugin';
import { Attrs, NodeType } from 'prosemirror-model';
import { Command, EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export type ImageNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, attrs?: {}) => boolean;
// Insert a placeholder that gets overwritten after the image is hosted on the API server
export type CreateImagePlaceholdersCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, actions?: ImagePlaceholderAction[]) => boolean;

export interface ImageAction extends ImagePlaceholderAction {
  url?: string;
  attrs?: Attrs;
}

/*
 * Flare Commands
*/

// Used for inserting new images that must be added to the server first
export function insertImagePlaceholders(imageType: NodeType): CreateImagePlaceholdersCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, actions?: ImagePlaceholderAction[]): boolean {
    const $from = editorState.selection.$from;
    const index = $from.index();

    if (!$from.parent.canReplaceWith(index, index, imageType)) {
      return false;
    }

    if (dispatch) {
      const tr = editorState.tr;

      if (!tr.selection.empty) {
        tr.deleteSelection();
      }

      actions.forEach(action => {
        // Default to the beginning of the selection if not assigned
        action.pos = action.pos ?? tr.selection.from;
      });

      tr.setMeta(ImageUploadMetaKey, {
        add: actions
      } as ImageUploadPluginMetaData);

      dispatch(tr);
    }

    return true;
  };
}

// Used for inserting or updating an image
export function insertOrEditImage(imageType: NodeType): ImageNodeCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, attrs?: Attrs): boolean {
    const selection = editorState.selection;
    const $from = selection.$from;
    const index = $from.index();

    if (!$from.parent.canReplaceWith(index, index, imageType)) {
      return false;
    }

    if (dispatch) {
      const node = selection instanceof NodeSelection && selection.node.type === imageType ? selection.node : undefined;

      const tr = editorState.tr;
      if (node) {
        // Update the node with the new attrs
        updateFlareNodeAttrs(tr, node, $from.pos, attrs);
        tr.setSelection(NodeSelection.create(tr.doc, $from.pos));
      } else {
        tr.replaceSelectionWith(imageType.create(attrs));
      }

      dispatch(tr.scrollIntoView());
    }

    return true;
  }
}

/*
 * Image placeholder functions
*/

export function updateImagePlaceholders(actions: ImageAction[]): Command {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView): boolean {
    return dispatchImageMeta(editorState, dispatch, ImageActionType.Remove, view, actions);
  };
}

export function insertImageUploadErrors(actions: ImagePlaceholderAction[]): Command {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView): boolean {
    return dispatchImageMeta(editorState, dispatch, ImageActionType.Error, view, actions);
  };
}

export function removeImagePlaceholders(actions: ImagePlaceholderAction[]): Command {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView): boolean {
    return dispatchImageMeta(editorState, dispatch, ImageActionType.Remove, view, actions);
  };
}

function dispatchImageMeta(state: EditorState, dispatch: ProsemirrorDispatcher, actionType: ImageActionType, view?: EditorView, actions?: ImageAction[]): boolean {
  // Find the placeholder position
  actions.forEach(action => {
    action.pos = findImageUploadPos(state, action.id);
  })

  if (actions.some(action => typeof action.pos !== 'number')) {
    return false;
  }

  if (dispatch) {
    const tr = state.tr;
    const imageType = state.schema.nodes.image;

    actions.forEach(action => {
      // Insert image node with url from server
      if (actionType === ImageActionType.Remove && action.url) {
        const mappedPos = tr.mapping.map(action.pos);
        const attrs: Attrs = mergeFlareNodeAttrs(action.attrs, { src: action.url });
        tr.insert(mappedPos, imageType.create(attrs));
      }
    });

    // Remove the placeholderss
    tr.setMeta(ImageUploadMetaKey, {
      [actionType]: actions
    } as ImageUploadPluginMetaData);

    dispatch(tr);
  }

  return true;
}
