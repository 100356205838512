<ng-container *ngFor="let instruction of messageInstructions">
  <ng-container [ngSwitch]="instruction.type">
    <ng-container *ngSwitchCase="'tag'">
      <mc-notification-message-tag [notification]="notification" [tag]="instruction.value"></mc-notification-message-tag>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
      {{ instruction.value }}
    </ng-container>
    <ng-container *ngSwitchCase="'wrapper'">
      <mc-notification-message-wrapper [text]="instruction.value" [wrapper]="instruction.wrapper"></mc-notification-message-wrapper>
    </ng-container>
  </ng-container>
</ng-container>