import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { AlertDialogComponent } from '@portal-core/general/components/alert-dialog/alert-dialog.component';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { AvatarIndicatorDirective } from '@portal-core/ui/avatar/directives/avatar-indicator/avatar-indicator.directive';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { UserProfileDialogComponent } from '@portal-core/users/components/user-profile-dialog/user-profile-dialog.component';
import { UserOnlineStatusType } from '@portal-core/users/enums/user-online-status-type.enum';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { map, Observable, of, switchMap } from 'rxjs';

/**
 * mc-license-user-avatar
 * A component for displaying a user's avatar image and name.
 */
@Component({
  selector: 'mc-license-user-avatar',
  templateUrl: './license-user-avatar.component.html',
  styleUrls: ['./license-user-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseUserAvatarComponent extends AvatarBase implements OnInit {
  @Input() licenseUser: LicenseUser;
  @Input() showAdmin: boolean = false;
  @Input() showOnlineStatus: boolean = false;
  @Input() showSeatType: boolean = false;
  @Input() tooltip?: string;
  @Input() tooltipClass?: string;

  @ContentChild(AvatarIndicatorDirective) avatarIndicator: AvatarIndicatorDirective;

  @InputObservable('licenseUser') licenseUser$: Observable<LicenseUser>;

  LicenseUserSeatType: typeof LicenseUserSeatType = LicenseUserSeatType;
  LicenseUserStatus: typeof LicenseUserStatus = LicenseUserStatus;

  isAdmin$: Observable<boolean>;
  onlineStatusClass$: Observable<string>;

  constructor(private dialog: MatDialog, private licenseUsersService: LicenseUsersService, private currentService: CurrentService) {
    super();
  }

  ngOnInit() {
    this.isAdmin$ = this.licenseUser$.pipe(
      switchMap(licenseUser => {
        if (licenseUser) {
          return this.licenseUsersService.getLicenseUsersAdministrations$(licenseUser.LicenseId).pipe(
            map(administrators => Array.isArray(administrators) && administrators.some(admin => admin.Id === licenseUser.Id))
          );
        } else {
          return of(false);
        }
      })
    );

    this.onlineStatusClass$ = this.licenseUser$.pipe(
      map(licenseUser => {
        if (licenseUser && licenseUser.User.OnlineStatus === UserOnlineStatusType.Online) {
          return 'mc-text-primary';
        } else if (licenseUser && licenseUser.User.OnlineStatus === UserOnlineStatusType.Away) {
          return 'mc-text-caution';
        } else {
          return 'mc-text-basic';
        }
      })
    );
  }

  onAvatarClicked() {
    if (this.licenseUser.Status === this.LicenseUserStatus.Deactivated) {
      this.openUserDisabledAlertDialog();
    } else {
      this.openUserDialogCard();
    }
  }


  hasAvatarImage(): boolean {
    return !!this.licenseUser?.User?.Avatar;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return this.licenseUser && this.licenseUser.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.licenseUser;
  }

  private openUserDisabledAlertDialog() {
    this.dialog.open(AlertDialogComponent, {
      width: '36rem',
      data: {
        title: 'User Deactivated',
        message: 'This user is deactivated and their profile cannot be opened. To open the user\'s profile, you must change their status to Active.'
      }
    });
  }

  private openUserDialogCard() {
    this.dialog.open(UserProfileDialogComponent, {
      ...UserProfileDialogComponent.DialogConfigForLicenseUser(this.licenseUser, this.currentService.getLicenseUser()),
      data: {
        licenseId: this.licenseUser.LicenseId,
        licenseUserId: this.licenseUser.Id,
        userId: this.licenseUser.User.Id
      }
    });
  }
}
