<mc-grid-header-filter [column]="column" [hasFilter]="hasFilter" (menuClosed)="onMenuClosed()" (menuClosing)="onMenuClosing()" (menuOpened)="onMenuOpened()">
  <ng-template mcGridHeaderFilterForm>
    <form #filterFormDirective="ngForm" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <mat-radio-group class="mc-boolean-filter-radio-group" formControlName="bool">
        <mat-radio-button #firstRadioButton class="mc-boolean-filter-radio-button mat-primary-vivid" value="false">{{column?.booleanOptions[0]}}</mat-radio-button>
        <mat-radio-button class="mc-boolean-filter-radio-button mat-primary-vivid" value="true">{{column?.booleanOptions[1]}}</mat-radio-button>
      </mat-radio-group>

      <div class="mc-menu-button-group">
        <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
        <button type="submit" mat-flat-button color="primary">Filter</button>
      </div>
    </form>
  </ng-template>

  <ng-template *ngIf="menuTemplate" mcGridHeaderFilterMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-header-filter>
