import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Sort } from '@angular/material/sort';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { MaxAttachmentSizeBytes } from '@portal-core/data/common/constants/max-attachment-size.constant';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { FileSizeService } from '@portal-core/general/services/file-size.service';
import { License } from '@portal-core/licenses/models/license.model';
import { TaskAssetDelete } from '@portal-core/tasks/models/task-asset-delete.model';
import { TaskCommentAsset } from '@portal-core/tasks/models/task-comment-asset.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { TaskCommentsDialogService } from '@portal-core/tasks/services/task-comments-dialog.service';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'mc-task-profile-dialog-attachments',
  templateUrl: './task-profile-dialog-attachments.component.html',
  styleUrls: ['./task-profile-dialog-attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskProfileDialogAttachmentsComponent implements OnInit, OnDestroy {
  @Input() newComment: TaskComment;
  @Input() task: Task;
  @Input() license: License;
  @Input() canEditAllTasks: boolean;
  @Output() isDirtyChanged: EventEmitter<any> = new EventEmitter();
  columns: GridColumn[];
  visibleColumns: string[];
  commentAssets$: Observable<TaskCommentAsset[]>;
  private resortGrid: BehaviorSubject<any> = new BehaviorSubject<Sort>(undefined);
  constructor(
    private dialog: MatDialog,
    public taskCommentsDialogService: TaskCommentsDialogService,
    private fileSizeService: FileSizeService) { }

  ngOnInit() {
    this.columns = [
      {
        name: 'FileName',
        title: 'Name',
        sortEnabled: true,
        type: PageDataType.String
      },
      {
        name: 'CreatedOn',
        title: 'Added',
        sortEnabled: true,
        type: PageDataType.Date
      },
      {
        name: 'UploadedByUserId',
        title: 'By',
        sortEnabled: false,
        type: PageDataType.String
      }
    ];
    this.visibleColumns = this.columns.map(column => column.name);

    this.commentAssets$ = combineLatest([this.resortGrid, this.taskCommentsDialogService.commentAssets$])
      .pipe(
        map(([sort, commentAssets]) => {
          return [...commentAssets.sort((ca1, ca2) => {
            if (!sort) {
              return ca1.Asset.FileName > ca2.Asset.FileName
                ? 1
                : -1;
            }
            const multiplier = sort.direction === 'asc'
              ? -1
              : 1;
            if (sort.active === 'CreatedOn') {
              return new Date(ca1.Asset[sort.active]) < new Date(ca2.Asset[sort.active])
                ? 1 * multiplier
                : -1 * multiplier;
            }
            return ca1.Asset[sort.active] < ca2.Asset[sort.active]
              ? 1 * multiplier
              : -1 * multiplier;
          })];
        }),
        tap(() => {
          this.isDirtyChanged.emit();
        }));

    this.resortGrid.next(<Sort>{ active: 'FileName', direction: '' });
  }

  ngOnDestroy(): void {
    this.resortGrid.unsubscribe();
  }

  onAssetDelete($event: TaskAssetDelete) {
    this.taskCommentsDialogService.deleteAsset(this.newComment, $event.Asset);
    this.isDirtyChanged.emit();
  }

  onAddAttachmentsChanged(event: any) {
    let displayWarning = false;
    let totalSize = 0;
    this.taskCommentsDialogService.commentAssets$.pipe(map(commentAssets => commentAssets.filter(ca => ca.Asset.Id === 0).reduce((total, ca) => total + ca.Asset.SizeInBytes, 0)))
      .subscribe(total => totalSize = total);
    for (const file of event.target.files) {
      totalSize += file.size;
      // nothing larger than 15MB
      if (file.size >= MaxAttachmentSizeBytes || totalSize >= MaxAttachmentSizeBytes) {
        displayWarning = true;
      } else {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        this.taskCommentsDialogService.addAsset(this.newComment, file, formData);
      }
    }

    // resetting input to allow for attaching the same file name
    event.currentTarget.value = null;

    if (displayWarning) {
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Warning File Size Limit',
          message: `Cannot attach files whose sum is larger than ${this.fileSizeService.format(MaxAttachmentSizeBytes, 0)}.`
        }
      });
    }
  }

  onSortChanged(sort: Sort) {
    this.resortGrid.next(sort);
  }
}
