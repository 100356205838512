import { ComponentRef } from '@angular/core';
import { nodeConditionsValue } from '@common/prosemirror/commands/conditions.command';
import { DynamicViewComponent } from '@portal-core/text-editor/components/dynamic-view/dynamic-view.component';
import { ComponentNodeViewOptions } from '@portal-core/text-editor/models/component-node-view-options.model';
import { NodeViewLoadEvent } from '@portal-core/text-editor/models/node-view-load-event.model';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { Decoration, EditorView, NodeView } from 'prosemirror-view';

export abstract class ComponentNodeView implements NodeView {
  component: DynamicViewComponent;
  componentRef: ComponentRef<DynamicViewComponent>;
  dom: HTMLElement;
  contentDOM: HTMLElement;

  get inputs(): Dictionary {
    return this.component.inputs;
  }

  get outputs(): Dictionary {
    return this.component.outputs;
  }

  constructor(
    componentType: any,
    protected node: ProseMirrorNode,
    protected editorView: EditorView,
    protected getPos: GetPosForNodeView,
    protected decorations: Decoration[],
    protected create: DynamicViewComponentInjector<DynamicViewComponent>,
    protected options?: ComponentNodeViewOptions
  ) {
    // Create dynamic node view component
    const injection = create(DynamicViewComponent);
    this.component = injection.componentRef.instance;
    this.componentRef = injection.componentRef;
    this.dom = injection.element;

    // Setup the dynamic component
    this.component.inputs = {};
    this.component.outputs = {};
    this.component.componentType = componentType;

    this.onComponentCreated();
    this.update(node, decorations);
  }

  update(node: ProseMirrorNode, decorations: Decoration[]) {
    if (node.type !== this.node.type) {
      return false;
    }

    // Store the new node
    this.node = node;
    this.decorations = decorations;

    // Update the dynamic component
    this.inputs.changeList = this.node.attrs.changeList;
    this.inputs.changeIds = this.node.attrs.changeIds;
    this.inputs.conditions = nodeConditionsValue(this.node);
    this.updateComponent();
    this.component.detectChanges();

    return true;
  }

  destroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
      this.component = null;
      this.dom = null;
    }
  }

  emitLoaded(event: NodeViewLoadEvent) {
    if (this.options && typeof this.options.onLoad === 'function') {
      this.options.onLoad(event);
    }
  }

  onComponentCreated() { }
  abstract updateComponent();
}
