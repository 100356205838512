import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { Mark, MarkSpec } from 'prosemirror-model';

export class LinkSchemaPlugin extends SchemaPlugin {
  marks: Dictionary<MarkSpec> = {
    link: {
      attrs: {
        href: { default: '' }
      },
      inclusive: false,
      parseDOM: [{
        tag: 'a[href]',
        getAttrs(dom: HTMLElement) {
          return {
            href: dom.getAttribute('href')
          };
        }
      }],
      toDOM(node: Mark) {
        return ['a', {
          href: node.attrs.href,
          target: '_blank'
        }, 0];
      }
    }
  };
}
