import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseProfileDialogComponent, LicenseProfileDialogData } from '@portal-core/licenses/components/license-profile-dialog/license-profile-dialog.component';
import { License } from '@portal-core/licenses/models/license.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-license-avatar',
  templateUrl: './license-avatar.component.html',
  styleUrls: ['./license-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseAvatarComponent extends AvatarBase {
  @Input() license: License;
  @Input() nameLinksToPage?: boolean = false;
  @Input() showId?: boolean = false;

  @HostBinding('class.mc-avatar-square') _avatarSquareClass: boolean = true;

  get href(): string {
    if (this.license) {
      return this.authUrlService.buildSubdomainUrl(this.license.VanityBaseUrl);
    } else {
      return '#';
    }
  }

  constructor(private dialog: MatDialog, private currentService: CurrentService, private authUrlService: AuthUrlService) {
    super();
  }

  hasAvatarImage(): boolean {
    return !!this.license?.Avatar;
  }

  isDeleted(): boolean {
    return this.license && this.license.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.license;
  }

  hasPermission(): boolean {
    return true;
  }

  onAvatarClicked() {
    this.openLicenseProfileDialog();
  }

  private openLicenseProfileDialog() {
    this.dialog.open<LicenseProfileDialogComponent, LicenseProfileDialogData>(LicenseProfileDialogComponent, {
      ...LicenseProfileDialogComponent.DialogConfig,
      data: {
        licenseId: this.license.Id,
        licenseUserId: !this.currentService.isSysAdmin() ? this.currentService.getLicenseUserId() : null,
        sysAdminUser: this.currentService.isSysAdmin() ? this.currentService.getUser() : null,
      }
    });
  }
}
