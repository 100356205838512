<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <p class="mc-form-text">
      Deleting a build will permanently remove it.
    </p>
    <p class="mc-form-text">
      You cannot delete builds that are set to "Keep" or associated with a site.
    </p>

    <p *ngIf="!build.CanBeDeleted" class="mc-text-warn">
      <ng-container *ngIf="build.IsKept && !build.SitesList?.length">
        This build is set to "Keep" and cannot be deleted.
        To delete this build, first remove the "Keep" setting.
      </ng-container>

      <ng-container *ngIf="!build.IsKept && build.SitesList?.length > 0">
        This build is associated with {{ build.SitesList.length | number:'1.0-0' }} {{ build.SitesList.length === 1 ? 'site' : 'sites' }}.
        To delete this build, first unlink it from the {{ build.SitesList.length === 1 ? 'site' : 'sites' }}.
      </ng-container>

      <ng-container *ngIf="build.IsKept && build.SitesList?.length > 0">
        This build is set to "Keep" and associated with {{ build.SitesList.length | number:'1.0-0' }} {{ build.SitesList.length === 1 ? 'site' : 'sites' }}.
        To delete this build, first remove the "Keep" setting and unlink it from the {{ build.SitesList.length === 1 ? 'site' : 'sites' }}.
      </ng-container>
    </p>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="warn" [disabled]="!build.CanBeDeleted || (savingState.loading$ | async)">Delete</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>