import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridFilterSelectOption } from '@portal-core/ui/grid/models/grid-filter-select-option.model';
import { ColumnAutocomplete } from '@portal-core/ui/grid/types/column-autocomplete.type';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { forEach } from 'lodash';
import { map, Observable, switchMap } from 'rxjs';

interface FilterListItem {
  column?: GridColumn;
  filterType?: PageFilterType;
  groupId: string;
  title: string;
  type: PageFilterGroupType;
  value: any;
  value2?: any;

  autocomplete?: ColumnAutocomplete;
  selectOptions?: GridFilterSelectOption[];
}

/**
 * mc-filter-bar
 * An interactive bar that shows the filters made by a Filterable.
 * Allows the user to remove the filters.
 */
@Component({
  selector: 'mc-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarComponent implements OnInit {
  /** The filterable that the filter-bar displays the filters for. */
  @Input() filterable: Filterable;

  /** Whether or not this filter bar should display itself like it is attached to a list or grid. */
  @Input() display?: 'free' | 'grid' | 'list' = 'free';

  @HostBinding('class')
  get _display(): string {
    switch (this.display) {
      case 'grid':
        return 'mc-filter-bar-with-grid';
      case 'list':
        return 'mc-filter-bar-with-list';
    }
  }


  /** An observable of the filterable property. */
  @PropertyObservable('filterable') filterable$: Observable<Filterable>;

  PageFilterGroupType: typeof PageFilterGroupType = PageFilterGroupType;
  PageFilterType: typeof PageFilterType = PageFilterType;

  filterList$: Observable<FilterListItem[]>;

  constructor(private pageFilterService: PageFilterService) { }

  /** Subscribes to filter change events to build the list of filters to display. */
  ngOnInit() {
    this.filterList$ = this.filterable$.pipe(
      switchMap(filterable => filterable.getFilters$()),
      map(pageFilterGroups => this.createFilterItems(pageFilterGroups))
    );
  }

  onFilterChipRemoved(filterListItem: FilterListItem) {
    // If this is a search then clear all the search filters
    if (filterListItem.type === PageFilterGroupType.Search) {
      this.filterable.applyFilter$(filterListItem.groupId, null);
    } else if (filterListItem.type === PageFilterGroupType.Custom) {
      this.filterable.applyFilter$(filterListItem.groupId, null);
    } else {
      const filterGroup = this.filterable.getFilter(filterListItem.groupId);
      if (filterGroup) {
        const fb = this.pageFilterService.create(filterGroup);
        fb.remove(filterListItem.column.name);
        this.filterable.applyFilter$(filterListItem.groupId, fb.value);
      }
    }
  }

  getSelectValueDisplayText(id: string, selectOptions: GridFilterSelectOption[]) {
    return selectOptions.find(option => option.id === id)?.text;
  }

  private createFilterItems(pageFilterGroups: Dictionary<PageFilterGroup>): FilterListItem[] {
    let filterItems: FilterListItem[] = [];

    forEach(pageFilterGroups, pageFilterGroup => {
      switch (pageFilterGroup?.Type) {
        case PageFilterGroupType.Bool:
          break;
        case PageFilterGroupType.Custom:
          filterItems = filterItems.concat(this.createCustomFilterItems(pageFilterGroup));
          break;
        case PageFilterGroupType.Date:
          filterItems = filterItems.concat(this.createDateFilterItems(pageFilterGroup));
          break;
        case PageFilterGroupType.Number:
          filterItems = filterItems.concat(this.createNumberFilterItems(pageFilterGroup));
          break;
        case PageFilterGroupType.Search:
          filterItems = filterItems.concat(this.createSearchFilterItems(pageFilterGroup));
          break;
        case PageFilterGroupType.Select:
          filterItems = filterItems.concat(this.createSelectFilterItems(pageFilterGroup));
          break;
        case PageFilterGroupType.String:
          filterItems = filterItems.concat(this.createStringFilterItems(pageFilterGroup));
          break;
      }
    });

    return filterItems;
  }

  private createCustomFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];

    if (pageFilterGroup.DisplayTitle) {
      filterItems.push({
        type: PageFilterGroupType.Custom,
        groupId: pageFilterGroup.Id,
        title: pageFilterGroup.DisplayTitle,
        value: pageFilterGroup.DisplayValue
      });
    }

    return filterItems;
  }

  private createDateFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];

    pageFilterGroup.FilterGroups?.forEach(filterGroup => {
      if (filterGroup.Filters?.length > 0) {
        const column = this.filterable.filterConfig.getColumn(filterGroup.Id);

        let filterType: PageFilterType;
        let from: ISO8601DateString;
        let to: ISO8601DateString;

        if (filterGroup.Filters.length > 1) {
          filterType = PageFilterType.Between;
          from = filterGroup.Filters[0].PropertyValue as string;
          to = filterGroup.Filters[1].PropertyValue as string;
        } else {
          filterType = filterGroup.Filters[0].FilterType;
          from = filterGroup.Filters[0].PropertyValue as string;
        }

        filterItems.push({
          type: PageFilterGroupType.Date,
          groupId: pageFilterGroup.Id,
          column,
          filterType,
          title: column?.title,
          value: from,
          value2: to
        });
      }
    });

    return filterItems;
  }

  private createNumberFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];

    pageFilterGroup.FilterGroups?.forEach(filterGroup => {
      if (filterGroup.Filters?.length > 0) {
        const column = this.filterable.filterConfig.getColumn(filterGroup.Id);

        let filterType: PageFilterType;
        let from: number;
        let to: number;

        if (filterGroup.Filters.length > 1) {
          filterType = PageFilterType.Between;
          from = filterGroup.Filters[0].PropertyValue as number;
          to = filterGroup.Filters[1].PropertyValue as number;
        } else {
          filterType = filterGroup.Filters[0].FilterType;
          from = filterGroup.Filters[0].PropertyValue as number;
        }

        filterItems.push({
          type: PageFilterGroupType.Number,
          groupId: pageFilterGroup.Id,
          column,
          filterType,
          title: column?.title,
          value: from,
          value2: to
        });
      }
    });

    return filterItems;
  }

  private createSearchFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];
    const value = this.pageFilterService.getValueFromSearch(pageFilterGroup);

    pageFilterGroup.FilterGroups?.forEach(filterGroup => {
      filterItems.push({
        type: PageFilterGroupType.Search,
        groupId: pageFilterGroup.Id,
        title: 'Search',
        value
      });
    });

    return filterItems;
  }

  private createSelectFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];

    pageFilterGroup.FilterGroups?.forEach(filterGroup => {
      if (filterGroup.Filters?.length > 0) {
        const column = this.filterable.filterConfig.getColumn(filterGroup.Id);

        filterItems.push({
          type: PageFilterGroupType.Select,
          groupId: pageFilterGroup.Id,
          column,
          title: column?.title,
          value: filterGroup.Filters.map(filterOptions => filterOptions.PropertyValue),
          autocomplete: column.autocomplete,
          selectOptions: column.selectOptions
        });
      }
    });

    return filterItems;
  }

  private createStringFilterItems(pageFilterGroup: PageFilterGroup): FilterListItem[] {
    const filterItems: FilterListItem[] = [];

    pageFilterGroup.FilterGroups?.forEach(filterGroup => {
      if (filterGroup.Filters?.length > 0) {
        const filter = filterGroup.Filters[0];
        const column = this.filterable.filterConfig.getColumn(filterGroup.Id);

        filterItems.push({
          type: PageFilterGroupType.String,
          groupId: pageFilterGroup.Id,
          column: column,
          title: column?.title,
          value: filter.PropertyValue
        });
      }
    });

    return filterItems;
  }
}
