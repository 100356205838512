<mc-data-grid [gridControl]="gridControl" [gridId]="gridId" [defaultVisibleColumns]="defaultVisibleColumns">
  <ng-template mcGridCell="Message" let-data>
    <div class="mc-build-logs-grid-text-container" *ngIf="data.Message">
      <div class="mc-build-logs-grid-text mc-truncate" [matTooltip]="data.Message">
        {{ data.Message }}
      </div>
      <div class="mc-build-logs-grid-text-actions">
        <button mat-icon-button (click)="openLogDialog(data.Message)" matTooltip="Show">
          <mat-icon fontSet="project-titan" fontIcon="icon-eye-open"></mat-icon>
        </button>
        <button mat-icon-button (click)="copyText(data.Message)" matTooltip="Copy">
          <mat-icon fontSet="project-titan" fontIcon="icon-copy"></mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template mcGridCell="File" let-data>
    <div class="mc-build-logs-grid-text-container" *ngIf="data.File">
      <div class="mc-build-logs-grid-text mc-truncate" [matTooltip]="data.File">
        {{ data.File }}
      </div>
      <div class="mc-build-logs-grid-text-actions">
        <button mat-icon-button (click)="copyText(data.File)" matTooltip="Copy">
          <mat-icon fontSet="project-titan" fontIcon="icon-copy"></mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template mcEmptyGrid="unfiltered">
    There are no logs for this build.
  </ng-template>
</mc-data-grid>
