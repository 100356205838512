import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ConditionSetsListModule } from '@portal-core/project-files/conditions/components/condition-sets-list/condition-sets-list.module';
import { ConditionsGridModule } from '@portal-core/project-files/conditions/components/conditions-grid/conditions-grid.module';
import { DialogAutoFocusModule } from '@portal-core/ui/dialog/directives/dialog-auto-focus/dialog-auto-focus.module';
import { ApplyConditionsDialogComponent } from './apply-conditions-dialog.component';

@NgModule({
  declarations: [
    ApplyConditionsDialogComponent
  ],
  imports: [
    CommonModule,
    ConditionsGridModule,
    ConditionSetsListModule,
    DialogAutoFocusModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  exports: [
    ApplyConditionsDialogComponent
  ],
})
export class ApplyConditionsDialogModule { }
