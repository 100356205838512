import { rangeNode } from '@common/prosemirror/model/node';
import { getParentFromNodeSelection } from '@common/prosemirror/state/selection';
import { ProseMirrorNode } from 'prosemirror-model';
import { NodeSelection, TextSelection, Transaction } from 'prosemirror-state';

/**
 * Selects a node's inline content starting at the given position.
 * If the node does not have inline content then the node itself is selected.
 * @param tr The transaction to apply the selection to.
 * @param node The node to select the content of.
 * @param pos The position to start the selection at.
 * @returns The transaction with the selection applied.
 */
export function selectNodeContent(tr: Transaction, node: ProseMirrorNode, pos: number): Transaction {
  if (node.inlineContent) {
    return tr.setSelection(TextSelection.create(tr.doc, pos + 1, pos + node.nodeSize - 1));
  } else {
    return tr.setSelection(NodeSelection.create(tr.doc, pos));
  }
}

/**
 * Selects a node's parent if the node is selected and the node's type is in selectParentForNodeNames.
 * @param tr The transaction to normalize the selection for.
 * @param selectParentForNodeNames The node names to select the parent for.
 * @returns The transaction with the selection normalized.
 */
export function normalizeNodeSelectionToParentNode(tr: Transaction, selectParentForNodeNames: string[]): Transaction {
  const selection = tr.selection;

  if (selection instanceof NodeSelection) {
    if (selectParentForNodeNames.includes(selection.node.type.name)) {
      const { parent, pos } = getParentFromNodeSelection(selection);
      if (NodeSelection.isSelectable(parent)) {
        tr = tr.setSelection(NodeSelection.create(tr.doc, pos));
      }
    }
  }

  return tr;
}

/**
 * Restores the selection on the specified node after applying changes on it, for example: unbind.
 * If the node is still exist in the document it will be selected, otherwise text where the node was located will be selected.
 * @param tr The transaction to apply the selection to.
 */
export function restoreNodeSelection(tr: Transaction, node: ProseMirrorNode, pos: number): Transaction {
  const doc = tr.doc, mapping = tr.mapping;
  const $anchor = doc.resolve(mapping.map(pos));
  const $head = doc.resolve(mapping.map(pos + node.nodeSize));
  const n = rangeNode($anchor, $head);
  if (n && !n.isText) {
    selectNodeContent(tr, n, $anchor.pos);
  } else {
    tr.setSelection(TextSelection.between($anchor, $head));
  }
  return tr;
}
