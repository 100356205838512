import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormControlErrorCodeModule } from '@portal-core/forms/components/form-control-error-code/form-control-error-code.module';
import { GridHeaderFilterModule } from '@portal-core/ui/grid/components/grid-header-filter/grid-header-filter.module';
import { GridHeaderFilterFormModule } from '@portal-core/ui/grid/directives/grid-header-filter-form/grid-header-filter-form.module';
import { GridHeaderFilterMenuModule } from '@portal-core/ui/grid/directives/grid-header-filter-menu/grid-header-filter-menu.module';
import { GridNumberFilterComponent } from './grid-number-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FormControlErrorCodeModule,
    GridHeaderFilterFormModule,
    GridHeaderFilterMenuModule,
    GridHeaderFilterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  declarations: [
    GridNumberFilterComponent
  ],
  exports: [
    GridNumberFilterComponent
  ]
})
export class GridNumberFilterModule { }
