import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';

@Pipe({
  name: 'mcReviewFileUserStatusIcon'
})
export class ReviewFileUserStatusIconPipe implements PipeTransform {
  transform(status: ReviewFileUserStatus | null | undefined): string {
    switch (status) {
      case ReviewFileUserStatus.Todo:
        return 'icon-todo';
      case ReviewFileUserStatus.Submitted:
        return 'icon-completed';
      case ReviewFileUserStatus.InProgress:
        return 'icon-inprogress';
    }
  }
}
