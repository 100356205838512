import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReviewFileReviewerStatusBarChartModule } from '@portal-core/reviews/review-files/components/review-file-reviewer-status-bar-chart/review-file-reviewer-status-bar-chart.module';
import { ReviewFileStatusBarChartModule } from '@portal-core/reviews/review-files/components/review-file-status-bar-chart/review-file-status-bar-chart.module';
import { ReviewFilesGridModule } from '@portal-core/reviews/review-files/components/review-files-grid/review-files-grid.module';
import { ReviewPackageOverviewComponent } from './review-package-overview.component';

@NgModule({
  declarations: [
    ReviewPackageOverviewComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReviewFileReviewerStatusBarChartModule,
    ReviewFileStatusBarChartModule,
    ReviewFilesGridModule
  ],
  exports: [
    ReviewPackageOverviewComponent
  ]
})
export class ReviewPackageOverviewModule { }
