import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Conversation } from '@portal-core/messages/models/conversation.model';

@CollectionStateSelectors({
  stateType: ConversationsState
})
@State<ConversationsState>({
  name: ConversationsState.source
})
@Injectable()
export class ConversationsState extends CollectionStateBase {
  static source = 'Conversations';
  getSource(): string {
    return ConversationsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConversationsDataService extends CollectionDataServiceBase<Conversation> {
  constructor(protected store: Store) {
    super(store, ConversationsState);
  }
}
