import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class MadCapEquationSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapequation: {
      group: 'inline',
      inline: true,
      content: '(math | mmlmath | text)*',
      atom: true,
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:equation'
      }],
      toDOM() { return ['span', { class: 'mc-madcap-equation' }, 'Equation']; },
      tagName: 'MadCap:equation'
    },
    /*
     * The <math> tag shouldn't normally exist on its own. It should have gotten wrapped by a <MadCap:equation> tag.
     * We should treat a <math> tag like a <MadCap:equation> tag until we add equation editing support. Then things can be reconsidered.
     */
    math: {
      group: 'inline',
      inline: true,
      attrs: {
        content: { default: undefined }
      },
      parseDOM: [{
        tag: 'math',
        getAttrs: function (dom: HTMLElement) {
          return {
            content: dom.outerHTML
          };
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-equation' }, 'Equation']; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, 'math', {
          xml: node.attrs.content
        });
      }
    }
  };
}
