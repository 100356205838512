import { FlareSchema } from '@common/flare/flare-schema';
import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChange, TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { marksHaveChange } from '@common/prosemirror/changeset/tracked-changes';
import { differenceWith, isMatch } from 'lodash';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';

export const gutterKey = new PluginKey('gutter');

export enum GutterItemType {
  Annotation = 'madcapannotation',
  Change = 'madcapchange'
}

export interface GutterAnnotationItem {
  data: Dictionary;
  id: string;
  index: number;
  schemaType: 'mark' | 'node';
  targetSelector: string;
  type: GutterItemType.Annotation;
}

export interface GutterChangeItem {
  changeIds: string;
  data: TrackedChange;
  id: string;
  index: number;
  schemaType: 'mark' | 'node';
  targetSelector: string;
  // targets: string;
  type: GutterItemType.Change;
}

export type GutterItem = GutterAnnotationItem | GutterChangeItem;

export type OnGutterItemsChanged = (items: GutterItem[], removed: GutterItem[], added: GutterItem[]) => void;

export interface GutterPluginOptions {
  onGutterItemsChanged: OnGutterItemsChanged;
  schema: FlareSchema;
}

export enum GutterPluginTargetType {
  Annotation = 'annotation',
  Change = 'change'
}

export enum GutterPluginTargetDecorationType {
  Inline = 'inline',
  Node = 'node'
}

export interface GutterPluginTargetItem {
  id: string;
  from: number;
  group: string;
  targetType: GutterPluginTargetType;
  to: number;
  type: GutterPluginTargetDecorationType;
}

export interface GutterMetaData {
  adds: GutterPluginTargetItem[];
  removes: GutterPluginTargetItem[] | 'all';
}

/*
 * GutterItemSet
 * Helper class to accumulate the gutter items in a prosemirror document
 */
class GutterItemsSet {
  foundAnnotationIds: Dictionary<boolean> = {};
  foundChangeIds: Dictionary<boolean> = {};
  items: GutterItem[] = [];

  addAnnotation(nodeAttrs: Dictionary) {
    if (!this.foundAnnotationIds[nodeAttrs['MadCap:guid']]) {
      this.items.push({
        data: nodeAttrs,
        id: nodeAttrs['MadCap:guid'],
        index: this.items.length,
        schemaType: 'node',
        targetSelector: '[madcap-guid="' + nodeAttrs['MadCap:guid'] + '"]',
        type: GutterItemType.Annotation
      });

      this.foundAnnotationIds[nodeAttrs['MadCap:guid']] = true;

      // TODO: determine if an annotation is selected or has the cursor in it and mark the annotation as active
    }
  }

  addChange(schemaType: 'mark' | 'node', changeIds: string, changeList: TrackedChangeList) {
    // If this node has a changes
    if (changeIds && Array.isArray(changeList)) {
      // let changeTargetIndex = 0;

      changeList.forEach(change => {
        // If this is not an orphaned tracked change (just check if the change type is defined) AND if the change has not already been added as a gutter item
        // Orphaned tracked changes can exist where the element has a MadCap:changes="1" attribute but has no matching change data in the <head>
        if (change.changeType && !this.foundChangeIds[change.id]) {
          this.items.push({
            changeIds: changeIds,
            data: change,
            id: change.id,
            index: this.items.length,
            schemaType,
            targetSelector: '[madcap-changes="' + changeIds + '"]',
            type: GutterItemType.Change
            // id: 'change-' + change.id + '-' + changeTargetIndex,
            // target: '[madcap-changes="' + changeIds + '"]:eq(' + changeTargetIndex + ')',
            // targets: '[madcap-changes="' + changeIds + '"]',
          });

          this.foundChangeIds[change.id] = true;
        }
      });
    }
  }
}

/*
 * GutterPluginView
 * Prosemirror plugin view class. Gathers the changes to all gutter items in the prosemirror document whenever the document changes.
 */
class GutterPluginView {
  gutterItems: GutterItem[] = [];

  constructor(editorView: EditorView, private schema: FlareSchema, private onGutterItemsChanged: OnGutterItemsChanged) {
    this.onGutterItemsChanged([], [], []);
    this.update(editorView, editorView.state);
  }

  destroy() {
    this.gutterItems = null;
  }

  update(editorView: EditorView, editorState: EditorState) {
    const gutterItemsSet = new GutterItemsSet();

    // Loop through the document finding all the gutter items
    editorView.state.doc.descendants(node => {
      const attrs: ChangeNodeAttrs = node.attrs;

      gutterItemsSet.addChange('node', attrs.changeIds, attrs.changeList);

      if (node.type.name === 'madcapannotation') {
        gutterItemsSet.addAnnotation(node.attrs);
      }

      if (Array.isArray(node.marks)) {
        node.marks.forEach(mark => {
          // Add change marks if they are not an untracked change
          if (mark.type.name === 'madcapchange' && !marksHaveChange(this.schema, [mark], ChangeType.Untracked)) {
            const markAttrs: ChangeNodeAttrs = mark.attrs;
            gutterItemsSet.addChange('mark', markAttrs.changeIds, markAttrs.changeList);
          }
        });
      }
    });

    // Get the gutter items that were removed and the items that were added
    const removed = differenceWith(this.gutterItems, gutterItemsSet.items, this.compareGutterItems);
    const added = differenceWith(gutterItemsSet.items, this.gutterItems, this.compareGutterItems);

    // Only fire the change event if there were changes to the gutterItems
    if (added.length > 0 || removed.length > 0) {
      this.onGutterItemsChanged(gutterItemsSet.items, removed, added);
    }

    this.gutterItems = gutterItemsSet.items;
  }

  protected compareGutterItems(itemA: GutterItem, itemB: GutterItem) {
    if (itemA.type !== itemB.type) {
      return false;
    }

    if (itemA.type === 'madcapannotation') {
      return itemA.data['MadCap:guid'] === itemB.data['MadCap:guid'] &&
        itemA.data['MadCap:comment'] === itemB.data['MadCap:comment'] &&
        itemA.data['MadCap:creator'] === itemB.data['MadCap:creator'] &&
        itemA.data['MadCap:creatorCentralUserId'] === itemB.data['MadCap:creatorCentralUserId'];
    } else if (itemA.type === 'madcapchange') {
      return itemA.id === itemB.id;
    }
  }
}

/*
 * A prosemirror plugin that adds support for an editor gutter by providing two different features.
 * Feature one: gathers gutter items within the document and emits events when the items change.
 * Feature two: adds and removes decorations to a gutter item's target node in the document.
 */
export function gutterPlugin(options: GutterPluginOptions): Plugin {
  return new Plugin({
    key: gutterKey,

    view(editorView: EditorView) {
      return new GutterPluginView(editorView, options.schema, options.onGutterItemsChanged);
    },

    state: {
      init() {
        return DecorationSet.empty;
      },

      apply: function (tr: Transaction, decorations: DecorationSet) {
        // Adjust decoration positions to changes made by the transaction
        decorations = decorations.map(tr.mapping, tr.doc);

        // See if the transaction adds or removes any active targets
        const metaData: GutterMetaData = tr.getMeta('gutter');

        if (metaData) {
          // If there is a remove action to perform
          if (metaData.removes) {
            // If all the existing decorations should be removed
            if (metaData.removes === 'all') {
              decorations = DecorationSet.empty;
            } else if (Array.isArray(metaData.removes)) {
              // Else treat removes like an array
              // Loop through each remove
              metaData.removes.forEach(remove => {
                // Remove any decoration that matches the remove object
                decorations = decorations.remove(decorations.find(null, null, spec => isMatch(spec, remove)));
              });
            }
          }

          // If there is an add action to perform
          if (Array.isArray(metaData.adds)) {
            const newDecorations: Decoration[] = [];

            // Loop through each add
            metaData.adds.forEach(add => {
              // If the add should be a node decoration
              if (add.type === GutterPluginTargetDecorationType.Node) {
                // Create the decoration with the properties from the add object
                newDecorations.push(Decoration.node(add.from, add.to, {
                  class: 'mc-editor-gutter-target mc-editor-gutter-node-target' + (add.targetType ? ' mc-editor-gutter-' + add.targetType + '-target' : '')
                }, {
                  id: add.id,
                  group: add.group
                }));
              } else if (add.type === GutterPluginTargetDecorationType.Inline) { // Else if the add should be an inline decoration
                // Create the decoration with the properties from the add object
                newDecorations.push(Decoration.inline(add.from, add.to, {
                  class: 'mc-editor-gutter-target mc-editor-gutter-inline-target' + (add.targetType ? ' mc-editor-gutter-' + add.targetType + '-target' : '')
                }, {
                  id: add.id,
                  group: add.group,
                  inclusiveStart: true,
                  inclusiveEnd: true
                } as any)); // Cast to any because the prosemirror-view definition file incorrectly does not allow custom properties
              }
            });

            // Add the new decorations
            decorations = decorations.add(tr.doc, newDecorations);
          }
        }

        // Return the new state of the decorations
        return decorations;
      }
    },

    props: {
      decorations(editorState: EditorState) {
        return this.getState(editorState);
      }
    }
  });
}
