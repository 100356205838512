<mc-select-list [dataListId]="dataListId" listClass="mc-list-selectable" [listControl]="listControl">
  <ng-template mcListItem let-licenseUser>
    <mat-list-item>
      <mc-license-user-avatar [disabled]="true" [licenseUser]="licenseUser" size="small" [truncate]="true"></mc-license-user-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>No users found.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All users loaded.</p>
  </ng-template>
</mc-select-list>