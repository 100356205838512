import { Injectable } from '@angular/core';
import { TaskAsset } from '@portal-core/tasks/models/task-asset.model';
import { TaskCommentAsset } from '@portal-core/tasks/models/task-comment-asset.model';
import { TaskComment } from '@portal-core/tasks/models/task-comment.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskCommentsDialogService {
  commentAssets$: BehaviorSubject<TaskCommentAsset[]> = new BehaviorSubject<TaskCommentAsset[]>([]);
  comments: TaskComment[];
  private userId: string;

  constructor() { }

  reset() {
    this.commentAssets$.next([]);
    this.comments = [];
  }

  initialize(userId: string, comments: TaskComment[]) {
    this.userId = userId;
    this.comments = comments || [];

    const commentAssets = [];
    this.comments.forEach(c => this.cacheCommentAssets(c, commentAssets));
    this.commentAssets$.next(commentAssets);
  }

  createComment(taskId: number): TaskComment {
    const newComment: TaskComment = {
      Id: 0,
      TaskId: taskId,
      Comment: null,
      IsDeleted: false,
      CreatedByUserId: this.userId,
      CreatedOn: null,
    };

    return newComment;
  }

  addComment(comment: TaskComment): TaskComment {
    const newComment: TaskComment = this.createComment(comment.TaskId);
    newComment.ParentTaskCommentId = comment.Id;

    this.comments.push(newComment);

    return newComment;
  }

  addAsset(comment: TaskComment, file: File, formData: FormData): TaskAsset {
    const asset: TaskAsset = {
      Id: 0,
      TaskId: comment.TaskId,
      IsDeleted: false,
      FileName: file.name,
      MimeType: file.type,
      StorageId: '',
      Extension: file.name.split('.')[1],
      SizeInBytes: file.size,
      CreatedOn: new Date().toISOString(),
      UploadedByUserId: this.userId,
      TaskCommentId: comment.Id,
      FormData: formData,
    };

    if (!comment.TaskAssets) {
      comment.TaskAssets = [];
    }
    comment.TaskAssets.push(asset);

    const commentAsset: TaskCommentAsset = {
      Comment: comment,
      Asset: asset
    };
    this.commentAssets$.next(this.commentAssets$.getValue().concat(commentAsset));

    return asset;
  }

  deleteComment(comment: TaskComment) {
    comment.IsDeleted = true;
    if (comment.ChildComments) {
      comment.ChildComments.forEach(c => c.IsDeleted = true);
    }
    this.commentAssets$.next(this.commentAssets$.getValue().filter(ca => ca.Comment !== comment));
  }

  deleteAsset(comment: TaskComment, asset: TaskAsset) {
    const index = comment.TaskAssets?.indexOf(asset, 0);
    if (index > -1) {
      comment.TaskAssets.splice(index, 1);
    }
    asset.IsDeleted = true;
    this.commentAssets$.next(this.commentAssets$.getValue().filter(a => a.Asset !== asset));
  }

  deleteNewAssets() {
    this.commentAssets$.next(this.commentAssets$.getValue().filter(a => a.Asset.Id !== 0));
  }

  private cacheCommentAssets(taskComment: TaskComment, commentAssets: TaskCommentAsset[]) {
    if (taskComment.TaskAssets) {
      taskComment.TaskAssets.forEach(asset => {
        const commentAsset: TaskCommentAsset = {
          Comment: taskComment,
          Asset: asset
        };
        commentAssets.push(commentAsset);
      });
    }
    if (taskComment.ChildComments) {
      taskComment.ChildComments.forEach(c => this.cacheCommentAssets(c, commentAssets));
    }
  }
}
