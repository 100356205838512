import { Injectable } from '@angular/core';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilterOptions } from '@common/paged-data/types/page-filter-options.type';
import { FilterBuilder } from '@common/paged-data/util/filter-builder';
import { getFilterCount, getFilterCountFromDictionary } from '@common/paged-data/util/filter-group';
import { toBoolean } from '@common/util/bool';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';

@Injectable({
  providedIn: 'root'
})
export class PageFilterService {
  /**
   * Creates a FilterBuilder instance.
   * @param filterGroup Optionally provide a filter group to initialize the FilterBuilder with.
   */
  create(filterGroup: PageFilterGroup): FilterBuilder {
    return new FilterBuilder(filterGroup);
  }

  /**
   * Sums all the filters in a dictionary of filter groups.
   * @param filters A dictionary of filter groups to count the filters in.
   */
  getFilterCountFromDictionary(filters: Dictionary<PageFilterGroup>): number {
    return getFilterCountFromDictionary(filters);
  }

  /**
   * Sums all the filters in the filter group.
   * @param filterGroup The filter group to count the filters in.
   * @param columnName Optionally provide a column name to get the filter count for a sub filter group.
   */
  getFilterCount(filterGroup: PageFilterGroup, columnName?: string): number {
    return getFilterCount(columnName ? this.getFilterGroup(filterGroup, columnName) : filterGroup);
  }

  /**
   * Returns a sub filter group from a filter group that has the given id.
   * @param filterGroup The filter group to get the sub filter group from.
   * @param filterGroupId The id of the filter group to find.
   */
  getFilterGroup(filterGroup: PageFilterGroup, filterGroupId: string): PageFilterGroup {
    return filterGroup?.FilterGroups?.find(subFilterGroup => subFilterGroup.Id === filterGroupId);
  }

  /**
   * Returns a filter options object by searching a filter group for a date filter with the given column name.
   * Works with a filter group created by FilterBuilder.
   * @param filterGroup The filter group to get the filter from.
   * @param columnName The name of the column to get the filter for.
   */
  getFilterFromDates(filterGroup: PageFilterGroup, columnName: string): PageFilterOptions {
    return this.getFilterGroup(filterGroup, columnName)?.Filters?.[0];
  }

  /**
   * Returns the value of a bool filter by searching a filter group for a bool filter with the given column name.
   * Works with a filter group created by FilterBuilder.
   * @param filterGroup The filter group to get the filter from.
   * @param columnName The name of the column to get the filter for.
   */
  getValueFromBools(filterGroup: PageFilterGroup, columnName: string): boolean {
    return toBoolean(this.getFilterGroup(filterGroup, columnName)?.Filters?.[0]?.PropertyValue, null);
  }

  /**
   * Returns the value of a select filter by searching a filter group for a select filter with the given column name.
   * Works with a filter group created by FilterBuilder.
   * @param filterGroup The filter group to get the filter from.
   * @param columnName The name of the column to get the filter for.
   */
  getValueFromSelects(filterGroup: PageFilterGroup, columnName: string): (number | string)[] {
    return this.getFilterGroup(filterGroup, columnName)?.Filters?.map(filterOptions => filterOptions.PropertyValue);
  }

  /**
   * Returns the value of a search filter by grabbing the value from the first filter.
   * Works with a filter group created by FilterBuilder.
   * @param filterGroup The filter group to get the filter from.
   */
  getValueFromSearch(filterGroup: PageFilterGroup): string {
    return filterGroup?.FilterGroups?.[0]?.Filters?.[0]?.PropertyValue as string;
  }

  /**
   * Returns the value of a string filter by searching a filter group for a string filter with the given column name.
   * Works with a filter group created by FilterBuilder.
   * @param filterGroup The filter group to get the filter from.
   * @param columnName The name of the column to get the filter for.
   */
  getValueFromStrings(filterGroup: PageFilterGroup, columnName: string): string {
    return this.getFilterGroup(filterGroup, columnName)?.Filters?.[0]?.PropertyValue as string;
  }

  /**
   * Excludes a set of columns from a column array by name.
   * @param columns The columns to filter.
   * @param columnsToExclude The names of the columns to filter out.
   */
  excludeColumns(columns: GridColumn[], columnsToExclude: string[]): string[] {
    return Array.isArray(columnsToExclude) ? columns.filter(column => !columnsToExclude.includes(column.name)).map(column => column.name) : null;
  }
}
