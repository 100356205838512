import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackageSource } from '@portal-core/reviews/review-packages/enums/review-package-source.enum';

@Pipe({
  name: 'mcReviewPackageSourceText'
})
export class ReviewPackageSourceTextPipe implements PipeTransform {
  transform(status: ReviewPackageSource | null | undefined): string {
    switch (status) {
      case ReviewPackageSource.Central:
        return 'Central';
      case ReviewPackageSource.Flare:
        return 'Flare';
    }
  }
}
