import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { MessagesState } from '@portal-core/messages/services/messages-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([MessagesState])
  ]
})
export class MessagesServicesModule { }
