import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-user-password-reset-form',
  templateUrl: './user-password-reset-form.component.html',
  styleUrls: ['./user-password-reset-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPasswordResetFormComponent implements OnInit {
  @Input() licenseUser: LicenseUser;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetting: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();
  showCancelButton: boolean;

  constructor(private authService: AuthService, private errorService: ErrorService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.showCancelButton = this.cancel.observers.length > 0;
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    const resettingEvent = new CancelableEvent();
    this.resetting.emit(resettingEvent);

    if (!resettingEvent.defaultPrevented) {
      this.savingState.update(true);

      this.authService.requestPasswordReset$(this.licenseUser.User.Email).subscribe(() => {
        this.savingState.update(false);

        this.snackBar.open('The password reset email has been sent.', 'OK', {
          duration: 2500
        });

        this.reset.emit();
      }, error => {
        this.savingState.update(false, 'Unable to send the password reset email.', this.errorService.getErrorMessages(error));
      });
    }
  }
}
