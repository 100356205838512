import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectCommitDiffModule } from '@portal-core/commits/components/project-commit-diff/project-commit-diff.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { FlareTextEditorModule } from '@portal-core/project-files/components/flare-text-editor/flare-text-editor.module';
import { ProjectFilesAIDialogComponent } from '@portal-core/project-files/components/project-files-ai-dialog/project-files-ai-dialog.component';
import { DiffPipeModule } from '@portal-core/project-files/pipes/diff/diff.module';
import { FlareCodePipeModule } from '@portal-core/project-files/pipes/flare-code/flare-code.module';
import { MarkdownToFlareXMLModule } from '@portal-core/project-files/pipes/markdown-to-flare-xml/markdown-to-flare-xml.module';
import { NormalizeFlareXMLModule } from '@portal-core/project-files/pipes/normalize-flare-xml/normalize-flare-xml.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';

@NgModule({
  declarations: [
    ProjectFilesAIDialogComponent
  ],
  imports: [
    CommonModule,
    DiffPipeModule,
    ErrorsExpansionPanelModule,
    FlareCodePipeModule,
    FlareTextEditorModule,
    FormsModule,
    MarkdownToFlareXMLModule,
    MatProgressBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NormalizeFlareXMLModule,
    ProjectCommitDiffModule,
    ReactiveFormsModule,
    SwitchBoardModule
  ],
  exports: [
    ProjectFilesAIDialogComponent
  ]
})
export class ProjectFilesAIDialogModule { }
