import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageLanguageBranchesApiService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches-api.service';
import { TranslationPackageLanguageBranchesDataService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches-data.service';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TranslationPackageLanguageBranchesService extends CollectionServiceBase<TranslationPackageLanguageBranch> {
  constructor(private translationPackagesApiService: TranslationPackageLanguageBranchesApiService, private translationPackagesDataService: TranslationPackageLanguageBranchesDataService, protected dataService: DataService) {
    super(translationPackagesDataService, dataService);
  }

  uploadPackage$(file: File, translationPackageId: number): Observable<MadCloudResult> {
    return this.translationPackagesApiService.uploadPackage$(file, translationPackageId);
  }

  getProjectTranslationPackageLanguageBranches$(projectId: number, filter: PageFilter): Observable<Page<TranslationPackageLanguageBranch>> {
    return this.translationPackagesApiService.getProjectTranslationPackageLanguageBranches$(projectId, filter);
  }

  deletePackages$(translationPackageIds: number[]): Observable<MadCloudResult> {
    return this.translationPackagesApiService.deletePackages$(translationPackageIds);
  }

  updatePackageLanguageStatuses$(translationPackageIds: number[], status: TranslationPackageStatus): Observable<MadCloudResult> {
    return this.translationPackagesApiService.updatePackageLanguageStatuses$(translationPackageIds, status);
  }
}
