import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DoughnutChartHoleDirective } from '@portal-core/ui/charts/directives/doughnut-chart-hole/doughnut-chart-hole.directive';
import { DoughnutChartHoleType } from '@portal-core/ui/charts/enums/doughnut-chart-hole-type.enum';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { ArcElement, Chart, ChartData, ChartOptions, DoughnutController } from 'chart.js';
import { Observable, combineLatest, map } from 'rxjs';

Chart.register(ArcElement, DoughnutController);

interface HoleInfo { value: number; label: string; }

@Component({
  selector: 'mc-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DoughnutChartComponent implements OnInit, AfterContentInit {
  @Input() data: ChartData<'doughnut'>;
  @Input() options: ChartOptions;
  @Input() holeIndex: number;
  @Input() holeType: DoughnutChartHoleType = DoughnutChartHoleType.Percentage;

  @InputObservable('data') data$: Observable<ChartData<'doughnut'>>;
  @InputObservable('holeIndex') holeIndex$: Observable<number>;
  @InputObservable('holeType') holeType$: Observable<DoughnutChartHoleType>;

  @ContentChild(DoughnutChartHoleDirective) doughnutChartHoleDirective: DoughnutChartHoleDirective;

  holeLabel: string;
  holeTemplate: TemplateRef<any>;
  holeValue: number = null;
  hole$: Observable<HoleInfo>;

  ngAfterContentInit() {
    this.holeTemplate = this.doughnutChartHoleDirective && this.doughnutChartHoleDirective.templateRef || null;
  }

  ngOnInit() {
    this.hole$ = combineLatest([
      this.data$,
      this.holeIndex$,
      this.holeType$
    ]).pipe(
      map(([data, holeIndex, holeType]) => {
        if (data && holeType && typeof holeIndex === 'number') {
          return this.getDoughnutHole(data, holeIndex, holeType);
        } else {
          return { value: null, label: '' } as HoleInfo;
        }
      })
    );
  }

  getDoughnutHole(data: ChartData<'doughnut'>, holeIndex: number, holeType: DoughnutChartHoleType): HoleInfo {
    const holeInfo: HoleInfo = { value: null, label: '' };
    const dataValues: number[] = data?.datasets?.[0]?.data;

    if (dataValues) {
      switch (holeType) {
        case DoughnutChartHoleType.Percentage:
          const holeTotal = dataValues.reduce((total, value) => total + value, 0);
          if (holeTotal > 0) {
            holeInfo.value = dataValues[holeIndex] / holeTotal;
          } else {
            holeInfo.value = 1; // Default to 100% if there is no total
          }
          break;
        case DoughnutChartHoleType.Value:
          holeInfo.value = dataValues[holeIndex];
          break;
      }

      holeInfo.label = Array.isArray(data.labels) ? (data.labels as string[])[holeIndex] : '';
      return holeInfo;
    } else {
      return null;
    }
  }
}
