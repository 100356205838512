import { ProjectConditionTag } from '@portal-core/project-files/conditions/models/project-condition-tag.model';
import { differenceWith, isEqual } from 'lodash';

type ConditionStyleOptions = {
  gradientAngle?: number;
  repeatable?: boolean;
  conditionLineWidth?: number;
  definitionSuffix?: string;
  /** Allows to modify the conditional selector, e.g. add different classes for inline and block elements. */
  patchSelector?: (selector: string) => string;
  /** Allows to override the tag background color, e.g. make it darker or lighter. */
  patchColor?: (color: string) => string;
  unknownTagColor: string;
  loadingStylesBuilder?: LoadingConditionStylesBuilder
}

/**
 * A help class to generate background styles for project conditions using specified options.
 */
export class BackgroundStylesBuilder {

  private head: HTMLHeadElement;

  private colorDefinitionsNode: HTMLStyleElement = null;
  private colorDefinitions: Dictionary<string> = {};

  private conditionStylesNode: HTMLStyleElement = null;
  private conditionStyles: Dictionary<boolean> = {};

  private unit: string;

  constructor(private options: ConditionStyleOptions) {
    this.unit = this.options.repeatable ? 'px' : '%';
  }

  init(head: HTMLHeadElement) {
    this.head = head;
    this.reset();
  }

  reset() {
    this.detachStyleNodes();

    if (!this.head)
      return;

    this.colorDefinitionsNode = document.createElement('style');
    this.colorDefinitions = {};

    this.conditionStylesNode = document.createElement('style');
    this.conditionStyles = {};

    // attach style nodes
    this.appendNode(this.colorDefinitionsNode);
    this.appendNode(this.conditionStylesNode);

    // use colorDefinitionsNode to configure loading condition styles
    // loading condition style will be removed when condition tags are provided
    this.options.loadingStylesBuilder?.buildStyles(this.colorDefinitionsNode);
  }

  destroy() {
    this.detachStyleNodes();
  }

  private detachStyleNodes() {
    this.removeNode(this.colorDefinitionsNode);
    this.removeNode(this.conditionStylesNode);
  }

  private appendNode(node: HTMLElement) {
    this.head.appendChild(node);
  }

  private removeNode(node: HTMLElement) {
    if (node?.parentNode)
      node.parentNode.removeChild(node);
  }

  /** Creates the CSS variables that define project condition colors. */
  createColorDefinitions(conditionTags: ProjectConditionTag[]) {
    // reset color definitions
    this.colorDefinitions = {};
    this.colorDefinitionsNode.innerHTML = '';
    // reset condition styles
    this.conditionStyles = {};
    this.conditionStylesNode.innerHTML = '';

    if (Array.isArray(conditionTags)) {
      this.colorDefinitionsNode.append(':root {');

      conditionTags.forEach(tag => {
        const fullName: string = tag.Id;
        // create color definition
        const colorName = this.toCssVariableName(fullName);
        const definition = ` ${colorName} : ${this.getColor(tag)};`;
        // add color definition
        this.colorDefinitions[fullName] = colorName;
        this.colorDefinitionsNode.append(definition);
      });

      this.colorDefinitionsNode.append('}');
    }
  }

  private addUnknownTag(tagName: string) {
    this.colorDefinitionsNode.append(':root {');
    // create color definition
    const colorName = this.toCssVariableName(tagName);
    const definition = ` ${colorName} : ${this.getColor(null)};`;
    // add color definition
    this.colorDefinitions[tagName] = colorName;
    this.colorDefinitionsNode.append(definition);

    this.colorDefinitionsNode.append('}');
  }

  private toCssVariableName(conditionTagFullName: string): string {
    const name = conditionTagFullName.replaceAll(new RegExp('[/\.]', 'g'), '_').replaceAll(new RegExp('[^a-zA-Z0-9_]', 'g'), '-');
    return `--condition-tag-color-${name}${this.options.definitionSuffix}`;
  }

  private getColor(tag?: ProjectConditionTag): string {
    let color: string = tag?.BackgroundColor || this.options.unknownTagColor || '#ffffff';
    return this.options.patchColor ? this.options.patchColor(color) : color;
  }

  /** Builds a background style for the specified condition value. */
  buildBackgroundStyle(condition: string) {
    // skip processed condition
    if (this.conditionStyles[condition])
      return;

    // skip if no colors (y.e. colors are not loaded yet)
    if (!Object.keys(this.colorDefinitions).length)
      return;

    const colors = this.conditionToColors(condition);
    if (colors.length) {

      let pos = 0;
      let width = this.options.repeatable ? this.options.conditionLineWidth : 100 / colors.length;
      const colorsLine = [];
      colors.forEach(colorName => {
        const next = pos + width;
        colorsLine.push(`var(${colorName}) ${pos}${this.unit} ${next}${this.unit}`);
        pos = next;
      });

      const gradientType = this.options.repeatable ? 'repeating-linear-gradient' : 'linear-gradient';
      const gradientValue = `${gradientType}( ${this.options.gradientAngle}deg, ${colorsLine.join(', ')} )`;

      let cssSelector = `[madcap\\:conditions="${condition}"]`;
      cssSelector = this.options.patchSelector ? this.options.patchSelector(cssSelector) : cssSelector;

      const backgroundRule = `${cssSelector} { background: ${gradientValue} !important }`;

      this.conditionStylesNode.append(backgroundRule);
    }

    this.conditionStyles[condition] = true;
  }

  private conditionToColors(condition: string): string[] {
    const tagNames = condition?.split(',').sort();
    const colors = [];
    tagNames?.forEach(tagName => {
      if (!this.colorDefinitions[tagName])
        this.addUnknownTag(tagName);
      const colorName = this.colorDefinitions[tagName];
      if (colorName)
        colors.push(colorName);
    });
    return colors;
  }
}

export class LoadingConditionStylesBuilder {

  constructor(private selector: string, private animationName: string) { }

  buildStyles(styleNode: HTMLStyleElement): void {
    styleNode.innerHTML = '';
    // add animated style
    styleNode.append(`${this.selector} {`);
    styleNode.append('background: linear-gradient(to bottom right, #0000 calc(50% - 2rem), #fff 50%, #0000 calc(50% + 2rem)) bottom right/calc(200% + 4rem) calc(200% + 4rem) #ddd;');
    styleNode.append(`animation: ${this.animationName} 1s infinite;`);
    styleNode.append('}');
    // add animation frames
    styleNode.append(`@keyframes ${this.animationName} {`);
    styleNode.append('100% { background-position: top left }');
    styleNode.append('}');
  }
}

/**
 * ProjectConditionsStyleManager allows to generate styles to highlight nodes with applied conditions in text editor.
 */
export class ProjectConditionsStyleManager {

  private conditionTags: ProjectConditionTag[];
  private projectConditionsLoaded: boolean = false;
  private processedConditions: string[] = [];
  private queuedConditions: string[] = [];

  private builders: BackgroundStylesBuilder[] = [];

  init(...builders: BackgroundStylesBuilder[]): void {
    this.builders = builders;
    const head: HTMLHeadElement = document.querySelector('head');
    this.builders.forEach(builder => builder.init(head));
  }

  destroy() {
    this.builders.forEach(builder => builder.destroy());
  }

  reset() {
    this.conditionTags = undefined;
    this.projectConditionsLoaded = false;
    this.processedConditions = [];
    this.queuedConditions = [];

    this.builders.forEach(builder => builder.reset());
  }

  /** Allows to set conditions to define condition colors. */
  setConditions(conditionTags: ProjectConditionTag[]) {
    if (isEqual(this.conditionTags, conditionTags)) {
      // prevent duplicate condition tags from being processed
      return;
    }
    // cache passed conditions
    const currentConditions = [...this.processedConditions, ...this.queuedConditions];
    // reset all generated styles
    this.reset();
    // queue cached conditions to be processed again
    this.queuedConditions = currentConditions;

    this.conditionTags = conditionTags;
    this.projectConditionsLoaded = !!this.conditionTags;

    if (this.projectConditionsLoaded) {
      this.builders.forEach(builder => builder.createColorDefinitions(conditionTags));
      this.processQueue();
    }
  }

  /** Takes array of applied condition values to generate styles. */
  processAppliedConditions(values: string[]) {
    const added = differenceWith(values, [...this.processedConditions, ...this.queuedConditions], (a, b) => a === b);
    if (added.length) {
      this.queuedConditions = this.queuedConditions.concat(added);
      this.processQueue();
    };
  }

  private processQueue() {
    // if project conditions not loaded then skip processing
    if (!this.projectConditionsLoaded)
      return;
    // if queue is empty then skip processing
    if (!this.queuedConditions.length)
      return;

    // process and clean queued conditions
    this.queuedConditions.forEach(condition => {
      this.builders.forEach(builder => builder.buildBackgroundStyle(condition));
    });
    this.processedConditions = [...this.processedConditions, ...this.queuedConditions];
    this.queuedConditions = [];
  }

}
