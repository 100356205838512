export enum AuthStatusMessage {
  Admin = 'Admin',
  Authorized = 'Authorized',
  BillingFailed = 'BillingFailed',
  DeactivatedLicense = 'DeactivatedLicense',
  ExpiredLicense = 'ExpiredLicense',
  ExpiredTrial = 'ExpiredTrial',
  LicenseNotFound = 'LicenseNotFound',
  NeedsPermission = 'NeedsPermission',
  NeedsRole = 'NeedsRole',
  UserNotActive = 'UserNotActive',
  UsersExceeded = 'UsersExceeded',
  UserSiteAccessDenied = 'UserSiteAccessDenied',
  // Client only
  UserAccessDenied = 'UserAccessDenied'
}
