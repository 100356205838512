import { Observable } from 'rxjs';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { Permissions } from '@portal-core/permissions/models/permissions.model';

export class PermissionsFormControl {
  constructor(
    public getPermissionsOnLicenseLevel$: (entityId: ModelId, licenseId: number) => Observable<Permissions[]>,
    public getPermissionsOnProjectLevel$: (entityId: ModelId, projectId: number) => Observable<Permissions[]>,
    public savePermissionsOnLicenseLevel$: (entityId: ModelId, licenseId: number, permissions: Permissions[]) => Observable<any>,
    public savePermissionsOnProjectLevel$: (entityId: ModelId, projectId: number, permissions: Permissions[]) => Observable<any>
  ) { }
}
