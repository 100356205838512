import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { TranslationPackageUpdate } from '@portal-core/translation-packages/models/translation-package-update.model';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationPackagesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getTranslationPackageById$(itemId: number): Observable<TranslationPackage> {
    return this.http.get<TranslationPackage>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/${itemId}`);
  }

  @SharePendingRequest()
  getBatchTranslationPackagesByIds$(itemIds: number[]): Observable<TranslationPackage[]> {
    return this.http.post<TranslationPackage[]>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/getBatchTranslationPackagesByIds`, itemIds);
  }

  @SharePendingRequest()
  getProjectPackages$(projectId: number, filter: PageFilter): Observable<Page<TranslationPackage>> {
    return this.http.post<Page<TranslationPackage>>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/${projectId}/getProjectPackages`, filter);
  }

  createTranslationPackage$(packageModel: TranslationPackage): Observable<TranslationPackage> {
    return this.http.post<TranslationPackage>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage`, packageModel);
  }

  updatePackage$(translationPackage: TranslationPackageUpdate): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/updateSettings`, translationPackage);
  }

  getArchivePath(translationPackageId: number): string {
    return `${this.apiService.centralArchiveBaseUri}/translationPackage/${translationPackageId}`;
  }

  getPackageManifest$(translationPackageId: number): Observable<any> {
    const opts: any = { responseType: 'blob' };
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/${translationPackageId}/getManifest`, opts);
  }

  deletePackage$(translationPackageId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/${translationPackageId}`);
  }

  deletePackages$(translationPackageIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/bulkDelete`, translationPackageIds);
  }

  updatePackageStatuses$(translationPackageIds: number[], status: TranslationPackageStatus): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/TranslationPackage/status/${status}`, translationPackageIds);
  }
}
