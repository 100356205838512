import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorCodeModule } from '@portal-core/errors/components/error-code/error-code.module';
import { FormControlErrorCodeComponent } from './form-control-error-code.component';

@NgModule({
  declarations: [
    FormControlErrorCodeComponent
  ],
  imports: [
    CommonModule,
    ErrorCodeModule
  ],
  exports: [
    FormControlErrorCodeComponent
  ]
})
export class FormControlErrorCodeModule { }
