export enum ProcessState {
  Unknown = 0,
  Failed = 1,
  Building = 2,
  Complete = 3,
  Cancelled = 4,
  Queued = 5,
  Publishing = 6,
  GettingFiles = 7
}
