<div class="mc-user-usage-bar-header">
  <b>{{ title$ | async }}</b>

  <span class="mc-fill-remaining-flex-space"></span>

  <span *ngIf="isUnlimited$ | async" class="mc-text-muted">{{ usedSeats$ | async | number: '1.0-0' }} of unlimited seats</span>
  <span *ngIf="!(isUnlimited$ | async)" class="mc-text-muted">{{ usedSeats$ | async | number: '1.0-0' }} of {{ totalSeats$ | async | number: '1.0-0' }} {{ (totalSeats$ | async) === 1 ? 'seat' : 'seats' }} ({{ availableSeats$ | async | number: '1.0-0' }} available)</span>
</div>

<mat-progress-bar mode="determinate" [color]="color$ | async" [value]="usedSeatsPercentage$ | async"></mat-progress-bar>
