import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { BuildDeletionFormModule } from '@portal-core/builds/components/build-deletion-form/build-deletion-form.module';
import { BuildLogsGridModule } from '@portal-core/build-logs/components/build-logs-grid/build-logs-grid.module';
import { BuildMessagesListModule } from '@portal-core/build-logs/components/build-messages-list/build-messages-list.module';
import { BuildOverviewModule } from '@portal-core/builds/components/build-overview/build-overview.module';
import { BuildSettingsFormModule } from '@portal-core/builds/components/build-settings-form/build-settings-form.module';
import { ConfirmDialogModule } from '@portal-core/general/components/confirm-dialog/confirm-dialog.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { DurationModule } from '@portal-core/general/pipes/duration/duration.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { BuildProfileDialogComponent } from './build-profile-dialog.component';

@NgModule({
  declarations: [
    BuildProfileDialogComponent
  ],
  imports: [
    BranchAvatarModule,
    BuildAvatarModule,
    BuildDeletionFormModule,
    BuildLogsGridModule,
    BuildMessagesListModule,
    BuildOverviewModule,
    BuildSettingsFormModule,
    CommonModule,
    ConfirmDialogModule,
    DurationModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatTabsModule,
    PreventDefaultModule,
    ProjectAvatarModule,
    RouterModule,
    SwitchBoardModule,
    TargetAvatarModule
  ],
  exports: [
    BuildProfileDialogComponent
  ]
})
export class BuildProfileDialogModule { }
