import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';

@Pipe({
  name: 'mcReviewFileUserStatusText'
})
export class ReviewFileUserStatusTextPipe implements PipeTransform {
  transform(status: ReviewFileUserStatus | null | undefined): string {
    switch (status) {
      case ReviewFileUserStatus.Todo:
        return 'To Do';
      case ReviewFileUserStatus.Submitted:
        return 'Submitted';
      case ReviewFileUserStatus.InProgress:
        return 'In Progress';
    }
  }
}
