import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { OutputAnalyticsReportItemState } from '@portal-core/output-analytics/services/report-items/output-analytics-report-item-data.service';
import { CshIdCallsReportState } from '@portal-core/output-analytics/services/csh-id-calls/csh-id-calls-report-data.service';
import { SearchQueryReportState } from '@portal-core/output-analytics/services/search-query/search-query-report-data.service';
import { TopicViewsReportState } from '@portal-core/output-analytics/services/topic-views/topic-views-report-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      OutputAnalyticsReportItemState,
      CshIdCallsReportState,
      SearchQueryReportState,
      TopicViewsReportState,
    ])
  ]
})
export class OutputAnalyticsReportServicesModule { }
