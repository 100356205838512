import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseHostMap } from '@portal-core/license-host-maps/models/license-host-map.model';
import { LicenseHostMapsApiService } from '@portal-core/license-host-maps/services/license-host-maps-api.service';
import { LicenseHostMapsDataService } from '@portal-core/license-host-maps/services/license-host-maps-data.service';
import { Observable, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseHostMapsService extends CollectionServiceBase<LicenseHostMap> {
  constructor(private licenseHostMapApiService: LicenseHostMapsApiService, protected licenseHostMapsDataService: LicenseHostMapsDataService, protected dataService: DataService) {
    super(licenseHostMapsDataService, dataService);
  }

  getLicenseHostMap$(licenseHostMapId: number): Observable<LicenseHostMap> {
    return this.licenseHostMapApiService.getLicenseHostMap$(licenseHostMapId);
  }

  getCNameValidation$(licenseId: number, domain: string, licenseHostMapId?: number): Observable<MadCloudResult> {
    return this.licenseHostMapApiService.getCNameValidation$(licenseId, domain, licenseHostMapId);
  }

  addLicenseHostMap$(licenseId: number, licenseHostMap: LicenseHostMap, email: string, password: string): Observable<LicenseHostMap> {
    return this.licenseHostMapApiService.addLicenseHostMap$(licenseId, licenseHostMap, email, password);
  }

  updateLicenseHostMap$(licenseId: number, licenseHostMap: LicenseHostMap, email: string, password: string): Observable<LicenseHostMap> {
    return this.licenseHostMapApiService.updateLicenseHostMap$(licenseId, licenseHostMap, email, password).pipe(
      switchMap(newLicenseHostMap => {
        return this.updateItems$([newLicenseHostMap]).pipe(
          map(() => newLicenseHostMap)
        );
      })
    );
  }

  deleteLicenseHostMap$(licenseId: number, licenseHostMapId: number): Observable<MadCloudResult> {
    return this.licenseHostMapApiService.deleteLicenseHostMap$(licenseId, licenseHostMapId).pipe(
      switchMap(result => {
        return this.updateItems$({
          [licenseHostMapId]: {
            InProcess: true,
            IsDeleted: true
          }
        }).pipe(
          map(() => result)
        );
      })
    );
  }

  setLicenseHostMapDefaultSite$(licenseId: number, licenseHostMapId: number, defaultSiteId: number): Observable<MadCloudResult> {
    return this.licenseHostMapApiService.setLicenseHostMapDefaultSite$(licenseId, licenseHostMapId, defaultSiteId).pipe(
      switchMap(result => {
        return this.updateItems$({
          [licenseHostMapId]: {
            DefaultSiteId: defaultSiteId
          }
        }).pipe(
          map(() => result)
        );
      })
    );
  }
}
