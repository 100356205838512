import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor() { }

  copy(text: string): boolean {
    const selBox = document.createElement('textarea');
    let success = true;
    try {
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
    } catch {
      success = false;
    } finally {
      document.body.removeChild(selBox);
    }
    return success;
  }
}
