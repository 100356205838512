import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BranchesSelectListModule } from '@portal-core/branches/components/branches-select-list/branches-select-list.module';
import { BranchAsyncModule } from '@portal-core/branches/pipes/branch-async/branch-async.module';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ConfirmDialogModule } from '@portal-core/general/components/confirm-dialog/confirm-dialog.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ProjectFilePickerInputModule } from '@portal-core/project-files/components/project-file-picker-input/project-file-picker-input.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { TranslationPackageSettingsFormComponent } from './translation-package-settings-form.component';

@NgModule({
  declarations: [
    TranslationPackageSettingsFormComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormControlErrorMessageModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    BranchesSelectListModule,
    TargetAvatarModule,
    ProjectFilePickerInputModule,
    ConfirmDialogModule,
    AutocompleteInputModule,
    BranchAvatarModule,
    BranchAsyncModule
  ],
  exports: [
    TranslationPackageSettingsFormComponent
  ],
})
export class TranslationPackageSettingsFormModule { }
