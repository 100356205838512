import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { ProjectsState } from './projects-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProjectsState])
  ]
})
export class ProjectsServicesModule { }
