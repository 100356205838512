import { NodeType } from '@common/html/enums/node-type.enum';
import { cloneElementWithNewName, getAttributeStyle, hasAttributeStyle, removeClasses, removeStyles } from '@common/html/util/dom';

/**
 * Data about a Microsoft list item.
 */
interface MicrosoftListItemData {
  /** The id of the list the item belongs to. */
  id: string;
  /** The indentation level of the list item. */
  indent: number;
  /** The list insertion order in the document. */
  order: string;
}

/**
 * Data about a Microsoft list type.
 */
interface MicrosoftListTypeData {
  /** The start index for an ordered list. */
  startIndex: number;
  /** The CSS list-style-type for the list. */
  style: string;
  /** The type of list. Either 'ul' or 'ol'. */
  type: 'ul' | 'ol';
}

/**
 * A class for normalizing Microsoft lists into HTML lists.
 */
export class MsListNormalizer {
  /** The CSS stylesheet that belongs to the document. This contains information about the lists. */
  private css: string;
  /** The indentation level of the current list. */
  private currentIndentation: number;
  /** The current list that items are being added to. */
  private currentList: HTMLElement;
  /** The document being normalized. */
  private doc: Document;

  /**
   * Modifies a document converting Microsoft lists into HTML lists.
   * @param doc The document to modify.
   * @param css The CSS stylesheet for the document.
   */
  normalize(doc: Document, css: string) {
    this.doc = doc;
    this.css = css;
    this.currentIndentation = 1;
    this.currentList = null;

    this.descendNode(doc);
  }

  /**
   * Loops down through the DOM tree and transforms Microsoft list items into HTML lists.
   * @param node The node to descend.
   */
  private descendNode(node: Node) {
    let previousListItemData: MicrosoftListItemData;

    for (let i = 0; i < node.childNodes.length; i += 1) {
      const element = node.childNodes[i] as HTMLElement;

      if (this.isMicrosoftListItem(element)) {
        const listData = this.getMicrosoftListData(element);

        const isDifferentList = this.doesItemBelongToDifferentList(element, listData, previousListItemData);
        if (isDifferentList) {
          previousListItemData = null;
          this.currentList = null;
          this.currentIndentation = 1;
        }

        const indentationDifference = this.getIndentationDifference(listData, previousListItemData);

        if (!this.currentList || indentationDifference !== 0) {
          const listTypeData = this.getMicrosoftListType(element, listData);

          if (!this.currentList) {
            this.currentList = this.createList(listTypeData);
            element.after(this.currentList);
          } else if (listData.indent > this.currentIndentation) {
            const lastListItem = this.currentList.lastChild.lastChild;
            this.currentList = this.createList(listTypeData);
            lastListItem.after(this.currentList);
            this.currentIndentation += 1;
          } else if (listData.indent < this.currentIndentation) {
            const differentIndentation = this.currentIndentation - listData.indent;
            this.currentList = this.findListAtIndentation(this.currentList, differentIndentation);
            this.currentIndentation = listData.indent;
          }

          if (listData.indent <= this.currentIndentation) {
            if (this.currentList.nodeName.toLowerCase() !== listTypeData.type) {
              const newList = cloneElementWithNewName(this.currentList, listTypeData.type);
              this.currentList.replaceWith(newList);
              this.currentList = newList;
            }
          }
        }

        this.currentList.appendChild(this.transformMicrosoftListItemToHtmlListItem(element));

        previousListItemData = listData;
        i -= 1; // We just removed the element so we need to decrement the index
      } else {
        this.descendNode(element);
      }
    }
  }

  /**
   * Creates an HTML list element from a Microsoft list type.
   * @param listTypeData The list type data to build the list from.
   * @returns The HTML list element (either an ol or ul).
   */
  private createList(listTypeData: MicrosoftListTypeData): HTMLOListElement | HTMLUListElement {
    const list = this.doc.createElement(listTypeData.type);
    // 'disc' is the default value for a list so leave it off so we don't add unnecessary styles
    if (listTypeData.style && listTypeData.style !== 'disc') {
      list.style['list-style-type'] = listTypeData.style;
    }
    if (typeof listTypeData.startIndex === 'number') {
      list.setAttribute('start', listTypeData.startIndex.toString());
    }
    return list;
  }

  /**
   * Creates an HTML list item from a Microsoft list item.
   * Removes the bullet markers and the Microsoft list item element from the DOM.
   * @param element The Microsoft list item element.
   * @returns The HTML list item element.
   */
  private transformMicrosoftListItemToHtmlListItem(element: HTMLElement): HTMLLIElement {
    const listItem = cloneElementWithNewName(element, 'li') as HTMLLIElement;

    // Remove the unwanted styles and classes because its just extra Microsoft stuff that is no longer needed
    removeStyles(listItem, name => name === 'text-indent' || name === 'margin-left' || name === 'mso-list' || name === 'mso-text-indent-alt');
    removeClasses(listItem, name => name.startsWith('MsoList'))

    // Remove bullet markers
    this.removeBulletMarkers(listItem, listItem);

    // Remove the element now that it is a list item
    element.remove();

    return listItem;
  }

  /**
   * Removes bullet markers from the list item.
   * Finds the bullet marker and removes it and all of its parents up to and not including the list item.
   * @param element The element to search for bullet markers.
   * @param listItem The list item having its bullet markers removed.
   */
  private removeBulletMarkers(element: Node, listItem: HTMLLIElement) {
    for (let i = element.childNodes.length - 1; i >= 0; i -= 1) {
      const childNode = element.childNodes[i];

      // A bullet marker is a span with the style 'mso-list:Ignore'
      if (childNode.nodeName.toLowerCase() === 'span' && hasAttributeStyle(childNode as Element, 'mso-list', 'Ignore')) {
        // Find the furthest parent of the bullet marker that is within the list item
        let parentNode: Node = childNode;
        while (parentNode) {
          if (parentNode.parentNode === listItem) {
            (parentNode as Element).remove();
          } else {
            parentNode = parentNode.parentNode;
          }
        }
      } else {
        this.removeBulletMarkers(childNode, listItem);
      }
    }
  }

  /**
   * Determines if the given list item belongs to a different list than the previous list item.
   * @param element The Microsoft list item element.
   * @param listItemData The list data for the Microsoft list item.
   * @param previousListItemData The list data for the previous Microsoft list item.
   * @returns Whether the list item belongs to a different list than the previous list item.
   */
  private doesItemBelongToDifferentList(element: HTMLElement, listItemData: MicrosoftListItemData, previousListItemData: MicrosoftListItemData): boolean {
    if (!previousListItemData) {
      return true;
    }

    // If the ids are different then its likely the items belong to different lists
    if (previousListItemData.id !== listItemData.id) {
      if (listItemData.indent - previousListItemData.indent === 1) {
        return false;
      } else {
        return true;
      }
    }

    const previousSibling = element.previousElementSibling;
    if (!previousSibling) {
      return true;
    }

    return previousSibling.nodeName.toLowerCase() !== 'ol' && previousSibling.nodeName.toLowerCase() !== 'ul';
  }

  /**
   * Calculates the indentation difference between two given list items (based on the indent attribute extracted from the `mso-list` style).
   * @param listItemData The list item data to calculate the indentation difference for.
   * @param previousListItemData The previous list item data to calculate the indentation difference for.
   * @returns The indentation difference between the two list items.
   */
  private getIndentationDifference(listItemData: MicrosoftListItemData, previousListItemData: MicrosoftListItemData): number {
    return previousListItemData ? listItemData.indent - previousListItemData.indent : listItemData.indent - 1;
  }

  /**
   * Finds the parent list element (ul/ol) of a given list element with indentation level lower by a given value.
   * @param listElement List element from which to start looking for a parent list.
   * @param indentationDifference Indentation difference between lists.
   * @returns Found list element with indentation level lower by a given value.
   */
  private findListAtIndentation(list: HTMLElement, indentationDifference: number): HTMLElement {
    let indentChange = 0;
    let element = list;

    while (element.parentNode) {
      element = element.parentNode as HTMLElement;

      if (element.nodeName?.toLowerCase() === 'ol' || element.nodeName?.toLowerCase() === 'ul') {
        indentChange += 1;
      }

      if (indentChange === indentationDifference) {
        return element;
      }
    }
  }

  /**
   * Extracts list item data from the provided element.
   * @param element The element to extract list item data from.
   * @returns The list item data.
   */
  private getMicrosoftListData(element: Element): MicrosoftListItemData {
    // The list item data is in a style with the format: style="mso-list: l1 level1 lfo1;"
    const listStyle = getAttributeStyle(element, 'mso-list')?.value;

    if (listStyle) {
      const idMatch = listStyle.match(/(^|\s{1,100})l(\d+)/i);
      const indentMatch = listStyle.match(/\s{0,100}level(\d+)/i);
      const orderMatch = listStyle.match(/\s{0,100}lfo(\d+)/i);

      if (idMatch && orderMatch && indentMatch) {
        return {
          id: idMatch[2],
          indent: parseInt(indentMatch[1]),
          order: orderMatch[1]
        };
      }
    }
  }

  /**
   * Checks if the given node is a Microsoft list item.
   * @param node The node to check.
   * @returns Whether the node is a Microsoft list item.
   */
  private isMicrosoftListItem(node: Node): boolean {
    const nodeNameLowerCase = node.nodeName.toLowerCase();
    return (
      nodeNameLowerCase === 'p' ||
      nodeNameLowerCase === 'h1' ||
      nodeNameLowerCase === 'h2' ||
      nodeNameLowerCase === 'h3' ||
      nodeNameLowerCase === 'h4' ||
      nodeNameLowerCase === 'h5' ||
      nodeNameLowerCase === 'h6'
    ) && hasAttributeStyle(node as Element, 'mso-list');
  }

  /**
   * Extracts list type data for a Microsoft list item from the provided CSS.
   *
   * The data is stored in the CSS in the following format:
   * ```css
   * @list l1:level1 {
   *   mso-list: l1 level1 lfo1;
   * }
   * ```
   *
   * @param node The Microsoft list item to extract list type data for.
   * @param listItemData The list item data.
   * @param css The CSS stylesheet.
   * @returns The list type data.
   */
  private getMicrosoftListType(node: Node, listItemData: MicrosoftListItemData): MicrosoftListTypeData {
    let listStyle = 'decimal'; // MS default
    let listType: 'ul' | 'ol' = 'ol'; // MS default
    let listStartIndex: number;

    // Regex to search for the list style definition in the Word CSS stylesheet
    const listStyleRegexp = new RegExp(`@list l${listItemData.id}:level${listItemData.indent}\\s*({[^}]*)`, 'gi');
    // Regex to pull the values from the list style definition
    const listStyleTypeRegex = /mso-level-number-format:([^;]{0,100});/gi;
    const listStartIndexRegex = /mso-level-start-at:\s{0,100}([0-9]{0,10})\s{0,100};/gi;

    const listStyleMatch = listStyleRegexp.exec(this.css);

    // If the list style definition was found in the Word CSS stylesheet
    if (listStyleMatch?.[1]) {
      const listStyleTypeMatch = listStyleTypeRegex.exec(listStyleMatch[1]);

      // If the list style type was found in the Word CSS stylesheet
      if (listStyleTypeMatch?.[1]) {
        listStyle = listStyleTypeMatch[1].trim();
        listType = listStyle !== 'bullet' && listStyle !== 'image' ? 'ol' : 'ul';
      }

      // Styles for the numbered lists are always defined in the CSS.
      // However, unordered lists only sometimes contain a value for `mso-level-text` in the CSS.
      // So instead, grab the list style value from the list style marker element because it is always present.
      if (listStyle === 'bullet') {
        const bulletedStyle = this.findMicrosoftBulletedListStyle(node);
        if (bulletedStyle) {
          listStyle = bulletedStyle;
        }
      } else {
        const listStartIndexMatch = listStartIndexRegex.exec(listStyleMatch[1]);

        // If the list start index was found in the Word CSS stylesheet
        if (listStartIndexMatch?.[1]) {
          listStartIndex = parseInt(listStartIndexMatch[1], 10);
        }
      }
    }

    return {
      startIndex: listStartIndex,
      style: this.convertMicrosoftListStyleToHtmlListStyle(listStyle),
      type: listType
    };
  }

  /**
   * Converts a Microsoft list style to a CSS list-style-type value.
   * @param listStyle The Microsoft list style.
   * @returns The CSS list-style-type value.
   */
  private convertMicrosoftListStyleToHtmlListStyle(listStyle: string): string {
    if (listStyle.startsWith('arabic-leading-zero')) {
      return 'decimal-leading-zero';
    }

    switch (listStyle) {
      case 'alpha-lower':
        return 'lower-alpha';
      case 'alpha-upper':
        return 'upper-alpha';
      case 'roman-lower':
        return 'lower-roman';
      case 'roman-upper':
        return 'upper-roman';
      case 'circle':
      case 'disc':
      case 'square':
        return listStyle;
    }
  }

  /**
   * Finds the list-style marker for a Microsoft list item and returns its style as a CSS list-style-type value.
   * @param node The Microsoft list item to search.
   * @returns The CSS list-style-type value.
   */
  private findMicrosoftBulletedListStyle(node: Node): string {
    const listMarkerNode = this.findMicrosoftListMarkerNode(node);

    if (listMarkerNode) {
      switch (listMarkerNode.nodeValue) {
        case 'o':
          return 'circle';
        case '·':
          return 'disc';
        case '§': // S is for square? Ask Microsoft.
          return 'square';
      }
    }
  }

  /**
   * Finds the list-style marker node inside of a Microsoft list item.
   * @param node The Microsoft list item to search.
   * @returns The text node containing the list-style marker.
   */
  private findMicrosoftListMarkerNode(node: Node): Text {
    for (let i = 0; i < node.childNodes.length; i += 1) {
      const childNode = node.childNodes[i];
      // The list-style marker is inside a <span>
      if (childNode.nodeType !== NodeType.ELEMENT_NODE || childNode.nodeName.toLowerCase() !== 'span') {
        continue;
      }

      const firstChild = childNode.firstChild
      if (!firstChild) {
        continue;
      }

      // If this is a text node then it is the list-style marker
      if (firstChild.nodeType === NodeType.TEXT_NODE) {
        return firstChild as Text;
      } else {
        // Else the list-style marker is inside the next first child
        return firstChild.firstChild as Text;
      }
    }
  }
}
