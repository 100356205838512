import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileAvatarTooltipClassPipe } from './review-file-avatar-tooltip-class.pipe';

@NgModule({
  declarations: [ReviewFileAvatarTooltipClassPipe],
  imports: [CommonModule],
  exports: [ReviewFileAvatarTooltipClassPipe]
})
export class ReviewFileAvatarTooltipClassModule { }
