import { descendants } from '@common/html/util/dom';
import { voidTags } from '@common/html/util/xml';

/**
 * Normalizes empty nodes in HTML by removing them.
 */
export class HtmlEmptyNodeNormalizer {
  /**
   * Normalizes the HTML.
   * @param doc The HTML doc to normalize.
   * @param whitelist The whitelist of tags to keep as CSS selectors. Any node that matches a selector in the list will not be removed.
   * @param whiteListMatcher A function to determine if an empty node should be kept. Return true to keep an empty node when it is empty on paste.
   */
  normalize(doc: Document, whitelist?: string[], whiteListMatcher?: (node: Element) => boolean): void {
    const whitelistSelector = whitelist?.join(',');

    // Do a reverse, depth first descent into the DOM removing empty nodes
    descendants(doc.documentElement, (node: Element) => {
      // Remove empty nodes (that aren't void tags)
      if (node.childNodes.length === 0 && node.textContent === '' && !voidTags.includes(node.nodeName.toLowerCase()) && !node?.matches(whitelistSelector) && !whiteListMatcher?.(node)) {
        node.parentNode?.removeChild(node);
      }
    }, {
      depthFirst: true,
      reverse: true
    })
  }
}
