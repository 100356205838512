import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';

@Component({
  selector: 'mc-solo-file-text-editor-status-bar',
  templateUrl: './solo-file-text-editor-status-bar.component.html',
  styleUrls: ['./solo-file-text-editor-status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoloFileTextEditorStatusBarComponent {
  @Input() exceedsMaxSize: boolean;
  @Input() size: number;

  readonly maxSize: number;

  constructor(private textEditorService: TextEditorService) {
    this.maxSize = this.textEditorService.flareTextEditorDocMaxCharLength;
  }
}
