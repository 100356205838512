/** List basic colors */
export const BASIC_COLORS = ["#ffffff", "#ffff00", "#ff00ff", "#ff0000",
  "#c0c0c0", "#808080", "#808000", "#800080",
  "#800000", "#00ffff", "#00ff00", "#008080",
  "#008000", "#0000ff", "#000080", "#000000"
];

/**
 * Get color at position
 * @param ctx
 * @param x
 * @param y
 */
export function getColorAtPosition(ctx: CanvasRenderingContext2D, x: number, y: number): { r: number, g: number, b: number } {
  const imageData: Uint8ClampedArray = ctx.getImageData(x, y, 1, 1).data;
  return { r: imageData[0], g: imageData[1], b: imageData[2] };
}

export function createMissingDateImplError(provider: string) {
  return Error(
    `NgxMatColorPicker: No provider found for ${provider}. You must define MAT_COLOR_FORMATS in your module`);
}
