import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsApiService } from '@portal-core/task-boards/services/task-boards-api.service';
import { TaskBoardsDataService } from '@portal-core/task-boards/services/task-boards-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TaskBoardsService extends CollectionServiceBase<TaskBoard> {
  constructor(
    private taskBoardsApiService: TaskBoardsApiService,
    private taskBoardsDataService: TaskBoardsDataService,
    protected dataService: DataService) {
    super(taskBoardsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<TaskBoard> {
    return this.taskBoardsApiService.getTaskBoardById$(itemId);
  }

  deleteTaskBoards$(taskBoardIds: number[]): Observable<MadCloudResult> {
    return this.taskBoardsApiService.deleteTaskBoards$(taskBoardIds).pipe(
      tap(() => this.taskBoardsDataService.removeItems$(taskBoardIds))
    );
  }

  createTaskBoard$(taskBoard: TaskBoard): Observable<TaskBoard> {
    return this.taskBoardsApiService.createTaskBoard$(taskBoard).pipe(
      tap(result => {
        if (result !== undefined) {
          this.taskBoardsDataService.addItems$([result]);
        }
      })
    );
  }

  updateTaskBoard$(taskBoard: TaskBoard): Observable<MadCloudResult> {
    return this.taskBoardsApiService.updateTaskBoard$(taskBoard).pipe(
      tap(result => {
        if (result !== undefined && result.Succeeded) {
          this.taskBoardsDataService.updateItems$([taskBoard]);
        }
      })
    );
  }

  getTaskBoards$(licenseId: number, options: GetDataOptions = null): Observable<TaskBoard[]> {
    return this.dataService.getDataListItems$<TaskBoard>('TaskBoards', licenseId, this.taskBoardsDataService, {
      fetch: () => this.taskBoardsApiService.getTaskBoardListItemsByLicenseId$(licenseId).pipe(
        map(taskBoards => {
          if (Array.isArray(taskBoards)) {
            return taskBoards.sort((projectA, projectB) => projectA.Title.localeCompare(projectB.Title));
          }
        })
      )
    }, options);
  }

  getTaskBoardsPageByLicenseId$(licenseId: number, filter?: PageFilter): Observable<Page<TaskBoard>> {
    return this.taskBoardsApiService.getTaskBoardsPageByLicenseId$(licenseId, filter);
  }
}
