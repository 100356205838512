import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AuthModuleConfig } from '@portal-core/auth/models/auth-module-config.model';
import { AuthModuleConfigService } from '@portal-core/auth/services/auth-module-config.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { AuthState } from '@portal-core/auth/services/auth-data.service';


@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([AuthState])
  ]
})
export class AuthServicesModule {
  static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthServicesModule> {
    return {
      ngModule: AuthServicesModule,
      providers: [
        AuthService, // Inject here so that the constructor for AuthService runs early. This gives the AuthService time to create the auth effects.
        {
          provide: AuthModuleConfigService,
          useValue: config
        }
      ]
    };
  }
}
