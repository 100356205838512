import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewPackageColorClassPipe } from './review-package-color-class.pipe';

@NgModule({
  declarations: [ReviewPackageColorClassPipe],
  imports: [CommonModule],
  exports: [ReviewPackageColorClassPipe],
  providers: [ReviewPackageColorClassPipe]
})
export class ReviewPackageColorClassModule { }
