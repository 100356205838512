import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { CollabFileType } from '@common/collab/enums/collab-file-type.enum';
import { repeatWhileNull } from '@common/util/repeat-while-null.operator';
import { BranchesService } from '@portal-core/branches/services/branches.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { MetaDataService } from '@portal-core/project-files/services/meta-data.service';
import { ToggledVariableSetViewInfo, VariableSetsListComponent } from '@portal-core/project-files/variables/components/variable-sets-list/variable-sets-list.component';
import { VariablesGridComponent } from '@portal-core/project-files/variables/components/variables-grid/variables-grid.component';
import { ProjectVariableDefinitionView } from '@portal-core/project-files/variables/models/project-variable-definition-view.model';
import { ProjectVariableSetView } from '@portal-core/project-files/variables/models/project-variable-set-view.model';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { CustomInputBase } from '@portal-core/ui/forms/util/custom-input-base.directive';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subject, Subscription, map, of, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'mc-variable-picker-input',
  templateUrl: './variable-picker-input.component.html',
  styleUrls: ['./variable-picker-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.mc-variable-picker-input-disabled]': 'disabled',
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['disabled', 'tabIndex'],
  providers: [
    { provide: MatFormFieldControl, useExisting: VariablePickerInputComponent }
  ]
})

export class VariablePickerInputComponent extends CustomInputBase<ProjectVariableSetView[]> implements OnInit, OnDestroy {
  @Input() branchId: string[];
  @Input() projectId: number;
  @Input() hiddenSystemVariables: string[];
  @Input() setName?: string;
  @Input() variableName?: string;
  @Input() variableSets$?: Observable<ProjectVariableSet[]>;
  @Input() multiple: boolean = false;

  @InputObservable('branchId') branchId$: Observable<string>;

  @Output() variableSelected: EventEmitter<ProjectVariableDefinitionView> = new EventEmitter<ProjectVariableDefinitionView>();

  @ViewChild('variablesGrid', { static: false }) variablesGrid: VariablesGridComponent;
  @ViewChild('variableSetsList', { static: false }) variableSetsList: VariableSetsListComponent;
  projectVariableSetViews: ProjectVariableSetView[];
  activeVariableSetView: ProjectVariableSetView;

  variablesSetsSubscription: Subscription;
  loadingState: LoadingState<string> = new LoadingState<string>();
  projectVariableSetViews$: Observable<ProjectVariableSetView[]>;

  private unsubscribe = new Subject<void>();

  /** Whether the variable set picker has no value. Required by MatFormFieldControl. */
  get empty(): boolean {
    for (let i = 0; i < this.value.length; i++) {
      if (this.value[i].TotalSelected) {
        return false;
      }
    }
    return true;
  }

  /** Required by CustomInputBase */
  readonly controlType: string = 'mc-variable-picker-input';

  /** Required by MatFormFieldControl */
  get shouldLabelFloat(): boolean {
    return false;
  }

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor,
    private errorService: ErrorService,
    private metaDataService: MetaDataService,
    private branchesService: BranchesService
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, cdr, focusMonitor);
  }

  ngOnInit() {
    this.setProjectVariableSets$();
  }

  /**
 * Filters out the variables from the variable sets.
 * @param variableSets The variable sets to filter.
 * @param hiddenVariables The list of names to filter out.
 * @returns The filtered variable sets.
 */
  private hideVariables(variableSets: ProjectVariableSetView[], hiddenVariables: string[]): ProjectVariableSetView[] {
    return variableSets.map(({ Definitions, ...rest }) => {
      return {
        ...rest,
        Definitions: Definitions.filter(d => !hiddenVariables.includes(d.Data.LongName))
      };
    });
  }

  ngOnDestroy() {
    // release all subscriptions
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** Required by CustomInputBase */
  getDefaultPlaceholder(): string {
    return 'Variable';
  }

  /** Required by CustomInputBase */
  getFocusableElementRef(): ElementRef<HTMLElement> {
    return null;
  }

  /** Required by MatFormFieldControl */
  onContainerClick(event: MouseEvent) {
  }

  onVariableSetSelected(variableSetView: ProjectVariableSetView) {
    this.activeVariableSetView = variableSetView;
  }

  setAllVariablesInSet(toggledVariableSetInfo: ToggledVariableSetViewInfo) {
    const variableSet = toggledVariableSetInfo.variableSetView;

    // if you change it on the current set you are in has to be inside of the grid
    if (this.activeVariableSetView.Name === variableSet.Name) {
      this.variablesGrid.setAllForCurrentSet(toggledVariableSetInfo.checked);
    } else {
      // change the data
      variableSet.TotalSelected = toggledVariableSetInfo.checked ? variableSet.Definitions.length : 0;
      variableSet.Definitions.forEach(definition => definition.Checked = toggledVariableSetInfo.checked);
    }

    // set the new values
    this.value = this.projectVariableSetViews;
    this.onChange(this.value);
    this.onTouched();
  }


  variableSelect(selectedVariable: ProjectVariableDefinitionView) {
    this.variableSelected.emit(selectedVariable);
  }

  setOneVariableInSet(variableDefinitionView: ProjectVariableDefinitionView) {

    const variableSet = this.projectVariableSetViews.find(variableSet => variableSet.Name === variableDefinitionView.Data.SetName);

    //change the data
    variableDefinitionView.Checked = !variableDefinitionView.Checked;

    // gotta set the data in the set list component or it wont update the checkboxes.
    this.variableSetsList.incrementOrDecrementTotalSelected(variableSet, variableDefinitionView.Checked);

    // set the new values
    this.value = this.projectVariableSetViews;
    this.onChange(this.value);
    this.onTouched();
  }

  setProjectVariableSets$() {

    this.projectVariableSetViews$ = this.branchId$.pipe(
      switchMap(branchId => {
        const branch = this.branchesService.getItemById(branchId?.[0]);
        const commitId = branch?.Commit.id;

        if (commitId || this.variableSets$) {
          const variableSets$ = this.variableSets$ ? this.variableSets$ : this.metaDataService.getProjectVariableSets$({ commitId, projectId: this.projectId, fileType: CollabFileType.Edit, fileId: undefined })
          return variableSets$.pipe(
            map(projectVariableSets => {
              if (projectVariableSets) {
                const projectVariableSetViews = [];

                projectVariableSets.forEach(variableSet => {
                  const definitionsView = [];
                  const variableSetView = {
                    Name: variableSet.Name,
                    TotalSelected: 0,
                    Definitions: null
                  }

                  variableSet.Definitions.forEach(definition => {
                    definitionsView.push(
                      {
                        Data: definition,
                        Checked: false
                      })
                  });

                  variableSetView.Definitions = definitionsView;
                  projectVariableSetViews.push(variableSetView);
                });

                return projectVariableSetViews;
              }
              return null;
            }),
            takeUntil(this.unsubscribe),
            repeatWhileNull({ retryInterval: 2000 }));
        } else {
          return of([]);
        }
      }));

    this.loadingState.update(true);

    this.variablesSetsSubscription = this.projectVariableSetViews$.subscribe(variableSetViews => {
      this.projectVariableSetViews = variableSetViews;
      if (this.projectVariableSetViews) {
        if (this.hiddenSystemVariables?.length > 0) {
          this.projectVariableSetViews = this.hideVariables(this.projectVariableSetViews, this.hiddenSystemVariables);
        }

        this.projectVariableSetViews.sort((a, b) => a.Name.localeCompare(b.Name));
        this.loadingState.update(false);
        this.cdr.markForCheck();
      } else {
        this.loadingState.update(false, 'The variable data for the project is still being generated. Please try again soon.');
      }

    }, error => {
      this.loadingState.update(false, 'Unable to load the project\'s variables.', this.errorService.getErrorMessages(error));
    });
  }

  onRetryClicked() {
    this.setProjectVariableSets$();
  }

}

