import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { Build } from '@portal-core/builds/models/build.model';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'mc-build-overview',
  templateUrl: './build-overview.component.html',
  styleUrls: ['./build-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildOverviewComponent implements OnInit {
  @Input() build: Build;

  @PropertyObservable('build') build$: Observable<Build>;

  creatorLicenseUser$: Observable<LicenseUser>;
  project$: Observable<Project>;
  sites$: Observable<Site[]>;

  constructor(private licenseUsersService: LicenseUsersService, private projectsService: ProjectsService, private sitesService: SitesService) { }

  ngOnInit() {
    // Create an observable of the build's creator
    this.creatorLicenseUser$ = this.build$.pipe(
      switchMap(build => build && build.CreatedByLicenseUser ? this.licenseUsersService.getItemById$(build.CreatedByLicenseUser.Id) : of(null))
    );

    // Create an observable of the build's project
    this.project$ = this.build$.pipe(
      switchMap(build => build && typeof build.ProjectId === 'number' ? this.projectsService.getItemById$(build.ProjectId) : of(null))
    );

    // Create an observable for the build's sites
    this.sites$ = this.build$.pipe(
      switchMap(build => build && typeof build.Id === 'number' ? this.sitesService.getSitesListByBuildId$(build.Id, { allowApiRequest: false }) : of(null))
    );
  }
}
