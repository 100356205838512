import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { OutputAnalyticsReportItem } from '@portal-core/output-analytics/models/output-analytics-report-item.model';

@CollectionStateSelectors({
  stateType: OutputAnalyticsReportItemState
})
@State<OutputAnalyticsReportItemState>({
  name: OutputAnalyticsReportItemState.source,
})
@Injectable()
export class OutputAnalyticsReportItemState extends CollectionStateBase {
  static source = 'OutputAnalyticsReportItems';
  getSource(): string {
    return OutputAnalyticsReportItemState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OutputAnalyticsReportItemDataService extends CollectionDataServiceBase<OutputAnalyticsReportItem> {
  constructor(protected store: Store) {
    super(store, OutputAnalyticsReportItemState);
  }
}
