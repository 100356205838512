import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ConversationsState } from '@portal-core/messages/services/conversations-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ConversationsState])
  ]
})
export class ConversationsServicesModule { }
