import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ElementRef, Renderer2 } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';

@Component({
  selector: 'mc-switch-board',
  templateUrl: './switch-board.component.html',
  styleUrls: ['./switch-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    class: 'mc-switch-board mc-spring-board mc-horizontal-spring-board'
  }
})
export class SwitchBoardComponent {
  /** The theme color of the switch-board. */
  @Input()
  get color(): ThemePalette {
    return this._color;
  }
  set color(color: ThemePalette) {
    if (this._color) {
      this.renderer.removeClass(this.element.nativeElement, `mat-${this._color}`);
    }

    this._color = color;

    if (this._color) {
      this.renderer.addClass(this.element.nativeElement, `mat-${this._color}`);
    }
  }
  private _color: ThemePalette;

  constructor(private element: ElementRef, private renderer: Renderer2) { }
}
