import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TaskBoardsState } from '@portal-core/task-boards/services/task-boards-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaskBoardsState])
  ]
})
export class TaskBoardsServicesModule { }
