import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasVisitedDirective } from './has-visited.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HasVisitedDirective
  ],
  exports: [
    HasVisitedDirective
  ]
})
export class HasVisitedModule { }
