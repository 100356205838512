import { Pipe, PipeTransform } from '@angular/core';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcBuildAsync'
})
export class BuildAsyncPipe implements PipeTransform {
  constructor(private buildsService: BuildsService) { }

  transform(buildId: number | null | undefined, allowApiRequest: boolean = false): Observable<Build | null> {
    if (!buildId) {
      return of(null);
    }

    return this.buildsService.getItemById$(buildId, {
      allowApiRequest
    });
  }
}
