<mc-data-grid #reviewsGrid [columnInputs]="{ ProjectId: { licenseId: licenseId } }" [gridControl]="gridControl" [gridId]="gridId" [defaultVisibleColumns]="defaultVisibleColumns" [selectable]="selectable">
  <ng-template mcGridCell="Name" let-data>
    <mc-review-file-avatar [nameLinksToPage]="true" [reviewFile]="data" size="small" [truncate]="true"></mc-review-file-avatar>
  </ng-template>

  <ng-template mcGridCell="Path" let-data>
    <mc-review-file-avatar [disabled]="true" [reviewFile]="data" [showAvatar]="false" [showPath]="true"></mc-review-file-avatar>
  </ng-template>

  <ng-template mcGridCell="AuthorUser.FullName" let-data>
    <mc-license-user-avatar [truncate]="true" [licenseUser]="data.AuthorUserId | mcLicenseUserForLicenseAsync : licenseId | async" size="small"></mc-license-user-avatar>
  </ng-template>

  <ng-template mcGridCell="ProjectId" let-data>
    <mc-project-avatar *ngIf="data.ProjectId" [project]="data.ProjectId | mcProjectAsync | async" [showName]="false" size="small"></mc-project-avatar>
  </ng-template>

  <ng-template mcGridCell="BranchName" let-data>
    <mc-branch-avatar [branchName]="data.BranchName" [project]="data.ProjectId | mcProjectAsync | async" size="small" [truncate]="true"></mc-branch-avatar>
  </ng-template>

  <ng-template mcGridCell="CommitId" let-data>
    <mc-commit-avatar [commit]="data.CommitId" [showAvatar]="false" size="small" [truncate]="true"></mc-commit-avatar>
  </ng-template>

  <ng-template mcGridCell="ReviewPackage.Name" let-data>
    <mc-review-package-avatar *ngIf="data" [reviewPackage]="data.ReviewPackageId | mcReviewPackageAsync | async" size="small" [truncate]="true"></mc-review-package-avatar>
  </ng-template>

  <ng-template mcGridCell="Status" let-data>
    {{ data.Status | mcReviewFileStatusText }}
  </ng-template>

  <ng-template mcGridCell="Reviewers" let-data>
    <mc-avatars-list [avatars]="data.Reviewers" [maxAvatars]="reviewersListLimit - 1" (viewAvatars)="openReviewFileProfileDialog(data, ReviewFileProfileTab.Reviewers)" tooltip="{{ data.TotalReviewers }} total reviewers">
      <ng-template mcListItem let-item>
        <ng-container *ngIf="item.UserId | mcLicenseUserForLicenseAsync: licenseId | async as licenseUser">
          <mc-license-user-avatar class="mc-avatar-list-item" [licenseUser]="licenseUser" [showName]="false" size="small" [tooltip]="item.Status | mcReviewFileUserStatusLicenseUserTooltip : licenseUser.Status : licenseUser.IsDeleted : licenseUser.SeatType : licenseUser.User.FullName" [tooltipClass]="item.Status | mcReviewFileUserStatusLicenseUserTooltipClass">
            <ng-template mcAvatarIndicator>
              <mat-icon class="mc-avatar-indicator-bottom-right mc-avatar-indicator-icon" [color]="item.Status | mcReviewFileUserStatusColor" fontSet="project-titan" [fontIcon]="item.Status | mcReviewFileUserStatusIcon"></mat-icon>
            </ng-template>>
          </mc-license-user-avatar>
        </ng-container>
      </ng-template>
    </mc-avatars-list>
  </ng-template>

  <ng-template mcGridCell="CreatedOn" let-data>
    <div class="mc-review-files-grid-created-on">
      <span>{{ data.CreatedOn | date: 'MMM dd, yyyy' }}</span>
      <span>{{ data.CreatedOn | date: 'h:mm a' }}</span>
    </div>
  </ng-template>

  <ng-template mcGridCell="ReviewersSubmittedOn" let-data>
    <div class="mc-review-files-grid-submitted-on">
      <span>{{ data.ReviewersSubmittedOn | date: 'MMM dd, yyyy' }} </span>
      <span>{{ data.ReviewersSubmittedOn | date: 'h:mm a' }}</span>
    </div>
  </ng-template>

  <ng-template mcGridCell="ReviewersReviewerStatus" let-data>
    <mat-icon class="mc-image-icon" [color]="data.ReviewersReviewerStatus | mcReviewFileUserStatusColor" fontSet="project-titan" [fontIcon]="data.ReviewersReviewerStatus | mcReviewFileUserStatusIcon" [matTooltip]="data.ReviewersReviewerStatus | mcReviewFileUserStatusText"></mat-icon>
  </ng-template>

  <ng-template mcGridCell="UserSubmittedOn" let-data>
    <div class="mc-review-files-grid-submitted-on">
      <span>{{ data.UserSubmittedOn | date: 'MMM dd, yyyy' }} </span>
      <span>{{ data.UserSubmittedOn | date: 'h:mm a' }}</span>
    </div>
  </ng-template>

  <ng-template mcGridCell="UserReviewerStatus" let-data>
    <mat-icon class="mc-image-icon" [color]="data.UserReviewerStatus | mcReviewFileUserStatusColor" fontSet="project-titan" [fontIcon]="data.UserReviewerStatus | mcReviewFileUserStatusIcon" [matTooltip]="data.UserReviewerStatus | mcReviewFileUserStatusText"></mat-icon>
  </ng-template>

  <ng-template *ngIf="showMenu" mcContextMenuItems let-data>
    <!-- <button mat-menu-item class="mat-menu-item" (click)="openReviewFileProfileDialog(data, ReviewFileProfileTab.Activity)">Show Activity</button> -->
    <button mat-menu-item class="mat-menu-item" (click)="openReviewFileProfileDialog(data, ReviewFileProfileTab.Reviewers)">View Reviewers</button>
    <button *ngIf="CentralPermissions.ManageReviews | mcHasPermission : data.ProjectId | async" mat-menu-item class="mat-menu-item" (click)="openReviewFileProfileDialog(data, ReviewFileProfileTab.Settings)">Set Status</button>
  </ng-template>

  <ng-template mcEmptyGrid="unfiltered">
    <p>
      Create a review to see it here.
    </p>
    <p class="mc-review-files-grid-empty-message">
      <a target="_blank" href="https://documentation.madcapsoftware.com/central/Content/Central/Reviews/Review-Workflow.htm">Reviews Documentation</a>
    </p>
  </ng-template>
</mc-data-grid>