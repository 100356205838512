import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { ProjectVariableSetView } from '@portal-core/project-files/variables/models/project-variable-set-view.model';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { Focusable } from '@portal-core/ui/focus/interfaces/focusable';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable } from 'rxjs';


export interface ToggledVariableSetViewInfo {
  checked: boolean;
  variableSetView: ProjectVariableSetView;
}

@Component({
  selector: 'mc-variable-sets-list',
  templateUrl: './variable-sets-list.component.html',
  styleUrls: ['./variable-sets-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MC_FOCUSABLE, useExisting: VariableSetsListComponent }]
})
export class VariableSetsListComponent implements OnInit, Focusable {
  @Input() variableSets?: ProjectVariableSetView[] | ProjectVariableSet[];
  @Input() initialSetName?: string;
  @Input() multiple?: boolean = false;

  @InputObservable('variableSets') variableSets$: Observable<ProjectVariableSetView[] | ProjectVariableSet[]>;

  @Output() variableSetSelect = new EventEmitter<ProjectVariableSetView | ProjectVariableSet>();
  @Output() setAllVariablesInSet = new EventEmitter<ToggledVariableSetViewInfo>();

  TriSelectedState: typeof TriSelectedState = TriSelectedState;

  @ViewChild('matSelectionList', { static: true, read: ElementRef }) matSelectionList: ElementRef<MatSelectionList>;

  selectedVariableSet?: ProjectVariableSetView | ProjectVariableSet;
  selectedVariableSetViews?: ProjectVariableSetView[] | ProjectVariableSet[];

  constructor(private cdr: ChangeDetectorRef) {

  }
  ngOnInit() {
    this.initialSelect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variableSets) {
      this.initialSelect();
    }
  }

  onVariableSetSelected(event: MatSelectionListChange) {
    const [selectedOption] = event.options;
    this.select(selectedOption.value);
  }

  focus() {
    this.matSelectionList.nativeElement.focus();
  }

  private initialSelect() {
    const selectedSet = (this.variableSets as ProjectVariableSet[])?.find(variableSet => variableSet.Name === this.initialSetName) || this.variableSets?.[0];
    if (selectedSet) {
      this.select(selectedSet);
    }
  }

  private select(variableSet: ProjectVariableSetView | ProjectVariableSet) {
    this.selectedVariableSet = variableSet;
    this.variableSetSelect.emit(this.selectedVariableSet);
  }

  variableSetChecked(event: MatCheckboxChange, variableSetView: ProjectVariableSetView) {
    this.setAllVariablesInSet.emit({ checked: event.checked, variableSetView });
  }

  incrementOrDecrementTotalSelected(variableSet: ProjectVariableSetView | ProjectVariableSet, checked: boolean) {
    if (checked && variableSet) {
      variableSet.TotalSelected++;
    } else if (variableSet) {
      variableSet.TotalSelected--;
    }

    this.cdr.markForCheck();
  }
}
