import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
  selector: 'mc-grid-date-range',
  templateUrl: './grid-date-range.component.html',
  styleUrls: ['./grid-date-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridDateRangeComponent {
  /** Theme color to be used for the underlying form controls. */
  @Input() color: ThemePalette;
  @Input() days?: number = 1;

  @Output() daysChange: EventEmitter<number> = new EventEmitter<number>();

  onDaysChanged(event: MatSelectChange) {
    this.daysChange.emit(event.value);
  }
}
