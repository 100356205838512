import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { defaultToFlareXML, fragmentToArray } from '@common/flare/util/prosemirror-flare-xml';
import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { applyChange } from '@common/prosemirror/changeset/tracked-changes';
import { getMark } from '@common/prosemirror/model/mark';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

function transferChangesToChildNode(node: ProseMirrorNode, childNode: ProseMirrorNode, changeAttrs: ChangeNodeAttrs): ProseMirrorNode {
  let existingAttrs: ChangeNodeAttrs;
  const changeMarkType = node.type.schema.marks['madcapchange'];

  // If the child is a text node then wrap it in a MadCap:change node
  if (childNode.type.name === 'text') {
    const changeMark = getMark(childNode, changeMarkType);
    if (changeMark) {
      existingAttrs = {
        changeIds: changeMark.attrs.changeIds,
        changeList: changeMark.attrs.changeList
      };
    }
  } else {
    existingAttrs = {
      changeIds: childNode.attrs.changeIds,
      changeList: childNode.attrs.changeList
    };
  }

  if (Array.isArray(changeAttrs.changeList)) {
    changeAttrs.changeList.forEach(trackedChange => {
      existingAttrs = applyChange(existingAttrs, trackedChange);
    });
  }

  if (existingAttrs) {
    if (childNode.type.name === 'text') {
      childNode = childNode.mark([changeMarkType.create(existingAttrs)]);
    } else {
      childNode = childNode.type.create({ ...node.attrs, ...existingAttrs }, childNode.content, childNode.marks);
    }
  } else {
    childNode = null;
  }

  return childNode;
}

/**
 * McCentralContainerSchemaPlugin
 * The mc-central-container is used to wrap inline content in a block node when that inline content is inside a node that only allows block content.
 * The mc-central-container schema also defines a node that is used as the default auto-inserted block node when running in the server environment.
 */
export class McCentralContainerSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    mcCentralContainer: {
      group: 'block',
      content: 'inline*',
      parseDOM: [
        // Parse <mc-central-container> because it is used when serializing content to the clipboard.
        // This makes it possible to copy and paste plain text within the editor. Otherwise the plain text would get wrapped in a <p> tag on paste.
        {
          tag: 'mc-central-container',
          preserveWhitespace: true
        }, {
          tag: 'div',
          getAttrs(dom: HTMLElement) {
            if (!dom.classList.contains('mc-central-container')) {
              return false;
            }
          },
          preserveWhitespace: true
        }
      ],
      toDOM() { return ['div', { class: 'mc-central-container' }, 0]; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        let xml = '';

        let children: ProseMirrorNode[] = fragmentToArray(node.content);
        const changeIds: string = node.attrs.changeIds;
        const changeList: TrackedChangeList = node.attrs.changeList;

        // If there are changes then move them to the child nodes
        if (changeList?.length > 0) {
          children = children
            // Transfer the changes to each child node
            .map(childNode => transferChangesToChildNode(node, childNode, { changeIds, changeList }))
            // Filter out any children that were removed from the transfer
            .filter(childNode => !!childNode);
        }

        // Render the children
        for (let i = 0; i < children.length; i += 1) {
          const child = children[i];
          xml += (child.type.spec.toFlareXML ?? defaultToFlareXML)(child, options)
        }

        return xml;
      },
      tagName: 'mc-central-container',
      specSkip: true
    }
  };
}
