<form [formGroup]="CSSLengthForm">
  <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
    <mat-label>{{label}}</mat-label>
    <input #textInput matInput type="text" formControlName="measurement" [matAutocomplete]="autoMeasurement">
    <mat-autocomplete #autoMeasurement="matAutocomplete">
      <mat-option *ngFor="let preset of presets" [value]="preset">
        {{ preset }}
      </mat-option>
    </mat-autocomplete>
    <mc-dropdown-arrow matSuffix> </mc-dropdown-arrow>
  </mat-form-field>

  <div class="mc-form-fields-row">
    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Length</mat-label>
      <input matInput type="number" formControlName="length">
    </mat-form-field>

    <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
      <mat-label>Unit</mat-label>
      <input #textInput matInput type="text" formControlName="unit" [matAutocomplete]="autoUnit">
      <mat-autocomplete #autoUnit="matAutocomplete">
        <mat-option *ngFor="let unit of filteredUnits$ | async" [value]="unit">
          {{ unit }}
        </mat-option>
      </mat-autocomplete>
      <mc-dropdown-arrow matSuffix> </mc-dropdown-arrow>
    </mat-form-field>
  </div>
</form>