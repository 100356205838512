import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapGlossaryTermSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapglossaryterm: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        glossterm: {
          default: undefined,
          exportedName: 'glossTerm'
        }
      },
      parseDOM: [{
        tag: 'MadCap\\:glossaryTerm',
        getAttrs(dom: HTMLElement) {
          // For backwards compatibility the attribute name needs to stay all lowercase. But the attribute name on the element itself should be camelCase.
          // So read the attribute from the element with camelCase.
          // And then set it on the ProseMirror node with all lowercase.
          const attrs = getSchemaAttrs(dom, ['glossTerm']);
          return {
            glossterm: attrs['glossTerm']
          };
        },
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-glossary-term' }, 0]; },
      tagName: 'MadCap:glossaryTerm'
    }
  };
}
