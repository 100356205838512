import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { cache } from '@common/util/cache.operator';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { GridHeaderCellComponent } from '@portal-core/ui/grid/components/grid-header-cell/grid-header-cell.component';
import { ContextMenuItemsDirective } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.directive';
import { EmptyGridDirective } from '@portal-core/ui/grid/directives/empty-grid/empty-grid.directive';
import { ExpandedRowDetailDirective } from '@portal-core/ui/grid/directives/expanded-row-detail/expanded-row-detail.directive';
import { GridCellDirective } from '@portal-core/ui/grid/directives/grid-cell/grid-cell.directive';
import { GridHeaderMenuDirective } from '@portal-core/ui/grid/directives/grid-header-menu/grid-header-menu.directive';
import { HelpGridDirective } from '@portal-core/ui/grid/directives/help-grid/help-grid.directive';
import { GridColumnName } from '@portal-core/ui/grid/enums/grid-column-name.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { GridMenuEvent } from '@portal-core/ui/grid/models/grid-menu-event.model';
import { EmptyGridDisplay } from '@portal-core/ui/grid/types/empty-grid-display.type';
import { VisibleColumns } from '@portal-core/ui/grid/types/visible-columns.type';
import { GridControl } from '@portal-core/ui/grid/util/grid-control';
import { Filterable } from '@portal-core/ui/page-filters/types/filterable.type';
import { Breakpoints } from '@portal-core/util/breakpoints';
import { MediaQueryString } from '@portal-core/util/types/media-query-string.type';
import { forEach, get } from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'mc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/** Selectable is an optional data model property which is used to allow hiding the Select checkbox.
 * For example, when several rows have the same Id and form a group a single checkbox should be displayed. */
export class GridComponent<T extends { Id: number | string, Selectable?: boolean }> implements OnInit, AfterContentInit, OnChanges {
  /**
   * The media query at which to switch the grid into a single column card based view.
   * @deprecated
   */
  @Input() cardBreakpoint?: MediaQueryString;
  @Input() columns: GridColumn[];
  /** A dictionary of column names with Dictionary values. Where those dictionary values map to input names used by components in the grid headers.  */
  @Input() columnInputs?: Dictionary<Dictionary<any>>;
  @Input() detailedErrorMessages?: string[];
  @Input() errorMessage?: string;
  /** Whether the grid has expandable rows. */
  @Input() expandableRows?: boolean = false;
  @Input() filterable: Filterable;
  @Input() filters?: Dictionary<PageFilterGroup>;
  @Input() gridControl?: GridControl;
  /** Whether the grid has indeterminate pages. Changes the controls in the grid's paginators. */
  @Input() indeterminatePages?: boolean = false;
  @Input() items: T[];
  @Input() itemCount?: number = 0;
  /** Whether or not expandable rows are rendered only at the point of being expanded. */
  @Input() lazyLoadExpandableRows?: boolean = false;
  @Input() limitedItemCount?: number;
  @Input() loadingData?: boolean = false;
  /** The most recent number of days to limit the grid data to. */
  @Input() lastDays?: number;
  /** The most recent number of top rows to limit the grid data to. */
  @Input() topRows?: number;
  @Input() orderBy?: string;
  @Input() orderDirection?: SortDirection;
  @Input() showPagination?: boolean = true;
  /** Whether or not pagination can be hidden automatically for small data sets, i.e. when items count is less than minimum page size. */
  @Input() hideEmptyPagination?: boolean = false;
  @Input() pageIndex?: number = 0;
  @Input() pageSize?: number = 25;
  @Input() pageSizeOptions?: number[] = [25, 50, 100, 200];
  @Input() paginatorXSmallBreakpoint?: MediaQueryString = Breakpoints.gridXSmallPaginator;
  @Input() paginatorSmallBreakpoint?: MediaQueryString = Breakpoints.gridSmallPaginator;
  /** Provides a method that is called for each row that adds the 'mc-grid-row-disabled' CSS class to the row when the method returns true. */
  @Input() rowDisabledPredicate?: (item: T) => boolean;
  /** Whether the grid scrolls horizontally when its content is wider than the grid. */
  @Input() scrollHorizontally?: boolean = true;
  /** Whether there is a checkbox column in the grid. */
  @Input() selectable?: boolean = false;
  /**
   * Selects the item with the given id.
   * @deprecated Use replaceSelectionById instead.
   */
  @Input() selectedItemId?: number | string;
  /** Whether or not to show the last days dropdown. */
  @Input() showLastDays?: boolean = false;
  /** Whether or not to show the top rows dropdown. */
  @Input() showTopRows?: boolean = false;
  /** Whether the border is shown between rows. */
  @Input() showRowBorder?: boolean = true;
  @Input() visibleColumns?: VisibleColumns = 'all';

  @Output() filtersCleared: EventEmitter<void> = new EventEmitter<void>();
  @Output() headerMenuClosed: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() headerMenuOpened: EventEmitter<GridMenuEvent> = new EventEmitter<GridMenuEvent>();
  @Output() lastDaysChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() topRowsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() retryClicked: EventEmitter<void> = new EventEmitter<void>();
  /** Emits an array of the selected items whenever the selected items changes. */
  @Output() selectedRowsChange: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() sortChanged: EventEmitter<Sort> = new EventEmitter<Sort>();

  @HostBinding('class.mc-grid-fixed-width')
  get _fixedWidth(): boolean {
    return !this.scrollHorizontally;
  }

  @HostBinding('class.mc-grid-striped')
  @Input() stripedRows: boolean = false;

  @ContentChildren(GridHeaderMenuDirective) gridHeaderMenuDirectives: QueryList<GridHeaderMenuDirective>;
  @ContentChildren(GridCellDirective) gridCellDirectives: QueryList<GridCellDirective>;
  @ContentChild(ExpandedRowDetailDirective) expandedRowDetailDirective: ExpandedRowDetailDirective;
  @ContentChild(ContextMenuItemsDirective) contextMenuItemsDirective: ContextMenuItemsDirective;
  @ContentChildren(EmptyGridDirective) emptyGridDirectives: QueryList<EmptyGridDirective>;
  @ContentChild(HelpGridDirective) helpGridDirective: HelpGridDirective;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('gridTableContainer', { static: true }) gridTableContainer: ElementRef<Element>;
  @ViewChildren(GridHeaderCellComponent) gridHeaderCellComponents: QueryList<GridHeaderCellComponent>;

  GridColumnName: typeof GridColumnName = GridColumnName;

  contextMenuItemsTemplate: TemplateRef<any>;
  emptyGridTemplates: Map<EmptyGridDisplay, TemplateRef<any>>;
  expandedRowDetailTemplate: TemplateRef<any>;
  gridCellTemplates: Map<string, TemplateRef<any>>;
  gridHeaderMenuDefaultTemplate: TemplateRef<any>;
  gridHeaderMenuTemplates: Map<string, TemplateRef<any>>;
  helpGridTemplate: TemplateRef<any>;

  allItemsSelected: boolean = false;
  contextMenuPosition: { x: string, y: string } = { x: '0px', y: '0px' };
  expandedRowId: number | string;
  filterCount: number = 0;
  renderedColumnNames$: Observable<string[]>;
  selectedItemCount: number;
  selection: SelectionModel<number | string> = new SelectionModel<number | string>(true, []);
  showHeaderRow$: Observable<boolean>;
  showCardColumn$: Observable<boolean>;
  useXSmallPaginator$: Observable<boolean>;
  useSmallPaginator$: Observable<boolean>;

  @PropertyObservable('cardBreakpoint') cardBreakpoint$: Observable<MediaQueryString>;
  @PropertyObservable('columns') columns$: Observable<GridColumn[]>;
  @PropertyObservable('contextMenuItemsTemplate') contextMenuItemsTemplate$: Observable<TemplateRef<any>>;
  @PropertyObservable('expandableRows') expandableRows$: Observable<boolean>;
  @PropertyObservable('paginatorSmallBreakpoint') paginatorSmallBreakpoint$: Observable<MediaQueryString>;
  @PropertyObservable('paginatorXSmallBreakpoint') paginatorXSmallBreakpoint$: Observable<MediaQueryString>;
  @PropertyObservable('selectable') selectable$: Observable<boolean>;
  @PropertyObservable('visibleColumns') visibleColumns$: Observable<VisibleColumns>;

  /** Whether or not the grid is being filtered or sorted. */
  get isFilteredOrSorted(): boolean {
    return this.filterCount > 0 || !!this.orderBy || !!this.orderDirection;
  }

  /** The currently selected items in the grid. Returns an empty array if no items are selected. */
  get selectedItems(): T[] {
    return this.items ? this.items.filter(item => item && this.selection.selected.includes(item.Id)) : [];
  }

  /** The left scroll position of the grid's scroll area. */
  get leftScrollPosition(): number {
    return this.gridTableContainer?.nativeElement?.scrollLeft ?? 0;
  }
  set leftScrollPosition(pos: number) {
    if (this.gridTableContainer) {
      this.gridTableContainer.nativeElement.scrollLeft = pos;
    }
  }

  /** Whether or not grid contains items less than a minimum possible page size in which case pagination we can hide pagination. */
  get isItemCountLessThanMinPageSize(): boolean {
    const minPageSize = Math.min(this.pageSize, this.pageSizeOptions ? this.pageSizeOptions[0] : 0);
    return this.itemCount > minPageSize;
  }

  constructor(private breakpointObserver: BreakpointObserver) { }

  /**
   * @private
   */
  ngOnInit() {
    // Create the observable for whether the x-small paginator view should be used
    this.useXSmallPaginator$ = this.paginatorXSmallBreakpoint$.pipe(
      switchMap(paginatorXSmallBreakpoint => {
        if (paginatorXSmallBreakpoint) {
          return this.breakpointObserver.observe(paginatorXSmallBreakpoint);
        } else {
          return of(null);
        }
      }),
      map(breakpointState => breakpointState ? breakpointState.matches : false),
      distinctUntilChanged(),
      cache()
    );

    // Create the observable for whether the small paginator view should be used
    this.useSmallPaginator$ = this.paginatorSmallBreakpoint$.pipe(
      switchMap(paginatorSmallBreakpoint => {
        if (paginatorSmallBreakpoint) {
          return this.breakpointObserver.observe(paginatorSmallBreakpoint);
        } else {
          return of(null);
        }
      }),
      map(breakpointState => breakpointState ? breakpointState.matches : false),
      distinctUntilChanged(),
      cache()
    );

    // Create the observable for whether the card column should be shown
    this.showCardColumn$ = this.cardBreakpoint$.pipe(
      switchMap(cardBreakpoint => {
        if (cardBreakpoint) {
          return this.breakpointObserver.observe(cardBreakpoint);
        } else {
          return of(null);
        }
      }),
      map(cardBreakpointState => cardBreakpointState ? cardBreakpointState.matches : false),
      distinctUntilChanged(),
      cache()
    );

    // Create the observable for the columns that should be rendered
    this.renderedColumnNames$ = combineLatest([
      this.showCardColumn$,
      this.selectable$,
      this.expandableRows$,
      this.contextMenuItemsTemplate$,
      this.visibleColumns$,
      this.columns$
    ]).pipe(
      map(([showCardColumn, selectable, expandableRows, contextMenuItemsTemplate, visibleColumns, columns]) => {
        const columnNames: string[] = [];

        if (visibleColumns === 'all' && Array.isArray(columns)) {
          visibleColumns = columns.map(column => column.name);
        }

        if (Array.isArray(visibleColumns)) {
          if (selectable) {
            columnNames.push(GridColumnName.Select);
          }

          if (contextMenuItemsTemplate) {
            columnNames.push(GridColumnName.Menu);
          }

          // Show the card column if its the only item in the visible columns
          if (showCardColumn || (visibleColumns.length === 1 && visibleColumns[0] === GridColumnName.Card)) {
            columnNames.push(GridColumnName.Card);
          } else {
            columnNames.push(...visibleColumns.filter(columnName => columnName !== GridColumnName.Card));
            columnNames.push(GridColumnName.Fill);
          }

          if (expandableRows) {
            columnNames.push(GridColumnName.Expand);
          }
        }

        return columnNames;
      }),
      cache()
    );

    // Create the observable for whether the header row should be shown
    this.showHeaderRow$ = this.renderedColumnNames$.pipe(
      map((renderedColumnNames) => {
        // Show the header if there is at least one column that is not the grid-card, grid-expand, grid-fill, and grid-menu columns
        return Array.isArray(renderedColumnNames) && renderedColumnNames.some(columnName => {
          return columnName !== GridColumnName.Card && columnName !== GridColumnName.Expand && columnName !== GridColumnName.Fill && columnName !== GridColumnName.Menu;
        });
      }),
      distinctUntilChanged(),
      cache()
    );
  }

  /**
   * @private
   */
  ngAfterContentInit() {
    this.gridCellTemplates = new Map<string, TemplateRef<any>>();
    this.gridCellDirectives.forEach(gridCell => {
      this.gridCellTemplates.set(gridCell.templateName, gridCell.templateRef);
    });

    this.gridHeaderMenuTemplates = new Map<string, TemplateRef<any>>();
    this.gridHeaderMenuDirectives.forEach(gridHeaderMenu => {
      if (gridHeaderMenu.templateName) {
        this.gridHeaderMenuTemplates.set(gridHeaderMenu.templateName, gridHeaderMenu.templateRef);
      } else {
        this.gridHeaderMenuDefaultTemplate = gridHeaderMenu.templateRef;
      }
    });

    this.emptyGridTemplates = new Map<EmptyGridDisplay, TemplateRef<any>>();
    this.emptyGridDirectives.forEach(emptyGrid => {
      this.emptyGridTemplates.set(emptyGrid.templateName, emptyGrid.templateRef);
    });

    this.expandedRowDetailTemplate = this.expandedRowDetailDirective && this.expandedRowDetailDirective.templateRef || null;
    this.contextMenuItemsTemplate = this.contextMenuItemsDirective && this.contextMenuItemsDirective.templateRef || null;
    this.helpGridTemplate = this.helpGridDirective && this.helpGridDirective.templateRef || null;
  }

  /**
   * @private
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.updateSelectedItemIds(changes.items.currentValue);
      this.refreshSelectInfo();
    }

    if (changes.selectedItemId) {
      this.replaceSelectionById(this.selectedItemId);
    }

    if (changes.filters) {
      this.filterCount = 0;

      if (this.filters) {
        forEach(this.filters, filter => {
          if (filter) {
            this.filterCount += 1;
          }
        });
      }
    }
  }

  /**
   * Handles the context menu event on the grid.
   * Shows the grid's context menu if a context menu template is defined.
   * @private
   */
  onContextMenu(event: MouseEvent, item: T) {
    if (!this.contextMenuItemsTemplate) {
      return;
    }

    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'row': item };
    this.contextMenu.openMenu();
  }

  /**
   * Emits the headerMenuOpened output when the header menu opens.
   * @private
   */
  onHeaderMenuOpened(event: GridMenuEvent) {
    this.headerMenuOpened.emit(event);
  }

  /**
   * Emits the headerMenuClosed output when the header menu closes.
   * @private
   */
  onHeaderMenuClosed(event: GridMenuEvent) {
    this.headerMenuClosed.emit(event);
  }

  /**
   * Selects all rows if they are not all selected; otherwise clears the selection.
   * @private
   */
  onAllItemsSelectionChanged() {
    if (this.allItemsSelected) {
      this.selection.clear();
    } else {
      this.items.forEach(row => this.selection.select(row.Id));
    }

    this.selectedRowsChange.emit(this.selectedItems);
    this.refreshSelectInfo();
  }

  /**
   * Updates the selection when the row's selection checkbox value changes.
   * @private
   */
  onItemSelectionChanged(row: T) {
    this.selection.toggle(row.Id);
    this.selectedRowsChange.emit(this.selectedItems);
    this.refreshSelectInfo();
  }

  /**
   * Expands the row that was clicked if expandable rows is enabled.
   * If the row was already expanded then the row is collapsed instead.
   * @private
   */
  onBodyRowClicked(row: T) {
    if (this.expandableRows) {
      this.expandedRowId = (this.expandedRowId === row.Id) ? null : row.Id;
    }
  }

  /**
   * Emits the pageChanged output when the current page or page size changes.
   * @private
   */
  onPageChanged(event: PageEvent) {
    this.pageChanged.emit(event);
  }

  /**
   * Emits the sortChanged output when the sort column or order changed.
   * @private
   */
  onSortChanged(event: Sort) {
    this.sortChanged.emit(event);
  }

  /**
   * Emits the filtersCleared output when all the filters are cleared.
   * @private
   */
  onClearAllFiltersClicked() {
    this.filtersCleared.emit();
  }

  /**
   * Emits the lastDaysChange output when the last days value changes.
   * @private
   */
  onLastDaysChanged(days: number) {
    this.lastDaysChange.emit(days);
  }

  /**
   * Emits the topRowsChange output when the top rows value changes.
   * @private
   */
  onTopRowsChanged(rows: number) {
    this.topRowsChange.emit(rows);
  }

  /** Clears the current selection of items. */
  clearSelection() {
    this.selection.clear();
    this.refreshSelectInfo();
    this.selectedRowsChange.emit([]);
  }

  /**
   * Closes the header menu on the grid.
   * @param columnName Optionally provide the column name to only close the menu for that column.
   */
  closeHeaderMenu(columnName?: string) {
    // Loop through each header cell
    this.gridHeaderCellComponents.forEach(gridHeaderCellComponent => {
      // If no column name was specified or this header cell has a matching column name
      if (!columnName || gridHeaderCellComponent.column.name === columnName) {
        // Then close header cell's menu
        gridHeaderCellComponent.closeMenu();
      }
    });
  }

  /**
   * Deselects the given items from the grid.
   * @param items The items to deselect.
   */
  deselectItems(...items: T[]) {
    this.selection.deselect(...items.map(item => item.Id));
    this.refreshSelectInfo();
    this.selectedRowsChange.emit(this.selectedItems);
  }

  /**
   * Clears out the current selection and selects the given items by id.
   * @param itemIds The item ids to select.
   */
  replaceSelectionById(...itemIds: (number | string)[]) {
    this.selection.clear();
    if (itemIds.length > 0 && itemIds[0]) {
      this.selection.select(...itemIds);
      this.refreshSelectInfo();
      this.selectedRowsChange.emit(this.selectedItems);
    }
  }

  /**
   * Prevents re-rendering of the column headers if the column hasn't actually changed.
   * The column's name is used to determine if a column has changed.
   *
   * This was added primarily to fix a bug with the checklists grid which has dynamic columns.
   * When the checklist grid would update the columns the ViewChildren for gridHeaderCellComponents would be updated but lose the dynamic columns for some reason.
   * This prevents the column headers from changing because the column headers no longer replace themselves.
   * @private
   */
  columnTrackBy(index: number, column: GridColumn): string {
    return column.name;
  }

  /**
   * Determines if a row is disabled by calling the rowDisabledPredicate function if it exists.
   * @private
   */
  getDisabled(row: T): boolean {
    return this.rowDisabledPredicate && typeof this.rowDisabledPredicate === 'function' ? this.rowDisabledPredicate(row) : false;
  }

  /**
   * Returns a column's cell template.
   * @private
   */
  getGridCellTemplate(columnName: string, templateName?: string): TemplateRef<any> {
    return this.gridCellTemplates.get(templateName || columnName);
  }

  /**
   * Returns a column's header menu template.
   * @private
   */
  getGridHeaderMenuTemplate(columnName: string): TemplateRef<any> {
    return this.getColumn(columnName).headerMenuDisabled ? null : this.gridHeaderMenuTemplates.get(columnName) || this.gridHeaderMenuDefaultTemplate;
  }

  /**
   * Returns an empty grid template.
   * @private
   */
  getEmptyGridTemplate(emptyGridType: EmptyGridDisplay): TemplateRef<any> {
    return this.emptyGridTemplates.get(emptyGridType);
  }

  /**
   * Determines whether a row is striped when the grid is rendered with stripes.
   * @private
   */
  getRowIsStriped(index: number, dataIndex: number): boolean {
    if (typeof dataIndex === 'number') {
      return dataIndex % 2 !== 0;
    } else if (typeof index === 'number') {
      return index % 2 !== 0;
    } else {
      return false;
    }
  }

  /**
   * A helper method for the template to get the text value for a cell.
   * @private
   */
  getPropByString(obj: object, propString: string): string {
    return get(obj, propString);
  }

  /**
   * Returns the GridColumn by its name.
   * @private
   */
  getColumn(name: string): GridColumn {
    return this.columns.find(column => column.name === name);
  }

  /**
   * Returns the type of message to display when the grid is empty.
   * Uses `GridControl.getEmptyGridDisplay` if it exists.
   * If `getEmptyGridDisplay` does not exist or returns undefined then the default behavior is used.
   * By default the filtered message is displayed if at least one filter is being applied. Otherwise the unfiltered message is displayed.
   */
  getEmptyGridDisplay(): EmptyGridDisplay {
    let emptyGridDisplay: EmptyGridDisplay;

    if (typeof this.gridControl?.getEmptyGridDisplay === 'function') {
      emptyGridDisplay = this.gridControl.getEmptyGridDisplay(this.filters);
    }

    if (typeof emptyGridDisplay === 'undefined') {
      emptyGridDisplay = this.filterCount > 0 ? 'filtered' : 'unfiltered';
    }

    return emptyGridDisplay;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  private refreshSelectInfo() {
    this.allItemsSelected = true;
    this.selectedItemCount = 0;

    if (this.items) {
      this.items.forEach(row => {
        if (!this.selection.isSelected(row.Id)) {
          this.allItemsSelected = false;
        } else {
          this.selectedItemCount += 1;
        }
      });
    } else {
      this.allItemsSelected = false;
    }
  }

  /** Removes any items in this.selection which are not in the given list of items */
  private updateSelectedItemIds(newItems: any[]) {
    if (Array.isArray(newItems) && newItems.length > 0) {
      const newItemIds = newItems.map(item => item.Id);
      const itemsToDeselect = this.selection.selected.filter(selectedId => !newItemIds.some(id => selectedId === id));

      if (itemsToDeselect.length > 0) {
        this.selection.deselect(...itemsToDeselect);
      }
    } else {
      this.selection.clear();
    }
  }
}
