import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { NodeViewBase } from '@portal-core/text-editor/util/node-view.base';

@Component({
  selector: 'mc-variable-node-view',
  templateUrl: './variable-node-view.component.html',
  styleUrls: ['./variable-node-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableNodeViewComponent extends NodeViewBase {
  @Input() value: string;

  @HostBinding('class.mc-node-view-text') _nodeViewTextClass: boolean = true;

  public get parentClassNameSuffix(): string {
    return 'variable-node-view';
  }
}
