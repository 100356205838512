import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { cache } from '@common/util/cache.operator';
import { ThemeService } from '@portal-core/general/services/theme.service';
import { LicenseStorageItemCode } from '@portal-core/license-storage/enums/license-storage-item-code.enum';
import { LicenseStorageItem } from '@portal-core/license-storage/models/license-storage-item.model';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseType } from '@portal-core/licenses/enums/license-type.enum';
import { License } from '@portal-core/licenses/models/license.model';
import { DoughnutChartHoleType } from '@portal-core/ui/charts/enums/doughnut-chart-hole-type.enum';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { ChartData, ChartOptions } from 'chart.js';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'mc-license-overview',
  templateUrl: './license-overview.component.html',
  styleUrls: ['./license-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseOverviewComponent implements OnInit {
  @Input() license: License;
  @InputObservable('license') license$: Observable<License>;

  DoughnutChartHoleType: typeof DoughnutChartHoleType = DoughnutChartHoleType;
  LicenseUserSeatType: typeof LicenseUserSeatType = LicenseUserSeatType;

  chartOptions: ChartOptions<'doughnut'> = {
    cutout: '92.5%'
  };

  buildsChartData$: Observable<ChartData>;
  isTrialLicense$: Observable<boolean>;
  licenseStorage$: Observable<LicenseStorage>;
  miscChartData$: Observable<ChartData>;
  sourceFilesChartData$: Observable<ChartData>;
  tasksChartData$: Observable<ChartData>;

  constructor(private licenseStorageService: LicenseStorageService, private themeService: ThemeService) { }

  ngOnInit() {
    this.isTrialLicense$ = this.license$.pipe(
      map(license => license && license.LicenseType === LicenseType.Trial)
    );

    // Create an observable for the license storage
    this.licenseStorage$ = this.licenseStorageService.getItemById$(this.license.Id, { forceApiRequest: true }).pipe(
      cache()
    );

    // Create an observable for for the chart data dependencies
    const chartData$ = combineLatest([
      this.isTrialLicense$,
      this.licenseStorage$
    ]);

    // Create observables for the chart data
    this.sourceFilesChartData$ = chartData$.pipe(
      map(([isTrialLicense, licenseStorage]) => this.getStorageItemChartData(isTrialLicense, licenseStorage, LicenseStorageItemCode.ProjectSourceFiles, 'Source Files', 'usage-source-files'))
    );

    this.buildsChartData$ = chartData$.pipe(
      map(([isTrialLicense, licenseStorage]) => this.getStorageItemChartData(isTrialLicense, licenseStorage, LicenseStorageItemCode.ProjectBuilds, 'Builds', 'usage-builds'))
    );

    this.tasksChartData$ = chartData$.pipe(
      map(([isTrialLicense, licenseStorage]) => this.getStorageItemChartData(isTrialLicense, licenseStorage, LicenseStorageItemCode.Tasks, 'Tasks', 'usage-tasks'))
    );

    this.miscChartData$ = chartData$.pipe(
      map(([isTrialLicense, licenseStorage]) => this.getStorageItemChartData(isTrialLicense, licenseStorage, LicenseStorageItemCode.Miscellaneous, 'Misc', 'usage-misc'))
    );
  }

  private getStorageItem(licenseStorage: LicenseStorage, storageItemCode: LicenseStorageItemCode): LicenseStorageItem {
    if (licenseStorage && licenseStorage.StorageInfoItems) {
      return licenseStorage.StorageInfoItems.find(storageItem => storageItem.Code === storageItemCode);
    }
  }

  private getStorageItemChartData(isTrialLicense: boolean, licenseStorage: LicenseStorage, storageItemCode: LicenseStorageItemCode, label: string, palette: string): ChartData {
    const storageItem = this.getStorageItem(licenseStorage, storageItemCode);

    if (storageItem) {
      return {
        datasets: [{
          data: [storageItem.Size, licenseStorage.MaxStorageSpace - storageItem.Size],
          backgroundColor: [isTrialLicense ? this.themeService.getColor(palette, 'lighter') : this.themeService.getColor(palette), this.themeService.getColor(palette, 'lighter')],
          borderWidth: [0, 0]
        }],
        labels: [label]
      };
    }
  }
}
