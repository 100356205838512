import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileTabDirective } from './profile-tab.directive';

@NgModule({
  declarations: [
    ProfileTabDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProfileTabDirective
  ]
})
export class ProfileTabModule { }
