/**
 * Taken from the entities library.
 * https://github.com/fb55/entities/blob/master/src/escape.ts#L3
 * Added 160 -> &160; to the map.
 */
const xmlCodeMap = new Map([
  [34, '&quot;'],
  [38, '&amp;'],
  [39, '&apos;'],
  [60, '&lt;'],
  [62, '&gt;'],
  [160, '&#160;'],
]);

/**
 * Creates a function that escapes all characters matched by the given regular expression using the given map of characters to escape to their entities.
 * Taken from the entities library.
 * https://github.com/fb55/entities/blob/master/src/escape.ts#L81
 * @param regex Regular expression to match characters to escape.
 * @param map Map of characters to escape to their entities.
 * @returns Function that escapes all characters matched by the given regular expression using the given map of characters to escape to their entities.
 */
function getEscaper(regex: RegExp, map: Map<number, string>): (data: string) => string {
  return function escape(data: string): string {
    let match;
    let lastIdx = 0;
    let result = '';

    while ((match = regex.exec(data))) {
      if (lastIdx !== match.index) {
        result += data.substring(lastIdx, match.index);
      }

      // We know that this character will be in the map.
      result += map.get(match[0].charCodeAt(0))!;

      // Every match will be of length 1
      lastIdx = match.index + 1;
    }

    return result + data.substring(lastIdx);
  };
}

/**
 * Encodes characters not valid in XML documents using XML entities.
 * Encodes &, <, >, and nbsp.
 *
 * @param data String to escape.
 * @returns The escaped string.
 */
export const escapeXML = getEscaper(/[&<>\u00A0]/g, xmlCodeMap);

/**
 * Encodes characters not valid in XML attribute values using XML entities.
 * Encodes ", &, and nbsp.
 *
 * @param data String to escape.
 * @returns The escaped string.
 */
export const escapeXMLAttribute = getEscaper(/["&\u00A0]/g, xmlCodeMap);
