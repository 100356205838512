import { Pipe, PipeTransform } from '@angular/core';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { TranslationPackageLanguageBranchesService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcTranslationPackageLanguageBranchAsync'
})
export class TranslationPackageLanguageBranchAsyncPipe implements PipeTransform {
  constructor(private translationPackageLanguageBranchesService: TranslationPackageLanguageBranchesService) { }

  transform(translationPackageLanguageBranchId: number | null | undefined, allowApiRequest: boolean = false): Observable<TranslationPackageLanguageBranch | null> {
    if (!translationPackageLanguageBranchId) {
      return of(null);
    }

    return this.translationPackageLanguageBranchesService.getItemById$(translationPackageLanguageBranchId, {
      allowApiRequest
    });
  }
}
