import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusLicenseUserTooltipClassPipe } from './review-file-user-status-license-user-tooltip-class.pipe';

@NgModule({
  declarations: [ReviewFileUserStatusLicenseUserTooltipClassPipe],
  imports: [CommonModule],
  exports: [ReviewFileUserStatusLicenseUserTooltipClassPipe]
})
export class ReviewFileUserStatusLicenseUserTooltipClassModule { }
