import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectFilesTreeComponent } from '@portal-core/project-files/components/project-files-tree/project-files-tree.component';
import { FlareFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ReviewFileListComponent } from '@portal-core/reviews/review-files/components/review-file-list/review-file-list.component';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { PopupComponent } from '@portal-core/ui/popup/components/popup/popup.component';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'mc-review-package-files-form',
  templateUrl: './review-package-files-form.component.html',
  styleUrls: ['./review-package-files-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageFilesFormComponent {
  @Input() reviewPackage: ReviewPackage;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('addFilesPopup', { static: true }) addFilesPopup: PopupComponent;
  @ViewChild('filesList', { static: true }) filesList: ReviewFileListComponent;
  @ViewChild('filesTree', { static: true }) projectFilesTree: ProjectFilesTreeComponent;

  @InputObservable('reviewPackage') reviewPackage$: Observable<ReviewPackage>;

  FlareFileFilter: string = FlareFileFilter;

  filesTreeValue = null;
  savingState: LoadingState<string> = new LoadingState<string>();
  userCanManageReviews$: Observable<boolean>;

  constructor(
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    private reviewFilesService: ReviewFilesService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.userCanManageReviews$ = this.reviewPackage$.pipe(
      switchMap(reviewPackage => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageReviews, reviewPackage?.ProjectId))
    );
  }

  onPopupClosed() {
    // CLear out the selected files for the next time the popup is opened
    this.filesTreeValue = null;
  }

  onPopupOpened() {
    // Scroll the project files tree back to the top when the popup is opened AND check the viewport size
    this.projectFilesTree.scrollToTop();
    this.projectFilesTree.checkViewportSize();
  }

  onSaveFilesCancelClicked() {
    this.addFilesPopup.close();
    // Clear out any errors that may have happened before
    this.savingState.update(false);
  }

  onSaveFilesClicked() {
    this.addFilesPopup.close();

    if (this.projectFilesTree.value?.length > 0) {
      this.savingState.update(true);

      this.reviewFilesService.addReviewFiles$(this.reviewPackage.Id, this.projectFilesTree.value).subscribe(() => {
        this.savingState.update(false);
        this.snackBar.open('The review files have been added.', 'OK', { duration: 2500 });
        this.hardReload();
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to add the review files.', this.errorService.getErrorMessages(error));
      });
    } else {
      // Clear out any errors that may have happened before
      this.savingState.update(false);
    }
  }

  hardReload() {
    this.filesList.hardReload();
  }
}
