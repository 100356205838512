import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildLog } from '@portal-core/build-logs/models/build-log.model';
import { BuildLogsDataService } from '@portal-core/build-logs/services/build-logs-data.service';
import { BuildLogsApiService } from '@portal-core/build-logs/services/build-logs-api.service';
import { Page } from '@common/paged-data/types/page.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ProcessLogMessageType } from '@portal-core/processes/enums/process-log-message-type.enum';
import { ProcessNotification } from '@portal-core/processes/models/process-notification.model';
import { Resettable } from '@portal-core/util/resettable.decorator';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class BuildLogsService extends CollectionServiceBase<BuildLog> {
  constructor(private buildLogsDataService: BuildLogsDataService, protected dataService: DataService, private buildLogsApiService: BuildLogsApiService) {
    super(buildLogsDataService, dataService);
  }

  getBuildLogsPageByMessageType$(buildId: number, logMessageType: ProcessLogMessageType, filter: PageFilter): Observable<Page<ProcessNotification>> {
    return this.buildLogsApiService.getBuildLogsPageByMessageType$(buildId, logMessageType, filter);
  }

  getBuildLog$(buildId: number): Observable<BuildLog> {
    return this.buildLogsApiService.getBuildLog$(buildId);
  }
}
