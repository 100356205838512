<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    Variables
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <mc-variable-picker-input [variableSets$]="data.projectVariableSets$" [hiddenSystemVariables]="data.hiddenSystemVariables" [setName]="data.setName" [variableName]="data.variableName" (variableSelected)="onVariableSelected($event?.Data)"></mc-variable-picker-input>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button (click)="onInsertClicked()" color="primary" [disabled]="!activeVariable">Insert</button>
  </div>

  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>
</div>