import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CshIdCallsReport } from '@portal-core/output-analytics/models/output-analytics-csh-id-calls-report.model';

@CollectionStateSelectors({
  stateType: CshIdCallsReportState
})
@State<CshIdCallsReportState>({
  name: CshIdCallsReportState.source,
})
@Injectable()
export class CshIdCallsReportState extends CollectionStateBase {
  static source = 'CshIdCallsReports';
  getSource(): string {
    return CshIdCallsReportState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CshIdCallsReportDataService extends CollectionDataServiceBase<CshIdCallsReport> {
  constructor(protected store: Store) {
    super(store, CshIdCallsReportState);
  }
}
