import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Target } from '@portal-core/targets/models/target.model';

@CollectionStateSelectors({
  stateType: TargetsState
})
@State<TargetsState>({
  name: TargetsState.source,
})
@Injectable()
export class TargetsState extends CollectionStateBase {
  static source: string = 'Targets';
  getSource(): string {
    return TargetsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TargetsDataService extends CollectionDataServiceBase<Target> {
  constructor(protected store: Store) {
    super(store, TargetsState);
  }
}
