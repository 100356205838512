import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mcRange'
})
export class RangePipe implements PipeTransform {
  transform(size: number | undefined, offset: number = 0): number[] {
    if (!size) {
      return [];
    }

    const array = [];
    for (let i = 0; i < size; i += 1) {
      array.push(i + offset);
    }

    return array;
  }
}
