import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapMicroContentSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapmicrocontent: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      linkBucket: 'micro_content',
      parseDOM: [{
        tag: 'MadCap\\:microContent'
      }],
      toDOM() { return ['div', { class: 'mc-madcap-micro-content' }, 0]; },
      tagName: 'MadCap:microContent'
    }
  };
}
