import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { BrowserHostService } from '@portal-core/auth/services/browser-host.service';
import { map, Observable, of, tap } from 'rxjs';

/**
 * Emits a login event for Flare once the user is logged in with an active license user on an active license.
 */
@Injectable({
  providedIn: 'root'
})
export class FlareLoginEmitterResolverService implements Resolve<boolean> {
  constructor(private browserHostService: BrowserHostService, private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // If the browser host is defined then run the code to emit the login event
    if (this.browserHostService.isCefSharpHosted()) {
      return this.browserHostService.browserHost$().pipe(
        // Emit the login event if the user is authenticated
        tap(browserHost => {
          if (this.authService.isAuthenticated()) {
            browserHost?.data?.({
              type: 'login',
              auth: this.authService.getAuth()
            });
          }
        }),
        map(() => true)
      );
    }

    return of(true);
  }
}
