import { Pipe, PipeTransform } from '@angular/core';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';

@Pipe({
  name: 'mcLicenseUserAvatarTooltipClass'
})
export class LicenseUserAvatarTooltipClassPipe implements PipeTransform {
  transform(status: LicenseUserStatus | null | undefined): string {
    return `mc-tooltip-multiline mc-tooltip-centered`;
  }
}
