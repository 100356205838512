import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapPageHeaderSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcappageheader: {
      group: 'block',
      content: 'block*',
      atom: true,
      parseDOM: [{
        tag: 'MadCap\\:pageHeader'
      }],
      toDOM() {
        return ['div', { class: 'mc-madcap-page-header' }, 'Page Header'];
      },
      tagName: 'MadCap:pageHeader'
    }
  };
}
