import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseUserForLicenseAsyncPipe } from './license-user-for-license-async.pipe';



@NgModule({
  declarations: [LicenseUserForLicenseAsyncPipe],
  exports: [LicenseUserForLicenseAsyncPipe],
  imports: [CommonModule],
  providers: [LicenseUserForLicenseAsyncPipe]
})
export class LicenseUserForLicenseAsyncModule {}
