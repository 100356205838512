import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { Observable, Subject, Subscription } from 'rxjs';

const metaDataLoaderKey = new PluginKey('metaDataLoader');

export interface MetaDataLoaderPluginOptions {
  onLoadMetaData$: () => Observable<MetaDataValues>;
  metaDataSource: Subject<MetaDataValues>;
}

const fetchRetryDelayMS: number = 5000;

/**
 * metaDataLoaderPlugin
 * Requests meta data. Once loaded the plugin dispatches the meta data to all created editors (root editor and nested snippet editors).
 */
export function metaDataLoaderPlugin(options: MetaDataLoaderPluginOptions): Plugin {
  let fetchSubscription: Subscription;
  let fetchTimeoutId: any = null;

  function fetchMetaData() {
    // Grab the subscription so it can be unsubscribed from when the plugin is destroyed
    fetchSubscription = options.onLoadMetaData$().subscribe({
      next: data => {
        if (data?.metaData) {
          // Update the meta data
          options.metaDataSource.next(data);
        } else if (typeof data?.metaData === 'undefined') {
          // If the meta data is undefined then the meta data hasn't been generated yet so try again
          scheduleMetaDataFetch();
        }
      },
      error: err => {
        // There was an error fetching the meta data so try again
        scheduleMetaDataFetch();
      }
    });
  }

  function scheduleMetaDataFetch() {
    // Only schedule a fetch if one isn't already scheduled
    if (!fetchTimeoutId) {
      fetchTimeoutId = setTimeout(() => {
        // Remove the fetch timeout id now that it has been executed
        fetchTimeoutId = null;
        fetchMetaData();
      }, fetchRetryDelayMS);
    }
  }

  return new Plugin({
    key: metaDataLoaderKey,

    state: {
      init: function () { },
      apply: function (tr: Transaction) {
        if (tr.getMeta('metaDataLoader.load')) {
          fetchMetaData();
        }
      }
    },

    // Define a destroy function to clean up resources
    view: () => {
      return {
        destroy() {
          fetchSubscription?.unsubscribe();
          clearTimeout(fetchTimeoutId);
        }
      };
    }
  });
}
