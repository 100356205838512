import { BlockBrNormalizer } from '@common/clipboard/google-docs/block-brs';
import { BoldWrapperNormalizer } from '@common/clipboard/google-docs/bold-wrapper';
import { SpanNormalizer } from '@common/clipboard/google-docs/spans';
import { HtmlPasteNormalizerData } from '@common/clipboard/types/html-paste-normalizer-data.type';
import { HtmlPasteNormalizerOptions } from '@common/clipboard/types/html-paste-normalizer-options.type';
import { HtmlPasteNormalizer } from '@common/clipboard/types/html-paste-normalizer.type';

const googleDocsRegex = /id=("|')docs-internal-guid-[-0-9a-f]+("|')/i;

/**
 * Normalizes content pasted from Google Docs.
 */
export class GoogleDocsNormalizer implements HtmlPasteNormalizer {
  /**
   * Checks if the HTML is being pasted from Google Docs.
   * @param html The HTML to check.
   * @returns `true` if the HTML is from Google Docs, otherwise `false`.
   */
  matches(html: string): boolean {
    return googleDocsRegex.test(html);
  }

  /**
   * Converts Google Docs HTML to standard HTML.
   * @param data The data to normalize.
   * @param options The options to use when normalizing.
   * @returns The normalized HTML document.
   */
  normalize(data: HtmlPasteNormalizerData, options?: HtmlPasteNormalizerOptions): Document {
    const doc = data.doc;

    // Unwrap the content from the bold wrapper
    const boldWrapperNormalizer = new BoldWrapperNormalizer();
    boldWrapperNormalizer.normalize(doc);

    // Normalize spans
    const spanNormalizer = new SpanNormalizer();
    spanNormalizer.normalize(doc);

    // Normalize block brs into paragraphs
    const blockBrNormalizer = new BlockBrNormalizer();
    blockBrNormalizer.normalize(doc);

    return doc;
  }
}
