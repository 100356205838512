import { BehaviorSubject } from 'rxjs';

/**
 * LoadingState
 * Keeps track of the state during an asynchronous operation. Including whether the operation is underway or has completed. And any errors associated with the operation.
 * Provides observables for easy use with `OnPush` components.
 */
export class LoadingState<ErrorType> {
  /** Whether the LoadingState has completed. */
  complete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** The current detailed errors of the LoadingState. */
  detailedErrors$: BehaviorSubject<ErrorType[]> = new BehaviorSubject<ErrorType[]>(null);
  /** The current general error of the LoadingState. */
  generalError$: BehaviorSubject<ErrorType> = new BehaviorSubject<ErrorType>(null);
  /** Whether the LoadingState is loading. */
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Sets the loading and error states.
   * Emits the loading value on the loading$ observable and the error values on the error observables.
   * @return Returns this instance to allow chaining.
   */
  update(loading: boolean, generalErrorCode: ErrorType = null, detailedErrorCodes: ErrorType[] = null) {
    this.loading$.next(loading);
    this.generalError$.next(generalErrorCode);
    this.detailedErrors$.next(detailedErrorCodes);
    return this;
  }

  /**
   * Sets the complete state.
   * Emits the complete value on the complete$ observable.
   * @return Returns this instance to allow chaining.
   */
  complete(complete: boolean = true) {
    this.complete$.next(complete);
    return this;
  }
}
