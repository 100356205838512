import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TaskBoardSettingsFormComponent } from '@portal-core/task-boards/components/task-board-settings-form/task-board-settings-form.component';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, first } from 'rxjs';

export interface TaskBoardProfileDialogData {
  taskBoardId: number;
  userId: string;
  taskBoardProfileTab: TaskBoardProfileTab;
  licenseId: number;
}

export enum TaskBoardProfileTab {
  Settings,
  Delete
}

@Component({
  selector: 'mc-task-board-profile-dialog',
  templateUrl: './task-board-profile-dialog.component.html',
  styleUrls: ['./task-board-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoardProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '80rem',
    height: '60rem'
  };

  @ViewChild('taskBoardSettingsForm', { static: true }) taskBoardSettingsFormComponent: TaskBoardSettingsFormComponent;

  TaskBoardProfileTab: typeof TaskBoardProfileTab = TaskBoardProfileTab;

  taskBoard$: Observable<TaskBoard>;
  userId: string;
  loadingState: LoadingState<string> = new LoadingState<string>();
  taskBoardProfileTab: TaskBoardProfileTab;

  private taskBoardId: number;
  private licenseId: number;

  get requirePromptOnClose(): boolean {
    return this.taskBoardSettingsFormComponent && this.taskBoardSettingsFormComponent.dirty;
  }

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<TaskBoardProfileDialogComponent>,
    private taskBoardsService: TaskBoardsService,
    private errorService: ErrorService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: TaskBoardProfileDialogData) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.taskBoardId = this.data.taskBoardId
    this.userId = this.data.userId;
    this.taskBoardProfileTab = this.data.taskBoardProfileTab ?? TaskBoardProfileTab.Settings;
    this.licenseId = this.data.licenseId;

    this.loadingState.update(true);

    this.taskBoard$ = this.taskBoardsService.getItemById$(this.taskBoardId, { forceApiRequest: true });
    this.taskBoard$.pipe(
      first()
    ).subscribe(() => {
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the task board.', this.errorService.getErrorMessages(error));
    });
  }

  onCancel() {
    this.closeDialog();
  }

  onDelete() {
    if (this.router.url.search(`/tasks/boards/${this.taskBoardId}`) !== -1) {
      this.router.navigate([this.licenseId, 'tasks', 'boards']);
    }
    this.dialogRef.close(true);
  }
}
