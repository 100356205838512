import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { toBoolean } from '@common/util/bool';
import { GridFilterName } from '@portal-core/ui/grid/enums/grid-filter-name.enum';
import { GridFilterBase } from '@portal-core/ui/grid/util/grid-filter-base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';

@Component({
  selector: 'mc-grid-boolean-filter',
  templateUrl: './grid-boolean-filter.component.html',
  styleUrls: ['./grid-boolean-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridBooleanFilterComponent extends GridFilterBase {
  @ViewChild('firstRadioButton') firstRadioButton: MatRadioButton;

  constructor(protected formBuilder: UntypedFormBuilder, pageFilterService: PageFilterService) {
    super(pageFilterService);

    // Do this in the constructor so that the form is ready for ngOnChanges
    this.buildForm();
  }

  onMenuOpened() {
    super.onMenuOpened();

    // Give focus to the 'from' field if there is no custom menu
    if (!this.menuTemplate) {
      this.firstRadioButton.focus();
    }
  }

  onSubmit() {
    if (this.filterForm.invalid) {
      return;
    }

    this.submitFilter(this.buildFilter(this.filterForm.value['bool']));
  }

  buildFilter(bool: string): PageFilterGroup {
    return this.pageFilterService.create(this.filterable.getFilter(GridFilterName.Bool) ?? {
      Id: GridFilterName.Bool,
      Type: PageFilterGroupType.Bool
    }).bool(this.column.name, toBoolean(bool, null), {
      PropertyType: this.column.filterDataType ?? this.column.type,
      PropertyName: this.column.filterName
    }).value;
  }

  protected buildForm() {
    this.filterForm = this.formBuilder.group({
      bool: new UntypedFormControl(null)
    });
  }

  protected buildFormValue(pageFilter: PageFilterGroup): Dictionary {
    let bool: string;
    if (this.column && this.filterable) {
      bool = this.pageFilterService.getValueFromBools(pageFilter, this.column.name)?.toString();
    }

    return { bool };
  }

  protected getGridFilterName(): GridFilterName {
    return GridFilterName.Bool;
  }
}
