import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { BuildAvatarComponent } from './build-avatar.component';


@NgModule({
  declarations: [
    BuildAvatarComponent
  ],
  imports: [
    CommonModule,
    InlineButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    BuildAvatarComponent
  ]
})
export class BuildAvatarModule { }
