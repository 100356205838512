<mat-sidenav-container class="sidenav-container navbar-container" hasBackdrop="true">
  <mat-sidenav #drawer class="sidenav page-drawer" fixedInViewport="true" role="navigation" mode="over" fixedTopGap="54" (click)="onDrawerClicked($event)">
    <!-- <router-outlet name="app-drawer-launch-pad" *ngIf="(showAppDrawerLaunchPad | async)?.matches"></router-outlet> -->

    <router-outlet name="app-drawer-nav" *ngIf="(showAppDrawerNav | async)?.matches"></router-outlet>
    <router-outlet name="page-drawer-nav" *ngIf="(showAppDrawerNav | async)?.matches"></router-outlet>
    <router-outlet name="app-drawer-gutter" *ngIf="(showAppDrawerGutter | async)?.matches"></router-outlet>
    <router-outlet name="page-drawer-gutter" *ngIf="(showAppDrawerGutter | async)?.matches"></router-outlet>
    <router-outlet name="page-drawer-gutter-always" #pageDrawerGutterAlwaysOutlet="outlet"></router-outlet>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="navbar" color="secondary">
      <div class="navbar-heading">
        <button mat-button type="button" class="mc-navbar-drawer-toggle" aria-label="Toggle sidenav" [disabled]="!pageDrawerGutterAlwaysOutlet.isActivated && !(showAppDrawer | async)?.matches" (click)="drawer.toggle()">
          <mat-icon fontSet="project-titan" fontIcon="icon-burger" class="mc-navbar-title-icon text-large" *ngIf="pageDrawerGutterAlwaysOutlet.isActivated || (showAppDrawer | async)?.matches"></mat-icon>
          <mat-icon fontSet="project-titan" fontIcon="icon-close" class="mc-navbar-title-icon text-large"></mat-icon>
          <router-outlet name="page-heading"></router-outlet>
        </button>
      </div>

      <router-outlet name="page-navbar-tab-links" *ngIf="(showPageNavbarTabLinks | async)?.matches"></router-outlet>
      <router-outlet name="page-navbar-toolbar" *ngIf="(showPageNavbarToolbar | async)?.matches"></router-outlet>
      <span class="mc-fill-remaining-flex-space"></span>
      <router-outlet name="app-navbar-launch-pad" *ngIf="(showAppNavbarLaunchPad | async)?.matches"></router-outlet>
      <!-- <router-outlet name="page-navbar-dropdown-links" *ngIf="(showPageNavbarDropdownLinks | async)?.matches"></router-outlet> -->
    </mat-toolbar>
  </mat-sidenav-content>
</mat-sidenav-container>
