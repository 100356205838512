import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { DashboardItem } from '@portal-core/dashboard/models/dashboard-item.model';
import { PhoneNumber } from '@portal-core/users/models/phone-number.model';
import { UserEdit } from '@portal-core/users/models/user-edit.model';
import { UserNotification } from '@portal-core/users/models/user-notification.model';
import { User } from '@portal-core/users/models/user.model';
import { UserAppRole } from '@portal-core/users/types/user-app-role.type';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getUserById$(userId: string): Observable<User> {
    return this.http.get<User>(`${this.apiService.centralApiBaseUri}/api/users/${userId}`);
  }

  @SharePendingRequest()
  getUserByName$(userName: string): Observable<User> {
    return this.http.get<User>(`${this.apiService.centralApiBaseUri}/api/users/${userName}`);
  }

  @SharePendingRequest()
  getDashboardSettingsByLicenseId$(licenseId: number): Observable<DashboardItem[]> {
    return this.http.get<DashboardItem[]>(`${this.apiService.centralApiBaseUri}/api/users/me/dashboard/${licenseId}`).pipe(
      map(dashboardSettings => typeof dashboardSettings === 'string' ? JSON.parse(dashboardSettings) : [])
    );
  }

  @SharePendingRequest()
  getDashboardSettingsByProjectId$(projectId: number): Observable<DashboardItem[]> {
    return this.http.get<DashboardItem[]>(`${this.apiService.centralApiBaseUri}/api/users/me/projectdashboard/${projectId}`).pipe(
      map(dashboardSettings => typeof dashboardSettings === 'string' ? JSON.parse(dashboardSettings) : [])
    );
  }

  updateUserSettings$(user?: Partial<UserEdit>, avatarFile?: File): Observable<User> {
    const formData = new FormData();
    formData.append('User', user ? JSON.stringify(user) : null);

    if (typeof avatarFile !== 'undefined') {
      formData.append('AvatarFile', avatarFile);
    }

    formData.append('IsAvatarSet', JSON.stringify(avatarFile !== null ? true : false));

    return this.http.put<User>(`${this.apiService.centralApiBaseUri}/api/users/${user.Id}`, formData);
  }

  disableGettingStartedSetting$(userId: string): Observable<any> {
    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/DisableGettingStarted`, null);
  }

  saveNewPhoneNumber$(userId: string, phoneNumber: string, label: string): Observable<User> {
    return this.http.post<User>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/PhoneNumber/${phoneNumber}?label=${label}`, null);
  }

  updatePhoneNumber$(userId: string, phoneNumberId: number, phoneNumber: string, label: string): Observable<PhoneNumber> {
    return this.http.put<PhoneNumber>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/PhoneNumber/${phoneNumberId}?label=${label}`, { value: phoneNumber });
  }

  updateEmail$(currentEmail: string, newEmail: string): Observable<any> {
    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/users/ChangeUsername` +
      `?currentUsername=${encodeURIComponent(currentEmail)}&newUsername=${encodeURIComponent(newEmail)}`, null);
  }

  updateUserAvatar$(userId: string, avatarImage: FormData): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/users/${userId}/Avatar`, avatarImage);
  }

  deleteUserAvatar$(userId: string): Observable<any> {
    return this.http.delete(`${this.apiService.centralApiBaseUri}/api/users/${userId}/Avatar`);
  }

  updateUserProjectsAndTeams$(userId: string, licenseId: number, projectIds: number[], teamIds: number[]): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/users/${licenseId}/UserMemberships`, {
      ProjectIds: projectIds,
      TeamIds: teamIds,
      UserId: userId
    });
  }

  @SharePendingRequest()
  getSubscriptions$(): Observable<UserNotification[]> {
    return this.http.get<UserNotification[]>(`${this.apiService.centralApiBaseUri}/api/users/subscriptions`);
  }

  @SharePendingRequest()
  getWhatsNew$(): Observable<MadCloudResult<string>> {
    return this.http.get<MadCloudResult<string>>(`${this.apiService.centralApiBaseUri}/api/users/whatsnew`);
  }

  @SharePendingRequest()
  getUsersPage$(filter: PageFilter): Observable<Page<User>> {
    return this.http.post<Page<User>>(`${this.apiService.centralApiBaseUri}/api/users/query`, filter);
  }

  updateUserRoles$(userIds: string[], newRoles: UserAppRole[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/updateUserRole`, {
      userIds, newRoles
    });
  }

  updateLatestVersionViewed$(): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/users/updateLatestVersionViewed`, null);
  }

  resetAccessFailed$(userId: string): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/ResetAccessFailed`, null);
  }
}
