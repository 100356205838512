import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ReviewFileUser } from '@common/reviews/models/review-file-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { ReviewFileUsersService } from '@portal-core/reviews/review-file-users/services/review-file-users.service';
import { ReviewFileUsersPageFilterConfig } from '@portal-core/reviews/review-file-users/util/review-file-users-page-filter-config';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { uniq } from 'lodash';
import { of, tap } from 'rxjs';

@Component({
  selector: 'mc-review-file-user-list',
  templateUrl: './review-file-user-list.component.html',
  styleUrls: ['./review-file-user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileUserListComponent extends ListBase<ReviewFileUser> implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewFileId: number;

  filterConfig: ReviewFileUsersPageFilterConfig = new ReviewFileUsersPageFilterConfig(['UserId', 'Status']);

  listControl: ListControl<ReviewFileUser> = new ListControl<ReviewFileUser>(this.reviewFileUsersService, filter => {
    if (typeof this.reviewFileId === 'number' && typeof this.licenseId === 'number') {
      const licenseId: number = this.licenseId;

      return this.reviewFileUsersService.getReviewFileUsersPage$(this.reviewFileId, filter).pipe(
        tap(page => {
          if (Array.isArray(page.Items)) {
            // Bulk load all the users used by the review file user grid items
            this.licenseUsersService.loadLicenseUsersByUserId$(licenseId,
              uniq(
                page.Items.flatMap(reviewFileUser => reviewFileUser.UserId ?? null).filter(userId => typeof userId === 'string')
              )
            ).subscribe();
          }
        })
      );
    } else {
      return of(null);
    }
  });

  constructor(private licenseUsersService: LicenseUsersService, private reviewFileUsersService: ReviewFileUsersService) {
    super(reviewFileUsersService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId || changes.reviewFileId) {
      this.hardReload();
    }
  }
}
