import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * A generic dialog for showing an alert message to the user.
 * The `mc-alert-dialog` component behaves like any other [Material dialog]{@link https://material.angular.io/components/dialog/overview}.
 * The MatDialog service can be used to open the dialog with Material Design styling and animations.
 * The alert dialog can be configured when opening it using the data object.
 */
@Component({
  selector: 'mc-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertDialogComponent implements OnInit {
  /** The title of the dialog. */
  title: string = '';
  /** The message shown in the body of the dialog. */
  message: string = '';

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title || '';
    this.message = this.data.message || '';
  }
}
