import { TrackedChangeCleaner } from '@common/prosemirror/changeset/tracked-change-cleaner';
import { EditorState, Plugin, Transaction } from 'prosemirror-state';

// Creates and returns a trackedChanges plugin instance
export function trackedChangesCleanUpPlugin(): Plugin {
  return new Plugin({
    appendTransaction(transactions: Transaction[], oldState: EditorState, newState: EditorState): Transaction {
      const tr = newState.tr;

      if (Array.isArray(transactions)) {
        transactions.forEach(transaction => {
          // Only handle changes that come from the tracked changes plugin
          if (transaction.docChanged && transaction.getMeta('trackedChange')) {
            const cleaner = new TrackedChangeCleaner(transaction, oldState, newState);
            cleaner.clean(tr);
          }
        });

        // If any changes were made then return the transaction
        if (tr.docChanged) {
          tr.setMeta('trackedChangesCleanUp', true);
          return tr;
        }
      }
    }
  });
}
