<div *ngIf="isSamlAuthenticationConfigured && !isEditing" class="mc-spring-board">
  <div class="mc-spring-board-form-body">
    <ng-container *ngIf="ssoSettings$ | async as ssoSettings">
      <ng-container *ngIf="ssoSettings.Enabled">
        <p class="mc-form-text">
          <mat-icon color="primary-vivid" fontSet="project-titan" fontIcon="icon-completed" class="mc-centered-text-icon"></mat-icon>&nbsp;
          Single sign-on is enabled. Users can log in using SAML.
        </p>
        <ng-container *ngIf="ssoSettings.CreateViewerUserOnDemand">
          <p class="mc-form-text">New users will be added to the following teams:</p>
          <div *ngIf="ssoSettings.SSODefaultTeamIds?.length > 0" class="mc-form-section mc-avatar-list mc-avatar-list-truncate mc-avatar-list-fade">
            <mc-team-avatar *ngFor="let teamId of ssoSettings.SSODefaultTeamIds" [team]="getTeamById$(teamId) | async" [showName]="false" [size]="'small'" class="mc-avatar-list-item"></mc-team-avatar>
          </div>
        </ng-container>
      </ng-container>

      <p *ngIf="!ssoSettings.Enabled" class="mc-form-text">
        Single sign-on is disabled. Users <b>cannot</b> log in using SAML. Please click Change Settings to enable SSO.
      </p>

      <div class="mc-form-spacer"></div>

      <h4 class="mc-form-heading">SAML Authentication Settings</h4>
      <ul class="mc-form-section mc-property-list mc-property-list-muted">
        <li>
          <span class="mc-property-name">Identity Provider Issuer: </span>
          <span class="mc-property-value">{{ ssoSettings.IdentityProviderIssuer }}</span>
        </li>
        <li>
          <span class="mc-property-name">SAML 2.0 Login Endpoint (HTTP): </span>
          <span class="mc-property-value">{{ ssoSettings.SamlEndpoint }}</span>
        </li>
        <li>
          <span class="mc-property-name">SLO Logout Endpoint (HTTP): </span>
          <span class="mc-property-value">{{ ssoSettings.IdpLogoutEndpoint }}</span>
        </li>
        <li>
          <span class="mc-property-name">Public Certificate: </span>
          <span class="mc-property-value">{{ ssoSettings.PublicCertificate }}</span>
        </li>
      </ul>

      <div class="mc-form-spacer"></div>

      <h4 class="mc-form-heading">UI Customization</h4>
      <ul class="mc-property-list mc-property-list-muted">
        <li>
          <span class="mc-property-name">Login Button Label: </span>
          <span class="mc-property-value">{{ ssoSettings.SignInButtonLabel }}</span>
        </li>
      </ul>
    </ng-container>

    <div class="mc-form-spacer"></div>
    <button type="button" mat-raised-button color="primary" (click)="onConfigureSamlAuthentication()">Change Settings</button>
    <div class="mc-form-spacer"></div>
  </div>
</div>

<div *ngIf="!isSamlAuthenticationConfigured && !isEditing" class="mc-spring-board">
  <div class="mc-spring-board-form-body">
    <h4 class="mc-form-heading">Configure Single Sign-On</h4>

    <p class="mc-form-text">Configure single sign-on with an Identity Provider that supports SAML 2.0.</p>

    <button type="button" mat-raised-button color="primary" (click)="onConfigureSamlAuthentication()">Configure</button>
    <div class="mc-form-spacer"></div>
  </div>
</div>

<form *ngIf="isEditing" novalidate class="mc-spring-board" [formGroup]="ssoSettingsForm" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <h4 class="mc-form-heading">SAML Authentication Settings</h4>
    <div formGroupName="saml">
      <div class="mc-form-field">
        <mat-checkbox color="primary-vivid" formControlName="enabled">Enable SSO for Central login</mat-checkbox>
      </div>
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>SAML 2.0 Login Endpoint (HTTP)</mat-label>
        <input matInput type="text" placeholder="SAML 2.0 Endpoint (HTTP)" formControlName="samlEndpoint" [required]="ssoSettingsForm.get('saml.enabled').value">
        <mat-error>
          <mc-form-control-error-message [form]="ssoSettingsForm" controlName="saml.samlEndpoint" type="saml_endpoint"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>SLO Logout Endpoint (HTTP)</mat-label>
        <input matInput type="text" placeholder="SLO Endpoint (HTTP)" formControlName="idpLogoutEndpoint">
        <mat-error>
          <mc-form-control-error-message [form]="ssoSettingsForm" controlName="saml.idpLogoutEndpoint" type="saml_endpoint"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Identity Provider Issuer</mat-label>
        <input matInput type="text" placeholder="Identity Provider Issuer" formControlName="identityProviderIssuer" [required]="ssoSettingsForm.get('saml.enabled').value">
        <mat-error>
          <mc-form-control-error-message [form]="ssoSettingsForm" controlName="saml.identityProviderIssuer" type="saml_idp_issuer"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Public Certificate</mat-label>
        <input matInput type="text" placeholder="Public Certificate" formControlName="publicCertificate" [required]="ssoSettingsForm.get('saml.enabled').value">
        <mat-error>
          <mc-form-control-error-message [form]="ssoSettingsForm" controlName="saml.publicCertificate" type="saml_public_cert"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <h4 class="mc-form-heading">Single Sign-On Settings</h4>
    <div class="mc-form-section" formGroupName="settings">
      <div class="mc-form-field">
        <mat-checkbox color="primary-vivid" formControlName="createViewerUserOnDemand">Create viewer users on demand</mat-checkbox>
      </div>

      <p class="mc-form-note">
        When a user logs in to Central using Single Sign-On and an existing Central user doesn't exist on this license, a user will be created with a Viewer seat type and associated with the selected default teams.
      </p>
      <p class="mc-form-note">
        For more information, <a target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Viewer_Users_On_Demand">click here.</a>
      </p>

      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Default Teams</mat-label>
        <mc-autocomplete-input formControlName="teams" [required]="ssoSettingsForm.get('settings.teams').enabled">
          <mc-teams-select-list mcAutocompleteList [licenseId]="license?.Id"></mc-teams-select-list>

          <ng-template mcAutocompleteItem let-teamId="item">
            <mc-team-avatar compact [disabled]="true" [team]="getTeamById$(teamId) | async" size="small" [truncate]="true"></mc-team-avatar>
          </ng-template>
        </mc-autocomplete-input>
        <mat-error>
          <mc-form-control-error-message [form]="ssoSettingsForm" controlName="settings.teams" type="mc_license_teams"></mc-form-control-error-message>
        </mat-error>
      </mat-form-field>
    </div>

    <h4 class="mc-form-heading">UI Customization</h4>
    <div formGroupName="customization">
      <mat-form-field appearance="standard" class="mc-form-field" color="primary-vivid">
        <mat-label>Login Button Label</mat-label>
        <input matInput type="text" placeholder="Login Button Label" formControlName="signInButtonLabel">
      </mat-form-field>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="isEditing = false">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>
</form>

<mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>