import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathService {
  sumReducer(sum: number, num: number): number {
    return sum + num;
  }

  sum(numbers: number[]): number {
    return numbers.reduce(this.sumReducer, 0);
  }

  /** Use the Largest Remainder method to round a set of percentages so that they add up to 100. */
  roundPercentagesToOneHundred(percentages: number[]): number[] {
    // Round the percentages down, sum them up, and subtract them from 100 to get the remaining difference
    const diff = 100 - percentages.map(Math.floor).reduce(this.sumReducer, 0);
    // keep track of original order
    const percentsWithIndex = percentages.map((percent, index) => {
      return{index, percent};
    });
    // Sort the percentages by their decimal size from largest to smallest
    const sortedPercentsWithIndex =  percentsWithIndex.sort((a, b) => (Math.floor(a.percent) - a.percent) - (Math.floor(b.percent) - b.percent));
    // Round each percentage down and add one to the percentages with the largest decimal until all of diff has been added back in
    sortedPercentsWithIndex.forEach((sortedPercentWithIndex, index) => {
      const percent = sortedPercentWithIndex.percent;
      if (index < diff) {
        sortedPercentWithIndex.percent = Math.floor(percent) + 1;
      } else {
        sortedPercentWithIndex.percent = Math.floor(percent);
      }
    });
    // return the input's order, and with just the percents
    return sortedPercentsWithIndex.sort((a, b) => a.index - b.index).map(sortedPercentWithIndex => sortedPercentWithIndex.percent);
  }

  /** Round a number to the nearest multiple. */
  roundToMultiple(value: number, multiple: number): number {
    const floating = value / multiple;
    const integer = Math.floor(floating);
    const rest = floating - integer;

    return rest > 0.5 ? (integer + 1) * multiple : integer * multiple;
  }

  nextMultiple(value: number, multiple: number): number {
    return (Math.floor(value / multiple) + 1) * multiple;
  }

  prevMultiple(value: number, multiple: number): number {
    return (Math.ceil(value / multiple) - 1) * multiple;
  }

  clamp(value: number, min: number, max: number): number {
    if (value < min) {
      return min;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  }
}
