import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AlertDialogModule } from '@portal-core/general/components/alert-dialog/alert-dialog.module';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';
import { ProjectAvatarComponent } from './project-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    InlineButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    AlertDialogModule,
    RouterModule
  ],
  declarations: [
    ProjectAvatarComponent
  ],
  exports: [
    ProjectAvatarComponent
  ]
})
export class ProjectAvatarModule { }
