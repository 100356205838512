import { Environment } from '@portal-core/environment/environment.model';

declare const process: any;

export const environment: Environment = {
  centralApiBaseUri: process.env.MC_CENTRAL_API_BASE_URI,
  centralApiSubdomainUri: process.env.MC_CENTRAL_API_SUBDOMAIN_URI,
  centralConfirmSubdomain: process.env.MC_CENTRAL_CONFIRM_SUBDOMAIN,
  centralLoginPortalBaseUri: process.env.MC_CENTRAL_LOGIN_PORTAL_BASE_URI,
  centralPortalBaseUri: process.env.MC_CENTRAL_PORTAL_BASE_URI,
  clientId: process.env.MC_CLIENT_ID,
  collabApiUrl: process.env.MC_COLLAB_API_URL,
  collabAuthorityUrl: process.env.MC_COLLAB_AUTHORITY_URL,
  collabSocketMaxHttpBufferSizeBytes: process.env.MC_COLLAB_SOCKET_MAX_HTTP_BUFFER_SIZE_BYTES,
  flareTextEditorDocMaxCharLength: process.env.MC_FLARE_TEXT_EDITOR_DOC_MAX_CHAR_LENGTH,
  madcapUrl: process.env.MC_MADCAP_URL,
  production: process.env.MC_PRODUCTION === 'true',
  signalrLoggingEnabled: process.env.MC_SIGNALR_LOGGING_ENABLED === 'true',
  subdomainPortal: process.env.MC_SUBDOMAIN_PORTAL === 'true',
  externalWebServerConfirmRedirect: process.env.MC_EXTERNAL_WEBSERVER_CONFIRM_REDIRECT === 'true'
} as Environment;
