import { ProseMirrorNode, ResolvedPos } from 'prosemirror-model';

export declare type ResolvedPosCallback = (node: ProseMirrorNode, depth: number, $pos: ResolvedPos) => boolean | void;

export interface FoundResolvedPosInfo {
  $nodePos: ResolvedPos;
  $pos: ResolvedPos;
  depth: number;
  node: ProseMirrorNode;
}

// Loops through all the nodes in a ResolvedPos. Starts with the deepest node and iterates up.
export function resolvedPosForEachNode($pos: ResolvedPos, iteratee: ResolvedPosCallback, topDown: boolean = false) {
  let depth: number;
  let node: ProseMirrorNode;

  if (topDown) {
    depth = 0;

    while (depth <= $pos.depth) {
      node = $pos.node(depth);

      if (iteratee(node, depth, $pos) === false) {
        break;
      }

      depth += 1;
    }
  } else {
    depth = $pos.depth;

    while (depth >= 0) {
      node = $pos.node(depth);

      if (iteratee(node, depth, $pos) === false) {
        break;
      }

      depth -= 1;
    }
  }
}

// Finds a depth in a ResolvedPos
export function resolvedPosFind($pos: ResolvedPos, predicate: ResolvedPosCallback): FoundResolvedPosInfo {
  let foundInfo: FoundResolvedPosInfo;

  resolvedPosForEachNode($pos, (node: ProseMirrorNode, depth: number) => {
    if (predicate(node, depth, $pos)) {
      // top document node does not have position before it
      // so we use zero position for it
      const p = depth === 0 ? 0 : $pos.before(depth);
      foundInfo = {
        depth: depth,
        node: node,
        $nodePos: $pos.doc.resolve(p),
        $pos: $pos
      };
      return false;
    }
  });

  return foundInfo;
}

// Finds a node in a ResolvedPos
export function resolvedPosFindNode($pos: ResolvedPos, predicate: ResolvedPosCallback): ProseMirrorNode {
  const foundInfo = resolvedPosFind($pos, predicate);
  if (foundInfo) {
    return foundInfo.node;
  }
}
