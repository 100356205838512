import { Observable } from 'rxjs';

export interface IResettable {
  reset$(): Observable<any>;
}

interface ResettableDecorator {
  (): ((constructor: Function) => void);
  resettables: IResettable[];
}

// Use Object.assign to add the resettables array to the Resettable function with strict typing for the function and added property
export const Resettable: ResettableDecorator = Object.assign(() => {
  return function (constructor: Function) {
    if (typeof constructor.prototype.reset$ !== 'function') {
      throw new Error(`${constructor.name} is using @Resettable but does not implement IResettable.`);
    }

    (constructor as any).mcResettable = true;
    Resettable.resettables.push(constructor as unknown as IResettable); // TODO: write this so the cast to unknown is unnecessary
  };
}, {
  resettables: []
});
