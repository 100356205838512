import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslationPackageLanguageBranchProfileDialogComponent } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-profile-dialog/translation-package-language-branch-profile-dialog.component';
import { TranslationPackageLanguageBranch } from '@portal-core/translation-package-language-branches/models/translation-package-language-branch.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-translation-package-language-branch-avatar',
  templateUrl: './translation-package-language-branch-avatar.component.html',
  styleUrls: ['./translation-package-language-branch-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPackageLanguageBranchAvatar extends AvatarBase {
  @Input() translationPackageLanguageBranch: TranslationPackageLanguageBranch;

  get name(): string {
    if (this.translationPackageLanguageBranch) {
      return this.translationPackageLanguageBranch.LanguageName + ' [' + this.translationPackageLanguageBranch.Language + ']';
    }
  }

  get tooltip(): string {
    return this.name;
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  onAvatarClicked() {
    this.openTranslationPackageLanguageBranchProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.translationPackageLanguageBranch;
  }

  private openTranslationPackageLanguageBranchProfileDialog() {
    this.dialog.open(TranslationPackageLanguageBranchProfileDialogComponent, {
      ...TranslationPackageLanguageBranchProfileDialogComponent.DialogConfig,
      data: {
        translationPackageLanguageBranchId: this.translationPackageLanguageBranch.Id
      }
    });
  }
}
