import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PathBasenameModule } from '@portal-core/general/pipes/path-base-name/path-base-name.module';
import { TargetAvatarModule } from '@portal-core/targets/components/target-avatar/target-avatar.module';
import { TranslationPackageLanguageBranchAvatarModule } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-avatar/translation-package-language-branch-avatar.module';
import { TranslationPackagesSummaryComponent } from '@portal-core/translation-packages/components/translation-packages-summary/translation-packages-summary.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslationPackageLanguageBranchAvatarModule,
    PathBasenameModule,
    TargetAvatarModule
  ],
  declarations: [TranslationPackagesSummaryComponent],
  exports: [TranslationPackagesSummaryComponent]
})
export class TranslationPackagesSummaryModule { }
