import { Pipe, PipeTransform } from '@angular/core';
import { FlareSchema } from '@common/flare/flare-schema';
import { ProseMirrorNode } from 'prosemirror-model';

/*
 * Creates a title for a node, usually just uses the type of node it is and its text content, if it has classes/id adds them to the title too.
 * Usage:
 *   node | mcCreateDocumentBucketNodeTitle
 * Example:
 *   {{ {...type: {...name:'paragraph' }, contentType: 'Hello World', attrs: {...MadCap:custom}: {... class: 'Test'}} |  mcCreateDocumentBucketNodeTitle : {...textContent: 'test'} : false }}
 *    returns 'paragraph.Test - Hello World'
*/
@Pipe({
    name: 'mcCreateDocumentBucketNodeTitle'
})

export class CreateDocumentBucketNodeTitlePipe implements PipeTransform {
    constructor() { }

    static schema = new FlareSchema();
    transform(node: ProseMirrorNode, topOfTheDocument: boolean = false): string {
        if (topOfTheDocument) {
            return 'Top Of The Document';
        }
        const customAttrs = node.attrs ? node.attrs['MadCap:custom'] : null;
        const classStr = customAttrs?.class ? '.' + customAttrs.class.replace(' ', '.') : '';
        const idStr = customAttrs?.id ? '#' + customAttrs.id : '';
        if (node.type.spec.linkBucket === 'bookmark') {
            return node.attrs.name;
        }

        return CreateDocumentBucketNodeTitlePipe.schema.getTagDisplayName(node) + classStr + idStr + ' - ' + node.textContent;


    }
}
