import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUsersSelectListModule } from '@portal-core/license-users/components/license-users-select-list/license-users-select-list.module';
import { LicenseUserAsyncModule } from '@portal-core/license-users/pipes/license-user-async/license-user-async.module';
import { ReviewFileUserStatusTextModule } from '@portal-core/reviews/review-file-users/pipes/review-file-user-status-text/review-file-user-status-text.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { ReviewFileSettingsFormComponent } from './review-file-settings-form.component';

@NgModule({
  declarations: [ReviewFileSettingsFormComponent],
  imports: [
    AutocompleteInputModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LicenseUserAsyncModule,
    LicenseUserAvatarModule,
    LicenseUsersSelectListModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    ReviewFileUserStatusTextModule
  ],
  exports: [ReviewFileSettingsFormComponent]
})
export class ReviewFileSettingsFormModule { }
