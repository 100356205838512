import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Type } from '@angular/core';
import { PluginViewEx, ViewPluginOptions } from '@common/prosemirror/plugins/view.plugin';
import { PluginViewBase } from '@portal-core/text-editor/util/plugin-view.base';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Subscription, first } from 'rxjs';

export abstract class ComponentPluginView<T extends PluginViewBase> implements PluginViewEx {
  destroyed: boolean = false;
  editorView: EditorView;
  ready: boolean = false;

  private componentRef: ComponentRef<T>;
  private overlayRef: OverlayRef;
  private portal: ComponentPortal<T>;
  private overlaySubscription: Subscription;

  get component(): T {
    return this.componentRef?.instance;
  }

  constructor(private componentType: Type<T>, private elementOverlayService: ElementOverlayService) { }

  init(editorView: EditorView, options: ViewPluginOptions) {
    // Initialize the dynamic component once the overlay is ready
    this.overlaySubscription = options.overlay$.pipe(
      first(overlayContainerElement => !!overlayContainerElement)
    ).subscribe(overlayContainerElement => {
      this.portal = new ComponentPortal(this.componentType);
      this.overlayRef = this.elementOverlayService.create(overlayContainerElement);
      this.componentRef = this.overlayRef.attach(this.portal);
      this.componentRef.instance.editorOverlayContainer = overlayContainerElement;
      this.componentRef.instance.scrollable = options.scrollable;

      this.editorView = editorView;
      this.ready = true;

      this.onComponentCreated();
      this.update(editorView, null);
    });
  }

  update(editorView: EditorView, prevEditorState: EditorState) {
    if (this.ready) {
      // Update the dynamic component
      this.editorView = editorView;
      this.updateComponent(editorView, prevEditorState);
    }
  }

  destroy() {
    this.destroyed = true;

    this.overlaySubscription?.unsubscribe();

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    // Only trigger the onComponentDestroyed event if the component was initialized in the first place
    if (this.ready) {
      this.onComponentDestroyed();
      this.ready = false;
    }
  }

  onComponentCreated() { }
  onComponentDestroyed() { }

  abstract updateComponent(editorView: EditorView, prevEditorState: EditorState);
}
