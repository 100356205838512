<div class="mc-avatar-tooltip-container" [matTooltip]="filePath + (showTooltipRedirectMessage && isLink() ? '\n\nView file in the Project Files page' : '')" matTooltipClass="mc-tooltip-multiline mc-tooltip-centered" [matTooltipDisabled]="disabledTooltip || !filePath">
  <a *ngIf="showAvatar && project && branchName && !isImageDisabled()" class="mc-avatar-container" [routerLink]="['/', project?.LicenseId, 'projects', project?.Id, 'files', 'blob', encodeURIComponent(branchName), encodeURIComponent(filePath)]">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-documenticon"></mat-icon>
  </a>
  <div *ngIf="showAvatar && (!project || !branchName || isImageDisabled())" class="mc-avatar-container">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-documenticon"></mat-icon>
  </div>

  <a *ngIf="showName && project && branchName && !isNameDisabled()" href="#" class="mc-avatar-name" [routerLink]="['/', project?.LicenseId, 'projects', project?.Id, 'files', 'blob', encodeURIComponent(branchName), encodeURIComponent(filePath)]">{{ filePath }}</a>

  <span *ngIf="showName && (!project || !branchName || isNameDisabled())" class="mc-avatar-name">{{ filePath }}</span>
</div>