import { defaultBlockAt } from '@common/prosemirror/commands/commands.prosemirror';
import { FoundResolvedPosInfo, resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { Fragment, Node, NodeType, Slice } from 'prosemirror-model';
import { Command, EditorState, TextSelection, Transaction } from 'prosemirror-state';
import { ReplaceAroundStep, ReplaceStep, canSplit } from 'prosemirror-transform';

export function insertDropDown(dropdownType: NodeType, headType: NodeType, hotspotType: NodeType, bodyType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, node => !node.isTextblock);
    if (!range) {
      return false;
    }

    const parent: Node = range.parent;
    if (!parent.canReplaceWith(range.startIndex, range.endIndex, dropdownType)) {
      return false;
    }

    if (dispatch) {
      // transform first node in the range into drop-down head around drop-down hotspot
      const firstNode = parent.child(range.startIndex);
      let hotspotNode: Node;

      if (firstNode.content.childCount === 0) {
        hotspotNode = hotspotType.create(null, state.schema.nodes.mcCentralContainer.create(null, state.schema.text('(This is the Drop-down Hotspot)')));
      } else {
        if (firstNode.isTextblock) {
          hotspotNode = hotspotType.create(null, state.schema.nodes.mcCentralContainer.create(null, firstNode.content));
        } else {
          hotspotNode = hotspotType.create(null, firstNode.content);
        }
      }

      const headNode = headType.create(null, hotspotNode);

      // nodes after the first in the range are wrapped into drop-down-body
      let bodyNode: Node;
      if (range.startIndex + 1 === range.endIndex) {
        const p = state.schema.nodes.paragraph.create(null, state.schema.text('(This is the Drop-down text)'));
        bodyNode = bodyType.create(null, p);
      } else {
        const acc: Node[] = [];
        for (let i = range.startIndex + 1; i < range.endIndex; i++) {
          acc.push(parent.child(i));
        }
        bodyNode = bodyType.create(null, acc);
      }

      const dd = dropdownType.create(null, [headNode, bodyNode]);
      dispatch(state.tr.replaceWith(range.start, range.end, dd).scrollIntoView());
    }

    return true;
  };
}

// Finds and removes the highest empty ancestor of the given position. Returns even number of removed tags.
function removeEmptyAncestor(tr: Transaction, pos: number, depth: number): number {
  const $pos = tr.doc.resolve(pos);

  let i = 0;
  for (let d = $pos.depth; i < depth; d--, i++) {
    if ($pos.node(d).nodeSize !== 2 * (i + 1)) {
      break;
    }
  }

  if (i > 0) {
    tr.step(new ReplaceStep(pos - i, pos + i, Slice.empty));
  }

  return 2 * i;
}

export function setDropDownHotspot(headType: NodeType, hotspotType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    if (state.selection.empty) {
      return false;
    }

    const { $from, from, to } = state.selection;

    // get drop-down head that covers the selection
    const headPosInfo = resolvedPosFind($from, node => node.type === headType);
    if (!headPosInfo || to <= headPosInfo.$nodePos.pos || to >= headPosInfo.$nodePos.pos + headPosInfo.node.nodeSize) {
      return false;
    }

    // find the current hotspot and unbind it
    let hotspot: { node: Node, pos: number, parent: Node, index: number } | undefined;
    headPosInfo.node.descendants((node, pos, parent, index) => {
      if (hotspot) {
        return false;
      }
      if (node.type === hotspotType) {
        hotspot = { node: node, pos: pos, parent: parent, index: index };
        return false;
      }
      return true;
    });

    let tr = state.tr;

    if (hotspot) {
      if (!hotspot.parent.canReplace(hotspot.index, hotspot.index + 1, hotspot.node.content)) {
        return false;
      }

      const posBefore = headPosInfo.$nodePos.pos + 1 + hotspot.pos;
      const posAfter = posBefore + hotspot.node.nodeSize;
      tr.step(new ReplaceAroundStep(posBefore, posAfter, posBefore + 1, posAfter - 1, Slice.empty, 0, true));

      // the unbinding process can create adjacent mcCentralContainer nodes; join them
      const r1 = hotspot.node.lastChild;
      const r2 = hotspot.parent.maybeChild(hotspot.index + 1);
      if (r1 && r2 && r1.type.name === r2.type.name && r1.type.name === 'mcCentralContainer') {
        tr.join(posAfter - 2);
      }
      const l1 = hotspot.parent.maybeChild(hotspot.index - 1);
      const l2 = hotspot.node.firstChild;
      if (l1 && l2 && l1.type.name === l2.type.name && l1.type.name === 'mcCentralContainer') {
        tr.join(posBefore);
      }
    }

    // get slice with a selection that we will later wrap into a hotspot element
    const from1 = tr.mapping.map(from);
    const to1 = tr.mapping.map(to);
    const $from1 = tr.doc.resolve(from1);
    const $to1 = tr.doc.resolve(to1);

    // we are looking for common ancestor that doesn't allow inline content
    let openDepth: { start: number, end: number };
    for (let d = $from1.depth; d > 0; d--) {
      if ($from1.start(d) <= to1 && to1 <= $from1.end(d) && !$from1.node(d).inlineContent) {
        openDepth = { start: $from1.depth - d, end: $to1.depth - d };
        break;
      }
    }

    // test that we can cut the selection for later wrapping
    if (!openDepth) {
      return false;
    }
    if (!canSplit(tr.doc, from1, openDepth.start)) {
      return false;
    }
    if (!canSplit(tr.doc, to1, openDepth.end)) {
      return false;
    }

    if (dispatch) {
      // cut the selection and remove empty nodes around boundaries
      tr.split(to1, openDepth.end);
      tr.split(from1, openDepth.start);
      let from2 = from1 + openDepth.start;
      let to2 = to1 + 2 * openDepth.start + openDepth.end;

      // remove empty nodes at the right boundary
      removeEmptyAncestor(tr, to2 + openDepth.end, openDepth.end);
      to2 -= removeEmptyAncestor(tr, to2 - openDepth.end, openDepth.end);
      // remove empty nodes at the left boundary
      to2 -= removeEmptyAncestor(tr, from2 + openDepth.start, openDepth.start);
      const x = removeEmptyAncestor(tr, from2 - openDepth.start, openDepth.start);
      from2 -= x;
      to2 -= x;

      // create the hotspot
      const emptyHotspot = hotspotType.create(hotspot ? hotspot.node.attrs : null, Fragment.empty);
      tr.step(new ReplaceAroundStep(from2, to2, from2, to2, new Slice(Fragment.from(emptyHotspot), 0, 0), 1, true));

      // update selection
      tr.setSelection(TextSelection.between(tr.doc.resolve(from2 + 1), tr.doc.resolve(to2 + 1)));

      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

export function moveFromDropDown(dropdownType: NodeType, bodyType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $cursor } = state.selection as TextSelection;
    if (!$cursor) {
      return false;
    }

    // empty paragraph at the end of a drop-down body and the drop-down body has other children
    if ($cursor.depth > 2 && $cursor.node(-1).type === bodyType && $cursor.node(-2).type === dropdownType &&
      !$cursor.parent.content.size && $cursor.after() === $cursor.end(-1) && $cursor.node(-1).content.childCount > 1) {

      const type = defaultBlockAt($cursor.node(-3).contentMatchAt($cursor.indexAfter(-3)));
      if (!type) {
        return false;
      }

      if (dispatch) {
        // get position after the drop-down
        const p = $cursor.after(-2);
        // insert default block after the drop-down
        let tr = state.tr.insert(p, type.createAndFill());
        // delete the empty paragraph
        tr.delete($cursor.before(), $cursor.after());
        // put cursor inside the created block
        tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(p) - 1));
        dispatch(tr.scrollIntoView());
      }

      return true;
    }

    // cursor is at the beginning of a drop-down and drop-down itself is the first child
    const ddPosInfo: FoundResolvedPosInfo = resolvedPosFind($cursor, node => node.type === dropdownType);
    if (!ddPosInfo) {
      return false;
    }

    const $dd = ddPosInfo.$nodePos;
    if ($cursor.pos - $dd.pos === $cursor.depth - $dd.depth && $dd.index() === 0) {
      const type = defaultBlockAt($dd.parent.contentMatchAt(0));
      if (!type) {
        return false;
      }

      if (dispatch) {
        // get position before the drop-down
        const p = $dd.pos;
        // insert default block before the drop-down
        let tr = state.tr.insert(p, type.createAndFill()!);
        // put cursor inside the created block
        tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(p) - 1))
        dispatch(tr.scrollIntoView())
      }

      return true;
    }

    return false;
  };
}
