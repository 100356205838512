import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * Emulates the look of the mat-select dropdown arrow so that the same arrow can be used in our own controls.
 * This makes it so we have consistent looking dropdown arrows in our forms whether we are using mat-select or own dropdowns.
 */
@Component({
  selector: 'mc-dropdown-arrow',
  templateUrl: './dropdown-arrow.component.html',
  styleUrls: ['./dropdown-arrow.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownArrowComponent { }
