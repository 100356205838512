<div #trigger class="mc-project-file-picker-input-trigger" mcPopupClass="mc-project-file-picker-input-popup" [mcPopupHeight]="popupHeight" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex" (focusout)="onFocusOut()">
  <ng-container *ngIf="!empty || !placeholder; else emptyPlaceholder">
    <mat-chip-list class="mc-project-file-picker-input-chip-list">
      <mat-chip *ngFor="let path of value" class="mc-project-file-picker-input-chip" [removable]="true" [selectable]="false" (mcClick)="onFileChipRemoved(path)" (removed)="onFileChipRemoved(path)" [disabled]="disabled">
        <ng-container *ngIf="itemDirective; else defaultValue" [ngTemplateOutlet]="itemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: isPathWithLeadingSlash ? (path | mcRemoveLeadingSlash) : path  }"></ng-container>
        <ng-template #defaultValue>
          <mc-self-truncating-path class="mc-project-file-picker-input-value" [filePath]="isPathWithLeadingSlash ? (path | mcRemoveLeadingSlash) : path" [showTooltip]="!disabled"></mc-self-truncating-path>
        </ng-template>
        <mat-icon matChipRemove fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <ng-template #emptyPlaceholder>
    <input matInput tabindex="-1" [placeholder]="placeholder" readonly="true" type="text">
  </ng-template>
  <mc-dropdown-arrow class="mc-project-file-picker-input-arrow"></mc-dropdown-arrow>
</div>

<mc-popup #popup (afterOpen)="onPopupOpened()" (closed)="onPopupClosed()">
  <mc-project-files-tree #filesTree class="mc-project-file-picker-input-files-tree" [treeData]="{projectId, branchName}" [fileFilter]="fileFilter" [show404ErrorAsEmptyTree]="true" [rootFolder]="rootFolder" [projectFilesTreeType]="multiple ? 'selection' : 'default'" [disableFolderCheckboxes]="true" [treeClass]="treeClass" (fileSelected)="onFileSelected($event)" (pathFiltersChanged)="onPathFiltersChanged($event)">
    <ng-template *ngFor="let treeItemDirective of treeItemDirectives" [mcProjectFilesTreeItem]="treeItemDirective.templateName" let-item>
      <ng-container [ngTemplateOutlet]="treeItemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </ng-template>
  </mc-project-files-tree>
</mc-popup>