function trimTo(html: string, tag: string): string {
  const tagStartIndex = html.indexOf(`<${tag}`);
  const tagEndIndex = html.lastIndexOf(`</${tag}>`);

  if (tagStartIndex !== -1 && tagEndIndex !== -1) {
    return html.substring(tagStartIndex, tagEndIndex + tag.length + 3);
  }
}

/**
 * Trims all characters before and after the start of the specified tag.
 * Multiple tags can be specified, and the first tag found will be trimmed to.
 * @param html The HTML to trim.
 * @param trimToTags The tags to trim the HTML to. Defaults to the `html` tag.
 * @returns The trimmed HTML.
 */
export function trimHTML(html: string, trimToTags: string[] = ['html']): string {
  for (const tag of trimToTags) {
    const trimmed = trimTo(html, tag);
    if (trimmed) {
      return trimmed;
    }
  }

  return html;
}
