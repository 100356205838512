<mc-select-list [dataListId]="dataListId" listClass="mc-list-selectable" [listControl]="listControl">
  <ng-template mcListItem let-team>
    <mat-list-item>
      <mc-team-avatar [disabled]="true" [team]="team" size="small" [truncate]="true"></mc-team-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>No teams found.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All teams loaded.</p>
  </ng-template>
</mc-select-list>
