import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { ReviewFilePageFilterConfig } from '@portal-core/reviews/review-files/util/review-file-page-filter-config';
import { ListComponent } from '@portal-core/ui/list/components/list/list.component';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { of } from 'rxjs';

@Component({
  selector: 'mc-review-file-list',
  templateUrl: './review-file-list.component.html',
  styleUrls: ['./review-file-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileListComponent extends ListBase<ReviewFile> implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewPackageId?: number;
  @Input() showDeleteButton?: boolean = false;

  @Output() deleteFile: EventEmitter<ReviewFile> = new EventEmitter<ReviewFile>();

  @ViewChild(ListComponent, { static: true }) list: ListComponent;

  filterConfig: ReviewFilePageFilterConfig = new ReviewFilePageFilterConfig(['Name', 'Path', 'Project.Name', 'ProjectId', 'AuthorUser.FullName', 'CreatedOn']);

  listControl: ListControl<ReviewFile> = new ListControl<ReviewFile>(this.reviewFilesService, filter => {
    if (typeof this.licenseId === 'number') {
      return this.reviewFilesService.getReviewFilesPage$(this.licenseId, 0, filter);
    } else {
      return of(null);
    }
  });

  private deletingStates: Map<number, LoadingState<string>> = new Map<number, LoadingState<string>>();

  constructor(
    private errorService: ErrorService,
    private reviewFilesService: ReviewFilesService,
    private pageFilterService: PageFilterService,
    private snackBar: MatSnackBar
  ) {
    super(reviewFilesService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reviewPackageId) {
      this.applyFilter$('review-file-list-package-id', this.pageFilterService.create({
        Id: 'review-file-list-package-id',
        Type: PageFilterGroupType.Number
      }).number('ReviewPackageId', 'int', this.reviewPackageId).value);
    }

    if (changes.licenseId) {
      this.hardReload();
    }
  }

  onDeleteFileClicked(reviewFile: ReviewFile) {
    this.updateDeletingState(reviewFile, true);

    this.reviewFilesService.deleteReviewFile$(reviewFile.ReviewPackageId, reviewFile.Id).subscribe(() => {
      this.updateDeletingState(reviewFile, false);
      this.snackBar.open('The review file has been removed.', 'OK', { duration: 2500 });
      this.deleteFile.emit(reviewFile);
    }, error => {
      // Combine the errors because it will all be displayed in the tooltip
      const generalError = [
        'Unable to remove the review file.',
        ...this.errorService.getErrorMessages(error) ?? []
      ].join(' ');
      this.updateDeletingState(reviewFile, false, generalError, this.errorService.getErrorMessages(error));
    });
  }

  updateDeletingState(reviewFile: ReviewFile, loading: boolean, generalErrorCode: string = null, detailedErrorCodes: string[] = null) {
    if (!this.deletingStates.has(reviewFile.Id)) {
      this.deletingStates.set(reviewFile.Id, new LoadingState<string>());
    }

    this.deletingStates.get(reviewFile.Id).update(loading, generalErrorCode, detailedErrorCodes);
  }

  getDeletingState(reviewFile: ReviewFile): LoadingState<string> {
    return this.deletingStates.has(reviewFile.Id) ? this.deletingStates.get(reviewFile.Id) : null;
  }

  hardReload() {
    this.list.hardReload();
  }
}
