<div #trigger class="mc-build-picker-input-trigger" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex">
  <div class="mc-build-picker-input-value">
    <ng-container *ngIf="!empty">
      <mc-project-avatar *ngIf="value.projectId" compact [disabled]="true" [project]="value.projectId | mcProjectAsync: true | async" size="small" [truncate]="true"></mc-project-avatar>
      <mc-target-avatar *ngIf="value.targetPath" compact [disabled]="true" [branchName]="value.branchName" [project]="value.projectId | mcProjectAsync: true | async" [targetPath]="value.targetPath" size="small" [truncate]="true"></mc-target-avatar>
      <mc-branch-avatar *ngIf="(value.projectId && value.targetPath) || value.branchName" compact [disabled]="true" [branchName]="value.branchName || 'Any Branch'" size="small" [truncate]="true"></mc-branch-avatar>
      <mc-build-avatar *ngIf="value.buildId || value.buildId === 0" compact [disabled]="true" [build]="value.buildId | mcBuildAsync | async" [latest]="value.buildId === 0" size="small" [truncate]="true"></mc-build-avatar>
    </ng-container>
  </div>

  <mc-dropdown-arrow class="mc-build-picker-input-arrow"></mc-dropdown-arrow>
</div>

<mc-popup #popup (closed)="onPopupClosed()" (opened)="onPopupOpened()">
  <mc-build-picker cdkTrapFocus [color]="color" [licenseId]="licenseId" [value]="value" (clear)="onBuildPickerCleared()" (done)="onBuildPickerDone()" (valueChange)="onBuildPickerValueChanged($event)"></mc-build-picker>
</mc-popup>
