<ng-container [ngSwitch]="wrapper">
  <ng-container *ngSwitchCase="'&lt;b&gt;'">
    <b>{{ text }}</b>
  </ng-container>

  <ng-container *ngSwitchCase="'&lt;span style=&quot;color:red&quot;&gt;'">
    <span class="mc-text-warn">{{ text }}</span>
  </ng-container>

  <ng-container *ngSwitchDefault>
    {{ wrapper }}
  </ng-container>
</ng-container>