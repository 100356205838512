import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { Page } from '@common/paged-data/types/page.type';
import { SortDirection } from '@common/paged-data/types/sort-direction.type';
import { distinctEquivalenceUntilChanged } from '@common/util/distinct-equivalence-until-changed.operator';
import { Store } from '@ngxs/store';
import { DataList } from '@portal-core/data/collection/models/data-list.model';
import { GridPage } from '@portal-core/data/collection/models/grid-state.model';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { AddDataListPage, AddGridPage, AddItems, AddItemsToListById, ClearGrid, ClearGridFilters, ClearLists, ClearPagedDataList, DeleteItems, RemoveItems, Reset, ResetGrid, ResetPagedDataList, SetGridFilterByName, SetGridOrder, SetGridPageSize, SetGridVisibleColumns, SetListById, SetPagedDataListFilterByName, SetPagedDataListOrder } from '@portal-core/data/collection/types/collection-state.type';
import { McModel } from '@portal-core/data/common/models/mc-model.model';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { map as _map, filter, find, get, keyBy } from 'lodash';
import { Observable, distinctUntilChanged, map } from 'rxjs';

export abstract class CollectionDataServiceBase<T extends McModel> {
  static idProperty: string = 'Id';

  constructor(protected store: Store, protected stateType: typeof CollectionStateBase) { }

  addDefaultItems$(): Observable<any> {
    return this.addItems$(this.stateType.defaultItems);
  }

  reset$(): Observable<any> {
    return this.store.dispatch(new Reset(this.stateType.source));
  }

  // Items
  getItems<S extends T = T>(): Dictionary<S> {
    return this.store.selectSnapshot(this.stateType.itemsSelector);
  }

  getItems$<S extends T = T>(): Observable<Dictionary<S>> {
    return this.store.select(this.stateType.itemsSelector);
  }

  addItems$<S extends T = T>(items: Dictionary<Partial<S>> | Partial<S>[]): Observable<any> {
    if (Array.isArray(items)) {
      items = keyBy(items, CollectionDataServiceBase.idProperty);
    }
    return this.store.dispatch(new AddItems(this.stateType.source, { items }));
  }

  // Simply an alias for addItem$ but with better semantic meaning when updating items instead of adding them
  updateItems$<S extends T = T>(items: Dictionary<Partial<S>> | Partial<S>[]): Observable<any> {
    return this.addItems$(items);
  }

  removeItems$(items: Dictionary<T> | T[] | ModelId[]): Observable<any> {
    const itemIds = this.mapItemsToIds(items);
    return this.store.dispatch(new RemoveItems(this.stateType.source, { itemIds }));
  }

  deleteItems$(items: Dictionary<T> | T[] | ModelId[]): Observable<any> {
    const itemIds = this.mapItemsToIds(items);
    return this.store.dispatch(new DeleteItems(this.stateType.source, { itemIds }));
  }

  // Item by id
  getItemById<S extends T = T>(itemId: ModelId): S {
    const items = this.store.selectSnapshot(this.stateType.itemsSelector);
    return items ? items[itemId] : undefined;
  }

  getItemById$<S extends T = T>(itemId: ModelId): Observable<S> {
    return this.store.select(this.stateType.itemByIdSelector).pipe(
      map(fn => fn(itemId))
    );
  }

  // Lists
  getListById(listName: string, listId: ModelId): DataList {
    const lists = this.store.selectSnapshot(this.stateType.listsSelector);
    return lists && lists[listName] ? lists[listName][listId] : undefined;
  }

  getListById$(listName: string, listId: ModelId): Observable<DataList> {
    return this.store.select(this.stateType.listByIdSelector).pipe(
      map(fn => fn(listName, listId)),
    );
  }

  setListById$(listName: string, listId: ModelId, items: T[]): Observable<any> {
    return this.store.dispatch(new SetListById(this.stateType.source, { items, listId, listName }));
  }

  addItemsToListById$(listName: string, listId: ModelId, items: T[]): Observable<any> {
    return this.store.dispatch(new AddItemsToListById(this.stateType.source, { items, listId, listName }));
  }

  clearLists$(): Observable<any> {
    return this.store.dispatch(new ClearLists(this.stateType.source));
  }

  /*
   * Paged Data Lists
   */
  getPagedDataListItems$(pagedDataListId: string): Observable<T[]> {
    return this.store.select(this.stateType.pagedDataListItems).pipe(
      map(fn => fn(pagedDataListId)),
      distinctUntilChanged()
    );
  }

  getPagedDataListPageIndex(pagedDataListId: string): number {
    return this.store.selectSnapshot(this.stateType.pagedDataListPageIndex)(pagedDataListId);
  }

  getPagedDataListTotal(pagedDataListId: string): number {
    return this.store.selectSnapshot(this.stateType.pagedDataListTotal)(pagedDataListId);
  }

  getPagedDataListLength(pagedDataListId: string): number {
    return this.store.selectSnapshot(this.stateType.pagedDataListLength)(pagedDataListId);
  }

  getPagedDataListAllPagesLoaded(pagedDataListId: string): boolean {
    return this.store.selectSnapshot(this.stateType.pagedDataListAllPagesLoaded)(pagedDataListId);
  }

  getPagedDataListAllPagesLoaded$(pagedDataListId: string): Observable<boolean> {
    return this.store.select(this.stateType.pagedDataListAllPagesLoaded).pipe(
      map(fn => fn(pagedDataListId)),
      distinctUntilChanged()
    );
  }

  // Filters
  getPagedDataListFilters(pagedDataListId: string): Dictionary<PageFilterGroup> {
    return this.store.selectSnapshot(this.stateType.pagedDataListFilters)(pagedDataListId);
  }

  getPagedDataListFilters$(pagedDataListId: string): Observable<Dictionary<PageFilterGroup>> {
    return this.store.select(this.stateType.pagedDataListFilters).pipe(
      map(fn => fn(pagedDataListId)),
      distinctEquivalenceUntilChanged()
    );
  }

  // Filter by Name
  getPagedDataListFilterByName(pagedDataListId: string, name: string): PageFilterGroup {
    return this.store.selectSnapshot(this.stateType.pagedDataListFilterByName)(pagedDataListId, name);
  }

  getPagedDataListFilterByName$(pagedDataListId: string, name: string): Observable<PageFilterGroup> {
    return this.store.select(this.stateType.pagedDataListFilterByName).pipe(
      map(fn => fn(pagedDataListId, name)),
      distinctEquivalenceUntilChanged()
    );
  }

  setPagedDataListFilterByName$(dataListId: string, name: string, value: PageFilterGroup): Observable<any> {
    return this.store.dispatch(new SetPagedDataListFilterByName(this.stateType.source, { dataListId, name, value }));
  }

  // Order
  getPagedDataListOrder(pagedDataListId: string): PageSort {
    return this.store.selectSnapshot(this.stateType.pagedDataListOrder)(pagedDataListId);
  }

  getPagedDataListOrder$(pagedDataListId: string): Observable<PageSort> {
    return this.store.select(this.stateType.pagedDataListOrder).pipe(
      map(fn => fn(pagedDataListId)),
      distinctEquivalenceUntilChanged()
    );
  }

  setPagedDataListOrder$(dataListId: string, orderBy: string, orderDirection: SortDirection): Observable<any> {
    return this.store.dispatch(new SetPagedDataListOrder(this.stateType.source, {
      dataListId,
      order: {
        by: orderBy,
        dir: orderDirection
      }
    }));
  }

  // Pages
  addDataListPage$(dataListId: string, page: Page): Observable<any> {
    return this.store.dispatch(new AddDataListPage(this.stateType.source, { dataListId, page }));
  }

  resetPagedDataList$(dataListId: string): Observable<any> {
    return this.store.dispatch(new ResetPagedDataList(this.stateType.source, { dataListId }));
  }

  clearPagedDataList$(dataListId: string): Observable<any> {
    return this.store.dispatch(new ClearPagedDataList(this.stateType.source, { dataListId }));
  }

  /*
   * Grids
   */

  /**
   * Returns an observable for a page's items in a grid.
   * @param gridId The id of the grid to get the items for.
   * @param pageIndex The page index to get the items for.
   */
  getGridItems$(gridId: string, pageIndex: number): Observable<T[]> {
    return this.store.select(this.stateType.gridItems).pipe(
      map(fn => fn(gridId, pageIndex)),
      distinctUntilChanged()
    );
  }

  /**
   * Adds a page to a grid.
   * @param gridId The id of the grid to add the page to.
   * @param page The page to be added to the grid.
   */
  addGridPage$(gridId: string, page: Page, pageSize: number): Observable<any> {
    return this.store.dispatch(new AddGridPage(this.stateType.source, { gridId, page, pageSize }));
  }

  /**
   * Returns a GridPage from a grid.
   * @param gridId The id of the grid to add the page to.
   * @param pageIndex The index of the page to get.
   */
  getGridPage(gridId: string, pageIndex: number): GridPage {
    return this.store.selectSnapshot(this.stateType.gridPage)(gridId, pageIndex);
  }

  /**
   * Returns an observable of a GridPage from a grid.
   * @param gridId The id of the grid to add the page to.
   * @param pageIndex The index of the page to get.
   */
  getGridPage$(gridId: string, pageIndex: number): Observable<GridPage> {
    return this.store.select(this.stateType.gridPage).pipe(
      map(fn => fn(gridId, pageIndex)),
      distinctUntilChanged()
    );
  }

  /**
   * Removes all the data and config associated with the grid.
   * @param gridId The id of the grid.
   */
  resetGrid$(gridId: string): Observable<any> {
    return this.store.dispatch(new ResetGrid(this.stateType.source, { gridId }));
  }

  /**
   * Removes the pages from the grid.
   * @param gridId The id of the grid.
   */
  clearGrid$(gridId: string): Observable<any> {
    return this.store.dispatch(new ClearGrid(this.stateType.source, { gridId }));
  }

  /**
   * Returns the cursor for page in a grid.
   * @param gridId The id of the grid to get the cursor for.
   * @param pageIndex The page index to get the cursor for.
   */
  getGridCursor(gridId: string, pageIndex: number): number {
    return this.store.selectSnapshot(this.stateType.gridCursor)(gridId, pageIndex);
  }

  /**
   * Returns the total number of items across every page of the grid.
   * @param gridId The id of the grid.
   */
  getGridTotal(gridId: string): number {
    return this.store.selectSnapshot(this.stateType.gridTotal)(gridId);
  }

  /**
   * Returns an observable of the total number of items across every page of the grid.
   * @param gridId The id of the grid.
   */
  getGridTotal$(gridId: string): Observable<number> {
    return this.store.select(this.stateType.gridTotal).pipe(
      map(fn => fn(gridId)),
      distinctUntilChanged()
    );
  }

  /**
   * Returns the limited total number of items across every page of the grid.
   * The server will sometimes return a limited total number of items for performance reasons.
   * @param gridId The id of the grid.
   */
  getGridLimitedTotal(gridId: string): number {
    return this.store.selectSnapshot(this.stateType.gridLimitedTotal)(gridId);
  }

  /**
   * Returns an observable of the limited total number of items across every page of the grid.
   * The server will sometimes return a limited total number of items for performance reasons.
   * @param gridId The id of the grid.
   */
  getGridLimitedTotal$(gridId: string): Observable<number> {
    return this.store.select(this.stateType.gridLimitedTotal).pipe(
      map(fn => fn(gridId)),
      distinctUntilChanged()
    );
  }

  /**
   * Returns the filters on the grid.
   * @param gridId The id of the grid.
   */
  getGridFilters(gridId: string): Dictionary<PageFilterGroup> {
    return this.store.selectSnapshot(this.stateType.gridFilters)(gridId);
  }

  /**
   * Returns an observable of the filters on the grid.
   * @param gridId The id of the grid.
   */
  getGridFilters$(gridId: string): Observable<Dictionary<PageFilterGroup>> {
    return this.store.select(this.stateType.gridFilters).pipe(
      map(fn => fn(gridId)),
      distinctEquivalenceUntilChanged()
    );
  }

  /**
   * Returns the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   */
  getGridFilterByName(gridId: string, name: string): PageFilterGroup {
    return this.store.selectSnapshot(this.stateType.gridFilterByName)(gridId, name);
  }

  /**
   * Returns an observable of the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   */
  getGridFilterByName$(gridId: string, name: string): Observable<PageFilterGroup> {
    return this.store.select(this.stateType.gridFilterByName).pipe(
      map(fn => fn(gridId, name)),
      distinctEquivalenceUntilChanged()
    );
  }

  /**
   * Sets the filter on the grid with the given name.
   * @param gridId The id of the grid.
   * @param name: The name of the filter.
   * @param value: The value of the filter.
   */
  setGridFilterByName$(gridId: string, name: string, value: PageFilterGroup): Observable<any> {
    return this.store.dispatch(new SetGridFilterByName(this.stateType.source, { gridId, name, value }));
  }

  /**
   * Clear all the filters on the grid.
   * @param gridId The id of the grid.
   */
  clearGridFilters$(gridId: string): Observable<any> {
    return this.store.dispatch(new ClearGridFilters(this.stateType.source, { gridId }));
  }

  /**
   * Returns the page size of the grid.
   * @param gridId The id of the grid.
   */
  getGridPageSize(gridId: string): number {
    return this.store.selectSnapshot(this.stateType.gridPageSize)(gridId);
  }

  /**
   * Returns an observable of the page size of the grid.
   * @param gridId The id of the grid.
   */
  getGridPageSize$(gridId: string): Observable<number> {
    return this.store.select(this.stateType.gridPageSize).pipe(
      map(fn => fn(gridId)),
      distinctUntilChanged()
    );
  }

  /**
   * Sets the page size of the grid.
   * @param gridId The id of the grid.
   * @param pageSize The new page size of the grid.
   */
  setGridPageSize$(gridId: string, pageSize: number): Observable<any> {
    return this.store.dispatch(new SetGridPageSize(this.stateType.source, { gridId, pageSize }));
  }

  /**
   * Returns the sort order on the grid.
   * @param gridId The id of the grid.
   */
  getGridOrder(gridId: string): PageSort {
    return this.store.selectSnapshot(this.stateType.gridOrder)(gridId);
  }

  /**
   * Returns an observable of the sort order on the grid.
   * @param gridId The id of the grid.
   */
  getGridOrder$(gridId: string): Observable<PageSort> {
    return this.store.select(this.stateType.gridOrder).pipe(
      map(fn => fn(gridId)),
      distinctEquivalenceUntilChanged()
    );
  }

  /**
   * Sets the sort order of the grid.
   * @param gridId The id of the grid.
   * @param orderBy The name of the column to order the grid by.
   * @param orderDirection The direction to sort the column by.
   */
  setGridOrder$(gridId: string, orderBy: string, orderDirection: SortDirection): Observable<any> {
    return this.store.dispatch(new SetGridOrder(this.stateType.source, {
      gridId,
      order: {
        by: orderBy,
        dir: orderDirection
      }
    }));
  }

  /**
   * Returns the visible columns for the grid.
   * @param gridId The id of the grid.
   */
  getGridVisibleColumns(gridId: string): string[] {
    return this.store.selectSnapshot(this.stateType.gridVisibleColumns)(gridId);
  }

  /**
   * Returns an observable of the visible columns for the grid.
   * @param gridId The id of the grid.
   */
  getGridVisibleColumns$(gridId: string): Observable<string[]> {
    return this.store.select(this.stateType.gridVisibleColumns).pipe(
      map(fn => fn(gridId)),
      distinctEquivalenceUntilChanged()
    );
  }

  /**
   * Sets the visible columns for the grid.
   * @param gridId The id of the grid.
   * @param visibleColumns An array of column names to be displayed in the grid. The order of the names is used as the order of the columns in the grid.
   */
  setGridVisibleColumns$(gridId: string, visibleColumns: string[]): Observable<any> {
    return this.store.dispatch(new SetGridVisibleColumns(this.stateType.source, { gridId, visibleColumns }));
  }

  // Items by properties
  getItemByProperties(...args): T {
    return find(this.store.selectSnapshot(this.stateType.itemsSelector), item => {
      for (let i = 0; i < args.length; i += 2) {
        if (typeof args[i] === 'function') {
          if (!args[i](item, args[i + 1])) {
            return false;
          }
        } else {
          if (get(item, args[i]) !== args[i + 1]) {
            return false;
          }
        }
      }

      return true;
    });
  }

  getItemByProperties$(...args): Observable<T> {
    return this.store.select(this.stateType.itemByPropertiesSelector).pipe(
      map(fn => fn(...args))
    );
  }

  getItemsByProperties(...args): T[] {
    return filter(this.store.selectSnapshot(this.stateType.itemsSelector), item => {
      for (let i = 0; i < args.length; i += 2) {
        if (typeof args[i] === 'function') {
          if (!args[i](item, args[i + 1])) {
            return false;
          }
        } else {
          if (get(item, args[i]) !== args[i + 1]) {
            return false;
          }
        }
      }

      return true;
    });
  }

  getItemsByProperties$(...args): Observable<T[]> {
    return this.store.select(this.stateType.itemsByPropertiesSelector).pipe(
      map(fn => fn(...args))
    );
  }

  private mapItemsToIds(items: Dictionary<T> | T[] | ModelId[]): ModelId[] {
    return _map(items, (item: T | ModelId) => {
      if (typeof item === 'string' || typeof item === 'number') {
        return item;
      } else {
        return item.Id;
      }
    });
  }
}
