import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-build-deletion-form',
  templateUrl: './build-deletion-form.component.html',
  styleUrls: ['./build-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildDeletionFormComponent {
  @Input() build: Build;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private snackBar: MatSnackBar, private errorService: ErrorService, private buildsService: BuildsService) { }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    this.buildsService.bulkDeleteBuilds$(this.build.ProjectId, [this.build.Id]).subscribe(() => {
      this.savingState.update(false);
      this.snackBar.open('The build has been deleted.', 'OK', { duration: 2500 });
      this.deleted.emit();
    }, error => {
      this.savingState.update(false, 'Unable to delete the build.', this.errorService.getErrorMessages(error));
    });
  }
}
