import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatTreeModule } from '@angular/material/tree';
import { DocumentNodeSelectorInputComponent } from '@portal-core/project-files/components/document-node-selector-input/document-node-selector-input.component';

import { CreateDocumentBucketNodeTitlePipeModule } from '@portal-core/project-files/pipes/create-document-bucket-node-title/create-document-bucket-node-title.module';
import { ClickModule } from '@portal-core/ui/clicking/directives/click/click.module';
import { DropdownArrowModule } from '@portal-core/ui/forms/components/dropdown-arrow/dropdown-arrow.module';
import { PopupModule } from '@portal-core/ui/popup/popup.module';

@NgModule({
    declarations: [
        DocumentNodeSelectorInputComponent
    ],
    imports: [
        CommonModule,
        A11yModule,
        ClickModule,
        DropdownArrowModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        PopupModule,
        MatTreeModule,
        MatRippleModule,
        CreateDocumentBucketNodeTitlePipeModule
    ],
    exports: [
        DocumentNodeSelectorInputComponent
    ]
})
export class DocumentNodeSelectorInputModule { }
