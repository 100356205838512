import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-build-settings-form',
  templateUrl: './build-settings-form.component.html',
  styleUrls: ['./build-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildSettingsFormComponent implements OnChanges {
  @Input() build: Build;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();
  buildSettingsForm: UntypedFormGroup;

  constructor(private snackBar: MatSnackBar, private formBuilder: UntypedFormBuilder, private errorService: ErrorService, private buildsService: BuildsService) {
    // Do this in the constructor so that it is ready for ngOnChanges
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.build) {
      this.resetForm(this.build || {} as Build);
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    this.savingState.update(true);

    this.buildsService.setBuildAsKeep$(this.build.Id, formGroup.value['Keep']).subscribe(() => {
      this.savingState.update(false);
      this.buildSettingsForm.markAsPristine();
      this.snackBar.open('Your settings have been saved.', 'OK', { duration: 2500 });
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to update your build settings.', this.errorService.getErrorMessages(error));
    });
  }

  protected buildForm() {
    this.buildSettingsForm = this.formBuilder.group({
      Keep: new UntypedFormControl(null)
    });
  }

  protected resetForm(build: Build) {
    this.buildSettingsForm.reset({
      Keep: {
        value: build.IsKept || build.LinkedToLiveSite,
        disabled: !build.CanBeKeep || build.LinkedToLiveSite
      }
    });
  }
}
