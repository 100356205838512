import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { UserUsageBarComponent } from './user-usage-bar.component';

@NgModule({
  declarations: [
    UserUsageBarComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [
    UserUsageBarComponent
  ]
})
export class UserUsageBarModule { }
