<mat-toolbar color="secondary">
  <button class="navbar-item mc-toolbar-item-full" color="secondary-invert" mat-button [matMenuTriggerFor]="helpMenu">Help</button>

  <mat-menu #helpMenu="matMenu" [overlapTrigger]="false" class="mc-menu mc-navbar-menu">
    <ng-template matMenuContent>
      <a mat-menu-item href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Help_Documentation" target="_blank">Documentation</a>
      <a mat-menu-item [href]="'https://www.madcapsoftware.com/feedback/bugs.aspx?p=Central&v=' + appVersionAndBuildDate" target="_blank">Submit Feedback</a>
      <a mat-menu-item href="https://www.madcapsoftware.com/privacy/" target="_blank">Privacy Policy</a>
      <a mat-menu-item href="https://www.madcapsoftware.com/company/terms.aspx" target="_blank">Terms of Use</a>
      <a mat-menu-item (click)="onAboutClicked()">About</a>
    </ng-template>
  </mat-menu>
</mat-toolbar>