import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { Page } from '@common/paged-data/types/page.type';
import { AnyBranchId } from '@portal-core/branches/constants/any-branch-id.constant';
import { Branch } from '@portal-core/branches/models/branch.model';
import { BranchesService } from '@portal-core/branches/services/branches.service';
import { BranchesFilterConfig } from '@portal-core/branches/util/branches-filter-config';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { SelectListBase } from '@portal-core/ui/list/util/select-list-base';
import { SelectionListControl } from '@portal-core/ui/list/util/selection-list-control';
import { PageFilterService } from '@portal-core/ui/page-filters/services/page-filter.service';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'mc-branches-select-list',
  templateUrl: './branches-select-list.component.html',
  styleUrls: ['./branches-select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Provide SelectListBase as BranchesSelectList so that this component can work in mc-autocomplete
  providers: [{ provide: SelectListBase, useExisting: forwardRef(() => BranchesSelectListComponent) }]
})
export class BranchesSelectListComponent extends SelectListBase<Branch> implements OnChanges {
  @Input() processStates?: ProcessState[];
  @Input() projectId: number;
  @Input() showAnyBranchOption?: boolean = false;
  @Input() targetPath?: string;
  @Input() type?: 'project' | 'builds' = 'project';

  filterConfig: BranchesFilterConfig = new BranchesFilterConfig(['BranchName', 'Name']);

  listControl: SelectionListControl<Branch> = new SelectionListControl<Branch>(this.branchesService, filter => {
    let fetch$: Observable<Page<Branch>> = of(null);

    if (typeof this.projectId === 'number') {
      if (this.type === 'builds') {
        fetch$ = this.branchesService.getBuildBranchesPageByProjectId$(this.projectId, filter);
      } else {
        fetch$ = this.branchesService.getProjectBranchesPageByProjectId$(this.projectId, filter);
      }
    }

    return fetch$.pipe(
      tap(page => {
        if (this.showAnyBranchOption) {
          if (page.Page === 0) {
            page.Items.splice(0, 0, this.branchesService.getItemById(AnyBranchId));
          }
        }
      })
    );
  }, branch => {
    return branch.Id;
  });

  constructor(private branchesService: BranchesService, private pageFilterService: PageFilterService) {
    super(branchesService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.processStates) {
      this.applyFilter$('branches-select-list-process-states', this.pageFilterService.create({
        Id: 'branches-select-list-process-states',
        Type: PageFilterGroupType.Select
      }).select('LastNotification.ProcessState', this.processStates).value);
    }

    if (changes.targetPath) {
      this.applyFilter$('branches-select-list-target-path', this.pageFilterService.create({
        Id: 'branches-select-list-target-path',
        Type: PageFilterGroupType.String
      }).string('TargetPath', this.targetPath, { FilterType: PageFilterType.Equals }).value);
    }

    if (changes.projectId || changes.showAnyBranchOption || changes.type) {
      this.hardReload();
    }
  }
}
