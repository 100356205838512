import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ReviewFileUsersState } from '@portal-core/reviews/review-file-users/services/review-file-users-data.service';
import { ReviewFilesState } from '@portal-core/reviews/review-files/services/review-files-data.service';
import { ReviewPackageUsersState } from '@portal-core/reviews/review-package-users/services/review-package-users-data.service';
import { ReviewPackagesState } from '@portal-core/reviews/review-packages/services/review-packages-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ReviewFilesState, ReviewFileUsersState, ReviewPackagesState, ReviewPackageUsersState])
  ]
})
export class ReviewsServicesModule { }
