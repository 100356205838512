import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CssIdentifierRegex } from '@portal-core/project-files/constants/css-identifier-regexes.constants';
import { FlareSpecialCharsRegex } from '@portal-core/project-files/constants/file-regexes.constants';

export class McValidators {
  // Email validation pattern: x@x.xx
  static MadCapSubscriptionEmailValidator: ValidatorFn = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  // must start and end with an alphanumeric character
  // can only contain alphanumeric, underscore, hyphen, plus or forward slash characters
  // cannot contain consecutive underscore, hyphen, plus or forward slash characters
  static BranchNameValidator: ValidatorFn = Validators.pattern(/^(?!.*([\-_\/+])\1)[a-zA-Z0-9]([a-zA-Z0-9_\-\/+]*[a-zA-Z0-9])?$/);

  static supportedExternalMultimediaLink(control: AbstractControl): { pattern: boolean } {
    const vimeoPattern = "(?:player.)?vimeo\.com/(?:.*#|.*/)?([0-9]+)";
    const youTubePattern = "youtu(?:\.be|be\.com)/(?:(?:.*)v(?:/|=)|(?:.*/)?)([a-zA-Z0-9-_]+)";
    if (!control.value) {
      return null;
    }
    if (!control.value.match(vimeoPattern) && !control.value.match(youTubePattern)) {
      return { pattern: true };
    }
  }

  static notOnlyWhitespace(control: AbstractControl): { whitespace: boolean } {
    // Required checks this
    if (!control.value) {
      return null;
    }
    const isWhitespace = control.value.trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  static validFilePath(control: AbstractControl): { filePath: boolean } {
    let isValid: boolean;
    const lastIndexOfSlash = control.value.lastIndexOf('/');

    if (lastIndexOfSlash === -1 || (lastIndexOfSlash >= 0 && lastIndexOfSlash + 1 !== control.value.length)) {
      isValid = true;
    } else if (lastIndexOfSlash >= 0) {
      isValid = false;
    }

    return isValid ? null : { 'filePath': true };
  }

  static validFileNames(control: AbstractControl): { special_chars: boolean } {
    const files = control.value as File[];
    if (!Array.isArray(files)) {
      return null;
    }
    const specialCharRegex = new RegExp(FlareSpecialCharsRegex)
    const isInvalid = files.some(file => !specialCharRegex.test(file.name));
    return isInvalid ? { special_chars: true } : null;
  }

  static notOnlyWhiteSpaceFileName(control: AbstractControl): { whitespaceFileName: boolean } {
    let isValid: boolean;
    const testValueSplit = control.value.split('/');
    let fileName = testValueSplit.pop();
    if (fileName?.trim().length) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid ? null : { 'whitespaceFileName': true };
  }

  static cssIdentifier(control: AbstractControl): { css_identifier: boolean } {
    if (control.value === '') {
      return null;
    }
    const cssIdentifierRegex = new RegExp(CssIdentifierRegex)
    const isInvalid = !cssIdentifierRegex.test(control.value);
    return isInvalid ? { css_identifier: true } : null;
  }

  static validContentSecurityPolicyDirectiveValue(control: AbstractControl): { valid: boolean } | null {
    // for more information visit: https://www.w3.org/TR/CSP3/#framework-directive-source-list.
    const schemePart = '([A-Za-z][A-Za-z0-9+-.]*)';
    const schemeSource = `^(${schemePart}:)$`;
    const hostChar = '[A-Za-z0-9-]';
    const hostPart = `((((\\*\\.)?${hostChar}+)(\\.${hostChar}+)*)|\\*)`;
    const portPart = '([0-9]+|\\*)';
    const pathChars = '[A-Za-z0-9-._~!$&\'()*+=%:@]';
    const pathPart = `((/${pathChars}*)+)`;
    const hostSource = `^((${schemePart}://)?${hostPart}(:${portPart})?${pathPart}?)$`;
    const hostSourceRegEx = new RegExp(hostSource);
    const schemeSourceRegEx = new RegExp(schemeSource);
    const isValid = hostSourceRegEx.test(control.value) || schemeSourceRegEx.test(control.value);
    if (!isValid && control.value.length !== 0) {
      return { valid: true };
    }
    return null;
  }
}
