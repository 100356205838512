import { SimpleDomNode } from '@common/html/simple-dom/node';

/**
 * Parses a class string into an array of class names.
 * @param classString The class string to parse.
 * @returns An array of class names.
 */
export function parseClassString(classString: string): string[] {
  if (typeof classString === 'string') {
    return classString.split(' ').map(name => name.trim()).filter(name => name);
  } else {
    return [];
  }
}

/**
 * Represents the contents of the element's class attribute.
 * If the class attribute is not set or empty then this list is empty as well.
 * Using SimpleDomClassList is a convenient alternative to accessing an element's list of classes as a space-delimited string.
 */
export class SimpleDomClassList {
  private classList: string[];

  constructor(private ownerNode: SimpleDomNode, classList: string) {
    this.classList = parseClassString(classList);
  }

  /**
   * Returns an iterator that allows iterating over the classList.
   * @returns An iterator that allows iterating over the classList.
   */
  [Symbol.iterator]() {
    let index = 0;
    return {
      next: () => {
        if (index < this.length) {
          return { value: this.classList[index++], done: false };
        } else {
          return { done: true };
        }
      }
    };
  }

  /**
   * Returns the number of classes in the classList.
   */
  public get length(): number {
    return this.classList.length;
  }

  /**
   * Returns the class list as a space separated string.
   * Equivalent to the value in the class attribute.
   */
  public get value(): string {
    return this.classList.join(' ');
  }

  /**
   * Sets the class list to the specified value.
   */
  public set value(value: string) {
    this.classList = parseClassString(value);
    this.updateAttr();
  }

  /**
   * Adds one or more class names to the classList.
   * Updates the class attribute of the owner node.
   * @param classNames The class names to add.
   */
  public add(...classNames: string[]) {
    for (const className of classNames) {
      if (!this.classList.includes(className)) {
        this.classList.push(className);
      }
    }

    this.updateAttr();
  }

  /**
   * Checks if the classList contains a specific class name.
   * @param className The class name to check for.
   * @returns `true` if the classList contains the class name, otherwise `false`.
   */
  public contains(className: string): boolean {
    return this.classList.includes(className);
  }

  /**
   * Removes one or more class names from the classList.
   * Updates the class attribute of the owner node.
   * @param classNames The class names to remove.
   */
  public remove(...classNames: string[]) {
    for (const className of classNames) {
      const index = this.classList.indexOf(className);
      if (index !== -1) {
        this.classList.splice(index, 1);
      }
    }

    this.updateAttr();
  }

  /**
   * Sets the ownerNode's class attribute to the new class value.
   */
  private updateAttr() {
    const value = this.value;
    if (value) {
      if (this.ownerNode.getAttribute('class') !== value) {
        this.ownerNode.setAttribute('class', value);
      }
    } else {
      this.ownerNode.removeAttribute('class');
    }
  }
}
