import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileStatusTextPipe } from './review-file-status-text.pipe';

@NgModule({
  declarations: [ReviewFileStatusTextPipe],
  imports: [CommonModule],
  exports: [ReviewFileStatusTextPipe],
  providers: [ReviewFileStatusTextPipe]
})
export class ReviewFileStatusTextModule { }
