import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { WebhookSubscriber } from '@portal-core/slack/models/webhook-subscriber.model';

// Webhook Subscriber
@CollectionStateSelectors({
  stateType: WebhookSubscribersState
})
@State<WebhookSubscribersState>({
  name: WebhookSubscribersState.source
})
@Injectable()
export class WebhookSubscribersState extends CollectionStateBase {
  static source = 'WebhookSubscribers';
  getSource(): string {
    return WebhookSubscribersState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlackWebhookSubscribersDataService extends CollectionDataServiceBase<WebhookSubscriber> {
  constructor(protected store: Store) {
    super(store, WebhookSubscribersState);
  }
}
