import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartType, Legend, Tooltip } from 'chart.js';
import { cloneDeep, isEqual } from 'lodash';

Chart.register(Legend, Tooltip);

Chart.defaults.animation = false;
Chart.defaults.plugins.legend.display = false;
Chart.defaults.plugins.tooltip.animation = false;
Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(97, 97, 97, 0.9)'; // To match mat-tooltip
Chart.defaults.plugins.tooltip.boxPadding = 6; // Add some space between the color box and its label
Chart.defaults.plugins.tooltip.caretPadding = 8; // Add some space between the caret and the edge of the bar in horizontal bar charts
Chart.defaults.plugins.tooltip.caretSize = 0; // To match mat-tooltip, hides the caret
Chart.defaults.plugins.tooltip.cornerRadius = 4; // To match mat-tooltip
Chart.defaults.plugins.tooltip.enabled = false;
Chart.defaults.plugins.tooltip.padding = { // To match mat-tooltip
  bottom: 6,
  left: 8,
  right: 8,
  top: 6
};
Chart.defaults.maintainAspectRatio = false;

@Component({
  selector: 'mc-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChartComponent implements OnInit, OnChanges {
  @ViewChild('canvas', { static: true }) canvasRef: ElementRef;
  @Input() type: ChartType;
  @Input() data: ChartData;
  @Input() options: ChartOptions;

  chart: Chart;

  ngOnInit() {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chart) {
      if (changes.type || changes.options) {
        this.chart.destroy();
        this.createChart();
      } else if (changes.data) {
        // Only update the chart if the data values (not references) have changed
        if (!isEqual(changes.data.previousValue, changes.data.currentValue)) {
          this.chart.data = cloneDeep(changes.data.currentValue);  // Clone the data so that an equality check can be done later when the data changes
          this.chart.update();
        }
      }
    }
  }

  protected createChart() {
    this.chart = new Chart(this.canvasRef.nativeElement, {
      type: this.type,
      options: this.options,
      data: cloneDeep(this.data) // Clone the data so that an equality check can be done later when the data changes
    });
  }
}
