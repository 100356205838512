import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseHostMapsService } from '@portal-core/license-host-maps/services/license-host-maps.service';
import { SiteUrl } from '@portal-core/sites/models/site-url.model';
import { SiteUrlsApiService } from '@portal-core/sites/services/site-urls/site-urls-api.service';
import { SiteUrlsDataService } from '@portal-core/sites/services/site-urls/site-urls-data.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteUrlsService extends CollectionServiceBase<SiteUrl> {
  constructor(
    private siteUrlsDataService: SiteUrlsDataService,
    private siteUrlsApiService: SiteUrlsApiService,
    private licenseHostMapsService: LicenseHostMapsService,
    protected dataService: DataService
  ) {
    super(siteUrlsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<SiteUrl> {
    return this.siteUrlsApiService.getSiteUrlById$(itemId);
  }

  getSiteUrlsPageByLicenseId$(licenseId: number, getDeleted: boolean, filter: PageFilter): Observable<Page<SiteUrl>> {
    return this.siteUrlsApiService.getSiteUrlsPageByLicenseId$(licenseId, getDeleted, filter).pipe(
      tap(page => {
        if (Array.isArray(page.Items) && page.Items.length > 0) {
          this.licenseHostMapsService.addItems$(page.Items.map(siteUrl => siteUrl.LicenseHostMap).filter(licenseHostMap => !!licenseHostMap));
        }
      })
    );
  }

  getSiteUrlsByLicenseId$(licenseId: number, getDeleted: boolean, options?: GetDataOptions): Observable<SiteUrl[]> {
    return this.dataService.getDataListItems$<SiteUrl>('SiteUrls', licenseId, this.siteUrlsDataService, {
      fetch: () => this.siteUrlsApiService.getSiteUrlsByLicenseId$(licenseId, getDeleted, null).pipe(
        tap(page => {
          if (Array.isArray(page.Items) && page.Items.length > 0) {
            this.licenseHostMapsService.addItems$(page.Items.map(siteUrl => siteUrl.LicenseHostMap).filter(licenseHostMap => !!licenseHostMap));
          }
        }),
        map(page => {
          if (Array.isArray(page.Items)) {
            return page.Items;
          }
        })
      )
    }, options);
  }
}
