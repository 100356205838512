import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SystemMessageCardModule } from '@portal-core/system-messages/components/system-message-card/system-message-card.module';
import { SystemMessageCardsComponent } from '@portal-core/system-messages/components/system-message-cards/system-message-cards.component';

@NgModule({
  declarations: [
    SystemMessageCardsComponent
  ],
  imports: [
    CommonModule,
    SystemMessageCardModule
  ],
  exports: [
    SystemMessageCardsComponent
  ]
})
export class SystemMessageCardsModule { }
