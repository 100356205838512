import { removeStyles } from '@common/html/util/dom';

/**
 * Normalizes the top level tables in an HTML document.
 * Removes the xmlns attribute from the top level table elements.
 * Removes the `width: 0px;` style from the top level table elements.
 */
export class TablesNormalizer {
  /**
   * Normalizes an HTML document by removing the xmlns attribute from the top level table elements.
   * @param doc The HTML doc to normalize.
   */
  normalize(doc: Document) {
    const body = doc.querySelector('body');
    const childNodesLength = body.childNodes.length;

    for (let i = 0; i < childNodesLength; i += 1) {
      const node = body.childNodes[i] as HTMLElement;

      if (node.nodeName.toLowerCase() === 'table') {
        node.removeAttribute('xmlns');
        removeStyles(node, (name, value) => name === 'width' && value === '0px');
      }
    }
  }
}
