import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { License } from '@portal-core/licenses/models/license.model';

@CollectionStateSelectors({
  stateType: LicensesState
})
@State<LicensesState>({
  name: LicensesState.source
})
@Injectable()
export class LicensesState extends CollectionStateBase {
  static source = 'Licenses';
  getSource(): string {
    return LicensesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicensesDataService extends CollectionDataServiceBase<License> {
  constructor(protected store: Store) {
    super(store, LicensesState);
  }
}
