export enum ProjectFileType {
  Binary = 'binary',
  Code = 'code',
  Flare = 'flare',
  Image = 'image',
  ConditionTagSet = 'conditiontagset',
  VariableSet = 'variableset',
  Stylesheet = 'stylesheet',
  Target = 'target',
}
