import { updateFlareNodeAttrs } from '@common/flare/prosemirror/transform/node';
import { NodeType } from 'prosemirror-model';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

/**
 * Command that either updates or inserts a proxy tag.
 * @param editorState The current state of the editor.
 * @param editorView The current view of the editor.
 * @param dispatch Runs the command on the editor.
 */
export declare type ProxyNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary) => boolean;

/**
 * Makes the editor insert or edit a proxy tag inside of itself.
 * @param proxyType The current proxy type to insert or edit.
 * @return returns a function that handles the insert or edit.
 */
export function insertOrEditProxy(proxyType: NodeType): ProxyNodeCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary): boolean {
    const selection = editorState.selection;
    const $from = selection.$from;

    if (dispatch) {
      const node = selection instanceof NodeSelection && selection.node.type === proxyType ? selection.node : undefined;

      const tr = editorState.tr;
      if (node) {
        updateFlareNodeAttrs(tr, node, $from.pos, attrs);
        tr.setSelection(NodeSelection.create(tr.doc, $from.pos));
      } else {
        tr.replaceSelectionWith(proxyType.create(attrs));
      }

      dispatch(tr.scrollIntoView());
    }

    return true;
  }
}
