const backslashRgx = /\\/g;

/**
 * Normalizes a path by replacing all slashes with forward slashes and removing leading slashes.
 * @param path The path to normalize.
 * @returns The normalized path.
 */
export function normalizePath(path: string): string {
  return path.replace(backslashRgx, '/').replace(/^\//, '');
}

/**
 * Resolves a file's path relative to another files's path.
 * e.g.
 * ```
 * resolvePath('Content/Images/source.html', '../extra/image.png') // Content/extra/image.png
 * ```
 * @param fullFilePath A file's path to resolve against.
 * @param relativeFilePath A file's path to resolve.
 * @returns The resolved path.
 */
export function resolvePath(fullFilePath: string, relativeFilePath: string): string {
  // Normalize the slashes
  const parts = relativeFilePath.replace(backslashRgx, '/').split('/');
  const fullPathParts = fullFilePath.replace(backslashRgx, '/').split('/');

  // Start with a pos at the end of the full path
  let pos = fullPathParts.length - 1;
  let i: number;

  // Resolve the leading '.' and '..' parts of the relative path by moving the pos in the full path
  for (i = 0; i < parts.length; i += 1) {
    const part = parts[i];

    if (part === '..') {
      pos -= 1;
    } else if (part === '.') {
      // Do nothing
    } else {
      break;
    }
  }

  // Return the full path (up to the pos index) joined with the remaining parts of the relative path
  return fullPathParts.splice(0, pos).concat(parts.splice(i)).join('/');
}

const absoluteUrlRgx = new RegExp('^(?:[a-z]+:)?//', 'i');

export function isAbsoluteUrl(url: string): boolean {
  return absoluteUrlRgx.test(url);
}

export function isRelativeUrl(url: string): boolean {
  if (typeof url !== 'string') {
    return false;
  }
  if (isDataUrl(url) || isMailtoUrl(url)) {
    return false;
  }
  return !absoluteUrlRgx.test(url);
}

/**
 * Gets whether the URL is a relative path like 'content/topic.html' or '../topic.html', but not '/content/topic.html'.
 * @param url The URL to check.
 * @returns True if the URL is a relative path, otherwise False.
 */
export function isRelativePath(url: string): boolean {
  if (typeof url !== 'string') {
    return false;
  }
  return isRelativeUrl(url) && !url.replace(backslashRgx, '/').startsWith('/');
}

export function isDataUrl(url: string): boolean {
  return typeof url === 'string' ? url.startsWith('data:') : false;
}

export function isMailtoUrl(url: string): boolean {
  return typeof url === 'string' ? url.startsWith('mailto:') : false;
}

const centralUrlRgx = new RegExp('^central://', 'i');

export function isCentralUrl(url: string): boolean {
  return centralUrlRgx.test(url);
}
/**
 * Resolves a URL using the central protocol to a relative URL that contains the Central instance in the path.
 * @param url The URL to resolve.
 * @param centralInstancePathPrefix The path prefix to the Central instance.
 * @returns The resolved URL.
 */
export function resolveCentralUrl(url: string, centralInstancePathPrefix: string): string {
  return join([centralInstancePathPrefix, url.replace(centralUrlRgx, '')]);
}

export function ensureAbsoluteUrl(url: string, protocol: string = 'https'): string {
  return isRelativeUrl(url) ? `${protocol}://${url}` : url;
}

/**
 * Returns the last portion of a path.
 * @param path The path to get the base name of.
 * @param suffix An optional suffix to remove (e.g. the file extension).
 * @returns The base name of the path.
 */
export function basename(path: string, suffix?: string): string {
  if (typeof path === 'string') {
    return path.substring(path.lastIndexOf('/') + 1, path.length - (suffix && path.endsWith(suffix) ? suffix.length : 0));
  }
}

/**
 * Returns the directory name of a path.
 * @param path The path to get the directory name of.
 * @param includeTrailingSlash Whether to include the trailing slash on the returned directory name.
 * @returns The directory name of the path.
 */
export function dirname(path: string, includeTrailingSlash: boolean = false): string {
  if (typeof path === 'string') {
    const index = path.lastIndexOf('/');
    if (index > -1) {
      return path.substring(0, index + (includeTrailingSlash ? 1 : 0));
    } else {
      return '';
    }
  }
}

/**
 * Returns the extension of a path, from the last occurrence of the . (period) character to the end of the string in the last portion of the path.
 * If there is no . in the last portion of the path, or if there are no . characters other than the first character of the basename of path (see basename), an empty string is returned.
 * e.g.
 * ```
 * extname('index.html') // .html
 * ```
 * @param path The path to get the extension of.
 * @returns The extension of the path.
 */
export function extname(path: string): string {
  if (typeof path === 'string') {
    path = basename(path);
    const index = path.lastIndexOf('.');
    return index > 0 ? path.substring(index) : '';
  }
}

/**
 * Returns the path for a file relative to a directory.
 * e.g.
 * ```
 * relative('Content/Images', 'Content/Images/source.png') // source.png
 * relative('Content/Images', 'Content/Styles/reset.css') // ../Styles/reset.css
 * ```
 * @param from The directory path to be relative to.
 * @param to The file path to make relative.
 * @returns The relative path.
 */
export function relative(from: string, to: string): string {
  if (typeof from === 'string' && typeof to === 'string') {
    const fromParts = normalizePath(from).split('/');
    const toParts = normalizePath(to).split('/');

    // Find the index where the two paths diverge
    let i = 0;
    while (i < fromParts.length && i < toParts.length) {
      if (fromParts[i] !== toParts[i]) {
        break;
      }

      i += 1;
    }

    // Relative path = '..' for each part in common + the remaining toParts
    return Array(fromParts.length - i).fill('..').concat(toParts.splice(i)).join('/');
  }
}

/**
 * Joins path parts into one path correctly handling leading and trailing slashes on the parts.
 * Assumes path separator is a forward slash (/).
 * This algorithm is modified from https://stackoverflow.com/a/55142565/1267725
 * @param paths The path parts to join together.
 */
export function join(paths: (string | number)[]): string {
  if (!Array.isArray(paths)) {
    return;
  }

  // Normalize each path part
  paths = paths.map((path, index) => {
    path = path.toString(); // Convert any number parts to strings

    if (index) {
      path = path.replace(/^\//, '');
    }
    if (index !== paths.length - 1) {
      path = path.replace(/\/$/, '');
    }

    return path;
  });

  // Filter out empty path parts first
  return paths.filter(path => !!path).join('/');
}

/**
 * Used to get a new relative link value, e.g. when a file is moved/copied from one folder to another.
 * ```
 * tryUpdateRelativeLink('Images/source.png', 'Content/Topic.htm', 'Content/Subfolder') // ../Images/source.png
 * tryUpdateRelativeLink('Images/source.png', '/Content/Subfolder/Topic.htm', 'Content') // Subfolder/Images/source.png
 * ```
 * @param link The relative link that resolved against fromFilePath to be resolved against toFolderPath.
 * @param fromFilePath The path of the original file that contains the specified resolved link.
 * @param toFolderPath The new folder for the file.
 * @returns A new relative link value.
 * If the link is not a relative path then the function returns the link as is.
 * If the resolved link is an external path then the function returns undefined value.
 */
export function tryUpdateRelativeLink(link: string, fromFilePath: string, toFolderPath: string): string {
  if (typeof link === 'string' && typeof fromFilePath === 'string' && typeof toFolderPath === 'string') {
    if (!isRelativePath(link))
      return link;
    fromFilePath = normalizePath(fromFilePath);
    const resolvedLink = resolvePath(fromFilePath, link);
    if (resolvedLink && !resolvedLink.startsWith('../')) {
      const relativeLink = relative(toFolderPath, resolvedLink);
      return relativeLink;
    }
  }
}

export function addLeadingSlash(path: string): string {
  if (!path || typeof path !== 'string')
    return path;
  return path.startsWith('/') ? path : `/${path}`
}

export function removeLeadingSlash(path: string): string {
  if (!path || typeof path !== 'string')
    return path;
  return !path.startsWith('/') ? path : path.substring(1);
}
