import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { RangeModule } from '@portal-core/general/pipes/range/range.module';
import { ProjectFilesTreeItemModule } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.module';
import { ProjectFilesTreeFileComponent } from './project-files-tree-file.component';

@NgModule({
  declarations: [
    ProjectFilesTreeFileComponent
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    ProjectFilesTreeItemModule,
    RangeModule,
    RouterModule
  ],
  exports: [
    ProjectFilesTreeFileComponent
  ]
})
export class ProjectFilesTreeFileModule { }
