import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getVersionAndBuildDate$(): Observable<string> {
    return this.http.get<string>(`${this.apiService.centralApiBaseUri}/api/common/centralVersion`);
  }
}
