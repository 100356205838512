import { FoundNodeInfo, getLowestLevelContainer } from '@common/prosemirror/model/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { createBookmark, replaceSelectionWithLink } from '@common/prosemirror/transform/link';
import { NodeType, ProseMirrorNode } from 'prosemirror-model';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

/**
  * Command that either inserts or edits a link into the editor.
  * @param editorState The current state of the editor.
  * @param dispatch A function to dispatch transactions.
  * @param editorView The current editor view.
  * @param attrs Additional attributes for the link node.
  * @param content The content of the link node.
  * @param linkToNode Information about the node to link to.
  * @param foundBookmark Indicates whether a bookmark was found.
  * @returns Returns true if the operation was successful, false otherwise.
  */
export declare type LinkNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary, content?: string | ProseMirrorNode, linkToNode?: FoundNodeInfo, foundBookmark?: boolean) => boolean;

/**
 * Inserts or edits a link node in the editor state.
 * @param linkType The type of node representing a link.
 * @param bookmarkType The type of node representing a bookmark.
 * @returns A function that takes editor state and other optional parameters to perform the insertion or editing of a link node.
 */
export function insertOrEditLink(linkType: NodeType, bookmarkType: NodeType): LinkNodeCommand {
  return function (editorState: EditorState, dispatch?: ProsemirrorDispatcher, editorView?: EditorView, attrs?: Dictionary, content?: string | ProseMirrorNode, linkToNode?: FoundNodeInfo, foundBookmark?: boolean): boolean {
    const selection = editorState.selection;
    const $from = selection.$from;
    const index = $from.index();

    // get LowestLevelContainer returns a body or list tag if there is more than one siblings selected - do not allow these selections
    const contentSlice = editorState.selection?.content();
    const contentNode = contentSlice.size ? getLowestLevelContainer(contentSlice) : null;

    if (!$from.parent.canReplaceWith(index, index, linkType) || contentNode?.type.spec.list || contentNode?.type.name === 'body') {
      return false;
    }

    let tr = editorState.tr;

    if (dispatch) {
      let editLinkNode = null;
      let startPos = null;
      let nodeSelection = false;

      if (selection instanceof NodeSelection && selection.node.type === linkType) {
        editLinkNode = selection.node;
        startPos = selection.$from.pos;
        nodeSelection = true;
      } else {
        const editLinkInfo = resolvedPosFind($from, node => node.type.name === linkType.name);
        editLinkNode = editLinkInfo?.node;
        startPos = editLinkInfo?.$nodePos.pos;
      }

      if (linkToNode) {
        // commands that require bookmark, to not break the positions we insert what should be on the bottom first.
        if ($from.pos > linkToNode?.pos) {
          tr = replaceSelectionWithLink(tr, linkType, startPos, content, attrs, editLinkNode);
          if (nodeSelection) {
            tr.setSelection(NodeSelection.create(tr.doc, $from.pos))
          }
          createBookmark(tr, bookmarkType, foundBookmark, linkToNode, attrs);
        } else {
          createBookmark(tr, bookmarkType, foundBookmark, linkToNode, attrs);
          tr = replaceSelectionWithLink(tr, linkType, startPos, content, attrs, editLinkNode);
          if (nodeSelection) {
            tr.setSelection(NodeSelection.create(tr.doc, $from.pos));
          }
        }
      } else {
        tr = replaceSelectionWithLink(tr, linkType, startPos, content, attrs, editLinkNode);
        if (nodeSelection) {
          tr.setSelection(NodeSelection.create(tr.doc, $from.pos))
        }
      }

      dispatch(tr.scrollIntoView());
    }
    return true;
  }

}
