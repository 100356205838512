import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileAvatarTooltipPipe } from './review-file-avatar-tooltip.pipe';

@NgModule({
  declarations: [ReviewFileAvatarTooltipPipe],
  imports: [CommonModule],
  exports: [ReviewFileAvatarTooltipPipe]
})
export class ReviewFileAvatarTooltipModule { }
