import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageNodeViewModule } from '@portal-core/text-editor/components/image-node-view/image-node-view.module';
import { MultipleChoiceItemNodeViewModule } from '@portal-core/text-editor/components/multiple-choice-item-node-view/multiple-choice-item-node-view.module';
import { NodeToolbarPopupPluginViewModule } from '@portal-core/text-editor/components/node-toolbar-popup-plugin-view/node-toolbar-popup-plugin-view.module';
import { SnippetNodeViewModule } from '@portal-core/text-editor/components/snippet-node-view/snippet-node-view.module';
import { VariableNodeViewModule } from '@portal-core/text-editor/components/variable-node-view/variable-node-view.module';
import { DynamicIoModule, DynamicModule } from 'ng-dynamic-component';
import { DynamicViewComponent } from './dynamic-view.component';

@NgModule({
  declarations: [
    DynamicViewComponent
  ],
  imports: [
    CommonModule,
    ImageNodeViewModule,
    DynamicModule,
    DynamicIoModule,
    MultipleChoiceItemNodeViewModule,
    NodeToolbarPopupPluginViewModule,
    SnippetNodeViewModule,
    VariableNodeViewModule
  ],
  exports: [
    DynamicViewComponent
  ]
})
export class DynamicViewModule { }
