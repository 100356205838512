import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EnvService } from '@portal-core/general/services/env.service';

export interface CutCopyPasteHelpDialogData {
  action: 'copy' | 'cut' | 'paste';
}

@Component({
  selector: 'mc-cut-copy-paste-help-dialog',
  templateUrl: './cut-copy-paste-help-dialog.component.html',
  styleUrls: ['./cut-copy-paste-help-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CutCopyPasteHelpDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '42rem'
  };

  action: 'copy' | 'cut' | 'paste';
  actionDisplayText: string;
  useCommandIcon: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: CutCopyPasteHelpDialogData, private envService: EnvService) { }

  ngOnInit() {
    this.action = this.data.action;

    switch (this.action) {
      case 'copy':
        this.actionDisplayText = 'copying';
        break;
      case 'cut':
        this.actionDisplayText = 'cutting';
        break;
      case 'paste':
        this.actionDisplayText = 'pasting';
        break;
    }

    this.useCommandIcon = this.envService.isMac;
  }
}
