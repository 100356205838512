import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageSort } from '@common/paged-data/types/page-sort.type';
import { Selector } from '@ngxs/store';
import { CollectionStateSelectorsOptions } from '@portal-core/data/collection/models/collection-state-selectors-options.model';
import { DataList } from '@portal-core/data/collection/models/data-list.model';
import { GridPage } from '@portal-core/data/collection/models/grid-state.model';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { filter, find, get } from 'lodash';

export function CollectionStateSelectors(options: CollectionStateSelectorsOptions) {
  return function (constructor) {
    class Selectors {
      @Selector([options.stateType as any])
      static itemsSelector(state: CollectionStateBase): Dictionary {
        return state.items;
      }

      @Selector([options.stateType as any])
      static itemByIdSelector(state: CollectionStateBase): (itemId: ModelId) => any {
        return (itemId: ModelId): any => {
          return state.items ? state.items[itemId] : undefined;
        };
      }

      @Selector([options.stateType as any])
      static itemByPropertiesSelector(state: CollectionStateBase): (...args) => any {
        return (...args): any => {
          return find(state.items, item => {
            for (let i = 0; i < args.length; i += 2) {
              if (typeof args[i] === 'function') {
                if (!args[i](item, args[i + 1])) {
                  return false;
                }
              } else {
                if (get(item, args[i]) !== args[i + 1]) {
                  return false;
                }
              }
            }

            return true;
          });
        };
      }

      @Selector([options.stateType as any])
      static itemsByPropertiesSelector(state: CollectionStateBase): (...args) => any {
        return (...args): any => {
          return filter(state.items, item => {
            for (let i = 0; i < args.length; i += 2) {
              if (typeof args[i] === 'function') {
                if (!args[i](item, args[i + 1])) {
                  return false;
                }
              } else {
                if (get(item, args[i]) !== args[i + 1]) {
                  return false;
                }
              }
            }

            return true;
          });
        };
      }

      @Selector([options.stateType as any])
      static listsSelector(state: CollectionStateBase): Dictionary<Dictionary<DataList>> {
        return state.lists;
      }

      @Selector([options.stateType as any])
      static listByIdSelector(state: CollectionStateBase): (listName: string, listId: ModelId) => DataList {
        return (listName: string, listId: ModelId): DataList => {
          return state.lists && state.lists[listName] ? state.lists[listName][listId] : undefined;
        };
      }

      /*
       * Paged Data Lists
       */
      @Selector([options.stateType as any])
      static pagedDataListItems(state: CollectionStateBase): (pagedDataListId: string) => any[] {
        return (pagedDataListId: string) => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          if (pagedDataList) {
            const itemIds = pagedDataList.itemIds;
            const items = state.items;

            if (items && Array.isArray(itemIds)) {
              return itemIds.map(itemId => items[itemId]).filter(item => !!item);
            }
          }
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListLength(state: CollectionStateBase): (pagedDataListId: string) => number {
        return (pagedDataListId: string): number => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          if (pagedDataList) {
            return Array.isArray(pagedDataList.itemIds) ? pagedDataList.itemIds.length : undefined;
          }
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListPageIndex(state: CollectionStateBase): (pagedDataListId: string) => number {
        return (pagedDataListId: string): number => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          return pagedDataList ? pagedDataList.pageIndex : undefined;
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListTotal(state: CollectionStateBase): (pagedDataListId: string) => number {
        return (pagedDataListId: string): number => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          return pagedDataList ? pagedDataList.total : undefined;
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListAllPagesLoaded(state: CollectionStateBase): (pagedDataListId: string) => boolean {
        return (pagedDataListId: string): boolean => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          return pagedDataList ? pagedDataList.allPagesLoaded : undefined;
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListFilters(state: CollectionStateBase): (pagedDataListId: string) => Dictionary<PageFilterGroup> {
        return (pagedDataListId: string): Dictionary<PageFilterGroup> => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          if (pagedDataList && pagedDataList.config) {
            return pagedDataList.config.filters;
          }
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListFilterByName(state: CollectionStateBase): (pagedDataListId: string, name: string) => PageFilterGroup {
        return (pagedDataListId: string, name: string) => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          if (pagedDataList && pagedDataList.config && pagedDataList.config.filters) {
            return pagedDataList.config.filters[name];
          }
        };
      }

      @Selector([options.stateType as any])
      static pagedDataListOrder(state: CollectionStateBase): (pagedDataListId: string) => PageSort {
        return (pagedDataListId: string): PageSort => {
          const pagedDataList = state && state.pagedLists ? state.pagedLists[pagedDataListId] : undefined;

          if (pagedDataList && pagedDataList.config) {
            return pagedDataList.config.order;
          }
        };
      }

      /*
       * Grids
       */

      /** A selector for getting the page items in a grid. */
      @Selector([options.stateType as any])
      static gridItems(state: CollectionStateBase): (gridId: string, pageIndex: number) => any[] {
        return (gridId: string, pageIndex: number): any[] => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.pages) {
            const page = grid.pages[pageIndex];

            if (page) {
              const itemIds = page.itemIds;
              const items = state.items;

              if (items && Array.isArray(itemIds)) {
                return itemIds.map(itemId => items[itemId]).filter(item => !!item);
              }
            }
          }
        };
      }

      /** A selector for getting the page in a grid. */
      @Selector([options.stateType as any])
      static gridPage(state: CollectionStateBase): (gridId: string, pageIndex: number) => GridPage {
        return (gridId: string, pageIndex: number): GridPage => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.pages) {
            return grid.pages[pageIndex];
          }
        };
      }

      /** A selector for getting the cursor in a grid. */
      @Selector([options.stateType as any])
      static gridCursor(state: CollectionStateBase): (gridId: string, pageIndex: number) => number {
        return (gridId: string, pageIndex: number): number => {
          // Typically this selector is called with the preceding page index to get the cursor of the next page.
          // This means -1 can be passed in to get the cursor of the first page.
          // The first page cursor is always 0 and not stored in a page.
          if (pageIndex < 0) {
            return 0;
          }

          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.pages) {
            return grid.pages[pageIndex].cursor;
          }
        };
      }

      /** A selector for getting the total number of items across all pages in a grid. */
      @Selector([options.stateType as any])
      static gridTotal(state: CollectionStateBase): (gridId: string) => number {
        return (gridId: string): number => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid) {
            return grid.total;
          }
        };
      }

      /** A selector for getting the limited total number of items across every page of the grid. */
      @Selector([options.stateType as any])
      static gridLimitedTotal(state: CollectionStateBase): (gridId: string) => number {
        return (gridId: string): number => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid) {
            return grid.limitedTotal;
          }
        };
      }

      /** A selector for getting the filters on a grid. */
      @Selector([options.stateType as any])
      static gridFilters(state: CollectionStateBase): (gridId: string) => Dictionary<PageFilterGroup> {
        return (gridId: string): Dictionary<PageFilterGroup> => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.config) {
            return grid.config.filters;
          }
        };
      }

      /** A selector for getting a filter by name on a grid. */
      @Selector([options.stateType as any])
      static gridFilterByName(state: CollectionStateBase): (gridId: string, name: string) => PageFilterGroup {
        return (gridId: string, name: string) => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.config && grid.config.filters) {
            return grid.config.filters[name];
          }
        };
      }

      /** A selector for getting the page size of a grid. */
      @Selector([options.stateType as any])
      static gridPageSize(state: CollectionStateBase): (gridId: string) => number {
        return (gridId: string): number => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.config && typeof grid.config.pageSize === 'number') {
            return grid.config.pageSize;
          }

          // The default grid page size
          return 25;
        };
      }

      /** A selector for getting the sort order of a grid. */
      @Selector([options.stateType as any])
      static gridOrder(state: CollectionStateBase): (gridId: string) => PageSort {
        return (gridId: string): PageSort => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.config) {
            return grid.config.order;
          }
        };
      }

      /** A selector for getting the visible columns of a grid. */
      @Selector([options.stateType as any])
      static gridVisibleColumns(state: CollectionStateBase): (gridId: string) => string[] {
        return (gridId: string): string[] => {
          const grid = state && state.grids ? state.grids[gridId] : undefined;

          if (grid && grid.config) {
            return grid.config.visibleColumns;
          }
        };
      }
    }

    constructor.itemsSelector = Selectors.itemsSelector;
    constructor.itemByIdSelector = Selectors.itemByIdSelector;
    constructor.itemByPropertiesSelector = Selectors.itemByPropertiesSelector;
    constructor.itemsByPropertiesSelector = Selectors.itemsByPropertiesSelector;
    constructor.listsSelector = Selectors.listsSelector;
    constructor.listByIdSelector = Selectors.listByIdSelector;
    constructor.pagedDataListItems = Selectors.pagedDataListItems;
    constructor.pagedDataListLength = Selectors.pagedDataListLength;
    constructor.pagedDataListPageIndex = Selectors.pagedDataListPageIndex;
    constructor.pagedDataListTotal = Selectors.pagedDataListTotal;
    constructor.pagedDataListAllPagesLoaded = Selectors.pagedDataListAllPagesLoaded;
    constructor.pagedDataListFilters = Selectors.pagedDataListFilters;
    constructor.pagedDataListFilterByName = Selectors.pagedDataListFilterByName;
    constructor.pagedDataListOrder = Selectors.pagedDataListOrder;
    constructor.gridItems = Selectors.gridItems;
    constructor.gridPage = Selectors.gridPage;
    constructor.gridCursor = Selectors.gridCursor;
    constructor.gridTotal = Selectors.gridTotal;
    constructor.gridLimitedTotal = Selectors.gridLimitedTotal;
    constructor.gridFilters = Selectors.gridFilters;
    constructor.gridFilterByName = Selectors.gridFilterByName;
    constructor.gridPageSize = Selectors.gridPageSize;
    constructor.gridOrder = Selectors.gridOrder;
    constructor.gridVisibleColumns = Selectors.gridVisibleColumns;

    return constructor;
  };
}
