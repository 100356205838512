import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectChecklist } from '@portal-core/project-checklists/models/project-checklist.model';

@CollectionStateSelectors({
  stateType: ProjectChecklistsState
})
@State<ProjectChecklistsState>({
  name: ProjectChecklistsState.source
})
@Injectable()
export class ProjectChecklistsState extends CollectionStateBase {
  static source = 'ProjectChecklists';
  getSource(): string {
    return ProjectChecklistsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectChecklistsDataService extends CollectionDataServiceBase<ProjectChecklist> {
  constructor(protected store: Store) {
    super(store, ProjectChecklistsState);
  }
}
