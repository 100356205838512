import { Directive, HostBinding, Input } from '@angular/core';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class AvatarBase {
  @Input() disabled?: boolean = false;
  @Input() disabledName?: boolean = false;
  @Input() disabledTooltip?: boolean = false;
  @Input() inline?: boolean = false;
  @Input() showAvatar?: boolean = true;
  @Input() showName?: boolean = true;
  @Input() size?: 'x-small' | 'small' | 'normal' | 'large' = 'normal';
  @Input() truncate?: boolean = false;

  @HostBinding('class.mc-avatar-base') _avatarBaseClassName: boolean = true;

  @HostBinding('class.mc-avatar-link')
  get _isLink(): boolean {
    return this.isLink();
  }
  @HostBinding('class.mc-avatar-disabled')
  get _disabled(): boolean {
    return this.disabled;
  }
  @HostBinding('class.mc-avatar-name-disabled')
  get _disabledName(): boolean {
    return this.disabledName;
  }
  @HostBinding('class.mc-avatar-tooltip-disabled')
  get _disabledTooltip(): boolean {
    return this.disabledTooltip;
  }
  @HostBinding('class.mc-avatar-has-icon')
  get _hasAvatarIcon(): boolean {
    return this.hasAvatarIcon();
  }
  @HostBinding('class.mc-avatar-has-image')
  get _hasAvatarImage(): boolean {
    return this.hasAvatarImage();
  }
  @HostBinding('class.mc-avatar-has-svg')
  get _hasAvatarSVG(): boolean {
    return this.hasAvatarSVG();
  }
  @HostBinding('class.mc-avatar-inline')
  get _inlineAvatar(): boolean {
    return this.inline;
  }
  @HostBinding('class.mc-avatar-empty')
  get _isEmpty(): boolean {
    return this.isEmpty();
  }
  @HostBinding('class.mc-avatar-deleted')
  get _isDeleted(): boolean {
    return this.isDeleted();
  }
  @HostBinding('class')
  get _size(): string {
    return this.size ? 'mc-avatar-' + this.size : 'mc-avatar-normal';
  }
  @HostBinding('class.mc-avatar-truncate-name')
  get _truncateName(): boolean {
    return this.truncate;
  }

  onAvatarClicked() { }

  /** Emulates the avatar being clicked. */
  click() {
    this.onAvatarClicked();
  }

  isImageDisabled(): boolean {
    return this.disabled || !this.hasPermission() || this.isEmpty() || this.isDeleted();
  }

  isNameDisabled(): boolean {
    return this.disabled || this.disabledName || !this.hasPermission() || this.isEmpty() || this.isDeleted();
  }

  isLink(): boolean {
    return !this.disabled && !this.isDeleted();
  }

  hasAvatarSVG(): boolean {
    return false;
  }

  hasAvatarIcon(): boolean {
    return false;
  }

  abstract hasAvatarImage(): boolean;
  abstract hasPermission(): boolean;
  abstract isDeleted(): boolean;
  abstract isEmpty(): boolean;
}
