import { NodeType } from '@common/html/enums/node-type.enum';
import { SimpleDomClassList } from '@common/html/simple-dom/class-list';
import { SimpleDomCSSStyleDeclaration } from '@common/html/simple-dom/css-style-declaration';
import { parseFragmentFromString } from '@common/html/simple-dom/parse';
import { escapeXML, escapeXMLAttribute } from '@common/html/util/escape';
import { voidTagSet } from '@common/html/util/xml';
import { CachedAccessor } from '@common/util/cached-accessor.decorator';
import { escapeAttribute, escapeText } from 'entities';

interface SimpleDomAttr {
  nodeName: string;
  nodeType: NodeType;
  nodeValue: string;
}

export class SimpleDomNode {
  public attributes: SimpleDomAttr[] = [];
  public nodeValue: string = null;
  public parentNode: SimpleDomNode = null;
  public childNodes: SimpleDomNode[] = [];

  /** The node's position in its parent's childNodes array. This is non-standard and used to speed up some operations. */
  private index: number;

  constructor(public ownerDocument: SimpleDomDocument, public nodeName: string, public nodeType: NodeType) { }

  /** Returns an array of all the node's children that are element nodes. */
  public get children(): SimpleDomNode[] {
    return this.childNodes.filter(child => child.nodeType === NodeType.ELEMENT_NODE);
  }

  /** Returns the node's first child, or null if there are no children. */
  public get firstChild(): SimpleDomNode | null {
    return this.childNodes[0] ?? null;
  }

  /** Returns the node's last child, or null if there are no children. */
  public get lastChild(): SimpleDomNode | null {
    return this.childNodes[this.childNodes.length - 1] ?? null;
  }

  /** Returns the node immediately after this node in its parent's childNodes list, or null if this node is the last in that list. */
  public get nextSibling(): SimpleDomNode | null {
    return this.parentNode.childNodes[this.index + 1] ?? null;
  }

  /** Returns the node immediately before this node in its parent's childNodes list, or null if this node is the first in that list. */
  public get previousSibling(): SimpleDomNode | null {
    return this.parentNode.childNodes[this.index - 1] ?? null;
  }

  /** Returns the Element immediately before this node in its parent's children list, or null if this node is the first element in that list. */
  public get previousElementSibling(): SimpleDomNode | null {
    for (let i = this.index - 1; i >= 0; i -= 1) {
      const sibling = this.parentNode.childNodes[i];
      if (sibling.nodeType === NodeType.ELEMENT_NODE) {
        return sibling;
      }
    }

    return null;
  }

  /** Returns the serialized HTML fragment for the node's descendants. */
  public get innerHTML(): string {
    return this.childNodes.map(child => child.outerHTML).join('');
  }

  /**
   * Sets the HTML which will be parsed to replace the DOM tree within the node.
   * @param html The HTML to parse and set as the node's children.
   */
  public set innerHTML(html: string) {
    this.childNodes.forEach(child => {
      // Clear the child's parent and index
      child.parentNode = null;
      child.index = null;
    });

    this.childNodes = parseFragmentFromString(html, this.ownerDocument.contentType).childNodes;
    this.childNodes.forEach((child, index) => {
      child.parentNode = this;
      child.index = index;
    });
  }

  /** Returns the serialized HTML fragment for the node and its descendants. */
  public get outerHTML(): string {
    // Check for a custom implementation of outerHTML
    if (this.ownerDocument.hasRegisteredElement(this.nodeName)) {
      return this.ownerDocument.getRegisteredElement(this.nodeName).outerHTML(this);
    }

    switch (this.nodeType) {
      case NodeType.CDATA_SECTION_NODE:
        return `<![CDATA[${this.nodeValue}]]>`;
      case NodeType.COMMENT_NODE:
        return `<!--${this.nodeValue}-->`;
      case NodeType.TEXT_NODE:
        if (this.parentNode?.nodeName === 'script' || this.parentNode?.nodeName === 'style') {
          return this.nodeValue;
        } else {
          return this.ownerDocument.contentType === 'text/xml' ? escapeXML(this.nodeValue) : escapeText(this.nodeValue);
        }
      default: {
        const isVoid = this.ownerDocument.isVoidTag(this.nodeName);
        return `<${this.nodeName}${this.attributes.map(attr => ` ${attr.nodeName}="${this.ownerDocument.contentType === 'text/xml' ? escapeXMLAttribute(attr.nodeValue) : escapeAttribute(attr.nodeValue)}"`).join('')}${isVoid && this.ownerDocument.contentType === 'text/xml' ? ' /' : ''}>${isVoid ? '' : `${this.innerHTML}</${this.nodeName}>`}`;
      }
    }
  }

  /** Returns the text content of the node and its descendants. */
  public get textContent(): string {
    switch (this.nodeType) {
      case NodeType.CDATA_SECTION_NODE:
      case NodeType.COMMENT_NODE:
      case NodeType.TEXT_NODE:
        return this.nodeValue;
      case NodeType.DOCUMENT_NODE:
        return null;
      default:
        return this.getElementTextContent(this);
    }
  }

  /**
   * Sets the text content of the node.
   * Removes all children and replaces them with a single Text node with the given text value.
   * @param text The text content to set.
   */
  public set textContent(text: string) {
    this.childNodes.forEach(child => {
      // Clear the child's parent and index
      child.parentNode = null;
      child.index = null;
    });

    const child = this.ownerDocument.createTextNode(text);
    child.parentNode = this;
    child.index = 0;
    this.childNodes = [child];
  }

  /**
   * Returns the class list for the node.
   * Only returns a value for element nodes.
   */
  @CachedAccessor()
  public get classList(): SimpleDomClassList {
    if (this.nodeType === NodeType.ELEMENT_NODE) {
      return new SimpleDomClassList(this, this.getAttribute('class'));
    }
  }

  /**
   * Returns the inline style object for the node.
   * Only returns a value for element nodes.
   */
  @CachedAccessor()
  public get style(): SimpleDomCSSStyleDeclaration {
    if (this.nodeType === NodeType.ELEMENT_NODE) {
      return new SimpleDomCSSStyleDeclaration(this, this.getAttribute('style'));
    }
  }

  public append(...nodes: (SimpleDomNode | string)[]) {
    nodes.forEach(node => {
      if (typeof node === 'string') {
        this.appendChild(this.ownerDocument.createTextNode(node));
      } else {
        this.appendChild(node);
      }
    });
  }

  public appendChild(child: SimpleDomNode): SimpleDomNode {
    if (child.nodeType === NodeType.DOCUMENT_FRAGMENT_NODE) {
      this.append(...child.childNodes);
      return child;
    } else {
      if (child.parentNode) {
        child.parentNode.removeChild(child);
      }

      child.parentNode = this;
      child.index = this.childNodes.length;
      this.childNodes.push(child);
      return child;
    }
  }

  // TODO: try not to use this method because it has to update all the child node indexes
  public insertBefore(newNode: SimpleDomNode, referenceNode: SimpleDomNode): SimpleDomNode {
    if (!referenceNode) {
      return this.appendChild(newNode);
    }

    if (newNode.parentNode) {
      newNode.parentNode.removeChild(newNode);
    }

    newNode.parentNode = this;
    newNode.index = referenceNode.index;
    this.childNodes.splice(referenceNode.index, 0, newNode);

    // Update the indexes of the children
    for (let i = newNode.index + 1; i < this.childNodes.length; i += 1) {
      this.childNodes[i].index = i;
    }

    return newNode;
  }

  /**
   * Inserts a set of Node or string objects after this node. String objects are inserted as Text nodes.
   * @param nodes The nodes or text to insert.
   */
  public after(...nodes: (SimpleDomNode | string)[]) {
    if (!this.parentNode) {
      return;
    }

    const childNodes = this.parentNode.childNodes;
    const newNodes = nodes.map(node => typeof node === 'string' ? this.ownerDocument.createTextNode(node) : node);
    const lastNewIndex = this.index + nodes.length;

    childNodes.splice(this.index + 1, 0, ...newNodes);

    // Update the indexes of the children
    for (let i = this.index + 1; i < childNodes.length; i += 1) {
      const child = childNodes[i];

      // If one of the newly inserted nodes was a child of another node
      if (child.parentNode && i <= lastNewIndex) {
        child.parentNode.removeChild(child);
      }

      child.index = i;
      child.parentNode = this.parentNode;
    }
  }

  // TODO: try not to use this method because it has to update all the child node indexes
  public remove() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  }

  // TODO: try not to use this method because it has to update all the child node indexes
  public removeChild(child: SimpleDomNode): SimpleDomNode {
    this.childNodes.splice(child.index, 1);

    // Update the indexes of the remaining children
    for (let i = child.index; i < this.childNodes.length; i += 1) {
      this.childNodes[i].index = i;
    }

    // Clear the child's parent and index
    child.parentNode = null;
    child.index = null;

    return child;
  }

  /**
   * Replaces a child node with the new node.
   * @param newChild The child node to insert.
   * @param oldChild The child node to replace.
   * @returns The replaced child node.
   */
  public replaceChild(newChild: SimpleDomNode, oldChild: SimpleDomNode): SimpleDomNode {
    if (newChild.nodeType === NodeType.DOCUMENT_FRAGMENT_NODE) {
      oldChild.replaceWith(...newChild.childNodes);
    } else {
      oldChild.replaceWith(newChild);
    }

    return oldChild;
  }

  /**
   * Replaces this node in the children list of its parent with a set of nodes or strings. Strings are inserted as equivalent text nodes.
   * @param nodes The nodes or text to replace this node with.
   */
  public replaceWith(...nodes: (SimpleDomNode | string)[]) {
    if (!this.parentNode) {
      return null;
    }

    const childNodes = this.parentNode.childNodes;
    const newNodes = nodes.map(node => typeof node === 'string' ? this.ownerDocument.createTextNode(node) : node);
    const lastNewIndex = this.index + nodes.length - 1;

    // Replace the node with the new nodes
    childNodes.splice(this.index, 1, ...newNodes);

    // Update the indexes of the children starting with the first new node
    for (let i = this.index; i < childNodes.length; i += 1) {
      const child = childNodes[i];

      // If one of the newly inserted nodes was a child of another node
      if (child.parentNode && i <= lastNewIndex) {
        child.parentNode.removeChild(child);
      }

      child.index = i;
      child.parentNode = this.parentNode;
    }

    // Clear the parent and index
    this.index = null;
    this.parentNode = null;
  }

  public cloneNode(deep: boolean = false): SimpleDomNode {
    const clone = new SimpleDomNode(this.ownerDocument, this.nodeName, this.nodeType);
    clone.attributes = this.attributes.map(attr => ({ ...attr }));
    clone.nodeValue = this.nodeValue;

    if (deep) {
      for (const child of this.childNodes) {
        clone.appendChild(child.cloneNode(deep));
      }
    }

    return clone;
  }

  public contains(node: SimpleDomNode): boolean {
    while (node && node !== this) {
      node = node.parentNode;
    }

    return node === this;
  }

  public getAttribute(name: string): string {
    return this.attributes.find(attr => attr.nodeName === name)?.nodeValue ?? null;
  }

  public hasAttribute(name: string): boolean {
    return this.attributes.some(attr => attr.nodeName === name);
  }

  public removeAttribute(name: string) {
    const index = this.attributes.findIndex(attr => attr.nodeName === name);
    if (index !== -1) {
      this.attributes.splice(index, 1);

      // Update the style property
      if (name === 'style') {
        if (this.style.cssText !== '') {
          this.style.cssText = '';
        }
      }
      // Update the class property
      else if (name === 'class') {
        if (this.classList.value !== '') {
          this.classList.value = '';
        }
      }
    }
  }

  /**
   * Sets the value of an attribute on the node.
   * If the attribute already exists, the value is updated; otherwise a new attribute is added with the specified name and value.
   * @param name The name of the attribute.
   * @param value The value of the attribute.
   */
  public setAttribute(name: string, value: string) {
    const attr = this.attributes.find(attr => attr.nodeName === name);

    if (attr) {
      attr.nodeValue = value;

      // Update the style property
      if (name === 'style') {
        if (this.style.cssText !== value) {
          this.style.cssText = value;
        }
      }
      // Update the class property
      else if (name === 'class') {
        if (this.classList.value !== value) {
          this.classList.value = value;
        }
      }
    } else {
      this.attributes.push({ nodeName: name, nodeType: NodeType.ATTRIBUTE_NODE, nodeValue: value });
    }
  }

  /**
   * Because namespaces are not differentiated in SimpleDom, this method is the same as setAttribute.
   * @param namespace The namespace of the attribute. This is ignored.
   * @param name The name of the attribute with the namespace prefix.
   * @param value The value of the attribute.
   */
  public setAttributeNS(namespace: string, name: string, value: string) {
    this.setAttribute(name, value);
  }

  public getAttributeNames(): string[] {
    return this.attributes.map(attr => attr.nodeName);
  }

  public getElementsByTagName(tagName: string): SimpleDomNode[] {
    const nodes: SimpleDomNode[] = [];

    for (const child of this.childNodes) {
      if (child.nodeName === tagName) {
        nodes.push(child);
      }

      nodes.push(...child.getElementsByTagName(tagName));
    }

    return nodes;
  }

  public getElementById(id: string): SimpleDomNode {
    for (const child of this.childNodes) {
      if (child.getAttribute('id') === id) {
        return child;
      }

      const found = child.getElementById(id);
      if (found) {
        return found;
      }
    }

    return null;
  }

  /**
   * Tests whether the element would be selected by the specified CSS selector.
   * Supports a simpler subset of CSS selectors: *, tag names, class names, ids, attribute existence, and attribute value equality.
   *
   * For example: `*` `div` `.class` `#id` `[name]` `[name=value]`
   * @param selector The CSS selector to test.
   * @returns `true` if the element would be selected by the selector, otherwise `false`.
   */
  public matches(selector: string): boolean {
    // Check if the selector is a wildcard
    if (selector === '*') {
      return true;
    }

    // Split the selector into parts and check if any of them match
    return selector.split(',').some(part => {
      let index: number;
      part = part.trim();

      // Class name
      if (part.startsWith('.')) {
        const className = part.slice(1);
        if (this.classList.contains(className)) {
          return true;
        }
      }
      // ID
      else if (part.startsWith('#')) {
        const id = part.slice(1);
        if (this.getAttribute('id') === id) {
          return true;
        }
      }
      // Attribute
      else if ((index = part.indexOf('[')) !== -1) {
        if (index > 0) {
          const nodeName = part.slice(0, index);
          if (!(new RegExp(`^${nodeName}$`, 'i').test(this.nodeName))) {
            return false;
          }
        }
        const [attr, value] = part.slice(index + 1, -1).split('=');
        if (value) {
          return this.getAttribute(attr) === value;
        } else {
          return this.hasAttribute(attr);
        }
      }
      // Tag name
      else {
        return new RegExp(`^${part}$`, 'i').test(this.nodeName);
      }
    });
  }

  /**
   * Returns the first node that is a descendant of this node that matches the specified group of selectors.
   * @param selector The CSS selector to query for.
   * @returns The first node that is a descendant of this node that matches the specified group of selectors. Or `null` if no such node exists.
   */
  public querySelector(selector: string): SimpleDomNode {
    let foundNode: SimpleDomNode;
    for (const child of this.childNodes) {
      if (child.matches(selector)) {
        return child;
      }

      foundNode = child.querySelector(selector);
      if (foundNode) {
        return foundNode;
      }
    }

    return null;
  }

  private getElementTextContent(node: SimpleDomNode): string {
    if (node.nodeType === NodeType.TEXT_NODE) {
      return node.nodeValue;
    }

    return node.childNodes.map(child => this.getElementTextContent(child)).join('');
  }
}

/**
 * A registered element that has a custom implementation of outerHTML.
 * Can be registered with a document using SimpleDomDocument.registerElement.
 */
export interface SimpleDomRegisteredElement {
  /**
   * Returns the outerHTML for the node.
   * @param node The node to get the outerHTML for.
   * @returns The outerHTML for the node.
   */
  outerHTML: (node: SimpleDomNode) => string;
}

export class SimpleDomDocument extends SimpleDomNode {
  /** A map of registered elements. Maps element name to registered element. */
  private registeredElements: Map<string, SimpleDomRegisteredElement> = new Map();

  /** A set of registered void tags. Any tag in the set will be serialized as a void tag. */
  private registeredVoidTagSet: Set<string>;

  /** The content type of the document. */
  public contentType: 'text/html' | 'text/xml';

  public get documentElement(): SimpleDomNode {
    // Return the first element child
    return this.childNodes.find(child => child.nodeType === NodeType.ELEMENT_NODE);
  }

  public createCDATASection(data: string): SimpleDomNode {
    const node = new SimpleDomNode(this, '#cdata-section', NodeType.CDATA_SECTION_NODE);
    node.nodeValue = data ?? '';
    return node;
  }

  public createComment(data: string): SimpleDomNode {
    const node = new SimpleDomNode(this, '#comment', NodeType.COMMENT_NODE);
    node.nodeValue = data ?? '';
    return node;
  }

  public createDocumentFragment(): SimpleDomNode {
    return new SimpleDomNode(this, '#document-fragment', NodeType.DOCUMENT_FRAGMENT_NODE);
  }

  public createElement(nodeName: string): SimpleDomNode {
    return new SimpleDomNode(this, nodeName, NodeType.ELEMENT_NODE);
  }

  public createTextNode(text: string): SimpleDomNode {
    const node = new SimpleDomNode(this, '#text', NodeType.TEXT_NODE);
    node.nodeValue = text ?? '';
    return node;
  }

  /**
   * Registers an element with a custom implementation of outerHTML.
   * @param name The name of the element.
   * @param element The registered element.
   */
  public registerElement(name: string, element: SimpleDomRegisteredElement) {
    this.registeredElements.set(name, element);
  }

  /**
   * Checks if an element has been registered with a custom implementation of outerHTML.
   * @param name The name of the element.
   * @returns `true` if the element has been registered, otherwise `false`.
   */
  public hasRegisteredElement(name: string): boolean {
    return this.registeredElements.has(name);
  }

  /**
   * Gets a registered element.
   * @param name The name of the element.
   * @returns The registered element.
   */
  public getRegisteredElement(name: string): SimpleDomRegisteredElement | undefined {
    return this.registeredElements.get(name);
  }

  /**
   * Registers a set of void tags.
   * The tags will be serialized as void tags. eg by `outerHTML`
   * @param tagNames The tag names to register as void tags.
   */
  public registerVoidTags(tagNames: string[]) {
    if (!this.registeredVoidTagSet) {
      this.registeredVoidTagSet = new Set();
    }

    tagNames.forEach(tagName => this.registeredVoidTagSet.add(tagName.toLowerCase()));
  }

  /**
   * Returns true if the tag name is a void tag.
   * This includes HTML void tags and custom void tags that have been registered.
   * Performs a case-insensitive check.
   * @param tagName The tag name to check.
   * @returns `true` if the tag is a void tag, otherwise `false`.
   */
  public isVoidTag(tagName: string): boolean {
    tagName = tagName.toLowerCase();
    return voidTagSet.has(tagName) || this.registeredVoidTagSet?.has(tagName);
  }
}
