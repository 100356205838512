import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { PageFilterConfigBase } from '@portal-core/ui/page-filters/util/page-filter-config-base';

export class BuildPageFilterConfig extends PageFilterConfigBase {
  static readonly Columns: GridColumn[] = [
    {
      name: 'Id',
      title: 'ID',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int
    },
    {
      name: 'BranchName',
      title: 'Branch',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String,
    },
    {
      name: 'TargetPath',
      title: 'Target',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'Project.LicenseId',
      title: 'License Id',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int,
    },
    {
      name: 'Project.Name',
      title: 'Project',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.String,
    },
    {
      name: 'IsKept',
      title: 'Keep',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      filterDataType: PageDataType.Boolean,
      selectOptions: [{ id: 'false', text: 'None' }, { id: 'true', text: 'Kept' }]
    },
    {
      name: 'FirstNotification.ProcessNotification.StartTime',
      title: 'Started',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Date,
    },
    {
      name: 'LastNotification.ProcessNotification.StartTime',
      title: 'Finished',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Date
    },
    {
      name: 'RanFor',
      title: 'Duration',
      type: PageDataType.Int
    },
    {
      name: 'LastNotification.ProcessState',
      title: 'State',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      selectOptions: [{ id: '3', text: 'Completed' }, { id: '1', text: 'Failed' }, { id: '4', text: 'Cancelled' }, { id: '5', text: 'Queued' }, { id: '7', text: 'Getting Files' }, { id: '2', text: 'Building' }, { id: '6', text: 'Publishing' }]
    },
    {
      name: 'ProgressValue',
      title: 'Progress',
      type: PageDataType.Int
    },
    {
      name: 'Process.CreatedByUser.FullName',
      title: 'By',
      filterEnabled: true,
      type: PageDataType.String,
      filterDataType: PageDataType.FullName
    },
    {
      name: 'WarningCount',
      title: 'Warnings',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int
    },
    {
      name: 'ErrorCount',
      title: 'Errors',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int
    },
    {
      name: 'CreatedOn',
      title: 'Queued',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Date
    },
    {
      name: 'IsBuiltOnCentral',
      title: 'Source',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Select,
      selectOptions: [{ id: '0', text: 'Flare' }, { id: '1', text: 'Central' }]
    },
    {
      name: 'CommitId',
      title: 'Commit',
      filterEnabled: true,
      type: PageDataType.String
    },
    {
      name: 'AzureStorageSize',
      title: 'Size',
      filterEnabled: true,
      sortEnabled: true,
      type: PageDataType.Int
    }
  ];

  constructor(enabledColumnNames?: string[]) {
    super(enabledColumnNames, BuildPageFilterConfig.Columns);
  }
}
