import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { DynamicViewBase } from '@portal-core/text-editor/util/dynamic-view.base';
import { SVG, Svg } from '@svgdotjs/svg.js';

/**
 * The base class for node view components.
 * Takes care of madcap-changes by adding classes and drawing removal marks on node view.
 */
@Directive()
export abstract class NodeViewBase extends DynamicViewBase implements OnChanges {
  static StaticClassNames: string = 'mc-node-view';

  @HostBinding('class') changeClassNames: string = NodeViewBase.StaticClassNames;
  @HostBinding('attr.madcap-changes')
  @Input() changeIds: string;
  @Input() changeList: TrackedChangeList;
  @HostBinding('attr.madcap:conditions')
  @Input() conditions: string;
  @Output() load: EventEmitter<any> = new EventEmitter<any>();

  private svg: Svg;

  public get showRemovalMark(): boolean {
    return true;
  }

  public get hasChanges(): boolean {
    return Array.isArray(this.changeList);
  }

  constructor(changeDetectorRef: ChangeDetectorRef, protected elementRef: ElementRef) {
    super(changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeList) {
      if (Array.isArray(this.changeList)) {
        const types = [];
        let classNames = `${NodeViewBase.StaticClassNames} mc-node-view-change`;

        this.changeList.forEach(change => {
          classNames += ' mc-node-view-change-' + change.changeType;
          types.push(change.changeType);
        });

        this.changeClassNames = classNames;

        // If this is a remove change and an X should be drawn over the element
        if (types.includes('remove') && this.showRemovalMark) {
          this.drawRemovalMark();
        } else {
          this.clearRemovalMark();
        }
      } else {
        this.changeClassNames = NodeViewBase.StaticClassNames;
        this.clearRemovalMark();
      }
    }
  }

  private clearRemovalMark() {
    if (this.svg) {
      this.svg.remove();
      this.svg = null;
    }
  }

  private drawRemovalMark() {
    // Remove the current svg
    this.clearRemovalMark();

    // Create the svg canvas to draw on
    this.svg = SVG().addTo(this.elementRef.nativeElement).size('100%', '100%');
    this.svg.addClass('mc-node-view-change-remove-svg');

    this.svg.rect('100%' as unknown as number, '100%' as unknown as number).fill({
      color: '#000',
      opacity: 0.5
    });
    this.svg.line(0, 0, '100%' as unknown as number, '100%' as unknown as number).addClass('mc-node-view-change-remove-line').fill('none').stroke({ width: 1 });
    this.svg.line(0, '100%' as unknown as number, '100%' as unknown as number, 0).addClass('mc-node-view-change-remove-line').fill('none').stroke({ width: 1 });
  }
}
