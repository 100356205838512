<mc-notification-list [notifications]="notifications$ | async"></mc-notification-list>
<div class="mc-notification-list-footer">
  <button
    *ngIf="!allNotificationsLoaded"
    mat-stroked-button
    (click)="loadMoreNotifications()"
    [disabled]="loadingNotifications"
  >
    Load More
  </button>
  <span *ngIf="allNotificationsLoaded" class="mc-text-muted">All notifications have been loaded.</span>
  <span *ngIf="loadingError" class="mc-notification-list-loading-error mc-error-message">{{ loadingError }}</span>
</div>

<mat-progress-bar
  *ngIf="loadingNotifications"
  class="mc-notification-list-loading-progress-bar"
  mode="indeterminate"
  color="notice"
></mat-progress-bar>
