import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@portal-client/core/core.service';
import { AuthStatusMessage } from '@portal-core/auth/enums/auth-status-message.enum';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { RouterService } from '@portal-core/general/services/router.service';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicenseActivationType } from '@portal-core/licenses/enums/license-activation-type.enum';
import { LicenseType } from '@portal-core/licenses/enums/license-type.enum';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { catchError, first, map, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthUserDataResolverService implements Resolve<boolean> {
  constructor(
    private router: Router,
    private authService: AuthService,
    private coreService: CoreService,
    private licensesService: LicensesService,
    private licenseUsersService: LicenseUsersService,
    private routerService: RouterService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.isAuthenticated()) {
      return of(false);
    }

    const cachedAuthUserData = this.authService.getAuthUserData();
    if (cachedAuthUserData?.License) {
      return of(true);
    }

    return this.authService.getAuthUserData$().pipe(
      first(),
      map(authUserData => {
        // Add the licenses and license users to their data stores
        this.licensesService.addItems$([authUserData.License]);
        this.licenseUsersService.addItems$([authUserData.LicenseUser]);

        // Set the current items in the core service
        this.coreService.setLicenseId$(authUserData.License.Id);
        this.coreService.setLicenseUserId$(authUserData.LicenseUser.Id);

        return authUserData.License;
      }),
      tap(license => {
        // Only redirect to an alert page if not already on the alert page
        if (license.IsDeleted) {
          this.maybeNavigateToAlertPage(route, { type: AlertType.LicenseNotFound, licenseId: license.Id });
        } else if (license.LicenseActivationStatus === LicenseActivationType.Deactivated) {
          this.maybeNavigateToAlertPage(route, { type: AlertType.DeactivatedLicense, licenseId: license.Id });
        } else if (license.LicenseActivationStatus === LicenseActivationType.Inactive) {
          if (license.LicenseType === LicenseType.Full) {
            this.maybeNavigateToAlertPage(route, { type: AlertType.ExpiredLicense, licenseId: license.Id });
          } else {
            this.maybeNavigateToAlertPage(route, { type: AlertType.ExpiredTrial, licenseId: license.Id });
          }
        } else if (license.LicenseActivationStatus === LicenseActivationType.BillingFailed) {
          this.maybeNavigateToAlertPage(route, { type: AlertType.BillingFailed, licenseId: license.Id });
        }
      }),
      map(() => true),
      catchError(error => {
        if (error.status === 403) {
          const authStatus: string = AuthStatusMessage[error.headers.get('X-AuthStatus')];

          // If there is an auth status message AND it is not the NeedsPermission message then this is a drastic auth error
          if (authStatus && authStatus !== AuthStatusMessage.NeedsPermission) {
            this.maybeNavigateToAlertPage(route, { type: authStatus });
          } else {
            this.maybeNavigateToAlertPage(route, { type: AlertType.LicenseAccessDenied });
          }
        } else if (error.status === 404) {
          this.maybeNavigateToAlertPage(route, { type: AlertType.LicenseNotFound });
        } else {
          this.maybeNavigateToAlertPage(route, { type: AlertType.AuthInitError });
        }

        return of(false);
      })
    );
  }

  private maybeNavigateToAlertPage(route: ActivatedRouteSnapshot, queryParams: Params) {
    const routePath = this.routerService.getRoutePath(route);

    if (routePath.length < 1 || routePath[routePath.length - 1] !== 'alert') {
      this.router.navigate(['/alert'], { queryParams });
    }
  }
}
