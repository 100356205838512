import { NodeType } from 'prosemirror-model';
import { Command, EditorState } from 'prosemirror-state';

export function insertBr(brType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const $from = state.selection.$from;

    if (!$from.parent.inlineContent) {
      return false;
    }

    if (dispatch) {
      dispatch(state.tr.replaceSelectionWith(brType.create()).scrollIntoView());
    }

    return true;
  };
}
