import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { ProjectFilesTreeFileModule } from '@portal-core/project-files/components/project-files-tree-file/project-files-tree-file.module';
import { ProjectFilesTreeFolderModule } from '@portal-core/project-files/components/project-files-tree-folder/project-files-tree-folder.module';
import { ProjectFilesTreeItemModule } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.module';
import { ContextMenuItemsModule } from '@portal-core/ui/grid/directives/context-menu-items/context-menu-items.module';
import { ProjectFilesTreeComponent } from './project-files-tree.component';

@NgModule({
  declarations: [
    ProjectFilesTreeComponent
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatMenuModule,
    RouterModule,
    ContextMenuItemsModule,
    ScrollingModule,
    LoaderModule,
    ErrorsExpansionPanelModule,
    ProjectFilesTreeFolderModule,
    ProjectFilesTreeFileModule,
    ProjectFilesTreeItemModule,
    StopPropagationModule
  ],
  exports: [
    ProjectFilesTreeComponent,
    ProjectFilesTreeItemModule
  ]
})
export class ProjectFilesTreeModule { }
