import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Logout } from '@portal-core/auth/services/auth-data.service';

/**
 * Closes all material dialogs when the user logs out.
 * Listens to the Logout action from the auth data store to close the dialogs.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthDialogsEffect {
  constructor(private actions$: Actions, private dialog: MatDialog) {
    this.actions$.pipe(
      ofActionSuccessful(Logout)
    ).subscribe(() => {
      this.dialog.closeAll();
    });
  }
}
