import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { DoughnutChartModule } from '@portal-core/ui/charts/components/doughnut-chart/doughnut-chart.module';
import { DoughnutChartHoleModule } from '@portal-core/ui/charts/directives/doughnut-chart-hole/doughnut-chart-hole.module';
import { ProjectOverviewComponent } from './project-overview.component';

@NgModule({
  declarations: [
    ProjectOverviewComponent
  ],
  exports: [
    ProjectOverviewComponent
  ],
  imports: [
    CommonModule,
    DoughnutChartModule,
    DoughnutChartHoleModule,
    FileSizeModule,
    MatIconModule
  ]
})
export class ProjectOverviewModule { }
