import { ErrorCode } from '@common/http/enums/error-code.enum';


export class FormValidationConfig {
  /**
   * Configuration class for defining error codes to be treated as validation errors
   * @param errorCodes A dictionary mapping error names to error codes
   * @param formControlErrors A dictionary mapping form control names to error names
   */
  constructor(
    public errorCodes: Dictionary<ErrorCode | { code: ErrorCode, useServerMessage: boolean }>,
    public formControlErrors: Dictionary<string[]>
    ) {}
}
