import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectInfoState } from '@portal-core/project-info/services/project-info-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProjectInfoState])
  ]
})
export class ProjectInfoServicesModule { }
