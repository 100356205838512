import { Pipe, PipeTransform } from '@angular/core';
import { FileSizeService } from '@portal-core/general/services/file-size.service';

/*
 * Convert bytes into largest possible unit.
 * Takes a precision argument that defaults to 2.
 * Takes a divider argument that defaults to ' '.
 * Usage:
 *   bytes | mcFileSize: precision: divider
 * Example:
 *   {{ 1024 |  mcFileSize }}
 *   formats to: 1 KB
*/
@Pipe({
  name: 'mcFileSize'
})
export class FileSizePipe implements PipeTransform {
  constructor(private fileSizeService: FileSizeService) { }

  transform(bytes: string | number = 0, precision: number = 2, divider: string = ' '): string {
    return this.fileSizeService.format(bytes, precision, divider);
  }
}
