import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusLicenseUserTooltipPipe } from './review-file-user-status-license-user-tooltip.pipe';

@NgModule({
  declarations: [ReviewFileUserStatusLicenseUserTooltipPipe],
  imports: [CommonModule],
  exports: [ReviewFileUserStatusLicenseUserTooltipPipe]
})
export class ReviewFileUserStatusLicenseUserTooltipModule { }
