import { Directionality } from '@angular/cdk/bidi';
import { OverlayConfig, OverlayKeyboardDispatcher, OverlayOutsideClickDispatcher, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT, Location } from '@angular/common';
import { ANIMATION_MODULE_TYPE, ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, NgZone, Optional } from '@angular/core';
import { ElementOverlayPositionBuilderService } from '@portal-core/ui/overlay/services/element-overlay-position-builder.service';

/** Next overlay unique ID. */
let nextUniqueId = 0;

@Injectable({
  providedIn: 'root'
})
export class ElementOverlayService {
  private appRef: ApplicationRef;

  constructor(
    /** Scrolling strategies that can be used when creating an overlay. */
    public scrollStrategies: ScrollStrategyOptions,
    private componentFactoryResolver: ComponentFactoryResolver,
    private elementOverlayPositionBuilderService: ElementOverlayPositionBuilderService,
    private keyboardDispatcher: OverlayKeyboardDispatcher,
    private injector: Injector,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private directionality: Directionality,
    private location: Location,
    private outsideClickDispatcher: OverlayOutsideClickDispatcher,
    @Inject(ANIMATION_MODULE_TYPE) @Optional() private _animationsModuleType?: string
  ) { }

  create(overlayContainerElement: HTMLElement, config?: OverlayConfig): OverlayRef {
    overlayContainerElement.classList.add('mc-element-overlay-container', 'cdk-overlay-container');
    const host = this._createHostElement(overlayContainerElement);
    const pane = this._createPaneElement(host);
    const portalOutlet = this._createPortalOutlet(pane);
    const overlayConfig = new OverlayConfig(config);

    overlayConfig.direction = overlayConfig.direction || this.directionality.value;

    return new OverlayRef(
      portalOutlet,
      host,
      pane,
      overlayConfig,
      this.ngZone,
      this.keyboardDispatcher,
      this.document,
      this.location,
      this.outsideClickDispatcher,
      this._animationsModuleType === 'NoopAnimations',
    );
  }

  position(): ElementOverlayPositionBuilderService {
    return this.elementOverlayPositionBuilderService;
  }

  /**
   * Creates the host element that wraps around an overlay
   * and can be used for advanced positioning.
   * @returns Newly-create host element.
   */
  private _createHostElement(overlayContainerElement: HTMLElement): HTMLElement {
    const host = this.document.createElement('div');
    overlayContainerElement.appendChild(host);
    return host;
  }

  /**
   * Creates the DOM element for an overlay and appends it to the overlay container.
   * @returns Newly-created pane element
   */
  private _createPaneElement(host: HTMLElement): HTMLElement {
    const pane = this.document.createElement('div');

    pane.id = `cdk-overlay-${nextUniqueId++}`;
    pane.classList.add('cdk-overlay-pane');
    host.appendChild(pane);

    return pane;
  }

  /**
   * Create a DomPortalOutlet into which the overlay content can be loaded.
   * @param pane The DOM element to turn into a portal outlet.
   * @returns A portal outlet for the given DOM element.
   */
  private _createPortalOutlet(pane: HTMLElement): DomPortalOutlet {
    // We have to resolve the ApplicationRef later in order to allow people
    // to use overlay-based providers during app initialization.
    if (!this.appRef) {
      this.appRef = this.injector.get<ApplicationRef>(ApplicationRef);
    }

    return new DomPortalOutlet(
      pane,
      this.componentFactoryResolver,
      this.appRef,
      this.injector,
      this.document,
    );
  }
}
