
import { NotificationMessageInstruction } from '@portal-core/notifications/types/notification-message-instruction.type';

/**
 * Delimiters for parsing notification messages into rendering instructions.
 */
const messageDelimiters = [
  {
    start: '{',
    end: '}',
    match: /{\s*([^{}]+)\s*}/,
    delimiter: '{\s*[^{}]+\s*}',
    type: 'tag'
  },
  {
    start: '<b>',
    end: '</b>',
    match: /<b>([^<]*)<\/b>/,
    delimiter: '<b>[^<]*<\/b>',
    type: 'wrapper'
  },
  {
    start: '<span style="color:red">',
    end: '</span>',
    match: /<span style="color:red">([^<]*)<\/span>/,
    delimiter: '<span style="color:red">[^<]*<\/span>',
    type: 'wrapper'
  }
];

/**
 * Regular expression for matching delimiters in notification messages.
 */
const messageDelimiterRegex = new RegExp(`(${messageDelimiters.map(d => d.delimiter).join('|')})`);

/**
 * Parses a notification's message into a list of rendering instructions.
 * @param message The message to parse.
 * @returns The list of rendering instructions.
 */
export function parseNotificationMessage(message: string): NotificationMessageInstruction[] | undefined {
  if (message) {
    const parts = message.split(messageDelimiterRegex);

    return parts
      // Filter out empty strings
      .filter(part => part !== '')
      // Convert the parts to instructions
      .map(part => {
        let match: NotificationMessageInstruction;

        // Check if the part matches any of the delimiters
        messageDelimiters.find(d => {
          const captured = part.match(d.match);
          if (captured) {
            match = {
              type: d.type as any,
              value: captured[1].trim()
            };

            if (d.type === 'wrapper') {
              match.wrapper = d.start;
            }

            return true;
          }
        });

        // If no match was found, treat the part as text
        if (match) {
          return match;
        } else {
          return {
            type: 'text',
            value: part
          };
        }
      });
  }
}
