import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';

@Pipe({
  name: 'mcReviewPackageAvatarTooltip'
})
export class ReviewPackageAvatarTooltipPipe implements PipeTransform {
  transform(status: ReviewPackageStatus | null | undefined, name: string | null | undefined): string {
    let statusText: string = '';

    switch (status) {
      case ReviewPackageStatus.Open:
        statusText = 'Open';
        break;
      case ReviewPackageStatus.Closed:
        statusText = 'Closed';
        break;
    }

    return `${name} (${statusText})`;
  }
}
