import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { v4 as uuidv4 } from 'uuid';
import { NodeType, Slice } from 'prosemirror-model';
import { removeNodeTypeFromSlice } from '../model/node';
import { CreateImagePlaceholdersCommand } from '@common/prosemirror/commands/image.command';

const clipboardImageResolverKey = new PluginKey('clipboardImageResolver');

export interface ClipboardImageResolverOptions {
  imageType: NodeType;
  onPastedImages: (imageInfos: InsertImageData[]) => void;
  imagePlaceholdersCommand: CreateImagePlaceholdersCommand;
  getFile:(src: string) => File;
}

interface InsertImageData {
  file: File;
  guid: string;
  pos: number;
}

/**
 * A ProseMirror plugin for uploading and insert images from the clipboard.
 */
export function clipboardImageResolverPlugin(options?: ClipboardImageResolverOptions): Plugin {
  return new Plugin({
    key: clipboardImageResolverKey,

    props: {
      handlePaste(view: EditorView, event: ClipboardEvent, slice: Slice) {
        // Data to send to the replace image placeholders callback
        let uploadImageData: InsertImageData[] = [];

        // Check clipboard files for images
        const imageFiles = [...event.clipboardData.files].filter(file => file.type.includes('image'));

        // Gather image files from clipboard
        if (imageFiles.length) {
          uploadImageData = imageFiles.map(file => ({file: file, guid: uuidv4(), pos: view.state.selection.from}));
          // Check slice for image nodes
        } else if (slice.size) {
          // Adjust mapping to account for other placeholders inserted
          let prevImageOffset = 0;
          slice.content.descendants((node, pos) => {
            if (node.type == options.imageType) {
              // Transform the src to file
              const file = options.getFile(node.attrs.src);

              if (file) {
                uploadImageData.push({file: file, guid: uuidv4(), pos: view.state.selection.from + pos - slice.openStart - prevImageOffset++});
              }
            }
          });
        }

        if (uploadImageData.length) {
          // Replace selection with clipboard data using image placeholders instead of image nodes
          slice = removeNodeTypeFromSlice(slice, [options.imageType]);
          if (slice.size) {
            const tr = view.state.tr.replaceSelection(slice);
            view.dispatch(tr);
          }
          // Insert image placeholders
          const actions = uploadImageData.map(imageData => ({id: imageData.guid, pos: imageData.pos}));
          options.imagePlaceholdersCommand(view.state, view.dispatch, view, actions);
          // Notify the text editor to upload images to server and replace placeholders
          options.onPastedImages(uploadImageData);
          return true;
        } else {
          return false;
        }
      }
    }
  });
}
