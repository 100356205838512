import { Observable, throwError, from, MonoTypeOperatorFunction, of } from 'rxjs';
import { first, switchMap, concatMap } from 'rxjs/operators';

export interface SequenceValidator {
  validate$: (value: any) => Observable<true | Error>;
}

function validateSequence<T>(validators: SequenceValidator[], value: T): Observable<T> {
  return from(validators || []).pipe(
    // Run each validator one at a time in series
    concatMap(validator => validator.validate$(value)),
    // Grab the first validator to fail. This will close the subscription so that no more validators will run. If all pass then default the value to true
    first(valid => valid !== true, true),
    // If the value is valid then pass back the value otherwise throw the validation error
    switchMap(valid => valid === true ? of(value) : throwError(valid))
  );
}

/**
 * Applies a sequence of validators in series to each value emitted by the source Observable, and emits the error if any validator returns an error.
 * @param {function(value: T): SequenceValidator[]}: validators The function that returns an Array of SequenceValidator objects to run.
 * The `value` parameter is the value emitted by the source Observable.
 * @return {Observable<T>} An observable that emits the same value as the source Observable.
 */
export function validate<T>(validators: (value: T) => SequenceValidator[]): MonoTypeOperatorFunction<T> {
  return (source$: Observable<any>) => source$.pipe(
    switchMap(source => validateSequence(validators(source), source))
  );
}
