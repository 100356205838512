export enum CollabSessionSyncState {
  Loaded = 'Loaded',
  Loading = 'Loading',
  LoadFailed = 'Load Failed',
  NotLoaded = 'Not Loaded',
  Reverted = 'Reverted',
  Reverting = 'Reverting',
  RevertFailed = 'Revert Failed',
  Saved = 'Saved',
  Saving = 'Saving',
  SaveFailed = 'Save Failed',
  Stopped = 'Stopped',
  Unsaved = 'Unsaved'
}
