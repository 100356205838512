import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildAsyncPipe } from '@portal-core/builds/pipes/build-async/build-async.pipe';

@NgModule({
  declarations: [BuildAsyncPipe],
  imports: [CommonModule],
  exports: [BuildAsyncPipe],
  providers: [BuildAsyncPipe]
})
export class BuildAsyncModule {}
