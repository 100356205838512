import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectInfo } from '@portal-core/project-info/models/project-info.model';

@CollectionStateSelectors({
  stateType: ProjectInfoState
})
@State<ProjectInfoState>({
  name: ProjectInfoState.source
})
@Injectable()
export class ProjectInfoState extends CollectionStateBase {
  static source = 'ProjectInfo';
  getSource(): string {
    return ProjectInfoState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectInfoDataService extends CollectionDataServiceBase<ProjectInfo> {
  constructor(protected store: Store) {
    super(store, ProjectInfoState);
  }
}
