import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'mc-error-code',
  templateUrl: './error-code.component.html',
  styleUrls: ['./error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorCodeComponent implements OnInit {
  @Input() errorCode: ErrorCode;
  @Input() showErrorCode: boolean = false;

  @InputObservable('errorCode') errorCode$: Observable<ErrorCode>;

  ErrorCode: typeof ErrorCode = ErrorCode;
  hasErrorCode$: Observable<boolean>;

  ngOnInit() {
    this.hasErrorCode$ = this.errorCode$.pipe(
      map(errorCode => Number.isInteger(errorCode))
    );
  }
}
