<div class="mat-tree-node" [ngSwitch]="projectFilesTreeType">
  <div *ngSwitchCase="'selection'" class="mc-tree-node-item" [style]="'--mc-flat-tree-node-level:' + node.level">
    <ng-template [ngTemplateOutlet]="railTemplate"></ng-template>
    <mat-checkbox color="primary-vivid" class="mc-tree-node-item-checkbox" [checked]="checked" [disabled]="disableSelectionTree" disableRipple="true" (change)="onNodeCheckboxChanged(node)">
      <ng-template [ngTemplateOutlet]="nodeTemplate"></ng-template>
    </mat-checkbox>
  </div>

  <a *ngSwitchCase="'link'" class="mc-tree-node-item mc-plain-link" [ngClass]="[node.path === filePath ? 'mc-list-item-selected' : '']" [style]="'--mc-flat-tree-node-level:' + node.level" [routerLink]="['blob', encodeURIComponent(branchName), encodeURIComponent(node.path)]">
    <ng-template [ngTemplateOutlet]="railTemplate"></ng-template>
    <ng-template [ngTemplateOutlet]="nodeTemplate"></ng-template>
  </a>

  <div *ngSwitchDefault [ngClass]="[node.path === filePath ? 'mc-list-item-selected' : '']" class="mc-tree-node-item" [style]="'--mc-flat-tree-node-level:' + node.level" (click)="fileSelected.emit(node)">
    <ng-template [ngTemplateOutlet]="railTemplate"></ng-template>
    <ng-template [ngTemplateOutlet]="nodeTemplate"></ng-template>
  </div>
</div>

<ng-template #railTemplate>
  <div *ngFor="let i of (node.level | mcRange); let level = index" class="mc-tree-node-rail mc-border-divider" [style]="'--mc-flat-tree-rail-level:' + level"></div>
</ng-template>

<ng-template #nodeTemplate>
  <ng-container *ngIf="treeItemDirective; else defaultNode" [ngTemplateOutlet]="treeItemDirective.templateRef" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
  <ng-template #defaultNode>
    <mat-icon *ngIf="showIcons" class="mc-tree-text-icon" fontSet="project-titan" [fontIcon]="getFileIconName(node.name)"></mat-icon>
    {{node.name}}
  </ng-template>
</ng-template>