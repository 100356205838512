import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { DomService } from '@portal-core/general/services/dom.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Breakpoints } from '@portal-core/util/breakpoints';
import { Observable, Subscription, filter } from 'rxjs';

@Component({
  selector: 'mc-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe()
export class SubNavbarComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  @ViewChild('drawer', { read: ElementRef, static: true }) drawerElementRef: ElementRef;
  @ViewChild('pageDrawerGutterAlwaysOutlet', { static: true }) pageDrawerGutterAlwaysOutlet: RouterOutlet;

  routerEventSubscription: Subscription;
  showAppDrawerSubscription: Subscription;

  // showPageDrawer: Observable<BreakpointState>;
  showPageNavbarTabLinks: Observable<BreakpointState>;
  showPageNavbarToolbar: Observable<BreakpointState>;
  // showPageNavbarDropdownLinks: Observable<BreakpointState>;
  showAppDrawer: Observable<BreakpointState>;
  showAppNavbarLaunchPad: Observable<BreakpointState>;
  showAppDrawerNav: Observable<BreakpointState>;
  showAppDrawerGutter: Observable<BreakpointState>;
  // showAppDrawerLaunchPad: Observable<BreakpointState>;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private domService: DomService) {
    this.showPageNavbarTabLinks = this.breakpointObserver.observe(Breakpoints.pageNavbarTabLinksBreakpoint);
    this.showPageNavbarToolbar = this.breakpointObserver.observe(Breakpoints.pageNavbarToolbarBreakpoint);
    this.showAppDrawer = this.breakpointObserver.observe(Breakpoints.appDrawerBreakpoint);
    this.showAppNavbarLaunchPad = this.breakpointObserver.observe(Breakpoints.appNavbarLaunchPadBreakpoint);
    this.showAppDrawerNav = this.breakpointObserver.observe(Breakpoints.appDrawerNavBreakpoint);
    this.showAppDrawerGutter = this.breakpointObserver.observe(Breakpoints.appDrawerGutterBreakpoint);
  }

  ngOnInit() {
    this.routerEventSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => this.drawer.close());

    this.showAppDrawerSubscription = this.showAppDrawer.subscribe(showAppDrawer => {
      if (!showAppDrawer.matches && !this.pageDrawerGutterAlwaysOutlet.isActivated) {
        this.drawer.close();
      }
    });
  }

  onDrawerClicked(event: MouseEvent) {
    if (event.target) {
      if (this.domService.withinClass(event.target as Element, 'mc-js-close-drawer', this.drawerElementRef.nativeElement)) {
        this.drawer.close();
      }
    }
  }
}
