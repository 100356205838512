import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/*
 * Formats a date using dayjs.format and dayjs.fromNow.
 * Argument 1 (dateFormat) if equal to 'relative' uses dayjs.fromNow to format the date. Otherwise the format is passed directly to dayjs.format.
 * Argument 2 (excludeRelativeSuffix) is optional and only used if dateFormat equals 'relative'. If true then the suffix is excluded from the formatted date.
 * Usage:
 *   date | mcDate: 'MMMM Do YYYY, h:mm:ss a'
 * Example:
 *   {{ CreatedOn |  mcDate: 'relative' }}
 *   formats to: 4 years ago
 *   {{ CreatedOn |  mcDate: 'relative': true }}
 *   formats to: 4 years
*/
@Pipe({
  name: 'mcDate'
})
export class DatePipe implements PipeTransform {
  transform(value: any, dateFormat: string, excludeRelativeSuffix?: boolean): string {
    if (dateFormat === 'relative') {
      return dayjs(value).fromNow(excludeRelativeSuffix);
    } else {
      return dayjs(value).format(dateFormat);
    }
  }
}
