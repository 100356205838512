import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { SecuritySettings } from '@portal-core/licenses/models/license-security-settings.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { combineLatest, Observable, startWith, Subscription } from 'rxjs';

@Component({
  selector: 'mc-license-security-form',
  templateUrl: './license-security-form.component.html',
  styleUrls: ['./license-security-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicenseSecurityFormComponent implements OnChanges {
  @Input() license: License;
  @Input() readOnly: boolean;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDialog = new EventEmitter<boolean>();

  @PropertyObservable('readOnly') readonly$: Observable<boolean>;

  changeSecuritySettingsForm: UntypedFormGroup;
  disabledSubscription: Subscription;
  maxFailedAccessAttemptsOptions = [3, 5, 7];
  maxIdleTimeMinutesOptions = [15, 30, 45];
  requiredPasswordChangeDaysOptions = [30, 90, 120, 180];
  passwordMinimumCharacters = Array.from({ length: 23 }, (_, i) => i + 12);
  securitySettings: SecuritySettings = {
    RequirePasswordChangeAfterDays: null,
    MaxFailedAccessAttempts: null,
    MaxIdleTimeMinutes: null,
    PasswordMinimumLength: null
  }
  savingSettings = false;

  constructor(
    private licensesService: LicensesService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.license) {
      this.securitySettings = {
        RequirePasswordChangeAfterDays: this.license.RequirePasswordChangeAfterDays,
        MaxFailedAccessAttempts: this.license.MaxFailedAccessAttempts,
        MaxIdleTimeMinutes: this.license.MaxIdleTimeMinutes,
        PasswordMinimumLength: this.license.PasswordMinimumLength
      };

      this.resetForm();
    }
  }

  onSaveSecuritySettings({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      return;
    }
    this.savingSettings = true;

    this.securitySettings.RequirePasswordChangeAfterDays = value.requirePasswordChangeEnabled ? value.requirePasswordChangeValue : null;
    this.securitySettings.MaxFailedAccessAttempts = value.maxFailedAccessAttemptsEnabled ? value.maxFailedAccessAttemptsValue : null;
    this.securitySettings.MaxIdleTimeMinutes = value.maxIdleTimeEnabled ? value.maxIdleTimeValue : null;
    this.securitySettings.PasswordMinimumLength = value.passwordMinimumLengthEnabled ? value.passwordMinimumLengthValue : null;

    this.licensesService.changeSecuritySettings$(this.license.Id, this.securitySettings).subscribe(() => {
      this.savingSettings = false;
      this.snackBar.open('Security settings have been saved', 'OK', { duration: 2500 });
      this.changeSecuritySettingsForm.markAsPristine();
      this.saved.emit();
    }, error => {
      this.savingSettings = false;
      this.dialog.open(ErrorDialogComponent, {
        ...ErrorDialogComponent.DialogConfig,
        data: {
          title: 'Error Saving',
          message: 'There was an error saving security settings. Please contact us at sales@madcapsoftware.com or call +1 (858) 320-0387.',
          errors: this.errorService.getErrorMessages(error)
        }
      });
    });
  }

  private buildForm() {
    this.changeSecuritySettingsForm = this.formBuilder.group({
      maxFailedAccessAttemptsEnabled: new UntypedFormControl(null),
      maxFailedAccessAttemptsValue: new UntypedFormControl(null),
      maxIdleTimeEnabled: new UntypedFormControl(null),
      maxIdleTimeValue: new UntypedFormControl(null),
      requirePasswordChangeEnabled: new UntypedFormControl(null),
      requirePasswordChangeValue: new UntypedFormControl(null),
      passwordMinimumLengthEnabled: new UntypedFormControl(null),
      passwordMinimumLengthValue: new UntypedFormControl(null)
    });

    this.setupDisabledListener('maxFailedAccessAttemptsValue', 'maxFailedAccessAttemptsEnabled');
    this.setupDisabledListener('maxIdleTimeValue', 'maxIdleTimeEnabled');
    this.setupDisabledListener('requirePasswordChangeValue', 'requirePasswordChangeEnabled');
    this.setupDisabledListener('passwordMinimumLengthValue', 'passwordMinimumLengthEnabled');
  }

  private resetForm() {
    const currentMaxFailedAccessAttemptsIndex = this.maxFailedAccessAttemptsOptions.findIndex(v => v === (this.securitySettings.MaxFailedAccessAttempts ?? this.maxFailedAccessAttemptsOptions[0]));
    const currentMaxIdleTimeMinutesIndex = this.maxIdleTimeMinutesOptions.findIndex(v => v === (this.securitySettings.MaxIdleTimeMinutes ?? this.maxIdleTimeMinutesOptions[0]));
    const currentRequiredPasswordChangeDaysIndex = this.requiredPasswordChangeDaysOptions.findIndex(v => v === (this.securitySettings.RequirePasswordChangeAfterDays ?? this.requiredPasswordChangeDaysOptions[0]));
    const currentPasswordMinimumIndex = this.passwordMinimumCharacters.findIndex(v => v === (this.securitySettings.PasswordMinimumLength ?? this.passwordMinimumCharacters[0]));

    this.changeSecuritySettingsForm.reset({
      maxFailedAccessAttemptsEnabled: this.getCheckboxChecked('MaxFailedAccessAttempts'),
      maxFailedAccessAttemptsValue: currentMaxFailedAccessAttemptsIndex !== -1 ? this.maxFailedAccessAttemptsOptions[currentMaxFailedAccessAttemptsIndex] : this.maxFailedAccessAttemptsOptions[1],
      maxIdleTimeEnabled: this.getCheckboxChecked('MaxIdleTimeMinutes'),
      maxIdleTimeValue: currentMaxIdleTimeMinutesIndex !== -1 ? this.maxIdleTimeMinutesOptions[currentMaxIdleTimeMinutesIndex] : this.maxIdleTimeMinutesOptions[1],
      requirePasswordChangeEnabled: this.getCheckboxChecked('RequirePasswordChangeAfterDays'),
      requirePasswordChangeValue: currentRequiredPasswordChangeDaysIndex !== -1 ? this.requiredPasswordChangeDaysOptions[currentRequiredPasswordChangeDaysIndex] : this.requiredPasswordChangeDaysOptions[1],
      passwordMinimumLengthEnabled: this.getCheckboxChecked('PasswordMinimumLength'),
      passwordMinimumLengthValue: currentPasswordMinimumIndex !== -1 ? this.passwordMinimumCharacters[currentPasswordMinimumIndex] : this.passwordMinimumCharacters[3]
    });
  }

  private setupDisabledListener(selectControlName: string, checkboxControlName: string) {
    const sub = combineLatest([
      this.readonly$,
      this.changeSecuritySettingsForm.get(checkboxControlName).valueChanges.pipe(
        startWith(this.changeSecuritySettingsForm.get(checkboxControlName).value)
      )
    ]).subscribe(([readonly, checked]) => {
      if (readonly || !checked) {
        this.changeSecuritySettingsForm.get(selectControlName).disable();
      } else {
        this.changeSecuritySettingsForm.get(selectControlName).enable();
      }
    });

    if (this.disabledSubscription) {
      this.disabledSubscription.add(sub);
    } else {
      this.disabledSubscription = sub;
    }
  }

  private getCheckboxChecked(field: string): boolean {
    return this.securitySettings[field] && typeof this.securitySettings[field] === 'number';
  }
}
