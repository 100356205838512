import { FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, ViewportRuler } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ElementFlexibleConnectedPositionStrategy } from '@portal-core/ui/overlay/util/element-flexible-connected-position.strategy';
import { ElementOverlayContainer } from '@portal-core/ui/overlay/util/element-overlay-container';

@Injectable({
  providedIn: 'root'
})
export class ElementOverlayPositionBuilderService {
  constructor(private viewportRuler: ViewportRuler, @Inject(DOCUMENT) private document: Document, private platform: Platform) { }

  /**
   * Creates a flexible position strategy.
   * @param overlayContainerElement The overlay element that the position is within.
   * @param origin Origin relative to which to position the overlay.
   */
  flexibleConnectedTo(overlayContainerElement: HTMLElement, origin: FlexibleConnectedPositionStrategyOrigin): FlexibleConnectedPositionStrategy {
    return new ElementFlexibleConnectedPositionStrategy(origin, this.viewportRuler, this.document, this.platform, new ElementOverlayContainer(overlayContainerElement));
  }
}
