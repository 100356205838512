import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ColorAdapter } from '@portal-core/ui/color-picker/services/color-adapter';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-project-settings-form',
  templateUrl: './project-settings-form.component.html',
  styleUrls: ['./project-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSettingsFormComponent implements OnChanges {
  @Input() project: Project;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    return this.projectSettingsForm?.dirty;
  }

  projectSettingsForm: UntypedFormGroup;
  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private projectsService: ProjectsService,
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private colorAdapter: ColorAdapter) {
    // Do this in the constructor so that it is ready for ngOnChanges
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project) {
      this.resetForm(this.project || {} as Project);
    }
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    this.savingState.update(true);

    const project: Project = {
      Color: formGroup.value['Color']?.hex ? `#${formGroup.value['Color']?.hex}` : null,
      Description: formGroup.value['Description'],
      Id: this.project.Id,
      Initials: formGroup.value['Initials'],
      LicenseId: this.project.LicenseId,
      Name: formGroup.value['Name']
    }

    this.projectsService.updateProject$(project)
      .subscribe(() => {
        this.savingState.update(false);
        this.projectSettingsForm.markAsPristine();
        this.snackBar.open('The settings have been saved.', 'Ok', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to update the project settings.', this.errorService.getErrorMessages(error));
      });
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  protected buildForm() {
    this.projectSettingsForm = this.formBuilder.group({
      Color: new UntypedFormControl(null),
      Description: new UntypedFormControl(null),
      Initials: new UntypedFormControl(null, [Validators.required, Validators.maxLength(3)]),
      Name: new UntypedFormControl(null, [Validators.required])
    });
  }

  protected resetForm(project: Project) {
    this.projectSettingsForm.reset({
      Color: this.colorAdapter.parse(project.Color) || null,
      Description: project.Description || '',
      Initials: project.Initials || '',
      Name: project.Name || ''
    });

    // Manually sets Color Control as Pristine due to the binding to the mcc color picker.
    this.projectSettingsForm.controls.Color.markAsPristine();
  }
}
