import { CdkScrollable } from '@angular/cdk/scrolling';
import { Directive } from '@angular/core';
import { DynamicViewBase } from '@portal-core/text-editor/util/dynamic-view.base';

/**
 * The base class for plugin view components.
 */
@Directive()
export abstract class PluginViewBase extends DynamicViewBase {
  editorOverlayContainer: HTMLElement;
  scrollable: CdkScrollable;
}
