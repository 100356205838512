import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CentralInstance } from '@portal-core/auth/models/central-instance.model';
import { WINDOW } from '@portal-core/util/window.provider';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly CentralInstancePath: string = 'i'
  private readonly ArchivePath: string = 'archive';
  private readonly AssetPath: string = 'asset';
  private readonly ContentPath: string = 'content';
  private readonly PublishPath: string = 'publish';

  /**
   * The base URI for the global Central API.
   */
  get centralGlobalApiBaseUri(): string {
    return this._centralGlobalApiBaseUri;
  }
  private _centralGlobalApiBaseUri: string = environment.centralApiBaseUri;

  /**
   * The base URI for the Central API.
   * The URI contains the license subdomain if the portal was accessed with a subdomain, eg `example.api.madcapcentral.com`.
   * The URI also contains the Central instance if `setInstanceUrl` has been called, eg `example.api.eugwc.madcapcentral.com`.
   */
  get centralApiBaseUri(): string {
    return this._centralApiBaseUri;
  }
  private _centralApiBaseUri: string = environment.centralApiBaseUri;

  /**
   * The base URI for the Collab API.
   * The URI contains the license subdomain if the portal was accessed with a subdomain, eg `example.collab-api.madcapcentral.com`.
   * The URI also contains the Central instance if `setInstanceUrl` has been called, eg `example.collab-api.eugwc.madcapcentral.com`.
   */
  get collabApiUrl(): string {
    return this._collabApiUrl;
  }
  private _collabApiUrl: string = environment.collabApiUrl;

  /**
   * The base URI for the Collab Authority Service.
   * The URI contains the license subdomain if the portal was accessed with a subdomain, eg `example.collab.madcapcentral.com`.
   * The URI also contains the Central instance if `setInstanceUrl` has been called, eg `example.collab.eugwc.madcapcentral.com`.
   */
  get collabAuthorityUrl(): string {
    return this._collabAuthorityUrl;
  }
  private _collabAuthorityUrl: string = environment.collabAuthorityUrl;

    /**
   * The base URI for the Central instance's archives.
   * The base URI contains the Central instance code if `setInstanceCode` has been called, eg `i/eugwc/archive`.
   * If no instance code is set then the base URI is just `archive`.
   */
    get centralArchiveBaseUri(): string {
      return this._centralArchiveBaseUri;
    }
    private _centralArchiveBaseUri: string = this.ArchivePath;

  /**
   * The base URI for the Central instance's assets.
   * The base URI contains the Central instance code if `setInstanceCode` has been called, eg `i/eugwc/asset`.
   * If no instance code is set then the base URI is just `asset`.
   */
  get centralAssetsBaseUri(): string {
    return this._centralAssetsBaseUri;
  }
  private _centralAssetsBaseUri: string = this.AssetPath;

  /**
   * The base URI for the Central instance's content.
   * The base URI contains the Central instance code if `setInstanceCode` has been called, eg `i/eugwc/content`.
   * If no instance code is set then the base URI is just `content`.
   */
  get centralContentBaseUri(): string {
    return this._centralContentBaseUri;
  }
  private _centralContentBaseUri: string = this.ContentPath;

  /**
   * The base URI for the Central instance's published sites.
   * The base URI contains the Central instance code if `setInstanceCode` has been called, eg `i/eugwc/publish`.
   * If no instance code is set then the base URI is just `publish`.
   */
  get centralPublishBaseUri(): string {
    return this._centralPublishBaseUri;
  }
  private _centralPublishBaseUri: string = this.PublishPath;

  /**
   * The Central instance that is currently being used for API, collab, and blob requests.
   */
  get centralInstance(): CentralInstance {
    return this._centralInstance;
  }
  private _centralInstance: CentralInstance;

  public centralInstance$: BehaviorSubject<CentralInstance> = new BehaviorSubject<CentralInstance>(undefined);

  /**
   * The Central instance code that is currently being used for API, collab, and blob requests.
   */
  get centralInstanceInstanceCode(): string {
    return this._centralInstance?.InstanceCode;
  }

  /**
   * The Central instance blob path prefix that is currently being used for blob requests.
   */
  get centralInstanceBlobPathPrefix(): string {
    return this._centralInstance?.InstanceCode ? `${this.CentralInstancePath}/${this._centralInstance.InstanceCode}` : '';
  }

  constructor(@Inject(WINDOW) private window: Window) {
    if (environment.subdomainPortal) {
      this._centralGlobalApiBaseUri = this.getSubdomainUrl(environment.centralApiSubdomainUri, environment.subdomainPortal);
      this._collabAuthorityUrl = this.getSubdomainUrl(environment.collabAuthorityUrl, environment.subdomainPortal);
      this._collabApiUrl = this.getSubdomainUrl(environment.collabApiUrl, environment.subdomainPortal);
    }
  }

  /**
   * Updates the URLs for the Central API, Collab Server, and blob data (eg assets, content, publish).
   * @param centralInstance The Central instance to use for API, collab, and blob requests.
   */
  setCentralInstance(centralInstance?: CentralInstance) {
    this._centralApiBaseUri = this.getSubdomainUrl(centralInstance?.ApiUrl ?? environment.centralApiBaseUri, environment.subdomainPortal);
    this._collabAuthorityUrl = this.getSubdomainUrl(centralInstance?.CollabAuthorityUrl ?? environment.collabAuthorityUrl, environment.subdomainPortal);
    this._collabApiUrl = this.getSubdomainUrl(centralInstance?.CollabApiUrl ?? environment.collabApiUrl, environment.subdomainPortal);
    this._centralArchiveBaseUri = centralInstance?.InstanceCode ? `${this.CentralInstancePath}/${centralInstance.InstanceCode}/${this.ArchivePath}` : this.ArchivePath;
    this._centralAssetsBaseUri = centralInstance?.InstanceCode ? `${this.CentralInstancePath}/${centralInstance.InstanceCode}/${this.AssetPath}` : this.AssetPath;
    this._centralContentBaseUri = centralInstance?.InstanceCode ? `${this.CentralInstancePath}/${centralInstance.InstanceCode}/${this.ContentPath}` : this.ContentPath;
    this._centralPublishBaseUri = centralInstance?.InstanceCode ? `${this.CentralInstancePath}/${centralInstance.InstanceCode}/${this.PublishPath}` : this.PublishPath;
    this._centralInstance = centralInstance;
    this.centralInstance$.next(centralInstance);
  }

  private getSubdomainUrl(baseUri: string, includeSubdomain: boolean): string {
    const baseUrl = new URL(baseUri);
    let subdomainPart = '';

    // If the portal should use a subdomain in its API requests
    if (includeSubdomain) {
      // Grab the portal's subdomain and combine it with the Central API base URI from the environment config
      const [subdomain] = this.window.location.hostname.split('.');
      subdomainPart = `${subdomain}.`;
    }

    return `${baseUrl.protocol}//${subdomainPart}${baseUrl.host}${baseUrl.pathname !== '/' ? baseUrl.pathname : ''}`;
  }
}
