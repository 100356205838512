import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { PageFilterGroupType } from '@common/paged-data/enums/page-filter-group-type.enum';
import { PageFilterOperator } from '@common/paged-data/enums/page-filter-operator.enum';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilterOptions } from '@common/paged-data/types/page-filter-options.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { FileService } from '@portal-core/general/services/file.service';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { ChangeTaskPosition } from '@portal-core/tasks/models/change-task-position.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { TaskAssetsDataService } from '@portal-core/tasks/services/task-assets-data.service';
import { TaskCommentsDataService } from '@portal-core/tasks/services/task-comments-data.service';
import { TasksApiService } from '@portal-core/tasks/services/tasks-api.service';
import { TasksDataService } from '@portal-core/tasks/services/tasks-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, first, forkJoin, map, merge, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TasksService extends CollectionServiceBase<Task> {
  constructor(
    private tasksDataService: TasksDataService,
    private taskCommentsDataService: TaskCommentsDataService,
    private taskAssetsDataService: TaskAssetsDataService,
    private tasksApiService: TasksApiService,
    private fileService: FileService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    protected dataService: DataService) {
    super(tasksDataService, dataService);
  }

  reset$(): Observable<any> {
    return merge(
      this.taskAssetsDataService.reset$(),
      this.taskCommentsDataService.reset$(),
      this.tasksDataService.reset$()
    );
  }

  fetchItemById$(itemId: number): Observable<Task> {
    return this.tasksApiService.getTask$(itemId);
  }

  getTasksByStatus$(licenseId: number, taskStatus: TaskStatus, taskBoardId: number): Observable<Task[]> {
    return this.dataService.getData<Task[]>({
      get: () => this.tasksDataService.getItemsByStatus$(taskBoardId, taskStatus),
      fetch: () => this.byStatus$(licenseId, taskStatus, taskBoardId),
      set: tasks => {
        this.tasksDataService.setInitialized$(taskBoardId, taskStatus, true);
        this.tasksDataService.addItems$(tasks);
      }
    });
  }

  getTasksByFilter$(licenseId: number, filter?: PageFilter): Observable<Page<Task>> {
    return this.tasksApiService.getTasksByFilter$(licenseId, filter);
  }

  getTasksByMilestone$(licenseId: number, milestone: TaskMilestone, taskBoardId: number, options: GetDataOptions = null): Observable<Task[]> {
    return this.dataService.getData<Task[]>({
      get: () => this.tasksDataService.getItemsByMilestone$(taskBoardId, milestone),
      fetch: () => this.byStatus$(licenseId, TaskStatus.Active, taskBoardId),
      set: tasks => {
        this.tasksDataService.setInitialized$(taskBoardId, TaskStatus.Active, true);
        this.tasksDataService.addItems$(tasks);
      }
    }, options)
      .pipe(map(tasks => tasks
        ? tasks.filter(task => task.Milestone === milestone)
        : undefined));
  }

  getTasks$(licenseId: number, taskBoardId: number): Observable<Task[]> {
    return this.dataService.getData<Task[]>({
      get: () => this.tasksDataService.getTasks$(taskBoardId),
      fetch: () => this.byAllStatuses$(licenseId, taskBoardId),
      set: tasks => {
        Object.keys(TaskStatus)
          .filter(status => !/^\d+$/.test(status))
          .forEach(status => {
            this.tasksDataService.setInitialized$(taskBoardId, TaskStatus[status], true);
          });
        this.tasksDataService.addItems$(tasks);
      }
    });
  }

  getAllTasks$(licenseId: number): Observable<Task[]> {
    return this.dataService.getData<Task[]>({
      get: () => this.tasksDataService.getItems$().pipe(map(tasks => tasks ? Object.values(tasks) : null)),
      fetch: () => this.byAllStatuses$(licenseId),
      set: tasks => {
        const taskBoardIds = [];
        for (const task of tasks) {
          if (!taskBoardIds.some(id => task.TaskBoardId === id)) {
            taskBoardIds.push(task.TaskBoardId);
            Object.keys(TaskStatus)
              .filter(status => !/^\d+$/.test(status))
              .forEach(status => {
                this.tasksDataService.setInitialized$(task.TaskBoardId, TaskStatus[status], true);
              });
          }
        }
        this.tasksDataService.addItems$(tasks);
      }
    });
  }

  changeMilestones$(milestone: TaskMilestone, taskIds: number[]): Observable<MadCloudResult> {
    return this.tasksApiService.changeMilestones$(milestone, taskIds);
  }

  changeStatuses$(status: TaskStatus, taskIds: number[]): Observable<MadCloudResult> {
    return this.tasksApiService.changeStatuses$(status, taskIds);
  }

  changePositions$(changeTaskPositions: ChangeTaskPosition[]): Observable<MadCloudResult> {
    return this.tasksApiService.changePositions$(changeTaskPositions);
  }

  getTaskAssetBlob$(url: string): Observable<HttpResponse<Blob>> {
    return this.tasksApiService.getTaskAssetBlob$(url);
  }

  downloadTaskAsset(url: string, fileName: string) {
    this.getTaskAssetBlob$(url).pipe(
      first(blob => !!blob)
    ).subscribe(blob => this.fileService.downloadData(blob.body, blob.headers.get('Content-Type'), fileName),
      error => this.handleError(error)
    );
  }

  createTask$(task: FormData): Observable<Task> {
    return this.tasksApiService.createTask$(task).pipe(map(result => {
      if (result !== undefined) {
        this.tasksDataService.addItems$([result]);
        // todo: potentially leverage taskAssetsDataService, taskCommentsDataService.
        // Unnecessary currently as all operations on assets/comments occur inside task card.
      }
      return result;
    }));
  }

  updateTask$(taskId: number, task: FormData): Observable<Task> {
    return this.tasksApiService.updateTask$(taskId, task).pipe(map(result => {
      if (result !== undefined) {
        this.tasksDataService.updateItems$([result]);
        // todo: potentially leverage taskAssetsDataService, taskCommentsDataService.
        // Unnecessary currently as all operations on assets/comments occur inside task card.
      } else {
        // TODO: do something here?
      }
      return result;
    }));
  }

  deleteTasks$(taskIds: number[]): Observable<MadCloudResult> {
    return this.tasksApiService.deleteTasks$(taskIds).pipe(
      tap(() => this.tasksDataService.removeItems$(taskIds))
    );
  }

  clearTasksByStatus$(taskBoardId: number, status: TaskStatus): Observable<any> {
    const tasks = this.tasksDataService.getItemsByProperties('Status', status);
    return this.tasksDataService.removeItems$(tasks).pipe(
      switchMap(() => this.tasksDataService.setInitialized$(taskBoardId, status, false))
    );
  }

  clearTasks$(taskBoardId: number): Observable<any> {
    const tasks = this.tasksDataService.getItems();
    return this.tasksDataService.removeItems$(tasks).pipe(
      switchMap(() => forkJoin(
        [this.tasksDataService.setInitialized$(taskBoardId, TaskStatus.Active, false),
        this.tasksDataService.setInitialized$(taskBoardId, TaskStatus.Archive, false),
        this.tasksDataService.setInitialized$(taskBoardId, TaskStatus.Backlog, false)]))
    );
  }

  private byStatus$(licenseId: number, status: TaskStatus, taskBoardId: number): Observable<Task[]> {
    if (!taskBoardId) {
      throw new Error('unable to pull tasks with undefined taskboardid');
    }
    const statusFilterOption: PageFilterOptions = {
      FilterType: PageFilterType.Equals,
      PropertyName: 'Status',
      PropertyType: PageDataType.Select,
      PropertyValue: status
    };
    const taskBoardFilterOption: PageFilterOptions = {
      FilterType: PageFilterType.Equals,
      PropertyName: 'TaskBoardId',
      PropertyType: PageDataType.Int,
      PropertyValue: taskBoardId
    };
    const filterGroup: PageFilterGroup = {
      Id: 'tasks-service-by-status-board-id',
      Type: PageFilterGroupType.Custom,
      Operator: PageFilterOperator.And,
      Filters: [statusFilterOption, taskBoardFilterOption]
    };
    const filter: PageFilter = {
      Id: 'tasks-service-by-status',
      Type: PageFilterGroupType.Custom,
      FilterGroups: [filterGroup],
      PerPage: -1,
      OrderBy: 'Title',
      OrderDirection: 'desc'
    };
    return this.tasksApiService.getTasksByFilter$(licenseId, filter).pipe(map(gridPage => gridPage.Items));
  }

  private byAllStatuses$(licenseId: number, taskBoardId?: number): Observable<Task[]> {
    const statusFilterGroup: PageFilterGroup = {
      Id: 'tasks-service-by-all-statuses',
      Type: PageFilterGroupType.Custom,
      Operator: PageFilterOperator.Or,
      Filters: []
    };
    Object.keys(TaskStatus)
      .filter(status => /^\d+$/.test(status))
      .forEach(status => {
        const statusFilterOption: PageFilterOptions = {
          FilterType: PageFilterType.Equals,
          PropertyName: 'Status',
          PropertyType: PageDataType.Select,
          PropertyValue: status
        };
        statusFilterGroup.Filters.push(statusFilterOption);
      });

    const filter: PageFilter = {
      Id: 'tasks-service-by-all-statuses-title',
      Type: PageFilterGroupType.Custom,
      FilterGroups: [statusFilterGroup],
      PerPage: -1,
      OrderBy: 'Title',
      OrderDirection: 'desc'
    };
    if (taskBoardId) {
      const taskBoardFilterOption: PageFilterOptions = {
        FilterType: PageFilterType.Equals,
        PropertyName: 'TaskBoardId',
        PropertyType: PageDataType.Int,
        PropertyValue: taskBoardId
      };

      const taskBoardFilterGroup: PageFilterGroup = {
        Id: 'tasks-service-by-all-statuses-board-id',
        Type: PageFilterGroupType.Custom,
        Operator: PageFilterOperator.And,
        Filters: [taskBoardFilterOption]
      };

      filter.FilterGroups.push(taskBoardFilterGroup);
    }
    return this.tasksApiService.getTasksByFilter$(licenseId, filter).pipe(map(gridPage => gridPage.Items));
  }

  private handleError(error: HttpErrorResponse | Error | MadCloudResult<any>) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: 'Error Downloading Asset',
        message: 'An unexpected error happened while downloading the task asset blob',
        errors: this.errorService.getErrorMessages(error)
      }
    });
  }
}
