import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

/**
 * mc-flare-avatar
 * A component for displaying the flare avatar image and name.
 */
@Component({
  selector: 'mc-flare-avatar',
  templateUrl: './flare-avatar.component.html',
  styleUrls: ['./flare-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlareAvatarComponent extends AvatarBase {
  hasAvatarImage(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return false;
  }

  isLink(): boolean {
    return false;
  }
}

