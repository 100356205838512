export const DimensionStylePresets = [
  'max-content',
  'min-content',
  'fit-content',
  'auto',
  'inherit',
  'initial',
  'revert',
  'revert-layer',
  'unset'
];
