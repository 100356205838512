import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CoreService } from '@portal-client/core/core.service';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { MessageCenterDialogCardComponent } from '@portal-core/messages/components/message-center/message-center-dialog-card.component';
import { ConversationsService } from '@portal-core/messages/services/conversations.service';
import { AlertCenterDialogCardComponent, AlertCenterDialogMode } from '@portal-core/notifications/components/alert-center/alert-center-dialog-card.component';
import { TasksFilterService } from '@portal-core/tasks/services/tasks-filter.service';
import { UserProfileDialogComponent } from '@portal-core/users/components/user-profile-dialog/user-profile-dialog.component';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subscription, distinctUntilChanged, filter, first } from 'rxjs';

@Component({
  selector: 'mc-app-navbar-user-launch-pad',
  templateUrl: './app-navbar-user-launch-pad.component.html',
  styleUrls: ['./app-navbar-user-launch-pad.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class AppNavbarUserLaunchPadComponent implements OnInit {
  currentLicenseUser$: Observable<LicenseUser>;
  licenseUser: LicenseUser;
  appVersionAndBuildDate$: Observable<string>;
  taskSearchValue: string;
  showTaskSearchBox: boolean = false;
  currentLicenseUserMessagesSubscription: Subscription;
  private routerEventsSub: Subscription;

  constructor(
    private coreService: CoreService,
    private dataService: DataService,
    private licenseUsersService: LicenseUsersService,
    private conversationsService: ConversationsService,
    private dialog: MatDialog,
    public tasksFilterService: TasksFilterService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.currentLicenseUser$ = this.coreService.getLicenseUser$();

    this.currentLicenseUserMessagesSubscription = this.currentLicenseUser$.pipe(
      // Only authors get messages
      filter(licenseUser => licenseUser && licenseUser.SeatType === LicenseUserSeatType.Author),
      distinctUntilChanged(this.dataService.sameIdentity)
    ).subscribe(licenseUser => {
      this.conversationsService.getMyConversationUnreadCountByLicenseId$(licenseUser.LicenseId).subscribe(unreadCount => {
        this.licenseUsersService.setLicenseUserHasNewMessages(licenseUser.Id, Number.isInteger(unreadCount) && unreadCount > 0);
      });
    });

    // Create observables for the search field
    this.showTaskSearchBox = this.router.url.search('/tasks/boards/[0-9]+') !== -1;
    this.routerEventsSub = this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.taskSearchValue) {
          this.taskSearchValue = '';
          const filters = this.tasksFilterService.getTaskFilters(LicenseUserClientSideInfoKey.AllTaskPages);
          filters.Search = '';
          this.tasksFilterService.setTaskFilters(LicenseUserClientSideInfoKey.AllTaskPages, filters);
        }
      } else {
        this.showTaskSearchBox = this.router.url.search('/tasks/boards/[0-9]+') !== -1;
        this.ref.detectChanges();
      }
    });

    // Create an observable for the app version
    this.appVersionAndBuildDate$ = this.coreService.getVersionAndBuildDate$();
  }

  onMyProfileClicked(licenseUser: LicenseUser) {
    this.dialog.open(UserProfileDialogComponent, {
      ...UserProfileDialogComponent.DialogConfigForLicenseUser(licenseUser, licenseUser),
      data: {
        licenseId: licenseUser.LicenseId,
        licenseUserId: licenseUser.Id,
        userId: licenseUser.User.Id
      }
    });
  }

  onSearchEntered() {
    this.searchTasks();
  }

  onSearchClearClicked() {
    this.taskSearchValue = '';
    this.searchTasks();
  }

  onMessagesClicked() {
    this.currentLicenseUser$.pipe(
      first()
    ).subscribe(
      (licenseUser) => {
        this.dialog.open(MessageCenterDialogCardComponent, {
          ...MessageCenterDialogCardComponent.DialogConfig,
          data: { licenseUser }
        });
      });
  }

  onAlertsClicked() {
    const licenseId = this.coreService.getLicenseId();

    // Sends the event to Google Analytics
    this.analyticsService.trackEvent('notifications_view', {
      'event_category': 'Notifications',
      'event_label': 'Notifications Viewed',
      'license_id': licenseId
    });

    this.dialog.open(AlertCenterDialogCardComponent, {
      ...AlertCenterDialogCardComponent.DialogConfig,
      data: {
        dialogMode: AlertCenterDialogMode.MyNotifications,
        licenseId
      }
    });
  }

  onUserSettingsClicked(licenseUser: LicenseUser): void {
    if (!licenseUser) {
      return;
    }
    this.dialog.open(UserProfileDialogComponent, {
      ...UserProfileDialogComponent.DialogConfigForLicenseUser(licenseUser, licenseUser),
      data: {
        licenseId: licenseUser.LicenseId,
        licenseUserId: licenseUser.Id,
        userId: licenseUser.User.Id
      }
    });
  }

  private searchTasks() {
    this.tasksFilterService.updateAllFiltersByGlobalSearch(this.taskSearchValue);
  }
}
