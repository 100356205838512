import { Pipe, PipeTransform } from '@angular/core';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';

@Pipe({
  name: 'mcReviewPackageColorClass'
})
export class ReviewPackageColorClassPipe implements PipeTransform {
  transform(reviewPackage: ReviewPackage | null | undefined, style: 'bg' | 'border' | 'fill', luminosity: '' | 'muted' | 'vivid' = ''): string {
    switch (reviewPackage?.Status) {
      case ReviewPackageStatus.Closed:
        return `mc-${style}-info${luminosity ? '-' + luminosity : ''}`;
      case ReviewPackageStatus.Open:
        return `mc-${style}-caution${luminosity ? '-' + luminosity : ''}`;
    }
  }
}
