import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { CurrentService } from '@portal-core/current/services/current.service';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AuditCategory } from '@portal-core/notifications/models/audit-category.model';
import { MyNotificationPage } from '@portal-core/notifications/models/my-notification-page.model';
import { NotificationDeliveryMethod } from '@portal-core/notifications/models/notification-delivery-method.model';
import { NotificationPage } from '@portal-core/notifications/models/notification-page.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { UserSubscription } from '@portal-core/notifications/models/subscription.model';
import { NotificationsApiService } from '@portal-core/notifications/services/notifications-api.service';
import { NotificationsDataService } from '@portal-core/notifications/services/notifications-data.service';
import { NotificationMessageInstruction } from '@portal-core/notifications/types/notification-message-instruction.type';
import { parseNotificationMessage } from '@portal-core/notifications/util/message-parser';
import { ProjectChecklistsService } from '@portal-core/project-checklists/services/project-checklists.service';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { TasksService } from '@portal-core/tasks/services/tasks.service';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { TranslationPackageLanguageBranchesService } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches.service';
import { TranslationPackagesService } from '@portal-core/translation-packages/services/translation-packages.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { forEach, sortBy } from 'lodash';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class NotificationsService extends CollectionServiceBase<Notification> {
  constructor(
    private notificationsApiService: NotificationsApiService,
    private notificationsDataService: NotificationsDataService,
    protected dataService: DataService,
    private licensesService: LicensesService,
    private licenseUsersService: LicenseUsersService,
    private projectsService: ProjectsService,
    private projectChecklistsService: ProjectChecklistsService,
    private reviewFilesService: ReviewFilesService,
    private tasksService: TasksService,
    private taskBoardsService: TaskBoardsService,
    private teamsService: TeamsService,
    private currentService: CurrentService,
    private translationPackagesService: TranslationPackagesService,
    private translationPackageLanguageBranchesService: TranslationPackageLanguageBranchesService
  ) {
    super(notificationsDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<Notification> {
    return this.notificationsApiService.getNotificationById$(itemId).pipe(
      tap(notification => this.processNotifications([notification]))
    );
  }

  getMyNotifications$(licenseId: number, pageSize: number, pageIndex: number = 0, options: GetDataOptions = null): Observable<MyNotificationPage> {
    return this.notificationsApiService.getMyNotifications$(licenseId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          const notifications = page.Notifications.map(myNotification => {
            myNotification.Notification.IsMyNotification = true;
            return myNotification.Notification;
          });

          if (notifications && notifications.length > 0) {
            this.notificationsDataService.addItems$(this.processNotifications(notifications));
          }
        }
      })
    );
  }

  getNotificationsByProjectId$(projectId: number, pageSize: number, pageIndex: number = 0, options: GetDataOptions = null): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByProjectId$(projectId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {

          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByUserIdAndLicenseId$(userId: string, licenseId: number, pageSize: number, pageIndex: number = 0, options: GetDataOptions = null): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByUserIdAndLicenseId$(userId, licenseId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByTeamId$(teamId: number, pageSize: number, pageIndex: number = 0, options: GetDataOptions = null): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByTeamId$(teamId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByReviewId$(reviewId: number, pageSize: number, pageIndex: number = 0, options: GetDataOptions = null): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByReviewId$(reviewId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByReviewPackageId$(reviewPackageId: number, pageSize: number, pageIndex: number = 0): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByReviewPackageId$(reviewPackageId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  getTaskNotificationsByLicenseId$(licenseId: number, pageSize: number, pageIndex: number = 0, taskBoardId: number = null, options: GetDataOptions = null): Observable<NotificationPage> {
    return this.notificationsApiService.getTaskNotificationsByLicenseId$(licenseId, pageSize, pageIndex, taskBoardId).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByTranslationPackageLanguageId$(translationPackageId: number, pageSize: number, pageIndex: number = 0): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByTranslationPackageLanguageId$(translationPackageId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  getNotificationsByTranslationPackageId$(translationPackageId: number, pageSize: number, pageIndex: number = 0): Observable<NotificationPage> {
    return this.notificationsApiService.getNotificationsByTranslationPackageId$(translationPackageId, pageSize, pageIndex).pipe(
      tap(page => {
        if (Array.isArray(page.Notifications) && page.Notifications.length > 0) {
          this.notificationsDataService.addItems$(this.processNotifications(page.Notifications));
        }
      })
    );
  }

  markAllMyNotificationsAsRead$(): Observable<any> {
    const licenseUserId = this.currentService.getLicenseUserId();
    if (typeof licenseUserId === 'number') {
      this.licenseUsersService.setLicenseUserHasNewNotifications([licenseUserId], false);
    }

    return this.notificationsApiService.markAllMyNotificationsAsRead$();
  }

  // getMyNotifications(licenseId: string): Observable<Notification[]> {
  //   return this.NotficationsApiService.getUserNotifications$(licenseId: string, options: any);
  // }

  getAuditCategories$(): Observable<AuditCategory[]> {
    return this.notificationsApiService.getAuditCategories$();
  }

  getNotificationDeliveryMethod$(): Observable<NotificationDeliveryMethod> {
    return this.notificationsApiService.getNotificationDeliveryMethod$();
  }

  saveNotificationDeliveryMethod$(deliveryMethod: NotificationDeliveryMethod): Observable<MadCloudResult> {
    return this.notificationsApiService.saveNotificationDeliveryMethod$(deliveryMethod);
  }

  saveUserNotifications$(categoryIds: number[]): Observable<UserSubscription[]> {
    return this.notificationsApiService.saveUserNotifications$(categoryIds);
  }

  addDataStreamNotifications(notifications: Notification[]) {
    if (Array.isArray(notifications) && notifications.length > 0) {
      const licenseId = this.currentService.getActiveLicenseId();
      const licenseNotifications = notifications.filter(notification => notification.LicenseId === licenseId);
      this.notificationsDataService.addItems$(this.processNotifications(licenseNotifications));
    }
  }

  getAuditCategoryTree$(): Observable<AuditCategory[]> {
    return this.getAuditCategories$().pipe(
      // Filter out invisible categories
      map(categories => categories.filter(category => category.Visible)),
      // Convert the categories into a tree of categories
      map(categories => {
        // Create the list of parent categories
        const categoryTree = categories.filter(category => category.ParentCategoryId == null);

        // Add subcategories
        categoryTree.forEach(parentCategory => {
          const subCategories = categories.filter(category => category.ParentCategoryId === parentCategory.Id);
          if (subCategories.length > 0) {
            parentCategory.SubCategories = sortBy(subCategories, 'Title');
          }
        });

        // Order the categories
        const sortedTree = sortBy(categoryTree, 'Title');
        return sortedTree;
      })
    );
  }

  processNotifications(notifications: Notification[]): Notification[] {
    const currentLicenseId = this.currentService.getActiveLicenseId();
    const checklists = {};
    const licenses = {};
    const licenseUsers = {};
    const projects = {};
    const reviews = {};
    const tasks = {};
    const taskBoards = {};
    const teams = {};
    const userIdsByLicense = {};
    const translationPackage = {};
    const translationPackageLanguageBranch = {};

    // Loop through every notification gathering all the data entities on them to add to the data stores in bulk
    notifications.forEach(notification => {
      if (notification.Checklist) {
        checklists[notification.Checklist.Id] = notification.Checklist;
        delete notification.Checklist.Analytics; // This always come back as null for notifications clearing out existing analytics data
      }

      if (notification.License) {
        licenses[notification.License.Id] = notification.License;
      }

      if (notification.LicenseUser) {
        licenseUsers[notification.LicenseUser.Id] = notification.LicenseUser;
      }

      if (notification.Project) {
        projects[notification.Project.Id] = notification.Project;
      }

      if (notification.ReviewFile) {
        reviews[notification.ReviewFile.Id] = notification.ReviewFile;
      }

      if (notification.Task) {
        tasks[notification.Task.Id] = notification.Task;
      }

      if (notification.TaskBoard) {
        taskBoards[notification.TaskBoard.Id] = notification.TaskBoard;
      }

      if (notification.Team) {
        teams[notification.Team.Id] = notification.Team;
      }

      if (notification.UserId && typeof currentLicenseId === 'number') {
        userIdsByLicense[currentLicenseId] = userIdsByLicense[currentLicenseId] || {};
        userIdsByLicense[currentLicenseId][notification.UserId] = true;
      }

      if (notification.InvokerUserId && typeof notification.LicenseId === 'number') {
        userIdsByLicense[notification.LicenseId] = userIdsByLicense[notification.LicenseId] || {};
        userIdsByLicense[notification.LicenseId][notification.InvokerUserId] = true;
      }

      if (notification.TranslationPackage) {
        translationPackage[notification.TranslationPackageId] = notification.TranslationPackage;
      }

      if (notification.TranslationPackageLanguageBranch) {
        translationPackageLanguageBranch[notification.TranslationPackageLanguageBranchId] = notification.TranslationPackageLanguageBranch;
      }
    });

    // Add all the data to the data stores in bulk
    this.addToDataStore(checklists, this.projectChecklistsService);
    this.addToDataStore(licenses, this.licensesService);
    this.addToDataStore(licenseUsers, this.licenseUsersService);
    this.addToDataStore(projects, this.projectsService);
    this.addToDataStore(reviews, this.reviewFilesService);
    this.addToDataStore(tasks, this.tasksService);
    this.addToDataStore(taskBoards, this.taskBoardsService);
    this.addToDataStore(teams, this.teamsService);
    this.addToDataStore(translationPackage, this.translationPackagesService);
    this.addToDataStore(translationPackageLanguageBranch, this.translationPackageLanguageBranchesService);

    // Bulk load the license users
    forEach(userIdsByLicense, (userIds, licenseId) => {
      const userIdArray = Object.keys(userIds);
      if (userIdArray.length > 0) {
        this.licenseUsersService.loadLicenseUsersByUserId$(parseInt(licenseId, 10), userIdArray).subscribe();
      }
    });

    return notifications;
  }

  private addToDataStore<T>(dataMap: { [Id: string]: T }, dataStoreService: CollectionServiceBase<T>) {
    const dataArray = Object.values(dataMap);
    if (dataArray.length > 0) {
      dataStoreService.addItems$(dataArray);
    }
  }

  /**
   * Parses a notification's message into a list of rendering instructions.
   * @param notification The notification to parse.
   * @returns The list of rendering instructions.
   */
  parseNotificationMessage(notification: Notification): NotificationMessageInstruction[] {
    return parseNotificationMessage(notification.Message);
  }
}
