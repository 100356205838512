import { flattenNode } from '@common/html/util/dom';

/**
 * Replaces all elements with the `w` namespace with their children.
 */
export class MsWTagNormalizer {
  /**
   * Replaces all elements with the `w` namespace with their children.
   * @param doc The document in which to normalize `w` tags.
   */
  normalize(doc: Document) {
    // Grab the elements so they can linearly be traversed
    // Replace each w: element with its children
    flattenNode(doc.querySelector('body')).forEach((element: HTMLElement) => {
      if (element.nodeName?.toLowerCase()?.startsWith('w:')) {
        element.replaceWith(...element.childNodes);
      }
    });
  }
}
