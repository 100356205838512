<div class="grid-header">
  <div *ngIf="column.titleHtml" [mat-sort-header]="column.name" [disableClear]="column.disableClear" class="grid-header-title" [ngClass]="column.headerTitleClass" [disabled]="!column.sortEnabled" [innerHTML]="column.titleHtml"></div>
  <div *ngIf="!column.titleHtml && column.title" [mat-sort-header]="column.name" [disableClear]="column.disableClear" class="grid-header-title" [disabled]="!column.sortEnabled">{{ column.title }}</div>
  <button *ngIf="menuEnabled" mat-icon-button [matMenuTriggerFor]="filterMenu" (menuClosed)="onMenuClosed()" (menuOpened)="onMenuOpened()">
    <mat-icon [color]="hasFilter ? 'primary-vivid' : ''" fontSet="project-titan" [fontIcon]="menuButtonIcon"></mat-icon>
  </button>
</div>

<mat-menu #filterMenu="matMenu" yPosition="below" class="mc-menu grid-header-filter-menu">
  <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>

  <ng-container *ngIf="column.filterEnabled">
    <div *ngIf="menuTemplate" class="mc-expansion-panel mc-expansion-panel-form" [ngClass]="{ 'mc-expansion-panel-expanded': showFormPanel }" cdkTrapFocus mcStopPropagation="click" (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()">
      <div class="mc-expansion-panel-header" (click)="showFormPanel = !showFormPanel">
        <span>Filter</span>
        <span class="mc-fill-remaining-flex-space"></span>
        <mat-icon fontSet="project-titan" fontIcon="icon-expand" class="mc-expansion-panel-indicator"></mat-icon>
      </div>
      <div class="mc-expansion-panel-content">
        <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
      </div>
    </div>

    <div *ngIf="!menuTemplate" cdkTrapFocus mcStopPropagation="click" (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()">
      <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
    </div>
  </ng-container>
</mat-menu>