import { metaDataKey } from '@common/prosemirror/plugins/meta-data';
import { VariableNodeViewComponent } from '@portal-core/text-editor/components/variable-node-view/variable-node-view.component';
import { NodeViewType } from '@portal-core/text-editor/enums/node-view-type.enum';
import { ComponentNodeViewOptions } from '@portal-core/text-editor/models/component-node-view-options.model';
import { ComponentNodeView } from '@portal-core/text-editor/node-views/component.node-view';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { Decoration, EditorView } from 'prosemirror-view';
import { Subscription } from 'rxjs';

export class VariableNodeView extends ComponentNodeView {
  private metaDataUpdateSubscription: Subscription;

  constructor(
    node: ProseMirrorNode,
    view: EditorView,
    getPos: GetPosForNodeView,
    decorations: Decoration[],
    create: DynamicViewComponentInjector,
    protected options?: ComponentNodeViewOptions
  ) {
    super(VariableNodeViewComponent, node, view, getPos, decorations, create, options);
  }

  destroy() {
    this.metaDataUpdateSubscription?.unsubscribe();
    super.destroy();
  }

  onComponentCreated() {
    const metaDataPlugin = metaDataKey.get(this.editorView.state);

    // Listen to the meta data plugin for updates so that the variable value can be updated
    this.metaDataUpdateSubscription = metaDataPlugin.spec.update$.subscribe(metaDataState => {
      this.updateComponent();
      this.component.detectChanges();
    });
  }

  updateComponent() {
    const definition = this.convertToValue(this.node.attrs.name);
    const value = this.processVariableText(definition, true);
    // Only emit loaded if the value has changed
    const emitLoaded = value !== this.inputs.value;

    this.inputs.value = value;

    if (emitLoaded) {
      this.emitLoaded({ type: NodeViewType.Variable });
    }
  }

  private convertToValue(longName: string) {
    if (this.isSystem(longName)) {
      return this.formatSystemVariable(longName);
    }

    const metaDataState = metaDataKey.getState(this.editorView.state);
    const variablesMeta = metaDataState?.metaData?.variables;
    const variableMetaLoaded = !!variablesMeta;
    const variable = variablesMeta ? variablesMeta[longName] : null;

    return variable ? variable['definition'] :
      variableMetaLoaded ? this.formatUndefinedVariable(longName) : undefined;
  }


  private formatSystemVariable(longName: string) {
    return `Variable: ${longName}`;
  }

  private formatUndefinedVariable(longName: string) {
    return longName ? `Undefined variable: ${longName}` : 'Undefined variable';
  }

  private isSystem(longName: string): boolean {
    if (!longName)
      return false;
    return longName.startsWith('System.') || longName.startsWith('Heading.');
  }

  private formatTextVariable(longName: string): string {
    return `[%=${longName}%]`;
  }

  /**
   * This code is the same as the Flare VariableTextParser.ProcessVariableText method cause it is used for output files.
   * It's not resolve broken formatted variables that has three or more '[%=' in a row
   * One difference is the added level of nesting to avoid the recursive problem.
   */
  private processVariableText(text: string, removeNewLinesAndTabs: boolean, nestingLevel: number = 0): string {
    if (!text)
      return text;

    let loop: string = text;

    while (true) {
      let start: number = loop.indexOf("[%=");
      if (start === -1) return text;

      let end: number = loop.indexOf("%]");
      if (end === -1) return text;

      //skip over broken formatted variables
      let nextStart: number = loop.indexOf("[%=", start + 3);
      if (nextStart !== -1 && nextStart < end) {
        let nextEnd: number = loop.indexOf("%]");
        if (nextEnd === -1) return text;
      }
      else {
        nextStart = start;
      }

      if (end > nextStart) {
        let temp: string = loop.substring(nextStart + 3, end);
        if (temp) {
          let newtext: string = this.convertToValue(temp);

          if (removeNewLinesAndTabs) {
            newtext = newtext.replace(/\t|\n|\r/g, " ");
          }

          if (newtext !== temp) {
            let fulltemp: string = this.formatTextVariable(temp);

            if (nestingLevel == 0) {
              try {
                text = this.processVariableText(text.replace(fulltemp, newtext), false, nestingLevel + 1);
              } catch (error) {
                return text.replace(fulltemp, `Variable with recursive problem: ${temp}`);
              }
            }
            if (nestingLevel < 5) {
              text = this.processVariableText(text.replace(fulltemp, newtext), false, nestingLevel + 1);
            } else {
              throw Error('Recursive problem in variable.')
            }
          }
        }
      }

      end += 2; // len of %]
      if (loop.length - (end) <= 0) return text;
      loop = loop.substring(end, loop.length - 1);
    }
  }
}
