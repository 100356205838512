import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { User } from '@portal-core/users/models/user.model';

@CollectionStateSelectors({
  stateType: UsersState
})
@State<UsersState>({
  name: UsersState.source
})
@Injectable()
export class UsersState extends CollectionStateBase {
  static source: string = 'Users';

  getSource(): string {
    return UsersState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsersDataService extends CollectionDataServiceBase<User> {
  constructor(protected store: Store) {
    super(store, UsersState);
  }
}
