import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ProjectFilesTreeModule } from '@portal-core/project-files/components/project-files-tree/project-files-tree.module';
import { VariablePickerInputComponent } from '@portal-core/project-files/variables/components/variable-picker-input/variable-picker-input.component';
import { VariableSetsListModule } from '@portal-core/project-files/variables/components/variable-sets-list/variable-sets-list.module';
import { VariablesGridModule } from '@portal-core/project-files/variables/components/variables-grid/variables-grid.module';

@NgModule({
  declarations: [
    VariablePickerInputComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    ProjectFilesTreeModule,
    VariableSetsListModule,
    VariablesGridModule
  ],
  exports: [
    VariablePickerInputComponent
  ]
})
export class VariablePickerInputModule { }
