import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { AttributeSpec, Fragment, Mark, ProseMirrorNode } from 'prosemirror-model';

export interface NodeToFlareXMLOptions {
  /**
   * The children to render instead of the children on the node.
   * If the child is a ProseMirrorNode then it is rendered with toFlareXML.
   * If the child is a string then it is used as the rendered XML.
   */
  children?: (ProseMirrorNode | string)[];
  /**
   * Render the given XML instead of building the XML from the tagName, attributes, and children.
   * The node's marks will still be rendered around the XML.
   */
  xml?: string;
}

export interface FlareXMLOutputSpec {
  attrs?: Dictionary<string>;
  tagName: string;
}

export function fragmentToArray(fragment: Fragment): ProseMirrorNode[] {
  const nodes: ProseMirrorNode[] = [];
  fragment.forEach(node => nodes.push(node));
  return nodes;
}

export function defaultToFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
  return nodeToFlareXML(node, options, node.type.spec.tagName ?? node.type.name);
}

export function encodeAttr(attr: string): string {
  return attr
    .replace(/&/g, '&amp;') // Replace the & first
    // .replace(/'/g, '&apos;') // Not necessary because the attribute quotes are always double quotes
    .replace(/"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

const textReplacements = {
  '\xA0': '&#160;',
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;'
};

export function encodeText(text: string): string {
  return text.replace(/[<>&\xA0]/g, match => textReplacements[match]);
}

export function nodeToFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions, tagName: string, nodeOptions?: NodeToFlareXMLOptions): string {
  let xml = '';

  // Start by wrapping the node in its marks
  const markXMLSpecs: FlareXMLOutputSpec[] = [];
  // Open each mark
  for (let i = 0; i < node.marks.length; i += 1) {
    const mark = node.marks[i];
    const xmlSpec = mark.type.spec.toFlareXML(mark, options);
    if (xmlSpec) {
      markXMLSpecs.push(xmlSpec);
      xml += `<${xmlSpec.tagName}`;
      xml += attrsToFlareXML(xmlSpec.attrs, mark.type.spec.attrs, options, mark.type.name, mark);
      xml += '>';
    }
  }

  if (nodeOptions?.xml) {
    xml += nodeOptions.xml;
  } else if (node.isText) {
    xml += encodeText(node.text);
  } else {
    // Open the tag
    xml += `<${tagName}`;

    // Add the attributes
    xml += attrsToFlareXML(node.attrs, node.type.spec.attrs, options, node.type.name, node);

    if (node.type.spec.isVoid) {
      // Void tags are self-closing
      xml += ' />';
    } else {
      xml += '>';

      // First try getting the content from the contentToFlareXML method as it overrides the default behavior
      const content = node.type.spec.contentToFlareXML?.(node, options, nodeOptions);

      // If the contentToFlareXML method returned a string then use it for the content
      if (typeof content === 'string') {
        xml += content;
      } else {
        xml += contentToFlareXML(node, options, nodeOptions);
      }

      // Close the tag
      xml += `</${tagName}>`;
    }
  }

  // Close each mark
  for (let i = markXMLSpecs.length - 1; i >= 0; i -= 1) {
    xml += `</${markXMLSpecs[i].tagName}>`;
  }

  return xml;
}

function isDictionary(value: any): value is Dictionary<any> {
  return value && typeof value === 'object' && !Array.isArray(value);
}

export function attrsToFlareXML(attributes: Dictionary<string | number>, attrsSpecs: Dictionary<AttributeSpec>, options: ToFlareXMLOptions, nodeName: string, nodeOrMark: ProseMirrorNode | Mark): string {
  let xml = '';

  if (attributes) {
    for (let [attributeName, value] of Object.entries(attributes)) {
      if (attrsSpecs?.[attributeName]?.skipExport) {
        continue;
      }

      if (attrsSpecs?.[attributeName]?.toFlareXML) {
        const newValue = attrsSpecs[attributeName].toFlareXML(value, options, nodeOrMark);
        if (isDictionary(newValue)) {
          xml += attrsToFlareXML(newValue, attrsSpecs, options, nodeName, nodeOrMark);
          continue;
        } else {
          value = newValue;
        }
      }

      if (typeof value !== 'undefined' && value !== null) {
        if (typeof value !== 'string' && typeof value !== 'number') {
          throw new Error(`Attribute value not a string. Node: ${nodeName}, Attribute: ${attributeName}, Value: ${value}`);
        }

        if (value === '' && attributeName === 'class') {
          continue;
        }

        if (attrsSpecs?.[attributeName]?.exportedName) {
          attributeName = attrsSpecs[attributeName].exportedName;
        }

        xml += ` ${attributeName}="${encodeAttr(value.toString())}"`;
      }
    }
  }

  return xml;
}

export function contentToFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions, nodeOptions?: NodeToFlareXMLOptions): string {
  let xml = '';

  // If the options specify children then use them for the content
  if (Array.isArray(nodeOptions?.children)) {
    for (let i = 0; i < nodeOptions.children.length; i += 1) {
      const child = nodeOptions.children[i];
      if (typeof child === 'string') {
        xml += child;
      } else {
        xml += (child.type.spec.toFlareXML ?? defaultToFlareXML)(child, options);
      }
    }
  }
  // Else use the node's children for the content
  else {
    for (let i = 0; i < node.childCount; i += 1) {
      const child = node.child(i);
      xml += (child.type.spec.toFlareXML ?? defaultToFlareXML)(child, options)
    }
  }

  return xml;
}
