import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FullNavbarModule } from '@portal-core/layout/components/full-navbar/full-navbar.module';
import { FullPageComponent } from './full-page.component';

@NgModule({
  imports: [
    CommonModule,
    FullNavbarModule,
    RouterModule
  ],
  declarations: [
    FullPageComponent
  ],
  exports: [
    FullPageComponent
  ]
})
export class FullPageModule { }
