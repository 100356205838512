import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { WebhookSubscriber } from '@portal-core/slack/models/webhook-subscriber.model';
import { WebhookSubscription } from '@portal-core/slack/models/webhook-subscription.model';
import { SlackWebhooksApiService } from '@portal-core/slack/services/slack-webhooks-api.service';
import { SlackWebhookSubscribersDataService } from '@portal-core/slack/services/slack-webhooks-subscribers-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class SlackWebhooksService extends CollectionServiceBase<WebhookSubscriber> {

  constructor(
    private slackWebhooksApiService: SlackWebhooksApiService,
    protected slackWebhookSubscribersDataService: SlackWebhookSubscribersDataService,
    protected dataService: DataService
  ) {
    super(slackWebhookSubscribersDataService, dataService);
  }

  // Subscribers
  getSubscribers$(licenseId: number, options: GetDataOptions = null): Observable<WebhookSubscriber[]> {
    return this.dataService.getDataListItems$<WebhookSubscriber>('Licenses', licenseId, this.slackWebhookSubscribersDataService, {
      fetch: () => this.slackWebhooksApiService.getSubscribers$(licenseId)
    }, options);
  }

  addSubscriber$(newWebhookSubscriber: WebhookSubscriber): Observable<WebhookSubscriber> {
    return this.slackWebhooksApiService.addSubscriber$(newWebhookSubscriber).pipe(
      tap(subscriber => {
        this.addItems$([subscriber]);
        this.slackWebhookSubscribersDataService.addItemsToListById$('Licenses', subscriber.LicenseId, [subscriber]);
      })
    );
  }

  updateSubscriber$(webhookSubscriber: WebhookSubscriber): Observable<WebhookSubscriber> {
    return this.slackWebhooksApiService.updateSubscriber$(webhookSubscriber).pipe(
      tap(subscriber => this.updateItems$([subscriber]))
    );
  }

  deleteSubscriber$(subscriberId: number): Observable<MadCloudResult> {
    return this.slackWebhooksApiService.deleteSubscriber$(subscriberId).pipe(
      tap(() => this.removeItems$([subscriberId]))
    );
  }

  // Subscribers' Subscriptions
  getSubscriptions$(subscriberId: number): Observable<WebhookSubscription[]> {
    return this.slackWebhooksApiService.getSubscriptions$(subscriberId);
  }

  addSubscription$(subscriberId: number, auditCategoryId: number): Observable<WebhookSubscription> {
    return this.slackWebhooksApiService.addSubscription$(subscriberId, auditCategoryId);
  }

  // deleteSubscription$(subscriberId: number, auditCategoryId: number): Observable<MadCloudResult> {
  //   return this.slackWebhooksApiService.deleteSubscription$(subscriberId, auditCategoryId);
  // }

  clearAllSubscriptions$(subscriberId: number): Observable<MadCloudResult> {
    return this.slackWebhooksApiService.clearAllSubscriptions$(subscriberId);
  }
}
