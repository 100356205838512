import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ReviewPackageUserStatus } from '@portal-core/reviews/review-package-users/enums/review-package-user-status.enum';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { catchError, Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewPackageUsersApiService {
  constructor(private http: HttpClient, private apiService: ApiService, private errorService: ErrorService) { }

  @SharePendingRequest()
  getReviewPackageUserById$(reviewPackageUserId: number): Observable<ReviewPackageUser> {
    return this.http.get<ReviewPackageUser>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageUserId}/User`);
  }

  @SharePendingRequest()
  getMyReviewPackageUser$(reviewPackageId: number): Observable<ReviewPackageUser> {
    return this.http.get<ReviewPackageUser>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageId}/Me`).pipe(
      catchError(error => {
        if (this.errorService.hasHttpStatus(error, 404)) {
          return of(null);
        } else {
          return throwError(error);
        }
      })
    );
  }

  @SharePendingRequest()
  getReviewPackageUsersPage$(reviewPackageId: number, filter?: PageFilter): Observable<Page<ReviewPackageUser>> {
    return this.http.post<Page<ReviewPackageUser>>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageId}/Reviewers`, filter);
  }

  setMyReviewPackageUserStatus$(reviewPackageId: number, status: ReviewPackageUserStatus): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageId}/ReviewerStatus/${status}`, null);
  }
}
