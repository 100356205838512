import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { RouterService } from '@portal-core/general/services/router.service';
import { OAuthVendor } from '@portal-core/oauth/enums/oauth-vendor.enum';
import { OAuthDataService } from '@portal-core/oauth/services/oauth-data.service';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  constructor(private route: ActivatedRoute, private oAuthDataService: OAuthDataService, private routerService: RouterService) { }

  getVendor(): OAuthVendor {
    return this.oAuthDataService.getVendor();
  }

  getVendor$(): Observable<OAuthVendor> {
    return this.oAuthDataService.getVendor$();
  }

  getState(): string {
    return this.oAuthDataService.getState();
  }

  getState$(): Observable<string> {
    return this.oAuthDataService.getState$();
  }

  getLastActivatedRoute(): string[] {
    return this.oAuthDataService.getLastActivatedRoute();
  }

  getLastActivatedRoute$(): Observable<string[]> {
    return this.oAuthDataService.getLastActivatedRoute$();
  }

  setConfirm$(vendor: OAuthVendor, state: string = null): Observable<any> {
    return this.oAuthDataService.setConfirm$(vendor, state, this.routerService.getRoutePath(this.route.snapshot));
  }

  clearConfirm$(vendor: OAuthVendor): Observable<any> {
    return this.oAuthDataService.clearConfirm$(vendor);
  }

  generateStateParam(): string {
    return uuidv4();
  }
}
