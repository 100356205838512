import { Pipe, PipeTransform } from '@angular/core';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { EditorState } from 'prosemirror-state';

/**
 * Changes the state's document to be a flare code string.
 * Usage:
 *   EditorState | mcFlareCode
 * Example:
 *   {{ flareTextEditor.getEditorState() |  mcFlareCode }}
 * Returns the editor state's document as a flare code string:
 *   'Hello! How can I assist you today?'
 */
@Pipe({
  name: 'mcFlareCode'
})
export class FlareCodePipe implements PipeTransform {
  constructor() { }

  transform(editorState: EditorState): string {
    const content = editorState?.doc.content as any;
    return content ? (editorState.schema as CollabSchema).nodeToCode(content.content[0]) : '';
  }
}
