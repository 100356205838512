import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';

@Pipe({
  name: 'mcReviewFileAvatarTooltipClass'
})
export class ReviewFileAvatarTooltipClassPipe implements PipeTransform {
  transform(status: ReviewFileStatus | null | undefined, isDeleted: boolean, luminosity: '' | 'muted' | 'vivid' = ''): string {
    let statusClass: string = '';

    if (isDeleted) {
      statusClass = `mc-border-warn${luminosity ? '-' + luminosity : ''}`
    } else {
      switch (status) {
        case ReviewFileStatus.Open:
          statusClass = `mc-border-caution${luminosity ? '-' + luminosity : ''}`;
          break;
        case ReviewFileStatus.Closed:
        case ReviewFileStatus.Accepted:
          statusClass = `mc-border-info${luminosity ? '-' + luminosity : ''}`;
          break;
      }
    }

    return `mc-tooltip-multiline mc-tooltip-centered mc-tooltip-border ${statusClass}`;
  }
}
