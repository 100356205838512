import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { ListModule } from '@portal-core/ui/list/list.module';
import { BuildsSelectListComponent } from './builds-select-list.component';

@NgModule({
  declarations: [
    BuildsSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    BuildAvatarModule
  ],
  exports: [
    BuildsSelectListComponent
  ]
})
export class BuildsSelectListModule { }
