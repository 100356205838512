import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PmEditorModule } from '@portal-core/text-editor/components/pm-editor/pm-editor.module';
import { TextEditorComponent } from './text-editor.component';

@NgModule({
  declarations: [
    TextEditorComponent
  ],
  imports: [
    CommonModule,
    PmEditorModule
  ],
  exports: [
    TextEditorComponent
  ]
})
export class TextEditorModule { }
