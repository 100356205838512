import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsState } from '@portal-core/builds/services/builds-data.service';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { SitesState } from '@portal-core/sites/services/sites-data.service';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { CollectionActionsService } from '@portal-core/data/collection/services/collection-actions.service';

/**
 * Manages a build's list of associated sites.
 * Listens for builds being added to the Builds data store and updates the build's data list in the Sites data store.
 */
@Injectable({
  providedIn: 'root'
})
export class SiteListByBuildEffect {
  constructor(private collectionActionsService: CollectionActionsService, private sitesService: SitesService, private buildsService: BuildsService) {
    this.collectionActionsService.updatedItems$<Build>(BuildsState.source).subscribe(actionItems => this.processBuilds(actionItems.new));
    this.collectionActionsService.updatedItems$<Site>(SitesState.source).subscribe(actionItems => this.processSites(actionItems.new, actionItems.old));
  }

  private processBuilds(builds: Dictionary<Build> | Build[]) {
    // For each build that is being added update its corresponding data list in the sites collection
    forEach(builds, (build: Build) => {
      // If the build has a SitesList property defined
      if (typeof build.SitesList !== 'undefined') {
        let sitesList: Site[];

        // Create a sorted version of the SitesList
        if (Array.isArray(build.SitesList)) {
          sitesList = build.SitesList.sort((siteA, siteB) => siteA.Name.localeCompare(siteB.Name));
        } else {
          sitesList = build.SitesList;
        }

        // Set the data list in the Sites collection for this build
        this.sitesService.setListById$('Builds', build.Id, sitesList);
      }
    });
  }

  private processSites(sites: Site[], originalSites: Dictionary<Site>) {
    if (Array.isArray(sites)) {
      sites.forEach(site => {
        // If this site is associated with a build then add the site to the build's site list (if it isn't already there)
        const buildId = this.getBuildIdFromSite(site);
        if (typeof buildId === 'number') {
          const build = this.buildsService.getItemById(buildId);
          if (build) {
            let sitesList: Site[] = build.SitesList;
            if (!Array.isArray(sitesList)) {
              sitesList = [];
            }

            if (!sitesList.find(buildSite => buildSite.Id === site.Id)) {
              sitesList.push(site);
              this.buildsService.updateItems$([this.buildsService.processBuild({
                ...build,
                SitesList: sitesList
              })]);
            }
          }
        }

        // If this site was associated with a different build before it was updated then remove this site from that build
        const originalSite = originalSites[site.Id];
        if (originalSite) {
          const oldBuildId = this.getBuildIdFromSite(originalSite);

          if (typeof oldBuildId === 'number' && oldBuildId !== buildId) {
            const oldBuild = this.buildsService.getItemById(oldBuildId);

            if (oldBuild) {
              const sitesList = oldBuild.SitesList;
              if (Array.isArray(sitesList)) {
                const newSitesList = sitesList.filter(buildSite => buildSite.Id !== site.Id);
                if (newSitesList.length !== sitesList.length) {
                  this.buildsService.updateItems$([this.buildsService.processBuild({
                    ...oldBuild,
                    SitesList: newSitesList
                  })]);
                }
              }
            }
          }
        }
      });
    }
  }

  private getBuildIdFromSite(site: Site): number {
    return site.BuildId ?? site.LastCompletedBuildId;
  }
}
