export enum DocumentBucket {
    Headings = 'heading',
    Paragraphs = 'paragraph',
    DefinitionListItems = 'definition_list_item',
    UnorderedListItems = 'unordered_list',
    OrderedListItems = 'ordered_list',
    Bookmarks = 'bookmark',
    Dropdowns = 'dropdown',
    ExpandingText = 'expanding_text',
    MicroContent = 'micro_content',
    TableContent = 'table_content',
    Body = 'body',
    Unknown = 'unknown'


}
