export interface WordInfo {
  end: number;
  endDistance: number;
  start: number;
  startDistance: number;
  word: string;
}

// check if char is a space.
export function isSpace(char: string): RegExpExecArray {
  return /\s/.exec(char);
}
// Given a string and a position finds the word at that position if one exists.
export function getWordAt(str: string, pos: number): WordInfo {
  let start: number = pos - 1;
  let end: number = pos;

  //get the start of the word.
  while (start >= 0 && !isSpace(str[start])) {
    start -= 1;
  }
  start = Math.max(0, start + 1);

  //get the end of the word.
  while (end < str.length && !isSpace(str[end])) {
    end += 1;
  }
  // end can't be bigger then the length of the string.
  end = Math.min(Math.max(start, end), str.length);

  const word: string = str.slice(start, end);
  const endDistance: number = end - pos;
  const startDistance: number = pos - start;

  if (!word.length || pos < 0) {
    return null;
  }

  return {
    endDistance,
    startDistance,
    word,
    start,
    end
  };
}


// Returns true if the string is a positive integer that is greater than or equal to zero
export function stringIsPositiveInteger(str: string): boolean {
  const num = Math.floor(Number(str));
  return String(num) === str && num >= 0;
}

// returns a string with escaped html tags
export function escapeHTML(str: string): string {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return str.replace(/[&<>"']/g, function (m) { return map[m]; });
}

/**
 * Collapse all whitespace to one space character.
 * @param str The string to collapse.
 * @returns The collapsed string.
 */
export function collapseSpaces(str: string): string {
  // Replace all whitespace with the space character and then collapse all white space to one space character
  return str.replace(/\s/g, ' ').replace(/\s\s+/g, ' ');
}

/**
 * Normalize all newlines to the newline character.
 * @param str The string to normalize.
 * @returns The normalized string.
 */
export function normalizeNewlines(str: string): string {
  // First replace all CR+LF newlines then replace CR newlines
  return str.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
}

// Take from https://stackoverflow.com/a/40026669/1267725
// export function collapseHtml(html) {
//     return html.replace(/(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g, "$1$3");
// }

/**
 * Truncates a string to a given length and appends an omission string to the end.
 * If the string is not longer than the max length then the original string is returned.
 * @param str The string to truncate.
 * @param maxLength The maximum length for the truncated string.
 * @param omission The string to append to the end of the truncated string.
 * @returns The truncated string.
 */
export function truncate(str: string, maxLength: number, omission: string = '...'): string {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - omission.length) + omission;
  } else {
    return str;
  }
}

/**
 * Returns the index of the nth last occurrence of a substring in a string.
 * @param str The string to search.
 * @param searchValue The substring to search for.
 * @param nth The nth last occurrence to find. Use 1 to find the 1st last occurrence.
 * @returns The index of the nth last occurrence of the substring in the string.
 */
export function nthLastIndexOf(str: string, searchValue: string, nth: number): number {
  let index = str.length;

  for (let i = 0; i < nth; i += 1) {
    index = str.lastIndexOf(searchValue, index - 1);
    if (index === -1) {
      return -1;
    }
  }

  return index;
}
