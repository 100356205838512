import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { AnyBranchId } from '@portal-core/branches/constants/any-branch-id.constant';
import { Branch } from '@portal-core/branches/models/branch.model';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';

@CollectionStateSelectors({
  stateType: BranchesState
})
@State<BranchesState>({
  name: BranchesState.source,
})
@Injectable()
export class BranchesState extends CollectionStateBase {
  static source: string = 'Branches';

  static readonly defaultItems: Branch[] = [{
    Id: AnyBranchId,
    ProjectId: 0,
    Name: 'Any Branch'
  }];

  getDefaultItems(): Branch[] {
    return BranchesState.defaultItems;
  }

  getSource(): string {
    return BranchesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BranchesDataService extends CollectionDataServiceBase<Branch> {
  constructor(protected store: Store) {
    super(store, BranchesState);
  }
}
