import { pull } from 'lodash';

/**
 * EventEmitter
 * A helper class that can be extended or used directly.
 * Registers callbacks for events and can emit those events.
 * Optionally specify a generic type for the event type. Defaults to string but can be changed to make stricter typing for the event type.
 */
export class EventEmitter<T = string> {
  private callbacks: Map<T, ((...args: any) => void)[]> = new Map<T, ((...args: any) => void)[]>();

  on(eventType: T, callback: (...args: any) => void) {
    if (!this.callbacks.has(eventType)) {
      this.callbacks.set(eventType, []);
    }

    this.callbacks.get(eventType).push(callback);
  }

  once(eventType: T, callback: (...args: any) => void) {
    const cb = (...data) => {
      this.off(eventType, cb);
      callback(...data);
    };

    this.on(eventType, cb);
  }

  off(eventType: T, callback: (...args: any) => void) {
    const callbacks = this.callbacks.get(eventType);
    if (Array.isArray(callbacks)) {
      pull(callbacks, callback);
    }
  }

  emit(eventType: T, ...args: any[]) {
    this.callbacks.get(eventType)?.forEach(callback => callback(...args));
  }

  removeAllListeners() {
    this.callbacks.clear();
  }
}
