import { spansOverlap } from '@common/prosemirror/changeset/changeset';
import { selectionIsInNode } from '@common/prosemirror/state/selection';
import { CachedAccessor } from '@common/util/cached-accessor.decorator';
import { sortBy } from 'lodash';
import { ChangeSet, DeletedSpan, Span } from 'prosemirror-changeset';
import { EditorState, Transaction } from 'prosemirror-state';
import { CellSelection, isInTable } from 'prosemirror-tables';
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform';

export class ChangeDetector {
  changeSet: ChangeSet;

  constructor(public oldState: EditorState, public newState: EditorState, public transaction: Transaction) {
    this.changeSet = ChangeSet.create(oldState.doc).addSteps(newState.doc, transaction.mapping.maps);

    this.changeSet.deleted.forEach(span => {
      span.oldState = oldState;
      span.newState = newState;
      span.srcState = oldState;
    });

    this.changeSet.inserted.forEach(span => {
      span.oldState = oldState;
      span.newState = newState;
      span.srcState = newState;
      span.slice = newState.doc.slice(span.from, span.to);
    });
  }

  /*
   * Span helpers
   */
  get deleted(): DeletedSpan[] {
    return this.changeSet.deleted;
  }

  get inserted(): Span[] {
    return this.changeSet.inserted;
  }

  /*
   * Deletion helpers
   */
  get firstDeletedSpan(): DeletedSpan {
    return this.deleted[0];
  }

  get lastDeletedSpan(): DeletedSpan {
    return this.deleted[this.deleted.length - 1];
  }

  /*
   * Insertion helpers
   */
  get firstInsertedSpan(): Span {
    return this.inserted[0];
  }

  get lastInsertedSpan(): Span {
    return this.inserted[this.inserted.length - 1];
  }

  /**
   * spans
   * Returns an array of all the inserted and deleted spans ordered by the from position
   */
  @CachedAccessor()
  get spans(): Span[] {
    return sortBy([...this.deleted, ...this.inserted], span => {
      if (span instanceof DeletedSpan) {
        return span.pos;
      }

      return span.from;
    });
  }

  @CachedAccessor()
  get spansOverlap(): boolean {
    return spansOverlap(this.spans);
  }

  /*
   * Selection Detection
   */

  /**
   * isCellSelection
   * Returns true if the changes were made with a table cell selection.
   * Change with table cell selection
   */
  @CachedAccessor()
  get isCellSelection(): boolean {
    if (this.oldState.selection instanceof CellSelection && !this.isTableDeletion &&
      !this.isTableColumnDeletion && !this.isTableColumnInsertion &&
      !this.isTableRowDeletion && !this.isTableRowInsertion) {
      return true;
    }

    return false;
  }

  /**
   * isNodeReplace
   * Returns true if the changeset contains a node replacement.
   * Returns true if the changeset contains a change to a node's attributes.
   */
  @CachedAccessor()
  get isNodeReplace(): boolean {
    // A node replace must contain no deletes or inserts
    if (this.deleted.length !== 0 || this.inserted.length !== 0) {
      return false;
    }

    // There must be at least one step
    if (this.transaction.steps.length === 0) {
      return false;
    }

    // The steps must be a ReplaceAroundStep or ReplaceStep
    for (let i = 0; i < this.transaction.steps.length; i += 1) {
      const step = this.transaction.steps[i];

      if (!(step instanceof ReplaceAroundStep) && !(step instanceof ReplaceStep)) {
        return false;
      }

      // If this is a replace step
      if (step instanceof ReplaceStep) {
        // The step's slice must not be open on either side
        if (step.slice.openStart !== 0 || step.slice.openEnd !== 0) {
          return false;
        }

        // The step's slice must contain only one node
        if (step.slice.content.childCount !== 1) {
          return false;
        }

        // Grab the slice that was replaced in the old document
        const oldSlice = this.oldState.doc.slice(step.from, step.to);

        // The slice from the old document must not be open on either side
        if (oldSlice.openStart !== 0 || oldSlice.openEnd !== 0) {
          return false;
        }

        // The slice from the old document must contain only one node
        if (oldSlice.content.childCount !== 1) {
          return false;
        }

        // The node in the replace step and the node from the old document must be the same type
        if (step.slice.content.firstChild.type !== oldSlice.content.firstChild.type) {
          return false;
        }
      }
    }

    // Looks like we have a node replace change
    return true;
  }

  /**
   * isReplace
   * Returns true if the changeset contains a replace.
   */
  @CachedAccessor()
  get isReplace(): boolean {
    // A table cell was selected
    if (this.oldState.selection instanceof CellSelection) {
      return false;
    }

    // A replace must be one deletion and one insertion
    if (this.deleted.length !== 1 || this.inserted.length !== 1) {
      return false;
    }

    const delSpan = this.firstDeletedSpan;
    const insSpan = this.firstInsertedSpan;

    // The insertion must start where the deletion starts
    if (insSpan.from !== delSpan.from) {
      return false;
    }

    // Looks like we have a replace
    return true;
  }

  /**
   * isReplaceJoin
   * Returns true if the changeset contains a replace join.
   * Like deleting a paragraph and the first list item `<p></p> <ul><li><p></p></li>`
   */
  @CachedAccessor()
  get isReplaceJoin(): boolean {
    if (!this.isReplace) {
      return false;
    }

    const delSpan = this.firstDeletedSpan;
    const insSpan = this.firstInsertedSpan;

    // The inserted span must be before the end of the deleted span
    if (insSpan.to >= delSpan.to) {
      return false;
    }

    // The inserted span and deleted span must have an open end
    if (insSpan.slice.openEnd === 0 || delSpan.slice.openEnd === 0) {
      return false;
    }

    // The inserted span and deleted span must have the same open end
    if (insSpan.slice.openEnd !== delSpan.slice.openEnd) {
      return false;
    }

    // Looks like we have a replace join
    return true;
  }

  /**
   * isMultiDepthReplaceJoin
   * Returns true if the changeset contains a replace join that occurs at different depths on either end.
   * Like deleting a a list item and the first sub list item.
   */
  @CachedAccessor()
  get isMultiDepthReplaceJoin(): boolean {
    // A multi depth replace join must have two deletions
    if (this.deleted.length !== 2) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const lastDelSpan = this.lastDeletedSpan;
    const firstInsSpan = this.firstInsertedSpan;
    const lastInsSpan = this.lastInsertedSpan; // The last inserted span closes the moved content

    // The first deleted slice must be open at the start and open at the end with a depth of at least 2
    if (firstDelSpan.slice.openStart === 0 || firstDelSpan.slice.openEnd < 2) {
      return false;
    }

    // The last deleted span must be open at the start and closed at the end
    if (lastDelSpan.slice.openStart === 0 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    // If this change has at least one insertion
    if (this.inserted.length > 0) {
      // The last inserted span must have a closed start
      if (lastInsSpan.slice.openStart !== 0) {
        return false;
      }

      // The last inserted span must be before the last deleted span
      if (lastInsSpan.to > lastDelSpan.from) {
        return false;
      }
    }

    // If there is more than one insertion (then the first insert is the new content)
    if (this.inserted.length > 1) {
      // The first inserted span must start where the first delete starts
      if (firstInsSpan.from !== firstDelSpan.from) {
        return false;
      }
    }

    // Looks like we have a multi depth replace join
    return true;
  }

  /**
   * isBlockTypeChange
   * Returns true if the changeset contains a block type change.
   * <p> -> <blockquote>
   */
  @CachedAccessor()
  get isBlockTypeChange(): boolean {
    // This changeSet must have at least two changes AND the same number of deletions and insertions
    // if (changeSet.deleted.length < 2 || changeSet.deleted.length !== changeSet.inserted.length) {

    // A block type change must have at least one change but also the same number of deletions and insertions
    if (this.deleted.length === 0 || this.deleted.length !== this.inserted.length) {
      return false;
    }

    if (this.isSplitText) {
      return false;
    }

    // Loop through the change spans to see if they are compliant with a block type change
    for (let i = 0, length = this.deleted.length; i < length; i += 1) {
      const delSpan = this.deleted[i];
      const insSpan = this.inserted[i];

      // The deletion and insertion span must be in the same place
      if (delSpan.from !== insSpan.from || delSpan.to !== insSpan.to) {
        return false;
      }

      // If this is the first change span
      if (i === 0) {
        // The deletion and insertion spans must not be open at the start and must be different node types
        if (delSpan.slice.openStart !== 0 || insSpan.slice.openStart !== 0 || delSpan.fromNode.type === insSpan.fromNode.type) {
          return false;
        }

        // The deleted node and inserted node must both be blocks
        if (!delSpan.fromNode.isBlock || !insSpan.fromNode.isBlock) {
          return false;
        }
      }

      // If this is the last change span
      if (i === length - 1) {
        // The deletion and insertion spans must not be open at the end and must be different node types
        if (delSpan.slice.openEnd !== 0 || insSpan.slice.openEnd !== 0 || delSpan.toNode.type === insSpan.toNode.type) {
          return false;
        }

        // The deleted node and inserted node must both be blocks
        if (!delSpan.toNode.isBlock || !insSpan.toNode.isBlock) {
          return false;
        }
      }
    }

    // Looks like we have a block type change
    return true;
  }

  /**
   * isUnwrap
   * Returns true if the changeset contains an unwrap.
   * Unwrapping an inline node from text (eg removing a bold tag from <b>text</b>)
   * Unwrapping an empty list item in a single item list
   */
  @CachedAccessor()
  get isUnwrap(): boolean {
    // // An unwrap must have two deletions and no insertions
    // if (this.deleted.length !== 2 || this.inserted.length !== 0) {
    // 	return false;
    // }

    // // The deletions must be the same node type
    // if (this.firstDeletedSpan.fromNode.type !== this.lastDeletedSpan.fromNode.type) {
    // 	return false;
    // }

    // // Looks like we have an unwrap
    // return true;



    // // An unwrap must have an even number of deletions and no insertions
    // if (this.deleted.length % 2 !== 0 || this.inserted.length !== 0) {
    // 	return false;
    // }

    // // Each deletion set must be the same node type
    // for (let i = 0; i < this.deleted.length; i += 2) {
    // 	if (this.deleted[i].fromNode.type !== this.deleted[i + 1].toNode.type) {
    // 		return false;
    // 	}
    // }

    // // Looks like we have an unwrap
    // return true;

    const totalSpanCount = this.deleted.length + this.inserted.length;

    // An unwrap must have an even number of deletions and insertions and no more than two insertions
    if (totalSpanCount === 0 || totalSpanCount % 2 !== 0 || this.inserted.length > 2) {
      return false;
    }

    // All the inserted spans need to have been included in the spans list
    // if (spans.length !== totalSpanCount) {
    // 	return false;
    // }

    // The spans must not overlap
    if (this.spansOverlap) {
      return false;
    }

    const spans = this.spans;

    // Check each pair of spans to see if they are unwraps
    for (let i = 0; i < spans.length; i += 2) {
      const firstSpan = spans[i];
      const secondSpan = spans[i + 1];

      // Each pair must be the same node type and not be text nodes
      if (firstSpan.fromNode.isText || firstSpan.fromNode.type !== secondSpan.toNode.type) {
        return false;
      }

      // If this is a pair of deletes
      if (firstSpan.isDelete && secondSpan.isDelete) {
        // The first span must haven an open start and a closed end
        if (firstSpan.slice.openStart !== 0 || firstSpan.slice.openEnd === 0) {
          return false;
        }

        // The second span must haven a closed start and an open end
        if (secondSpan.slice.openStart === 0 || secondSpan.slice.openEnd !== 0) {
          return false;
        }
      } else if ((firstSpan.isDelete && secondSpan.isInsert) || (firstSpan.isInsert && secondSpan.isDelete)) { // Else a pair of different spans
        // The spans must share the same open start
        if (firstSpan.slice.openStart !== secondSpan.slice.openStart) {
          return false;
        }

        // The spans must share the same open end
        if (firstSpan.slice.openEnd !== secondSpan.slice.openEnd) {
          return false;
        }

        // If the start is open then it must be at a depth of 1
        if (firstSpan.slice.openStart > 0 && firstSpan.slice.openStart !== 1) {
          return false;
        }

        // If the end is open then it must be at a depth of 1
        if (firstSpan.slice.openEnd > 0 && firstSpan.slice.openEnd !== 1) {
          return false;
        }
      }
    }

    // The unwrap step must be ReplaceAroundStep
    if (this.transaction.steps.some(step => !(step instanceof ReplaceAroundStep))) {
      return false;
    }

    // Looks like we have an unwrap
    return true;
  }

  /**
   * isLift
   * Returns true if the changeset contains a lift.
   */
  @CachedAccessor()
  get isLift(): boolean {
    // If the first insertion is in the same spot as the first delete AND the first insertion is of the same type as the top level node of the second delete

    // A lift must be two deletes with one insert
    if (this.deleted.length !== 2 || this.inserted.length !== 1) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const secondDelSpan = this.deleted[1];
    const insSpan = this.firstInsertedSpan;

    // The first deleted span and the inserted span must be in the same place
    if (firstDelSpan.from !== insSpan.from || firstDelSpan.to !== insSpan.to) {
      return false;
    }

    // The second deleted span and the inserted span must have root nodes of the same type
    const secondDelNode = secondDelSpan.slice.content.firstChild;
    const insNode = this.newState.doc.slice(insSpan.from, insSpan.to).content.firstChild; // TODO: insSpan.slice should be used instead of creating a new slice
    if (!secondDelNode || !insNode || secondDelNode.type !== insNode.type || !insNode.type.isBlock) {
      return false;
    }

    // Looks like we have a lift
    return true;
  }

  /**
   * isInlineLift
   * Returns true if the changeset includes an inline lift.
   */
  @CachedAccessor()
  get isInlineLift(): boolean {
    // An inline lift must be two insertions and no deletions
    if (this.inserted.length !== 2 || this.deleted.length !== 0) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;
    const lastInsSpan = this.lastInsertedSpan;

    // The first inserted span must be open on the left and the second inserted span must be open on the right
    if (firstInsSpan.slice.openStart <= firstInsSpan.slice.openEnd && lastInsSpan.slice.openEnd <= lastInsSpan.slice.openStart) {
      return false;
    }

    // The inserted spans must have end nodes of the same type
    // var firstInsNode = firstInsSpan.slice.content.firstChild,
    // 	lastInsNode = lastInsSpan.slice.content.lastChild;
    // if (!firstInsNode || !lastInsNode || firstInsNode.type !== lastInsNode.type) {
    // 	return false;
    // }

    if (!firstInsSpan.fromNode || !lastInsSpan.toNode || firstInsSpan.fromNode.type !== lastInsSpan.toNode.type || !lastInsSpan.toNode.type.isInline) {
      return false;
    }

    // Looks like we have an inline lift
    return true;
  }

  /**
   * isBackwardsLift
   * Returns true if the changeset contains a backwards lift.
   * Lifting the first item(s) in a top level list
   */
  @CachedAccessor()
  get isBackwardsLift(): boolean {
    // A table cell was selected
    if (this.oldState.selection instanceof CellSelection) {
      return false;
    }

    // A backwards lift must be one insert and at least two deletes
    if (this.inserted.length !== 1 || this.deleted.length < 2) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;

    // The insert must be closed at the start and open at the end with a depth of 1
    if (firstInsSpan.slice.openStart !== 0 || firstInsSpan.slice.openEnd !== 1) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;

    // The first delete must be closed at the start and an open end with a depth of 2
    if (firstDelSpan.slice.openStart !== 0 || firstDelSpan.slice.openEnd !== 2) {
      return false;
    }

    const lastDelSpan = this.lastDeletedSpan;

    // The last delete must be open at the start with a depth of 1 and closed at the end
    if (lastDelSpan.slice.openStart !== 1 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    for (let i = 1; i < this.deleted.length - 1; i += 1) {
      const delSpan = this.deleted[i];

      // The middle delete must be open at the start and end with a depth of 1
      if (delSpan.slice.openStart !== 1 || delSpan.slice.openEnd !== 1) {
        return false;
      }
    }

    // Looks like we have a backwards lift
    return true;
  }

  /**
   * isMiddleListItemLift
   * Returns true if the changeset contains a lift on a list item in the middle of a list.
   * Lifting multiple list items from the middle of a top level list
   * Removes the list item for the first item being lifted                    <list_item>(0,1)
   * Removes the list items for the middle items being lifted                 <list_item, list_item>(1,1)
   * Removes the list items for the last item being lifted                    <list_item>(1,0)
   * Inserts the new end of the list above the first list item being lifted   <bullet_list>(1,0)
   * Inserts the new start of the list below the last list item being lifted  <bullet_list>(0,1)
   */
  @CachedAccessor()
  get isMiddleListItemLift(): boolean {
    // A middle list item lift must be two inserts and at least two deletes
    if (this.inserted.length !== 2 || this.deleted.length < 2) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;
    const firstDelSpan = this.firstDeletedSpan;

    // The insert must be at the same range as the first deletion
    if (firstInsSpan.from !== firstDelSpan.from || firstInsSpan.to !== firstDelSpan.to) {
      return false;
    }

    // The insert must be open at the start with a depth of 1 and closed at the end
    if (firstInsSpan.slice.openStart !== 1 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // The first delete must be closed at the start and an open end with a depth of 1
    if (firstDelSpan.slice.openStart !== 0 || firstDelSpan.slice.openEnd !== 1) {
      return false;
    }

    const lastDelSpan = this.lastDeletedSpan;

    // The last delete must be open at the start with a depth of 1 and closed at the end
    if (lastDelSpan.slice.openStart !== 1 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    for (let i = 1; i < this.deleted.length - 1; i += 1) {
      const delSpan = this.deleted[i];

      // The middle delete must be open at the start and end with a depth of 1
      if (delSpan.slice.openStart !== 1 || delSpan.slice.openEnd !== 1) {
        return false;
      }
    }

    // Looks like we have a middle list item lift
    return true;
  }

  /**
   * isMiddleSubListItemLift
   * Returns true if the changeset contains a lift on a sub list item in the middle of a list.
   * Removes the end of the list item for the last item being lifted to adopt the newly created list below  <list_item>(1,0)
   * Inserts the new start of the list above the first item being lifted                                    <list_item(bullet_list)>(2,0)
   * Inserts the new end of the list for the items being lifted                                             <bullet_list>(0,1)
   */
  @CachedAccessor()
  get isMiddleSubListItemLift(): boolean {
    // A middle sub list item lift must be one delete with two inserts
    if (this.deleted.length !== 1 || this.inserted.length !== 2) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;
    const lastInsSpan = this.lastInsertedSpan;

    // The first inserted span must be open on the left with a depth of 2 and closed on the right
    if (firstInsSpan.slice.openStart !== 2 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // The last inserted span must be closed on the left and open on the right with a depth of 1
    if (lastInsSpan.slice.openStart !== 0 || lastInsSpan.slice.openEnd !== 1) {
      return false;
    }

    const delSpan = this.firstDeletedSpan;

    // The deleted span must be closed on the left and open on the right with a depth of 1
    if (delSpan.slice.openStart !== 1 || delSpan.slice.openEnd !== 0) {
      return false;
    }

    // Looks like we have a middle list item lift
    return true;
  }

  /**
   * isMiddleSubDefinitionListItemLift
   * Returns true if a dd item is being lifted.
   * dt items behave like other lists and are detected by isMiddleSubListItemLift
   * Removes the end of the item so that it can adopt the list being inserted after it  <definition_description>(1,0)
   * Removes the end of the sub list                                                    <definition_term(definition_list)>(2,0)
   * Inserts the end of a list above the item being lifted thus closing the list above  <definition_term(definition_list)>(2,0)
   * Inserts the new list into the item to contain the items that follow it             <definition_list>(0,1)
   * Inserts the end of the new list to contain the items that followed the item        <definition_description(definition_list)>(2,0)
   */
  @CachedAccessor()
  get isMiddleSubDefinitionListItemLift(): boolean {
    // A middle sub definition list item lift must be two deletes with three inserts
    if (this.deleted.length !== 2 || this.inserted.length !== 3) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;
    const middleInsSpan = this.inserted[1];
    const lastInsSpan = this.lastInsertedSpan;

    // The first inserted span must be open on the left with a depth of 2 and closed on the right
    if (firstInsSpan.slice.openStart !== 2 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // The middle inserted span must be closed on the left and open on the right with a depth of 1
    if (middleInsSpan.slice.openStart !== 0 || middleInsSpan.slice.openEnd !== 1) {
      return false;
    }

    // The last inserted span must be open on the left with a depth of 2 and closed on the right
    if (lastInsSpan.slice.openStart !== 2 || lastInsSpan.slice.openEnd !== 0) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const lastDelSpan = this.lastDeletedSpan;

    // The first deleted span must be open on the left with a depth of 1 and closed on the right
    if (firstDelSpan.slice.openStart !== 1 || firstDelSpan.slice.openEnd !== 0) {
      return false;
    }

    // The last deleted span must be open on the left with a depth of 2 and closed on the right
    if (lastDelSpan.slice.openStart !== 2 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    // Looks like we have a middle sub definition list item lift
    return true;
  }

  /**
   * isLastListItemLift
   * Returns true if the changeset contains a lift on the last item of a list.
   * Lifting multiple list items from the end of a top level list
   * Removes the list item for the first item being lifted                    <list_item>(0,1)
   * Removes the list items between the middle items being lifted             <list_item, list_item>(1,1)
   * Removes the list item and list for the last item being lifted            <bullet_list(list_item)>(2,0)
   * Inserts the new end of the list above the first list item being lifted   <bullet_list>(1,0)
   */
  @CachedAccessor()
  get isLastListItemLift(): boolean {
    // A last list item lift must be one insert and at least two deletes
    if (this.inserted.length !== 1 || this.deleted.length < 2) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;
    const firstDelSpan = this.firstDeletedSpan;

    // The insert must be at the same range as the first deletion
    if (firstInsSpan.from !== firstDelSpan.from || firstInsSpan.to !== firstDelSpan.to) {
      return false;
    }

    // The insert must be open at the start with a depth of 1 and closed at the end
    if (firstInsSpan.slice.openStart !== 1 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // The first delete must be closed at the start and an open end with a depth of 1
    if (firstDelSpan.slice.openStart !== 0 || firstDelSpan.slice.openEnd !== 1) {
      return false;
    }

    const lastDelSpan = this.lastDeletedSpan;

    // The last delete must be open at the start with a depth of 2 and closed at the end
    if (lastDelSpan.slice.openStart !== 2 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    for (let i = 1; i < this.deleted.length - 1; i += 1) {
      const delSpan = this.deleted[i];

      // The middle delete must be open at the start and end with a depth of 1
      if (delSpan.slice.openStart !== 1 || delSpan.slice.openEnd !== 1) {
        return false;
      }
    }

    // Looks like we have a last list item lift
    return true;
  }

  /**
   * isLastSubListItemLift
   * Returns true if the changeset contains a lift on the last item of a sub list.
   * Creates a new list item (that contains a list) before the first list item                 <list_item(bullet_list)>(2,0)
   * Opens the items being lifted by removing the last item's end tag and the list's end tag   <list_item(bullet_list)>(2,0)
   */
  @CachedAccessor()
  get isLastSubListItemLift(): boolean {
    // A last sub list item lift must be one delete with one insert
    if (this.deleted.length !== 1 || this.inserted.length !== 1) {
      return false;
    }

    const firstInsSpan = this.firstInsertedSpan;

    // The first inserted span must be open on the left with a depth of 2 and closed on the right
    if (firstInsSpan.slice.openStart !== 2 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    const delSpan = this.firstDeletedSpan;

    // The first inserted span must be open on the left with a depth of 2 and closed on the right
    if (delSpan.slice.openStart !== 2 || delSpan.slice.openEnd !== 0) {
      return false;
    }

    // The inserted span must come before the deleted span
    if (firstInsSpan.to >= delSpan.from) {
      return false;
    }

    // Looks like we have a last sub list item lift
    return true;
  }

  /**
   * isAllListItemsLift
   * Returns true if the changeset contains a lift on all the list items of a list.
   */
  @CachedAccessor()
  get isAllListItemsLift(): boolean {
    // An all list items lift must be at least two deletions and no insertions
    if (this.deleted.length < 2 || this.inserted.length !== 0) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const lastDelSpan = this.lastDeletedSpan;

    // The first delete must be closed at the start and open at the end with a depth of 2
    if (firstDelSpan.slice.openStart !== 0 || firstDelSpan.slice.openEnd !== 2) {
      return false;
    }

    // The last delete must be open at the start with a depth of 2 and closed at the end
    if (lastDelSpan.slice.openStart !== 2 || lastDelSpan.slice.openEnd !== 0) {
      return false;
    }

    for (let i = 1; i < this.deleted.length - 1; i += 1) {
      const delSpan = this.deleted[i];

      // The middle delete must be open at the start and end with a depth of 1
      if (delSpan.slice.openStart !== 1 || delSpan.slice.openEnd !== 1) {
        return false;
      }
    }

    // The first and last delete must both be the same type
    if (firstDelSpan.slice.content.firstChild.type !== lastDelSpan.slice.content.firstChild.type) {
      return false;
    }

    // Looks like we have an all list items lift
    return true;
  }

  /**
   * isListItemSink
   * Returns true if the changeset contains a sink on a list item.
   * Root list items and sub list items look the same.
   * Sinks across sub list to root list and root list to sub list look the same as well.
   * Sinks across sub lists starting and ending in the root list look the same.
   * Sinks across root lists starting and ending in sub lists look the same.
   * Sinks for the last items and sub list items look the same.
   * Sinks across sub list to root list and root list to sub list look the same as well.
   * Sinks across sub lists starting and ending in the root list look the same.
   * Removes the list item at the start of the sink                     <list_item>(1,0)
   * Inserts a list at the start of the sink                            <bullet_list>(0,1)
   * Inserts the close of a list item and list at the end of the sink   <list_item(bullet_list)>(2,0)
   */
  @CachedAccessor()
  get isListItemSink(): boolean {
    // A list item sink must be one deletion and at least two insertions
    if (this.deleted.length !== 1 || this.inserted.length < 2) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const firstInsSpan = this.firstInsertedSpan;
    const lastInsSpan = this.lastInsertedSpan;

    // The deletion and first insertion must be across the same range
    if (firstDelSpan.from !== firstInsSpan.from || firstDelSpan.to !== firstInsSpan.to) {
      return false;
    }

    // The deletion must be open at the start with a depth of 1 AND closed at the end or open with only a depth of 1
    if (firstDelSpan.slice.openStart !== 1 || firstDelSpan.slice.openEnd > 1) {
      return false;
    }

    // The first insertion must be closed at the start and open at the end with a depth of 1 or 2
    if (firstInsSpan.slice.openStart !== 0 || firstInsSpan.slice.openEnd < 1 || firstInsSpan.slice.openEnd > 2) {
      return false;
    }

    // The last insertion must be open at the start with a depth of 2 and closed at the end
    if (lastInsSpan.slice.openStart !== 2 || lastInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // Looks like we have a sink
    return true;
  }

  /**
   * isListItemSinkJoin
   * Returns true if the changeset contains a sink join on a list item.
   * Sinking the first items after a sub list
   * li
   * li
   *   li
   *   li
   * li |->
   * Removes the end of the sub list and the end of the last item in the sub list just before the sink  <list_item(bullet_list)>(2,0)
   * Inserts the new end of the sub list and the new end of the last item just after the sink           <list_item(bullet_list)>(2,0)
   */
  @CachedAccessor()
  get isListItemSinkJoin(): boolean {
    // A list item sink join must be one deletion and one insertion
    if (this.deleted.length !== 1 || this.inserted.length !== 1) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;
    const firstInsSpan = this.firstInsertedSpan;

    // The deletion must be open at the start with a depth of 2 and closed at the end
    if (firstDelSpan.slice.openStart !== 2 || firstDelSpan.slice.openEnd !== 0) {
      return false;
    }

    // The insertion must be open at the start with a depth of 2 and closed at the end
    if (firstInsSpan.slice.openStart !== 2 || firstInsSpan.slice.openEnd !== 0) {
      return false;
    }

    // The inserted span must come after the deleted span
    if (firstInsSpan.from <= firstDelSpan.to) {
      return false;
    }

    // Looks like we have a sink join
    return true;
  }

  /**
   * isDefinitionListExit
   * Returns true if the changeset contains an exit of a definition list.
   */
  @CachedAccessor()
  get isDefinitionListExit(): boolean {
    // A definition list exit must be one deletion and one insertion
    if (this.deleted.length !== 1 || this.inserted.length !== 1) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;

    // There must be only one top level node in the deleted span
    if (firstDelSpan.slice.content.childCount !== 1) {
      return false;
    }

    // The first deleted node must be a definition term
    if (firstDelSpan.slice.content.firstChild.type.name !== this.newState.schema.nodes.definition_term.name) {
      return false;
    }

    // Looks like we have a definition list exit
    return true;
  }

  /**
   * isDropdownBodyExit
   * Returns true if the changeset contains an exit of a dropdown body.
   */
  @CachedAccessor()
  get isDropdownBodyExit(): boolean {
    // A dropdown body exit must be within a dropdown body node
    if (!selectionIsInNode(this.oldState.selection, this.newState.schema.nodes.madcapdropdownbody, true)) {
      return false;
    }

    // A dropdown body exit must be one deletion and one insertion
    if (this.deleted.length !== 1 || this.inserted.length !== 1) {
      return false;
    }

    const firstDelSpan = this.firstDeletedSpan;

    // There must be only one top level node in the deleted span and it must be empty
    if (firstDelSpan.slice.content.childCount !== 1 || firstDelSpan.slice.content.firstChild.content.size !== 0) {
      return false;
    }

    // Looks like we have a dropdown body exit
    return true;
  }

  /**
   * isNodeExit
   * Returns true if the changeset contains an exit from a node that deletes its last empty child and inserts a new node after itself.
   * This includes definition lists and dropdown bodies.
   */
  @CachedAccessor()
  get isNodeExit(): boolean {
    return this.isDropdownBodyExit || this.isDefinitionListExit;
  }

  /**
   * isTableRowInsertion
   * Returns true if the changeset contains a table row insertion.
   */
  @CachedAccessor()
  get isTableRowInsertion(): boolean {
    if (!isInTable(this.oldState)) {
      return false;
    }

    // A table row add must be one insertion with no deletions
    if (this.inserted.length !== 1 || this.deleted.length !== 0) {
      return false;
    }

    const insFirstSpan = this.firstInsertedSpan;

    // The inserted span's slice must not have open ends
    if (insFirstSpan.slice.openStart !== 0 || insFirstSpan.slice.openEnd !== 0) {
      return false;
    }

    // The inserted span's child count must be one
    if (insFirstSpan.slice.content.childCount !== 1) {
      return false;
    }

    // The inserted span's child must be a table row
    if (insFirstSpan.slice.content.firstChild.type.name !== this.newState.schema.nodes.table_row.name) {
      return false;
    }

    return true;
  }

  /**
   * isTableColumnInsertion
   * Returns true if the changeset contains a table column insertion.
   */
  @CachedAccessor()
  get isTableColumnInsertion(): boolean {
    if (!isInTable(this.oldState)) {
      return false;
    }

    // A table column add must be one or more insertions with no deletions
    if (this.inserted.length < 1 || this.deleted.length !== 0) {
      return false;
    }

    for (let i = 0; i < this.inserted.length; i++) {
      const insSlice = this.inserted[i].slice;

      // The inserted spans' slices must not have open ends
      if (insSlice.openStart !== 0 || insSlice.openEnd !== 0) {
        return false;
      }

      // The inserted span's child count must be one
      if (insSlice.content.childCount !== 1) {
        return false;
      }

      // The inserted span's child must have a table cell role or be a col
      if (!(insSlice.content.firstChild.type.spec.tableRole === 'cell' || insSlice.content.firstChild.type.spec.tableRole === 'header_cell' || insSlice.content.firstChild.type.name === this.newState.schema.nodes.col.name || insSlice.content.firstChild.type.name === this.newState.schema.nodes.colgroup.name)) {
        return false;
      }
    }

    return true;
  }

  /**
   * isTableRowDeletion
   * Returns true if the changeset contains a table row deletion.
   */
  @CachedAccessor()
  get isTableRowDeletion(): boolean {
    if (!isInTable(this.oldState)) {
      return false;
    }

    // A table row deletion must have one deletion
    // Deleting rows with merged cells sometimes create inserted changes
    if (this.deleted.length !== 1) {
      return false;
    }

    // The deleted span's child must be a table row
    if (this.firstDeletedSpan.slice.content.firstChild.type.name !== this.newState.schema.nodes.table_row.name) {
      return false;
    }

    return true;
  }

  /**
   * isTableColumnDeletion
   * Returns true if the changeset contains a table column deletion.
   */
  @CachedAccessor()
  get isTableColumnDeletion(): boolean {
    if (!isInTable(this.oldState)) {
      return false;
    }

    // A table column deletion must be no insertions with at least one deletion
    if (this.inserted.length !== 0 || this.deleted.length < 1) {
      return false;
    }

    for (let i = 0; i < this.deleted.length; i++) {
      const delType = this.deleted[i].slice.content.firstChild.type;
      // The deleted span's child must be a table header or cell
      if (!(delType.spec.tableRole === 'cell' || delType.spec.tableRole === 'header_cell' || delType.name === this.newState.schema.nodes.col.name || delType.name === this.newState.schema.nodes.colgroup.name)) {
        return false;
      }
    }

    return true;
  }

  /**
   * isTableDeletion
   * Returns true if the changeset contains a table deletion.
   */
  @CachedAccessor()
  get isTableDeletion(): boolean {
    if (!isInTable(this.oldState)) {
      return false;
    }

    // Only deletion must be a table. Table must have 0 or 1 insertions if placeholder
    if (this.deleted.length !== 1 || this.inserted.length > 1 ||
      this.firstDeletedSpan.slice.content.firstChild.type.name !== this.newState.schema.nodes.table.name) {
      return false;
    }

    // Nested table insertion may add a mcCentralContainer if the table cell is empty
    if (!!this.firstInsertedSpan && !(this.firstInsertedSpan.isEmptyJoin || this.firstInsertedSpan.isPlaceholder)) {
      return false;
    }

    return true;
  }

  /**
   * isTableRowToggle
   * Returns true if the changeset contains a table row toggle.
   */
  // @CachedAccessor()
  // get isTableRowToggle(): boolean {
  //   if (!isInTable(this.oldState)) {
  //     return false;
  //   }

  //   if (!this.isBlockTypeChange) {
  //     return false;
  //   }

  //   const tableHeader = this.newState.schema.nodes.table_header.name;
  //   const tableCell = this.newState.schema.nodes.table_cell.name;

  //   // Loop through the change spans to see if they are compliant with a table row toggle change
  //   for (let i = 0, length = this.deleted.length; i < length; i += 1) {
  //     const delSpanType = this.deleted[i].fromNode.type;
  //     const insSpanType = this.inserted[i].fromNode.type;

  //     // Must be table elements
  //     if (!delSpanType.spec.tableRole || !insSpanType.spec.tableRole) {
  //       return false;
  //     }

  //     // Must convert from cell to header or vice versa
  //     // Toggling multiple rows will sometimes have a table row
  //     if (!(delSpanType.name === tableHeader && insSpanType.name === tableCell ||
  //           delSpanType.name === tableCell && insSpanType.name === tableHeader ||
  //           delSpanType.name === 'table_row' && insSpanType.name === 'table_row')) {
  //       return false;
  //     }
  //   }

  //   return true;
  // }


  /**
   * isSplitText
   * Returns true if the changeset contains the splitting of text.
   * Hitting enter inside of text
   */
  @CachedAccessor()
  get isSplitText(): boolean {
    // The number of deletes must be equal to or one less than the number of inserts
    if (this.inserted.length - this.deleted.length !== 1 && this.inserted.length - this.deleted.length !== 0) {
      return false;
    }

    // If there was a selection (a deleted span) then it must be within only one node
    const delSpanCount = this.deleted.filter(span => span.slice.content.firstChild.isText && span.slice.content.childCount === 1).length;
    // Other deletes should be text represented by mcCentralContainers converted to text blocks
    const replaceBlockCount = this.deleted.filter(span => span.slice.content.firstChild.type.name === 'mcCentralContainer' && span.slice.content.childCount === 1).length;

    // There should at most one text selection
    if (!this.spansOverlap || delSpanCount + replaceBlockCount !== this.deleted.length || delSpanCount > 1) {
      return false;
    }

    const splitInsSpan = this.inserted.find(span => span.slice.content.childCount === 2);
    if (!splitInsSpan) {
      return false;
    }
    const insSlice = this.newState.doc.slice(splitInsSpan.from, splitInsSpan.to);

    // The new slice must have exactly two children of the same type
    if (insSlice.content.childCount !== 2 || insSlice.content.firstChild.type !== insSlice.content.lastChild.type) {
      return false;
    }

    const delSpan = this.deleted.find(span => span.slice.content.firstChild.isText);
    // If there was a selection (a deleted span) then it must be within only one node
    if (delSpan && delSpan.slice.content.childCount > 1) {
      return false;
    }

    // Looks like we have a text split
    return true;
  }

  /**
   * isSplit
   * Returns true if the changeset contains the splitting of a non-text node.
   * Hitting enter inside of a node
   */
  @CachedAccessor()
  get isSplit(): boolean {
    // A split must be only one insert
    if (this.inserted.length !== 1) {
      return false;
    }

    // Deleting a row with a merged cell may give a false positive
    if (this.isTableRowDeletion) {
      return false;
    }

    const delSpan = this.firstDeletedSpan;
    const insSpan = this.firstInsertedSpan;
    const insSlice = this.newState.doc.slice(insSpan.from, insSpan.to);

    // The new slice must have the same depth at the start and end and not equal zero
    if (insSlice.openStart !== insSlice.openEnd || insSlice.openStart === 0) {
      return false;
    }

    // The new slice must have exactly two children of the same type
    if (insSlice.content.childCount !== 2 || insSlice.content.firstChild.type !== insSlice.content.lastChild.type) {
      return false;
    }

    // If there was a selection (a deleted span) then it must be within only one node
    if (delSpan && delSpan.slice.content.childCount > 1) {
      return false;
    }

    // Looks like we have a split
    return true;
  }

  /**
   * isWrap
   * Returns true if the changeset contains a wrap.
   * Creating a <b>, <u>, <i>, <ul>, <ol>, or <dl>
   */
  @CachedAccessor()
  get isWrap(): boolean {
    // A wrap must have no deletions and at least two insertions
    if (this.deleted.length !== 0 || this.inserted.length < 2) {
      return false;
    }

    const firstSpan = this.firstInsertedSpan;
    const lastSpan = this.lastInsertedSpan;

    // The first and last inserted spans must not be over the same range
    if (firstSpan.from === lastSpan.from || firstSpan.to === lastSpan.to) {
      return false;
    }

    const firstSlice = this.newState.doc.slice(firstSpan.from, firstSpan.to);
    const lastSlice = this.newState.doc.slice(lastSpan.from, lastSpan.to);

    // The first and last slice must be open at the same depth AND must have only one child each
    if (firstSlice.openEnd !== lastSlice.openStart && firstSlice.content.childCount !== 1 || lastSlice.content.childCount !== 1) {
      return false;
    }

    // The first and last nodes must be the same type
    if (firstSlice.content.firstChild.type !== lastSlice.content.firstChild.type) {
      return false;
    }

    // Looks like we have a wrap
    return true;
  }

  /**
   * isWrapAndReplace
   * Returns true if the changeset contains a wrap and replace.
   * Creating a ul/ol/dl where the block nodes are replaced with the item's child node type
   */
  @CachedAccessor()
  get isWrapAndReplace(): boolean {
    // A wrap and replace must be at least two insertions and deletions
    if (this.inserted.length < 2 || this.deleted.length < 2) {
      return false;
    }

    const firstSpan = this.firstInsertedSpan;
    const lastSpan = this.lastInsertedSpan;

    // The first and last inserted spans must not be over the same range
    if (firstSpan.from === lastSpan.from || firstSpan.to === lastSpan.to) {
      return false;
    }

    const firstSlice = this.newState.doc.slice(firstSpan.from, firstSpan.to);
    const lastSlice = this.newState.doc.slice(lastSpan.from, lastSpan.to);

    // The first and last slice must be open at the same depth
    if (firstSlice.openEnd === 0 || lastSlice.openStart === 0) {
      return false;
    }

    // The first and last slice must have only one child
    if (firstSlice.content.childCount !== 1 || lastSlice.content.childCount !== 1) {
      return false;
    }

    // The first and last slice must have the same child type
    if (firstSlice.content.firstChild.type !== lastSlice.content.firstChild.type) {
      return false;
    }

    const firstOldSpan = this.firstDeletedSpan;
    const lastOldSpan = this.lastDeletedSpan;
    const firstOldSlice = this.oldState.doc.slice(firstOldSpan.from, firstOldSpan.to);
    const lastOldSlice = this.oldState.doc.slice(lastOldSpan.from, lastOldSpan.to);

    // The new slices must be deeper than the old slices
    if (firstOldSlice.openEnd >= firstSlice.openEnd || lastOldSlice.openStart >= lastSlice.openStart) {
      return false;
    }

    // Looks like we have a wrap and replace
    return true;
  }

  /**
   * isSplitAndWrap
   * Returns true if changeset contains a split and wrap.
   * Splits the inline node if it appeared in "from" or "to" position of selection.
   * And wraps selection by creating new inline node(eg madcapconditiontext).
   */
  @CachedAccessor()
  get isSplitAndWrap(): boolean {
    // Must have two insertions and no deletions
    if (this.deleted.length !== 0 || this.inserted.length !== 2) {
      return false;
    }

    const firstContent = this.firstInsertedSpan.slice.content;
    const lastContent = this.lastInsertedSpan.slice.content;

    // The new inline node must be the same node type
    if (firstContent.lastChild.type !== lastContent.firstChild.type) {
      return false;
    }

    const childCount = firstContent.childCount + lastContent.childCount;
    // Must have three or four children
    if (childCount < 3 || childCount > 4) {
      return false;
    }

    // If there are two children in the first slice, there must be two children of the same type
    if (firstContent.childCount === 2 &&
      firstContent.firstChild.type !== firstContent.lastChild.content.firstChild?.type) {
      return false;
    }

    // If there are two children in the last slice, there must be two children of the same type
    if (lastContent.childCount === 2 &&
      lastContent.firstChild.content.firstChild?.type !== lastContent.lastChild.type) {
      return false;
    }

    // Looks like we have a split and wrap.
    return true;
  }
}
