<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    <h3>Columns</h3>
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-body">
    <mat-selection-list class="mat-primary-vivid" [(ngModel)]="visibleColumns">
      <mat-list-option *ngFor="let column of columns" checkboxPosition="before" [value]="column.name">
        {{ column.title }}
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div class="mc-spring-board-footer">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button (click)="onSaveClicked()" color="primary">Save</button>
  </div>
</div>