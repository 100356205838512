import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { ProjectConditionTag } from '@portal-core/project-files/conditions/models/project-condition-tag.model';
import { GridComponent } from '@portal-core/ui/grid/components/grid/grid.component';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { BehaviorSubject, Observable, Subscription, combineLatest, map, tap } from 'rxjs';

@Component({
  selector: 'mc-conditions-grid',
  templateUrl: './conditions-grid.component.html',
  styleUrls: ['./conditions-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ConditionsGridComponent implements OnInit {
  @Input() conditionTags: ProjectConditionTag[];
  @Input() undefinedTagsNames: string[];
  @Input() initialConditionTagsName?: string[] = [];
  @InputObservable('conditionTags') conditionTags$: Observable<ProjectConditionTag[]>;
  @InputObservable('undefinedTagsNames') undefinedTagsNames$: Observable<ProjectConditionTag[]>;
  @Input() useLongName?: boolean = false;
  @Input() allowSelect?: boolean = true;
  @Input() allowEdit?: boolean = false;
  @Input() resetPageIndexManually?: boolean = false;
  @Input() disableClearSort?: boolean = false;
  @Output() conditionSelect = new EventEmitter<string[] | null>();
  @Output() conditionEdit = new EventEmitter<ProjectConditionTag>();
  @Output() conditionRemove = new EventEmitter<ProjectConditionTag>();
  /** Whether or not pagination can be hidden automatically for small data sets, i.e. when items count is less than minimum page size. */
  @Input() hideEmptyPagination?: boolean = false;

  @ViewChild(GridComponent, { static: true }) grid: GridComponent<ProjectConditionTag>;

  items$: Observable<ProjectConditionTag[]>;
  itemCount: number;
  pageIndex: number = 0;
  pageSize: number = 25;
  columns: GridColumn[];
  visibleColumns: string[];
  defaultSort: Sort;

  private itemSelection: SelectionModel<ProjectConditionTag> = new SelectionModel<ProjectConditionTag>(true);
  private pageItemsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private sortGridSubject: BehaviorSubject<Sort | null>;
  private orderInFile: Map<ProjectConditionTag, number> = new Map();

  itemsSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.defineColumns();
    this.defaultSort = { active: this.columns[0].name, direction: 'asc' };
    this.sortGridSubject = new BehaviorSubject(this.defaultSort);
    this.setItems();
    this.selectInitial();
  }

  clearSelection() {
    this.grid.clearSelection();
    this.cdr.markForCheck();
  }

  resetPageIndex() {
    this.pageIndex = 0;
    // Trigger the items to be updated for the new page index/size
    this.pageItemsSubject.next();
  }

  onPageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.grid.pageSizeOptions[0]
    // Trigger the items to be updated for the new page index/size
    this.pageItemsSubject.next();
  }

  onSortChanged(sort: Sort) {
    this.sortGridSubject.next(sort);
  }

  onNameClicked(condition: ProjectConditionTag) {
    this.grid.onItemSelectionChanged(condition);
  }

  onSelectedTagsChanged(newSelection: ProjectConditionTag[]) {
    if (this.grid.items) {
      this.itemSelection.deselect(...this.grid.items);
      this.itemSelection.select(...newSelection);
      this.conditionSelect.emit(this.itemSelection.selected.map(x => x.Id));
    }
  }

  onEditConditionTag(conditionTag: ProjectConditionTag) {
    this.conditionEdit.emit(conditionTag);
  }

  onRemoveConditionTag(conditionTag: ProjectConditionTag) {
    this.conditionRemove.emit(conditionTag);
  }

  private defineColumns() {
    this.columns = [
      {
        name: 'Name',
        title: 'Name',
        sortEnabled: true,
        disableClear: this.disableClearSort,
        type: PageDataType.String
      },
      {
        name: 'Comment',
        title: 'Comment',
        sortEnabled: true,
        disableClear: this.disableClearSort,
        type: PageDataType.String
      }
    ];

    this.visibleColumns = this.columns.map(column => column.name);
  }

  private sort(sort: Sort, items: ProjectConditionTag[]): ProjectConditionTag[] {
    if (!sort.direction) {
      return items.sort((left: ProjectConditionTag, right: ProjectConditionTag): number => {
        return this.orderInFile.get(left) < this.orderInFile.get(right) ? -1 : 1;
      });
    }
    const multiplier = sort.direction === 'asc' ? 1 : -1;
    const sortActive = this.useLongName && sort.active === this.columns[0].name ? 'Id' : sort.active;
    let compare = (left: ProjectConditionTag, right: ProjectConditionTag): number => {
      let r = left[sortActive]?.toString().localeCompare(right[sortActive]?.toString());
      return r * multiplier;
    }
    return items.sort(compare);
  }

  private setItems() {
    // Create an observable that will emit the condition tags but more importantly resets the page index when the condition tags change.
    // This is done in conjunction with the condition tags to ensure the page index is updated before items$ builds the page.
    const conditionTags$ = this.conditionTags$.pipe(
      tap((tags) => {
        // Update itemCount
        this.itemCount = tags.length;
        // Reset the page index when the variable set changes
        if (this.resetPageIndexManually) {
          const pagesCount = Math.ceil(this.itemCount / this.pageSize);
          if (0 < pagesCount && pagesCount <= this.pageIndex) {
            this.pageIndex = pagesCount - 1;
          }
        } else {
          this.pageIndex = 0;
        }
        // Make sure the grid's paginator updates to the new page index
        this.cdr.markForCheck();
        // Refresh orderInFile before sorting items
        let order = 0;
        this.orderInFile.clear();
        tags.forEach((item) => this.orderInFile.set(item, order++));
      })
    );

    const sortedItems$ = combineLatest([this.sortGridSubject.asObservable(), conditionTags$, this.undefinedTagsNames$])
      .pipe(
        map(([sort, items]) => {
          return [
            ...this.sort(sort, items)
          ];
        })
      );

    // Create an observable of the items for the current page
    this.items$ = combineLatest([
      sortedItems$,
      this.pageItemsSubject.asObservable()
    ]).pipe(
      map(([items]) => {
        const start = this.pageIndex * this.pageSize;
        return items.slice(start, start + this.pageSize);
      })
    );

    this.itemsSubscription = this.items$.subscribe(() => {
      this.selectInitial();
    });
  }

  private selectInitial() {
    this.grid.replaceSelectionById(...this.initialConditionTagsName);
    const selectedTags = this.conditionTags.filter((tag) => this.initialConditionTagsName.includes(tag.Id));
    this.itemSelection.select(...selectedTags);
  }
}
