import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { UndefinedGlossaryTerm } from '@portal-core/reports/models/undefined-glossary-terms.model';

@CollectionStateSelectors({
  stateType: ProjectReportsUndefinedGlossaryTermsState
})
@State<ProjectReportsUndefinedGlossaryTermsState>({
  name: ProjectReportsUndefinedGlossaryTermsState.source
})
@Injectable()
export class ProjectReportsUndefinedGlossaryTermsState extends CollectionStateBase {
  static source = 'ProjectReportsUndefinedGlossaryTerms';
  getSource(): string {
    return ProjectReportsUndefinedGlossaryTermsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReportsUndefinedGlossaryTermsDataService extends CollectionDataServiceBase<UndefinedGlossaryTerm> {
  constructor(protected store: Store) {
    super(store, ProjectReportsUndefinedGlossaryTermsState);
  }
}
