import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { firstOrNull, makeArray } from '@common/util/array';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { TocFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ReviewPackageStatus } from '@portal-core/reviews/review-packages/enums/review-package-status.enum';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { LoadingState } from '@portal-core/util/loading-state';

interface ReviewPackageSettingsForm {
  description: FormControl<string>;
  name: FormControl<string>;
  ownerLicenseUserId: FormControl<number[]>;
  resetReviewerStatus: FormControl<boolean>;
  status: FormControl<ReviewPackageStatus>;
  tocPath: FormControl<string[]>;
}

@Component({
  selector: 'mc-review-package-settings-form',
  templateUrl: './review-package-settings-form.component.html',
  styleUrls: ['./review-package-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageSettingsFormComponent implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewPackage: ReviewPackage;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  LicenseUserSeatType: typeof LicenseUserSeatType = LicenseUserSeatType;
  ReviewFileUserStatus: typeof ReviewFileUserStatus = ReviewFileUserStatus;
  ReviewPackageStatus: typeof ReviewPackageStatus = ReviewPackageStatus;
  TocFileFilter: string = TocFileFilter;

  savingState: LoadingState<string> = new LoadingState<string>();
  reviewSettingsForm: FormGroup<ReviewPackageSettingsForm>;

  get dirty(): boolean {
    return this.reviewSettingsForm.dirty;
  }

  get statusChangedToOpen(): boolean {
    if (this.reviewPackage) {
      return this.reviewPackage.Status !== ReviewPackageStatus.Open && this.reviewSettingsForm.value.status === ReviewPackageStatus.Open;
    } else {
      return false;
    }
  }

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private reviewPackagesService: ReviewPackagesService,
    private licenseUsersService: LicenseUsersService
  ) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reviewPackage) {
      this.resetForm(this.reviewPackage || ({} as ReviewPackage));
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    if (!this.reviewSettingsForm.valid) {
      return;
    }

    let ownerUserId: string = null;
    const ownerLicenseUserIds = this.reviewSettingsForm.get('ownerLicenseUserId').value;
    if (typeof ownerLicenseUserIds?.[0] === 'number') {
      ownerUserId = this.licenseUsersService.getItemById(ownerLicenseUserIds[0])?.User?.Id;
    }

    this.savingState.update(true);

    this.reviewPackagesService.updateReviewPackage$({
      AuthorUserId: ownerUserId,
      Description: this.reviewSettingsForm.value.description,
      Id: this.reviewPackage.Id,
      Name: this.reviewSettingsForm.value.name,
      Status: this.reviewSettingsForm.value.status,
      TocPath: firstOrNull(this.reviewSettingsForm.value.tocPath)
    }, this.statusChangedToOpen && this.reviewSettingsForm.value.resetReviewerStatus).subscribe(() => {
      this.savingState.update(false);
      this.reviewSettingsForm.markAsPristine();
      this.snackBar.open('Your settings have been saved.', 'OK', { duration: 2500 });
      this.saved.emit();
    }, error => {
      this.savingState.update(false, 'Unable to update your review package settings.', this.errorService.getErrorMessages(error));
    });
  }

  private buildForm() {
    this.reviewSettingsForm = this.formBuilder.group<ReviewPackageSettingsForm>({
      description: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      ownerLicenseUserId: new FormControl<number[]>(null),
      resetReviewerStatus: new FormControl<boolean>(false, { nonNullable: true }),
      status: new FormControl<ReviewPackageStatus>(null, [Validators.required]),
      tocPath: new FormControl<string[]>(null)
    });
  }

  private resetForm(reviewPackage: ReviewPackage) {
    const ownerLicenseUserId =
      reviewPackage.AuthorUserId && reviewPackage.LicenseId
        ? this.licenseUsersService.getLicenseUserByUserId(reviewPackage.AuthorUserId, reviewPackage.LicenseId)?.Id
        : null;

    this.reviewSettingsForm.reset({
      description: reviewPackage.Description,
      name: reviewPackage.Name,
      ownerLicenseUserId: typeof ownerLicenseUserId === 'number' ? [ownerLicenseUserId] : null,
      status: reviewPackage.Status,
      tocPath: makeArray(reviewPackage.TocPath)
    });
  }
}
