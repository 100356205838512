import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PathBasenamePipe } from './path-base-name.pipe';

@NgModule({
  declarations: [PathBasenamePipe],
  imports: [CommonModule],
  exports: [PathBasenamePipe]
})
export class PathBasenameModule { }
