import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ProjectProfileModule } from '@portal-core/projects/components/project-profile/project-profile.module';
import { DialogAutoFocusModule } from '@portal-core/ui/dialog/directives/dialog-auto-focus/dialog-auto-focus.module';
import { ProjectProfileDialogComponent } from './project-profile-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DialogAutoFocusModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatIconModule,
    ProjectProfileModule
  ],
  declarations: [
    ProjectProfileDialogComponent
  ],
  exports: [
    ProjectProfileDialogComponent
  ]
})

// This module should only be imported into @portal-core/dialogs/dialogs.module due to circular dependencies
export class ProjectProfileDialogModule { }
