import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapCodeSnippetSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapcodesnippet: {
      group: 'block',
      // 0-1 button followed by 0-1 caption, followed by 1 body
      content: 'madcapcodesnippetcopybutton? madcapcodesnippetcaption? madcapcodesnippetbody',
      parseDOM: [{ tag: 'MadCap\\:codeSnippet' }],
      toDOM() { return ['pre'/* Use for pre styling */, { class: 'mc-madcap-code-snippet' }, 0]; },
      tagName: 'MadCap:codeSnippet',
      specContent: 'madcapcodesnippetbody'
    },
    madcapcodesnippetcopybutton: {
      group: 'block',
      parseDOM: [{ tag: 'MadCap\\:codeSnippetCopyButton' }],
      toDOM() { return ['div', { class: 'mc-madcap-code-snippet-copy-button' }]; },
      tagName: 'MadCap:codeSnippetCopyButton',
      isVoid: true
    },
    madcapcodesnippetcaption: {
      group: 'block',
      content: 'block+',
      fillIfContentDeleted: 'mcCentralContainer',
      parseDOM: [{ tag: 'MadCap\\:codeSnippetCaption' }],
      toDOM() { return ['div', { class: 'mc-madcap-code-snippet-caption' }, 0]; },
      tagName: 'MadCap:codeSnippetCaption'
    },
    madcapcodesnippetbody: {
      group: 'block',
      content: 'text*',
      attrs: {
        'MadCap:useLineNumbers': { default: undefined },
        'MadCap:lineNumberStart': { default: undefined },
        'MadCap:continue': { default: undefined }
      },
      code: true, // Preserves line-breaks during editing
      parseDOM: [{
        tag: 'MadCap\\:codeSnippetBody',
        preserveWhitespace: 'full',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['MadCap:useLineNumbers', 'MadCap:lineNumberStart', 'MadCap:continue']);
        }
      }],
      toDOM() { return ['div', { class: 'mc-madcap-code-snippet-body' }, 0]; },
      tagName: 'MadCap:codeSnippetBody'
    }
  };
}
