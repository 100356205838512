import { ChangeDetectorRef, Directive } from '@angular/core';

/**
 * The base class for dynamic view components.
 */
@Directive()
export abstract class DynamicViewBase {
  /**
  * Optional class name suffix to give to the view's parent component (mc-dynamic-view)
  */
  public get parentClassNameSuffix(): string {
    return undefined;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef) { }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }
}
