import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SystemMessages } from '@portal-core/system-messages/components/system-messages';
import { SystemMessageType } from '@portal-core/system-messages/enums/system-message-type.enum';
import { SystemMessage } from '@portal-core/system-messages/models/system-message.model';
import { SystemMessagesService } from '@portal-core/system-messages/services/system-messages.service';

@Component({
  selector: 'mc-system-message-list',
  templateUrl: './system-message-list.component.html',
  styleUrls: ['./system-message-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessageListComponent extends SystemMessages {
  constructor(protected systemMessagesService: SystemMessagesService) {
    super(systemMessagesService);
  }

  getMessageColorClassName(message: SystemMessage): string {
    return message.Type === SystemMessageType.WhatsNew ? 'mc-whats-new-link' : 'mc-system-message-link';
  }
}
