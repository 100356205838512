import { Component, ViewEncapsulation, Output, EventEmitter, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DOWN_ARROW, ENTER, LEFT_ARROW, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DimensionOptions } from '@common/prosemirror/commands/table.command';
import { MathService } from '@portal-core/general/services/math.service';

@Component({
  selector: 'mc-dimension-picker',
  templateUrl: './dimension-picker.component.html',
  styleUrls: ['./dimension-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionPickerComponent {
  @Input() tooltip?: string;
  @Input() icon?: string;
  @Output() options: EventEmitter<DimensionOptions> = new EventEmitter<DimensionOptions>();
  @Output() dropdownMenuClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('menuTrigger', { read: MatMenuTrigger, static: true }) menuTrigger: MatMenuTrigger;

  dimensions: DimensionOptions;

  private maxDimensionHeight: number = 10;
  private maxDimensionWidth: number = 10;
  private selectedDimensions: DimensionOptions;

  constructor(private mathService: MathService) { }

  onMenuOpened() {
    this.dimensions = {width: 1, height: 1};
    this.selectedDimensions = null;
  }

  onPositionClicked() {
    this.selectedDimensions = this.dimensions;
    this.options.emit(this.dimensions);
  }

  onPositionChange(e: MouseEvent) {
    this.dimensions.width = Math.max( Math.ceil(e.offsetX / 20 /* each cube is 2rem */), 1);
    this.dimensions.height = Math.max( Math.ceil(e.offsetY / 20), 1);
  }

  onDropdownMenuClosed(event: void | 'click' | 'keydown' | 'tab') {
    this.dropdownMenuClosed.emit({
      dimensions: this.selectedDimensions,
      source: event
    });

    this.selectedDimensions = null;
  }

  onKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
    case LEFT_ARROW:
      this.setDimensionWidth(this.dimensions.width - 1);
      event.preventDefault();
      break;
    case RIGHT_ARROW:
      this.setDimensionWidth(this.dimensions.width + 1);
      event.preventDefault();
      break;
    case UP_ARROW:
      this.setDimensionHeight(this.dimensions.height - 1);
      event.preventDefault();
      break;
    case DOWN_ARROW:
      this.setDimensionHeight(this.dimensions.height + 1);
      event.preventDefault();
      break;
    case ENTER:
      this.selectedDimensions = this.dimensions;
      this.options.emit(this.dimensions);
      this.menuTrigger.closeMenu();
      event.preventDefault();
      break;
    }
  }

  private setDimensionHeight(height: number) {
    this.dimensions.height = this.mathService.clamp(height, 1, this.maxDimensionHeight);
  }

  private setDimensionWidth(width: number) {
    this.dimensions.width = this.mathService.clamp(width, 1, this.maxDimensionWidth);
  }
}
