import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToOnLoadDirective } from './scroll-to-on-load.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScrollToOnLoadDirective
  ],
  exports: [
    ScrollToOnLoadDirective
  ]
})
export class ScrollToOnLoadModule { }
