import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchAsyncPipe } from './branch-async.pipe';

@NgModule({
  declarations: [BranchAsyncPipe],
  imports: [CommonModule],
  exports: [BranchAsyncPipe],
  providers: [BranchAsyncPipe]
})
export class BranchAsyncModule {}
