<div #trigger class="mc-document-node-selector-input-trigger" mcPopupClass="mc-document-node-selector-input-popup" [mcPopupHeight]="popupHeight" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex">
    <mat-chip-list class="mc-document-node-selector-input-chip-list">
        <mat-chip *ngIf="value" class="mc-document-node-selector-input-chip" [removable]="true" [selectable]="false" (mcClick)="onFileChipRemoved()" (removed)="onFileChipRemoved()">
            <span class="mc-truncate">{{value.node | mcCreateDocumentBucketNodeTitle : value.topOfTheDocument}}</span>
            <mat-icon matChipRemove fontSet="project-titan" class="mc-document-node-selector-input-remove-chip" fontIcon="icon-close"></mat-icon>
        </mat-chip>
    </mat-chip-list>
    <mc-dropdown-arrow class="mc-document-node-selector-input-arrow"></mc-dropdown-arrow>
</div>

<mc-popup #popup>
    <mat-tree class="mc-tree mc-tree-compact mc-list-selectable" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node [ngClass]="[(value?.pos === nodeInfo.pos && !value?.topOfTheDocument) ||  (nodeInfo?.value?.topOfTheDocument && value?.topOfTheDocument)  ? 'mc-list-item-selected' : '']" *matTreeNodeDef="let nodeInfo">
            <div matRipple class="mc-tree-node-item" (click)="onNodeSelected(nodeInfo)">
                {{nodeInfo.node | mcCreateDocumentBucketNodeTitle : nodeInfo?.value?.topOfTheDocument}}
            </div>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let documentBucketNode; when: hasChild">
            <div class="mat-tree-node">
                <div matTreeNodeToggle matRipple class="mc-tree-node-item">
                    <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="treeControl.isExpanded(documentBucketNode) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
                    {{documentBucketNode.name}}
                </div>
            </div>
            <div class="mc-nested-tree-node-items" [class.mc-hidden]="!treeControl.isExpanded(documentBucketNode)">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
</mc-popup>