import { descendants, removeStyles } from '@common/html/util/dom';

/**
 * Normalizes styles in HTML by removing the styles that are not whitelisted.
 */
export class HtmlStyleNormalizer {
  normalize(doc: Document, whitelist: string[]) {
    descendants(doc.documentElement, (node: Element) => {
      if (whitelist?.length > 0) {
        removeStyles(node, name => !whitelist.includes(name));
      } else {
        node.removeAttribute?.('style');
      }
    });
  }
}
