import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { VariableSetsListComponent } from './variable-sets-list.component';

@NgModule({
  declarations: [VariableSetsListComponent],
  exports: [VariableSetsListComponent],
  imports: [CommonModule, MatListModule, MatTooltipModule, MatCheckboxModule]
})
export class VariableSetsListModule { }
