<div class="mc-vertical-split-dialog">
  <div matDialogTitle>
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-image-icon mc-image-icon-normal" color="primary"></mat-icon>
    <button mat-icon-button color="primary" class="mc-vertical-split-dialog-close-button" tabindex="-1" matDialogClose>
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <h2 class="mc-vertical-split-dialog-body" [ngSwitch]="feature">
      <ng-container *ngSwitchCase="'image-insertion'">
        Review must be created from the latest version of MadCap Flare to enable image support.
      </ng-container>
      <ng-container *ngSwitchDefault>
        Authors should upgrade to the latest version of MadCap Flare to enable this feature.
      </ng-container>
    </h2>
  </mat-dialog-content>

  <mat-dialog-actions class="mc-dialog-actions-align-center">
    <button mat-raised-button color="primary" matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</div>
