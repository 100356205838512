import { MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';
import { SchemaPlugin } from '../../prosemirror/model/schema-plugin';
import { getSchemaAttr, modifyDomOutputSpec } from '../../util/schema';

const targetNameAttrName = 'MadCap:targetName';

export class MadCapTargetNameSchemaPlugin extends SchemaPlugin {
  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    Object.entries(nodes).forEach(([name, nodeSpec]) => {
      if (name !== 'text') {
        nodeSpec.attrs = Object.assign(nodeSpec.attrs || {}, { [targetNameAttrName]: { default: undefined } });

        if (Array.isArray(nodeSpec.parseDOM)) {
          nodeSpec.parseDOM.forEach(parser => {
            const originalGetAttrs = parser.getAttrs;

            parser.getAttrs = function (dom: Element) {
              let attrs = originalGetAttrs ? originalGetAttrs.apply(this, arguments) : {};

              if (attrs === false) {
                return false;
              }

              const targetName = getSchemaAttr(dom, targetNameAttrName);
              if (targetName) {
                attrs = attrs || {}; // Ensure attrs exists
                attrs[targetNameAttrName] = targetName;
              }

              return attrs;
            };
          });
        }

        const originalToDOM = nodeSpec.toDOM;
        nodeSpec.toDOM = function (node: ProseMirrorNode) {
          const dom = originalToDOM.apply(this, arguments);

          const targetName = node.attrs[targetNameAttrName];

          if (targetName) {
            modifyDomOutputSpec(dom, {
              attrs: {
                add: {
                  class: 'mc-madcap-target-name icon-pennant'
                }
              }
            });
          }

          return dom;
        };
      }
    });
  }
}
