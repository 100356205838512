import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { defaultParseRulePriority, getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class LinkSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    link: {
      group: 'inline',
      inline: true,
      isLink: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        href: { default: undefined },
        alt: { default: undefined },
        title: { default: undefined },
        tabindex: { default: undefined },
        target: { default: undefined },
        class: { default: undefined },
        id: { default: undefined }
      },
      parseDOM: [{
        tag: 'a',
        priority: defaultParseRulePriority - 1, // There are other anchor tags that have a higher priority (eg madcap bookmarks)
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['alt', 'class', 'href', 'id', 'tabindex', 'target', 'title']);
        },
        preserveWhitespace: true
      }],
      toDOM(node: ProseMirrorNode) {
        return ['span', {
          href: node.attrs.href,
          class: 'mc-pm-link'
        }, 0];
      },
      tagName: 'a'
    },

    madcapcrossreference: {
      group: 'inline',
      inline: true,
      isLink: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        href: { default: undefined },
        target: { default: undefined },
        title: { default: undefined },
        alt: { default: undefined },
      },
      parseDOM: [{
        tag: 'MadCap\\:xref',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['href', 'target', 'title', 'alt']);
        },
        preserveWhitespace: true
      }],
      toDOM() {
        return ['span', { class: 'mc-madcap-xref' }, 0];
      },
      tagName: 'MadCap:xref'
    }
  };
}
