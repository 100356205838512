import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs, hasSchemaAttr } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapBookmarkSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapbookmark: {
      group: 'inline',
      linkBucket: 'bookmark',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        name: { default: undefined }
      },
      parseDOM: [{
        tag: 'a[name]',
        getAttrs(dom: HTMLElement) {
          if (hasSchemaAttr(dom, 'href')) {
            return false;
          }

          return getSchemaAttrs(dom, ['name']);
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-bookmark icon-bookmark' }, 0]; },
      tagName: 'a',
      toDisplayName() { return 'MadCap:bookmark'; }
    }
  };
}
