import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SystemMessagesState } from '@portal-core/system-messages/services/system-messages-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([SystemMessagesState])
  ]
})
export class SystemMessagesServicesModule { }
