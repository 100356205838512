import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';

@CollectionStateSelectors({
  stateType: ReviewPackageUsersState
})
@State<ReviewPackageUsersState>({
  name: ReviewPackageUsersState.source
})
@Injectable()
export class ReviewPackageUsersState extends CollectionStateBase {
  static source = 'ReviewPackageUsers';
  getSource(): string {
    return ReviewPackageUsersState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewPackageUsersDataService extends CollectionDataServiceBase<ReviewPackageUser> {
  constructor(protected store: Store) {
    super(store, ReviewPackageUsersState);
  }
}
