import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { isEnumValue } from '@common/util/enum';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { TeamProfile } from '@portal-core/profiles/models/team-profile.model';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { TeamAccessFormTab } from '@portal-core/teams/components/team-access-form/team-access-form.component';
import { TeamProfileComponent, TeamProfileForm } from '@portal-core/teams/components/team-profile/team-profile.component';
import { TeamProfileTab } from '@portal-core/teams/enums/team-profile-tab.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, first, switchMap } from 'rxjs';

export interface TeamProfileDialogData {
  teamAccessFormTab?: TeamAccessFormTab;
  teamId: number;
  teamProfileTab?: TeamProfileTab;
}

@Component({
  selector: 'mc-team-profile-dialog',
  templateUrl: './team-profile-dialog.component.html',
  styleUrls: ['./team-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '88rem',
    height: '79rem',
    autoFocus: false
  };

  @ViewChild(TeamProfileComponent, { static: true }) teamProfileComponent: TeamProfileComponent;

  public get requirePromptOnClose(): boolean {
    return this.teamProfileComponent?.requirePromptOnClose;
  }

  loadingState: LoadingState<string> = new LoadingState<string>();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  teamAccessFormTab: TeamAccessFormTab;
  teamProfile$: Observable<TeamProfile>;
  teamProfileTab: TeamProfileTab;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TeamProfileDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<TeamProfileDialogComponent>,
    private errorService: ErrorService,
    private profilesService: ProfilesService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);

    // Create an observable of the project profile. Start with reload$ so that the data can be reloaded as needed
    this.teamProfile$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getTeamProfile$(this.data.teamId)),
      cache()
    );

    this.teamProfile$.pipe(
      first(),
    ).subscribe(() => {
      // Set the initial tab
      if (isEnumValue(TeamProfileTab, this.data.teamProfileTab)) {
        this.teamProfileTab = this.data.teamProfileTab;
      } else {
        this.teamProfileTab = TeamProfileTab.Access;
      }

      this.teamAccessFormTab = this.data.teamAccessFormTab;

      this.loadingState.update(false);
      this.onLoaded();
    }, error => {
      this.loadingState.update(false, 'Unable to load the team\'s profile.', this.errorService.getErrorMessages(error));
    });
  }

  onSaved(teamProfileForm: TeamProfileForm) {
    switch (teamProfileForm) {
      case TeamProfileForm.Settings:
      case TeamProfileForm.Access:
        this.reload();
        break;
      case TeamProfileForm.Delete:
        this.closeDialog();
        break;
    }
  }

  onCancel() {
    this.closeDialog();
  }

  reload() {
    this.reload$.next();
  }
}
