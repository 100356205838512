import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { MyNotificationListModule } from '@portal-core/notifications/components/my-notification-list/my-notification-list.module';
import { TaskNotificationListModule } from '@portal-core/notifications/components/task-notification-list/task-notification-list.module';
import { AlertCenterDialogCardComponent } from './alert-center-dialog-card.component';

@NgModule({
  imports: [
    CommonModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    MyNotificationListModule,
    TaskNotificationListModule
  ],
  declarations: [
    AlertCenterDialogCardComponent
  ],
  exports: [
    AlertCenterDialogCardComponent
  ]
})
export class AlertCenterDialogCardModule { }
