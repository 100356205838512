import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreService } from '@portal-client/core/core.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'mc-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNavComponent implements OnInit {
  @HostBinding('class.app-nav') appNavClass = true;

  currentLicenseId$: Observable<number>;
  currentLicenseUser$: Observable<LicenseUser>;

  private navClickedSubject = new Subject<string>();
  navClicked$ = this.navClickedSubject.asObservable();

  constructor(
    private coreService: CoreService,
  ) { }

  ngOnInit() {
    this.currentLicenseId$ = this.coreService.getActiveLicenseId$();
    this.currentLicenseUser$ = this.coreService.getLicenseUser$();
  }

  onNavClick(pageName: string) {
    this.navClickedSubject.next(pageName);
  }
}
