import { attrsEq, nodeSpecEq } from '@common/prosemirror/model/node';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class MadCapAnnotationSchemaPlugin extends SchemaPlugin {
  /*
   *  NOTE with the definingForContent setting : replacing annotations with the same thing, makes a copy of the first annotation
   *  This is happening because the selection starts within an annotation and since pm considers that a parent and tries to
   *  keep a similar structure it copies that "parent".
   *  Check out bug 182014 which uses the ai dialog to do the replace.
   */
  nodes: Dictionary<NodeSpec> = {
    madcapannotation: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      attrs: {
        'MadCap:comment': {
          default: undefined,
          toFlareXML(value: any): string {
            return value?.replace(/\n/g, '\\r\\n');
          }
        },
        'MadCap:createDate': { default: undefined },
        'MadCap:creator': { default: undefined },
        'MadCap:creatorCentralUserId': { default: undefined },
        'MadCap:editDate': { default: undefined },
        'MadCap:editor': { default: undefined },
        'MadCap:guid': { default: undefined, skipExport: true },
        'MadCap:initials': { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:annotation',
        getAttrs(dom: HTMLElement) {
          const attrs = getSchemaAttrs(dom, ['MadCap:comment', 'MadCap:createDate', 'MadCap:creator', 'MadCap:creatorCentralUserId', 'MadCap:editDate', 'MadCap:editor', 'MadCap:guid', 'MadCap:initials']);

          if (typeof attrs['MadCap:comment'] === 'string') {
            attrs['MadCap:comment'] = attrs['MadCap:comment'].replace(/\\r\\n/g, '\n');
          }

          return attrs;
        },
        preserveWhitespace: true
      }, {
        tag: 'span',
        getAttrs(dom: HTMLElement) {
          if (!dom.classList.contains('mc-madcap-annotation')) {
            return false;
          }

          return getSchemaAttrs(dom, ['MadCap:comment', 'MadCap:createDate', 'MadCap:creator', 'MadCap:creatorCentralUserId', 'MadCap:editDate', 'MadCap:editor', 'MadCap:guid', 'MadCap:initials']);
        },
        preserveWhitespace: true
      }],
      toDOM(node: ProseMirrorNode) {
        return ['span', {
          'madcap-guid': node.attrs['MadCap:guid'],
          class: 'mc-madcap-annotation'
        }, 0];
      },
      tagName: 'MadCap:annotation',
      eq: nodeSpecEq({
        attrs: (nodeA: ProseMirrorNode, nodeB: ProseMirrorNode): boolean => {
          // Because guids are never equal treat MadCap:guid attributes like they are always equal for comparison reasons.
          // This is needed for scenarios where the document is converted from a prosemirror doc to a string doc and back.
          // The guids will be re-assigned but the nodes are still the same in this situation.
          return attrsEq(nodeA.attrs, nodeB.attrs ?? nodeB.type.defaultAttrs ?? {}, (valueA, valueB, name) => name === 'MadCap:guid' || undefined);
        }
      })
    }
  };
}
