import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';

export interface ConfirmDialogData {
  action?: string;
  actionColor?: ThemePalette;
  cancelAction?: string;
  cancelActionColor?: ThemePalette;
  prompt?: string;
  showCloseButton?: boolean;
  title?: string;
  additionalDescription?: string[];
}

/**
 * A generic dialog for getting user confirmation.
 * The `mc-confirm-dialog` component behaves like any other [Material dialog]{@link https://material.angular.io/components/dialog/overview}.
 * The MatDialog service can be used to open the dialog with Material Design styling and animations.
 * The confirm dialog can be configured when opening it using the data object.
 * @param {Object} [data] - the configuration passed into the dialog when opened
 * @param {string} [data.action='Proceed'] - the display text for the confirm button
 * @param {ThemePalette} [data.actionColor='primary'] - the theme color for the confirm button
 * @param {string} [data.cancelAction='Cancel'] - the display text for the cancel button
 * @param {ThemePalette} [data.cancelActionColor] - the theme color for the cancel button
 * @param {string} [data.prompt] - the text prompt displayed to the user
 * @param {boolean} [data.showCloseButton=true] - whether the close button should be displayed
 * @param {string} [data.title] - the title of the dialog
 * @param {string[]} [data.additionalDescription] - the list of additional text displayed to the user
 */
@Component({
  selector: 'mc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '36rem'
  };

  action: string;
  actionColor: ThemePalette;
  cancelAction: string;
  cancelActionColor: ThemePalette;
  prompt: string;
  showCloseButton: boolean;
  title: string;
  additionalDescription: string[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData) { }

  ngOnInit() {
    this.action = this.data.action || 'Proceed';
    this.actionColor = this.data.actionColor || 'primary';
    this.cancelAction = this.data.cancelAction || 'Cancel';
    this.cancelActionColor = this.data.cancelActionColor || undefined;
    this.prompt = this.data.prompt || '';
    this.showCloseButton = typeof this.data.showCloseButton === 'boolean' ? this.data.showCloseButton : true;
    this.title = this.data.title || '';
    this.additionalDescription = this.data.additionalDescription || [];
  }
}
