import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs, toAttributeBoolean, toAttributeBooleanString } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

const HTML5VideoAttrName = 'MadCap:HTML5Video';
const MadCapMultimediaParamControlsAttrName = 'MadCap:Param_controls';
const MadCapMultimediaParamMutedAttrName = 'MadCap:Param_muted';
const MadCapMultimediaParamLoopAttrName = 'MadCap:Param_loop';
const MadCapMultimediaParamAutoplayAttrName = 'MadCap:Param_autoplay';

export class MadCapMultimediaSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapmultimedia: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      atom: true,
      definingForContent: true,
      attrs: {
        src: { default: undefined },
        [HTML5VideoAttrName]: {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        },
        [MadCapMultimediaParamControlsAttrName]: {
          default: undefined
        },
        [MadCapMultimediaParamMutedAttrName]: {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        },
        [MadCapMultimediaParamLoopAttrName]: {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        },
        [MadCapMultimediaParamAutoplayAttrName]: {
          default: undefined,
          toFlareXML(value: any): string {
            return toAttributeBooleanString(value);
          }
        }
      },
      parseDOM: [{
        tag: 'object',
        getAttrs(dom: HTMLElement) {
          const attrs = getSchemaAttrs(dom, ['src', HTML5VideoAttrName, MadCapMultimediaParamControlsAttrName, MadCapMultimediaParamMutedAttrName, MadCapMultimediaParamLoopAttrName, MadCapMultimediaParamAutoplayAttrName]);
          return {
            ...attrs,
            [HTML5VideoAttrName]: toAttributeBoolean(attrs[HTML5VideoAttrName]),
            [MadCapMultimediaParamMutedAttrName]: toAttributeBoolean(attrs[MadCapMultimediaParamMutedAttrName]),
            [MadCapMultimediaParamLoopAttrName]: toAttributeBoolean(attrs[MadCapMultimediaParamLoopAttrName]),
            [MadCapMultimediaParamAutoplayAttrName]: toAttributeBoolean(attrs[MadCapMultimediaParamAutoplayAttrName])
          };
        },
        preserveWhitespace: true
      }],
      toDOM() {
        return ['span', { class: 'mc-madcap-multimedia' }, 'Multimedia'];
      },
      tagName: 'object'
    }
  };
}
