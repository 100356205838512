import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicenseUserAvatarTooltipClassPipe } from './license-user-avatar-tooltip-class.pipe';

@NgModule({
  declarations: [LicenseUserAvatarTooltipClassPipe],
  imports: [CommonModule],
  exports: [LicenseUserAvatarTooltipClassPipe]
})
export class LicenseUserAvatarTooltipClassModule { }
