import { PageDataType } from '@common/paged-data/enums/page-data-type.enum';
import { CachedAccessor } from '@common/util/cached-accessor.decorator';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';

/**
 * ParsedConfig
 * The parsed page filter config for quick access to the columns by type.
 */
interface ParsedConfig {
  [PageDataType.Boolean]: GridColumn[];
  [PageDataType.Date]: GridColumn[];
  [PageDataType.Double]: GridColumn[];
  [PageDataType.Int]: GridColumn[];
  [PageDataType.Select]: GridColumn[];
  [PageDataType.String]: GridColumn[];
  sortColumns: GridColumn[];
}

/**
 * PageFilterConfigBase
 * The base class for page filter config. Provides common functionality for accessing the columns in the config and managing autocomplete inputs.
 * This is an immutable class and its values should not be directly edited.
 */
export abstract class PageFilterConfigBase {
  /** Returns the enabled columns. */
  @CachedAccessor()
  get enabledColumns(): GridColumn[] {
    if (Array.isArray(this.columns)) {
      if (Array.isArray(this.enabledColumnNames)) {
        return this.enabledColumnNames.map(columnName => {
          return this.columns.find(column => column.name === columnName);
        });
      } else {
        return this.columns;
      }
    } else {
      return [];
    }
  }

  /** Returns the parsed config by data type. */
  @CachedAccessor()
  get parsedConfig(): ParsedConfig {
    const parsedConfig: ParsedConfig = {
      [PageDataType.Boolean]: [],
      [PageDataType.Date]: [],
      [PageDataType.Double]: [],
      [PageDataType.Int]: [],
      [PageDataType.Select]: [],
      [PageDataType.String]: [],
      sortColumns: []
    };

    this.enabledColumns.forEach(column => {
      if (column.filterEnabled) {
        parsedConfig[column.type].push(column);
      }

      if (column.sortEnabled) {
        parsedConfig.sortColumns.push(column);
      }
    });

    return parsedConfig;
  }

  /** Returns the boolean filters. */
  @CachedAccessor()
  get booleanFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.Boolean];
  }

  /** Returns the boolean filter names. */
  @CachedAccessor()
  get booleanFilterNames(): string[] {
    return this.parsedConfig[PageDataType.Boolean].map(column => column.name);
  }

  /** Returns the date filters. */
  @CachedAccessor()
  get dateFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.Date];
  }

  /** Returns the date filter names. */
  @CachedAccessor()
  get dateFilterNames(): string[] {
    return this.parsedConfig[PageDataType.Date].map(column => column.name);
  }

  /** Returns the double filters. */
  @CachedAccessor()
  get doubleFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.Double];
  }

  /** Returns the double filter names. */
  @CachedAccessor()
  get doubleFilterNames(): string[] {
    return this.parsedConfig[PageDataType.Double].map(column => column.name);
  }

  /** Returns the int filters. */
  @CachedAccessor()
  get intFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.Int];
  }

  /** Returns the int filter names. */
  @CachedAccessor()
  get intFilterNames(): string[] {
    return this.parsedConfig[PageDataType.Int].map(column => column.name);
  }

  /** Returns the select filters. */
  @CachedAccessor()
  get selectFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.Select];
  }

  /** Returns the select filter names. */
  @CachedAccessor()
  get selectFilterNames(): string[] {
    return this.parsedConfig[PageDataType.Select].map(column => column.name);
  }

  /** Returns the string filters. */
  @CachedAccessor()
  get stringFilters(): GridColumn[] {
    return this.parsedConfig[PageDataType.String];
  }

  /** Returns the string filter names. */
  @CachedAccessor()
  get stringFilterNames(): string[] {
    return this.parsedConfig[PageDataType.String].map(column => column.name);
  }

  /** Returns the sort columns. */
  @CachedAccessor()
  get sortColumns(): GridColumn[] {
    return this.parsedConfig.sortColumns;
  }

  constructor(public readonly enabledColumnNames: string[], private readonly columns: GridColumn[]) { }

  /** Returns a column by name if that column is enabled. */
  getColumn(name: string): GridColumn {
    return this.enabledColumns.find(column => column.name === name);
  }
}
