import { HttpParams  } from '@angular/common/http';

/**
 * A way to pass configuration to the AuthInterceptor for a request.
 * Example
 * ```
 *  this.http.get<T>(`URL_HERE`, {
 *   params: new InterceptorHttpParams({ ignoreAuthErrors: true }, {
 *     ...normalHttpParams
 *   })
 * })
 * ```
 */
export class AuthInterceptorHttpParams extends HttpParams {
  constructor(public interceptorConfig: { ignoreAuthErrors: boolean }, params?: { [param: string]: string | ReadonlyArray<string> }) {
    super({ fromObject: params });
  }
}
