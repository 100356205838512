import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandedRowDetailDirective } from './expanded-row-detail.directive';

@NgModule({
  declarations: [
    ExpandedRowDetailDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ExpandedRowDetailDirective
  ]
})
export class ExpandedRowDetailModule { }
