import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ReviewPackageUserStatus } from '@portal-core/reviews/review-package-users/enums/review-package-user-status.enum';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { ReviewPackageUsersApiService } from '@portal-core/reviews/review-package-users/services/review-package-users-api.service';
import { ReviewPackageUsersDataService } from '@portal-core/reviews/review-package-users/services/review-package-users-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class ReviewPackageUsersService extends CollectionServiceBase<ReviewPackageUser> {
  constructor(
    private reviewPackageUsersApiService: ReviewPackageUsersApiService,
    private reviewPackageUsersDataService: ReviewPackageUsersDataService,
    protected dataService: DataService
  ) {
    super(reviewPackageUsersDataService, dataService);
  }

  protected fetchItemById$(reviewPackageUserId: number): Observable<ReviewPackageUser> {
    return this.reviewPackageUsersApiService.getReviewPackageUserById$(reviewPackageUserId);
  }

  getMyReviewPackageUserByUserId$(userId: string, reviewPackageId: number, options: GetDataOptions = null): Observable<ReviewPackageUser> {
    return this.dataService.getData<ReviewPackageUser>({
      get: () => this.reviewPackageUsersDataService.getItemByProperties$('UserId', userId, 'ReviewPackageId', reviewPackageId),
      fetch: () => this.reviewPackageUsersApiService.getMyReviewPackageUser$(reviewPackageId),
      set: newReviewPackageUser => this.reviewPackageUsersDataService.addItems$([newReviewPackageUser])
    }, options);
  }

  getReviewPackageUsersPage$(reviewPackageId: number, filter?: PageFilter): Observable<Page<ReviewPackageUser>> {
    return this.reviewPackageUsersApiService.getReviewPackageUsersPage$(reviewPackageId, filter);
  }

  setMyReviewPackageUserStatus$(reviewPackageId: number, reviewPackageUserId: number, status: ReviewPackageUserStatus): Observable<MadCloudResult> {
    return this.reviewPackageUsersApiService.setMyReviewPackageUserStatus$(reviewPackageId, status).pipe(
      tap(() => {
        this.updateItems$([{
          Id: reviewPackageUserId,
          Status: status
        }]);
      })
    );
  }
}
