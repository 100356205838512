import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectReportStatistics } from '@portal-core/reports/models/project-report-statistics.model';

@CollectionStateSelectors({
  stateType: ProjectReportStatisticsState
})
@State<ProjectReportStatisticsState>({
  name: ProjectReportStatisticsState.source
})
@Injectable()
export class ProjectReportStatisticsState extends CollectionStateBase {
  static source = 'ProjectReportStatistics';
  getSource(): string {
    return ProjectReportStatisticsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReportStatisticsDataService extends CollectionDataServiceBase<ProjectReportStatistics> {
  constructor(protected store: Store) {
    super(store, ProjectReportStatisticsState);
  }
}
