import { EditorState, Plugin, PluginKey, Selection, Transaction } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';

export const TrackedChangesMarkFixerPluginKey: PluginKey = new PluginKey('tracked-changes-mark-fixer');

/**
 * Removes the extra span inserted when backspacing in front of a space with tracked changes on it.
 * Its unclear why the extra span is being inserted but this cleans it up.
 * @returns a trackedChangesMarkFixerPlugin plugin instance
 */
export function trackedChangesMarkFixerPlugin(): Plugin {
  return new Plugin({
    key: TrackedChangesMarkFixerPluginKey,

    appendTransaction(transactions: Transaction[], oldState: EditorState, newState: EditorState): Transaction {
      const tr = newState.tr;

      if (Array.isArray(transactions)) {
        transactions.forEach(transaction => {
          // If the transaction is a single step replace
          if (transaction.docChanged && transaction.steps.length === 1 && transaction.steps[0] instanceof ReplaceStep) {
            const step = transaction.steps[0];
            const slice = step.slice;

            // If the new slice is a single inline node starting with a single space and has invalid change attrs
            if (slice.content.childCount === 1 && slice.content.firstChild.isInline && slice.content.firstChild.textContent.startsWith(' ') && slice.content.firstChild.attrs.changeIds && !slice.content.firstChild.attrs.changeList) {
              // Replace the invalid node with the original text
              tr.replaceRange(
                // Replace the range from the start of the change to the end of the change
                step.from, step.from + slice.size,
                // Grab the slice of the original text from the original doc
                transaction.before.slice(step.to - (slice.size - 2), step.to)
              );
              // Set the correct selection
              tr.setSelection(Selection.near(tr.doc.resolve(step.from)));
              tr.setMeta('trackedChangesMarkFixer', true);
            }
          }
        });
      }

      return tr.docChanged ? tr : null;
    }
  });
}
