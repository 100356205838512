import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { BuildAsyncModule } from '@portal-core/builds/pipes/build-async/build-async.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SiteDeletionFormModule } from '@portal-core/sites/components/site-deletion-form/site-deletion-form.module';
import { SiteSettingsFormModule } from '@portal-core/sites/components/site-settings-form/site-settings-form.module';
import { SiteTeamsFormModule } from '@portal-core/sites/components/site-teams-form/site-teams-form.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { SiteProfileDialogComponent } from './site-profile-dialog.component';

@NgModule({
  declarations: [SiteProfileDialogComponent],
  imports: [
    BuildAvatarModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    SiteAvatarModule,
    PreventDefaultModule,
    SiteSettingsFormModule,
    SiteDeletionFormModule,
    SiteTeamsFormModule,
    SwitchBoardModule,
    BuildAsyncModule
  ],
  exports: [SiteProfileDialogComponent]
})
export class SiteProfileDialogModule { }
