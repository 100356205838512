import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LifeCycleModule } from '@portal-core/general/directives/life-cycle/life-cycle.module';
import { EditorToolbarItemModule } from '@portal-core/ui/editor/directives/editor-toolbar-item/editor-toolbar-item.module';
import { EditorToolbarComponent } from './editor-toolbar.component';

@NgModule({
  declarations: [
    EditorToolbarComponent
  ],
  imports: [
    CommonModule,
    LifeCycleModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    EditorToolbarItemModule
  ],
  exports: [
    EditorToolbarComponent,
    EditorToolbarItemModule
  ]
})
export class EditorToolbarModule { }
