import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AuditTargetPageType } from '@portal-core/notifications/enums/audit-target-page-type.enum';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { Observable } from 'rxjs';

interface NotificationPage {
  Route: string | any[];
  Text: string;
}

@Component({
  selector: 'mc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnChanges {
  @Input() notification: Notification;

  invokerLicenseUser$: Observable<LicenseUser>;
  license$: Observable<License>;
  notificationPage: NotificationPage;
  project$: Observable<Project>;
  reviewFile$: Observable<ReviewFile>;
  team$: Observable<Team>;

  constructor(
    private licensesService: LicensesService,
    private licenseUsersService: LicenseUsersService,
    private projectsService: ProjectsService,
    private teamsService: TeamsService,
    private reviewFilesService: ReviewFilesService,
    private currentService: CurrentService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notification) {
      this.invokerLicenseUser$ = null;
      this.project$ = null;
      this.team$ = null;

      if (this.showUserAvatar()) {
        const licenseId = this.notification.LicenseId || this.currentService.getActiveLicenseId();
        this.invokerLicenseUser$ = this.licenseUsersService.getLicenseUserByUserId$(this.notification.InvokerUserId, licenseId, { allowApiRequest: false });
      } else if (this.showProjectAvatar()) {
        this.project$ = this.projectsService.getItemById$(this.notification.ProjectId, { allowApiRequest: false });
      } else if (this.showTeamAvatar()) {
        this.team$ = this.teamsService.getItemById$(this.notification.TeamId, { allowApiRequest: false });
      } else if (this.showReviewAvatar()) {
        this.reviewFile$ = this.reviewFilesService.getItemById$(this.notification.ReviewFileId, { allowApiRequest: false });
      } else if (this.showLicenseAvatar()) {
        this.license$ = this.licensesService.getItemById$(this.notification.LicenseId, { allowApiRequest: false });
      }


      if (this.notification) {
        this.notificationPage = this.getNotificationPage(this.notification);
      } else {
        this.notificationPage = null;
      }
    }
  }

  showUserAvatar(): boolean {
    return this.notification && !!this.notification.InvokerUserId;
  }

  showProjectAvatar(): boolean {
    return this.notification && !this.notification.InvokerUserId && typeof this.notification.ProjectId === 'number';
  }

  showTeamAvatar(): boolean {
    return this.notification && !this.notification.InvokerUserId && typeof this.notification.ProjectId !== 'number' && typeof this.notification.TeamId === 'number';
  }

  showReviewAvatar(): boolean {
    return this.notification && !this.notification.InvokerUserId && typeof this.notification.ProjectId !== 'number' &&
      typeof this.notification.TeamId !== 'number' && typeof this.notification.ReviewFileId === 'number';
  }

  showLicenseAvatar(): boolean {
    return this.notification && !this.showUserAvatar() && !this.showProjectAvatar() && !this.showReviewAvatar() && typeof this.notification.LicenseId === 'number';
  }

  getNotificationPage(notification: Notification): NotificationPage {
    if (notification.Category) {
      const licenseId = notification.LicenseId || this.currentService.getActiveLicenseId();

      switch (notification.Category.AuditTargetPageType) {
        case AuditTargetPageType.HomeDashboardPage:
          return {
            Route: ['/', licenseId],
            Text: 'Home'
          };
        case AuditTargetPageType.ProjectsPage:
          return {
            Route: ['/', licenseId, 'projects'],
            Text: 'Projects'
          };
        case AuditTargetPageType.ProjectDashboardPage:
          return {
            Route: ['/', licenseId, 'projects', notification.ProjectId, 'dashboard'],
            Text: 'Project'
          };
        case AuditTargetPageType.ProjectBuildsPage:
          return {
            Route: ['/', licenseId, 'projects', notification.ProjectId, 'builds'],
            Text: 'Builds'
          };
        case AuditTargetPageType.TasksPage:
          return {
            Route: ['/', licenseId, 'tasks'],
            Text: 'Tasks'
          };
        case AuditTargetPageType.TeamsPage:
          return {
            Route: ['/', licenseId, 'teams'],
            Text: 'Teams'
          };
        case AuditTargetPageType.UsersPage:
          return {
            Route: ['/', licenseId, 'users'],
            Text: 'Users'
          };
        case AuditTargetPageType.ProjectChecklistsPage:
          return {
            Route: ['/', licenseId, 'projects', notification.ProjectId, 'checklists'],
            Text: 'Checklists'
          };
        case AuditTargetPageType.ProjectReportsPage:
          return {
            Route: ['/', licenseId, 'projects', notification.ProjectId, 'reports'],
            Text: 'Reports'
          };
        case AuditTargetPageType.ReviewsPage:
          return {
            Route: ['/', licenseId, 'reviews'],
            Text: 'Reviews'
          };
        case AuditTargetPageType.SitesPage:
          return {
            Route: ['/', licenseId, 'sites'],
            Text: 'Sites'
          };
      }
    }
  }
}
