import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DiffPipe } from '@portal-core/project-files/pipes/diff/diff.pipe';

@NgModule({
    declarations: [
        DiffPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DiffPipe
    ]
})
export class DiffPipeModule { }