<form class="mc-spring-board" (ngSubmit)="onSubmit()" [formGroup]="reviewSettingsForm">
  <div class="mc-spring-board-form-body">
    <mat-form-field appearance="standard" color="primary-vivid" class="mc-form-field">
      <mat-label>Status</mat-label>
      <mat-select placeholder="Status" formControlName="status">
        <mat-option [value]="ReviewFileStatus.Open">Open</mat-option>
        <mat-option [value]="ReviewFileStatus.Closed">Closed</mat-option>
        <mat-option [value]="ReviewFileStatus.Accepted">Accepted</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mc-form-field" [hidden]="!statusChangedToOpen">
      <mat-checkbox color="primary-vivid" class="mc-checkbox-wrap" formControlName="resetReviewerStatus">
        Reset reviewer statuses to {{ ReviewFileUserStatus.Todo | mcReviewFileUserStatusText }}
      </mat-checkbox>
    </div>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="reviewSettingsForm.pristine || reviewSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>