<div class="mc-error-code-expansion-panel-icon-container" *ngIf="showIcon">
  <mat-icon class="mc-image-icon mc-image-icon-x-small mc-error-message" fontSet="project-titan" fontIcon="icon-alert"></mat-icon>
</div>

<p class="mc-error-code-expansion-panel-general-message mc-text-warn">
  <mc-error-code [errorCode]="generalErrorCode"></mc-error-code>
  <button *ngIf="detailedErrorCodes && detailedErrorCodes.length > 0" type="button" class="mc-link-button mc-error-code-expansion-panel-general-details-button" (click)="toggleDetailErrorsExpansionPanel()">Details</button>
</p>

<mat-accordion *ngIf="detailedErrorCodes && detailedErrorCodes.length > 0" displayMode="flat" class="accordion-flat accordion-transparent accordion-centered-headers">
  <mat-expansion-panel #detailedErrorsExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        More Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list dense matExpansionPanelContent class="mc-error-code-expansion-panel-error-list">
      <mat-list-item *ngFor="let detailedErrorCode of detailedErrorCodes" class="text-light-weight">
        <mc-error-code [errorCode]="detailedErrorCode" [showErrorCode]="true"></mc-error-code>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>

<button *ngIf="showRetry" type="button" mat-button color="link" class="mc-error-code-expansion-panel-retry-button" (click)="retry.emit()">Retry</button>
