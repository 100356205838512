import { Subscription } from 'rxjs';

/**
 * @SubscriptionProperty
 * Creates a Subscription property that automatically unsubscribes from its previous value when its value changes.
 *
 * @example
 * ```
 * @SubscriptionProperty() reviewFileSubscription: Subscription;
 *
 * // Create a first subscription to obs1$
 * reviewFileSubscription = obs1$.subscribe();
 *
 * // When reviewFileSubscription is reassigned here the subscription from obs1$ is automatically unsubscribed from
 * reviewFileSubscription = obs2$.subscribe();
 * ```
 */
export function SubscriptionProperty(): PropertyDecorator {
  return (target: Object, propertyKey: string) => {
    // The property key for the new "private" property on the object instance
    const subscriptionKey = `mc${propertyKey}Subscription`;

    // Change the @SubscriptionProperty property into a getter/setter property
    delete target[propertyKey];
    Object.defineProperty(target, propertyKey, {
      get(): Subscription {
        return this[subscriptionKey];
      },
      set(newValue: Subscription) {
        // If the property already has a Subscription value then unsubscribe from it
        if (this[subscriptionKey]) {
          this[subscriptionKey].unsubscribe();
        }

        this[subscriptionKey] = newValue;
      }
    });
  };
}
