import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { SoloFileTextEditorStatusBarComponent } from './solo-file-text-editor-status-bar.component';

@NgModule({
  declarations: [
    SoloFileTextEditorStatusBarComponent
  ],
  imports: [
    CommonModule,
    FileSizeModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [
    SoloFileTextEditorStatusBarComponent
  ]
})
export class SoloFileTextEditorStatusBarModule { }
