import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLineModule } from '@angular/material/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ConditionSetsListComponent } from './condition-sets-list.component';

@NgModule({
  declarations: [
    ConditionSetsListComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatLineModule,
    MatTooltipModule
  ],
  exports: [
    ConditionSetsListComponent
  ]
})
export class ConditionSetsListModule { }
