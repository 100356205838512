<span class="mc-grid-date-range-label">Last</span>
<mat-form-field class="mc-grid-date-range-select-form-field" [color]="color">
  <mat-select [(value)]="days" (selectionChange)="onDaysChanged($event)">
    <mat-option [value]="1">1</mat-option>
    <mat-option [value]="3">3</mat-option>
    <mat-option [value]="7">7</mat-option>
    <mat-option [value]="10">10</mat-option>
    <mat-option [value]="30">30</mat-option>
    <mat-option [value]="60">60</mat-option>
    <mat-option [value]="0">All</mat-option>
  </mat-select>
</mat-form-field>
<span class="mc-grid-date-range-label">day(s)</span>