import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { CommitAction } from '@portal-core/commits/models/commit-action.model';
import { CommitResponse } from '@portal-core/commits/models/commit-response.model';
import { Commit } from '@portal-core/commits/models/commit.model';
import { Diff } from '@portal-core/commits/models/diff.model';
import { GitCommitWithCaseCorrectedPaths } from '@portal-core/project-files/models/git-commit-with-case-corrected-paths.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommitsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getCommitById$(projectId: number, commitId: string): Observable<Commit> {
    return this.http.get<CommitResponse>(`${this.apiService.centralApiBaseUri}/api/git/projects/${projectId}/commit/${commitId}`).pipe(
      map(commitData => {
        const commit = commitData.Commit;
        if (commit) {
          commit.Diffs = commitData.FileDiffs;
        }
        return commit;
      })
    );
  }

  @SharePendingRequest()
  getProjectCommits$(projectId: number, branchName: string, includeAllUsers: boolean = true, filter: PageFilter): Observable<Page<Commit>> {
    return this.http.post<Page<Commit>>(
      `${this.apiService.centralApiBaseUri}/api/git/projects/${projectId}/commitsGrid?branchName=${encodeURIComponent(branchName)}&allUsers=${includeAllUsers}`,
      filter
    );
  }

  @SharePendingRequest()
  getFileCommits$(projectId: number, branchName: string, path: string, filter: PageFilter = null): Observable<Page<Commit>> {
    return this.http.post<Page<Commit>>(
      `${this.apiService.centralApiBaseUri}/api/projects/${projectId}/File/CommitsGrid?branchName=${branchName}&path=${encodeURIComponent(path).replace(/\(/g, '%28').replace(/\)/g, '%29')}`,
      filter
    );
  }

  @SharePendingRequest()
  getFileCommitDiff$(projectId: number, commitId: string, filePath: string): Observable<Diff> {
    const fileDiffRequest = {
      Path: filePath
    };
    return this.http.post<Diff>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/File/Commits/${commitId}/Diff`, fileDiffRequest);
  }

  @SharePendingRequest()
  getDuplicateFilePaths$(projectId: number, branchName: string, filePaths: string[]): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/File/DuplicateFilePaths?branch=${branchName}`, filePaths)
  }

  postNewCommit$(projectId: number, branchName: string, commitActions: CommitAction[], commit: string, email: string, fullName: string): Observable<GitCommitWithCaseCorrectedPaths> {
    return this.http.post<GitCommitWithCaseCorrectedPaths>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/File/PostCommit`, {
      branch: decodeURIComponent(branchName),
      commit_message: commit,
      author_email: email,
      author_name: fullName,
      actions: commitActions
    })
  }
}
