import { ChangeDetectorRef, Directive, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { ProfileTabDirective } from '@portal-core/profiles/directives/profile-tab/profile-tab.directive';
import { Focusable } from '@portal-core/ui/focus/interfaces/focusable';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { filter, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';

/**
 * An abstract class that can be inherited by profile components.
 * Provides functionality for displaying a prompt to users when they try to switch tabs in the profile.
 * The current tab index can be set and accessed with the `profileTab` property.
 * The ProfileBase class automatically check to see if the tab confirm prompt dialog should be shown whenever `profileTab` is set.
 * Define `requirePromptOnClose` in your child class to define when the prompt should be shown.
 */
@Directive()
@AutoUnsubscribe()
export abstract class ProfileBase<T> implements OnInit, Focusable {
  @ViewChildren(ProfileTabDirective) private profileTabList: QueryList<ProfileTabDirective<T>>;

  private _profileTab: T;
  @Input()
  set profileTab(profileTab: T) {
    this.closeRequestSource.next(profileTab);
  }
  get profileTab(): T {
    return this._profileTab;
  }

  /** Keeps track of the close prompt `Subscription` so it can be unsubscribed from. */
  private closeTabPromptSubscription: Subscription;
  /** Used to emit requests for the switching tabs. To be used with the `closeFormPromptSubscription` `Subscription`. */
  private closeRequestSource: Subject<T> = new Subject<T>();
  /**
   * Whether or not the prompt should be displayed to the user when there is an attempt to switch tabs.
   * Optionally override this property as a getter to check if a form is dirty, etc.
   */
  public get requirePromptOnClose(): boolean {
    return false;
  }

  constructor(protected dialog: MatDialog, protected cdr: ChangeDetectorRef) { }

  /**
   * Listens for tab switching in order to display a prompt to the user asking if the tab should be closed.
   */
  ngOnInit() {
    this.closeTabPromptSubscription = this.closeRequestSource.asObservable().pipe(
      // Prompt the user for confirmation to close the form
      switchMap(profileTab => {
        // If the prompt is currently required then show the confirmation dialog
        if (this.requirePromptOnClose) {
          return this.onPromptToClose$(profileTab).pipe();
        } else { // Else just emit the tab so that the tab will switch
          return of(profileTab);
        }
      }),
      // If the user chose to close the tab
      filter(profileTab => profileTab !== null)
    ).subscribe(profileTab => {
      this._profileTab = profileTab;
      this.cdr.markForCheck();
    });
  }

  /**
   * Displays a `ConfirmDialogComponent` to the user asking them if they want to close the dialog and discard changes.
   * Override this method to use your own dialog, a different prompt, or any other form of confirming with the user.
   * Emits true if the dialog should be closed.
   */
  onPromptToClose$(profileTab: T): Observable<T> {
    return this.dialog.open(ConfirmDialogComponent, {
      ...ConfirmDialogComponent.DialogConfig,
      disableClose: true, // Only allow the user to choose one of the action buttons
      data: {
        action: 'Close',
        actionColor: 'warn',
        cancelAction: 'Keep Open',
        cancelActionColor: 'primary',
        prompt: 'Are you sure you want to close this form? All changes will be discarded.',
        showCloseButton: false // Only allow the user to choose one of the action buttons
      }
    }).afterClosed().pipe(
      map(shouldClose => shouldClose ? profileTab : null)
    );
  }

  /**
   * Called when a tab successfully changes. This method is called after the dialog prompt to close a tab is dismissed. It is also called when the dialog is not used when switching tabs.
   * Override to add custom behavior when a tab changes.
   */
  onTabChanged(profileTab: T) { }

  focus() {
    this.profileTabList.find(profileTabDirective => profileTabDirective.tab === this.profileTab)?.focus();
  }
}
