<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    {{ title }}
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-body mc-log-dialog-body">
    <div class="mc-log-dialog-message">
      {{ data?.message }}
    </div>
    <div class="mc-log-dialog-copy-message">
      <button mat-icon-button (click)="copyMessage()" matTooltip="Copy">
        <mat-icon fontSet="project-titan" fontIcon="icon-copy"></mat-icon>
      </button>
    </div>
  </div>
</div>
