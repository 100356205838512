export class CustomError extends Error {
  /**
   * Returns an Error object from JSON, a string, an Error instance, or a CustomError instance.
   * The same value is returned if it is an Error or CustomError instance.
   * This is done to maintain the stack trace from when the error was created.
   * @param value The value to convert to an Error object.
   * @returns Returns the same value if it is an Error or CustomError instance. Otherwise, returns a new CustomError instance.
   */
  static from(value: JSONValue | string | Error | CustomError): Error {
    if (value instanceof Error || value instanceof CustomError) {
      return value;
    } else if (typeof value === 'string') {
      return new CustomError('CustomError', value);
    } else {
      return new CustomError(value.name ?? 'CustomError', value.message ?? 'Error message not set.');
    }
  }

  constructor(public name: string, public message: string) {
    // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }

  /**
   * Returns a JSON representation of the error.
   * This is defined so that JSON.stringify properly serializes the error.
   * @returns A JSON representation of the error.
   */
  toJSON(): { name: string, message: string } {
    return {
      name: this.name,
      message: this.message
    };
  }
}
