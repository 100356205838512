import { Injectable } from '@angular/core';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';

@CollectionStateSelectors({
  stateType: ReviewFilesState
})
@State<ReviewFilesState>({
  name: ReviewFilesState.source
})
@Injectable()
export class ReviewFilesState extends CollectionStateBase {
  static source = 'ReviewFiles';
  getSource(): string {
    return ReviewFilesState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewFilesDataService extends CollectionDataServiceBase<ReviewFile> {
  constructor(protected store: Store) {
    super(store, ReviewFilesState);
  }
}
