import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns true if the item exists in the collection.
 * ```html
 * color | mcIncludedIn : colorsArray
 * ```
*/
@Pipe({
  name: 'mcIncludedIn'
})
export class IncludedInPipe implements PipeTransform {
  transform(item: any, collection: any[]): boolean {
    return !!collection?.includes(item);
  }
}
