import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { cache } from '@common/util/cache.operator';
import { BuildProfileTab } from '@portal-core/builds/enums/build-profile-tab.enum';
import { ProcessBuildType } from '@portal-core/builds/enums/process-build-type.enum';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsUIService } from '@portal-core/builds/services/builds-ui.service';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProcessLogMessageType } from '@portal-core/processes/enums/process-log-message-type.enum';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { TargetsService } from '@portal-core/targets/services/targets.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, first, map, noop, switchMap } from 'rxjs';

export interface BuildProfileDialogData {
  buildId: number;
  buildProfileTab?: BuildProfileTab;
}

enum BuildProfileForm {
  Delete,
  Settings
}

@Component({
  selector: 'mc-build-profile-dialog',
  templateUrl: './build-profile-dialog.component.html',
  styleUrls: ['./build-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildProfileDialogComponent extends DialogBase implements OnInit {
  /** Defines the default config for the dialog. */
  static DialogConfig: MatDialogConfig = {
    width: '104rem',
    height: '68rem',
  };

  BuildProfileForm: typeof BuildProfileForm = BuildProfileForm;
  BuildProfileTab: typeof BuildProfileTab = BuildProfileTab;
  ProcessLogMessageType: typeof ProcessLogMessageType = ProcessLogMessageType;
  ProcessState: typeof ProcessState = ProcessState;

  build$: Observable<Build>;
  buildId: number;
  buildProfileTab: BuildProfileTab;
  licenseId$: Observable<number>;
  loadingState: LoadingState<string> = new LoadingState<string>();
  project$: Observable<Project>;
  projectIsLocked$: Observable<boolean>;
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);
  routerEventSubscription: Subscription;
  showCancelBuildTab$: Observable<boolean>;
  showDeleteTab$: Observable<boolean>;
  showDownloadBuildTab$: Observable<boolean>;
  showDownloadLogTab$: Observable<boolean>;
  showLogTab$: Observable<boolean>;
  showSettingsTab$: Observable<boolean>;
  showViewOutputTab$: Observable<boolean>;
  userCanDeleteBuilds$: Observable<boolean>;
  userCanDownloadBuilds$: Observable<boolean>;
  userCanManageBuilds$: Observable<boolean>;
  userCanRunScheduleBuilds$: Observable<boolean>;

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<BuildProfileDialogComponent>,
    private router: Router,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private buildsService: BuildsService,
    private targetsService: TargetsService,
    private buildsUIService: BuildsUIService,
    private projectsService: ProjectsService,
    private permissionsService: PermissionsService,
    private profilesService: ProfilesService,
    @Inject(MAT_DIALOG_DATA) private data: BuildProfileDialogData
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.buildId = this.data.buildId;

    // Create an observable of the build. Start with reload$ so that the data can be reloaded as needed
    this.build$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getBuildProfile$(this.buildId)),
      // Switch to the build in the data store. The build is returned in the profile data so there is no need to make a request to the API
      switchMap(build => this.buildsService.getItemById$(build.Id, { allowApiRequest: false })),
      cache()
    );

    // Create an observable for the build's project id
    const projectId$ = this.build$.pipe(
      map(build => build.ProjectId)
    );

    this.project$ = projectId$.pipe(
      // The project is returned in the profile data so there is no need to make a request to the API
      switchMap(projectId => this.projectsService.getItemById$(projectId, { allowApiRequest: false })),
      cache()
    );

    // Create an observable for the license id. The build doesn't have a license id so grab it from the project.
    this.licenseId$ = this.project$.pipe(
      map(project => project?.LicenseId)
    );

    // Create an observable for locked status of the build's project
    this.projectIsLocked$ = this.project$.pipe(
      map(project => project?.Status === ProjectStatus.Locked)
    );

    // Create an observable for the user's access to the build's project
    const userHasProjectAccess$ = this.project$.pipe(
      map(project => project?.UserHasAccess)
    );

    // Create observables for the user's permissions
    this.userCanManageBuilds$ = projectId$.pipe(
      switchMap(projectId => this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageBuilds, projectId))
    );
    this.userCanDeleteBuilds$ = projectId$.pipe(
      switchMap(projectId => this.permissionsService.currentUserHasPermission$(CentralPermissions.DeleteBuilds, projectId))
    );
    this.userCanDownloadBuilds$ = projectId$.pipe(
      switchMap(projectId => this.permissionsService.currentUserHasPermission$(CentralPermissions.DownloadBuilds, projectId))
    );
    this.userCanRunScheduleBuilds$ = projectId$.pipe(
      switchMap(projectId => this.permissionsService.currentUserHasPermission$(CentralPermissions.RunOrScheduleBuilds, projectId))
    );

    // Create observables for which tabs to show in the sidebar
    this.showCancelBuildTab$ = combineLatest([this.userCanRunScheduleBuilds$, userHasProjectAccess$, this.build$, this.projectIsLocked$]).pipe(
      map(([userCanRunScheduleBuilds, userHasProjectAccess, build, projectIsLocked]) => userCanRunScheduleBuilds && userHasProjectAccess && build.CanBeCancelled && !projectIsLocked)
    );
    this.showDeleteTab$ = combineLatest([this.userCanDeleteBuilds$, userHasProjectAccess$, this.build$, this.projectIsLocked$]).pipe(
      map(([userCanDeleteBuilds, userHasProjectAccess, build, projectIsLocked]) => userCanDeleteBuilds && userHasProjectAccess && build.IsFinished && !projectIsLocked)
    );
    this.showDownloadBuildTab$ = combineLatest([this.userCanDownloadBuilds$, userHasProjectAccess$, this.build$]).pipe(
      map(([userCanDownloadBuilds, userHasProjectAccess, build]) => userCanDownloadBuilds && userHasProjectAccess && build.CanDownload)
    );
    this.showDownloadLogTab$ = combineLatest([this.userCanDownloadBuilds$, userHasProjectAccess$, this.build$]).pipe(
      map(([userCanDownloadBuilds, userHasProjectAccess, build]) => userCanDownloadBuilds && userHasProjectAccess && build.CanViewLog)
    );
    this.showLogTab$ = combineLatest([this.userCanDownloadBuilds$, userHasProjectAccess$, this.build$]).pipe(
      map(([userCanDownloadBuilds, userHasProjectAccess, build]) => userCanDownloadBuilds && userHasProjectAccess && build.CanViewLog)
    );
    this.showSettingsTab$ = combineLatest([this.userCanManageBuilds$, userHasProjectAccess$, this.projectIsLocked$]).pipe(
      map(([userCanManageBuilds, userHasProjectAccess, projectIsLocked]) => userCanManageBuilds && userHasProjectAccess && !projectIsLocked)
    );
    this.showViewOutputTab$ = combineLatest([this.userCanDownloadBuilds$, userHasProjectAccess$, this.build$]).pipe(
      map(([userCanDownloadBuilds, userHasProjectAccess, build]) => userCanDownloadBuilds && userHasProjectAccess && build.CanViewOutput)
    );

    this.loadingState.update(true);

    // Start the initial load
    combineLatest([
      this.build$,
      this.projectIsLocked$,
      userHasProjectAccess$,
      this.userCanManageBuilds$,
      this.userCanDeleteBuilds$,
      this.userCanDownloadBuilds$,
      this.userCanRunScheduleBuilds$
    ]).pipe(
      first()
    ).subscribe(() => {
      // Set the initial tab
      if (typeof this.data.buildProfileTab === 'number') {
        this.buildProfileTab = this.data.buildProfileTab;
      } else {
        this.buildProfileTab = BuildProfileTab.Overview;
      }

      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the build.', this.errorService.getErrorMessages(error));
    });

    // Listen for successful navigations and close the dialog (for closing the dialog when the user clicks a link in the dialog)
    this.routerEventSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.dialogRef.close());
  }

  onCancelBuildClicked(build: Build) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        prompt: 'Are you sure you want to cancel this build?',
        action: 'OK'
      }
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      switchMap(() => this.buildsService.processBuild$(build.Id, ProcessBuildType.Cancel))
    ).subscribe(noop, () => {
      this.snackBar.open('Unable to cancel the build.', 'OK', { duration: 2500 });
    });
  }

  onDownloadLogClicked(build: Build) {
    const logUrl = build.PrivateOutputUrl.substring(0, build.PrivateOutputUrl.lastIndexOf('/') + 1) + this.getTargetFileName(build.TargetPath) + '.mclog';
    window.open(logUrl, '_blank');
  }

  getTargetFileName(targetPath: string): string {
    return this.targetsService.getTargetFileNameFromPath(targetPath);
  }

  onDownloadBuildClicked(build: Build) {
    this.buildsUIService.downloadBuild(build);
  }

  onViewOutputClicked(build: Build) {
    this.buildsUIService.viewOutput(build);
  }

  onCancel() {
    this.closeDialog();
  }

  onSaved(buildProfileForm: BuildProfileForm) {
    switch (buildProfileForm) {
      case BuildProfileForm.Delete:
        this.closeDialog();
        break;
      // If the settings were updated then refresh the build data to get the latest data
      case BuildProfileForm.Settings:
        this.reload();
        break;
    }
  }

  getColorForBuildState(build: Build): string {
    return this.buildsService.getColorForState(build && build.LastNotification ? build.LastNotification.ProcessState : ProcessState.Unknown);
  }

  getTextColorClassForBuildState(build: Build): string {
    return `mc-text-${this.getColorForBuildState(build)}`;
  }

  reload() {
    this.reload$.next();
  }
}
