import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { Observable, map } from 'rxjs';

/**
 * Redirects the user to the home page if they are already logged in. Otherwise, redirects to the login page.
 */
export const pageNotFoundGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthService);
  const authRouteService = inject(AuthRouteService);

  if (authService.isAuthenticated()) {
    return authRouteService.navigateToAuthRoute$(AuthRoute.Home, { replaceUrl: true }).pipe(
      map(() => false)
    );
  } else {
    return authRouteService.navigateToAuthRoute$(AuthRoute.Login, { replaceUrl: true }).pipe(
      map(() => false)
    );
  }
}
