import { Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mcProfileTab]'
})
export class ProfileTabDirective<T=number> implements OnInit, OnDestroy {
  @Input('mcProfileTab') tab: T;

  @HostBinding('class.mc-list-item-selected')
  private get _selected(): boolean {
    return this.mcProfileTabSelected === this.tab;
  }

  @Input() mcProfileTabSelected: T;
  @Output() mcProfileTabSelectedChange: EventEmitter<T> = new EventEmitter<T>();

  private unlistener: Function;

  constructor(private element: ElementRef<HTMLElement>, private renderer: Renderer2) { }

  ngOnInit() {
    this.unlistener = this.renderer.listen(this.element.nativeElement, 'click', (event: MouseEvent) => {
      event.preventDefault();
      this.mcProfileTabSelectedChange.emit(this.tab);
    });
  }

  ngOnDestroy() {
    this.unlistener?.();
    this.unlistener = null;
  }

  focus() {
    this.element.nativeElement.focus();
  }
}
