import { EditorState, NodeSelection, Plugin, Transaction } from 'prosemirror-state';

export function removeEmptyNodesInTransaction(tr: Transaction): Transaction {
  const $anchor = tr.selection.$anchor;
  let depth: number;

  // Find depth of shallowest parent with all content selected
  for (depth = $anchor.depth; depth > 0; depth--) {
    const node = $anchor.node(depth);

    // Don't go past block nodes or any un-selectable node
    if (!node.isInline || !NodeSelection.isSelectable(node)) {
      break;
      // Empty nodes size are only the opening and closing tags of all children
    } else if (node.content.size === ($anchor.depth - depth) * 2 && !node.type.spec.defining) {
      continue;
    } else {
      break;
    }
  }

  if (depth < $anchor.depth) {
    // Remove the topmost empty parent node
    const selection = NodeSelection.create(tr.doc, $anchor.before(depth + 1));
    tr.deleteRange(selection.from, selection.to);
    return tr;
  }
}

/*
 * Creates a plugin that removes empty nodes during delete transactions.
 * If all content in node is removed, delete node and all parent nodes with no content.
 */
export function emptyNodeRemovalPlugin(): Plugin {
  return new Plugin({
    filterTransaction(transaction: Transaction, editorState: EditorState): boolean {
      // Do not handle tracked change, collab, history, and anno transactions
      // Do not handle if selection unset since it can occur when snippet content is loaded
      if (transaction.steps.length !== 0 && transaction.docChanged && transaction.selectionSet && !transaction.getMeta('trackedChange') && !transaction.getMeta('trackedChangesCleanUp') && !transaction.getMeta('collab$') && !transaction.getMeta('history$') && !transaction.getMeta('anno')) {
        removeEmptyNodesInTransaction(transaction);
      }

      return true;
    }
  });
}
