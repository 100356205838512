<mc-grid-header-filter [column]="column" [hasFilter]="hasFilter" (menuClosed)="onMenuClosed()" (menuClosing)="onMenuClosing()" (menuOpened)="onMenuOpened()">
  <ng-template mcGridHeaderFilterForm>
    <form #filterFormDirective="ngForm" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <mat-form-field color="primary-vivid">
        <mat-select placeholder="Condition" formControlName="filterType">
          <mat-option [value]="PageFilterType.Equals">On</mat-option>
          <mat-option [value]="PageFilterType.GreaterThan">After</mat-option>
          <mat-option [value]="PageFilterType.LessThan">Before</mat-option>
          <mat-option [value]="PageFilterType.Today">Today</mat-option>
          <mat-option [value]="PageFilterType.Between">Between</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="primary-vivid" [ngClass]="{ 'mc-hidden': selectedFilterType === PageFilterType.Today }">
        <input #fromInput matInput formControlName="from" placeholder="Choose a date" [matDatepicker]="fromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker color="primary-vivid" #fromDate></mat-datepicker>
        <mat-error>
          <mc-form-control-error-code [form]="filterForm" controlName="from" type="mc_date"></mc-form-control-error-code>
        </mat-error>
      </mat-form-field>

      <div [ngClass]="{ 'mc-hidden': selectedFilterType !== PageFilterType.Between }">
        <p class="mc-form-title">And</p>
        <mat-form-field color="primary-vivid">
          <input matInput formControlName="to" placeholder="Choose a date" [matDatepicker]="toDate">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker color="primary-vivid" #toDate></mat-datepicker>
          <mat-error>
            <mc-form-control-error-code [form]="filterForm" controlName="to" type="mc_date"></mc-form-control-error-code>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mc-menu-button-group">
        <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
        <button type="submit" mat-flat-button color="primary">Filter</button>
      </div>
    </form>
  </ng-template>

  <ng-template *ngIf="menuTemplate" mcGridHeaderFilterMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-header-filter>
