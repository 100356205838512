import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { AlertDialogComponent } from '@portal-core/general/components/alert-dialog/alert-dialog.component';
import { ProjectProfileDialogComponent } from '@portal-core/projects/components/project-profile-dialog/project-profile-dialog.component';
import { ProjectStatus } from '@portal-core/projects/enums/project-status.enum';
import { Project } from '@portal-core/projects/models/project.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, Subscription, map } from 'rxjs';

/**
 * mc-project-avatar
 * A component for displaying a project's icon initials and name.
 */
@Component({
  selector: 'mc-project-avatar',
  templateUrl: './project-avatar.component.html',
  styleUrls: ['./project-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ProjectAvatarComponent extends AvatarBase implements OnInit {
  @Input() project: Project;
  @Input() nameLinksToPage?: boolean = false;
  @Input() showId?: boolean = false;

  @InputObservable('project') project$: Observable<Project>;

  ProjectStatus: typeof ProjectStatus = ProjectStatus;

  private currentUserHasPermission: boolean = false;
  tooltip$: Observable<string>;
  private isAuthorSubscription: Subscription;

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private currentService: CurrentService) {
    super();
  }

  ngOnInit() {
    this.isAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      this.currentUserHasPermission = isAuthor || this.currentService.isSysAdmin();
      this.cdr.markForCheck();
    });

    this.tooltip$ = this.project$.pipe(
      map(project => {
        let tooltip: string;

        if (project) {
          tooltip = project.Name;

          if (project.Status === ProjectStatus.Archived) {
            tooltip += ' (Archived)';
          } else if (project.Status === ProjectStatus.Locked) {
            tooltip += ' (Locked)';
          }
        }

        return tooltip;
      })
    );
  }

  onAvatarClicked() {
    if (this.project.Status === this.ProjectStatus.Archived) {
      this.openProjectArchivedAlertDialog();
    } else {
      this.openProjectProfileDialogCard();
    }
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasAvatarSVG(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return this.currentUserHasPermission;
  }

  isDeleted(): boolean {
    return this.project && this.project.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.project;
  }

  isLink(): boolean {
    // Only authors can view projects
    return super.isLink() && this.currentUserHasPermission;
  }

  private openProjectArchivedAlertDialog() {
    this.dialog.open(AlertDialogComponent, {
      width: '36rem',
      data: {
        title: 'Project Archived',
        message: 'This project is archived and cannot be opened. To open the project, you must change its status to Active.'
      }
    });
  }

  private openProjectProfileDialogCard() {
    this.dialog.open(ProjectProfileDialogComponent, {
      ...ProjectProfileDialogComponent.DialogConfig,
      data: { projectId: this.project.Id }
    });
  }
}
