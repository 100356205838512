import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { basename, dirname, extname } from '@common/util/path';
import { StringService } from '@portal-core/general/services/string.service';
import { TocFileExtension } from '@portal-core/project-files/constants/file-extensions.constant';
import { Project } from '@portal-core/projects/models/project.model';
import { RoutingService } from '@portal-core/routing/services/routing.service';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
@Component({
  selector: 'mc-toc-avatar',
  templateUrl: './toc-avatar.component.html',
  styleUrls: ['./toc-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TocAvatarComponent extends AvatarBase {
  @Input() branchName?: string;
  @HostBinding('class.mc-toc-avatar-emphasize-base-name')
  @Input() emphasizeBaseName?: boolean = true;
  @Input() project?: Project;
  @HostBinding('class.mc-toc-avatar-show-path')
  @Input() showPath?: boolean = false;
  @Input() tocPath: string;

  get tocBasename(): string {
    return basename(this.tocPath, `.${TocFileExtension}`);
  }

  get tocDirname(): string {
    if (typeof this.tocPath === 'string') {
      // Trim leading 'project/tocs/' from the path since all TOCs are in that folder
      return dirname(this.tocPath.replace(/^project\/tocs\//i, ''), true);
    }
  }

  get tocExtname(): string {
    return extname(this.tocPath);
  }

  get routeCommands(): any[] {
    if (this.project && this.branchName && this.tocPath) {
      const path = this.routingService.getTocPath(this.project.LicenseId, this.project.Id, this.branchName, this.tocPath);
      return typeof path === 'undefined' ? ['/', this.project.LicenseId, 'projects', this.project.Id, 'files', 'blob', encodeURIComponent(this.branchName), encodeURIComponent(this.tocPath)] : path;
    }
  }

  constructor(private stringService: StringService, private routingService: RoutingService) {
    super();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.tocPath;
  }

  isLink(): boolean {
    return super.isLink() && !!this.routeCommands
  }

  encodeURIComponent(component: string): string {
    return this.stringService.encodeURIComponent(component);
  }
}
