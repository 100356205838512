import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { SelectionListHeaderComponent } from './selection-list-header.component';

@NgModule({
  declarations: [SelectionListHeaderComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatCheckboxModule
  ],
  exports: [
    SelectionListHeaderComponent
  ]
})
export class SelectionListHeaderModule { }
