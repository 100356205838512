import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusColorClassPipe } from './review-file-user-status-color-class.pipe';

@NgModule({
  declarations: [ReviewFileUserStatusColorClassPipe],
  imports: [CommonModule],
  exports: [ReviewFileUserStatusColorClassPipe]
})
export class ReviewFileUserStatusColorClassModule { }
