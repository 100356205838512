<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <div class="mc-spring-board-form-body">
    <mat-tree [dataSource]="dataSource" [treeControl]="nestedTreeControl" class="mc-tree mc-tree-avatars mc-project-access-form-tree">
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <!-- Teams Root Node Item -->
        <div class="mat-tree-node" *ngIf="node.type === ProjectAccessTreeNodeType.TeamsNode">
          <button type="button" matTreeNodeToggle matRipple class="mc-tree-node-item">
            <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="nestedTreeControl.isExpanded(node) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
            <mat-checkbox *ngIf="editing" color="primary-vivid" class="mc-tree-text-icon" mcStopPropagation="click" [(ngModel)]="node.selected" [ngModelOptions]="{standalone: true}" [indeterminate]="node.indeterminate" (change)="selectAllChanged(node)">{{node.name}}</mat-checkbox>
            <ng-container *ngIf="!editing">{{node.name}} ({{project?.ProjectTeams?.length}})</ng-container>
          </button>
        </div>

        <!-- Users Root Node Item -->
        <div class="mat-tree-node" *ngIf="node.type === ProjectAccessTreeNodeType.UsersNode">
          <button type="button" matTreeNodeToggle matRipple class="mc-tree-node-item">
            <mat-icon class="mc-tree-text-icon mc-tree-expander-icon" color="primary-vivid" fontSet="project-titan" [fontIcon]="nestedTreeControl.isExpanded(node) ? 'icon-down-arrow-90' : 'icon-right-arrow-90'"></mat-icon>
            <mat-checkbox *ngIf="editing" color="primary-vivid" class="mc-tree-text-icon" mcStopPropagation="click" [(ngModel)]="node.selected" [ngModelOptions]="{standalone: true}" [indeterminate]="node.indeterminate" (change)="selectAllChanged(node)">{{node.name}}</mat-checkbox>
            <ng-container *ngIf="!editing">{{node.name}} ({{project?.ProjectUsers?.length}})</ng-container>
          </button>
        </div>

        <!-- Team Node Item -->
        <div class="mat-tree-node" *ngIf="node.type === ProjectAccessTreeNodeType.TeamNode">
          <button *ngIf="editing" type="button" class="mc-tree-node-item" [disabled]="node.disabled" matRipple (click)="onLeafNodeClicked(node)">
            <mat-pseudo-checkbox class="mc-tree-text-icon mat-primary-vivid" [disabled]="node.disabled" [state]="node.selected ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
            <mc-team-avatar [disabled]="true" [team]="node.value.teamId | mcTeamAsync | async" size="small" [truncate]="true"></mc-team-avatar>
          </button>

          <div *ngIf="!editing" class="mc-tree-node-item">
            <mc-team-avatar [team]="node.value.teamId | mcTeamAsync | async" size="small" [truncate]="true" mcStopPropagation="click"></mc-team-avatar>
          </div>
        </div>

        <!-- User Node Item -->
        <div class="mat-tree-node" *ngIf="node.type === ProjectAccessTreeNodeType.UserNode">
          <button *ngIf="editing" type="button" class="mc-tree-node-item" [disabled]="node.disabled" matRipple (click)="onLeafNodeClicked(node)">
            <mat-pseudo-checkbox class="mc-tree-text-icon mat-primary-vivid" [disabled]="node.disabled" [state]="node.selected ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
            <mc-license-user-avatar [disabled]="true" [licenseUser]="node.value.licenseUserId | mcLicenseUserAsync | async" size="small" [truncate]="true"></mc-license-user-avatar>
          </button>

          <div *ngIf="!editing" class="mc-tree-node-item">
            <mc-license-user-avatar [licenseUser]="node.value.licenseUserId | mcLicenseUserAsync | async" size="small" [truncate]="true" mcStopPropagation="click"></mc-license-user-avatar>
          </div>
        </div>

        <div class="mc-nested-tree-node-items" [class.invisible]="!nestedTreeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div class="mc-spring-board-form-footer">
    <button *ngIf="!editing && (canEdit$ | async)" type="button" mat-raised-button color="primary" (click)="onEditClicked()">Edit</button>

    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div *ngIf="editing" class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>

  <!--Loading State-->
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

  <!--Saving State-->
  <div *ngIf="savingState.loading$ | async" class="mc-loader mc-project-access-form-saving-pane">
    <p *ngIf="savingState.loading$ | async" class="mc-form-text">
      Updating teams and users access.
    </p>

    <mat-progress-bar class="mc-progress-bar-large" color="notice" mode="indeterminate"></mat-progress-bar>
  </div>
</form>

<!-- loading State Error -->
<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>