import { isEqual } from 'lodash';

/**
Normalizes items in the given conditions array by trimming them and filtering empty items.
* @param values Conditions array to normalize.
* @returns Normalized version of the given conditions array or null if no items after normalize.
*/
export function normalizeConditions(values: string[]): string[] | null {
  const res = values?.map(x => x?.trim()).filter(x => !!x);
  return !!res?.length ? res : null;
}

/**
Gets conditions string from the conditions array.
* @param value The conditions array.
* @returns The conditions string or null.
*/
export function joinConditions(values: string[]): string | null {
  const res = normalizeConditions(values)?.join(',');
  return !!res ? res : null;
}

/**
Gets array of conditions from a string.
* @param value The conditions string.
* @returns An array of conditions or null.
*/
export function splitConditions(value: string): string[] | null {
  return normalizeConditions(value?.split(','));
}

/**
Gets whether conditions strings are equal.
* @param x First string to check.
* @param y Second string to check.
* @returns true if the strings are equal; otherwise false.
*/
export function equalConditionStrings(x: string, y: string): boolean {
  const xs = splitConditions(x)?.sort();
  const ys = splitConditions(y)?.sort();
  return isEqual(xs, ys);
}

/**
Gets whether arrays of conditions are equal.
* @param x First array to check.
* @param y Second array to check.
* @returns true if the arrays are equal; otherwise false.
*/
export function equalConditions(x: string[], y: string[]): boolean {
  const xs = normalizeConditions(x)?.sort();
  const ys = normalizeConditions(y)?.sort();
  return isEqual(xs, ys);
}

/**
Gets conditions if all specified items have one and the same set of conditions ignoring items without conditions.
* @param items Items to check for having the same condition set. Items with empty conditions are ignored..
* @param get A function that gets conditions from the item.
* @returns Sorted conditions if all specified items have one and the same set of conditions, otherwise null.
*/
export function getSameConditionSet<T>(items: T[], get: (item: T) => string[]): string[] | null {
  let conditions: string[] = null;
  items?.some(item => {
    // get conditions to compare
    let c = get(item);
    // normalize and sort them
    c = normalizeConditions(c)?.sort();
    // consider only items with defined conditions
    if (c?.length) {
      if (conditions === null) {
        // first conditions to compare with others
        conditions = c;
      } else if (!isEqual(conditions, c)) {
        // conditions are not equal so nothing in common
        conditions = null;
        return true;
      }
    }
  });
  return conditions;
}
