import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY } from '@angular/material/tooltip';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppGettingStartedDialogModule } from '@portal-client/app/components/app-getting-started-dialog/app-getting-started-dialog.module';
import { AuthEventHandlerService } from '@portal-client/core/auth/auth-event-handler.service';
import { CurrentConfiguratorService } from '@portal-client/core/current/current-configurator.service';
import { LayoutEventHandlerService } from '@portal-client/core/layout/layout-event-handler.service';
import { LoginEventHandlerService } from '@portal-client/core/login/login-event-handler.service';
import { UserSettingsService } from '@portal-client/users/services/user-settings.service';
import { AuthCookieEffect } from '@portal-core/auth/effects/auth-cookie.effect';
import { AuthDialogsEffect } from '@portal-core/auth/effects/auth-dialogs.effect';
import { AuthLogoutRedirectEffect } from '@portal-core/auth/effects/auth-logout-redirect.effect';
import { AuthResettablesEffect } from '@portal-core/auth/effects/auth-resettables.effect';
import { AuthInterceptorService } from '@portal-core/auth/interceptors/auth-interceptor.service';
import { AuthServicesModule } from '@portal-core/auth/services/auth-services.module';
import { BranchesServiceModule } from '@portal-core/branches/services/branches.service.module';
import { BuildLogsServicesModule } from '@portal-core/build-logs/services/build-logs-services.module';
import { BuildsScheduleServicesModule } from '@portal-core/builds/services/builds-schedule-services.module';
import { BuildsServicesModule } from '@portal-core/builds/services/builds-services.module';
import { ChecklistTemplatesServicesModule } from '@portal-core/checklist-templates/services/checklist-templates-services.module';
import { CommitsServicesModule } from '@portal-core/commits/services/commits-services.module';
import { CurrentServicesModule } from '@portal-core/current/services/current-services.module';
import { DashboardServicesModule } from '@portal-core/dashboard/services/dashboard-services.module';
import { DataStreamServicesModule } from '@portal-core/data-stream/services/data-stream-services.module';
import { DataServicesModule } from '@portal-core/data/common/services/data-services.module';
import { FormsServicesModule } from '@portal-core/forms/services/forms-services.module';
import { GeneralServicesModule } from '@portal-core/general/services/general-services.module';
import { I18nServicesModule } from '@portal-core/i18n/services/i18n-services.module';
import { IdleLogoutServicesModule } from '@portal-core/idle-logout/services/idle-logout-services.module';
import { LanguagesServiceModule } from '@portal-core/languages/services/languages.service.module';
import { LayoutServicesModule } from '@portal-core/layout/services/layout-services.module';
import { LicenseHostMapsServicesModule } from '@portal-core/license-host-maps/services/license-host-maps-services.module';
import { LicenseStorageServicesModule } from '@portal-core/license-storage/services/license-storage-services.module';
import { LicenseUsersServicesModule } from '@portal-core/license-users/services/license-users-services.module';
import { LicensesServicesModule } from '@portal-core/licenses/services/licenses-services.module';
import { ConversationsServicesModule } from '@portal-core/messages/services/conversations-services.module';
import { MessagesServicesModule } from '@portal-core/messages/services/messages-services.module';
import { NotificationsServicesModule } from '@portal-core/notifications/services/notifications-services.module';
import { OAuthServicesModule } from '@portal-core/oauth/services/oauth-services.module';
import { OutputAnalyticsKeyServicesModule } from '@portal-core/output-analytics/services/output-analytics-key/output-analytics-key-services.module';
import { OutputAnalyticsReportServicesModule } from '@portal-core/output-analytics/services/output-analytics-report-services.module';
import { PermissionsServicesModule } from '@portal-core/permissions/services/permissions-services.module';
import { ProcessNotificationServicesModule } from '@portal-core/processes/services/process-notification-services.module';
import { ProfilesServicesModule } from '@portal-core/profiles/services/profiles-services.module';
import { ProjectChecklistsServicesModule } from '@portal-core/project-checklists/services/project-checklists-services.module';
import { ProjectFilesServicesModule } from '@portal-core/project-files/services/project-files-services.module';
import { ProjectInfoServicesModule } from '@portal-core/project-info/services/project-info-services.module';
import { ProjectTargetsServicesModule } from '@portal-core/project-targets/services/project-targets-services.module';
import { ProjectDeleterEffect } from '@portal-core/projects/effects/project-deleter.effect';
import { ProjectHydratorEffect } from '@portal-core/projects/effects/project-hydrator.effect';
import { ProjectsServicesModule } from '@portal-core/projects/services/projects-services.module';
import { ReportsServicesModule } from '@portal-core/reports/services/reports-services.module';
import { ReviewsServicesModule } from '@portal-core/reviews/services/reviews-services.module';
import { SiteListByBuildEffect } from '@portal-core/sites/effects/site-list-by-build.effect';
import { SiteUrlsServicesModule } from '@portal-core/sites/services/site-urls/site-urls-services.module';
import { SitesServicesModule } from '@portal-core/sites/services/sites-services.module';
import { SlackWebhooksServicesModule } from '@portal-core/slack/services/slack-webhooks-services.module';
import { SystemMessagesServicesModule } from '@portal-core/system-messages/services/system-messages-services.module';
import { TargetsServiceModule } from '@portal-core/targets/services/targets-service.module';
import { TaskAnalyticsServicesModule } from '@portal-core/task-analytics/services/task-analytics-services.module';
import { TaskBoardsServicesModule } from '@portal-core/task-boards/services/task-boards-services.module';
import { TasksServicesModule } from '@portal-core/tasks/services/tasks-services.module';
import { TeamsServicesModule } from '@portal-core/teams/services/teams-services.module';
import { TranslationPackageLanguageBranchesServicesModule } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches-services.module';
import { TranslationPackagesServicesModule } from '@portal-core/translation-packages/services/translation-packages-services.module';
import { TranslationQuotesServicesModule } from '@portal-core/translation-quotes/services/translation-quotes-services.module';
import { addIdAddTypeToPageFilterGroup } from '@portal-core/ui/page-filters/migrations/add.id.add.type.to.page-filter-group.migration';
import { UsersServicesModule } from '@portal-core/users/services/users-services.module';
import { USER_SETTINGS_SERVICE } from '@portal-core/users/tokens/user-settings-service.token';
import { appInitializerNoop } from '@portal-core/util/app-initializer-noop';
import { throwIfAlreadyLoaded } from '@portal-core/util/module-import-guard';
import { NAVIGATOR_PROVIDERS } from '@portal-core/util/navigator.provider';
import { WINDOW_PROVIDERS } from '@portal-core/util/window.provider';
import { CookieService } from 'ngx-cookie-service';
import { CoreState } from './core-data.service';

@NgModule({
  imports: [
    // The NGXS root module must come before the NGXS feature modules (which are defined in our feature modules below)
    NgxsModule.forRoot([
      CoreState
    ]),
    AppGettingStartedDialogModule,
    AuthServicesModule.forRoot({
      onAuthError: [AuthEventHandlerService],
      onAuthRoute: AuthEventHandlerService,
      onLoginComplete: LoginEventHandlerService,
      effects: [AuthCookieEffect, AuthResettablesEffect, AuthDialogsEffect, AuthLogoutRedirectEffect]
    }),
    BuildLogsServicesModule,
    BranchesServiceModule,
    LanguagesServiceModule,
    BuildsServicesModule,
    BuildsScheduleServicesModule,
    ChecklistTemplatesServicesModule,
    CommonModule,
    CommitsServicesModule,
    ConversationsServicesModule,
    CurrentServicesModule.forRoot({
      getLicenseUser: CurrentConfiguratorService,
      getLicenseUser$: CurrentConfiguratorService,
      getLicense: CurrentConfiguratorService,
      getLicense$: CurrentConfiguratorService,
      getActiveLicense: CurrentConfiguratorService,
      getActiveLicense$: CurrentConfiguratorService,
      isSysAdmin: CurrentConfiguratorService
    }),
    DashboardServicesModule,
    DataServicesModule,
    DataStreamServicesModule,
    FormsServicesModule,
    HttpClientModule,
    I18nServicesModule,
    IdleLogoutServicesModule,
    GeneralServicesModule,
    LayoutServicesModule.forRoot({
      onOpenAboutDialog: [LayoutEventHandlerService]
    }),
    LicenseHostMapsServicesModule,
    LicenseStorageServicesModule,
    LicenseUsersServicesModule,
    LicensesServicesModule,
    MatDialogModule,
    MessagesServicesModule,
    // NgxsStoragePluginModule should be the first NGXS plugin in the imports list
    NgxsStoragePluginModule.forRoot({
      key: [
        'coreState.settings',
        'oAuthState',
        'projectReportsPageState.filters.chartSize',
        'Builds.grids.project-builds-page.config',
        'BuildsSchedule.grids.project-schedule-builds-page.config',
        'LicenseUsers.grids.users-page.config',
        'Projects.grids.projects-page.config',
        'ReviewFiles.grids.reviews-page.config',
        'ReviewFiles.pagedLists.reviews-page.config',
        'ReviewPackages.grids.reviews-page.config',
        'ReviewPackages.pagedLists.reviews-page.config',
        'ReviewsPageState.filters.userReviewState',
        'Teams.grids.teams-page.config',
        'Sites.grids.sites-page.config',
        'SiteUrls.grids.site-urls-page.config',
        'OutputAnalyticsKeys.grids.output-analytics-keys-page.config',
        'CshIdCallsReports.grids.csh-id-call-reports.config',
        'SearchQueryReports.grids.search-phrase-reports.config',
        'SearchQueryReports.grids.search-phrases-no-result-reports.config',
        'TopicViewsReports.grids.topic-view-reports.config',
        'OutputAnalyticsReportItems.grids.browser-stats-reports.config',
        'OutputAnalyticsReportItems.grids.os-stats-reports.config',
        'Tasks.grids.tasks-grid-archive-page.config',
        'Tasks.grids.tasks-grid-backlog-page.config',
        'Tasks.grids.tasks-grid-all-page.config',
        'TranslationPackageLanguageBranches.grids.project-translation-branches-page.config',
        'TranslationPackages.grids.project-translations-page.config',
      ],
      // Run any migrations or clean up
      afterDeserialize: (state, key) => {
        return addIdAddTypeToPageFilterGroup(state, key);
      }
    }),
    // NgxsReduxDevtoolsPluginModule must be the last NGXS plugin in the imports list
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NotificationsServicesModule,
    OAuthServicesModule,
    OutputAnalyticsKeyServicesModule,
    OutputAnalyticsReportServicesModule,
    PermissionsServicesModule,
    ProcessNotificationServicesModule,
    ProfilesServicesModule,
    ProjectChecklistsServicesModule,
    ProjectFilesServicesModule,
    ProjectInfoServicesModule,
    ProjectTargetsServicesModule,
    ProjectsServicesModule,
    ReportsServicesModule,
    ReviewsServicesModule,
    SiteUrlsServicesModule,
    SitesServicesModule,
    SlackWebhooksServicesModule,
    SystemMessagesServicesModule,
    TargetsServiceModule,
    TaskAnalyticsServicesModule,
    TaskBoardsServicesModule,
    TasksServicesModule,
    TeamsServicesModule,
    TranslationPackageLanguageBranchesServicesModule,
    TranslationPackagesServicesModule,
    TranslationQuotesServicesModule,
    UsersServicesModule
  ],
  providers: [
    CookieService,
    NAVIGATOR_PROVIDERS,
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: USER_SETTINGS_SERVICE, useClass: UserSettingsService },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useFactory: function () {
        return {
          ...new MatDialogConfig(),
          maxHeight: '90vh'
        };
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useFactory: function () {
        return {
          ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
          disableTooltipInteractivity: true
        };
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerNoop,
      deps: [ProjectHydratorEffect, ProjectDeleterEffect, SiteListByBuildEffect],
      multi: true
    }
  ]
})
export class CoreModule {
  // Guard against reimporting of CoreModule as described in the Angular Docs https://angular.io/guide/styleguide#prevent-re-import-of-the-core-module
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
