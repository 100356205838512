import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';

@Component({
  selector: 'mc-build-progress-bar',
  templateUrl: './build-progress-bar.component.html',
  styleUrls: ['./build-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildProgressBarComponent {
  @Input() build: Build;
  @Input() showHeader?: boolean = true;
  @Input() showFooter?: boolean = true;

  ProcessState: typeof ProcessState = ProcessState;

  get colorForBuildState(): string {
    return this.buildsService.getColorForState(this.build && this.build.LastNotification ? this.build.LastNotification.ProcessState : ProcessState.Unknown);
  }

  get textColorClassForBuildState(): string {
    return `mc-text-${this.colorForBuildState}`;
  }

  constructor(private buildsService: BuildsService) { }
}
