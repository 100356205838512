<div class="mc-project-file-path-input-path">
  <div *ngFor="let item of valueBreadcrumbs" class="mc-project-file-path-input-item">
    <button class="mc-link-button" [disabled]="disabled || (item.partOfRestrictedToFolderCrumb && !item.endOfRestrictedToFolderCrumb)" type="button" (click)="onItemClicked(item)">
      {{ item.folderName }}
    </button>
    &nbsp;&nbsp;/&nbsp;&nbsp;
  </div>
  <input #textInput [hidden]="disabled" type="text" autocomplete="off" class="mat-input-element mc-project-file-path-input-text-field" [formControl]="textControl" [placeholder]="placeholder" (keydown)="onInputKeyDown($event)">
  <!-- the file name when its disabled shouldnt be an input so show this div instead -->
  <div *ngIf="disabled" class="mc-project-file-path-input-item">
    <button class="mc-link-button" [disabled]="true" type="button">
      {{ textInput.value }}
    </button>
  </div>
</div>

<!-- this button exists just to run openPopup() and be a trigger -->
<button type="button" #trigger mat-icon-button mcPopupClass="mc-project-file-path-input-popup" class="mc-hidden" [mcPopupHeight]="popupHeight" [disabled]="disabled" [mcPopupTriggerDisabled]="disabled" [mcPopupTriggerFor]="popup" [tabindex]="tabIndex">
</button>

<mc-popup #popup (afterOpen)="onPopupOpened()">
  <mc-project-files-tree class="mc-project-file-path-input-files-tree" #filesTree [treeData]="{projectId, branchName}" [show404ErrorAsEmptyTree]="true" [includeFiles]="false">
  </mc-project-files-tree>
  <div class="mc-form-buttons">
    <button type="button" mat-stroked-button color="warn" (click)="onCancelClicked()">Cancel</button>
    <button type="button" mat-raised-button color="primary" (click)="onAcceptClicked()" [disabled]="!getProjectFilesTreeSelectedDirectoryPath()">Accept</button>
  </div>
</mc-popup>