import { ESCAPE } from '@angular/cdk/keycodes';
import { Directive, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { DialogAutoFocusDirective } from '@portal-core/ui/dialog/directives/dialog-auto-focus/dialog-auto-focus.directive';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { filter, map, merge, Observable, of, Subject, Subscription, switchMap } from 'rxjs';

/**
 * An abstract class that can be inherited by dialog components.
 * Provides functionality for displaying a prompt to users when they try to close the dialog.
 */
@Directive()
@AutoUnsubscribe()
export abstract class DialogBase<T = any, R = any> implements OnInit {
  /** Adds the mc-dialog class to the host element. */
  @HostBinding('class.mc-dialog') dialogHostClass: boolean = true;

  @ViewChild(DialogAutoFocusDirective, { static: false }) dialogAutoFocus: DialogAutoFocusDirective;

  /** Keeps track of the close dialog prompt `Subscription` so it can be unsubscribed from. */
  private closeDialogPromptSubscription: Subscription;
  /** Used to emit requests for the dialog to close to be used with the `closeDialogPromptSubscription` `Subscription`. */
  private closeRequestSource: Subject<R> = new Subject<R>();
  /**
   * Whether or not the prompt should be displayed to the user when there is an attempt to close the dialog.
   * Optionally override this property as a getter to check if a form is dirty, etc.
   */
  public get requirePromptOnClose(): boolean {
    return false;
  }
  /**
   * The text displayed in the prompt dialog.
   * Optionally override this property to provide a custom message in the prompt dialog.
   */
  public get promptDisplayText(): string {
    return 'Are you sure you want to close the dialog? All changes will be discarded.';
  }

  constructor(protected dialog: MatDialog, protected dialogRef: MatDialogRef<T, R>) { }

  /**
   * Disables the default closing behavior of the mat-dialog (backdrop clicking and the escape key).
   * Listens to the backdrop click and escape key events on its own to display a prompt to the user asking if the dialog should be closed.
   */
  ngOnInit() {
    // Disable the default closing functionality built into mat-dialog
    this.dialogRef.disableClose = true;

    // Listen to close requests, backdrop clicks, and escape key presses
    this.closeDialogPromptSubscription = merge(
      this.closeRequestSource.asObservable(),
      this.dialogRef.backdropClick().pipe(
        map(() => undefined)
      ),
      this.dialogRef.keydownEvents().pipe(
        filter(event => event.keyCode === ESCAPE),
        map(() => undefined)
      )
    ).pipe(
      // Prompt the user for confirmation to close the dialog
      switchMap(result => {
        // If the prompt is currently required then show the confirmation dialog
        if (this.requirePromptOnClose) {
          return this.onPromptToClose$().pipe(
            map(shouldClose => [shouldClose, result])
          );
        } else { // Else just emit true so that the dialog will close
          return of([true, result]);
        }
      }),
      // If the user chose to close the dialog
      filter(([shouldClose, result]) => shouldClose),
      // Map the value to the result
      map(([shouldClose, result]) => result)
    ).subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  /**
   * Makes a request to close the dialog. If necessary the user will be prompted about discarding changes.
   * @param result The dialog's result that will be emitted in the dialog's afterClosed observable.
   * @param skipPrompt Optionally override prompting the user by setting to true. If false the prompt will only be shown if necessary.
   */
  closeDialog(result?: R, skipPrompt: boolean = false) {
    if (skipPrompt) {
      this.dialogRef.close(result);
    } else {
      this.closeRequestSource.next(result);
    }
  }

  onLoaded() {
    setTimeout(() => this.dialogAutoFocus?.focusable?.focus(), 0);
  }

  /**
   * Displays a `ConfirmDialogComponent` to the user asking them if they want to close the dialog and discard changes.
   * Override this method to use your own dialog, a different prompt, or any other form of confirming with the user.
   * Emits true if the dialog should be closed.
   */
  private onPromptToClose$(): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      ...ConfirmDialogComponent.DialogConfig,
      disableClose: true, // Only allow the user to choose one of the action buttons
      data: {
        action: 'Close',
        actionColor: 'warn',
        cancelAction: 'Keep Open',
        cancelActionColor: 'primary',
        prompt: this.promptDisplayText,
        showCloseButton: false // Only allow the user to choose one of the action buttons
      }
    }).afterClosed();
  }
}
