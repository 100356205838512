import { NodeSpec } from 'prosemirror-model';
import { SchemaPlugin } from '../../prosemirror/model/schema-plugin';

export class HeadingSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    h1: this.makeHeading('h1'),
    h2: this.makeHeading('h2'),
    h3: this.makeHeading('h3'),
    h4: this.makeHeading('h4'),
    h5: this.makeHeading('h5'),
    h6: this.makeHeading('h6')
  };

  makeHeading(tagName: string): NodeSpec {
    return {
      content: 'inline*',
      group: 'block',
      linkBucket: 'heading',
      defining: true,
      parseDOM: [{ tag: tagName }],
      toDOM() { return [tagName, 0]; },
      tagName
    };
  }
}
