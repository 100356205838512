import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { CountrySelectModule } from '@portal-core/forms/components/country-select/country-select.module';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { RegionSelectModule } from '@portal-core/forms/components/region-select/region-select.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { LicenseBillingFormComponent } from './license-billing-form.component';

@NgModule({
  declarations: [
    LicenseBillingFormComponent
  ],
  imports: [
    CommonModule,
    CountrySelectModule,
    FormControlErrorMessageModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    RegionSelectModule
  ],
  exports: [
    LicenseBillingFormComponent
  ]
})
export class LicenseBillingFormModule { }
