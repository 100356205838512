import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Team } from '@portal-core/teams/models/team.model';

@CollectionStateSelectors({
  stateType: TeamsState
})
@State<TeamsState>({
  name: TeamsState.source
})
@Injectable()
export class TeamsState extends CollectionStateBase {
  static source = 'Teams';
  getSource(): string {
    return TeamsState.source;
  }
}
@Injectable({
  providedIn: 'root'
})
export class TeamsDataService extends CollectionDataServiceBase<Team> {

  constructor(protected store: Store) {
    super(store, TeamsState);
  }
}
