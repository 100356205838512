import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { GridColumnName } from '@portal-core/ui/grid/enums/grid-column-name.enum';
import { GridColumn } from '@portal-core/ui/grid/models/grid-column.model';
import { VisibleColumns } from '@portal-core/ui/grid/types/visible-columns.type';

/**
 * The input data for the GridColumnsDialogComponent.
 */
export interface GridColumnsDialogData {
  /** All the columns in the grid. These columns are displayed in a selection list. */
  columns: GridColumn[];
  /** The currently selected columns in the grid. */
  visibleColumns: VisibleColumns;
}

/**
 * The results when the user saves their selection of columns and the GridColumnsDialogComponent is closed.
 */
export interface GridColumnsDialogResult {
  /** The names of the columns that the user selected. */
  visibleColumns: string[];
}

/**
 * GridColumnsDialogComponent
 * A dialog that lets the user select the columns shown in a grid.
 */
@Component({
  selector: 'mc-grid-columns-dialog',
  templateUrl: './grid-columns-dialog.component.html',
  styleUrls: ['./grid-columns-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridColumnsDialogComponent extends DialogBase<GridColumnsDialogComponent, GridColumnsDialogResult> implements OnInit {
  /** The default configuration for the dialog. Use this when opening the dialog. */
  static DialogConfig: MatDialogConfig = {
    width: '60rem',
    autoFocus: false
  };

  /** All the columns in the grid. These columns are displayed in a selection list. */
  columns: GridColumn[];
  /** The names of the currently selected columns in the grid. */
  visibleColumns: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GridColumnsDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<GridColumnsDialogComponent, GridColumnsDialogResult>
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    // Filter out the grid-card column since the grid determines when the card is shown
    this.columns = this.data.columns.filter(column => column.name !== GridColumnName.Card);
    // If the visible columns is 'all' then grab all the column names otherwise use the column names given
    this.visibleColumns = this.data.visibleColumns === 'all' ? this.columns.map(column => column.name) : this.data.visibleColumns;
  }

  onSaveClicked() {
    // When the save button is clicked close the dialog with the visible columns in the result
    this.closeDialog({
      visibleColumns: this.visibleColumns
    });
  }
}
