import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  MenuCloseAnimationDuration = 125;
  MatTableHeaderRowHeight = 56;

  constructor() { }
}
