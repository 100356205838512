import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { ImagePickerValue } from '@portal-core/forms/models/image-picker-value.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { AvatarImageFileExtensions } from '@portal-core/general/constants/file-extensions.constant';
import { FileService } from '@portal-core/general/services/file.service';
import { ImageService } from '@portal-core/general/services/image.service';
import { noop } from 'lodash';
import { map, switchMap } from 'rxjs';

/**
 * An image picker control for selecting an image from the user's computer.
 * Works with Angular forms.
 * When setting the image picker's value from a `FormControl` use the image's url.
 * When getting the image picker's value from a `FormControl` a `File` object will be returned. This `File` object can be used in POST requests.
 */
@Component({
  selector: 'mc-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImagePickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ImagePickerComponent), multi: true }
  ]
})
export class ImagePickerComponent implements ControlValueAccessor, Validator {
  /** The theme color of the picker. */
  @Input() color: ThemePalette = 'link' as ThemePalette;

  /** Whether the image picker is disabled. */
  @Input() disabled: boolean = false;

  /** The label displayed on the picker. */
  @Input() placeholder: string;

  /** The current value of the image picker. */
  @Input()
  get value(): ImagePickerValue {
    return this._value;
  }
  set value(value: ImagePickerValue) {
    if (this._value !== value) {
      this._value = value;
      this.cdr.markForCheck();
      this.valueChange.emit(value);
    }
  }
  private _value: ImagePickerValue;

  /** Emits when the value changes (either due to user input or programmatic change). */
  // @Output() valueChange: EventEmitter<File> = new EventEmitter<File>();
  @Output() valueChange: EventEmitter<ImagePickerValue> = new EventEmitter<ImagePickerValue>();

  /** Applies the mc-image-picker class to the host element. */
  @HostBinding('class.mc-image-picker') hostClass: boolean = true;

  /** Applies the mc-image-picker-has-value class to the host element when the picker has a valid value. */
  @HostBinding('class.mc-image-picker-has-value')
  get hasValueHostClass(): boolean {
    return !!(this.value && this.value.path);
  }

  @ViewChild('fileInput', { static: true }) fileInputRef: ElementRef<HTMLInputElement>;

  /** The value change callback for Angular Forms */
  private onChange: Function = noop;
  /** The control touched callback for Angular Forms */
  private onTouched: Function = noop;
  /** The validator change callback for Angular Forms */
  private onValidatorChange: Function = noop;
  /** The combined form control validator for this input. */
  private validator: ValidatorFn = Validators.compose([this.formsService.createFileTypeValidator('image', AvatarImageFileExtensions)]);

  constructor(private cdr: ChangeDetectorRef, private fileService: FileService, private imageService: ImageService, private formsService: FormsService) { }

  onFileChanged(event: Event) {
    const input = (event.target as HTMLInputElement);
    const file = input.files[0];

    input.value = null; // Clear out the input element's value so that a change is detected if the user picks the same file again

    if (file.type.includes('image')) {
      this.fileService.readAsDataURL$(file).pipe(
        switchMap(dataURL => this.imageService.getImageDimensions$(dataURL).pipe(
          map(dimensions => {
            return {
              dataURL,
              dimensions
            };
          })
        ))
      ).subscribe(({ dataURL, dimensions }) => {
        this.setValueFromUI({
          file,
          height: dimensions.height,
          name: file.name,
          path: dataURL,
          size: file.size,
          type: file.type,
          width: dimensions.width
        });
      }, () => {
        this.setValueFromUI({
          file,
          name: file.name,
          size: file.size,
          type: file.type
        });
      });
    } else {
      this.setValueFromUI({
        file,
        name: file.name,
        size: file.size,
        type: file.type
      });
    }
  }

  onDeleteImageClicked() {
    this.setValueFromUI(null);
  }

  /** Sets the value and path properties on the image picker and notifies Angular Forms of the changes.  */
  protected setValueFromUI(value: ImagePickerValue) {
    this.value = value;
    this.onChange(this.value); // So Angular Forms know this control's value has changed
    this.onTouched(); // So Angular Forms know this control has been touched
  }

  /** Required by ControlValueAccessor */
  writeValue(value: ImagePickerValue): void {
    this.value = value;
  }

  /** Required by ControlValueAccessor */
  registerOnChange(fn: Function): void {
    this.onChange = fn || noop;
  }

  /** Required by ControlValueAccessor */
  registerOnTouched(fn: Function): void {
    this.onTouched = fn || noop;
  }

  /** Required by ControlValueAccessor */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Required by Validator */
  validate(control: AbstractControl): ValidationErrors {
    return this.validator(control);
  }

  /** Required by Validator */
  registerOnValidatorChange(fn: Function) {
    this.onValidatorChange = fn || noop;
  }
}
