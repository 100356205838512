import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmEditorComponent } from './pm-editor.component';

@NgModule({
  declarations: [
    PmEditorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PmEditorComponent
  ]
})
export class PmEditorModule { }
