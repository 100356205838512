import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Validation } from '@portal-core/forms/models/validation.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, filter, map, Observable, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-form-control-error-message',
  templateUrl: './form-control-error-message.component.html',
  styleUrls: ['./form-control-error-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FormControlErrorMessageComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName = '';
  @Input() type: string;

  @InputObservable('form') form$: Observable<UntypedFormGroup>;
  @InputObservable('type') type$: Observable<string>;
  @InputObservable('controlName') controlName$: Observable<string>;

  validations$: Observable<Validation[]>; // Possible validation errors the form control has. Listed in forms.service
  formControl$: Observable<AbstractControl>;
  statusChangeSubscription: Subscription;

  constructor(private formsService: FormsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.validations$ = this.type$.pipe(
      map(type => this.formsService.getValidationTypes(type))
    );

    this.formControl$ = combineLatest(this.form$, this.controlName$).pipe(
      filter(([form, controlName]) => !!form && !!controlName), // Check that inputs exist
      map(([form, controlName]) => form.get(controlName))
    );

    this.statusChangeSubscription = this.formControl$.pipe(
      filter(control => !!control),
      switchMap(control => control.statusChanges.pipe( // Manually call change detection when error occurs
        tap(() => this.cdr.markForCheck())
      )),
    ).subscribe();
  }

  getErrorMessage(error: boolean | string, defaultMessage: string) {
    return typeof error === 'string' ? error : defaultMessage;
  }
}
