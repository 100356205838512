import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { CommitAvatarModule } from '@portal-core/commits/components/commit-avatar/commit-avatar.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { ReviewFileNotificationListModule } from '@portal-core/notifications/components/review-file-notification-list/review-file-notification-list.module';
import { ProjectFileAvatarModule } from '@portal-core/project-files/components/project-file-avatar/project-file-avatar.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { ReviewFileReviewersFormModule } from '@portal-core/reviews/review-files/components/review-file-reviewers-form/review-file-reviewers-form.module';
import { ReviewFileSettingsFormModule } from '@portal-core/reviews/review-files/components/review-file-settings-form/review-file-settings-form.module';
import { ReviewFileLinkModule } from '@portal-core/reviews/review-files/pipes/review-file-link/review-file-link.module';
import { ReviewFileStatusTextModule } from '@portal-core/reviews/review-files/pipes/review-file-status-text/review-file-status-text.module';
import { ReviewPackageAvatarModule } from '@portal-core/reviews/review-packages/components/review-package-avatar/review-package-avatar.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { ReviewFileProfileComponent } from './review-file-profile.component';

@NgModule({
  declarations: [
    ReviewFileProfileComponent
  ],
  imports: [
    BranchAvatarModule,
    CommitAvatarModule,
    CommonModule,
    LicenseUserAvatarModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    PreventDefaultModule,
    ProjectAvatarModule,
    ProjectFileAvatarModule,
    ReviewFileAvatarModule,
    ReviewFileLinkModule,
    ReviewFileNotificationListModule,
    ReviewFileReviewersFormModule,
    ReviewFileSettingsFormModule,
    ReviewFileStatusTextModule,
    ReviewPackageAvatarModule,
    RouterModule,
    SwitchBoardModule
  ],
  exports: [
    ReviewFileProfileComponent
  ]
})
export class ReviewFileProfileModule { }
