import { parseStyles, stringifyStyles } from '@common/html/util/style';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttr, modifyDomOutputSpec } from '@common/util/schema';
import { pick } from 'lodash';
import { MarkSpec, NodeSpec, ProseMirrorNode } from 'prosemirror-model';

const styleAttrName = 'style';
const whitelistedStylesForToDOM = ['width', 'height'];

export class MadCapStyleSchemaPlugin extends SchemaPlugin {
  modifyNodesAndMarks(nodes: Dictionary<NodeSpec>, marks: Dictionary<MarkSpec>) {
    Object.entries(nodes).forEach(([name, nodeSpec]) => {
      if (name !== 'text') {
        nodeSpec.attrs = Object.assign(nodeSpec.attrs ?? {}, {
          [styleAttrName]: {
            default: undefined,
            toFlareXML: (value: Dictionary<string>) => {
              return (typeof value === 'object' && value !== null) ? stringifyStyles(value) : value;
            }
          }
        });

        if (Array.isArray(nodeSpec.parseDOM)) {
          nodeSpec.parseDOM.forEach(parser => {
            const originalGetAttrs = parser.getAttrs;

            parser.getAttrs = function (dom: Element) {
              let attrs = originalGetAttrs ? originalGetAttrs.apply(this, arguments) : {};

              if (attrs === false) {
                return false;
              }

              const style = getSchemaAttr(dom, styleAttrName);
              if (style) {
                attrs = attrs ?? {}; // Ensure attrs exists
                attrs[styleAttrName] = parseStyles(style);
              }

              return attrs;
            };
          });
        }

        const originalToDOM = nodeSpec.toDOM;
        nodeSpec.toDOM = function (node: ProseMirrorNode) {
          const dom = originalToDOM.apply(this, arguments);
          const style: Dictionary = node.attrs[styleAttrName];
          const whitelistedStyles = pick(style, whitelistedStylesForToDOM);

          if (whitelistedStyles && Object.keys(whitelistedStyles).length > 0) {
            modifyDomOutputSpec(dom, {
              attrs: {
                add: {
                  [styleAttrName]: stringifyStyles(whitelistedStyles)
                }
              }
            });
          }

          return dom;
        };
      }
    });
  }
}
