import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subscription, first } from 'rxjs';

@Directive({
  selector: '[mcHasVisited]'
})
@AutoUnsubscribe()
export class HasVisitedDirective implements OnInit {
  @Input('mcHasVisited') pageName: string;
  @Input() licenseId: number;
  @Input() clicked$: Observable<string>;
  private viewRef: EmbeddedViewRef<any> = null;
  clickedSubscription: Subscription;
  hasVisitedSettings: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private licenseUsersService: LicenseUsersService
  ) { }

  ngOnInit() {
    this.licenseUsersService.getClientSideSettings$(this.licenseId, LicenseUserClientSideInfoKey.HasVisitedPages).subscribe(madCloudResult => {
      if (!madCloudResult.Succeeded) {
        return;
      }

      this.hasVisitedSettings = (madCloudResult.Result && madCloudResult.Result.Settings) ?
        <any>JSON.parse(madCloudResult.Result.Settings) : {};

      if (!this.hasVisitedSettings[this.pageName]) {
        this.show();
      } else {
        this.hide();
      }
    });

    this.clickedSubscription = this.clicked$.subscribe(pageName => {
      if (pageName === this.pageName) {
        this.onClick();
      }
    });
  }

  onClick() {
    if (!this.hasVisitedSettings[this.pageName]) {
      this.hasVisitedSettings[this.pageName] = true;
      this.hide();

      try {
        this.licenseUsersService.saveClientSideSettings$(
          this.licenseId,
          LicenseUserClientSideInfoKey.HasVisitedPages,
          this.hasVisitedSettings).pipe(first()).subscribe(result => {
            if (!result.Succeeded) {
              // TODO: should something be done here?
            }
          });
      } catch (error) {
        // TODO: should something be done here?
      }
    }
  }

  show() {
    if (!this.viewRef) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  hide() {
    this.viewContainer.clear();
    this.viewRef = null;
  }

}
