import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapConceptSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapconcept: {
      group: 'inline',
      inline: true,
      attrs: {
        term: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:concept',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['term']);
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-concept icon-star' }]; },
      tagName: 'MadCap:concept',
      isVoid: true
    },

    madcapconceptlink: {
      group: 'inline',
      inline: true,
      isLink: true,
      attrs: {
        term: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:conceptLink',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['term']);
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-concept-link' }, 'Concept Link']; },
      tagName: 'MadCap:conceptLink',
      isVoid: true
    }
  };
}
