import { NgModule } from '@angular/core';
import { CommonModule, I18nPluralPipe } from '@angular/common';
import { PluralizePipe } from './pluralize.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PluralizePipe
  ],
  exports: [
    PluralizePipe
  ],
  providers: [
    I18nPluralPipe,
    PluralizePipe // This makes the pipe available to inject
  ]
})
export class PluralizeModule { }
