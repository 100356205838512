import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSortModule } from '@angular/material/sort';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectsSelectListModule } from '@portal-core/projects/components/projects-select-list/projects-select-list.module';
import { AutocompleteInputModule } from '@portal-core/ui/autocomplete/components/autocomplete-input/autocomplete-input.module';
import { GridHeaderFilterModule } from '@portal-core/ui/grid/components/grid-header-filter/grid-header-filter.module';
import { GridHeaderFilterFormModule } from '@portal-core/ui/grid/directives/grid-header-filter-form/grid-header-filter-form.module';
import { GridHeaderFilterMenuModule } from '@portal-core/ui/grid/directives/grid-header-filter-menu/grid-header-filter-menu.module';
import { GridSelectFilterComponent } from './grid-select-filter.component';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';

@NgModule({
  imports: [
    AutocompleteInputModule,
    CommonModule,
    FormsModule,
    GridHeaderFilterFormModule,
    GridHeaderFilterMenuModule,
    GridHeaderFilterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatListModule,
    MatSortModule,
    ProjectAvatarModule,
    ProjectsSelectListModule,
    ReactiveFormsModule,
    ProjectAsyncModule
  ],
  declarations: [GridSelectFilterComponent],
  exports: [GridSelectFilterComponent]
})
export class GridSelectFilterModule {}
