import { I18nPluralPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { PluralItemsService } from '@portal-core/general/services/plural-items.service';

@Pipe({
  name: 'mcPluralize'
})
export class PluralizePipe implements PipeTransform {
  constructor(private pluralItemService: PluralItemsService, private i18nPluralPipe: I18nPluralPipe) { }

  transform(value: number, itemType: string) {
    return this.i18nPluralPipe.transform(value, this.pluralItemService.getMapItem(itemType));
  }
}
