<form class="mc-spring-board" (ngSubmit)="onSubmit(projectSettingsForm)" [formGroup]="projectSettingsForm">
  <div class="mc-spring-board-form-body">
    <mc-color-picker [control]="projectSettingsForm.controls.Color" [placeholder]="'Icon Color'" [color]="projectSettingsForm.value['Color']">
    </mc-color-picker>

    <mat-form-field color="primary-vivid" class="mc-form-field">
      <textarea matInput type="text" formControlName="Description" placeholder="Description"></textarea>
    </mat-form-field>
    <mat-form-field color="primary-vivid" class="mc-form-field">
      <input matInput type="text" formControlName="Initials" placeholder="Initials" maxlength="3">
      <mat-error>
        <mc-form-control-error-message [form]="projectSettingsForm" controlName="Initials"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field color="primary-vivid" class="mc-form-field">
      <input matInput type="text" formControlName="Name" placeholder="Name">
      <mat-error>
        <mc-form-control-error-message [form]="projectSettingsForm" controlName="Name"></mc-form-control-error-message>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mc-spring-board-form-footer">
    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="projectSettingsForm.pristine || projectSettingsForm.invalid || (savingState.loading$ | async)">Save</button>
    </div>
  </div>

  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>