import { Observable, MonoTypeOperatorFunction } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

/**
 * Caches the latest value emitted by the source observable causing any subscribers to use the cached value instead of executing the work done before `cache`.
 * @return {Observable<T>} An observable that emits the latest value from the source Observable.
 */
export function cache<T>(): MonoTypeOperatorFunction<T> {
  return (source$: Observable<any>) => source$.pipe(
    publishReplay(1), // Turn this into a multicasting observable that replays the latest value
    refCount() // Auto subscribes/unsubscribes from the underlying observable based on whether this observable has subscribers
  );
}
