import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemMessageListComponent } from './system-message-list.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

@NgModule({
  declarations: [
    SystemMessageListComponent
  ],
  imports: [
    CommonModule,
    MatListModule
  ],
  exports: [
    SystemMessageListComponent
  ]
})
export class SystemMessageListModule { }
