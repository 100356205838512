<div class="mc-avatar-tooltip-container" [matTooltip]="targetPath" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !targetPath">
  <a *ngIf="showAvatar && project && branchName && !isImageDisabled()" class="mc-avatar-container" [routerLink]="['/', project?.LicenseId, 'projects', project?.Id, 'files', 'blob', encodeURIComponent(branchName), encodeURIComponent(targetPath)]">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-reticle"></mat-icon>
  </a>
  <div *ngIf="showAvatar && (!project || !branchName || isImageDisabled())" class="mc-avatar-container">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-reticle"></mat-icon>
  </div>

  <a *ngIf="showName && project && branchName && !isNameDisabled()" href="#" class="mc-avatar-name" [routerLink]="['/', project?.LicenseId, 'projects', project?.Id, 'files', 'blob', encodeURIComponent(branchName), encodeURIComponent(targetPath)]"><span class="mc-target-avatar-path-name" [ngClass]="{'mc-text-muted': emphasizeTargetName}">{{ targetDirname }}</span>{{ targetBasename }}<span class="mc-target-avatar-path-name" [ngClass]="{'mc-text-muted': emphasizeTargetName}">{{ targetExtname }}</span></a>

  <span *ngIf="showName && (!project || !branchName || isNameDisabled())" class="mc-avatar-name"><span class="mc-target-avatar-path-name" [ngClass]="{'mc-text-muted': emphasizeTargetName}">{{ targetDirname }}</span> <span [ngClass]="{'mc-target-avatar-base-name' : emphasizeTargetName}" class="mc-target-avatar-base-name">{{ targetBasename }}</span><span class="mc-target-avatar-path-name" [ngClass]="{'mc-text-muted': emphasizeTargetName}">{{ targetExtname }}</span></span>
</div>