import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { NoItemPlaceholderModule } from '@portal-core/general/components/no-item-placeholder/no-item-placeholder.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { ProjectSettingsFormComponent } from './project-settings-form.component';
import { MCColorPickerModule } from '@portal-core/ui/color-picker/color-picker.module';
import { McPickerColorFormats, MC_PICKER_COLOR_FORMATS } from '@portal-core/ui/color-picker/services/color-formats';

//'rgb' | 'hex' | 'hex6' | 'hex3' | 'hex4' | 'hex8'
const CUSTOM_MC_COLOR_FORMATS: McPickerColorFormats = {
  display: {
    colorInput: 'hex6'
  }
}

@NgModule({
  declarations: [
    ProjectSettingsFormComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormControlErrorMessageModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NoItemPlaceholderModule,
    PreventDefaultModule,
    ReactiveFormsModule,
    MCColorPickerModule
  ],
  exports: [
    ProjectSettingsFormComponent
  ],
  providers: [
    { provide: MC_PICKER_COLOR_FORMATS, useValue: CUSTOM_MC_COLOR_FORMATS }
  ],
})
export class ProjectSettingsFormModule { }
