import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfSmeDirective } from './if-sme.directive';

@NgModule({
  declarations: [
    IfSmeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IfSmeDirective
  ]
})
export class IfSmeModule { }
