import { Observable } from 'rxjs';
import { Page } from '@common/paged-data/types/page.type';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { ListControl } from '@portal-core/ui/list/util/list-control';

/**
 * SelectionListControl
 * Defines the getValue method used in a SelectListComponent.
 */
 export class SelectionListControl<T = any> extends ListControl<T> {
  /**
   * constructor
   * @param collectionService The service for the collection redux store that stores the items in this list.
   * @param fetchDataListPage$ A method that can fetch a Page of items and returns it in an Observable.
   * @param getValue A method that gets the mc-list-option value for an item.
   * @param buildLink A method that returns a routerLink value to be used for an item. By defining this method the mc-list-items become anchor tags.
   * @param buildQueryParams A method that returns the queryParams to be used for an item.
   */
  constructor(
    public collectionService: CollectionServiceBase<T>,
    public fetchDataListPage$: (filter: PageFilter) => Observable<Page<T>>,
    public getValue: (item: T) => any,
    public buildLink?: (item: T) => string | any[],
    public buildQueryParams?: (item: T) => Dictionary
  ) {
    super(collectionService, fetchDataListPage$);
  }
}
