import { mergeStyles } from '@common/html/util/style';
import { Node } from 'prosemirror-model';
import { Transform } from 'prosemirror-transform';

/**
 * Options for updating a node's attributes.
 */
export interface UpdateFlareNodeAttrsOptions {
  /** Whether the style attribute should be merged with the existing style attribute. Defaults to true. */
  mergeStyles?: boolean;
}

/**
 * Sets the attributes of a node by adding/setting the given attrs to the node's existing attributes.
 * Is aware of special Flare attributes such as style.
 * By default the style attribute is merged with the existing style attribute.
 * @param tr The transform to update the node with.
 * @param node The node to update.
 * @param pos The position of the node in the document.
 * @param attrs The attributes to add/set on the node.
 * @param options special rules on how to merge some things.
 * @returns The transform with the node updated.
 */
export function updateFlareNodeAttrs(tr: Transform, node: Node, pos: number, attrs: Dictionary, options?: UpdateFlareNodeAttrsOptions): Transform {
  const newAttrs = mergeFlareNodeAttrs(node?.attrs, attrs, options);
  return tr.setNodeMarkup(pos, null, newAttrs);
}

/**
 * Merges two attributes dictionaries together where the attrs Dictionary replaces things in the nodeAttrs Dictionary.
 * Is aware of special Flare attributes such as style.
 * By default the style attribute is merged with the existing style attribute.
 * @param nodeAttrs The attrs the node already has.
 * @param attrs The attrs to update a node with.
 * @param options special rules on how to merge some things.
 * @returns A Dictionary representating all the attributes a node will now have.
 */
export function mergeFlareNodeAttrs(nodeAttrs: Dictionary, attrs: Dictionary, options?: UpdateFlareNodeAttrsOptions): Dictionary {
  let newAttrs = {
    ...nodeAttrs,
    ...attrs
  };

  if (options?.mergeStyles !== false) {
    const style = mergeStyles(nodeAttrs?.style, attrs?.style);
    // If the style is empty then use undefined
    newAttrs.style = Object.keys(style).length > 0 ? style : undefined;
  }
  return newAttrs;
}
