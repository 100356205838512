import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { DocumentNodeSelectorInputModule } from '@portal-core/project-files/components/document-node-selector-input/document-node-selector-input.module';
import { ProjectFilePickerInputModule } from '@portal-core/project-files/components/project-file-picker-input/project-file-picker-input.module';
import { DividerModule } from '@portal-core/ui/divider/components/divider/divider.module';
import { LinkPropertiesDialogComponent } from './link-properties-dialog.component';

@NgModule({
  declarations: [
    LinkPropertiesDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatFormFieldModule,
    MatTreeModule,
    MatToolbarModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    ProjectFilePickerInputModule,
    DividerModule,
    MatRippleModule,
    MatExpansionModule,
    DocumentNodeSelectorInputModule
  ],
  exports: [
    LinkPropertiesDialogComponent
  ]
})
export class LinkPropertiesDialogModule { }
