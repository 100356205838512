<mc-text-editor
  [editorProps]="editorProps"
  [language]="language"
  [nodeViews]="nodeViews"
  [plugins]="plugins"
  [readonly]="readonly"
  [schema]="schema"
  (change)="change.emit($event)"
  (editorContextMenu)="onEditorContextMenu($event)"
  (editorStateChange)="editorStateChange.emit($event)"
  (ready)="onEditorReady($event)"
  (selectionChange)="selectionChange.emit($event)"
></mc-text-editor>
