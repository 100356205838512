import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionPipe } from './has-permission.pipe';

@NgModule({
  declarations: [HasPermissionPipe],
  imports: [CommonModule],
  exports: [HasPermissionPipe]
})
export class HasPermissionModule { }
