<mc-select-list [dataListId]="dataListId" listClass="mc-list-selectable" [listControl]="listControl">
  <ng-template mcListItem let-branch>
    <mat-list-item>
      <mc-branch-avatar [branchName]="branch.Name" compact [disabled]="true" size="small" [truncate]="true"></mc-branch-avatar>
    </mat-list-item>
  </ng-template>

  <ng-template mcEmptyList>
    <p>No branches found.</p>
  </ng-template>

  <ng-template mcAllItemsLoadedList>
    <p>All branches loaded.</p>
  </ng-template>
</mc-select-list>