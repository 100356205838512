import { Injectable } from '@angular/core';
import { Store, State } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { TaskAnalytics } from '@portal-core/task-analytics/models/task-analytics.model';

@CollectionStateSelectors({
  stateType: TaskAnalyticsState
})
@State<TaskAnalyticsState>({
  name: TaskAnalyticsState.source
})
@Injectable()
export class TaskAnalyticsState extends CollectionStateBase {
  static source = 'TaskAnalytics';
  getSource(): string {
    return TaskAnalyticsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskAnalyticsDataService extends CollectionDataServiceBase<TaskAnalytics> {
  constructor(protected store: Store) {
    super(store, TaskAnalyticsState);
  }
}
