import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapPageBreakSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcappagebreak: {
      group: 'block',
      parseDOM: [
        { tag: 'MadCap\\:pageBreak' }
      ],
      toDOM() { return ['div', { class: 'mc-madcap-page-break' }]; },
      tagName: 'MadCap:pageBreak',
      isVoid: true
    }
  };
}
