import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { splitMeasurementFromUnit } from '@common/html/util/style';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subscription, map, startWith } from 'rxjs';

interface CSSLengthForm {
  measurement: FormControl<string>,
  length: FormControl<number>,
  unit: FormControl<string>
}

@Component({
  selector: 'mc-css-length-popup',
  templateUrl: './css-length-popup.component.html',
  styleUrls: ['./css-length-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

@AutoUnsubscribe()
export class CssLengthPopupComponent implements OnInit, OnChanges {

  /** The value to edit. */
  @Input() measurement: string;
  /** The parent input's label. */
  @Input() label: string;
  /** The presets to select that can be used for the parent input. */
  @Input() presets: string[];

  CSSLengthForm: FormGroup<CSSLengthForm>;

  filteredUnits$: Observable<string[]>;

  measurementSubscription: Subscription;
  lengthSubscription: Subscription;
  unitSubscription: Subscription;

  /** These flags are needed because the top most input updates the bottom and visa versa. */
  userChangedMeasurementValue: boolean = false;
  userChangedLengthOrUnitValue: boolean = false;

  /** What value the user ended up creating, to be used elsewhere. */
  get value(): string {
    return this.CSSLengthForm.controls['measurement'].value;
  }

  /* The currently supported units. */
  units: string[] = [
    'px',
    'pt',
    'cm',
    'mm',
    'in',
    'pc',
    'em',
    'ex',
    '%',
    'ch',
    'rem',
    'vw',
    'vh',
    'vmin',
    'vmax',
  ];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.measurement && !changes.measurement.firstChange) {
      this.updateCSSLengthFormControls(this.measurement);
    }
  }

  /** When the user updates the main input or hits cancel, update all of the other controls in this popup. */
  updateCSSLengthFormControls(measurement: string) {
    this.userChangedLengthOrUnitValue = true;
    this.userChangedMeasurementValue = true;
    const splitMeasurement = splitMeasurementFromUnit(measurement);
    this.CSSLengthForm.controls['measurement'].setValue(measurement);
    this.CSSLengthForm.controls['length'].setValue(splitMeasurement?.unit ? parseInt(splitMeasurement?.measurement, 10) : null);
    this.CSSLengthForm.controls['unit'].setValue(splitMeasurement?.unit);
  }

  ngOnInit() {
    this.createCSSLengthForm();
    const formControls = this.CSSLengthForm.controls;

    this.filteredUnits$ = formControls['unit'].valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value || '')),
    );

    this.measurementSubscription = formControls['measurement'].valueChanges.subscribe((measurement) => {
      // if the user changed the length this observable fired extra so don't do anything.
      if (this.userChangedLengthOrUnitValue) {
        this.userChangedLengthOrUnitValue = false;
        return;
      }
      const splitMeasurement = splitMeasurementFromUnit(measurement);

      // set that the user manually changed the measurement.
      this.userChangedMeasurementValue = true;

      // if its a measurement that can be split into length and unit then set length and unit controls
      // if not clear them.
      if (splitMeasurement && splitMeasurement.unit) {
        formControls['length'].setValue(parseInt(splitMeasurement.measurement, 10));
        formControls['unit'].setValue(splitMeasurement.unit);
      } else {
        formControls['length'].setValue(null);
        formControls['unit'].setValue(null);
      }
    });

    this.lengthSubscription = formControls['length'].valueChanges.subscribe(() => {
      if (this.userChangedMeasurementValue) {
        return;
      }
      this.setMeasurement();
    });

    this.unitSubscription = formControls['unit'].valueChanges.subscribe(() => {
      if (this.userChangedMeasurementValue) {
        // length and unit are both set from measurement so unit, the last one that is set, needs to be the one 
        // that changes the flag.
        this.userChangedMeasurementValue = false;
        return;
      }
      this.setMeasurement();
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.units.filter(unit => unit.includes(filterValue));
  }

  private setMeasurement() {
    const formControls = this.CSSLengthForm.controls;
    // user can remove length or unit always set it back to an empty string
    const length = formControls['length'].value ? formControls['length'].value : '';
    const unit = formControls['unit'].value ? formControls['unit'].value : '';
    this.userChangedLengthOrUnitValue = true;
    formControls['measurement'].setValue(length + unit);
  }

  protected createCSSLengthForm() {
    const splitMeasurement = splitMeasurementFromUnit(this.measurement);
    this.CSSLengthForm = this.fb.group({
      measurement: new FormControl(this.measurement ?? ''),
      length: new FormControl(splitMeasurement?.unit ? splitMeasurement?.measurement : null),
      unit: new FormControl(splitMeasurement?.unit ?? '')
    });
  }
}
