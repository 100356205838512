import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseAdminSettings } from '@portal-core/licenses/models/license-admin-settings.model';
import { ContactInfo } from '@portal-core/licenses/models/license-contact-info.model';
import { LicenseCreateRequest } from '@portal-core/licenses/models/license-create-request.model';
import { LicenseSamlAuthenticationSettings } from '@portal-core/licenses/models/license-saml-authentication-settings.model';
import { LicenseSeatCounts } from '@portal-core/licenses/models/license-seat-counts.model';
import { SecuritySettings } from '@portal-core/licenses/models/license-security-settings.model';
import { LicenseSettings } from '@portal-core/licenses/models/license-settings.model';
import { SubscriptionAddition } from '@portal-core/licenses/models/license-subscription-addition.model';
import { SubscriptionOrderItem } from '@portal-core/licenses/models/license-subscription-order-item.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesSizeStorage } from '@portal-core/licenses/models/licenses-size-storage.model';
import { UserBulkResponse } from '@portal-core/users/models/user-bulk-response.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicensesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getLicensesForCurrentUser$(): Observable<License[]> {
    return this.http.get<License[]>(`${this.apiService.centralApiBaseUri}/api/licenses/My/SimplifiedModel`);
  }

  createLicense$(licenseCreateRequest: LicenseCreateRequest): Observable<License> {
    return this.http.post<License>(`${this.apiService.centralApiBaseUri}/api/licenses/Create`, licenseCreateRequest);
  }

  @SharePendingRequest()
  getLicensesByQuery$(filter: PageFilter): Observable<Page> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/licenses/query`, filter);
  }

  @SharePendingRequest()
  getLicenseById$(licenseId: number | string): Observable<License> {
    return this.http.get<License>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}`);
  }

  @SharePendingRequest()
  getLicenseDefaultTeams$(licenseId: number): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/DefaultTeams`);
  }

  verifyBulkUserCsvFile$(licenseId: number, seatType: number, csvFile: FormData): Observable<UserBulkResponse> {
    return this.http.post<UserBulkResponse>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/bulkUsers?seatType=${seatType}`, csvFile);
  }

  @SharePendingRequest()
  getStorageInfo$(licenseId: number): Observable<LicenseStorage> {
    return this.http.get<LicenseStorage>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/storageInfo`);
  }

  setActivationStatus$(licenseId: number, status: number): Observable<any> {
    return this.http.put<any>(`${this.apiService.centralApiBaseUri}/api/licenses/ChangeActivationStatus`, { licenseId, status });
  }

  changeMaxCountOfSeats$(licenseId: number, licensesSeats: LicenseSeatCounts): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/changeMaxCountOfSeats`, licensesSeats);
  }

  changeMaxStorageSize$(licenseId: number, licensesSize: LicensesSizeStorage): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/changeMaxStorageSize`, licensesSize);
  }

  changeLicenseSettings$(licenseId: number, licenseData: LicenseSettings, avatar: File): Observable<MadCloudResult> {
    const formData = new FormData();
    formData.append('LicenseSettings', licenseData ? JSON.stringify(licenseData) : null);

    if (typeof avatar !== 'undefined') {
      formData.append('AvatarFile', avatar);
    }

    formData.append('IsAvatarSet', JSON.stringify(avatar !== null ? true : false));

    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/changeLicenseSettings`, formData);
  }

  changeVanityUrl$(licenseId: number, vanityUrl: string): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/ChangeVanityUrl`, { VanityBaseUrl: vanityUrl });
  }

  changeAdminLicenseSettings$(licenseId: number, licenseData: LicenseAdminSettings, avatar: File): Observable<MadCloudResult> {
    const formData = new FormData();
    formData.append('LicenseData', licenseData ? JSON.stringify(licenseData) : null)
    if (typeof avatar !== 'undefined') {
      formData.append('AvatarFile', avatar);
    }

    formData.append('IsAvatarSet', JSON.stringify(avatar !== null ? true : false));
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/changeAdminLicenseSettings`, licenseData);
  }

  calculateSubscriptionAdditions$(licenseId: number, additions: SubscriptionAddition): Observable<SubscriptionOrderItem[]> {
    return this.http.post<SubscriptionOrderItem[]>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/calculateSubscriptionAdditions`, additions);
  }

  processSubscriptionAdditions$(licenseId: number, additions: SubscriptionAddition): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/processSubscriptionAdditions`, additions);
  }

  @SharePendingRequest()
  getContactInfo$(licenseId: number): Observable<ContactInfo> {
    return this.http.get<ContactInfo>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/contactInfo`);
  }

  deleteContactInfo$(licenseId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/removeContactInfo`);
  }

  updateContactInfo$(licenseId: number, contactInfoModel: ContactInfo): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/updateContactInfo`, contactInfoModel);
  }

  changeSecuritySettings$(licenseId: number, securitySettingsModel: SecuritySettings): Observable<any> {
    return this.http.put(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/ChangeSecuritySettings`, securitySettingsModel);
  }

  validateUserEmailAddress$(licenseId: number, email: string): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/singleUser?email=${encodeURIComponent(email)}`, null);
  }

  changeOpenAISettings$(licenseId: number, apiKey: string, apiModel: string): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/changeOpenAISettings`, { ApiKey: apiKey, ApiModel: apiModel });
  }

  verifyOpenAIKey$(apiKey: string, apiModel: string): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/verifyOpenAIKey`, { ApiKey: apiKey, ApiModel: apiModel });
  }

  setSamlAuthenticationSettings$(licenseId: number, licenseSamlAuthenticationSettings: LicenseSamlAuthenticationSettings): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/licenses/${licenseId}/samlAuthenticationSettings`, licenseSamlAuthenticationSettings);
  }

  @SharePendingRequest()
  getSamlAuthenticationSettings$(licenseId: number): Observable<LicenseSamlAuthenticationSettings> {
    let path = `${this.apiService.centralApiBaseUri}/api/licenses/samlAuthenticationSettings`;
    if (licenseId) {
      path += `?licenseId=${licenseId}`;
    }
    return this.http.get<LicenseSamlAuthenticationSettings>(path);
  }

  @SharePendingRequest()
  getSamlAuthenticationSettingsAndDefaultTeams$(licenseId: number): Observable<LicenseSamlAuthenticationSettings> {
    let path = `${this.apiService.centralApiBaseUri}/api/licenses/samlAuthenticationSettingsAndDefaultTeams`;
    if (licenseId) {
      path += `?licenseId=${licenseId}`;
    }
    return this.http.get<LicenseSamlAuthenticationSettings>(path);
  }

  @SharePendingRequest()
  getLicenseByDomain$(): Observable<License> {
    return this.http.get<License>(`${this.apiService.centralApiBaseUri}/api/licenses/getDomainLicense`);
  }
}
