import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ReviewFileUserStatus } from '@common/reviews/enums/review-file-user-status.enum';
import { ReviewFileUser } from '@common/reviews/models/review-file-user.model';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { ReviewFileUsersService } from '@portal-core/reviews/review-file-users/services/review-file-users.service';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, Observable, catchError, of, switchMap, tap, throwError } from 'rxjs';

export interface ReviewEditSnippetDialogData {
  featureSetVersion: number;
  reviewer: LicenseUser;
  reviewPackageId: number;
  snippetSrc: string;
  changeCallback: (status: ReviewFileUserStatus) => void;
  navToFileCallback: (src: string) => void;
}

@Component({
  selector: 'mc-review-edit-snippet-dialog',
  templateUrl: './review-edit-snippet-dialog.component.html',
  styleUrls: ['./review-edit-snippet-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewEditSnippetDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '85rem',
    height: '45rem'
  };

  // Dialog data
  featureSetVersion: number;
  reviewer: LicenseUser;
  reviewPackageId: number;
  snippetSrc: string;
  changeCallback: (status: ReviewFileUserStatus) => void;
  navToFileCallback: (src: string) => void;

  // Assigned at initialization
  review$: Observable<{ file: ReviewFile, user: ReviewFileUser }>;
  reviewFile: ReviewFile;
  reviewFileUser$: Observable<ReviewFileUser>;
  loadingState: LoadingState<string> = new LoadingState();
  reload$: BehaviorSubject<void> = new BehaviorSubject(undefined);

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ReviewEditSnippetDialogData,
    protected dialogRef: MatDialogRef<ReviewEditSnippetDialogComponent>,
    private errorService: ErrorService,
    private reviewFilesService: ReviewFilesService,
    private reviewFileUsersService: ReviewFileUsersService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    // Fill in dialog data
    this.featureSetVersion = this.data.featureSetVersion;
    this.reviewer = this.data.reviewer;
    this.reviewPackageId = this.data.reviewPackageId;
    this.snippetSrc = this.data.snippetSrc;
    this.changeCallback = this.data.changeCallback;
    this.navToFileCallback = this.data.navToFileCallback;

    this.review$ = this.reload$.pipe(
      switchMap(() => {
        this.loadingState.update(true);
        return this.reviewFilesService.getItemByProperties$('ReviewPackageId', this.reviewPackageId, 'Path', this.snippetSrc).pipe(
          switchMap(reviewFile => {
            if (!this.reviewFile) {
              throwError(() => new Error('Unable to find snippet.'));
            }
            return this.reviewFileUsersService.getMyReviewFileUserByUserId$(this.reviewer.User.Id, reviewFile.Id).pipe(
              switchMap(reviewFileUser => {
                return of({ file: reviewFile, user: reviewFileUser });
              }),
              tap(_ => {
                this.loadingState.update(false);
              })
            );
          })
        );
      }),
      catchError(error => {
        this.loadingState.update(false, 'Unable to load the snippet file.', this.errorService.getErrorMessages(error));
        return of(null);
      })
    );


    // TODO: Needs to close when navigation changes (like selecting the package overview avatar)
  }

  onReviewerStatusUpdated(status: ReviewFileUserStatus) {
    this.changeCallback(status);
  }

  onRetryLoadFile() {
    this.reload$.next();
  }

  protected onNavToFile(src: string) {
    this.closeDialog();
    this.navToFileCallback(src);
  }
}

