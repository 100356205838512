import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { html } from 'diff2html';
import { OutputFormatType } from 'diff2html/lib/types';
import { Commit } from '@portal-core/commits/models/commit.model';
import { Diff } from '@portal-core/commits/models/diff.model';
import { CommitsApiService } from '@portal-core/commits/services/commits-api.service';
import { CommitsDataService } from '@portal-core/commits/services/commits-data.service';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CommitAction } from '@portal-core/commits/models/commit-action.model';
import { GitCommitWithCaseCorrectedPaths } from '@portal-core/project-files/models/git-commit-with-case-corrected-paths.model';
@Injectable({
  providedIn: 'root'
})
@Resettable()
export class CommitsService extends CollectionServiceBase<Commit> {
  constructor(private commitsDataService: CommitsDataService, private commitsApiService: CommitsApiService, protected dataService: DataService) {
    super(commitsDataService, dataService);
  }

  getInitials(name: string) {
    name = (name || '').replace(/\W*(\w)\w*/g, '$1').toUpperCase();
    return (name.length > 0 ? name[0] : '') +
    (name.length > 1 ? name[name.length - 1] : '');
  }

  grabDiffDOM$(data: string, view: string): Observable<string> {
    return new Observable((observer) => {
      observer.next(html(data, {drawFileList: false, outputFormat : view as OutputFormatType}));
    });
  }

  getFileCommitDiff$(projectId: number, commitId: string , filePath: string ): Observable<Diff> {
    return this.commitsApiService.getFileCommitDiff$(projectId, commitId, filePath);
  }

  getProjectCommits$(projectId: number, branchName: string, filter: PageFilter, includeAllUsers: boolean = true): Observable<Page<Commit>> {
    return this.commitsApiService.getProjectCommits$(projectId, branchName, includeAllUsers, filter);
  }

  getFileCommits$( projectId: number , branchName: string, path: string, filter: PageFilter): Observable<Page<Commit>> {
      return this.commitsApiService.getFileCommits$(projectId, branchName, path, filter);
  }

  getDuplicateFilePaths$(projectId: number, branchName: string, filePaths: string[]): Observable<string[]> {
    return this.commitsApiService.getDuplicateFilePaths$(projectId, branchName, filePaths);
  }

  postNewCommit$(projectId: number , branchName: string , commitActions : CommitAction[] , commit: string, email: string, fullName : string ): Observable<GitCommitWithCaseCorrectedPaths> {
    return this.commitsApiService.postNewCommit$(projectId, branchName, commitActions, commit, email, fullName);
  }
}
