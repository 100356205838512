<div class="mc-comment-box-container">
  <mat-form-field color="primary-vivid">
    <textarea #textArea class="mc-comment-box-input" matInput rows="{{isNewComment === false? 1 : reduceSize ? 2 : 3}}" cols="40" [(ngModel)]="comment.Comment"
      placeholder="{{isReplyText ? 'Add reply' : isNewComment ? 'Add new comment' : ''}}" [disabled]="!canEditAllTasks" (input)="onInputChanged()"></textarea>
  </mat-form-field>
  <label>
    <input class="mc-hidden" multiple="multiple" type="file" (change)="onAddAttachmentsChanged($event)">
    <mat-icon *ngIf="canEditAllTasks" class="mc-comment-box-icon mc-link" [fontSet]="'project-titan'" [fontIcon]="'icon-attach'"></mat-icon>
  </label>
</div>
<div [ngClass]="{'mc-comment-box-assets-container': isNewComment}">
  <mc-task-asset *ngFor="let commentAsset of comment.TaskAssets" [asset]="commentAsset" (delete)="onAssetDelete($event)" [canEditAllTasks]="canEditAllTasks"></mc-task-asset>
</div>
