import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Target } from '@portal-core/targets/models/target.model';
import { TargetsService } from '@portal-core/targets/services/targets.service';

@Pipe({
  name: 'mcTargetAsync'
})
export class TargetAsyncPipe implements PipeTransform {
  constructor(private targetsService: TargetsService) {}

  transform(targetId: number | null | undefined, allowApiRequest: boolean = false): Observable<Target | null> {
    if (!targetId) {
      return of(null);
    }

    return this.targetsService.getItemById$(targetId, {
      allowApiRequest
    });
  }
}
