<form class="mc-spring-board" (ngSubmit)="onSubmit()">
  <mat-tab-group [selectedIndex]="selectedTabIndex" class="mc-spring-board-form-body mc-arrow-tabs mc-arrow-tabs-border">
    <mat-tab label="Users">
      <ng-container *ngIf="!editing && teamLicenseUsers">
        <mat-list *ngIf="teamLicenseUsers.length > 0; else noLicenseUsers">
          <mat-list-item *ngFor="let licenseUser of teamLicenseUsers">
            <mc-license-user-avatar [licenseUser]="licenseUser.Id | mcLicenseUserAsync | async" size="small" [truncate]="true" [showSeatType]="true"></mc-license-user-avatar>
          </mat-list-item>
        </mat-list>
        <ng-template #noLicenseUsers>
          <mc-no-item-placeholder [message]="'This team is not assigned any users.'"></mc-no-item-placeholder>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="editing">
        <mc-selection-list-header [avatarSpacing]="true" [selectionListItems]="allLicenseUsers"></mc-selection-list-header>
        <mat-selection-list class="mat-primary-vivid" #usersList>
          <mat-list-option *ngFor="let licenseUser of allLicenseUsers" checkboxPosition="before" [value]="licenseUser?.Value" [selected]="licenseUser?.Selected" (click)="licenseUser.Selected = !licenseUser.Selected">
            <mc-license-user-avatar [licenseUser]="licenseUser.LicenseUser.Id | mcLicenseUserAsync | async" size="small" [truncate]="true" [disabled]="true" [showSeatType]="true"></mc-license-user-avatar>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>
    </mat-tab>

    <mat-tab label="Projects">
      <ng-container *ngIf="!editing && teamProjects">
        <mat-list *ngIf="teamProjects.length > 0; else noProjects" #projectsList>
          <mat-list-item *ngFor="let teamProject of teamProjects">
            <mc-project-avatar [project]="teamProject.Id | mcProjectAsync | async" size="small" [truncate]="true"></mc-project-avatar>
          </mat-list-item>
        </mat-list>
        <ng-template #noProjects>
          <mc-no-item-placeholder [message]="'This team is not assigned to any projects.'"></mc-no-item-placeholder>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="editing">
        <mc-selection-list-header [avatarSpacing]="true" [selectionListItems]="allLicenseProjects"></mc-selection-list-header>
        <mat-selection-list class="mat-primary-vivid" #projectsList>
          <ng-container *ngFor="let projectItem of allLicenseProjects">
            <div matTooltip="You cannot revoke a team's access from an archived project. You must unarchive the project first, then revoke the team's access." matTooltipClass="mc-tooltip-snug" [matTooltipDisabled]="projectItem.Project.Status !== ProjectStatus.Archived">
              <mat-list-option checkboxPosition="before" [disabled]="projectItem.Project.Status === ProjectStatus.Archived" [selected]="projectItem?.Selected" [value]="projectItem?.Value" (click)="projectItem.Selected = !projectItem.Selected">
                <mc-project-avatar [project]="projectItem.Project.Id | mcProjectAsync | async" size="small" [truncate]="true" [disabled]="true"></mc-project-avatar>
              </mat-list-option>
            </div>
          </ng-container>
        </mat-selection-list>
      </ng-container>
    </mat-tab>

    <mat-tab label="Sites">
      <ng-container *ngIf="!editing && teamSites">
        <mat-list *ngIf="teamSites.length > 0; else noSites" #sitesList>
          <mat-list-item *ngFor="let teamSite of teamSites">
            <mc-site-avatar [site]="teamSite.Id | mcSiteAsync | async" size="small" [truncate]="true"></mc-site-avatar>
          </mat-list-item>
        </mat-list>
        <ng-template #noSites>
          <mc-no-item-placeholder [message]="'This team is not assigned to any sites.'"></mc-no-item-placeholder>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="editing">
        <mc-selection-list-header [selectionListItems]="allLicenseSites"></mc-selection-list-header>
        <mat-selection-list class="mat-primary-vivid" #sitesList>
          <mat-list-option *ngFor="let site of allLicenseSites" checkboxPosition="before" [value]="site?.Value" [selected]="site?.Selected" (click)="site.Selected = !site.Selected">
            <mc-site-avatar [disabled]="true" [site]="site.Site.Id | mcSiteAsync | async" size="small" [truncate]="true"></mc-site-avatar>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <div class="mc-spring-board-form-footer">
    <button *ngIf="!editing && (canEdit$ | async)" type="button" mat-raised-button color="primary" (click)="onEditClicked()">Edit</button>

    <mc-errors-expansion-panel *ngIf="savingState.generalError$ | async as generalError" compact [generalError]="generalError" [detailedErrors]="savingState.detailedErrors$ | async"></mc-errors-expansion-panel>

    <div *ngIf="editing" class="mc-form-buttons">
      <button type="button" mat-stroked-button (click)="onCancelClicked()">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="savingState.loading$ | async">Save</button>
    </div>
  </div>

  <!-- Loading State -->
  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>

  <!-- Saving State -->
  <mc-loader *ngIf="savingState.loading$ | async" class="mc-loader-semi-transparent"></mc-loader>
</form>

<!-- loading State Error -->
<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
</div>