import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { ProseMirrorNode } from 'prosemirror-model';

export class MadCapCentralCommentSchemaPlugin extends SchemaPlugin {
  constructor() {
    super();

    this.nodes = {
      madcapcentralcomment: {
        group: 'inline',
        inline: true,
        attrs: {
          content: { default: undefined, skipExport: true }
        },
        parseDOM: [{
          tag: 'MadCap\\:centralComment',
          getAttrs(dom: HTMLElement) {
            return {
              content: dom.textContent
            };
          }
        }],
        toDOM() { return ['span', { class: 'mc-madcap-central-comment icon-star' }]; },
        toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
          if (options.exportForClipboard) {
            return nodeToFlareXML(node, options, null, {
              xml: `<MadCap:centralComment>${node.attrs.content}</MadCap:centralComment>`
            });
          } else {
            return nodeToFlareXML(node, options, null, {
              xml: `<!--${node.attrs.content}-->`
            });
          }
        },
        toDisplayName() { return '#comment'; },
        specSkip: true
      }
    };

    // For backwards compatibility
    this.nodes.madcapcentralcommentblock = this.nodes.madcapcentralcomment;
    this.nodes.madcapcentralcommentinline = this.nodes.madcapcentralcomment;
  }
}
