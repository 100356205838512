export enum AuditTargetPageType {
  HomeDashboardPage = 0,
  ProjectsPage = 1,
  ProjectDashboardPage = 2,
  ProjectBuildsPage = 3,
  TasksPage = 4,
  TeamsPage = 5,
  UsersPage = 6,
  LicenseDashboardPage = 7,
  LicensesPage = 8,
  BroadcastsPage = 9,
  ProjectChecklistsPage = 10,
  ProjectReportsPage = 11,
  ReviewsPage = 12,
  SitesPage = 13
}
