import { Mark, MarkType, ProseMirrorNode } from 'prosemirror-model';

export declare type MarkCallback = (mark: Mark, node: ProseMirrorNode, pos: number, size: number) => boolean;

/**
 * Information about a mark found in a ProseMirror document.
 */
export interface FoundMarkInfo {
  /** The mark. */
  mark: Mark;
  /** The node that contains the mark. */
  node: ProseMirrorNode;
  /** The position of the mark relative to its non-text ancestor node. */
  pos: number;
  /** The size of the mark's content. */
  size: number;
}

// Returns a mark of the type `markType` if it exists on `node`. Optionally pass in an attribute name to return the attribute value on the mark instead of the mark
export function getMark<T = Mark>(node: ProseMirrorNode, markType: MarkType, attr?: string): T {
  const foundMark = Array.isArray(node.marks) ? node.marks.find(mark => {
    return mark.type === markType;
  }) : null;

  if (foundMark) {
    return attr ? foundMark.attrs[attr] : foundMark;
  }
}

// Finds a mark in the descendants of the given node. It does not search the given node's marks
export function findMark(node: ProseMirrorNode, predicate: MarkCallback): FoundMarkInfo {
  let foundData: FoundMarkInfo = null;

  // Loop through the node's descendants
  node.descendants((child: ProseMirrorNode, pos: number) => {
    if (Array.isArray(child.marks)) {
      // Loop through the node's marks and call the predicate on each mark
      child.marks.find(mark => {
        if (predicate(mark, child, pos, child.nodeSize)) {
          foundData = {
            node: child,
            mark: mark,
            pos: pos,
            size: child.nodeSize
          };

          return true;
        }
      });
    }

    if (foundData) {
      return false; // break the descendants loop
    }
  });

  return foundData;
}

/**
 * Returns all the marks in the descendants of the given node that the predicate returns truthy for.
 * @param node The node to search.
 * @param predicate A function that returns whether or not the mark should be filtered.
 * @returns An array of marks.
 */
export function filterMarks(node: ProseMirrorNode, predicate: MarkCallback, nodeStart?: number): FoundMarkInfo[] {
  const filtered: FoundMarkInfo[] = [];

  // Loop through the node's descendants
  node.descendants((child: ProseMirrorNode, pos: number) => {
    let nodeEnd: number;
    if (typeof nodeStart === 'number') {
      nodeStart += pos;
      nodeEnd = nodeStart + child.nodeSize;
    }

    if (Array.isArray(child.marks)) {
      // Loop through the node's marks and call the predicate on each mark
      child.marks.forEach(mark => {
        if (predicate(mark, child, pos, child.nodeSize)) {
          filtered.push({
            node: child,
            mark: mark,
            pos: pos,
            size: child.nodeSize
          });
        }
      });
    }
  });

  return filtered;
}

/**
 * Returns all the marks in the range that the predicate returns truthy for.
 * @param from The absolute position of the start of the range.
 * @param to The absolute position of the end of the range.
 * @param doc The ProseMirror doc to search through.
 * @param predicate A function that returns whether or not the mark should be filtered.
 * @returns An array of marks.
 */
export function filterMarksInRange(from: number, to: number, doc: ProseMirrorNode, predicate: MarkCallback): FoundMarkInfo[] {
  const filtered: FoundMarkInfo[] = [];

  doc.nodesBetween(from, to, (node: ProseMirrorNode, pos: number, parent: ProseMirrorNode) => {
    if (Array.isArray(node.marks)) {
      // Loop through the node's marks and call the predicate on each mark
      node.marks.forEach(mark => {
        if (predicate(mark, node, pos, node.nodeSize)) {
          filtered.push({
            node,
            mark: mark,
            pos: pos,
            size: node.nodeSize
          });
        }
      });
    }
  });

  return filtered;
}


// Loops through all the descendant marks in the given node. Iteratee function may exit iteration early by explicitly returning false.
export function forEachMark(node: ProseMirrorNode, iteratee: MarkCallback) {
  let breakLoop = false;

  // Loop through the node's descendants
  node.descendants((child: ProseMirrorNode, pos: number) => {
    if (Array.isArray(child.marks)) {
      // Loop through the node's marks and call the iteratee on each mark
      child.marks.every(mark => {
        if (iteratee(mark, child, pos, child.nodeSize) === false) {
          breakLoop = true;
          return false;
        }

        return true;
      });
    }

    if (breakLoop) {
      return false; // break the descendants loop
    }
  });
}
