import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyPseudoCheckboxModule as MatPseudoCheckboxModule, MatLegacyRippleModule as MatRippleModule } from '@angular/material/legacy-core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { NoItemPlaceholderModule } from '@portal-core/general/components/no-item-placeholder/no-item-placeholder.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamAsyncModule } from '@portal-core/teams/pipes/team-async/team-async.module';
import { UserAccessFormComponent } from './user-access-form.component';

@NgModule({
  declarations: [UserAccessFormComponent],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    NoItemPlaceholderModule,
    ProjectAvatarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatPseudoCheckboxModule,
    MatRippleModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    StopPropagationModule,
    TeamAvatarModule,
    ProjectAsyncModule,
    TeamAsyncModule
  ],
  exports: [UserAccessFormComponent]
})
export class UserAccessFormModule { }
