import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorCodeExpansionPanelModule } from '@portal-core/errors/components/error-code-expansion-panel/error-code-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ProjectFilesTreeModule } from '@portal-core/project-files/components/project-files-tree/project-files-tree.module';
import { SoloFileTextEditorModule } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.module';
import { ProjectFilesTreeItemPreviewComponent } from './project-files-tree-item-preview.component';

@NgModule({
  declarations: [
    ProjectFilesTreeItemPreviewComponent,
  ],
  imports: [
    CommonModule,
    ProjectFilesTreeModule,
    SoloFileTextEditorModule,
    LoaderModule,
    ErrorCodeExpansionPanelModule
  ],
  exports: [
    ProjectFilesTreeItemPreviewComponent
  ]
})
export class ProjectFilesTreeItemPreviewModule { }
