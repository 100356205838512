export const MadCapVoidTags: string[] = [
  'MadCap:bodyProxy',
  'MadCap:breadcrumbsProxy',
  'MadCap:centralAccountProxy',
  'MadCap:centralComment',
  'MadCap:centralNbsp',
  'MadCap:codeSnippetCopyButton',
  'MadCap:concept',
  'MadCap:conceptsProxy',
  'MadCap:conceptLink',
  'MadCap:endnotesProxy',
  'MadCap:eLearningToolbarProxy',
  'MadCap:file',
  'MadCap:glossaryProxy',
  'MadCap:indexProxy',
  'MadCap:keyword',
  'MadCap:keywordLink',
  'MadCap:listOfProxy',
  'MadCap:menuProxy',
  'MadCap:metaTag',
  'MadCap:miniTocProxy',
  'MadCap:model3D',
  'MadCap:namedDestination',
  'MadCap:pageBreak',
  'MadCap:relatedTopic',
  'MadCap:relationshipsProxy',
  'MadCap:searchBarProxy',
  'MadCap:searchResultsProxy',
  'MadCap:snippetBlock',
  'MadCap:snippetText',
  'MadCap:submitQuestionButton',
  'MadCap:tocProxy',
  'MadCap:topicToolbarProxy',
  'MadCap:testResultsProxy',
  'MadCap:variable'
];
