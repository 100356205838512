import { environment } from '@env/environment';
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';

export class DataStreamSignalRConnection {
  public accessToken: string;
  public hubConnection: signalR.HubConnection;
  public hubDisconnected$: Observable<void>;
  public hubReconnected$: Observable<void>;

  private hubDisconnectedSource: Subject<void>;
  private hubEventNames: string[];
  private hubReconnectedSource: Subject<void>;
  private stopped: boolean = false;

  /**
   * Initializes a new instance of the DataStreamSignalRConnection class.
   * The start method must be called to start the connection.
   * @param centralApiBaseUri The base URI to connect to signalR with.
   * @param accessToken The user's auth token.
   */
  constructor(centralApiBaseUri: string, accessToken: string) {
    this.accessToken = accessToken;
    this.hubEventNames = [];
    this.hubDisconnectedSource = new Subject<void>();
    this.hubReconnectedSource = new Subject<void>();
    this.hubDisconnected$ = this.hubDisconnectedSource.asObservable();
    this.hubReconnected$ = this.hubReconnectedSource.asObservable();

    // Build the connection
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(centralApiBaseUri + '/signalr', { accessTokenFactory: () => this.accessToken })
      .configureLogging(environment.signalrLoggingEnabled ? LogLevel.Information : LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    // Listen to connection events
    this.hubConnection.onclose(() => this.onHubDisconnected());
    this.hubConnection.onreconnected(() => this.onHubReconnected());
  }

  /**
   * Starts up the signalR connection.
   */
  public start(): Promise<void> {
    this.log('SignalR starting connection');

    // Start the connection
    return this.hubConnection.start().then(() => {
      this.log('SignalR connected');
    }).catch(error => {
      this.logError('SignalR start error', error);
      throw error;
    });
  }

  /**
   * Stops the connection and stops listening to events from it.
   */
  public stop() {
    this.stopped = true;
    this.hubDisconnectedSource.complete();
    this.hubReconnectedSource.complete();

    // If there is an open connection then clean it up and stop it
    if (this.hubConnection) {
      this.hubEventNames.forEach(name => this.hubConnection.off(name));

      this.hubConnection.stop().catch(error => {
        this.logError('SignalR stop error', error);
      });
    }

    // Clear the hub state
    this.hubEventNames = null;
    this.hubConnection = null;
  }

  /**
   * Invokes a hub method on the server using the specified name and arguments.
   *
   * The Promise returned by this method resolves when the server indicates it has finished invoking the method.
   * When the promise resolves, the server has finished invoking the method.
   * If the server method returns a result, it is produced as the result of resolving the Promise.
   *
   * @param {string} methodName The name of the server method to invoke.
   * @param {any[]} args The arguments used to invoke the server method.
   * @returns {Promise<T>} A Promise that resolves with the result of the server method (if any), or rejects with an error.
   */
  public invoke<T = any>(methodName: string, ...args: any[]): Promise<T> {
    return this.hubConnection.invoke(methodName, ...args).catch(error => {
      this.logError(`SignalR invoke ${methodName} error`, error);
    });
  }

  /**
   * Listens to an event from the server and invokes a callback when the event occurs.
   * Keeps track of all the events subscribed to so they can be unsubscribed from when the connection is stopped.
   */
  public on(name: string, callback: (...args: any[]) => void) {
    this.hubEventNames.push(name);
    this.hubConnection.on(name, callback);
  }

  private onHubDisconnected() {
    this.log('SignalR disconnected');

    if (!this.stopped) {
      this.hubDisconnectedSource.next();
    }
  }

  private onHubReconnected() {
    this.log('SignalR reconnected');

    if (!this.stopped && this.hubConnection.state === HubConnectionState.Connected) {
      this.hubReconnectedSource.next();
    }
  }

  private log(message?: any, ...optionalParams: any[]) {
    if (environment.signalrLoggingEnabled) {
      console.log(message, ...optionalParams);
    }
  }

  private logError(message?: any, ...optionalParams: any[]) {
    if (environment.signalrLoggingEnabled) {
      console.error(message, ...optionalParams);
    }
  }
}
