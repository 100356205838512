import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { GridComponentModule } from '@portal-core/ui/grid/components/grid/grid.module';
import { GridCellModule } from '@portal-core/ui/grid/directives/grid-cell/grid-cell.module';
import { VariablesGridComponent } from './variables-grid.component';

@NgModule({
  declarations: [
    VariablesGridComponent,
  ],
  imports: [
    CommonModule,
    GridCellModule,
    GridComponentModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  exports: [
    VariablesGridComponent
  ]
})
export class VariablesGridModule { }
