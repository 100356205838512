<mc-switch-board color="secondary-vivid">
  <mc-switch-board-bar class="mc-spring-board">
    <div class="mc-spring-board-header">
      <mc-team-avatar [team]="team$ | async" [showName]="false" [disabled]="true" size="large"></mc-team-avatar>
    </div>

    <div class="mc-spring-board-body">
      <ng-container *ngIf="team$ | async as team">
        <h3 class="text-x-large">{{team.Name}}</h3>
        <p class="text-light-weight">{{team.Description}}</p>
      </ng-container>
    </div>

    <mat-nav-list class="mc-spring-board-footer mc-list-text-size-normal mc-list-text-weight-normal mc-list-bg-dark mc-list-compact">
      <a href="#" *ngIf="showSettingsTab$ | async" mat-list-item [mcProfileTab]="TeamProfileTab.Settings" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-gear'" color="primary"></mat-icon> Settings
      </a>
      <a href="#" *ngIf="showAccessTab$ | async" mat-list-item [mcProfileTab]="TeamProfileTab.Access" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-assign-task'" color="primary"></mat-icon> Access
      </a>
      <a href="#" *ngIf="showActivityTab$ | async" mat-list-item [mcProfileTab]="TeamProfileTab.Activity" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-clock'" color="primary"></mat-icon> Activity
      </a>
      <a href="#" *ngIf="showPermissionsTab$ | async" mat-list-item [mcProfileTab]="TeamProfileTab.Permissions" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-list'" color="primary"></mat-icon> Permissions
      </a>
      <a href="#" *ngIf="showDeleteTab$ | async" mat-list-item [mcProfileTab]="TeamProfileTab.Delete" [(mcProfileTabSelected)]="profileTab">
        <mat-icon matListIcon [fontSet]="'project-titan'" [fontIcon]="'icon-trash'" color="primary"></mat-icon> Delete
      </a>
    </mat-nav-list>
  </mc-switch-board-bar>

  <mc-switch-board-content [ngSwitch]="profileTab" class="mc-spring-board">
    <mat-tab-group *ngSwitchCase="TeamProfileTab.Settings" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Settings">
        <mc-team-settings-form [team]="team$ | async" (saved)="onSettingsSaved()" (cancel)="onCancel()"></mc-team-settings-form>
      </mat-tab>
    </mat-tab-group>

    <ng-container *ngSwitchCase="TeamProfileTab.Access">
      <mc-team-access-form class="mc-spring-board-body" [team]="team$ | async" [selectedTabIndex]="teamAccessFormTab" [teamProjects]="teamProjects$ | async" [teamLicenseUsers]="teamLicenseUsers$ | async" [teamSites]="teamSites$ | async" (saved)="onTeamAccessSaved()"></mc-team-access-form>
    </ng-container>

    <mat-tab-group *ngSwitchCase="TeamProfileTab.Activity" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Activity">
        <mc-team-notification-list [teamId]="(team$ | async).Id"></mc-team-notification-list>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="TeamProfileTab.Permissions" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Permissions">
        <mc-permissions-form [licenseId]="teamProfile?.Team?.LicenseId" [entityId]="teamProfile?.Team?.Id" [permissionsFormControl]="permissionsFormControl" [projectIds]="teamProjectIdsNotArchived$ | async" [readonly]="!(userCanManageTeams$ | async)" (cancel)="onCancel()"></mc-permissions-form>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngSwitchCase="TeamProfileTab.Delete" class="mc-spring-board-body mc-arrow-tabs mc-arrow-tabs-border">
      <mat-tab label="Delete">
        <mc-team-deletion-form [team]="team$ | async" (deleted)="onTeamDeleted()" (cancel)="onCancel()"></mc-team-deletion-form>
      </mat-tab>
    </mat-tab-group>
  </mc-switch-board-content>
</mc-switch-board>