import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusIconPipe } from './review-file-user-status-icon.pipe';

@NgModule({
  declarations: [ReviewFileUserStatusIconPipe],
  imports: [CommonModule],
  exports: [ReviewFileUserStatusIconPipe]
})
export class ReviewFileUserStatusIconModule { }
