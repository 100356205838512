/**
 * Get the value of a property from an object.
 * If the property value is a function then the function is called and its result is returned.
 * If the property value is undefined then the default value is returned.
 * @param obj The object to get the property value from.
 * @param key The key of the property to get the value of.
 * @param defaultValue The default value to return if the property value is undefined.
 * @returns The value of the property or the default value if the property value is undefined.
 */
export function propertyValue<T, R = T[keyof T]>(obj: T, key: keyof T, defaultValue?: R): R {
  const value = obj?.[key];

  if (typeof value === 'function') {
    return value.call(obj);
  } else if (typeof value !== 'undefined') {
    return value as R;
  } else {
    return defaultValue;
  }
}
