import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { WebhookSubscribersState } from '@portal-core/slack/services/slack-webhooks-subscribers-data.service';
import { WebhookChannelsState } from '@portal-core/slack/services/slack-webhooks-channels-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([WebhookSubscribersState, WebhookChannelsState])
  ]
})
export class SlackWebhooksServicesModule { }
