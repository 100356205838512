import { Injectable } from '@angular/core';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { ProjectConditionSet } from '@portal-core/project-files/conditions/models/project-condition-set.model';
import { ProjectConditionTag } from '@portal-core/project-files/conditions/models/project-condition-tag.model';
import { CommittedItem } from '@portal-core/project-files/models/committed-item.model';
import { MetaDataApiService } from '@portal-core/project-files/services/meta-data-api.service';
import { Skin } from '@portal-core/project-files/models/skin.model';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { Observable, map } from 'rxjs';
import { MetaDataGroup } from '@portal-core/project-files/enums/meta-data-group.enum';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  constructor(private metaDataApiService: MetaDataApiService) { }

  getMetaData$(groupNames: string[], committedItem: CommittedItem): Observable<MetaDataValues> {
    return this.metaDataApiService.getMetaData$(groupNames, committedItem).pipe(
      // If no metadata generated yet return null.
      map(data => data.metaData ? data : null)
    );
  }

  getProjectVariableSets$(committedItem: CommittedItem): Observable<ProjectVariableSet[]> {
    return this.getMetaData$([MetaDataGroup.Variables], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractVariableSetsFromMetaData(data.metaData) : null)
    );
  }

  extractVariableSetsFromMetaData(metaData: Dictionary<Dictionary>): ProjectVariableSet[] {
    const variablesMetaData = metaData[MetaDataGroup.Variables];
    if (!variablesMetaData) {
      return;
    }

    const setDict: Dictionary = {};
    const variableSets: ProjectVariableSet[] = [];

    for (const key of Object.keys(variablesMetaData)) {
      const metaDataVariable = variablesMetaData[key];
      let set: ProjectVariableSet = setDict[metaDataVariable.set];
      if (!set) {
        set = setDict[metaDataVariable.set] = { Name: metaDataVariable.set, Definitions: [] };
        variableSets.push(set);
      }
      const definitions = [metaDataVariable].concat(Object.values(metaDataVariable.definitions ?? []))
        .map(item => { return { 'Definition': item.definition, 'Comment': item.comment } });
      set.Definitions.push({
        SetName: metaDataVariable.set,
        Name: metaDataVariable.name,
        LongName: key,
        Definition: metaDataVariable.definition,
        Comment: metaDataVariable.comment,
        AllDefinitions: definitions
      });
    };

    return variableSets;
  }

  getProjectConditionSets$(committedItem: CommittedItem): Observable<ProjectConditionSet[]> {
    return this.getMetaData$([MetaDataGroup.Conditions], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractConditionSetsFromMetaData(data.metaData) : null)
    );
  }

  extractConditionSetsFromMetaData(metaData): ProjectConditionSet[] {
    const conditionsTags = this.extractConditionTagsFromMetaData(metaData);
    if (!conditionsTags) {
      return;
    }

    const setDict = {};
    const conditionSets: ProjectConditionSet[] = [];

    conditionsTags.forEach(tag => {
      const setName = tag.Set;
      let set: ProjectConditionSet = setDict[setName];
      if (!set) {
        set = setDict[setName] = { Name: setName, ConditionTags: [] };
        conditionSets.push(set);
      }
      set.ConditionTags.push(tag);
    });

    return conditionSets;
  }

  extractConditionTagsFromMetaData(metaData): ProjectConditionTag[] {
    const conditionsMetaData = metaData[MetaDataGroup.Conditions];
    if (!conditionsMetaData) {
      return;
    }

    const tags: ProjectConditionTag[] = [];
    for (const key of Object.keys(conditionsMetaData)) {
      const tag = conditionsMetaData[key];
      tags.push({ Id: tag.longName, Set: tag.set, Name: tag.name, BackgroundColor: tag.backgroundColor, Comment: tag.comment });
    };
    return tags;
  }

  getProjectSkins$(committedItem: CommittedItem, componentType?: string): Observable<Skin[]> {
    return this.getMetaData$([MetaDataGroup.Skins], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractSkinsFromMetaData(data.metaData, componentType) : null)
    );
  }

  extractSkinsFromMetaData(metaData, componentType?: string): Skin[] {
    const skinsMetaData = metaData[MetaDataGroup.Skins];
    if (!skinsMetaData) {
      return;
    }

    const skins: Skin[] = [];
    for (const key of Object.keys(skinsMetaData)) {
      const skin = skinsMetaData[key];
      if (!componentType || (skin.skinType === 'Component' && skin.componentType === componentType)) {
        skins.push({ FilePath: key, Version: skin.version, SkinType: skin.skinType, ComponentType: skin.componentType, Comment: skin.comment, EnableResponsiveOutput: skin.enableResponsiveOutput });
      }
    };
    return skins;
  }

  getProjectTOCs$(committedItem: CommittedItem): Observable<string[]> {
    return this.getMetaData$([MetaDataGroup.TOCs], committedItem).pipe(
      // If no data then return null. It means that metadata is not generated yet.
      map(data => data ? this.extractTOCsFromMetaData(data.metaData) : null)
    );
  }

  extractTOCsFromMetaData(metaData): string[] {
    const tocsMetaData = metaData[MetaDataGroup.TOCs];
    if (!tocsMetaData) {
      return;
    }

    return Object.keys(tocsMetaData).map(key => tocsMetaData[key]);
  }
}
