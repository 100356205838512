import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ModelId } from '@portal-core/data/common/types/mc-model.type';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseAdminSettings } from '@portal-core/licenses/models/license-admin-settings.model';
import { ContactInfo } from '@portal-core/licenses/models/license-contact-info.model';
import { LicenseCreateRequest } from '@portal-core/licenses/models/license-create-request.model';
import { LicenseSamlAuthenticationSettings } from '@portal-core/licenses/models/license-saml-authentication-settings.model';
import { LicenseSeatCounts } from '@portal-core/licenses/models/license-seat-counts.model';
import { SecuritySettings } from '@portal-core/licenses/models/license-security-settings.model';
import { LicenseSettings } from '@portal-core/licenses/models/license-settings.model';
import { SubscriptionAddition } from '@portal-core/licenses/models/license-subscription-addition.model';
import { SubscriptionOrderItem } from '@portal-core/licenses/models/license-subscription-order-item.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesSizeStorage } from '@portal-core/licenses/models/licenses-size-storage.model';
import { LicensesApiService } from '@portal-core/licenses/services/licenses-api.service';
import { LicensesDataService } from '@portal-core/licenses/services/licenses-data.service';
import { UserBulkResponse } from '@portal-core/users/models/user-bulk-response.model';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class LicensesService extends CollectionServiceBase<License> {
  constructor(
    private licensesApiService: LicensesApiService,
    private licensesDataService: LicensesDataService,
    private licenseStorageService: LicenseStorageService,
    protected dataService: DataService
  ) {
    super(licensesDataService, dataService);
  }

  protected fetchItemById$(itemId: ModelId): Observable<License> {
    return this.licensesApiService.getLicenseById$(itemId);
  }

  createLicense$(licenseCreateRequest: LicenseCreateRequest): Observable<License> {
    return this.licensesApiService.createLicense$(licenseCreateRequest);
  }

  getLicensesForCurrentUser$(): Observable<License[]> {
    return this.licensesApiService.getLicensesForCurrentUser$().pipe(
      tap(licenses => this.addItems$(licenses))
    );
  }

  getLicenseDefaultTeams$(licenseId: number): Observable<number[]> {
    return this.licensesApiService.getLicenseDefaultTeams$(licenseId);
  }

  getLicenseByQuery$(filter: PageFilter): Observable<Page> {
    return this.licensesApiService.getLicensesByQuery$(filter);
  }

  verifyBulkUserCsvFile$(licenseId: number, seatType: number, csvFile: FormData): Observable<UserBulkResponse> {
    return this.licensesApiService.verifyBulkUserCsvFile$(licenseId, seatType, csvFile);
  }

  setActivationStatus$(licenseId: number, status: number): Observable<any> {
    return this.licensesApiService.setActivationStatus$(licenseId, status).pipe(
      tap(() => this.updateItems$({ [licenseId]: { LicenseActivationStatus: status } }))
    );
  }

  changeMaxCountOfSeats$(licenseId: number, licensesSeats: LicenseSeatCounts): Observable<MadCloudResult> {
    return this.licensesApiService.changeMaxCountOfSeats$(licenseId, licensesSeats);
  }

  changeMaxStorageSize$(licenseId: number, licenseSize: LicensesSizeStorage): Observable<MadCloudResult> {
    return this.licensesApiService.changeMaxStorageSize$(licenseId, licenseSize);
  }

  changeAdminLicenseSettings$(licenseId: number, licenseData: LicenseAdminSettings, avatar: File): Observable<MadCloudResult> {
    return this.licensesApiService.changeAdminLicenseSettings$(licenseId, licenseData, avatar).pipe(
      tap(() => {
        this.fetchItemById$(licenseId).subscribe(updatedLicense => this.updateItems$([updatedLicense]));
        this.licenseStorageService.refreshItemById(licenseId);
      })
    );
  }

  changeLicenseSettings$(licenseId: number, licenseData: LicenseSettings, avatar: File): Observable<MadCloudResult> {
    return this.licensesApiService.changeLicenseSettings$(licenseId, licenseData, avatar).pipe(
      tap(() => {
        this.fetchItemById$(licenseId).subscribe(updatedLicense => this.updateItems$({
          [licenseId]: { KeyLabel: updatedLicense.KeyLabel, Avatar: updatedLicense.Avatar, VanityBaseUrl: updatedLicense.VanityBaseUrl }
        }));
      })
    );
  }

  changeVanityUrl$(licenseId: number, vanityUrl: string): Observable<MadCloudResult> {
    return this.licensesApiService.changeVanityUrl$(licenseId, vanityUrl).pipe(
      tap(() => {
        this.fetchItemById$(licenseId).subscribe(updatedLicense => this.updateItems$({
          [updatedLicense.Id]: { VanityBaseUrl: updatedLicense.VanityBaseUrl }
        }));
      })
    );
  }

  changeSecuritySettings$(licenseId: number, securitySettings: SecuritySettings): Observable<any> {
    return this.licensesApiService.changeSecuritySettings$(licenseId, securitySettings).pipe(
      tap(() => {
        this.fetchItemById$(licenseId).subscribe(updatedLicense => this.updateItems$({
          [updatedLicense.Id]: {
            RequirePasswordChangeAfterDays: updatedLicense.RequirePasswordChangeAfterDays,
            MaxFailedAccessAttempts: updatedLicense.MaxFailedAccessAttempts,
            MaxIdleTimeMinutes: updatedLicense.MaxIdleTimeMinutes,
            PasswordMinimumLength: updatedLicense.PasswordMinimumLength
          }
        }));
      })
    );
  }

  changeOpenAISettings$(licenseId: number, apiKey: string, apiModel: string): Observable<MadCloudResult> {
    return this.licensesApiService.changeOpenAISettings$(licenseId, apiKey, apiModel).pipe(
      tap(() => {
        this.updateItems$({ [licenseId]: { OpenAIKey: apiKey, OpenAIModel: apiModel } });
      })
    );
  }

  verifyOpenAIKey$(apiKey: string, apiModel: string): Observable<MadCloudResult> {
    return this.licensesApiService.verifyOpenAIKey$(apiKey, apiModel);
  }

  calculateSubscriptionAdditions$(licenseId: number, additions: SubscriptionAddition): Observable<SubscriptionOrderItem[]> {
    return this.licensesApiService.calculateSubscriptionAdditions$(licenseId, additions);
  }

  processSubscriptionAdditions$(licenseId: number, additions: SubscriptionAddition): Observable<MadCloudResult> {
    return this.licensesApiService.processSubscriptionAdditions$(licenseId, additions).pipe(
      tap(() => {
        this.fetchItemById$(licenseId).subscribe(license => this.updateItems$([license]));
      })
    );
  }

  getContactInfo$(licenseId: number): Observable<ContactInfo> {
    return this.licensesApiService.getContactInfo$(licenseId).pipe(
      tap((contactInfo) => this.updateItems$({ [licenseId]: { ContactInfo: contactInfo } }))
    );
  }

  deleteContactInfo$(licenseId: number): Observable<MadCloudResult> {
    return this.licensesApiService.deleteContactInfo$(licenseId).pipe(
      tap(() => {
        this.updateItems$({ [licenseId]: { ContactInfo: { billingContact: null, shippingContact: null, creditCard: null } } });
      })
    );
  }

  updateContactInfo$(licenseId: number, contactInfoModel: ContactInfo): Observable<MadCloudResult> {
    return this.licensesApiService.updateContactInfo$(licenseId, contactInfoModel).pipe(
      tap(() => {
        this.licensesApiService.getContactInfo$(licenseId).subscribe(contactInfo => this.updateItems$({ [licenseId]: { ContactInfo: contactInfo } }));
      })
    );
  }

  validateUserEmailAddress$(licenseId: number, email: string): Observable<MadCloudResult> {
    return this.licensesApiService.validateUserEmailAddress$(licenseId, email);
  }

  setSamlAuthenticationSettings$(licenseId: number, licenseSamlAuthenticationSettings: LicenseSamlAuthenticationSettings) {
    return this.licensesApiService.setSamlAuthenticationSettings$(licenseId, licenseSamlAuthenticationSettings);
  }

  getSamlAuthenticationSettings$(licenseId: number): Observable<LicenseSamlAuthenticationSettings> {
    return this.licensesApiService.getSamlAuthenticationSettings$(licenseId);
  }

  getSamlAuthenticationSettingsAndDefaultTeams$(licenseId: number): Observable<LicenseSamlAuthenticationSettings> {
    return this.licensesApiService.getSamlAuthenticationSettingsAndDefaultTeams$(licenseId);
  }

  getLicenseByDomain$(): Observable<License> {
    return this.licensesApiService.getLicenseByDomain$().pipe(
      tap(license => this.addItems$([license]))
    );
  }
}
