import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LogDialogModule } from '@portal-core/logs/components/log-dialog/log-dialog.module';
import { GridModule } from '@portal-core/ui/grid/grid.module';
import { BuildLogsGridComponent } from './build-logs-grid.component';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    LogDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [
    BuildLogsGridComponent
  ],
  exports: [
    BuildLogsGridComponent
  ]
})
export class BuildLogsGridModule { }
