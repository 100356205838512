import { MadCapTableColumnIdAttrName, MadCapTableRowGroupIdAttrName, MadCapTableRowGroupTypeAttrName, MadCapTableStructureAttrName, TableStructureItem } from '@common/flare/schema/table-schema-plugin';
import { RectDirection } from '@common/prosemirror/commands/rect-direction.enum';
import { FoundNodeInfo } from '@common/prosemirror/model/node';
import { tableNode } from '@common/prosemirror/model/table';
import { cloneDeep, isEqual, remove, uniq } from 'lodash';
import { Fragment, Node, NodeType, ProseMirrorNode } from 'prosemirror-model';
import { EditorState, Selection, Transaction } from 'prosemirror-state';
import { TableMap, TableRect, isInTable, addColumn as pmAddColumn, addRow as pmAddRow, deleteRow as pmDeleteRow, removeColumn, rowIsHeader, selectedRect } from 'prosemirror-tables';
import { EditorView } from 'prosemirror-view';
import { v4 as uuidv4 } from 'uuid';

interface Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

/**
 * Command to add a table row above the selected row.
 */
export function addRowBefore(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  return addRow(state, RectDirection.Top, dispatch);
}

/**
 * Command to add a table row below the selected row.
 */
export function addRowAfter(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  return addRow(state, RectDirection.Bottom, dispatch);
}

/**
 * Helper function to add a table row.
 * Associates the new row with same table row group as the reference row.
 * Pm defaults to td if the target row is null (beginning/end of table). If the row is the first header cell, change the new row cells from td to th.
 * @param direction which direction from the selection the new row should be added.
 */
function addRow(state: EditorState, direction: RectDirection, dispatch?: ProsemirrorDispatcher): boolean {
  if (!isInTable(state)) {
    return false;
  }

  if (dispatch) {
    const rect = selectedRect(state);
    const tr = pmAddRow(state.tr, rect, rect[direction]);

    // Move the selection to the new row left most table cell
    const newRowSel = Selection.findFrom(tr.doc.resolve(tr.steps[0].from), 1, true);
    tr.setSelection(newRowSel);

    const tableRowPos = direction === RectDirection.Top ? tr.steps[0].from : rect.tableStart + rect.map.map[(rect.bottom - 1) * rect.map.width] - 1;
    const tableRow = state.doc.nodeAt(tableRowPos);

    const newTable = tr.doc.nodeAt(rect.tableStart - 1);
    const newTableMap = TableMap.get(newTable);
    const newRowRect: Rect = { left: 0, top: rect[direction], right: rect.map.width, bottom: rect[direction] + 1 };
    const newRowCellPos = newTableMap.cellsInRect(newRowRect).map(cellPos => cellPos + rect.tableStart);
    const tableRowGroupId = tableRow.attrs[MadCapTableRowGroupIdAttrName];
    const tableRowGroupType = tableRow.attrs[MadCapTableRowGroupTypeAttrName];

    const table: FoundNodeInfo = tableNode(state.selection.$head);
    const tableStruct: TableStructureItem[] = cloneDeep(table.node.attrs[MadCapTableStructureAttrName]);
    const newId = uuidv4();

    if (tableRowGroupId || tableRowGroupType) {
      // new table-row position will be right before the first cell in the row
      tr.setNodeMarkup(newRowCellPos[0] - 1, null, { [MadCapTableRowGroupIdAttrName]: tableRowGroupType === 'tr' ? newId : tableRowGroupId, [MadCapTableRowGroupTypeAttrName]: tableRowGroupType });
    }

    if (tableRowGroupType === 'tr') {
      // Update the table struct to include the new row
      let index = tableStruct?.findIndex(item => item.id === tableRowGroupId);
      if (direction === RectDirection.Bottom) {
        index++;
      } else {
        index--;
      }

      const newRow: TableStructureItem[] = [{ nodeName: 'tr', id: newId }]
      tableStruct?.splice(index, 0, ...newRow);
      tr.setNodeAttribute(table.pos, MadCapTableStructureAttrName, tableStruct);
    }

    // Pm uses the row before as the reference cell type. If no row exists, it defaults to td.
    // If header cell, change cell type to th
    if (rowIsHeader(rect.map, rect.table, direction === RectDirection.Top ? rect.top : rect.bottom - 1)) {
      newRowCellPos.forEach(cellPos => tr.setNodeMarkup(cellPos, state.schema.nodes.table_header));
    }

    dispatch(tr);
  }

  return true;
}

/**
 * Command to add a table column to the left of the selected row.
 */
export function addColumnBefore(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  return addColumn(state, RectDirection.Left, dispatch);
}

/**
 * Command to add a table column to the right of the selected row.
 */
export function addColumnAfter(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  return addColumn(state, RectDirection.Right, dispatch);
}

/**
 * Helper function to add a table column.
 * @param colDir which direction from the selection the new column should be added.
 */
function addColumn(state: EditorState, colDir: RectDirection, dispatch?: ProsemirrorDispatcher): boolean {
  if (!isInTable(state)) {
    return false;
  }

  if (dispatch) {
    var rect = selectedRect(state);
    const tr = pmAddColumn(state.tr, rect, rect[colDir]);

    // Move the selection to the new column top most table cell
    const newColSel = Selection.findFrom(tr.doc.resolve(tr.steps[0].from), 1, true);
    tr.setSelection(newColSel);

    const table: FoundNodeInfo = tableNode(state.selection.$head);

    // Add another col
    let selectedColIndex = rect[colDir];
    if (colDir === RectDirection.Right) {
      selectedColIndex--;
    }
    updateColumns(table, colDir, selectedColIndex, state.schema.nodes.col, tr, table);

    dispatch(tr);
  }

  return true;
}

/**
 * Updates table column's span or incorporates the new column or if a new column should be added before/after the column.
 * Or returns the next index to check the next column.
 * @param columnParent the columns' parent node.
 * @param colDir which direction from the selection the new column should be added.
 * @param selectedIndex the index of the reference column. colDir inserts to the left or right of this column.
 * @param colType the node information to create a col
 * @param tr the transaction to update
 * @param currIndex keeps track of the iteration index until it reaches the selected index.
 */
function updateColumns(columnParent: FoundNodeInfo, colDir: RectDirection, selectedIndex: number, colType: NodeType, tr: Transaction, table: FoundNodeInfo = columnParent, currIndex: number = 0) {
  // Compare with the transaction length to see if the
  const originalStepLen = tr.steps.length;

  columnParent.node.forEach((column: Node, offset: number, index: number) => {
    if ((column.type.name !== 'colgroup' && column.type.name !== 'col') || tr.steps.length > originalStepLen) {
      return;
    }

    // Add one to move the position inside the parent
    const colPos = columnParent.pos + 1 + offset;

    // colgroup/col can represent multiple columns with the span attribute
    let span: number;
    if (column.childCount) {
      // Ignore span if colgroup has children
      span = 0;
    } else {
      // Only non negative numbers
      span = Math.max(0, Number(column.attrs?.['span'] || 0));
    }

    const isInsertLeftOfSpan = currIndex === selectedIndex && colDir === RectDirection.Left;
    const isInsertRightOfSpan = currIndex + span - 1 === selectedIndex && colDir === RectDirection.Right;
    // Col is inserted in the middle of a column span group
    if (currIndex + span > selectedIndex && !isInsertLeftOfSpan && !isInsertRightOfSpan) {
      // Increment the span attribute
      tr.setNodeAttribute(colPos, 'span', Math.max(span + 1, 2));
      return;
    } else if (currIndex === selectedIndex || isInsertRightOfSpan) {
      // If an empty colgroup
      if (column.type.name === 'colgroup' && !column.childCount) {
        // Increment the span attribute
        tr.setNodeAttribute(colPos, 'span', Math.max(span + 1, 2));
        return;
        // Col is selected
      } else if (!column.childCount) {
        const newId = uuidv4();
        tr.insert(colPos + (colDir === RectDirection.Left ? 0 : column.nodeSize), colType.create({ [MadCapTableColumnIdAttrName]: newId }));

        if (columnParent.node.type.name !== 'colgroup') {
          // Create a copy of the attr to modify
          const tableStruct: TableStructureItem[] = cloneDeep(table.node.attrs[MadCapTableStructureAttrName]);
          // Add the new col into the struct
          tableStruct.splice(index + (colDir === RectDirection.Left ? 0 : 1), 0, {nodeName: 'col', id: newId});

          tr.setNodeAttribute(table.pos, MadCapTableStructureAttrName, tableStruct);
        }
        return;
      }
    }

    if (column.childCount) {
      const result = updateColumns({ node: column, pos: colPos }, colDir, selectedIndex, colType, tr, table, currIndex);
      if (typeof result === 'number') {
        // Col was not inserted in the colgroup. Bubble up the new index
        currIndex = result;
      } else {
        return;
      }
    } else {
      currIndex += Math.max(span, 1);
    }
  });

  return currIndex;
}

/**
 * Command to delete the entire table.
 */
export function deleteTable(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  if (!isInTable(state)) {
    return false;
  }
  const $pos = state.selection.$anchor;
  for (let d = $pos.depth; d > 0; d--) {
    const node = $pos.node(d);
    // Same as pm but check for table node rather than table role. We gave tbody table role to work with pm
    if (node.type.name === state.schema.nodes.table.name) {
      if (dispatch) { dispatch(state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView()); }
      return true;
    }
  }
  return false;
}

/**
 * Command to delete the selected rows or entire table if all rows are selected.
 */
export function deleteRow(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  if (!isInTable(state)) {
    return false;
  }
  if (dispatch) {
    const rect = selectedRect(state);
    // Tables are required to have a tbody. If deleting all tbody rows, delete the table.
    if (!hasTableBody(rect)) {
      return deleteTable(state, dispatch);
    } else {
      return pmDeleteRow(state, (tr: Transaction) => {
        // Update the table struct attr with remaining table groups
        tr = maybeRemoveTableStructGroups(rect, state, tr);

        dispatch(tr);
      });
    }
  }
  return true;
}

/**
 * Helper function to see if a table has any non thead or tfoot rows to keep the table valid after a row delete command.
 * @param rect the table selection.
 * @returns if the table has any body rows not in the selection
 */
function hasTableBody(rect: TableRect): boolean {
  for (let i = 0; i < rect.table.childCount; i++) {
    // Don't include selected rows
    if (i >= rect.top && i < rect.bottom) {
      continue;
    }
    const group = rect.table.child(i).attrs[MadCapTableRowGroupTypeAttrName];

    if (group === 'tr' || group === 'tbody') {
      return true;
    }
  }

  return false;
}

/**
 * Helper function to update the table struct attr if a table has any empty table groups or rows to delete after the row delete command.
 * @param state the old state before the row deletion.
 * @param tr the transaction to append the attr changes
 * @returns the tr with possible new steps
 */
function maybeRemoveTableStructGroups(rect: TableRect, state: EditorState, tr: Transaction): Transaction {
  const table: FoundNodeInfo = tableNode(state.selection.$head);
  // Create a copy of the attr to modify
  const tableStruct: TableStructureItem[] = cloneDeep(table.node.attrs[MadCapTableStructureAttrName]);

  const newTbody = tr.doc.nodeAt(rect.tableStart - 1);
  let ids = [];
  for (let i = 0; i < newTbody.childCount; i++) {
    const row = newTbody.child(i);
    ids.push(row.attrs[MadCapTableRowGroupIdAttrName]);
  }
  ids = uniq(ids);

  // Delete groups that don't have any remaining rows left
  for (let i = 0; i < tableStruct?.length; i++) {
    const structItem = tableStruct[i];
    if (!structItem.id ||
      structItem.nodeName === 'caption' ||
      structItem.nodeName === 'colgroup' ||
      structItem.nodeName === 'col') {
      continue;
    }
    // Delete the table group and the spacing node before it
    if (!ids.includes(structItem.id)) {
      tableStruct.splice(i, 1);
      i--;
    }
  }

  if (tableStruct !== table.node.attrs[MadCapTableStructureAttrName]) {
    tr.setNodeAttribute(table.pos, MadCapTableStructureAttrName, tableStruct);
  }

  return tr;
}

/**
 * Command to delete the selected columns or entire table if all columns are selected.
 */
export function deleteColumn(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
  if (!isInTable(state)) {
    return false;
  }
  if (dispatch) {
    const rect = selectedRect(state), tr = state.tr;
    if (rect.left == 0 && rect.right == rect.map.width) {
      // Whole table width selected
      return deleteTable(state, dispatch);
    } else {
      /******************Direct from PM******************/
      for (var i = rect.right - 1; ; i--) {
        removeColumn(tr, rect, i);
        if (i == rect.left) { break }
        rect.table = rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc;
        rect.map = TableMap.get(rect.table);
      }
      /**************************************************/
      const table: FoundNodeInfo = tableNode(state.selection.$head);
      const tableStruct: TableStructureItem[] = cloneDeep(table.node.attrs[MadCapTableStructureAttrName]);
      removeColumns(table, rect, tr, tableStruct);

      // If table groups were removed, update the table struct
      if (!isEqual(table.node.attrs[MadCapTableStructureAttrName], tableStruct)) {
        tr.setNodeAttribute(table.pos, MadCapTableStructureAttrName, tableStruct);
      }

      dispatch(tr);
    }
  }
  return true;
}

/**
 * Updates the table's column attribute to exclude the deleted column.
 * @param columns the serialized column representations.
 * @param rect the table selection rectangle.
 * @param currIndex keeps track of the iteration index until it reaches the selected index.
 */
function removeColumns(columnParent: FoundNodeInfo, rect: TableRect, tr: Transaction, tableStruct: TableStructureItem[], currIndex: number = 0): number {
  let mapOffset = 0;
  columnParent.node.forEach((column: Node, offset: number, index: number) => {
    if ((column.type.name !== 'colgroup' && column.type.name !== 'col') || currIndex >= rect.right) {
      return;
    }

    // Add one to move the position inside the parent
    const colPos = columnParent.pos + 1 + offset + mapOffset;
    // colgroup/col can represent multiple columns with the span attribute
    let span: number;
    if (column.childCount) {
      // Ignore span if colgroup has children
      span = 0;
    } else {
      // Only non negative numbers
      span = Math.max(1, Number(column.attrs?.['span'] || 1));
    }

    if (column.childCount) {
      currIndex = removeColumns({ node: column, pos: colPos }, rect, tr, tableStruct, currIndex);
      const newColgroup = tr.doc.nodeAt(colPos);
      if (newColgroup && newColgroup?.childCount === 0) {
        tr.deleteRange(colPos, colPos + newColgroup.nodeSize);
        mapOffset -= column.nodeSize;

        // Remove the column from the table struct
        remove(tableStruct, col => col.id === column.attrs[MadCapTableColumnIdAttrName]);
      }
    } else {
      for (let spanIndex = span; currIndex < rect.right && spanIndex !== 0; spanIndex--, currIndex++) {
        if (currIndex >= rect.left) {
          span--;
        }
      }

      if (span === 0) {
        tr.deleteRange(colPos, colPos + column.nodeSize);
        mapOffset -= column.nodeSize;

        // Remove the column from the table struct
        remove(tableStruct, col => col.id === column.attrs[MadCapTableColumnIdAttrName]);
      } else {
        tr.setNodeAttribute(colPos, 'span', span === 1 ? undefined : span);
      }
    }
  });

  return currIndex;
}

export interface DimensionOptions {
  width?: number;
  height?: number;
}

export type CreateTableNodeCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, options?: DimensionOptions) => boolean;

/**
 * Helper function to create a table node with the given number of rows and columns.
 * @param tableType the type for the table node.
 * @param tbodyType the type for the tbody node.
 * @param rowType the type for the row nodes.
 * @param cellType the type for the cell nodes.
 * @param columns the inputted width corresponding to the number of columns to give the new table.
 * @param rows the inputted height corresponding to the number of rows to give the new table.
 */
function createTable(tableType: NodeType, tbodyType: NodeType, rowType: NodeType, cellType: NodeType, colGroupType: NodeType, columnType: NodeType, columns: number, rows: number): ProseMirrorNode {
  const cells: ProseMirrorNode[] = [];
  const cols: ProseMirrorNode[] = [];
  const rowNodes: ProseMirrorNode[] = [];

  const cell = cellType.createAndFill();
  for (let i = 0; i < columns; i++) {
    cells.push(cell);
  }

  const row = rowType.create(null, cells);
  const col = columnType.createAndFill();
  for (let i = 0; i < columns; i++) {
    cols.push(col);
  }
  for (let i = 0; i < rows; i++) {
    rowNodes.push(row);
  }

  const colgroup = colGroupType.create({ [MadCapTableColumnIdAttrName]: '1' }, cols);
  const tbody = tbodyType.create({ [MadCapTableRowGroupTypeAttrName]: 'tbody' }, rowNodes);

  return tableType.create({
    style: { width: '100%' },
    [MadCapTableStructureAttrName]: [
      { nodeName: 'colgroup', id: '1' }
    ]
  }, Fragment.from([colgroup, tbody]));
}

/**
 * Command to create a table node with the selected number of rows and columns.
 * @param tableType the type for the table node.
 * @param tbodyType the type for the tbody node.
 * @param rowType the type for the row nodes.
 * @param cellType the type for the cell nodes.
 */
export function insertTable(tableType: NodeType, tbodyType: NodeType, rowType: NodeType, cellType: NodeType, colGroupType: NodeType, columnType: NodeType): CreateTableNodeCommand {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, options: DimensionOptions = {}): boolean {
    if (dispatch) {
      const tr = state.tr.replaceSelectionWith(createTable(tableType, tbodyType, rowType, cellType, colGroupType, columnType, options.width, options.height));
      const firstTableCellSelection = Selection.findFrom(tr.doc.resolve(tr.steps[0].from), 1);
      tr.setSelection(firstTableCellSelection);
      dispatch(tr);
    }
    return true;
  };
}
