import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BuildLogsState } from '@portal-core/build-logs/services/build-logs-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([BuildLogsState])
  ]
})
export class BuildLogsServicesModule { }
