import { Directive, Inject } from '@angular/core';
import { Focusable } from '@portal-core/ui/focus/interfaces/focusable';
import { MC_FOCUSABLE } from '@portal-core/ui/focus/util/focusable.token';

@Directive({
  selector: '[mcDialogAutoFocus]'
})
export class DialogAutoFocusDirective {
  constructor(@Inject(MC_FOCUSABLE) public focusable: Focusable) { }
}
