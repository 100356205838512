import { normalizeSpacing } from '@common/clipboard/cf-html/space';
import { HtmlPasteNormalizerData } from '@common/clipboard/types/html-paste-normalizer-data.type';
import { parseFromString } from '@common/html/simple-dom/parse';
import { descendants } from '@common/html/util/dom';
import { trimHTML } from '@common/html/util/trim';

const StartFragment = '<!--StartFragment-->';
const EndFragment = '<!--EndFragment-->';

/**
 * Parses content pasted using the CF HTML format.
 */
export class CfHtmlParser {
  /**
   * Checks if the HTML being pasted is in the CF HTML format.
   * @param html The HTML to check.
   * @returns `true` if the HTML is in the CF HTML format, otherwise `false`.
   */
  isCfHtml(html: string): boolean {
    // This is CF HTML if it has the start and end fragment markers.
    return html.indexOf(StartFragment) !== -1 && html.indexOf(EndFragment) !== -1;
  }

  /**
   * Parses the HTML into a CF HTML data object.
   * @param html The HTML to parse.
   * @returns The CF HTML data object.
   */
  parseFromString(html: string): HtmlPasteNormalizerData {
    html = normalizeSpacing(trimHTML(html, ['html', 'body']));

    const startFragmentIndex = html.indexOf(StartFragment);
    const endFragmentIndex = html.indexOf(EndFragment);

    if (startFragmentIndex !== -1 && endFragmentIndex !== -1) {
      const fragment = html.substring(0, startFragmentIndex).trim() + html.substring(startFragmentIndex + StartFragment.length, endFragmentIndex).trim() + html.substring(endFragmentIndex + EndFragment.length).trim();
      const doc = parseFromString(fragment, 'text/html');  // Parse as text/html because the clipboard data from other sources is usually html and we need to process it correctly
      // Standard spec DOM implementations require normalizeSpacerunSpans to be called here. But SimpleDom does not require it.
      // normalizeSpacerunSpans(doc as unknown as Document);

      return {
        css: this.extractCSS(parseFromString(html, 'text/html') as unknown as Document),
        doc: doc as unknown as Document,
        html
      };
    }
  }

  /**
   * Extracts the CSS from the HTML by grabbing all style elements and concatenating their contents.
   * @param doc The HTML document to extract the CSS from.
   * @returns The CSS extracted from the HTML.
   */
  private extractCSS(doc: Document): string {
    let css = '';

    descendants(doc.documentElement, (element: HTMLElement) => {
      if (element.nodeName === 'style') {
        css += element.innerHTML + '\n';
      }
    }, { depthFirst: true });

    return css;
  }
}
