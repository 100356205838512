/**
 * @CachedAccessor
 * Creates an accessor that caches its value after the first time it is accessed.
 * Any subsequent calls to the getter return the cached value.
 */
export function CachedAccessor(): MethodDecorator {
  return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    // The property keys for the new "private" property on the object instance
    const cachedDataValueKey = `_${propertyKey}CachedValue`;
    const cachedDataSetKey = `_${propertyKey}CachedSet`;

    const getter = descriptor.get;

    // Change the @CachedAccessor property into a new getter that caches the data
    descriptor.get = function() {
      if (this[cachedDataSetKey]) {
        return this[cachedDataValueKey];
      }

      this[cachedDataSetKey] = true;
      return this[cachedDataValueKey] = getter.call(this);
    };
  };
}
