<mat-toolbar color="secondary">
  <button *ngIf="hasCurrentLicense$ | async; else noCurrentLicense" class="mc-toolbar-item-full" color="secondary-invert" mat-button [matMenuTriggerFor]="licenseMenu" type="button">
    <mc-license-avatar [disabled]="true" [license]="currentLicense$ | async" [showName]="false" size="small"></mc-license-avatar>
  </button>

  <ng-template #noCurrentLicense>
    <button class="button-dropdown navbar-item mc-app-license-dropdown-button" color="secondary-invert" mat-button [matMenuTriggerFor]="licenseMenu">
      <span>Change License</span>
      <span class="dropdown-arrow"></span>
    </button>
  </ng-template>

  <mat-menu #licenseMenu="matMenu" [overlapTrigger]="false" [class]="menuClass$ | async">
    <ng-template matMenuContent>
      <ng-container *ngIf="currentLicense$ | async as license">
        <h4 matSubheader>
          {{ license.KeyLabel }}
          <br>
          {{ subdomainUrlDisplayText$ | async }}
        </h4>

        <ng-container *ngIf="showSettings">
          <button *mcIfAuthor mat-menu-item type="button" (click)="onSettingsClicked()">License Settings</button>
        </ng-container>

        <a [href]="loginPortalUrl$ | async" mat-menu-item target="_blank">Log in to another license</a>
        <button mat-menu-item (click)="onSignOutClicked()">Log out of {{ license.KeyLabel }}</button>

        <mat-divider *ngIf="hasActiveLicenses$ | async"></mat-divider>
      </ng-container>

      <h4 matSubheader mcStopPropagation="click" *ngIf="hasActiveLicenses$ | async">Active Licenses</h4>
      <ng-container *ngFor="let item of activeLicenses$ | async">
        <a *ngIf="item.isCurrentLicense" class="mc-list-item-selected" mat-menu-item [routerLink]="['/', item.license.Id]">
          <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
        </a>
        <a *ngIf="!item.isCurrentLicense" mat-menu-item [href]="item.href">
          <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
        </a>
      </ng-container>

      <mat-divider *ngIf="hasActiveAndNonActiveLicenses$ | async"></mat-divider>

      <div *ngIf="hasExpiredLicenses$ | async" class="mc-expansion-panel mc-expansion-panel-list" [ngClass]="{ 'mc-expansion-panel-expanded': showExpiredLicenses }" mcStopPropagation="click">
        <div class="mc-expansion-panel-header" (click)="showExpiredLicenses = !showExpiredLicenses">
          <h4 matSubheader>Expired Licenses</h4>
          <span class="mc-fill-remaining-flex-space"></span>
          <mat-icon fontSet="project-titan" fontIcon="icon-expand" class="mc-expansion-panel-indicator"></mat-icon>
        </div>
        <div class="mc-expansion-panel-content">
          <ng-container *ngFor="let item of expiredLicenses$ | async">
            <a *ngIf="item.isCurrentLicense" class="mc-list-item-selected" mat-menu-item [routerLink]="['/', item.license.Id, 'home']">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
            <a *ngIf="!item.isCurrentLicense" mat-menu-item [href]="item.href">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
          </ng-container>
        </div>
      </div>

      <div *ngIf="hasExpiredTrials$ | async" class="mc-expansion-panel mc-expansion-panel-list" [ngClass]="{ 'mc-expansion-panel-expanded': showExpiredTrials }" mcStopPropagation="click">
        <div class="mc-expansion-panel-header" (click)="showExpiredTrials = !showExpiredTrials">
          <h4 matSubheader>Expired Trials</h4>
          <span class="mc-fill-remaining-flex-space"></span>
          <mat-icon fontSet="project-titan" fontIcon="icon-expand" class="mc-expansion-panel-indicator"></mat-icon>
        </div>
        <div class="mc-expansion-panel-content">
          <ng-container *ngFor="let item of expiredTrials$ | async">
            <a *ngIf="item.isCurrentLicense" class="mc-list-item-selected" mat-menu-item [routerLink]="['/', item.license.Id, 'home']">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
            <a *ngIf="!item.isCurrentLicense" mat-menu-item [href]="item.href">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
          </ng-container>
        </div>
      </div>

      <div *ngIf="hasDeactivatedLicenses$ | async" class="mc-expansion-panel mc-expansion-panel-list" [ngClass]="{ 'mc-expansion-panel-expanded': showDeactivatedLicenses }" mcStopPropagation="click">
        <div class="mc-expansion-panel-header" (click)="showDeactivatedLicenses = !showDeactivatedLicenses">
          <h4 matSubheader>Deactivated Licenses</h4>
          <span class="mc-fill-remaining-flex-space"></span>
          <mat-icon fontSet="project-titan" fontIcon="icon-expand" class="mc-expansion-panel-indicator"></mat-icon>
        </div>
        <div class="mc-expansion-panel-content">
          <ng-container *ngFor="let item of deactivatedLicenses$ | async">
            <a *ngIf="item.isCurrentLicense" class="mc-list-item-selected" mat-menu-item [routerLink]="['/', item.license.Id, 'home']">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
            <a *ngIf="!item.isCurrentLicense" mat-menu-item [href]="item.href">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
          </ng-container>
        </div>
      </div>

      <div *ngIf="hasBillingFailedLicenses$ | async" class="mc-expansion-panel mc-expansion-panel-list" [ngClass]="{ 'mc-expansion-panel-expanded': showBillingFailedLicenses }" mcStopPropagation="click">
        <div class="mc-expansion-panel-header" (click)="showBillingFailedLicenses = !showBillingFailedLicenses">
          <h4 matSubheader>Billing Failed</h4>
          <span class="mc-fill-remaining-flex-space"></span>
          <mat-icon fontSet="project-titan" fontIcon="icon-expand" class="mc-expansion-panel-indicator"></mat-icon>
        </div>
        <div class="mc-expansion-panel-content">
          <ng-container *ngFor="let item of billingFailedLicenses$ | async">
            <a *ngIf="item.isCurrentLicense" class="mc-list-item-selected" mat-menu-item [routerLink]="['/', item.license.Id, 'home']">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
            <a *ngIf="!item.isCurrentLicense" mat-menu-item [href]="item.href">
              <mc-license-avatar [disabled]="true" [license]="getLicenseById$(item.license.Id) | async" size="small"></mc-license-avatar>
            </a>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</mat-toolbar>

<!-- This only exists so that Angular includes the mat-list styles when this module is built -->
<mat-list hidden></mat-list>