import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { BuildDeletionFormComponent } from './build-deletion-form.component';

@NgModule({
  declarations: [
    BuildDeletionFormComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatSnackBarModule
  ],
  exports: [
    BuildDeletionFormComponent
  ]
})
export class BuildDeletionFormModule { }
