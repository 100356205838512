import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec } from 'prosemirror-model';

export class MadCapKeywordSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapkeyword: {
      group: 'inline',
      inline: true,
      attrs: {
        term: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:keyword',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['term']);
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-keyword icon-star' }]; },
      tagName: 'MadCap:keyword',
      isVoid: true
    },

    madcapkeywordlink: {
      group: 'inline',
      isLink: true,
      inline: true,
      attrs: {
        term: { default: undefined }
      },
      parseDOM: [{
        tag: 'MadCap\\:keywordLink',
        getAttrs(dom: HTMLElement) {
          return getSchemaAttrs(dom, ['term']);
        }
      }],
      toDOM() { return ['span', { class: 'mc-madcap-keyword-link' }, 'Search Index']; },
      tagName: 'MadCap:keywordLink',
      isVoid: true
    }
  };
}
