import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectChecklistItemsState } from '@portal-core/project-checklists/services/checklist-items/project-checklist-items-data.service';
import { ProjectChecklistsState } from '@portal-core/project-checklists/services/project-checklists-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([ProjectChecklistsState, ProjectChecklistItemsState])
  ]
})
export class ProjectChecklistsServicesModule { }
