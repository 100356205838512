import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuditCategory } from '@portal-core/notifications/models/audit-category.model';
import { MyNotificationPage } from '@portal-core/notifications/models/my-notification-page.model';
import { NotificationDeliveryMethod } from '@portal-core/notifications/models/notification-delivery-method.model';
import { NotificationPage } from '@portal-core/notifications/models/notification-page.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { UserSubscription } from '@portal-core/notifications/models/subscription.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getMyNotifications$(licenseId: number, pageSize: number, pagesLoaded: number): Observable<MyNotificationPage> {
    return this.http.get<MyNotificationPage>(`${this.apiService.centralApiBaseUri}/api/users/userNotifications/${licenseId}/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByProjectId$(projectId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/projects/Activity/${projectId}/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByUserIdAndLicenseId$(userId: string, licenseId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/users/${userId}/${licenseId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByTeamId$(teamId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/teams/${teamId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByReviewId$(reviewId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/reviews/${reviewId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByReviewPackageId$(reviewPackageId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/ReviewPackage/${reviewPackageId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getTaskNotificationsByLicenseId$(licenseId: number, pageSize: number, pagesLoaded: number, taskBoardId?: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/tasks/License/${licenseId}/Activity/${pageSize}/${pagesLoaded}/${taskBoardId || -1}`);
  }

  @SharePendingRequest()
  getNotificationsByTranslationPackageLanguageId$(translationPackageLanguageBranchId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/translationPackageLanguageBranch/${translationPackageLanguageBranchId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationsByTranslationPackageId$(translationPackageId: number, pageSize: number, pagesLoaded: number): Observable<NotificationPage> {
    return this.http.get<NotificationPage>(`${this.apiService.centralApiBaseUri}/api/translationPackage/${translationPackageId}/Activity/${pageSize}/${pagesLoaded}`);
  }

  @SharePendingRequest()
  getNotificationById$(notificationId: number): Observable<Notification> {
    return this.http.get<Notification>(`${this.apiService.centralApiBaseUri}/api/notifications/${notificationId}`);
  }

  markAllMyNotificationsAsRead$(): Observable<any> {
    return this.http.post(`${this.apiService.centralApiBaseUri}/api/users/notifications/MarkAllRead`, null);
  }

  @SharePendingRequest()
  getAuditCategories$(): Observable<AuditCategory[]> {
    return this.http.get<AuditCategory[]>(`${this.apiService.centralApiBaseUri}/api/AuditCategories`).pipe(
      map((res: any) => {
        return res.Items as AuditCategory[];
      })
    );
  }

  @SharePendingRequest()
  getNotificationDeliveryMethod$(): Observable<NotificationDeliveryMethod> {
    return this.http.get<NotificationDeliveryMethod>(`${this.apiService.centralApiBaseUri}/api/users/NotificationDeliveryMethod`);
  }

  saveNotificationDeliveryMethod$(deliveryMethod: NotificationDeliveryMethod): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/NotificationDeliveryMethod`, deliveryMethod);
  }

  saveUserNotifications$(categoryIds: number[]): Observable<UserSubscription[]> {
    return this.http.post<UserSubscription[]>(`${this.apiService.centralApiBaseUri}/api/Users/Subscriptions`, categoryIds);
  }
}
