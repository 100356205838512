import { ChangeNodeAttrs } from '@common/prosemirror/changeset/change-node-attrs.type';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChange, TrackedChangeList } from '@common/prosemirror/changeset/tracked-change.type';
import { mergeChanges, mergeNodeChanges, removeChangesByType, setNodeChangesFromAttrs, setNodeChangesFromChangeList } from '@common/prosemirror/changeset/tracked-changes';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { getMark } from '@common/prosemirror/model/mark';
import { nodesBetweenWithData } from '@common/prosemirror/model/node';
import { removeMarksOfType } from '@common/prosemirror/transform/mark';
import dayjs from 'dayjs';
import { MarkType, ProseMirrorNode } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';

type ChangeInfoType = ChangeType | 'other' | 'none';

interface ChangeInfo {
  type: ChangeInfoType;
  change: TrackedChange;
}

export class TrackedChangeCleaner {
  schema: CollabSchema;
  changeMark: MarkType;

  constructor(public transaction: Transaction, public oldState: EditorState, public newState: EditorState) {
    this.schema = this.oldState.schema as CollabSchema;
    this.changeMark = this.schema.changeMark;
  }

  /*
   * getChangeInfo
   * Gets the change information necessary to clean up a node's changes from a change list
   */
  getChangeInfo(changeList: TrackedChangeList): ChangeInfo {
    let trackedChange: TrackedChange;
    let changeType: ChangeInfoType;

    if (Array.isArray(changeList)) {
      trackedChange = changeList.find(change => change.changeType === ChangeType.Add || change.changeType === ChangeType.Remove);
    }

    if (trackedChange) {
      changeType = trackedChange.changeType;
    } else if (changeList && changeList.length > 0) {
      changeType = 'other';
      trackedChange = changeList[0];
    } else {
      changeType = 'none';
    }

    return {
      type: changeType,
      change: trackedChange
    };
  }

  /**
   * getMarkChangeInfo
   * Gets the change information necessary to clean up a node's changes from a node's marks
   */
  getMarkChangeInfo(node: ProseMirrorNode): ChangeInfo {
    return this.getChangeInfo(getMark(node, this.changeMark, 'changeList'));
  }

  /**
   * getNodeChangeInfo
   * Gets the change information necessary to clean up a node's changes from a node's attrs
   */
  getNodeChangeInfo(node: ProseMirrorNode): ChangeInfo {
    const attrs: ChangeNodeAttrs = node.attrs;
    return this.getChangeInfo(attrs.changeList);
  }

  /**
   * deleteNode
   * Deletes a node
   */
  deleteNode(tr: Transaction, node: ProseMirrorNode, pos: number) {
    tr.delete(tr.mapping.map(pos), tr.mapping.map(pos + node.nodeSize));
  }

  /**
   * cleanNodes
   * Modifies invalid changes within a node tree resulting in a tree with only valid changes
   */
  cleanNodes(tr: Transaction, nodeToSearch: ProseMirrorNode, from: number, to: number): Transaction {
    // Loop through the nodes inside the step's range
    nodesBetweenWithData(nodeToSearch, from, to, (node, ancestorChangeInfo: ChangeInfo, pos) => {
      const changeInfo = node.isText ? this.getMarkChangeInfo(node) : this.getNodeChangeInfo(node);

      if (changeInfo.type === ChangeType.Add) {
        // If the add change is in a remove change and is older than the remove change
        if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Remove &&
          dayjs(changeInfo.change.timestamp).isBefore(dayjs(ancestorChangeInfo.change.timestamp))) {
          // Remove the node
          this.deleteNode(tr, node, pos);
          // If the add change is in an add change
        } else if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Add && changeInfo.change.userName === ancestorChangeInfo.change.userName) {
          // If a text node
          if (node.isText) {
            // Remove the add change since the text node can rely on it's parent add change
            removeMarksOfType(tr, node, this.changeMark, pos);
          } else {
            // Give the node the add change and nothing else
            setNodeChangesFromChangeList(tr, this.schema, node, pos, ancestorChangeInfo.change);
          }
        } else {
          ancestorChangeInfo = changeInfo;
        }
      } else if (changeInfo.type === ChangeType.Remove) {
        // If the remove change is in an add change
        if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Add) {
          // Remove the node
          this.deleteNode(tr, node, pos);
          // Else if the remove change is in a remove change
        } else if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Remove) {
          // If a text node
          if (node.isText) {
            // Delete the remove change since the text node can rely on it's parent remove change
            removeMarksOfType(tr, node, this.changeMark, pos);
          } else {
            // Give the node the remove change and nothing else
            const newChangeAttrs = removeChangesByType(node.attrs, ChangeType.Remove);
            setNodeChangesFromAttrs(tr, this.schema, node, pos, mergeChanges(newChangeAttrs, ancestorChangeInfo.change));
          }
        } else {
          ancestorChangeInfo = changeInfo;
        }
      } else if (changeInfo.type === 'other') {
        // If the non add/remove change is in an add change
        if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Add) {
          // Give the node the add change and nothing else
          setNodeChangesFromChangeList(tr, this.schema, node, pos, ancestorChangeInfo.change);
          // Else if the non add/remove chagne is in a remove change
        } else if (ancestorChangeInfo && ancestorChangeInfo.type === ChangeType.Remove) {
          // Merge the remove change into the node's changes
          mergeNodeChanges(tr, this.schema, node, pos, ancestorChangeInfo.change);
        }
      } else {
        if (!node.isText && ancestorChangeInfo) {
          // Add the ancestorChangeType to the node
          setNodeChangesFromChangeList(tr, this.schema, node, pos, ancestorChangeInfo.change);
        }
      }

      return ancestorChangeInfo;
    });

    return tr;
  }

  /**
   * clean
   * Cleans up the tracked changes from a transaction. Ensures the nodes have the correct changes applied to them based on their parent's changes.
   */
  clean(tr: Transaction) {
    // Loop through each step and look for changes inside the step's range
    if (Array.isArray(this.transaction.steps)) {
      this.transaction.steps.forEach(step => {
        /*
        * We need to check a range of the document to clean up.
        * We are conservative with our check by choosing the largest range possible.
        * We base the range on the step's range before and after the transformation along with the modifed content's size.
        */

        // Choose the lowest value for the range's start
        let from = Math.min(step.from, this.transaction.mapping.map(step.from));
        from = Math.max(from, 0); // Clamp to the start of the doc

        // Choose the highest value for the range's end
        let to = Math.max(step.to, this.transaction.mapping.map(step.to));
        const size = Math.max(to - from, step.slice.size); // The size of the range can either be the step's range or the size of the content being modified
        to = Math.min(from + size, this.newState.doc.content.size); // Clamp to the end of the doc

        this.cleanNodes(tr, this.newState.doc, from, to);
      });
    }
  }
}
