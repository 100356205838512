<div class="color-collection-row">
  <button mat-mini-fab [style.background-color]="'transparent'" class="btn-color mc-color-collection-remove-color"
    (click)="select(null)" [ngClass]="{'active': selectedColor === null}">
    <span class="mc-color-collection-picker-transparent"></span>
  </button>
  <button *ngFor="let c of colors1" mat-mini-fab [style.background-color]="c" class="btn-color" (click)="select(c)"
    [ngClass]="{'active': selectedColor === c}">
  </button>
</div>
<div class="color-collection-row">
  <button *ngFor="let c of colors2" mat-mini-fab [style.background-color]="c" class="btn-color" (click)="select(c)"
    [ngClass]="{'active': selectedColor === c}">
  </button>
</div>