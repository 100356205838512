import { Injectable } from '@angular/core';
import { ReviewFileUser } from '@common/reviews/models/review-file-user.model';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';

@CollectionStateSelectors({
  stateType: ReviewFileUsersState
})
@State<ReviewFileUsersState>({
  name: ReviewFileUsersState.source
})
@Injectable()
export class ReviewFileUsersState extends CollectionStateBase {
  static source = 'ReviewFileUsers';
  getSource(): string {
    return ReviewFileUsersState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewFileUsersDataService extends CollectionDataServiceBase<ReviewFileUser> {
  constructor(protected store: Store) {
    super(store, ReviewFileUsersState);
  }
}
