import { MonoTypeOperatorFunction, Observable, catchError, defaultIfEmpty, first, of, repeat } from 'rxjs';

export interface RepeatOptions {
  /** Defines the maximum number of attempts. Missing value means that number of attempts is unlimited. */
  readonly maxRetryAttempts?: number;
  /** Defines the interval (in milliseconds) between retries. */
  readonly retryInterval?: number;
  /** Defines whether the occurred errors should be ignored. */
  readonly ignoreErrors?: boolean;
}

/**
 * Creates a new stream that repeats the source stream until not-null result received.
 * When the maximum number of attempts is defined and great that zero
 * then the source stream will be aborted immediately if an error occurs,
 * otherwise all occurred errors will be ignored to continue repeats.
 * If all attempts of the source stream completed with fail, a null value will be emitted.
 * If a retry interval value is not defined, then 1 second is used as a default value for retry interval.
 */
export function repeatWhileNull<T>(options: RepeatOptions): MonoTypeOperatorFunction<T> {
  return (source$: Observable<any>) => source$.pipe(
    // process errors
    catchError(err => {
      if (options.ignoreErrors) {
        // ignore error and return a null value to begin a new retry below
        return of(null);
      }
      // throw error to abort attempts
      throw err;
    }),
    // configure repeating attempts (it is not affect on errors)
    repeat({
      delay: options.retryInterval ?? 1000,
      count: (options.maxRetryAttempts ?? 0) > 0 ? options.maxRetryAttempts : undefined
    }),
    // take the first not-null result
    first((data) => data !== null),
    // configure the default value if the observable completes without result
    defaultIfEmpty(null)
  );
};

