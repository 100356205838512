import { Directive, OnInit } from '@angular/core';
import { SystemMessageType } from '@portal-core/system-messages/enums/system-message-type.enum';
import { SystemMessage } from '@portal-core/system-messages/models/system-message.model';
import { SystemMessagesService } from '@portal-core/system-messages/services/system-messages.service';
import { Observable, first, map } from 'rxjs';

@Directive()
export class SystemMessages implements OnInit {
  messages$: Observable<SystemMessage[]>;

  constructor(protected systemMessagesService: SystemMessagesService) { }

  ngOnInit() {
    // Fetch the whats new message
    this.systemMessagesService.getWhatsNewMessage$().pipe(
      first()
    ).subscribe();

    this.messages$ = this.systemMessagesService.getItems$().pipe(
      map(messages => messages ? Object.values(messages) : null)
    );
  }

  onMessageClicked(message: SystemMessage, openUrl: boolean = true) {
    if (message.Url && openUrl) {
      window.open(message.Url, '_blank');
    }

    if (message.Type === SystemMessageType.WhatsNew) {
      this.systemMessagesService.updateLatestAppVersionViewed$().subscribe();
    }
  }

  onMessageClosed(message: SystemMessage) {
    if (message.Type === SystemMessageType.WhatsNew) {
      this.systemMessagesService.updateLatestAppVersionViewed$().subscribe();
    }
  }
}
