import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TeamProfileDialogComponent } from '@portal-core/teams/components/team-profile-dialog/team-profile-dialog.component';
import { Team } from '@portal-core/teams/models/team.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

/**
 * mc-team-avatar
 * A component for displaying a team's avatar image and name.
 */
@Component({
  selector: 'mc-team-avatar',
  templateUrl: './team-avatar.component.html',
  styleUrls: ['./team-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamAvatarComponent extends AvatarBase {
  @Input() team: Team;

  constructor(private dialog: MatDialog) {
    super();
  }

  onAvatarClicked() {
    this.openTeamDialogCard();
  }

  hasAvatarImage(): boolean {
    return !!(this.team && this.team.Avatar);
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return this.team && this.team.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.team;
  }

  private openTeamDialogCard() {
    this.dialog.open(TeamProfileDialogComponent, {
      ...TeamProfileDialogComponent.DialogConfig,
      data: { teamId: this.team.Id }
    });
  }
}
