import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ChangeMarkAsPristineMetaKey, changePlugin, changePluginKey } from '@common/prosemirror/plugins/change.plugin';
import { EditorChangeEvent } from '@portal-core/text-editor/types/editor-change-event.type';
import { Schema } from 'prosemirror-model';
import { EditorState, Plugin } from 'prosemirror-state';
import { DirectEditorProps, EditorView, NodeViewConstructor } from 'prosemirror-view';

@Component({
  selector: 'mc-pm-editor',
  templateUrl: './pm-editor.component.html',
  styleUrls: ['./pm-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PmEditorComponent implements OnChanges, OnDestroy {
  @HostBinding('class.mc-pm-document') docClass: boolean = true;

  @Input() editorProps: Partial<DirectEditorProps>;
  @Input() nodeViews: Dictionary<NodeViewConstructor>;
  @Input() plugins: Plugin[];
  @Input() readonly: boolean = false;
  @Input() schema: Schema;

  @Output() change: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() editorStateChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() ready: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();
  @Output() selectionChange: EventEmitter<EditorChangeEvent> = new EventEmitter<EditorChangeEvent>();

  editorView: EditorView;

  get editorState(): EditorState {
    return this.editorView ? this.editorView.state : null;
  }

  constructor(private elementRef: ElementRef, private ngZone: NgZone) { }

  ngOnChanges(changes: SimpleChanges) {
    // If there are changes to the schema or plugins then the editor view needs to be recreated or destroyed
    if (changes.schema || changes.plugins) {
      // If there is a schema then a new editor view needs to be created
      if (this.schema) {
        this.createEditorView();
      } else { // Else we can't have an editor view with no schema so destroy the editor view
        this.destroyEditorView();
      }
    } else if (changes.editorProps || changes.nodeViews) { // Else if the editor props or node views (which is an editor prop) changed
      // If an editor view already exists then we can simply update it with the new props
      if (this.editorView) {
        this.editorView.setProps(Object.assign({}, this.editorProps, this.nodeViews) as DirectEditorProps);
      } else if (this.schema) { // Else we should create the editor view if we have a schema
        this.createEditorView();
      }
    }
  }

  ngOnDestroy() {
    this.destroyEditorView();
  }

  destroyEditorView() {
    if (this.editorView) {
      this.editorView.destroy();
      this.editorView = null;
    }
  }

  createEditorView() {
    this.destroyEditorView();

    const editorProps: DirectEditorProps = Object.assign({}, {
      state: this.createState(),
      nodeViews: this.nodeViews,
      editable: () => !this.readonly
    }, this.editorProps);

    this.ngZone.runOutsideAngular(() => {
      this.editorView = new EditorView(this.elementRef.nativeElement, editorProps);

      this.ngZone.run(() => {
        this.ready.emit({
          editorView: this.editorView,
          oldEditorState: null,
          newEditorState: this.editorView.state
        });
      });
    });
  }

  markAsPristine() {
    this.editorView?.dispatch(this.editorView.state.tr.setMeta(ChangeMarkAsPristineMetaKey, true));
  }

  resetState() {
    if (this.editorView) {
      this.editorView.updateState(this.createState());
    }
  }

  private createState() {
    const plugins = this.plugins ?? [];

    // Add the changePlugin if it isn't already in the list of plugins
    if (!plugins.some(plugin => plugin.key === changePluginKey.key)) {
      plugins.push(
        changePlugin({
          onDocChanged: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState, dirty: boolean) => {
            this.ngZone.run(() => {
              this.change.emit({ editorView, oldEditorState, newEditorState, dirty });
            });
          },
          onSelectionChanged: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState) => {
            this.ngZone.run(() => {
              this.selectionChange.emit({ editorView, oldEditorState, newEditorState });
            });
          },
          onStateChanged: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState) => {
            this.ngZone.run(() => {
              this.editorStateChange.emit({ editorView, oldEditorState, newEditorState });
            });
          }
        })
      );
    }

    return EditorState.create({
      schema: this.schema,
      plugins: plugins
    });
  }
}
