import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommentBoxModule } from '@portal-core/tasks/components/comment-box/comment-box.module';
import { TaskCommentGroupComponent } from '@portal-core/tasks/components/task-comment-group/task-comment-group.component';
import { TaskCommentModule } from '@portal-core/tasks/components/task-comment/task-comment.module';
import { DividerModule } from '@portal-core/ui/divider/components/divider/divider.module';

@NgModule({
  imports: [
    CommonModule,
    CommentBoxModule,
    DividerModule,
    TaskCommentModule
  ],
  declarations: [
    TaskCommentGroupComponent
  ],
  exports: [
    TaskCommentGroupComponent
  ]
})
export class TaskCommentGroupModule { }
