import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UsersState } from '@portal-core/users/services/users-data.service';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([UsersState])
  ]
})
export class UsersServicesModule { }
