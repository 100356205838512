import { EditorToolbarGroup } from '@portal-core/ui/editor/types/editor-toolbar-group.type';
import { EditorToolbarItem } from '@portal-core/ui/editor/types/editor-toolbar-item.type';

/**
 * EditorToolbarControl<C>
 * C: The command type such as Command from CodeMirror or ProseMirror.
 * Defines the items in a toolbar and how they collapse into groups.
 */
export class EditorToolbarControl<C> {
  /**
   * constructor
   * @param toolbarItems: an ordered array of buttons and dividers to display in the toolbar
   * @param toolbarGroups: an array of groups that the toolbar items collapse into
   */
  constructor(public toolbarItems: EditorToolbarItem<C>[], public toolbarGroups?: EditorToolbarGroup[]) { }
}
