import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { omit } from 'lodash';
import { Observable, map } from 'rxjs';

export interface UserPermissionsMap {
  [Id: number]: {
    mcCachedAt: Date,
    permissions: Permissions[]
  };
}

export class CurrentUserPermissionsStateModel {
  currentLicenseId: number; // for verification
  currentUserId: string; // for verification
  licensePermissions: UserPermissionsMap;
  projectPermissions: UserPermissionsMap;
}

export class SetCurrentUserId {
  static readonly type = '[CurrentUserPermissions] SetCurrentUserId';
  constructor(public userId: string) { }
}

export class SetCurrentLicenseId {
  static readonly type = '[CurrentUserPermissions] SetCurrentLicenseId';
  constructor(public licenseId: number) { }
}

export class SetCurrentUserLicensePermissions {
  static readonly type = '[CurrentUserPermissions] SetLicensePermissions';
  constructor(public licenseId: number, public permissions: Permissions[]) { }
}

export class SetCurrentUserProjectPermissions {
  static readonly type = '[CurrentUserPermissions] SetProjectPermissions';
  constructor(public projectId: number, public permissions: Permissions[]) { }
}

export class ClearAllCurrentUserPermissions {
  static readonly type = '[CurrentUserPermissions] ClearAllUserPermissions';
}

export class Reset {
  static readonly type = '[CurrentUserPermissions] Reset';
}

const currentUserPermissionsStateDefaultValues: CurrentUserPermissionsStateModel = {
  currentLicenseId: null,
  currentUserId: null,
  licensePermissions: {},
  projectPermissions: {}
};

@State<CurrentUserPermissionsStateModel>({
  name: 'currentUserPermissionsState',
  defaults: currentUserPermissionsStateDefaultValues
})
@Injectable()
export class CurrentUserPermissionsState {

  @Selector()
  static currentUserId(state: CurrentUserPermissionsStateModel): string { return state.currentUserId; }

  @Selector()
  static currentLicenseId(state: CurrentUserPermissionsStateModel): number { return state.currentLicenseId; }

  @Selector()
  static licensePermissions(state: CurrentUserPermissionsStateModel): UserPermissionsMap { return state.licensePermissions; }

  @Selector()
  static projectPermissions(state: CurrentUserPermissionsStateModel): UserPermissionsMap { return state.projectPermissions; }


  @Action(SetCurrentUserId)
  setCurrentUserId(ctx: StateContext<CurrentUserPermissionsStateModel>, action: SetCurrentUserId) {
    ctx.patchState({
      currentUserId: action.userId
    });
  }

  @Action(SetCurrentLicenseId)
  setCurrentLicenseId(ctx: StateContext<CurrentUserPermissionsStateModel>, action: SetCurrentLicenseId) {
    ctx.patchState({
      currentLicenseId: action.licenseId
    });
  }

  @Action(SetCurrentUserLicensePermissions)
  setCurrentUserLicensePermissions(ctx: StateContext<CurrentUserPermissionsStateModel>, action: SetCurrentUserLicensePermissions) {
    if (action.permissions) {
      const now = new Date();

      ctx.patchState({
        licensePermissions: {
          [action.licenseId]: {
            mcCachedAt: now,
            permissions: action.permissions
          }
        }
      });
    } else {
      ctx.patchState({
        licensePermissions: {}
      });
    }
  }

  @Action(SetCurrentUserProjectPermissions)
  setCurrentUserProjectPermissions(ctx: StateContext<CurrentUserPermissionsStateModel>, action: SetCurrentUserProjectPermissions) {
    const existingProjectPermissions = ctx.getState().projectPermissions;

    if (action.permissions) {
      const now = new Date();

      ctx.patchState({
        projectPermissions: {
          ...existingProjectPermissions,
          [action.projectId]: {
            mcCachedAt: now,
            permissions: action.permissions
          }
        }
      });
    } else {
      ctx.patchState({
        projectPermissions: omit(existingProjectPermissions, action.projectId)
      });
    }
  }

  @Action(ClearAllCurrentUserPermissions)
  clearAllCurrentUserPermissions(ctx: StateContext<CurrentUserPermissionsStateModel>) {
    ctx.setState(currentUserPermissionsStateDefaultValues);
  }

  @Action(Reset)
  reset(ctx: StateContext<CurrentUserPermissionsStateModel>) {
    ctx.setState(currentUserPermissionsStateDefaultValues);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserPermissionsDataService {

  constructor(private store: Store) { }

  reset$(): Observable<any> {
    return this.store.dispatch(new Reset());
  }

  setCurrentLicenseId$(licenseId: number) {
    return this.store.dispatch(new SetCurrentLicenseId(licenseId));
  }

  getCurrentUserId() {
    return this.store.selectSnapshot(CurrentUserPermissionsState.currentUserId);
  }

  setCurrentUserId$(userId: string) {
    return this.store.dispatch(new SetCurrentUserId(userId));
  }

  getCurrentLicenseId() {
    return this.store.selectSnapshot(CurrentUserPermissionsState.currentLicenseId);
  }

  setLicensePermissions$(licenseId: number, permissions: Permissions[]) {
    return this.store.dispatch(new SetCurrentUserLicensePermissions(licenseId, permissions));
  }

  getLicensePermissionsByLicenseId$(licenseId: number): Observable<UserPermissionsMap> {
    return this.store.select(CurrentUserPermissionsState.licensePermissions).pipe(
      map(licensePermissions => licensePermissions && licensePermissions[licenseId] ? licensePermissions[licenseId] : undefined)
    );
  }

  setProjectPermissions$(projectId: number, permissions: Permissions[]) {
    return this.store.dispatch(new SetCurrentUserProjectPermissions(projectId, permissions));
  }

  getProjectPermissionsByProjectId$(projectId: number): Observable<UserPermissionsMap> {
    return this.store.select(CurrentUserPermissionsState.projectPermissions).pipe(
      map(projectPermissions => projectPermissions && projectPermissions[projectId] ? projectPermissions[projectId] : undefined)
    );
  }

  clearUserPermissions$(): Observable<any> {
    return this.store.dispatch(new ClearAllCurrentUserPermissions());
  }
}
