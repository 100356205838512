import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonStringsModule } from '@portal-core/i18n/components/common-strings/common-strings.module';

@NgModule({
  imports: [
    CommonModule,
    CommonStringsModule
  ]
})
export class I18nServicesModule { }
