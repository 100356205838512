import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlareFileTextEditorFeatureUnsupportedDialogComponent } from './flare-file-text-editor-feature-unsupported-dialog.component';

@NgModule({
  declarations: [
    FlareFileTextEditorFeatureUnsupportedDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule
  ],
  exports: [
    FlareFileTextEditorFeatureUnsupportedDialogComponent
  ]
})
export class FlareFileTextEditorFeatureUnsupportedDialogModule { }
