import { Fragment, MarkType, Node, ProseMirrorNode, Schema } from 'prosemirror-model';

/*
 * The base class for schemas to be used with the collab server.
 */
export abstract class CollabSchema extends Schema {
  get name(): string { return 'collab-schema'; }
  get version(): string { return '0.0.0'; }
  get changeMark(): MarkType { return this.marks[this.madcapChangeMarkName]; }

  // The madcap change mark name (defined by the schema modules)
  madcapChangeMarkName: string;

  abstract codeToDoc(code: string): ProseMirrorNode;
  abstract nodeToCode(node: Node): string;
  abstract fragmentToCode(fragment: Fragment): string;
  abstract validate(code: string): Error;

  // NOTE: this should do a semver comparison but the semver library isn't available on the client so just return true here for now
  // This function can be overridden elsewhere to actually compare the versions
  compatible(version: string): boolean {
    return true;
  }
}
