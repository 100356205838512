import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { HttpClient } from '@angular/common/http';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class TaskBoardsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getTaskBoardById$(taskBoardId: number): Observable<TaskBoard> {
    return this.http.get<TaskBoard>(`${this.apiService.centralApiBaseUri}/api/taskBoards/${taskBoardId}`);
  }

  @SharePendingRequest()
  getTaskBoardsPageByLicenseId$(licenseId: number, filter?: PageFilter): Observable<Page<TaskBoard>> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/taskBoards/license/${licenseId}`, filter);
  }

  @SharePendingRequest()
  getTaskBoardListItemsByLicenseId$(licenseId: number): Observable<TaskBoard[]> {
    return this.http.get<TaskBoard[]>(`${this.apiService.centralApiBaseUri}/api/taskBoards/license/${licenseId}/getTaskBoardsList`);
  }

  deleteTaskBoards$(taskBoardIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/taskBoards/delete`, taskBoardIds);
  }

  createTaskBoard$(taskBoard: TaskBoard): Observable<TaskBoard> {
    return this.http.post<TaskBoard>(`${this.apiService.centralApiBaseUri}/api/taskBoards/create`, taskBoard);
  }

  updateTaskBoard$(taskBoard: TaskBoard): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/taskBoards/update`, taskBoard);
  }
}
