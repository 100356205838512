import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getErrorCodes, getErrorMessageForCode, getErrorMessages, getMcErrors, hasErrorCode, hasHttpStatus } from '@common/errors/error';
import { McError } from '@common/errors/mc-error';
import { PackagedError } from '@common/errors/types/packaged-error.type';
import { instanceOfMadCloudError, instanceOfMadCloudResult } from '@common/errors/util/types';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { MadCloudError } from '@common/http/models/mad-cloud-error.model';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  getErrorMessages(error: PackagedError): string[] {
    return getErrorMessages(error);
  }

  getErrors(error: PackagedError): McError[] {
    return getMcErrors(error);
  }

  getErrorCodes(error: PackagedError): ErrorCode[] {
    return getErrorCodes(error);
  }

  hasErrorCode(error: PackagedError, errorCode: ErrorCode): boolean {
    return hasErrorCode(error, errorCode);
  }

  getErrorMessage(error: PackagedError, errorCode: ErrorCode): string {
    return getErrorMessageForCode(error, errorCode);
  }

  hasHttpStatus(error: HttpErrorResponse, statusCode: number): boolean {
    return hasHttpStatus(error, statusCode);
  }

  instanceOfMadCloudResult<T = any>(obj: any): obj is MadCloudResult<T> {
    return instanceOfMadCloudResult(obj);
  }

  instanceOfMadCloudError(obj: any): obj is MadCloudError {
    return instanceOfMadCloudError(obj);
  }
}
