import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Page } from '@common/paged-data/types/page.type';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewFileProfileDialogComponent, ReviewFileProfileDialogData } from '@portal-core/reviews/review-files/components/review-file-profile-dialog/review-file-profile-dialog.component';
import { ReviewFileProfileTab } from '@portal-core/reviews/review-files/components/review-file-profile/review-file-profile.component';
import { ReviewFileGridItem } from '@portal-core/reviews/review-files/models/review-file-grid-item.model';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { ReviewFilePageFilterConfig } from '@portal-core/reviews/review-files/util/review-file-page-filter-config';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { DataGridBase } from '@portal-core/ui/grid/util/data-grid-base';
import { DataGridControl } from '@portal-core/ui/grid/util/data-grid-control';
import { uniq } from 'lodash';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'mc-review-files-grid',
  templateUrl: './review-files-grid.component.html',
  styleUrls: ['./review-files-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFilesGridComponent extends DataGridBase<ReviewFile> implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewPackageId?: number;
  @Input() reviewersListLimit?: number = 6;
  @Input() showMenu?: boolean = true;

  CentralPermissions: typeof CentralPermissions = CentralPermissions;
  ReviewFileProfileTab: typeof ReviewFileProfileTab = ReviewFileProfileTab;

  filterConfig: ReviewFilePageFilterConfig = new ReviewFilePageFilterConfig(['Name', 'Status', 'Path', 'ReviewPackage.Name', 'UserReviewerStatus', 'UserSubmittedOn', 'ReviewersReviewerStatus', 'ReviewersSubmittedOn', 'AuthorUser.FullName', 'CreatedOn', 'Reviewers', 'ProjectId', 'BranchName', 'CommitId']);
  gridControl: DataGridControl<ReviewFileGridItem, ReviewFile> = new DataGridControl<ReviewFileGridItem, ReviewFile>(this.reviewFilesService, filter => {
    let fetch$: Observable<Page<ReviewFileGridItem>>;
    // Store the licenseId because it is used after the reviews fetch is finished and we want to make sure the matching license id is used
    let licenseId: number = this.licenseId;

    if (typeof this.reviewPackageId === 'number') {
      fetch$ = this.reviewFilesService.getReviewFilesPageByPackageId$(this.reviewPackageId, filter);
    } else if (typeof this.licenseId === 'number') {
      fetch$ = this.reviewFilesService.getReviewFilesPage$(this.licenseId, this.reviewersListLimit, filter);
    }

    if (fetch$) {
      return fetch$.pipe(
        tap(page => {
          if (Array.isArray(page.Items)) {
            // Bulk load all the users used by the review file grid items. Grab the unique users from the review owners and reviewers
            this.licenseUsersService.loadLicenseUsersByUserId$(licenseId, uniq(page.Items.flatMap(reviewFile => {
              const userIds: string[] = [];

              if (typeof reviewFile.AuthorUserId === 'number') {
                userIds.push(reviewFile.AuthorUserId);
              }

              if (Array.isArray(reviewFile.Reviewers) && reviewFile.Reviewers.length > 0) {
                userIds.push(...reviewFile.Reviewers.map(reviewer => reviewer.UserId));
              }

              return userIds;
            }))).subscribe();

            // Bulk load all the projects used by the review file grid items
            this.projectsService.loadItems$(
              uniq(
                page.Items.flatMap(reviewFile => reviewFile.ProjectId ?? null).filter(projectId => typeof projectId === 'number')
              )
            ).subscribe();

            this.reviewPackagesService.loadItems$(uniq(page.Items.map(reviewFile => reviewFile.ReviewPackageId).filter(Boolean))).subscribe();
          }
        })
      );
    } else {
      return of(null);
    }
  }, this.filterConfig);

  constructor(
    private dialog: MatDialog,
    private licenseUsersService: LicenseUsersService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private reviewFilesService: ReviewFilesService,
    private reviewPackagesService: ReviewPackagesService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId || changes.reviewPackageId) {
      this.hardReload();
    }
  }

  openReviewFileProfileDialog(reviewFile: ReviewFile, reviewFileProfileTab: ReviewFileProfileTab) {
    this.dialog.open<ReviewFileProfileDialogComponent, ReviewFileProfileDialogData>(ReviewFileProfileDialogComponent, {
      ...ReviewFileProfileDialogComponent.DialogConfig,
      data: {
        reviewFileId: reviewFile.Id,
        reviewFileProfileTab
      }
    });
  }
}
