import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ListOptionModule } from '@portal-core/ui/list/components/list-option/list-option.module';
import { ListItemModule } from '@portal-core/ui/list/directives/list-item/list-item.module';
import { InfiniteListComponent } from './infinite-list.component';

@NgModule({
  declarations: [
    InfiniteListComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    ListItemModule,
    ListOptionModule,
    LoaderModule,
    MatListModule,
    MatProgressBarModule,
    RouterModule,
    ScrollingModule
  ],
  exports: [
    InfiniteListComponent
  ]
})
export class InfiniteListModule { }
