import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { BehaviorSubject } from 'rxjs';

export class ProjectFileNode {
  childrenChange: BehaviorSubject<ProjectFileNode[]> = new BehaviorSubject<ProjectFileNode[]>([]);

  get children(): ProjectFileNode[] {
    return this.childrenChange.value;
  }

  constructor(
    public name: string,
    public id: number,
    public path: string,
    public flatFileNode: ProjectFileFlatNode,
    public parentNode: ProjectFileNode,
    public hasChildren: boolean = false
  ) { }
}
