import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TranslationPackageLanguageBranchesState } from '@portal-core/translation-package-language-branches/services/translation-package-language-branches-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([TranslationPackageLanguageBranchesState])
  ]
})
export class TranslationPackageLanguageBranchesServicesModule { }
