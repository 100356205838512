import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DateModule } from '@portal-core/general/pipes/date/date.module';
import { FlareFileTextEditorTagbarComponent } from './flare-file-text-editor-tagbar.component';

@NgModule({
  declarations: [
    FlareFileTextEditorTagbarComponent
  ],
  imports: [
    CommonModule,
    DateModule,
    MatButtonModule,
    MatToolbarModule
  ],
  exports: [
    FlareFileTextEditorTagbarComponent
  ]
})
export class FlareFileTextEditorTagbarModule { }
