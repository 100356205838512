import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { LicenseStorage } from '@portal-core/license-storage/models/license-storage.model';
import { LicenseStorageService } from '@portal-core/license-storage/services/license-storage.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseProfileTab } from '@portal-core/licenses/components/license-profile-dialog/license-profile-dialog.component';
import { LicenseType } from '@portal-core/licenses/enums/license-type.enum';
import { SubscriptionAdditionSeat } from '@portal-core/licenses/models/license-subscription-addition-seat.model';
import { SubscriptionAddition } from '@portal-core/licenses/models/license-subscription-addition.model';
import { SubscriptionOrderItem } from '@portal-core/licenses/models/license-subscription-order-item.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { SettingsPurchasePriceError } from '@portal-core/users/enums/settings-purchase-price-error.enum';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import dayjs from 'dayjs';
import { Observable, Subscription, distinctUntilChanged, filter, first, map, of, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-license-purchasing-form',
  templateUrl: './license-purchasing-form.component.html',
  styleUrls: ['./license-purchasing-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicensePurchasingFormComponent implements OnChanges {
  @Input() license: License;
  @Input() licenseUser: LicenseUser;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectLicenseProfileTab: EventEmitter<number> = new EventEmitter<number>();

  SettingsPurchasePriceError = SettingsPurchasePriceError;

  licenseStorage$: Observable<LicenseStorage>;
  expirationDate$: Observable<Date>;

  orderItems: SubscriptionOrderItem[];
  changeGeneralSettingsForm: UntypedFormGroup;
  priceError = SettingsPurchasePriceError.None;
  hideConfirmPurchase = true;
  OneGBInBytes = Math.pow(2, 30); // Used in template for storage size conversion
  storageStepSize = 10;
  maxSeatSize = 99999;
  trialFreeGb = 10;
  price = 0;
  isTrial: boolean;
  awaitingResponse = false;

  userCanPurchase$: Observable<boolean>;
  userCanManageServer$: Observable<boolean>;
  formChangeSubscription: Subscription;
  licenseStorage: LicenseStorage;

  constructor(
    private licensesService: LicensesService,
    private licenseStorageService: LicenseStorageService,
    private errorService: ErrorService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef
  ) { }

  // Wait for license to be loaded
  ngOnChanges(changes: SimpleChanges) {
    if (changes.license && changes.license.currentValue !== null) {
      if (changes.license.previousValue !== changes.license.currentValue) {
        this.isTrial = this.license.LicenseType === LicenseType.Trial;
      }
      if (!this.licenseStorage$) {
        // Create an observable for the license storage
        this.licenseStorage$ = this.licenseStorageService.getItemById$(this.license.Id).pipe(
          tap(licenseStorage => {
            if (this.changeGeneralSettingsForm) {
              return;
            }
            this.licenseStorage = licenseStorage;
            // Build the form the first time
            this.changeGeneralSettingsForm = this.formBuilder.group(this.buildForm(licenseStorage));
            // Kick off first calculation
            this.changeGeneralSettingsForm.markAsDirty();
            this.formChangeSubscription = this.changeGeneralSettingsForm.valueChanges.pipe(
              startWith(null)
            ).subscribe(this.onFormChanges.bind(this));
          })
        );

        this.expirationDate$ = this.licenseStorage$.pipe(
          map(licenseStorage => {
            // If not date specified, assume it expires in a month
            return licenseStorage.CentralEndDate ? licenseStorage.CentralEndDate : dayjs(licenseStorage.CentralStartDate).add(1, 'month').toDate();
          })
        );
      } else if (changes.license.previousValue !== changes.license.currentValue) {
        // Update the form with new license
        this.licenseStorageService.getItemById$(this.license.Id, { forceApiRequest: true }).pipe(
          first(),
          tap(licenseStorage => this.licenseStorage = licenseStorage)
        ).subscribe();
      }
    }

    if (changes.licenseUser) {
      if (this.licenseUser) {
        this.userCanManageServer$ = this.permissionsService.licenseUserHasPermission$(of(this.licenseUser), CentralPermissions.ServerManagement);
        this.userCanPurchase$ = this.permissionsService.licenseUserHasPermission$(of(this.licenseUser), CentralPermissions.Purchasing);
      } else {
        this.userCanManageServer$ = null;
        this.userCanPurchase$ = null;
      }
    }
  }

  onFormChanges(): void {
    // Round to step size
    const roundedStorage = Math.ceil(this.changeGeneralSettingsForm.value.storage / this.storageStepSize) * this.storageStepSize;
    if (this.changeGeneralSettingsForm.value.storage !== roundedStorage) {
      this.changeGeneralSettingsForm.controls['storage'].setValue(roundedStorage);
      return;
    }

    if ((this.isTrial ? 0 : this.licenseStorage.SmeSeats) + this.changeGeneralSettingsForm.value.smes > this.maxSeatSize ||
      (this.isTrial ? 0 : this.licenseStorage.AuthorSeats) + this.changeGeneralSettingsForm.value.authors > this.maxSeatSize) {
      this.priceError = SettingsPurchasePriceError.SeatSize;
      this.orderItems = null;
      this.cdr.markForCheck();
      return;
    }

    if (!this.changeGeneralSettingsForm.pristine) {
      const subscription = this.createSubscription();
      this.licensesService.calculateSubscriptionAdditions$(this.license.Id, subscription).pipe(distinctUntilChanged()).subscribe(orderItems => {
        this.orderItems = orderItems.length ? orderItems : null;
        const add = (sum, n) => sum + n;
        const saleTotals = orderItems.map(orderItem => orderItem.SaleTotal);
        this.priceError = SettingsPurchasePriceError.None;
        this.price = saleTotals.length !== 0 ? saleTotals.reduce(add) : 0;
        this.cdr.markForCheck();
      },
        () => {
          this.orderItems = null;
          this.priceError = SettingsPurchasePriceError.Calculation;
          this.cdr.markForCheck();
        });
    } else {
      this.orderItems = null;
      this.price = 0;
      this.priceError = SettingsPurchasePriceError.None;
      this.cdr.markForCheck();
    }
  }

  saveGeneralSettings() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        action: 'OK',
        title: 'Purchase Confirmation',
        prompt: 'Please confirm your purchase.'
      }
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      switchMap(() => {
        const subscription = this.createSubscription();
        this.awaitingResponse = true;
        this.cdr.markForCheck();
        return this.licensesService.processSubscriptionAdditions$(this.license.Id, subscription);
      })
    ).subscribe(ok => {
      this.awaitingResponse = false;
      this.showMessage('Thank you! Your purchase is processing!');
      const controls = this.changeGeneralSettingsForm.controls;
      controls.storage.setValidators(Validators.min(0));
      controls.authors.setValidators([Validators.min(0), Validators.max(this.maxSeatSize)]);
      controls.smes.setValidators([Validators.min(0), Validators.max(this.maxSeatSize)]);
      this.changeGeneralSettingsForm.patchValue({ storage: 0, authors: 0, smes: 0 }, { emitEvent: false });
      this.changeGeneralSettingsForm.updateValueAndValidity({ emitEvent: false });
      this.changeGeneralSettingsForm.markAsPristine();
      this.hideConfirmPurchase = true;
      this.cdr.markForCheck();
    },
      (errorResponse: HttpErrorResponse) => {
        this.awaitingResponse = false;
        this.displayError('Purchase Failed', 'Please contact us at sales@madcapsoftware.com or call +1 (858) 320-0387.', errorResponse);
        this.cdr.markForCheck();
      }
    );
  }

  private buildForm(licenseStorage: LicenseStorage) {
    // Minimum storage space that a trial user must buy to be a step size above
    let trialStorageMinimum = Math.ceil(licenseStorage.UsedStorageSpace / this.OneGBInBytes / this.storageStepSize) * this.storageStepSize;
    // Adjust for the free 10 Gb
    trialStorageMinimum -= trialStorageMinimum;
    // Make sure the minimum is positive
    trialStorageMinimum = Math.max(trialStorageMinimum, 0);
    return {
      storage: new UntypedFormControl(this.isTrial ? trialStorageMinimum : 0, Validators.min(this.isTrial ? trialStorageMinimum : 0)),
      authors: new UntypedFormControl(this.isTrial ? licenseStorage.UsedAuthorSeats : 0, [Validators.min(this.isTrial ? licenseStorage.UsedAuthorSeats : 0), Validators.max(this.maxSeatSize)]),
      smes: new UntypedFormControl(this.isTrial ? licenseStorage.UsedSmeSeats : 0, [Validators.min(this.isTrial ? licenseStorage.UsedSmeSeats : 0), Validators.max(this.maxSeatSize)])
    };
  }

  private createSubscription() {
    const additionalSeats: SubscriptionAdditionSeat[] = [
      { SeatType: LicenseUserSeatType.Author, SeatQty: this.changeGeneralSettingsForm.value.authors },
      { SeatType: LicenseUserSeatType.SME, SeatQty: this.changeGeneralSettingsForm.value.smes }
    ];
    const subscription: SubscriptionAddition = {
      AdditionalSeats: additionalSeats,
      AdditionalStorageQty: this.isTrial ? this.changeGeneralSettingsForm.value.storage + 10 : this.changeGeneralSettingsForm.value.storage,
      AdditionalMonths: 12
    };
    return subscription;
  }

  private showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 2500,
    });
  }

  private displayError(title: string, message: string, errorResponse: HttpErrorResponse) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: title,
        message: message,
        errors: this.errorService.getErrorMessages(errorResponse)
      }
    });
  }

  openBillingTab() {
    this.selectLicenseProfileTab.emit(LicenseProfileTab.Billing);
  }
}
