import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';
import { TranslationPackageUpdate } from '@portal-core/translation-packages/models/translation-package-update.model';
import { TranslationPackage } from '@portal-core/translation-packages/models/translation-package.model';
import { TranslationPackagesApiService } from '@portal-core/translation-packages/services/translation-packages-api.service';
import { TranslationPackagesDataService } from '@portal-core/translation-packages/services/translation-packages-data.service';
import { Resettable } from '@portal-core/util/resettable.decorator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class TranslationPackagesService extends CollectionServiceBase<TranslationPackage> {
  constructor(private translationPackagesApiService: TranslationPackagesApiService, private translationPackagesDataService: TranslationPackagesDataService, protected dataService: DataService) {
    super(translationPackagesDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<TranslationPackage> {
    return this.translationPackagesApiService.getTranslationPackageById$(itemId);
  }

  protected fetchItemsById$(itemIds: number[]): Observable<TranslationPackage[]> {
    return this.translationPackagesApiService.getBatchTranslationPackagesByIds$(itemIds);
  }

  getProjectPackages$(projectId: number, filter: PageFilter): Observable<Page<TranslationPackage>> {
    return this.translationPackagesApiService.getProjectPackages$(projectId, filter);
  }

  createTranslationPackage$(packageModel: TranslationPackage): Observable<TranslationPackage> {
    return this.translationPackagesApiService.createTranslationPackage$(packageModel);
  }

  updatePackage$(translationPackage: TranslationPackageUpdate): Observable<MadCloudResult> {
    return this.translationPackagesApiService.updatePackage$(translationPackage);
  }

  getArchivePath(translationPackageId: number): string {
    return this.translationPackagesApiService.getArchivePath(translationPackageId);
  }

  getPackageManifest$(translationPackageId: number): Observable<any> {
    return this.translationPackagesApiService.getPackageManifest$(translationPackageId);
  }

  deletePackage$(translationPackageId: number): Observable<MadCloudResult> {
    return this.translationPackagesApiService.deletePackage$(translationPackageId);
  }

  deletePackages$(translationPackageIds: number[]): Observable<MadCloudResult> {
    return this.translationPackagesApiService.deletePackages$(translationPackageIds);
  }

  updatePackageStatuses$(translationPackageIds: number[], status: TranslationPackageStatus): Observable<MadCloudResult> {
    return this.translationPackagesApiService.updatePackageStatuses$(translationPackageIds, status);
  }

}
