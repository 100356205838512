import { FlareMadCapXMLNSAttribute } from '@common/flare/constants/flare-madcap-xmlns.constant';
import { FlareXMLDeclaration } from '@common/flare/constants/flare-xml-declaration.constant';

/**
 * Normalizes markup to be Flare XML compliant by wrapping the content in an html and body tag if they are not present.
 * And prepends the markup with an XML declaration if it is not there.
 * @param xml The XML markup to normalize.
 * @returns The normalized XML markup.
 */
export function normalizeFlareXML(xml: string): string {
  xml = xml?.trim() ?? '';

  let prepend = '';
  let append = '';

  if (!xml.startsWith(FlareXMLDeclaration)) {
    prepend = FlareXMLDeclaration;

    if (!xml.startsWith('<html')) {
      prepend += `<html ${FlareMadCapXMLNSAttribute}>`;
      append = '</html>';

      if (!xml.startsWith('<body')) {
        prepend += '<body>';
        append = '</body>' + append;
      }
    }
  }

  return prepend + xml + append
}
