import { reduce } from 'lodash';
import { PageFilterGroup } from '@common/paged-data/types/page-filter-group.type';
import { PageFilterOptions } from '@common/paged-data/types/page-filter-options.type';

/**
 * Sums all the filters in the filter group.
 * @param filterGroup The filter group to count the filters in.
 */
export function getFilterCount(filterGroup: PageFilterGroup): number {
  let count = 0;

  if (Array.isArray(filterGroup?.Filters)) {
    count += filterGroup.Filters.length;
  }

  if (Array.isArray(filterGroup?.FilterGroups)) {
    count += filterGroup.FilterGroups.reduce((sum, subFilterGroup) => {
      return sum + getFilterCount(subFilterGroup);
    }, 0);
  }

  return count;
}

/**
 * Sums all the filters in a dictionary of filter groups.
 * @param filters A dictionary of filter groups to count the filters in.
 */
export function getFilterCountFromDictionary(filters: Dictionary<PageFilterGroup>): number {
  return reduce(filters, (sum, filterGroup) => {
    return sum + getFilterCount(filterGroup);
  }, 0);
}

/**
 * Check whether an object is a PageFilterOptions instance.
 * @param obj The object to check the type of.
 * @returns true if the object is a PageFilterOptions instance.
 */
export function isPageFilterOptions(obj: any): obj is PageFilterOptions {
  return obj && typeof obj === 'object' && 'PropertyName' in obj && 'FilterType' in obj;
}
