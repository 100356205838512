import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUserStatus } from '@portal-core/license-users/enums/license-user-status.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-user-deletion-form',
  templateUrl: './user-deletion-form.component.html',
  styleUrls: ['./user-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDeletionFormComponent implements OnInit {
  @Input() licenseUser: LicenseUser;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deactivate: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() deactivated: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();
  showCancelButton: boolean;

  constructor(private licenseUsersService: LicenseUsersService, private errorService: ErrorService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.showCancelButton = this.cancel.observers.length > 0;
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onDeleteClicked() {
    const deleteEvent = new CancelableEvent();
    this.delete.emit(deleteEvent);

    if (!deleteEvent.defaultPrevented) {
      this.savingState.update(true);

      this.licenseUsersService.bulkDeleteLicenseUsers$(this.licenseUser.LicenseId, [this.licenseUser.Id]).subscribe(() => {
        this.savingState.update(false);

        this.snackBar.open('The user has been deleted.', 'OK', {
          duration: 2500
        });

        this.deleted.emit();
      }, error => {
        this.savingState.update(false, 'Unable to delete the user.', this.errorService.getErrorMessages(error));
      });
    }
  }

  onSubmit() {
    const deactivateEvent = new CancelableEvent();
    this.deactivate.emit(deactivateEvent);

    if (!deactivateEvent.defaultPrevented) {
      this.savingState.update(true);

      this.licenseUsersService.bulkSetLicenseUsersStatus$(this.licenseUser.LicenseId, LicenseUserStatus.Deactivated, [this.licenseUser.Id]).subscribe(() => {
        this.savingState.update(false);

        this.snackBar.open('The user has been deactivated.', 'OK', {
          duration: 2500
        });

        this.deactivated.emit();
      }, error => {
        this.savingState.update(false, 'Unable to deactivate the user.', this.errorService.getErrorMessages(error));
      });
    }
  }
}
