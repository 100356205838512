import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { ReviewPackageProfileDialogComponent, ReviewPackageProfileDialogData } from '@portal-core/reviews/review-packages/components/review-package-profile-dialog/review-package-profile-dialog.component';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-review-package-avatar',
  templateUrl: './review-package-avatar.component.html',
  styleUrls: ['./review-package-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ReviewPackageAvatarComponent extends AvatarBase implements OnInit {
  @Input() color?: 'invert';
  @Input() nameLinksToPage?: boolean = true;
  @Input() reviewPackage: ReviewPackage;
  @Input() showId?: boolean = false;

  private currentUserHasPermission: boolean = false;
  private isAuthorSubscription: Subscription;

  constructor(private dialog: MatDialog, private currentService: CurrentService) {
    super();
  }

  ngOnInit() {
    this.isAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      this.currentUserHasPermission = isAuthor || this.currentService.isSysAdmin();
    });
  }

  onAvatarClicked() {
    this.openReviewPackageProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return this.currentUserHasPermission;
  }

  isDeleted(): boolean {
    return this.reviewPackage?.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.reviewPackage;
  }

  isLink(): boolean {
    return super.isLink() && this.nameLinksToPage;
  }

  /** Override the default to not check if the user hasPermission() when the name is a link because all user types have permission to use the link. */
  isNameDisabled(): boolean {
    return this.disabled || this.disabledName || this.isEmpty() || this.isDeleted() || (!this.nameLinksToPage && !this.hasPermission());
  }

  private openReviewPackageProfileDialog() {
    this.dialog.open<ReviewPackageProfileDialogComponent, ReviewPackageProfileDialogData>(ReviewPackageProfileDialogComponent, {
      ...ReviewPackageProfileDialogComponent.DialogConfig,
      data: {
        reviewPackageId: this.reviewPackage?.Id
      }
    });
  }
}
