import { GoogleSheetsWrapperNormalizer } from '@common/clipboard/google-sheets/google-sheets-wrapper';
import { StyleTagsNormalizer } from '@common/clipboard/google-sheets/style-tags';
import { TablesNormalizer } from '@common/clipboard/google-sheets/tables';
import { HtmlPasteNormalizerData } from '@common/clipboard/types/html-paste-normalizer-data.type';
import { HtmlPasteNormalizerOptions } from '@common/clipboard/types/html-paste-normalizer-options.type';
import { HtmlPasteNormalizer } from '@common/clipboard/types/html-paste-normalizer.type';

const googleSheetsRegex = /<google-sheets-html-origin/i;

/**
 * Normalizes content pasted from Google Sheets.
 */
export class GoogleSheetsNormalizer implements HtmlPasteNormalizer {
  /**
   * Checks if the HTML is being pasted from Google Sheets.
   * @param html The HTML to check.
   * @returns `true` if the HTML is from Google Sheets, otherwise `false`.
   */
  matches(html: string): boolean {
    return googleSheetsRegex.test(html);
  }

  /**
   * Converts Google Sheets HTML to standard HTML.
   * @param data The data to normalize.
   * @param options The options to use when normalizing.
   * @returns The normalized HTML document.
   */
  normalize(data: HtmlPasteNormalizerData, options?: HtmlPasteNormalizerOptions): Document {
    const doc = data.doc;

    // Remove the Google Sheets wrapper
    const googleSheetsWrapperNormalizer = new GoogleSheetsWrapperNormalizer();
    googleSheetsWrapperNormalizer.normalize(doc);

    // Normalize the table
    const tablesNormalizer = new TablesNormalizer();
    tablesNormalizer.normalize(doc);

    // Normalize the style tags
    const styleTagsNormalizer = new StyleTagsNormalizer();
    styleTagsNormalizer.normalize(doc);

    return doc;
  }
}
