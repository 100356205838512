import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { cache } from '@common/util/cache.operator';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { ProfilesService } from '@portal-core/profiles/services/profiles.service';
import { SiteSettingsFormComponent } from '@portal-core/sites/components/site-settings-form/site-settings-form.component';
import { SiteTeamsFormComponent } from '@portal-core/sites/components/site-teams-form/site-teams-form.component';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { LoadingState } from '@portal-core/util/loading-state';
import { BehaviorSubject, combineLatest, first, Observable, switchMap } from 'rxjs';

export interface SiteProfileDialogData {
  siteId: number;
  siteProfileTab?: SiteProfileTab;
}

export enum SiteProfileTab {
  Delete,
  Teams,
  Settings
}

@Component({
  selector: 'mc-site-profile-dialog',
  templateUrl: './site-profile-dialog.component.html',
  styleUrls: ['./site-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteProfileDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '90rem',
    height: '63rem',
    autoFocus: false
  };

  @ViewChild('settingsForm', { static: false }) settingsFormComponent: SiteSettingsFormComponent;
  @ViewChild('teamsForm', { static: false }) teamsFormComponent: SiteTeamsFormComponent;

  SiteProfileTab: typeof SiteProfileTab = SiteProfileTab;
  loadingState: LoadingState<string> = new LoadingState<string>();
  siteProfileTab: SiteProfileTab;
  site$: Observable<SiteGridItem>;
  liveSiteUrl$: Observable<string>;
  private reload$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<SiteProfileDialogComponent>,
    private profilesService: ProfilesService,
    private errorService: ErrorService,
    private licensesService: LicensesService,
    private sitesService: SitesService,
    @Inject(MAT_DIALOG_DATA) public data: SiteProfileDialogData
  ) {
    super(dialog, dialogRef);
  }

  get requirePromptOnClose(): boolean {
    return this.settingsFormComponent?.siteSettingsForm?.dirty || this.teamsFormComponent?.teamPristine === false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.loadingState.update(true);
    this.site$ = this.reload$.pipe(
      switchMap(() => this.profilesService.getSiteProfile$(this.data.siteId)),
      cache()
    );

    this.liveSiteUrl$ = this.site$.pipe(
      switchMap(site => this.licensesService.getItemById$(site.LicenseId)),
      switchMap(license => this.sitesService.getLiveSiteUrl$(license.VanityBaseUrl))
    );

    combineLatest([
      this.site$,
      this.liveSiteUrl$
    ]).pipe(
      first()
    ).subscribe(() => {
      this.siteProfileTab = this.data.siteProfileTab != null ? this.data.siteProfileTab : SiteProfileTab.Settings;
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Failed to load the site.', this.errorService.getErrorMessages(error));
    });
  }

  onSaved() {
    this.reload$.next();
  }
}
