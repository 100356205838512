import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class FormSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    form: {
      group: 'block',
      content: 'block*',
      atom: true,
      parseDOM: [{
        tag: 'form'
      }],
      toDOM() { return ['div', { class: 'mc-pm-form' }, 'Form']; }
    },
    input: {
      group: 'inline',
      inline: true,
      definingForContent: true,
      attrs: {
        type: { default: undefined }
      },
      parseDOM: [{
        tag: 'input',
        getAttrs: function (dom: HTMLElement) {
          return getSchemaAttrs(dom, ['type']);
        }
      }],
      toDOM(node: ProseMirrorNode) { return ['span', { class: 'mc-pm-input' }, `Input${node.attrs.type ? ` type=${node.attrs.type}` : ''}`]; },
      isVoid: true
    }
  };
}
