import { Pipe, PipeTransform } from '@angular/core';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';

@Pipe({
  name: 'mcReviewFileAvatarTooltip'
})
export class ReviewFileAvatarTooltipPipe implements PipeTransform {
  transform(status: ReviewFileStatus | null | undefined, isDeleted: boolean, nameOrPath?: string | null | undefined): string {
    let statusText: string = '';

    if (isDeleted) {
      statusText = 'Removed from review package.';
    } else {
      switch (status) {
        case ReviewFileStatus.Open:
          statusText = 'Open';
          break;
        case ReviewFileStatus.Closed:
          statusText = 'Closed - this file can no longer be edited.';
          break;
        case ReviewFileStatus.Accepted:
          statusText = 'Accepted - this file can no longer be edited.';
          break;
      }
    }

    return `${nameOrPath}${nameOrPath ? '\n\n' : ''}${statusText}`;
  }
}
