import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BuildProfileDialogComponent } from '@portal-core/builds/components/build-profile-dialog/build-profile-dialog.component';
import { Build } from '@portal-core/builds/models/build.model';
import { StringService } from '@portal-core/general/services/string.service';
import { ProcessState } from '@portal-core/processes/enums/process-state.enum';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-build-avatar',
  templateUrl: './build-avatar.component.html',
  styleUrls: ['./build-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildAvatarComponent extends AvatarBase {
  @Input() build?: Build;
  @Input() latest?: boolean;
  @Input() showCompletedDate?: boolean = false;

  get name(): string {
    let name = '';

    if (this.latest) {
      name = 'Latest Build';
    } else if (this.build) {
      name = `${this.build.Id}`;
    }

    if (this.showCompletedDate && name && this.build?.LastNotification?.ProcessNotification) {
      name += ` - ${this.stringService.formatDate(this.build.LastNotification.ProcessNotification.StartTime, 'MMM dd, yyyy h:mm a')}`;
    }

    return name;
  }

  get tooltip(): string {
    let tooltip = '';

    if (this.build) {
      tooltip = `Build ${this.build.Id}`;

      switch (this.build.LastNotification?.ProcessState) {
      case ProcessState.Complete:
        if (this.build.LastNotification.ProcessNotification) {
          tooltip += ` completed ${this.stringService.formatDate(this.build.LastNotification.ProcessNotification.StartTime, 'MMM dd, yyyy h:mm a')}`;
        }
        break;
      case ProcessState.Cancelled:
        if (this.build.LastNotification.ProcessNotification) {
          tooltip += ` canceled ${this.stringService.formatDate(this.build.LastNotification.ProcessNotification.StartTime, 'MMM dd, yyyy h:mm a')}`;
        }
        break;
      case ProcessState.Failed:
        if (this.build.LastNotification.ProcessNotification) {
          tooltip += ` failed ${this.stringService.formatDate(this.build.LastNotification.ProcessNotification.StartTime, 'MMM dd, yyyy h:mm a')}`;
        }
        break;
      default:
        tooltip += ` queued ${this.stringService.formatDate(this.build.CreatedOn, 'MMM dd, yyyy h:mm a')}`;
        break;
      }
    } else if (this.latest) {
      tooltip = 'Latest Build';
    }

    return tooltip;
  }

  constructor(private dialog: MatDialog, private stringService: StringService) {
    super();
  }

  onAvatarClicked() {
    this.openBuildProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.build && !this.latest;
  }

  private openBuildProfileDialog() {
    this.dialog.open(BuildProfileDialogComponent, {
      ...BuildProfileDialogComponent.DialogConfig,
      data: {
        buildId: this.build.Id,
      }
    });
  }
}
