import { MadCapVoidTags } from '@common/flare/constants/madcap-void-tags.constant';
import { FlareSchema } from '@common/flare/flare-schema';
import { ProsemirrorFlareDOMParser } from '@common/flare/prosemirror-flare-dom-parser';
import { FlareSerializerExportOptions, ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { defaultToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { TrackedChangesExporter } from '@common/flare/util/tracked-changes-exporter';
import { TrackedChangesImporter } from '@common/flare/util/tracked-changes-importer';
import { DocSizeError } from '@common/html/constants/doc-size-error.constant';
import { NodeType } from '@common/html/enums/node-type.enum';
import { parseFromString } from '@common/html/simple-dom/parse';
import { ValidationOptions } from '@common/html/types/serialization-options.type';
import { nbsp } from '@common/html/util/dom';
import { stripBOM } from '@common/html/util/xml';
import { CustomError } from '@common/util/custom-error';
import { byteSize } from '@common/util/utf';
import { Fragment, ProseMirrorNode } from 'prosemirror-model';
import { v4 as uuidv4 } from 'uuid';

export class FlareSerializer {
  codeToDoc(code: string, schema: FlareSchema): ProseMirrorNode {
    code = stripBOM(code);

    // Throw an error if the doc is too large
    this.throwIfDocTooLarge(code, schema.docMaxCharLength);

    // Throw an error if the doc is empty or doesn't start with an xml declaration
    if (!code || !code.startsWith('<?xml ')) {
      throw new Error('The document must begin with an xml declaration');
    }

    const trackedChangesImporter = new TrackedChangesImporter();
    let processingBody = false;

    // Convert the html string into an xml dom
    const xmlDoc = parseFromString(code, 'text/xml', {
      // Enable validation with strictMode
      strictMode: true,
      // Register the MadCap void tags so they are serialized correctly
      registerVoidTags: MadCapVoidTags,
      // Register elements with custom outerHTML implementations
      registerElements: {
        // Because comments are replaced with MadCap:centralComment nodes during the parse we need to serialize them back to regular comments in their outerHTML
        'MadCap:centralComment': {
          outerHTML(node) {
            return `<!--${node.textContent}-->`;
          }
        },
        // Because cdata nodes are replaced with MadCap:centralCData nodes during the parse we need to serialize them back to regular cdata in their outerHTML
        'MadCap:centralCData': {
          outerHTML(node) {
            return `<![CDATA[${node.textContent}]]>`;
          }
        }
      },
      // Use onNodeStart and onNodeEnd to track when the body is being processed
      onNodeStart: (node) => {
        if (node.nodeName === 'body') {
          processingBody = true;
        }
      },
      onNodeEnd: (node) => {
        if (node.nodeName === 'body') {
          processingBody = false;
        }
      },
      // Process nodes after they have been parsed
      onNode: (node) => {
        // Extract the change data stored in the head so that its available when processing nodes in the body
        if (node.nodeName === 'MadCap:changeData') {
          trackedChangesImporter.extractChangeData(node);
          node.remove();
          return;
        }

        // Only process nodes in the body
        if (processingBody) {
          // Convert comment nodes to MadCap:centralComment nodes
          if (node.nodeType === NodeType.COMMENT_NODE) {
            const comment = node.ownerDocument.createElement('MadCap:centralComment');
            comment.appendChild(node.ownerDocument.createTextNode(node.nodeValue));
            node.replaceWith(comment);
            node = comment;
          }

          // Convert cdata nodes to MadCap:centralCData nodes
          if (node.nodeType === NodeType.CDATA_SECTION_NODE) {
            const cdata = node.ownerDocument.createElement('MadCap:centralCData');
            cdata.appendChild(node.ownerDocument.createTextNode(node.nodeValue));
            node.replaceWith(cdata);
            node = cdata;
          }

          // Add a MadCap:guid attribute to any MadCap:annotation node that does not have one
          if (node.nodeName === 'MadCap:annotation') {
            if (!node.getAttribute('MadCap:guid')) {
              node.setAttribute('MadCap:guid', uuidv4());
            }
          }

          // Remove nbsp entities from nodes if the node's content is only one nbsp entity
          // Do not modify MadCap:change or MadCap:centralCData nodes because we do not want to lose their content
          if (node.nodeName !== 'MadCap:change' && node.nodeName !== 'MadCap:centralCData') {
            if (node.childNodes.length === 1 && node.firstChild.nodeType === NodeType.TEXT_NODE && node.firstChild.nodeValue === nbsp) {
              node.removeChild(node.firstChild);
            }
          }
        }
      }
    });

    // Throw an error if the doc doesn't have one root tag
    const rootChildren = xmlDoc.children;
    if (rootChildren.length === 0) {
      throw new Error(`Root tag of document must be 'html'`);
    } else if (rootChildren.length > 1) {
      throw new Error(`Document must have only one root tag`);
    }

    // Convert the xml dom into a prosemirror doc
    const pmDoc = ProsemirrorFlareDOMParser.fromSchema(schema).parse(xmlDoc as unknown as Document);

    // Update the prosemirror doc nodes with the tracked change data
    trackedChangesImporter.injectChangeData(pmDoc);

    return pmDoc;
  }

  nodeToCode(node: ProseMirrorNode, schema: FlareSchema, options?: FlareSerializerExportOptions): string {
    return this.toFlareXML(node, schema, options);
  }

  fragmentToCode(fragment: Fragment, schema: FlareSchema, options?: FlareSerializerExportOptions): string {
    return this.toFlareXML(fragment, schema, options);
  }

  // Validates flare code. Returns an error object if the code is invalid. Otherwise returns null.
  validate(code: string, schema: FlareSchema, options?: ValidationOptions): Error | null {
    // Validate the code by running the parser
    try {
      this.codeToDoc(code, schema);
    } catch (ex) {
      return ex;
    }

    return null;
  }

  private toFlareXML(nodeOrFragment: ProseMirrorNode | Fragment, schema: FlareSchema, options?: FlareSerializerExportOptions): string {
    // We don't want to modify the options object passed in so create a new object with the trackedChangesExporter property
    const trackedChangesExporter = new TrackedChangesExporter();
    const toFlareXmlOptions: ToFlareXMLOptions = {
      ...options,
      trackedChangesExporter
    };

    let xml = '';

    if (nodeOrFragment instanceof Fragment) {
      nodeOrFragment.forEach(node => xml += (node.type.spec.toFlareXML || defaultToFlareXML)(node, toFlareXmlOptions));
    } else {
      xml = (nodeOrFragment.type.spec.toFlareXML || defaultToFlareXML)(nodeOrFragment, toFlareXmlOptions);
    }

    trackedChangesExporter.clear();

    this.throwIfDocTooLarge(xml, schema.docMaxCharLength);

    return xml;
  }

  private throwIfDocTooLarge(markup: string, maxDocCharLength: number) {
    if (typeof maxDocCharLength === 'number' && markup?.length > maxDocCharLength) {
      const error = new CustomError(DocSizeError, `Cannot load document, document is ${markup.length} characters long and must be ${maxDocCharLength} characters or less in length.`);
      (error as any).docLength = markup.length;
      (error as any).docSize = byteSize(markup);
      throw error;
    }
  }
}
