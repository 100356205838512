import { ViewportRuler } from '@angular/cdk/scrolling';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { EditorToolbarItemDirective } from '@portal-core/ui/editor/directives/editor-toolbar-item/editor-toolbar-item.directive';
import { EditorToolbarDropdownMenuClosedEvent } from '@portal-core/ui/editor/types/editor-toolbar-dropdown-menu-closed-event.type';
import { EditorToolbarItem } from '@portal-core/ui/editor/types/editor-toolbar-item.type';
import { EditorToolbarControl } from '@portal-core/ui/editor/util/editor-toolbar-control';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, of, startWith, switchMap, tap } from 'rxjs';

/**
 * EditorToolbarComponent<C, U>
 * C: The command type such as Command from CodeMirror or ProseMirror.
 * U: The type passed to the updateState method to update the toolbar items.
 */
@Component({
  selector: 'mc-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorToolbarComponent<C, U> implements OnInit, AfterContentInit {
  @Input() toolbarControl: EditorToolbarControl<C>;
  @Output() itemClick: EventEmitter<EditorToolbarItem<C>> = new EventEmitter<EditorToolbarItem<C>>();
  @Output() dropdownMenuClosed: EventEmitter<EditorToolbarDropdownMenuClosedEvent<C>> = new EventEmitter<EditorToolbarDropdownMenuClosedEvent<C>>();

  @ViewChild('toolbar', { read: ElementRef, static: true }) toolbarRef: ElementRef<HTMLElement>;
  @ContentChildren(EditorToolbarItemDirective) toolbarItemDirectives: QueryList<EditorToolbarItemDirective>;

  @PropertyObservable('toolbarControl') toolbarControl$: Observable<EditorToolbarControl<C>>;

  calculatingLayout$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  items$: Observable<EditorToolbarItem<C>[]>;
  toolbarItemTemplates: Map<string, TemplateRef<any>>;

  private clickedItem: EditorToolbarItem<C>;
  private collapse$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private collapsedGroups: Set<string> = new Set<string>();
  private updateState$: BehaviorSubject<U> = new BehaviorSubject<U>(null);

  constructor(private elementRef: ElementRef<HTMLElement>, private changeDetectorRef: ChangeDetectorRef, private viewportRuler: ViewportRuler) { }

  ngOnInit() {
    this.items$ = combineLatest([
      // Observe the toolbarControl changing
      this.toolbarControl$.pipe(
        // The toolbar control changed which means the toolbar items may have changed. Clear out the collapsed groups so that collapsing can start again
        tap(() => {
          this.collapsedGroups.clear();
          this.collapse$.next(false);
        })
      ),
      // Observe the window size changing
      this.viewportRuler.change(100).pipe(
        startWith(null),
        map(() => this.toolbarRef.nativeElement.offsetWidth),
        distinctUntilChanged(),
        // The width of the toolbar changed which means the toolbar items that are collapsed could change. Clear out the collapsed groups so that collapsing can start again
        tap(() => {
          this.collapsedGroups.clear();
          this.collapse$.next(false);
        })
      ),
      this.collapse$.asObservable(),
    ]).pipe(
      tap(() => this.calculatingLayout$.next(true)),
      // Build the toolbar items
      map(([toolbarControl, toolbarWidth, collapse]) => {
        if (toolbarControl && typeof toolbarWidth === 'number') {
          return this.buildToolbarItems(toolbarControl, collapse);
        }
      }),
      // Observe requests to update the state of the items
      switchMap(items => {
        return combineLatest([
          of(items),
          this.updateState$.asObservable()
        ]);
      }),
      // Update the state of the toolbar items
      map(([items, editorState]) => {
        return this.updateToolbarItems(items, editorState);
      })
    );
  }

  ngAfterContentInit() {
    this.toolbarItemTemplates = new Map<string, TemplateRef<any>>();
    this.toolbarItemDirectives.forEach(toolbarItem => {
      this.toolbarItemTemplates.set(toolbarItem.templateName, toolbarItem.templateRef);
    });
  }

  onItemClicked(item: EditorToolbarItem<C>) {
    this.clickedItem = item;
    this.itemClick.emit(item);
  }

  onDropdownMenuEscapePressed(event: KeyboardEvent, menuTrigger: MatMenuTrigger) {
    event.stopPropagation();
    menuTrigger.closeMenu();
  }

  onDropdownMenuOpened() {
    this.clickedItem = null;
  }

  onDropdownMenuClosed(event: void | 'click' | 'keydown' | 'tab') {
    this.dropdownMenuClosed.emit({
      item: this.clickedItem,
      source: event
    });

    this.clickedItem = null;
  }

  onDetectorAfterViewInit() {
    // Run this code in the next cycle to ensure the dom changes are ready
    setTimeout(() => {
      if (this.toolbarRef.nativeElement.scrollWidth > this.toolbarRef.nativeElement.offsetWidth && this.collapsedGroups.size < this.toolbarControl?.toolbarGroups?.length) {
        this.collapse$.next(true);
      } else {
        this.calculatingLayout$.next(false);
      }
    }, 0);
  }

  public updateState(editorState: U) {
    this.updateState$.next(editorState);
  }

  public checkViewport() {
    this.collapse$.next(true);
  }

  protected buildToolbarItems(toolbarControl: EditorToolbarControl<C>, collapse: boolean): EditorToolbarItem<C>[] {
    const items: EditorToolbarItem<C>[] = [];

    // If a new group should be collapsed
    if (collapse) {
      if (Array.isArray(toolbarControl.toolbarGroups)) {
        // Find the next group to collapse by sorting by priority and filtering out the groups that are already collapsed
        const uncollapsedGroups = toolbarControl.toolbarGroups
          .sort((groupA, groupB) => groupA.priority - groupB.priority)
          .filter(group => !this.collapsedGroups.has(group.name));

        // If there is a group to collapse then collapse it by adding it to the set of collapsed groups
        if (uncollapsedGroups.length > 0) {
          this.collapsedGroups.add(uncollapsedGroups[0].name);
        }
      }
    }

    // Process the toolbar items defined by the toolbar control
    toolbarControl.toolbarItems.forEach(toolbarItem => {
      // Do not add hidden toolbar items
      if (toolbarItem.hidden) {
        return;
      }

      // If this toolbar item belongs in a group and the group is collapsed
      if (toolbarItem.group && this.collapsedGroups.has(toolbarItem.group)) {
        // Find the group's dropdown
        let dropdownItem = items.find(item => item.type === 'dropdown' && item.group === toolbarItem.group);

        // If the group's dropdown does not exist yet
        if (!dropdownItem) {
          // Find the group for the toolbar item
          const group = toolbarControl.toolbarGroups.find(toolbarGroup => toolbarGroup.name === toolbarItem.group);

          // Create a new dropdown item for the group
          dropdownItem = {
            group: group.name,
            icon: group.icon,
            items: [],
            text: group.text,
            tooltip: group.tooltip,
            type: 'dropdown'
          };

          // If inserting this dropdown will cause these items to be dropdown/divider/dropdown then remove the divider between the two dropdowns
          if (items.length > 1 && items[items.length - 2].type === 'dropdown' && items[items.length - 1].type === 'divider') {
            items.pop();
          }

          // Add the dropdown item to the items that will be rendered
          items.push(dropdownItem);
        }

        // Add the toolbar item to the dropdown item
        this.addItemToBeRendered(toolbarItem, dropdownItem.items);
      } else if (!toolbarItem.dropdownOnly) {
        this.addItemToBeRendered(toolbarItem, items);
      }
    });

    // Add the detector as the last item to be rendered. This triggers an event after the items are rendered
    items.push({
      type: 'detector'
    });

    return items;
  }

  protected addItemToBeRendered(toolbarItem: EditorToolbarItem<C>, items: EditorToolbarItem<C>[]) {
    // If this is a custom toolbar item
    if (toolbarItem.type === 'custom') {
      // Then grab the template for it and add it to the items that will be rendered
      items.push({
        ...toolbarItem,
        template: this.toolbarItemTemplates.get(toolbarItem.templateName)
      });
    } else {
      // Add the toolbar item to the items that will be rendered
      items.push(toolbarItem);
    }
  }

  protected updateToolbarItem(item: EditorToolbarItem<C>, editorState: U) {
    if (typeof item.isActive === 'function') {
      item.active = editorState && item.isActive(editorState);
    }

    if (typeof item.isDisabled === 'function') {
      item.disabled = editorState && item.isDisabled(editorState);
    }

    if (Array.isArray(item.items)) {
      item.items.forEach(subItem => this.updateToolbarItem(subItem, editorState));
    }
  }

  protected updateToolbarItems(items: EditorToolbarItem<C>[], editorState: U): EditorToolbarItem<C>[] {
    items.forEach(item => this.updateToolbarItem(item, editorState));
    return items;
  }
}
