import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { ContentSecurityPolicy } from '@portal-core/sites/models/content-security-policy.model';
import { ContentSecurityPolicyDataService } from '@portal-core/sites/services/content-security-policy/content-security-policy-data.service';
import { SitesApiService } from '@portal-core/sites/services/sites-api.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentSecurityPolicyService extends CollectionServiceBase<ContentSecurityPolicy> {
  constructor(private contentSecurityPolicyDataService: ContentSecurityPolicyDataService, private sitesApiService: SitesApiService, protected dataService: DataService) {
    super(contentSecurityPolicyDataService, dataService);
  }

  protected fetchItemById$(itemId: number): Observable<ContentSecurityPolicy> {
    return this.sitesApiService.getContentSecurityPolicyById$(itemId);
  }

  getContentSecurityPoliciesByLicenseId$(licenseId: number, options?: GetDataOptions): Observable<ContentSecurityPolicy[]> {
    return this.dataService.getDataListItems$<ContentSecurityPolicy>('Licenses', licenseId, this.contentSecurityPolicyDataService, {
      fetch: () => this.sitesApiService.getContentSecurityPoliciesByLicenseId$(licenseId).pipe(
        map(contentSecurityPolicies => {
          if (Array.isArray(contentSecurityPolicies)) {
            return contentSecurityPolicies.sort((contentSecurityPolicyA, contentSecurityPolicyB) => contentSecurityPolicyA.Name.localeCompare(contentSecurityPolicyB.Name));
          }
        })
      )
    }, options);
  }

  createContentSecurityPolicy$(licenseId: number, contentSecurityPolicyName: string): Observable<ContentSecurityPolicy> {
    return this.sitesApiService.upsertContentSecurityPolicy$(licenseId, contentSecurityPolicyName).pipe(
      tap(contentSecurityPolicy => {
        this.contentSecurityPolicyDataService.addItems$([contentSecurityPolicy]);
        this.contentSecurityPolicyDataService.addItemsToListById$('Licenses', licenseId, [contentSecurityPolicy]);
      })
    );
  }

  upsertContentSecurityPolicyDirectives$(licenseId: number, updatedContentSecurityPolicy: ContentSecurityPolicy): Observable<MadCloudResult> {
    return this.sitesApiService.upsertContentSecurityPolicyDirectives$(licenseId, updatedContentSecurityPolicy).pipe(
      tap(() => {
        this.contentSecurityPolicyDataService.updateItems$([updatedContentSecurityPolicy]);
      })
    );
  }

  getContentSecurityPoliciesPageByLicenseId$(licenseId: number, filter: PageFilter): Observable<Page<ContentSecurityPolicy>> {
    return this.sitesApiService.getContentSecurityPoliciesPageByLicenseId$(licenseId, filter);
  }

  removeContentSecurityPolicy$(contentSecurityPolicyId: number): Observable<MadCloudResult> {
    return this.sitesApiService.removeContentSecurityPolicy$(contentSecurityPolicyId).pipe(
      tap(() => {
        this.contentSecurityPolicyDataService.removeItems$([contentSecurityPolicyId]);
      })
    );
  }
}
