import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReviewFileStatus } from '@common/reviews/enums/review-file-status.enum';
import { ReviewFile } from '@common/reviews/models/review-file.model';
import { cache } from '@common/util/cache.operator';
import { CurrentService } from '@portal-core/current/services/current.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { ReviewFileProfile } from '@portal-core/profiles/models/review-file-profile.model';
import { ProfileBase } from '@portal-core/profiles/util/profile.base';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { ReviewFileSettingsFormComponent } from '@portal-core/reviews/review-files/components/review-file-settings-form/review-file-settings-form.component';
import { ReviewFilesService } from '@portal-core/reviews/review-files/services/review-files.service';
import { ReviewPackage } from '@portal-core/reviews/review-packages/models/review-package.model';
import { ReviewPackagesService } from '@portal-core/reviews/review-packages/services/review-packages.service';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

export enum ReviewFileProfileTab {
  Settings,
  Reviewers,
  Activity
}

export enum ReviewFileProfileForm {
  Settings
}

@Component({
  selector: 'mc-review-file-profile',
  templateUrl: './review-file-profile.component.html',
  styleUrls: ['./review-file-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewFileProfileComponent extends ProfileBase<ReviewFileProfileTab> implements OnInit {
  @Input() reviewFileProfile: ReviewFileProfile;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<ReviewFileProfileForm> = new EventEmitter<ReviewFileProfileForm>();

  @InputObservable('reviewFileProfile') reviewFileProfile$: Observable<ReviewFileProfile>;

  @ViewChild(ReviewFileSettingsFormComponent, { static: false }) reviewFileSettingsFormComponent: ReviewFileSettingsFormComponent;

  ReviewFileProfileTab: typeof ReviewFileProfileTab = ReviewFileProfileTab;
  ReviewFileStatus: typeof ReviewFileStatus = ReviewFileStatus;

  project$: Observable<Project>;
  ownerLicenseUser$: Observable<LicenseUser>;
  reviewFile$: Observable<ReviewFile>;
  reviewPackage$: Observable<ReviewPackage>;
  showSettingsTab$: Observable<boolean>;
  userCanManageReviews$: Observable<boolean>;

  public get requirePromptOnClose(): boolean {
    return this.dirty;
  }

  get dirty(): boolean {
    switch (this.profileTab) {
      case ReviewFileProfileTab.Settings:
        return this.reviewFileSettingsFormComponent?.dirty;
      default:
        return false;
    }
  }

  constructor(
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private currentService: CurrentService,
    private licenseUsersService: LicenseUsersService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private reviewFilesService: ReviewFilesService,
    private reviewPackagesService: ReviewPackagesService
  ) {
    super(dialog, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    this.userCanManageReviews$ = this.reviewFileProfile$.pipe(
      switchMap(reviewFileProfile => {
        if (reviewFileProfile?.ReviewFile) {
          return this.permissionsService.currentUserHasPermission$(CentralPermissions.ManageReviews, reviewFileProfile?.ReviewFile.ProjectId);
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable for the review file
    this.reviewFile$ = this.reviewFileProfile$.pipe(
      switchMap(reviewFileProfile => {
        if (typeof reviewFileProfile?.ReviewFile?.Id === 'number') {
          // Do not allow an api request because the review file profile data already contains the review data
          return this.reviewFilesService.getItemById$(reviewFileProfile.ReviewFile.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable for the review package
    this.reviewPackage$ = this.reviewFile$.pipe(
      switchMap(reviewFile => {
        if (typeof reviewFile?.ReviewPackageId === 'number') {
          // Do not allow an api request because the review file profile data already contains the review package data
          return this.reviewPackagesService.getItemById$(reviewFile.ReviewPackageId, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable of the project the review file belongs to
    this.project$ = this.reviewFileProfile$.pipe(
      switchMap(reviewFileProfile => {
        if (typeof reviewFileProfile?.Project?.Id === 'number') {
          // Do not allow an api request because the review file profile data already contains the project data
          return this.projectsService.getItemById$(reviewFileProfile.Project.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create an observable of the review file's owner
    this.ownerLicenseUser$ = this.reviewFileProfile$.pipe(
      switchMap(reviewFileProfile => {
        if (typeof reviewFileProfile?.Owner?.Id === 'number') {
          // Do not allow an api request because the review file profile data already contains the license user data
          return this.licenseUsersService.getItemById$(reviewFileProfile.Owner.Id, { allowApiRequest: false });
        } else {
          return of(null);
        }
      }),
      cache()
    );

    // Create observables for which tabs to show in the sidebar
    this.showSettingsTab$ = combineLatest([
      this.currentService.getCurrentLicenseUserIsAuthor$(),
      this.userCanManageReviews$
    ]).pipe(
      map(([isAuthor, userCanManageReviews]) => isAuthor && userCanManageReviews)
    );
  }

  onCancel() {
    this.cancel.emit();
  }

  onSettingsSaved() {
    this.saved.emit(ReviewFileProfileForm.Settings);
  }
}
