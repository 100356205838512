import { Directive, EmbeddedViewRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CurrentService } from '@portal-core/current/services/current.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[mcIfAuthor]'
})
@AutoUnsubscribe()
export class IfAuthorDirective implements OnInit {
  private viewRef: EmbeddedViewRef<any> = null;
  private licenseUserIsAuthorSubscription: Subscription;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private currentService: CurrentService) { }

  ngOnInit() {
    this.licenseUserIsAuthorSubscription = this.currentService.getCurrentLicenseUserIsAuthor$().subscribe(isAuthor => {
      if (isAuthor) {
        if (!this.viewRef) {
          this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
        this.viewRef = null;
      }
    });
  }
}
