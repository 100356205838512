import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Validation } from '@portal-core/forms/models/validation.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { combineLatest, filter, map, Observable, startWith, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'mc-form-control-error-code',
  templateUrl: './form-control-error-code.component.html',
  styleUrls: ['./form-control-error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FormControlErrorCodeComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() type: string;

  @InputObservable('form') form$: Observable<UntypedFormGroup>;
  @InputObservable('type') type$: Observable<string>;
  @InputObservable('controlName') controlName$: Observable<string>;

  formControl$: Observable<AbstractControl>;
  statusChangeSubscription: Subscription;
  validations$: Observable<Validation[]>; // Possible validation errors the form control has. Listed in forms.service
  customErrorMessages$: Observable<string[]>;

  constructor(private formsService: FormsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.validations$ = this.type$.pipe(
      map(type => this.formsService.getValidationTypes(type))
    );

    this.formControl$ = combineLatest([this.form$, this.controlName$]).pipe(
      filter(([form, controlName]) => !!form && !!controlName), // Check that inputs exist
      map(([form, controlName]) => form.get(controlName))
    );

    this.customErrorMessages$ = this.formControl$.pipe(
      switchMap(formControl =>
        formControl.statusChanges.pipe(
          startWith(() => formControl.status),
          map(() => formControl)
        )
      ),
      map(formControl => {
        const messages: string[] = [];

        if (formControl.errors) {
          for (let key in formControl.errors) {
            const error = formControl.errors[key];
            if (typeof error === 'string') {
              messages.push(error);
            }
          }
        }

        return messages;
      })
    );

    // Manually call change detection when the form control enters an invalid state
    this.statusChangeSubscription = this.formControl$.pipe(
      filter(control => !!control),
      switchMap(control => control.statusChanges)
    ).subscribe(() => this.cdr.detectChanges());
  }
}
