import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
  selector: 'mc-indeterminate-paginator',
  templateUrl: './indeterminate-paginator.component.html',
  styleUrls: ['./indeterminate-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndeterminatePaginatorComponent {
  /** Theme color to be used for the underlying form controls. */
  @Input() color: ThemePalette;

  /** The length of the total number of items that are being paginated. Defaulted to -1 meaning unknown. */
  @Input()
  get length(): number { return this._length; }
  set length(value: number) {
    this._length = value;
    this.cdr.markForCheck();
  }
  private _length: number = -1;

  /** The zero-based page index of the displayed list of items. Defaulted to 0. */
  @Input()
  get pageIndex(): number { return this._pageIndex; }
  set pageIndex(value: number) {
    this._pageIndex = value;
    this.cdr.markForCheck();
  }
  private _pageIndex: number = 0;

  /** Number of items to display on a page. By default set to 50. */
  @Input()
  get pageSize(): number { return this._pageSize; }
  set pageSize(value: number) {
    this._pageSize = value;
    this.cdr.markForCheck();
  }
  private _pageSize: number;

  /** The set of provided page size options to display to the user. */
  @Input()
  get pageSizeOptions(): number[] { return this._pageSizeOptions; }
  set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = value;
    this.cdr.markForCheck();
  }
  private _pageSizeOptions: number[] = [];

  /** The limited total number of items that are being paginated. Sometimes we only know there are at least x number of items. */
  @Input()
  get limitedLength(): number { return this._limitedLength; }
  set limitedLength(value: number) {
    this._limitedLength = value;
    this.cdr.markForCheck();
  }
  private _limitedLength: number;

  /** Event emitted when the paginator changes the page size or page index. */
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  constructor(private cdr: ChangeDetectorRef) { }

  onPageSizeSelected(event: MatSelectChange) {
    this.changePageSize(event.value);
  }

  onPrevPageClicked() {
    if (this.pageIndex > 0) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex -= 1;
      this.emitPageEvent(previousPageIndex);
    }
  }

  onNextPageClicked() {
    const previousPageIndex = this.pageIndex;
    this.pageIndex += 1;
    this.emitPageEvent(previousPageIndex);
  }

  nextButtonIsDisabled(): boolean {
    if (this.pageSize === 0 || this.length === -1) {
      return false;
    }

    const maxPageIndex = Math.ceil(this.length / this.pageSize) - 1;

    return this.pageIndex >= maxPageIndex;
  }

  // TODO: turn this method into a pipe
  getRangeLabel(): string {
    let label = '';

    if (typeof this.pageIndex === 'number' && typeof this.pageSize === 'number') {
      label = `${this.pageIndex * this.pageSize + 1} -`;

      if (this.length !== -1 && this.length < this.pageSize) {
        label += ` ${this.pageIndex * this.pageSize + this.length}`;
      } else {
        label += ` ${(this.pageIndex + 1) * this.pageSize}`;
      }

      if (this.limitedLength > 0) {
        label += ` of ${this.limitedLength} or more`;
      } else if (this.length > 0) {
        label += ` of ${this.length}`;
      }
    }

    return label;
  }

  /**
   * Changes the page size so that the first item displayed on the page will still be
   * displayed using the new page size.
   *
   * For example, if the page size is 10 and on the second page (items indexed 10-19) then
   * switching so that the page size is 5 will set the third page as the current page so
   * that the 10th item will still be displayed.
   */
  private changePageSize(pageSize: number) {
    // The current page needs to be updated to reflect the new page size. Navigate to the page containing the previous page's first item.
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;

    this.emitPageEvent(previousPageIndex);
  }

  private emitPageEvent(previousPageIndex: number) {
    this.page.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      previousPageIndex,
      length: this.length
    });
  }
}
