import { Injectable } from '@angular/core';
import { join } from '@common/util/path';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthUrlService {
  /** Returns an absolute link to the login portal optionally appending a url path. */
  getLoginPortalUrl(path?: (string | number)[]): string {
    return join([environment.centralLoginPortalBaseUri, ...(path ?? [])]);
  }

  /**
   * Returns an absolute link to the client portal.
   * Defaults to using the current subdomain but the subdomain can be overridden with options.vanityBaseUrl
   * Appends a url path if provided in options.path
   */
  getClientPortalUrl(options?: { vanityBaseUrl?: string, path?: (string | number)[] }): string {
    let path: (string | number)[] = [];
    const vanityBaseUrl = options?.vanityBaseUrl ?? this.getSubdomain();

    if (Array.isArray(options?.path) && options.path.length > 0) {
      path = ['#', ...options.path];
    }

    return join([this.buildSubdomainUrl(vanityBaseUrl), ...path]);
  }

  getRootDomain(): string {
    return new URL(environment.centralPortalBaseUri).host;
  }

  getRootDomainForCookies(): string {
    return new URL(environment.centralPortalBaseUri).hostname;
  }

  getSubdomain(): string {
    const portalBase = new URL(environment.centralPortalBaseUri);

    // If the request host is different than the portal host then there is a subdomain
    if (window.location.hostname !== portalBase.hostname) {
      return window.location.hostname.split('.')[0];
    }
  }

  buildSubdomainUrl(vanityBaseUrl: string): string {
    const portalBaseUrl = new URL(environment.centralPortalBaseUri);
    return join([`${portalBaseUrl.protocol}//${vanityBaseUrl}.${portalBaseUrl.host}`, portalBaseUrl.pathname]);
  }

  buildSubdomainUrlDisplayText(vanityBaseUrl: string): string {
    const portalBaseUrl = new URL(environment.centralPortalBaseUri);
    return join([`${vanityBaseUrl}.${portalBaseUrl.host}`, portalBaseUrl.pathname]);
  }
}
