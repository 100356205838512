import { undoDepth } from 'prosemirror-history';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export const ChangeSetMetaKey: string = 'change.set';
export const ChangeMarkAsPristineMetaKey: string = 'change.markAsPristine';
export const changePluginKey = new PluginKey('change');

export interface ChangePluginState {
  dirty: boolean;
  dirtyUndoDepth: number;
}

export interface ChangePluginOptions {
  onDocChanged?: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState, dirty: boolean) => void;
  onSelectionChanged?: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState) => void;
  onStateChanged?: (editorView: EditorView, oldEditorState: EditorState, newEditorState: EditorState) => void;
}

/*
 * A ProseMirror plugin for listening to doc and selection change events.
 * Works by looking at all the transactions that occur via appendTransaction. Keeping track if any of the transactions made a change.
 * After the transactions occurred the plugin's view.update is called by ProseMirror. If a change occurred then the ChangePluginOptions callbacks are invoked.
 */
export function changePlugin(options: ChangePluginOptions): Plugin {
  let docChanged: boolean = false;
  let selectionChanged: boolean = false;

  const plugin = new Plugin({
    key: changePluginKey,

    state: {
      init(): ChangePluginState {
        return {
          dirty: false,
          dirtyUndoDepth: 0
        };
      },

      apply(tr: Transaction, stateValue: ChangePluginState, oldEditorState: EditorState, newEditorState: EditorState): ChangePluginState {
        // Check for updates to the plugin state
        const changeConfig: ChangePluginState = tr.getMeta(ChangeSetMetaKey);
        if (changeConfig) {
          stateValue = changeConfig;
        }

        // Check for the state being marked as pristine
        const markAsPristine: boolean = tr.getMeta(ChangeMarkAsPristineMetaKey);
        if (typeof markAsPristine === 'boolean') {
          stateValue.dirty = false;
          stateValue.dirtyUndoDepth = undoDepth(newEditorState);
        }

        return stateValue;
      }
    },

    view() {
      return {
        update(editorView: EditorView, oldEditorState: EditorState) {
          // If the doc has changed then invoke the callback
          if (docChanged && typeof options.onDocChanged === 'function') {
            const changePluginState: ChangePluginState = plugin.getState(editorView.state);
            options.onDocChanged(editorView, oldEditorState, editorView.state, changePluginState.dirty);
            // Reset the changed state to false
            docChanged = false;
          }

          // If the selection has changed then invoke the callback
          if (selectionChanged && typeof options.onSelectionChanged === 'function') {
            options.onSelectionChanged(editorView, oldEditorState, editorView.state);

            // Reset the changed state to false
            selectionChanged = false;
          }

          if (typeof options.onStateChanged === 'function') {
            options.onStateChanged(editorView, oldEditorState, editorView.state);
          }
        }
      };
    },

    appendTransaction(transactions: Transaction[], oldEditorState: EditorState, newEditorState: EditorState): Transaction {
      let tr: Transaction;

      if (Array.isArray(transactions)) {
        // The doc has changed if any of the transactions have changed the doc
        docChanged = transactions.some(tr => tr.docChanged);

        // The selection has changed if any of the transactions have changed the selection
        selectionChanged = transactions.some(tr => tr.selectionSet);

        // Check to see if the doc is now dirty
        const changePluginState: ChangePluginState = this.getState(oldEditorState);

        if (!changePluginState.dirty) {
          if (undoDepth(newEditorState) !== changePluginState.dirtyUndoDepth) {
            tr = newEditorState.tr;

            tr.setMeta(ChangeSetMetaKey, {
              dirty: true,
              dirtyUndoDepth: changePluginState.dirtyUndoDepth
            });
          }
        }
      } else {
        docChanged = false;
        selectionChanged = false;
      }

      return tr;
    }
  });

  return plugin;
}
