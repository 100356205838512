import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { SystemMessageCardComponent } from './system-message-card.component';

@NgModule({
  declarations: [SystemMessageCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    StopPropagationModule
  ],
  exports: [
    SystemMessageCardComponent
  ]
})
export class SystemMessageCardModule { }
