<div class="mc-variable-picker-input-label">
  <p class="mc-variable-picker-input-item mc-form-label" *ngIf="multiple">Select variable sets or specific variables so translators know which ones you want flattened to text for translation.</p>
  <span class="mc-variable-picker-input-label mc-form-label mc-variable-picker-input-sets-label-container" *ngIf="!multiple">Variable Sets</span>
  <span class="mc-variable-picker-input-label mc-form-label mc-variable-picker-input-grid-label-container" *ngIf="!multiple">Variables</span>
</div>
<div class="mc-variable-picker-input-wrapper" *ngIf="projectVariableSetViews$ | async">
  <div class="mc-variable-picker-input-sets-container mc-variable-picker-input-item">
    <mc-variable-sets-list class="mc-variable-picker-input-border mc-border-divider" #variableSetsList [variableSets]="projectVariableSetViews" [initialSetName]="setName" (variableSetSelect)="onVariableSetSelected($event)" [multiple]="multiple" (setAllVariablesInSet)=setAllVariablesInSet($event)></mc-variable-sets-list>
  </div>
  <div class="mc-variable-picker-input-grid-container mc-variable-picker-input-item">
    <mc-variables-grid class="mc-variable-picker-input-border mc-border-divider" #variablesGrid *ngIf="activeVariableSetView" [variableSetView]="activeVariableSetView" [initialVariableName]="variableName" (setOneVariableInSet)="setOneVariableInSet($event)" [multiple]="multiple" (variableSelect)="variableSelect($event)"></mc-variables-grid>
  </div>
</div>

<mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
<div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
  <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async" (retry)="onRetryClicked()"></mc-errors-expansion-panel>
</div>