export enum ProjectReportScanStatus {
  Unknown = 0,
  Failed,
  Scanning,
  Complete,
  Cancelled,
  Queued,
  GettingFiles,
  ReportGeneration
}
