import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SystemMessages } from '@portal-core/system-messages/components/system-messages';
import { SystemMessagesService } from '@portal-core/system-messages/services/system-messages.service';

@Component({
  selector: 'mc-system-message-cards',
  templateUrl: './system-message-cards.component.html',
  styleUrls: ['./system-message-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessageCardsComponent extends SystemMessages {
  constructor(protected systemMessagesService: SystemMessagesService) {
    super(systemMessagesService);
  }
}
