import { SimpleDomNode } from '@common/html/simple-dom/node';
import { parseStyles, stringifyStyles } from '@common/html/util/style';

/**
 * A helper Proxy for SimpleDomCSSStyleDeclaration that provides property getters and setters for CSS properties using dot notation and bracket notation.
 * For example, to get the font-weight property of an element's style use `element.style['font-weight']`.
 */
const SimpleDomCSSStyleDeclarationPropertyProxyHandler: ProxyHandler<SimpleDomCSSStyleDeclaration> = {
  get(target: SimpleDomCSSStyleDeclaration, name: string): string | Function {
    // If the property exists in the target instance then return its value or call it if its a function
    if (name in target) {
      const member = target[name];

      // Forward function calls to the target instance
      if (typeof member === 'function') {
        return function (...args: any[]) {
          return member.apply(target, args);
        };
      }
      // Otherwise return the property value
      else {
        return member;
      }
    }
    // Otherwise assume the property is a CSS property and return its value
    else {
      return target.getPropertyValue(name);
    }
  },
  set(target: SimpleDomCSSStyleDeclaration, name: string, value: string) {
    // If cssText is getting set then forward it to the target instance
    if (name === 'cssText') {
      target[name] = value;
    }
    // Otherwise assume the property is a CSS property and set its value
    else {
      if (value === null || value === '') {
        target.removeProperty(name);
      } else {
        target.setProperty(name, value);
      }
    }

    return true;
  }
}

/**
 * Represents an object that is a CSS declaration block, and exposes style information and various style-related methods and properties.
 * For speed and simplicity the methods of this class only support the hyphen case for CSS properties.
 * For example when getting the font-weight through an Element's style property use `element.style['font-weight']` instead of `element.style.fontWeight`.
 * Reading properties is fast but writing properties is slower because it updates the cssText property and the ownerNode's style attribute.
 */
export class SimpleDomCSSStyleDeclaration {
  private styles: Dictionary<string>;

  constructor(private ownerNode: SimpleDomNode, cssText: string) {
    this.styles = parseStyles(cssText?.trim() ?? '');

    // Return a proxied instance of this class
    return new Proxy(this, SimpleDomCSSStyleDeclarationPropertyProxyHandler);
  }

  public get cssText(): string {
    return stringifyStyles(this.styles);
  }

  public set cssText(value: string) {
    this.styles = parseStyles(value?.trim() ?? '');
    this.updateAttr();
  }

  /**
   * Returns an integer that represents the number of style declarations in this CSS declaration block.
   */
  public get length(): number {
    return Object.keys(this.styles).length;
  }

  /**
   * Returns a string containing the value of a specified CSS property.
   * @param name a string representing the CSS property name (hyphen case) to get the value of.
   * @returns a string representing the value of the property. If the property is not set, returns an empty string.
   */
  public getPropertyValue(name: string): string {
    return this.styles[name] ?? '';
  }

  /**
   * Removes a property from a CSS style declaration object.
   * @param name a string representing the CSS property name (hyphen case) to be removed.
   * @returns a string representing the value of the property just removed, or the empty string if no property with the specified name was found.
   */
  public removeProperty(name: string): string {
    let value = '';

    if (this.styles && name in this.styles) {
      value = this.styles[name];
      delete this.styles[name];
      this.updateAttr();
    }

    return value;
  }

  /**
   * Sets a new value for a property.
   * @param name a string representing the CSS property name (hyphen case) to be modified.
   * @param value a string containing the new property value. If not specified, treated as the empty string.
   */
  public setProperty(name: string, value: string = '') {
    if (!value) {
      this.removeProperty(name);
    } else {
      this.styles[name] = value;
      this.updateAttr();
    }
  }

  /**
   * Updates the ownerNode's style attribute to the new cssText.
   */
  private updateAttr() {
    const cssText = this.cssText;
    if (cssText) {
      if (this.ownerNode.getAttribute('style') !== cssText) {
        this.ownerNode.setAttribute('style', cssText);
      }
    } else {
      this.ownerNode.removeAttribute('style');
    }
  }
}
