<div class="mc-dialog-card-content-container">
  <div class="mc-dialog-card-main">
    <mat-toolbar class="mc-dialog-header-bar" color="secondary">
      <h3>Filters</h3>
      <span class="mc-fill-remaining-flex-space"></span>
      <button mat-icon-button color="primary" tabindex="-1" [mat-dialog-close]="true">
        <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
      </button>
    </mat-toolbar>

    <div class="mc-dialog-card-content">
      <div class="text-large">Custom Dates</div>
      <div class="mc-task-filter-dialog-time-container">
        <mat-form-field color="primary-vivid" class="mc-task-filter-dialog-date-form-field">
          <input class="mc-task-filter-dialog-date-input" matInput [matDatepicker]="startPicker" placeholder="Start" [ngModel]="startDate"
            (dateChange)="onStartDateValueChanged($event.value)">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker color="primary-vivid" #startPicker></mat-datepicker>
        </mat-form-field>
        <input class="mc-task-filter-dialog-time-input" #startTime type="time" [ngModel]="startDate | date:'HH:mm'" (blur)="onStartTimeValueChanged(startTime.value)">
        <mat-form-field color="primary-vivid" class="mc-task-filter-dialog-date-form-field">
          <input class="mc-task-filter-dialog-date-input" matInput [matDatepicker]="duePicker" placeholder="Due" [ngModel]="dueDate"
            (dateChange)="onDueDateValueChanged($event.value)">
          <mat-datepicker-toggle matSuffix [for]="duePicker"></mat-datepicker-toggle>
          <mat-datepicker color="primary-vivid" #duePicker></mat-datepicker>
        </mat-form-field>
        <input class="mc-task-filter-dialog-time-input" #dueTime type="time" [ngModel]="dueDate | date:'HH:mm'" (blur)="onDueTimeValueChanged(dueTime.value)">
      </div>
      <div>
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Dates" [formControl]="selectedDates" multiple>
            <mat-option [value]="FilterType.Overdue">Overdue</mat-option>
            <mat-option [value]="FilterType.Today">Today</mat-option>
            <mat-option [value]="FilterType.Tomorrow">Tomorrow</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Users" [formControl]="selectedLicenseUsers" multiple>
            <mat-option *ngFor="let licenseUser of licenseUsers$ | async" [value]="licenseUser.User.Id">{{licenseUser.User.FullName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Priorities" [formControl]="selectedPriorities" panelClass="mc-task-filter-dialog-priority-dropdown" multiple>
            <mat-option [value]="TaskPriority.Low">
              <mat-icon color="task-low-priority" class="mc-task-calendar-priority-icon text-large" [fontSet]="'project-titan'"
                [fontIcon]="'icon-dot'"></mat-icon>
              Low Priority
            </mat-option>
            <mat-option [value]="TaskPriority.Medium">
              <mat-icon color="task-medium-priority" class="mc-task-calendar-priority-icon text-large" [fontSet]="'project-titan'"
                [fontIcon]="'icon-dot'"></mat-icon>
              Medium Priority
            </mat-option>
            <mat-option [value]="TaskPriority.High">
              <mat-icon color="task-high-priority" class="mc-task-calendar-priority-icon text-large" [fontSet]="'project-titan'"
                [fontIcon]="'icon-dot'"></mat-icon>
              High Priority
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Projects" [formControl]="selectedProjects" multiple>
            <mat-option *ngFor="let project of projects$ | async" [value]="project.Id">{{project.Name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="currentInfoKey === LicenseUserClientSideInfoKey.CalendarTaskPage">
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Statuses" [formControl]="selectedStatuses" multiple>
            <mat-option [value]="TaskStatus.Active">Active</mat-option>
            <mat-option [value]="TaskStatus.Backlog">Backlog</mat-option>
            <mat-option [value]="TaskStatus.Archive">Archive</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="currentInfoKey === LicenseUserClientSideInfoKey.CalendarTaskPage">
        <mat-form-field color="primary-vivid">
          <mat-select placeholder="Milestones" [formControl]="selectedMilestones" multiple>
            <mat-option [value]="TaskMilestone.ToDo">To Do</mat-option>
            <mat-option [value]="TaskMilestone.InProgress">In Progress</mat-option>
            <mat-option [value]="TaskMilestone.Completed">Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mc-dialog-card-actions">
      <button mat-raised-button color="primary" class="mc-dialog-card-action-button" (click)="onSaveClicked()">Save</button>
    </div>
  </div>
</div>
