import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

/**
 * Defines block nodes that have block content.
 */
export class BlockContainerSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    article: this.makeBlockContainer('article'),
    aside: this.makeBlockContainer('aside'),
    blockquote: this.makeBlockContainer('blockquote', 'div'),
    details: this.makeBlockContainer('details', 'div'),
    div: this.makeBlockContainer('div'),
    fieldset: this.makeBlockContainer('fieldset'),
    figcaption: this.makeBlockContainer('figcaption'),
    figure: this.makeBlockContainer('figure'),
    footer: this.makeBlockContainer('footer'),
    header: this.makeBlockContainer('header'),
    main: this.makeBlockContainer('main'),
    nav: this.makeBlockContainer('nav'),
    section: this.makeBlockContainer('section'),
    summary: this.makeBlockContainer('summary')
  };

  makeBlockContainer(tagName: string, toDomTagName?: string): NodeSpec {
    return {
      group: 'block',
      content: 'block+',
      defining: true, // Persist and wrap the inserted content during replace operations
      fillIfContentDeleted: 'mcCentralContainer',
      parseDOM: [{ tag: tagName }],
      toDOM() { return [toDomTagName ?? tagName, { class: `mc-pm-${tagName}` }, 0]; }
    };
  }
}
