<mc-grid [columns]="columns" [items]="items$ | async" [itemCount]="itemCount" [visibleColumns]="visibleColumns" [selectable]="allowSelect" [orderBy]="defaultSort.active" [orderDirection]="defaultSort.direction" [hideEmptyPagination]="hideEmptyPagination" [pageIndex]="pageIndex" [pageSize]="pageSize" (pageChanged)="onPageChanged($event)" (sortChanged)="onSortChanged($event)" (selectedRowsChange)="onSelectedTagsChanged($event)">
  <ng-template mcGridCell="Name" let-condition>
    <button *ngIf="allowSelect; else conditionNameStaticTemplate" class="mc-conditions-grid-name-item mc-inline-button mc-bg-none" [matTooltip]="condition.Name" (click)="onNameClicked(condition)">
      <ng-container *ngTemplateOutlet="conditionNameTextTemplate"></ng-container>
    </button>
    <ng-template #conditionNameStaticTemplate>
      <div class="mc-conditions-grid-name-item" [matTooltip]="condition.Name">
        <ng-container *ngTemplateOutlet="conditionNameTextTemplate"></ng-container>
      </div>
    </ng-template>

    <ng-template #conditionNameTextTemplate>
      <div class="mc-block mc-border-radius-normal" [ngClass]="{ 'mc-border-normal mc-border-divider': condition.Id | mcIncludedIn : undefinedTagsNames }" [style.background]="condition.BackgroundColor"></div>
      <span class="mc-conditions-grid-name-text" [class.mc-text-warn]="condition.Id | mcIncludedIn : undefinedTagsNames">{{ useLongName ? condition.Id : condition.Name }}</span>
    </ng-template>
  </ng-template>
  <ng-container *ngIf="allowEdit">
    <ng-template mcContextMenuItems let-conditionTag>
      <button mat-menu-item class="mat-menu-item" (click)="onEditConditionTag(conditionTag)">Edit</button>
      <button mat-menu-item class="mat-menu-item" (click)="onRemoveConditionTag(conditionTag)">Delete</button>
    </ng-template>
  </ng-container>
  <ng-template mcEmptyGrid="unfiltered">
    <div *ngIf="allowEdit; else emptyConditionsReadOnlyGrid" class="mc-conditions-grid-no-tags">
      <p>
        Click <mat-icon fontSet="project-titan" fontIcon="icon-add-condition" class="mc-baseline-text-icon"></mat-icon> on the toolbar to create a condition tag.
      </p>
      <p>
        <a target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Condition_Tags">Condition Tags Documentation</a>
      </p>
    </div>
    <ng-template #emptyConditionsReadOnlyGrid>
      <p>There are no condition tags in this set.</p>
    </ng-template>
  </ng-template>
</mc-grid>