import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapShortcutSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapshortcut: {
      group: 'inline',
      inline: true,
      content: 'text*',
      atom: true,
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:shortcut'
      }],
      toDOM() {
        return ['span', { class: 'mc-madcap-shortcut' }, 'Shortcut'];
      },
      tagName: 'MadCap:shortcut'
    }
  };
}
