import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PageFilterType } from '@common/paged-data/enums/page-filter-type.enum';
import { LicenseUserClientSideInfoKey } from '@portal-core/license-users/enums/license-user-client-side-info-key.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { TaskPriority } from '@portal-core/tasks/enums/tasks-priority.enum';
import { TaskFilters } from '@portal-core/tasks/models/task-filters.model';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-task-filter-dialog',
  templateUrl: './task-filter-dialog.component.html',
  styleUrls: ['./task-filter-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskFilterDialogComponent implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '45rem',
    height: '46rem',
    panelClass: 'mc-dialog-card-container'
  };
  static CalendarDialogConfig: MatDialogConfig = {
    width: '45rem',
    height: '57rem',
    panelClass: 'mc-dialog-card-container'
  };

  TaskPriority = TaskPriority;
  FilterType = PageFilterType;
  TaskStatus = TaskStatus;
  TaskMilestone = TaskMilestone;
  LicenseUserClientSideInfoKey = LicenseUserClientSideInfoKey;
  licenseUsers$: Observable<LicenseUser[]>;
  projects$: Observable<Project[]>;
  selectedDates = new UntypedFormControl();
  selectedLicenseUsers = new UntypedFormControl();
  selectedPriorities = new UntypedFormControl();
  selectedProjects = new UntypedFormControl();
  selectedStatuses = new UntypedFormControl();
  selectedMilestones = new UntypedFormControl();
  currentInfoKey: LicenseUserClientSideInfoKey;
  startDate: string;
  dueDate: string;
  private originalFilters: TaskFilters;
  constructor(
    private projectsService: ProjectsService,
    private licenseUsersService: LicenseUsersService,
    private dialogRef: MatDialogRef<TaskFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    const licenseId = this.data.licenseId;
    this.licenseUsers$ = this.licenseUsersService.getAllLicenseUsersByLicenseId$(licenseId, false);
    this.projects$ = this.projectsService.getProjectsList$(licenseId);
    this.currentInfoKey = this.data.currentInfoKey;
    this.originalFilters = this.data.filters;
    if (!this.originalFilters) {
      return;
    }

    if (this.originalFilters.CustomStartDate) {
      this.startDate = new Date(this.originalFilters.CustomStartDate).toISOString();
    }
    if (this.originalFilters.CustomDueDate) {
      this.dueDate = new Date(this.originalFilters.CustomDueDate).toISOString();
    }
    this.selectedPriorities.setValue(this.originalFilters.Priorities);
    this.selectedDates.setValue(this.originalFilters.Dates);
    this.selectedLicenseUsers.setValue(this.originalFilters.Users);
    this.selectedProjects.setValue(this.originalFilters.Projects);
    this.selectedStatuses.setValue(this.originalFilters.Statuses);
    this.selectedMilestones.setValue(this.originalFilters.Milestones);
  }

  onStartDateValueChanged(date: Date) {
    if (!date) {
      this.startDate = null;
      return;
    }
    if (!this.startDate) {
      this.startDate = date.toISOString();
      return;
    }

    const newDate = new Date(this.startDate);

    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    newDate.setFullYear(date.getFullYear());

    this.startDate = newDate.toISOString();
  }

  onStartTimeValueChanged(time: string) {
    if (time === '') {
      this.startDate = null;
      return;
    }

    const date = (!this.startDate
      ? new Date()
      : new Date(this.startDate));

    const split = time.split(':');
    date.setHours(+split[0]);
    date.setMinutes(+split[1]);

    this.startDate = date.toISOString();
  }

  onDueDateValueChanged(date: Date) {

    if (!date) {
      this.dueDate = null;
      return;
    }
    if (!this.dueDate) {
      this.dueDate = date.toISOString();
      return;
    }

    const newDate = new Date(this.dueDate);

    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    newDate.setFullYear(date.getFullYear());

    this.dueDate = newDate.toISOString();
  }

  onDueTimeValueChanged(time: string) {
    if (time === '') {
      this.dueDate = null;
      return;
    }

    const date = (!this.dueDate
      ? new Date()
      : new Date(this.dueDate));

    const split = time.split(':');
    date.setHours(+split[0]);
    date.setMinutes(+split[1]);

    this.dueDate = date.toISOString();
  }

  onCloseDialogClicked() {
    this.dialogRef.close();
  }

  onSaveClicked() {
    const filters: TaskFilters = {
      Priorities: this.selectedPriorities.value?.length > 0 ? this.selectedPriorities.value : undefined,
      Dates: this.selectedDates.value?.length > 0 ? this.selectedDates.value : undefined,
      Users: this.selectedLicenseUsers.value?.length > 0 ? this.selectedLicenseUsers.value : undefined,
      Projects: this.selectedProjects.value?.length > 0 ? this.selectedProjects.value : undefined,
      Statuses: this.selectedStatuses.value?.length > 0 ? this.selectedStatuses.value : undefined,
      Milestones: this.selectedMilestones.value?.length > 0 ? this.selectedMilestones.value : undefined,
      CustomStartDate: this.startDate,
      CustomDueDate: this.dueDate
    };

    if (isEqual(this.originalFilters, filters)) {
      this.dialogRef.close();
      return;
    }

    this.dialogRef.close(filters);
  }
}
