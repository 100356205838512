<div class="mc-spring-board">
  <div class="mc-spring-board-body">
    <mc-build-progress-bar [build]="build"></mc-build-progress-bar>

    <div class="mc-build-overview-avatars">
      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Queued by</b>
        <mc-license-user-avatar *ngIf="creatorLicenseUser$ | async as creatorLicenseUser; else noCreator" [licenseUser]="creatorLicenseUser" size="small" [truncate]="true"></mc-license-user-avatar>
        <ng-template #noCreator>
          <mc-central-avatar *ngIf="build.IsBuiltOnCentral" name="Scheduler" size="small"></mc-central-avatar>
        </ng-template>
      </div>

      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Built by</b>
        <mc-central-avatar *ngIf="build.IsBuiltOnCentral" size="small"></mc-central-avatar>
        <mc-flare-avatar *ngIf="!build.IsBuiltOnCentral" size="small"></mc-flare-avatar>
      </div>

      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Project</b>
        <mc-project-avatar [project]="project$ | async" size="small" [truncate]="true"></mc-project-avatar>
      </div>

      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Target</b>
        <mc-target-avatar size="small" [branchName]="build.BranchName" [project]="project$ | async" [targetPath]="build.TargetPath" [truncate]="true"></mc-target-avatar>
      </div>

      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Branch</b>
        <mc-branch-avatar [branchName]="build.BranchName" [project]="project$ | async" size="small" [truncate]="true"></mc-branch-avatar>
      </div>

      <div class="mc-build-overview-avatar-container">
        <b class="mc-build-overview-header">Commit ID</b>
        <mc-commit-avatar [commit]="build.CommitId ? build.CommitId : 'Unknown'" size="small" [truncate]="true"></mc-commit-avatar>
      </div>
    </div>

    <b class="mc-build-overview-header">Languages</b>
    <ng-container *ngIf="build.BuildLanguageBranches?.length; else noLanguages">
      <ng-container *ngFor="let languageBranch of build.BuildLanguageBranches; let isLast = last">
        {{languageBranch.LanguageName}}{{isLast ? '' : ', '}}
      </ng-container>
    </ng-container>
    <ng-template #noLanguages>
      <p class="mc-text-muted">This build does not have any language branches.</p>
    </ng-template>

    <div class="mc-build-overview-timeline-header">
      <div>Queued</div>
      <div>Started</div>
      <div>Finished</div>
    </div>

    <div class="mc-build-overview-timeline">
      <mat-icon fontSet="project-titan" fontIcon="icon-dot-circle" class="mc-text-muted"></mat-icon>
      <mat-divider></mat-divider>
      <mat-icon fontSet="project-titan" fontIcon="icon-dot-circle" class="mc-text-muted"></mat-icon>
      <mat-divider></mat-divider>
      <mat-icon fontSet="project-titan" fontIcon="icon-dot-circle" class="mc-text-muted"></mat-icon>
    </div>

    <div class="mc-build-overview-timeline-footer">
      <div>{{ build.CreatedOn | date: 'MMM dd, yyyy h:mm a' }}</div>
      <div>{{ build.FirstNotification?.ProcessNotification?.StartTime | date: 'MMM dd, yyyy h:mm a' }}</div>
      <div>
        <ng-container *ngIf="build.IsStopped">
          {{ build.LastNotification?.ProcessNotification?.StartTime | date: 'MMM dd, yyyy h:mm a' }}
        </ng-container>
      </div>
    </div>

    <b class="mc-build-overview-header">Linked Sites</b>
    <ng-container *ngIf="sites$ | async as sites">
      <ng-container *ngIf="sites?.length > 0; else noSites">
        <div class="mc-build-overview-site-list">
          <div *ngFor="let site of sites">
            <mc-site-avatar [site]="site.Id | mcSiteAsync | async" size="small" [truncate]="true"></mc-site-avatar>
          </div>
        </div>
      </ng-container>
      <ng-template #noSites>
        <p class="mc-text-muted">This build is not linked to any sites.</p>
      </ng-template>
    </ng-container>
  </div>
</div>