import { Plugin, PluginKey, PluginView } from 'prosemirror-state';
import { EditorProps, EditorView } from 'prosemirror-view';
import { Observable } from 'rxjs';

export interface PluginViewEx extends PluginView {
  init(editorView: EditorView, options: ViewPluginOptions);
  props?: EditorProps;
}

/**
 * The options for a view plugin.
 */
export interface ViewPluginOptions {
  /** Optionally the key for the plugin. */
  key?: PluginKey;
  /** Optionally an observable of the overlay container element. Should emit when the overlay container element is ready to use. */
  overlay$: Observable<HTMLElement>;
  scrollable?: any;
}

/**
 * A generic plugin for adding a view to an editor.
 * @param PluginViewEx The plugin view to be used in the editor. The plugin view must extend PluginViewEx.
 * @param options The options for the plugin.
 * @returns A plugin to be used with a ProseMirror editor.
 */
export function viewPlugin(view: PluginViewEx, options: ViewPluginOptions): Plugin {
  return new Plugin({
    key: options?.key,

    view(editorView: EditorView): PluginView {
      view.init(editorView, options);
      return view;
    },

    props: view.props
  });
}
