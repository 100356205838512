/**
Specifies file filter types.
*/
export enum ProjectFileFilterType {
  All = 'all',
  Content = 'content',
  Topic = 'topic',
  Snippet = 'snippet',
  TemplatePage = 'templatepage',
  Project = 'project',
  Toc = 'toc',
  Target = 'target',
  VariableSet = 'variableset',
  ConditionTagSet = 'conditiontagset',
  Stylesheet = 'stylesheet',
  EditableFiles = 'editablefiles',
}
