<div class="mc-spring-board">
  <mat-toolbar class="mc-spring-board-header" color="secondary">
    Apply Condition Tags
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-icon-button color="primary" tabindex="-1" (click)="closeDialog()">
      <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div class="mc-spring-board-form-body">
    <div *ngIf="projectConditionSets" class="mc-apply-conditions-dialog-split-wrapper">
      <div class="mc-apply-conditions-dialog-set-list-container">
        <p class="mc-form-label">Condition Tag Sets</p>
        <div class="mc-apply-conditions-dialog-sets-list  mc-border-divider">
          <mc-condition-sets-list mcDialogAutoFocus [conditionSets]="projectConditionSets" [initialSetName]="projectConditionSets?.at(0)?.Name" (conditionSetSelect)="onConditionSetSelected($event)"></mc-condition-sets-list>
        </div>
      </div>
      <div class="mc-apply-conditions-dialog-conditions-tags-container">
        <p class="mc-form-label">Condition Tags</p>
        <div class="mc-apply-conditions-dialog-conditions-tags mc-border-divider">
          <mc-conditions-grid [disableClearSort]="true" [conditionTags]="activeConditionSet.ConditionTags" [initialConditionTagsName]="selectedConditions" [undefinedTagsNames]="undefinedTagsNames" [useLongName]="activeConditionSet.Name === showAllTagsSetName || activeConditionSet.Name === undefinedTagsSetName" [hideEmptyPagination]="true" (conditionSelect)="onConditionSelected($event)"></mc-conditions-grid>
        </div>
      </div>
    </div>
    <mat-radio-group *ngIf="excludeAction" [(ngModel)]="excludeAction" class="mc-apply-conditions-dialog-exclude-action">
      <a mat-icon-button type="button" color="primary-vivid" matTooltip="Help" target="_blank" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Using_Conditions_Unbind_Links">
        <mat-icon fontSet="project-titan" fontIcon="icon-circle-help"></mat-icon>
      </a>
      <span>Exclude Action:</span>
      <mat-radio-button *ngFor="let action of excludeActions" class="mc-apply-conditions-dialog-exclude-action-option mat-primary-vivid" [value]="action">{{ action.toString() | titlecase }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="mc-spring-board-footer">
    <span class="mc-fill-remaining-flex-space"></span>
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button (click)="onApplyClicked()" [disabled]="loadingState.generalError$ | async" color="primary">Apply</button>
  </div>

  <mc-loader *ngIf="loadingState.loading$ | async"></mc-loader>
  <div *ngIf="loadingState.generalError$ | async as generalError" class="error-pane">
    <mc-errors-expansion-panel [generalError]="generalError" [detailedErrors]="loadingState.detailedErrors$ | async"></mc-errors-expansion-panel>
  </div>
</div>