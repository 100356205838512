import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, HostBinding, ViewChild } from '@angular/core';
import { CollectionServiceBase } from '@portal-core/data/collection/services/collection.service.base';
import { ListOptionComponent } from '@portal-core/ui/list/components/list-option/list-option.component';
import { SelectListComponent } from '@portal-core/ui/list/components/select-list/select-list.component';
import { ListCoreBase } from '@portal-core/ui/list/util/list-core.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { Observable, Subject, Subscription } from 'rxjs';

/**
 * SelectListBase
 * The base class for select list components. Provides common functionality for selection changes and access to the list's key manager.
 */
@Directive()
@AutoUnsubscribe()
export abstract class SelectListBase<T> extends ListCoreBase<T> implements AfterViewInit {
  /** A reference to the mc-select-list component. */
  @ViewChild(SelectListComponent, { static: true }) list: SelectListComponent;

  /** Gives the host element the mc-select-list-base CSS class. */
  @HostBinding('class.mc-select-list-base') _hostClass: boolean = true;

  /**
   * Emits the changed options when there is a selection change.
   * This is not defined until after the view is initialized (ngAfterViewInit).
   */
  get selectionChange$(): Observable<ListOptionComponent[]> {
    return this.list?.selectionChange$;
  }

  /** A reference to the underlying key manager. */
  get keyManager(): ActiveDescendantKeyManager<ListOptionComponent> {
    return this.list?.keyManager;
  }

  /** The id of the list's DOM element. Primarily used for a11y like with aria-owns. */
  get listId(): string {
    return this.list?.infiniteList?.listId;
  }

  /** A stream that emits any time the TAB key is pressed, so components can react when focus is shifted off of the list. */
  public tabOut: Subject<void> = new Subject<void>();

  private tabOutSubscription: Subscription;

  constructor(collectionService: CollectionServiceBase<T>) {
    super(collectionService);
  }

  ngAfterViewInit() {
    this.tabOutSubscription = this.list.keyManager.tabOut.subscribe(() => this.tabOut.next());
  }

  /** Removes the active item from the key manager. */
  clearKeyManagerActiveItem() {
    this.list?.clearKeyManagerActiveItem();
  }

  /** sets the active item from the key manager */
  setKeyManagerActiveItem(activeOptionOrIndex: any) {
    this.list?.setKeyManagerActiveItem(activeOptionOrIndex);
  }

  /** Emulates a click on the active item of the list's key manager. */
  clickKeyManagerActiveItem() {
    this.list?.clickKeyManagerActiveItem();
  }

  hardReload() {
    this.list?.hardReload();
  }

  checkViewportSize() {
    this.list?.checkViewportSize();
  }
}
