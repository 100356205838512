export enum PageFilterType {
  Equals = 'Equals',
  NotEqual = 'NotEqual',
  NavEquals = 'NavEquals',
  Contains = 'Contains',
  NotContains = 'NotContains',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  Today = 'Today',
  Between = 'Between',
  Overdue = 'Overdue',
  Tomorrow = 'Tomorrow',
  IsNull = 'IsNull'
}
