import { Pipe, PipeTransform } from '@angular/core';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsService } from '@portal-core/permissions/services/permissions.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'mcHasPermission'
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService) { }
  transform(centralPermission: CentralPermissions, projectId?: number): Observable<boolean> {
    return this.permissionsService.currentUserHasPermission$(centralPermission, projectId);
  }
}
