import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyGridDirective } from './empty-grid.directive';

@NgModule({
  declarations: [
    EmptyGridDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EmptyGridDirective
  ]
})
export class EmptyGridModule { }
