import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { toBoolean, toBooleanString } from '@common/util/bool';
import { addLeadingSlash, removeLeadingSlash } from '@common/util/path';
import { McValidators } from '@portal-core/forms/services/mc-validators';
import { SkinFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFileProxyType } from '@portal-core/project-files/enums/project-file-proxy-type.enum';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { repeatWhileNull } from '@common/util/repeat-while-null.operator';

export interface ProjectFilesProxyDialogData {
  type: ProjectFileProxyType,
  attrs?: Dictionary;
  isEdit: boolean;
  projectId: number;
  commitId: string;
  skinFilePaths$: Observable<string[]>;
  TOCs$: Observable<string[]>;
}

export interface ProjectFilesProxyDialogResult {
  attrs?: ResultAttrs;
}

interface ResultAttrs {
  style: Dictionary;
}

interface ProjectFilesProxyDialogForm {
  skinFilePath: FormControl<string>,
  tocDepth: FormControl<number>,
  glossaryHeading: FormControl<string>,
  menuTOC: FormControl<string>,
  menuContextSensitive: FormControl<boolean>,
  menuParent: FormControl<boolean>,
  menuSiblings: FormControl<boolean>,
  menuChildren: FormControl<boolean>,
  stylesheetClass: FormControl<string>,
  toolbarButtons: FormControl<string>
}

@Component({
  selector: 'mc-project-files-proxy-dialog',
  templateUrl: './project-files-proxy-dialog.component.html',
  styleUrls: ['./project-files-proxy-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ProjectFilesProxyDialogComponent extends DialogBase implements OnInit, OnDestroy {
  static DialogConfig: MatDialogConfig = {
    width: '50rem',
    maxWidth: '50rem',
    maxHeight: '65rem',
  };

  // Dialog Data
  type: ProjectFileProxyType;
  attrs: Dictionary;

  // Dialog descriptions
  proxyTypeText: string;
  headerText: string;
  footerText: string;

  ProjectFolder: typeof ProjectFolder = ProjectFolder;
  ProjectFileProxyType: typeof ProjectFileProxyType = ProjectFileProxyType;

  proxyForm: FormGroup<ProjectFilesProxyDialogForm>;
  skinFileFilter: string = SkinFileFilter;
  tocDepthLabel: string;
  tocDepthPresets: number[] = [];
  menuTOCs: string[] = [];

  TOCs$: Observable<string[]>;
  skinFilePaths$: Observable<string[]>;
  menuTOCSubscription: Subscription;
  menuContextSensitiveSubscription: Subscription;
  menuChildrenSubscription: Subscription;

  private unsubscribe = new Subject<void>();

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProjectFilesProxyDialogData,
    protected dialogRef: MatDialogRef<ProjectFilesProxyDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();

    this.type = this.data.type;
    this.attrs = this.data.attrs;
    if (this.type === ProjectFileProxyType.Body) {
      this.proxyTypeText = 'Body';
      this.headerText = 'A Body proxy is a placeholder for content. When a topic is compiled, the Body proxy is replaced with the body of the topic.';
    } else if (this.type === ProjectFileProxyType.Breadcrumbs) {
      this.proxyTypeText = 'Breadcrumbs';
      this.headerText = 'A Breadcrumbs proxy is a placeholder used in a template page that displays a "trail of breadcrumbs" of TOC entries in the output.';
      this.footerText = 'Enter a specific style class of the MadCap:breadcrumbsProxy style to control its look and feel. If you do not enter a style class, Central uses the main MadCap:breadcrumbsProxy style.';
    } else if (this.type === ProjectFileProxyType.TopicToolbar) {
      this.proxyTypeText = 'Topic Toolbar';
      this.headerText = 'The Topic Toolbar proxy lets you include a toolbar inside a topic.';
      this.footerText = 'Enter a specific style class of the MadCap:topicToolbarProxy style to control its look and feel. If you do not enter a style class, Central uses the main MadCap:topicToolbarProxy style.';
    } else if (this.type === ProjectFileProxyType.MiniTOC) {
      this.proxyTypeText = 'Mini-TOC';
      this.headerText = 'A Mini-TOC proxy lets you generate a portion of the TOC or topic headings at a particular location in output.';
      this.tocDepthLabel = 'TOC Depth';
      this.footerText = 'Enter a specific style class of the MadCap:miniTocProxy style to control its look and feel. If you do not enter a style class, Central uses the main MadCap:miniTocProxy style.';
      for (let i = 0; i <= 9; i++) {
        this.tocDepthPresets.push(i);
      }
    } else if (this.type === ProjectFileProxyType.Glossary) {
      this.proxyTypeText = 'Glossary';
      this.headerText = 'A Glossary proxy is a placeholder for a generated glossary.';
      this.footerText = 'Enter a specific style class of the MadCap:glossaryProxy style to control its look and feel. If you do not enter a style class, Central uses the main MadCap:glossaryProxy style.';
    } else if (this.type === ProjectFileProxyType.SearchBar) {
      this.proxyTypeText = 'Search Bar';
      this.headerText = 'The Search Bar proxy lets you include a search bar inside a topic.';
    } else if (this.type === ProjectFileProxyType.Menu) {
      this.proxyTypeText = 'Menu';
      this.headerText = 'A Menu proxy lets you include a topic menu based on the TOC.';
      this.tocDepthLabel = 'Levels to Show (Depth)';
      for (let i = 1; i <= 10; i++) {
        this.tocDepthPresets.push(i);
      }
    } else if (this.type === ProjectFileProxyType.CentralAccount) {
      this.proxyTypeText = 'Central Account';
      this.headerText = 'The Central Account proxy lets you include an account link drop-down.';
    } else if (this.type === ProjectFileProxyType.ELearningToolbar) {
      this.proxyTypeText = 'eLearning Toolbar';
      this.headerText = 'The eLearning Toolbar proxy lets you include a toolbar with navigation controls for a course.';
    }

    this.createProxyForm();

    this.menuTOCSubscription = this.proxyForm.controls.menuTOC.valueChanges.subscribe(value => {
      if (value === '$topicHeadings') {
        this.proxyForm.controls.menuContextSensitive.disable();
        this.proxyForm.controls.menuParent.disable();
        this.proxyForm.controls.menuSiblings.disable();
        this.proxyForm.controls.menuChildren.disable();
        this.proxyForm.controls.tocDepth.enable();
      } else {
        this.proxyForm.controls.menuContextSensitive.enable();
        if (this.proxyForm.controls.menuContextSensitive.value) {
          this.proxyForm.controls.menuParent.enable();
          this.proxyForm.controls.menuSiblings.enable();
          this.proxyForm.controls.menuChildren.enable();
        }
      }
    });
    this.menuContextSensitiveSubscription = this.proxyForm.controls.menuContextSensitive.valueChanges.subscribe(value => {
      if (value) {
        this.proxyForm.controls.menuParent.enable();
        this.proxyForm.controls.menuSiblings.enable();
        this.proxyForm.controls.menuChildren.enable();
      } else {
        this.proxyForm.controls.menuParent.disable();
        this.proxyForm.controls.menuSiblings.disable();
        this.proxyForm.controls.menuChildren.disable();
        this.proxyForm.controls.tocDepth.enable()
      }
    });
    this.menuChildrenSubscription = this.proxyForm.controls.menuChildren.valueChanges.subscribe(value => {
      if (value) {
        this.proxyForm.controls.tocDepth.enable();
      } else {
        this.proxyForm.controls.tocDepth.disable();
      }
    });

    if (this.type === ProjectFileProxyType.Menu) {
      this.TOCs$ = this.data.TOCs$.pipe(
        takeUntil(this.unsubscribe),
        repeatWhileNull({ retryInterval: 2000 })
      );
    }

    this.skinFilePaths$ = this.data.skinFilePaths$.pipe(
      takeUntil(this.unsubscribe),
      repeatWhileNull({ retryInterval: 2000 })
    );
  }

  ngOnDestroy(): void {
    // unsubscribe from observables
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSubmit() {
    const proxyForm = this.proxyForm.controls;
    const result: ProjectFilesProxyDialogResult = {attrs: {style: {}}};

    if (this.type === ProjectFileProxyType.Menu) {
      let TOC = proxyForm.menuTOC.value;
      result.attrs['mc-linked-toc'] = TOC.length ? (TOC === '$topicHeadings' ? TOC : addLeadingSlash(TOC)) : null;
      result.attrs.style['mc-context-sensitive'] = toBooleanString(proxyForm.menuContextSensitive.value, true);
      result.attrs.style['mc-include-parent'] = toBooleanString(proxyForm.menuParent.value, true);
      result.attrs.style['mc-include-siblings'] = toBooleanString(proxyForm.menuSiblings.value, true);
      result.attrs.style['mc-include-children'] = toBooleanString(proxyForm.menuChildren.value, true);
    }
    if (this.type === ProjectFileProxyType.TopicToolbar || this.type === ProjectFileProxyType.SearchBar || this.type === ProjectFileProxyType.Menu || this.type === ProjectFileProxyType.CentralAccount || this.type === ProjectFileProxyType.ELearningToolbar) {
      result.attrs['data-mc-skin'] = proxyForm.skinFilePath.value?.length ? addLeadingSlash(proxyForm.skinFilePath.value) : null;
    }
    if (this.type === ProjectFileProxyType.MiniTOC || this.type === ProjectFileProxyType.Menu) {
      result.attrs.style['mc-toc-depth'] = proxyForm.tocDepth.value >= -1 ? proxyForm.tocDepth.value?.toString() : null;
    }
    if (this.type === ProjectFileProxyType.Glossary) {
      result.attrs.style['mc-glossary-headings'] = proxyForm.glossaryHeading.value !== '' ? toBooleanString(proxyForm.glossaryHeading.value) : null;
    }
    if (this.type === ProjectFileProxyType.Body || this.type === ProjectFileProxyType.Breadcrumbs || this.type === ProjectFileProxyType.TopicToolbar || this.type === ProjectFileProxyType.MiniTOC || this.type === ProjectFileProxyType.Glossary) {
      result.attrs['MadCap:custom'] = { class: proxyForm.stylesheetClass.value };
    }
    if (this.type === ProjectFileProxyType.TopicToolbar) {
      result.attrs.style['mc-topic-toolbar-items'] = proxyForm.toolbarButtons.value;
    }

    // Null values are only used when editing to remove attributes. Remove null styles when inserting proxy to prevent empty attributes
    if (!this.data.isEdit) {
      result.attrs.style = Object.fromEntries(Object.entries(result.attrs.style).filter(([style, value]) => value !== null && value !== undefined));
    }

    // Don't include styles when empty
    if (!Object.keys(result.attrs.style).length) {
      delete result.attrs.style;
    }

    this.closeDialog(result);
  }

  protected createProxyForm() {
    const skinFilePath = removeLeadingSlash(this.attrs?.['data-mc-skin']) ?? '';
    let tocDepth = this.attrs?.style?.['mc-toc-depth'];
    tocDepth = isNaN(tocDepth) ? -2 : Number(tocDepth);
    const glossaryHeading = toBoolean(this.attrs?.style?.['mc-glossary-headings'], null) ?? '';
    const menuTOC = removeLeadingSlash(this.attrs?.['mc-linked-toc']) ?? '';
    // Checkboxes default to true
    const menuContextSensitive = toBoolean(this.attrs?.style?.['mc-context-sensitive'], true);
    const menuParent = toBoolean(this.attrs?.style?.['mc-include-parent'], true);
    const menuSiblings = toBoolean(this.attrs?.style?.['mc-include-siblings'], true);
    const menuChildren = toBoolean(this.attrs?.style?.['mc-include-children'], true);

    const stylesheetClass = this.attrs?.['MadCap:custom']?.class;
    const toolbarButtons = this.attrs?.style?.['mc-topic-toolbar-items'];

    this.proxyForm = this.fb.group({
      skinFilePath: new FormControl({ value: skinFilePath, disabled: !(this.type === ProjectFileProxyType.TopicToolbar || this.type === ProjectFileProxyType.SearchBar || this.type === ProjectFileProxyType.Menu || this.type === ProjectFileProxyType.CentralAccount || this.type === ProjectFileProxyType.ELearningToolbar) }),
      tocDepth: new FormControl({value: tocDepth, disabled: !(this.type === ProjectFileProxyType.MiniTOC || this.type === ProjectFileProxyType.Menu) || !(menuTOC === '$topicHeadings' || menuContextSensitive === false || menuChildren !== false)}),
      glossaryHeading: new FormControl({value: glossaryHeading, disabled: this.type !== ProjectFileProxyType.Glossary}),
      menuTOC: new FormControl({value: menuTOC, disabled: this.type !== ProjectFileProxyType.Menu}),
      menuContextSensitive: new FormControl({value: menuContextSensitive, disabled: this.type !== ProjectFileProxyType.Menu || menuTOC === '$topicHeadings'}),
      menuParent: new FormControl({value: menuParent, disabled: this.type !== ProjectFileProxyType.Menu || menuTOC === '$topicHeadings' || menuContextSensitive === false}),
      menuSiblings: new FormControl({value: menuSiblings, disabled: this.type !== ProjectFileProxyType.Menu || menuTOC === '$topicHeadings' || menuContextSensitive === false}),
      menuChildren: new FormControl({value: menuChildren, disabled: this.type !== ProjectFileProxyType.Menu || menuTOC === '$topicHeadings' || menuContextSensitive === false}),
      stylesheetClass: new FormControl({ value: stylesheetClass, disabled: !(this.type === ProjectFileProxyType.Body || this.type === ProjectFileProxyType.Breadcrumbs || this.type === ProjectFileProxyType.TopicToolbar || this.type === ProjectFileProxyType.MiniTOC || this.type === ProjectFileProxyType.Glossary) }, [McValidators.cssIdentifier]),
      toolbarButtons: new FormControl({value: toolbarButtons, disabled: this.type !== ProjectFileProxyType.TopicToolbar}),
    });
  }

  formatMenuTOC(TOC: string): string {
    TOC = removeLeadingSlash(TOC);

    // Remove file extension
    return TOC.substring(0, TOC.lastIndexOf('.'))
  }
}
