export enum PageDataType {
  Boolean = 'bool',
  Date = 'datetime',
  Int = 'int',
  Select = 'select',
  String = 'string',
  Double = 'double',
  Object = 'object',

  // Custom Types
  ProgressValue = 'ProgressValue',
  FullName = 'FullName',
  LicenseGroupsEmails = 'LicenseGroupsEmails',
  ProjectTeams = 'ProjectTeams',
  ProjectUsers = 'ProjectUsers',
  ProjectUserHasAccess = 'ProjectUserHasAccess',
  TeamUsers = 'TeamUsers',
  ReviewersStatus = 'ReviewersStatus',
  ReviewersUser = 'ReviewersUser',
  SiteStyles = 'SiteStyles',
  ContentSecurityPolicy = 'ContentSecurityPolicy',
  ChecklistColumnStatus = 'ChecklistColumnStatus',
  Roles = 'Roles'
}
