import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MaxAvatarImageFileSizeBytes } from '@portal-core/data/common/constants/max-avatar-image-file-size.constant';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ImagePickerValue } from '@portal-core/forms/models/image-picker-value.model';
import { FormsService } from '@portal-core/forms/services/forms.service';
import { CancelableEvent } from '@portal-core/general/classes/cancelable-event';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { PhoneNumber } from '@portal-core/users/models/phone-number.model';
import { UserEdit } from '@portal-core/users/models/user-edit.model';
import { UsersService } from '@portal-core/users/services/users.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-user-settings-form',
  templateUrl: './user-settings-form.component.html',
  styleUrls: ['./user-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsFormComponent implements OnInit, OnChanges {
  @Input() licenseUser: LicenseUser;
  @Input() showIdpLogout?: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<CancelableEvent> = new EventEmitter<CancelableEvent>();
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  get dirty(): boolean {
    return this.userSettingsForm?.dirty;
  }

  savingState: LoadingState<string> = new LoadingState<string>();
  showCancelButton: boolean;
  userSettingsForm: UntypedFormGroup;

  constructor(
    private usersService: UsersService,
    private formsService: FormsService,
    private formBuilder: UntypedFormBuilder,
    private errorService: ErrorService,
    private snackBar: MatSnackBar
  ) {
    // Do this in the constructor so that it is ready for ngOnChanges
    this.buildForm();
  }

  ngOnInit() {
    this.showCancelButton = this.cancel.observers.length > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseUser) {
      this.resetForm(this.licenseUser || {} as LicenseUser);
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }

    const saveEvent = new CancelableEvent();
    this.save.emit(saveEvent);

    if (!saveEvent.defaultPrevented) {
      this.savingState.update(true);

      const phoneNumbers: PhoneNumber[] = [];

      if (formGroup.value['Phone']) {
        phoneNumbers.push({
          Label: 'primary',
          Value: formGroup.value['Phone']
        });
      }

      if (formGroup.value['CellPhone']) {
        phoneNumbers.push({
          Label: 'cell',
          Value: formGroup.value['CellPhone']
        });
      }

      const newUser: Partial<UserEdit> = {
        Id: this.licenseUser.User.Id,
        FirstName: formGroup.value['FirstName'],
        LastName: formGroup.value['LastName'],
        Initials: formGroup.value['Initials'],
        Title: formGroup.value['Title'],
        Location: formGroup.value['Location'],
        Department: formGroup.value['Department'],
        SeconderyEmail: formGroup.value['SeconderyEmail'],
        PhoneNumbers: phoneNumbers
      };

      if (this.showIdpLogout) {
        newUser.IdpLogout = formGroup.value['IdpLogout'];
      }

      const avatarValue: ImagePickerValue = formGroup.value.Avatar;

      this.usersService.updateUserSettings$(this.licenseUser.Id, newUser, avatarValue ? avatarValue.file : null).subscribe(() => {
        this.savingState.update(false);
        this.userSettingsForm.markAsPristine();
        this.snackBar.open('Your settings have been saved.', 'OK', { duration: 2500 });
        this.saved.emit();
      }, error => {
        this.savingState.update(false, 'Unable to update your user settings.', this.errorService.getErrorMessages(error));
      });
    }
  }

  protected buildForm() {
    this.userSettingsForm = this.formBuilder.group({
      Avatar: new UntypedFormControl(null, this.formsService.createFileSizeValidator(MaxAvatarImageFileSizeBytes)),
      FirstName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(40)]),
      LastName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(80)]),
      Initials: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2)]),
      Title: new UntypedFormControl(null, Validators.maxLength(128)),
      Phone: new UntypedFormControl(null, [Validators.minLength(7), Validators.maxLength(30)]),
      CellPhone: new UntypedFormControl(null, [Validators.minLength(7), Validators.maxLength(30)]),
      Location: new UntypedFormControl(null, Validators.maxLength(128)),
      Department: new UntypedFormControl(null, Validators.maxLength(128)),
      SeconderyEmail: new UntypedFormControl(null, [Validators.email, Validators.maxLength(128)]),
      IdpLogout: new UntypedFormControl(null)
    });
  }

  protected resetForm(licenseUser: LicenseUser) {
    const user = licenseUser.User || { Id: null };

    this.userSettingsForm.reset({
      Avatar: user.Avatar ? this.formsService.buildImagePickerValue(user.Avatar) : null,
      FirstName: user.FirstName ?? '',
      LastName: user.LastName ?? '',
      Initials: user.Initials ?? '',
      Title: user.Title ?? '',
      Phone: this.usersService.getPrimaryPhoneNumber(user) ?? '',
      CellPhone: this.usersService.getCellPhoneNumber(user) ?? '',
      Location: user.Location ?? '',
      Department: user.Department ?? '',
      SeconderyEmail: user.SeconderyEmail ?? '',
      IdpLogout: user.IdpLogout ?? false
    });
  }
}
