import { Injectable } from '@angular/core';
import { CurrentService } from '@portal-core/current/services/current.service';
import { DataService } from '@portal-core/data/common/services/data.service';
import { TreeService } from '@portal-core/general/services/tree.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { CentralPermissions } from '@portal-core/permissions/enums/central-permissions.enum';
import { PermissionsCategory } from '@portal-core/permissions/models/permissions-category.model';
import { Permissions } from '@portal-core/permissions/models/permissions.model';
import { CurrentUserPermissionsDataService } from '@portal-core/permissions/services/current-user-permissions-data.service';
import { PermissionsApiService } from '@portal-core/permissions/services/permissions-api.service';
import { PermissionsTreeNode } from '@portal-core/permissions/types/permissions-tree-node.type';
import { UsersService } from '@portal-core/users/services/users.service';
import { IResettable, Resettable } from '@portal-core/util/resettable.decorator';
import { cloneDeep, sortBy } from 'lodash';
import { combineLatest, filter, first, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class PermissionsService implements IResettable {
  private permissionCategories: PermissionsCategory[];

  constructor(
    private permissionApiService: PermissionsApiService,
    private currentUserPermissionsDataService: CurrentUserPermissionsDataService,
    private currentService: CurrentService,
    private treeService: TreeService,
    private licenseUsersService: LicenseUsersService,
    private dataService: DataService,
    private usersService: UsersService
  ) { }

  reset$(): Observable<any> {
    return this.currentUserPermissionsDataService.reset$();
  }

  /*
   * Permission Categories
   */
  getSubPermissionCategories$(parentName: string): Observable<PermissionsCategory[]> {
    return this.getPermissionCategories$().pipe(
      map(permissions => {
        const parentId = permissions.find(permission => permission.Name === parentName)?.Id;
        if (typeof parentId === 'number') {
          return permissions.filter(permission => permission.ParentCategoryId === parentId);
        }
      })
    );
  }

  getPermissionCategoriesTree$(): Observable<PermissionsTreeNode> {
    return this.getPermissionCategories$().pipe(
      map(categories => this.transformPermissionsCategoriesIntoTree(categories))
    );
  }

  /*
   * User Permissions
   */
  getUserPermissionsOnLicenseLevel$(userId: string, licenseId: number): Observable<Permissions[]> {
    return this.permissionApiService.getUserPermissionsOnLicenseLevel$(userId, licenseId);
  }

  getUserPermissionsOnProjectLevel$(userId: string, projectId: number): Observable<Permissions[]> {
    return this.permissionApiService.getUserPermissionsOnProjectLevel$(userId, projectId);
  }

  saveUserPermissionsOnLicenseLevel$(userId: string, licenseId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.permissionApiService.saveUserPermissionsOnLicenseLevel$(userId, licenseId, permissions).pipe(
      tap(() => {
        this.refreshLicenseAdmins(licenseId);
      })
    );
  }

  saveUserPermissionsOnProjectLevel$(userId: string, projectId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.permissionApiService.saveUserPermissionsOnProjectLevel$(userId, projectId, permissions);
  }

  getUserHasPermissionOnLicenseLevel$(userId: string, licenseId: number, centralPermission: CentralPermissions): Observable<boolean> {
    return combineLatest([
      this.permissionApiService.getComputedPermissionsOnLicenseLevel$(userId, licenseId),
      this.getPermissionCategoryIdByName$(centralPermission)
    ]).pipe(
      map(([permissions, permissionCategoryId]) => {
        return permissions?.some(lp => lp.PermissionCategoryId === permissionCategoryId) ?? false;
      })
    );
  }

  /*
   * Team Permissions
   */
  getTeamPermissionsOnLicenseLevel$(teamId: number): Observable<Permissions[]> {
    return this.permissionApiService.getTeamPermissionsOnLicenseLevel$(teamId);
  }

  getTeamPermissionsOnProjectLevel$(teamId: number, projectId: number): Observable<Permissions[]> {
    return this.permissionApiService.getTeamPermissionsOnProjectLevel$(teamId, projectId);
  }

  saveTeamPermissionsOnLicenseLevel$(teamId: number, licenseId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.permissionApiService.saveTeamPermissionsOnLicenseLevel$(teamId, licenseId, permissions).pipe(
      tap(() => {
        this.refreshLicenseAdmins(licenseId);
      })
    );
  }

  saveTeamPermissionsOnProjectLevel$(teamId: number, projectId: number, permissions: Permissions[]): Observable<Permissions[]> {
    return this.permissionApiService.saveTeamPermissionsOnProjectLevel$(teamId, projectId, permissions);
  }

  /*
   * User Authorization
   */
  licenseUserHasPermission$(licenseUser$: Observable<LicenseUser>, centralPermission: CentralPermissions, projectId?: number): Observable<boolean> {
    return licenseUser$.pipe(
      first(licenseUser => !!licenseUser)
    ).pipe(
      switchMap(licenseUser => {
        if (this.usersService.hasSomeRoles(licenseUser.User, ['SystemAdmin', 'SupportAdmin', 'QAAdmin'])) {
          // All permissions the system admin has access to
          switch (centralPermission) {
            case CentralPermissions.UserAdministration:
            case CentralPermissions.DeleteUser:
              return of(true);
            default: // Do nothing
          }
        }

        // first prepare to get user permissions by getting necessary data and verifying the store is current
        const getUserDataAndValidatePermissionsState$ = this.getPermissionCategoryIdByName$(centralPermission).pipe(
          // put together the results of the above calls along with store validation
          switchMap(permissionCategoryId => {
            return combineLatest([
              of(permissionCategoryId),
              this.verifyPermissionsAreForUser$(licenseUser.User.Id, licenseUser.LicenseId)
            ]);
          })
        );

        // use data from above to collect all the permissions along with the permissionCategoryId
        return getUserDataAndValidatePermissionsState$.pipe(
          switchMap(([permissionCategoryId]) => {
            return combineLatest([
              of(permissionCategoryId),
              this.getCurrentUserLicensePermissions$(licenseUser.User.Id, licenseUser.LicenseId).pipe(
                filter(licensePermissions => !!licensePermissions)
              ),
              // If a project Id was passed then return project permissions, else return an empty array
              projectId
                ? this.getCurrentUserProjectPermissions$(licenseUser.User.Id, projectId).pipe(
                  filter(projectPermissions => !!projectPermissions)
                )
                : of([])
            ]);
          })
        ).pipe(
          // finally check all user permissions for the existence of the given permission and return the result.
          map(([permissionCategoryId, licensePermissions, projectPermissions]) => {
            if (typeof permissionCategoryId !== 'number') {
              return false;
            }

            return licensePermissions.some(lp => lp.PermissionCategoryId === permissionCategoryId) ||
              projectPermissions.some(pp => pp.PermissionCategoryId === permissionCategoryId);
          })
        );
      })
    );
  }

  currentUserHasPermission$(centralPermission: CentralPermissions, projectId?: number): Observable<boolean> {
    return this.licenseUserHasPermission$(
      this.currentService.getLicenseUser$().pipe(
        first(licenseUser => !!licenseUser)
      ),
      centralPermission,
      projectId
    );
  }

  hasPermission$(permissions: Permissions[], centralPermission: CentralPermissions): Observable<boolean> {
    return this.getPermissionCategoryIdByName$(centralPermission).pipe(
      map(permissionCategoryId => permissions?.some(perm => perm.PermissionCategoryId === permissionCategoryId) ?? false)
    );
  }

  resetUserPermissions$(): Observable<any> {
    return this.currentUserPermissionsDataService.clearUserPermissions$();
  }

  updateUserPermissions$(licenseId: number, projectId: number, permissions: Permissions[]): Observable<any> {
    if (projectId) {
      return this.currentUserPermissionsDataService.setProjectPermissions$(projectId, permissions);
    } else {
      return this.currentUserPermissionsDataService.setLicensePermissions$(licenseId, permissions);
    }
  }

  invalidateLicensePermissions$(licenseId: number): Observable<any> {
    return this.currentUserPermissionsDataService.setLicensePermissions$(licenseId, null);
  }

  invalidateProjectPermissions$(projectId: number): Observable<any> {
    return this.currentUserPermissionsDataService.setProjectPermissions$(projectId, null);
  }

  // If the data for licenseId and userId in the store doesn't match what's given - clear the store and set these values in place.
  private verifyPermissionsAreForUser$(userId: string, licenseId: number): Observable<boolean> {
    const currentPermissionsLicenseId = this.currentUserPermissionsDataService.getCurrentLicenseId();
    const currentPermissionsUserId = this.currentUserPermissionsDataService.getCurrentUserId();

    if (licenseId !== currentPermissionsLicenseId || userId !== currentPermissionsUserId) {
      return this.resetUserPermissions$().pipe(
        tap(() => {
          this.currentUserPermissionsDataService.setCurrentLicenseId$(licenseId);
          this.currentUserPermissionsDataService.setCurrentUserId$(userId);
        }),
        map(() => true)
      );
    } else {
      return of(true);
    }
  }

  private getCurrentUserLicensePermissions$(userId: string, licenseId: number): Observable<Permissions[]> {
    return this.dataService.getData<any>({
      get: () => this.currentUserPermissionsDataService.getLicensePermissionsByLicenseId$(licenseId),
      fetch: () => this.permissionApiService.getComputedPermissionsOnLicenseLevel$(userId, licenseId),
      set: permissions => this.currentUserPermissionsDataService.setLicensePermissions$(licenseId, permissions),
      expired: data => !data // Permissions do not use the normal caching system and instead consider themselves expired whenever they do not exist. This works because their value gets cleared to invalidate their cache
    }, { maxAgeMS: null }).pipe(
      map(data => data ? data.permissions : undefined)
    );
  }

  private getCurrentUserProjectPermissions$(userId: string, projectId: number): Observable<Permissions[]> {
    return this.dataService.getData<any>({
      get: () => this.currentUserPermissionsDataService.getProjectPermissionsByProjectId$(projectId),
      fetch: () => this.permissionApiService.getComputedPermissionsOnProjectLevel$(userId, projectId),
      set: permissions => this.currentUserPermissionsDataService.setProjectPermissions$(projectId, permissions),
      expired: data => !data // Permissions do not use the normal caching system and instead consider themselves expired whenever they do not exist. This works because their value gets cleared to invalidate their cache
    }, { maxAgeMS: null }).pipe(
      map(data => data ? data.permissions : undefined)
    );
  }

  private refreshLicenseAdmins(licenseId: number) {
    // Force an api request to get the latest license user administrations
    this.licenseUsersService.getLicenseUsersAdministrations$(licenseId, { forceApiRequest: true }).pipe(
      first() // We only need to get the first emission
    ).subscribe(); // Subscribe and forget
  }

  private transformPermissionsCategoriesIntoTree(permissionsCategories: PermissionsCategory[]): PermissionsTreeNode {
    // Don't mutate the given array.
    permissionsCategories = cloneDeep(permissionsCategories);

    function createNode(props?: Partial<PermissionsTreeNode>): PermissionsTreeNode {
      return {
        allSelected: false,
        category: null,
        children: [],
        disabled: false,
        parent: null,
        partiallySelected: false,
        ...props
      };
    }

    const root: PermissionsTreeNode = createNode();

    // Loop through all the categories building a nested tree from them.
    // The tree is built from the top down making n loops where n is the tree depth.
    // Whenever a node is placed in the tree it is removed from the categories array.
    // We are done building the tree when there are no more categories in the array.
    while (permissionsCategories.length > 0) {
      // Keep track of the the category count
      const lengthBefore = permissionsCategories.length;

      // Loop through all the categories finding there spot in the tree
      for (let i = permissionsCategories.length - 1; i >= 0; i -= 1) {
        const category = permissionsCategories[i];
        let parent: PermissionsTreeNode;

        // If this is a root node then add it to the root
        if (typeof category.ParentCategoryId !== 'number') {
          parent = root;
        } else {
          // Else we have a child node so find its parent (if it is in the tree yet)
          parent = this.treeService.find(root, 'children', node => node.category?.Id === category.ParentCategoryId);
        }

        if (parent) {
          parent.children.push(createNode({ category, parent }));
          permissionsCategories.splice(i, 1);
        }
      }

      // In case a category has a parent id that does not exist we need a way to break out of this loop
      // If the length of the array hasn't changed in this iteration then we did no work on the categories and we will go into an infinite loop if we do not bail
      if (lengthBefore === permissionsCategories.length) {
        break;
      }
    }

    return this.sortPermissionsTree(root);
  }

  private sortPermissionsTree(node: PermissionsTreeNode): PermissionsTreeNode {
    if (node.children?.length > 0) {
      // Sort by the node's title
      node.children = sortBy(node.children, child => child.category.Title);
      // Sort the "folder" nodes to the end
      node.children = sortBy(node.children, child => child.children.length > 0 ? 1 : 0);
      // Sort the descendants
      node.children.forEach(child => this.sortPermissionsTree(child));
    }

    return node;
  }

  private getPermissionCategories$(): Observable<PermissionsCategory[]> {
    if (this.permissionCategories) {
      return of(this.permissionCategories);
    } else {
      return this.permissionApiService.getPermissionCategories$().pipe(
        tap(permissions => this.permissionCategories = permissions)
      );
    }
  }

  // Returns the server specific id based on a given permission name.
  private getPermissionCategoryIdByName$(name: CentralPermissions) {
    return this.getPermissionCategories$().pipe(
      map(permissionsCategories => permissionsCategories.find(c => c.Name === name)?.Id)
    );
  }
}
