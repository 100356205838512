import { environment as coreEnvironment } from '@portal-core/environment/environment';
import { Environment } from '@portal-core/environment/environment.model';

// declare const process: any;
declare const window: any;

export const environment: Environment = {
  ...coreEnvironment,
  apiServiceBaseUri: process.env.MC_API_SERVICE_BASE_URI, // For the old portal
  authorizeApiLoginId: process.env.MC_AUTHORIZE_API_LOGIN_ID,
  authorizeClientKey: process.env.MC_AUTHORIZE_CLIENT_KEY,
  authorizeSandbox: process.env.MC_AUTHORIZE_SANDBOX === 'true',
  googleAnalyticsMeasurementId: process.env.MC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  slackAppClientId: process.env.MC_SLACK_APP_CLIENT_ID,
  ...window.mcEnvironment
};
