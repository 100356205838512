import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomService } from '@portal-core/general/services/dom.service';
import { PluginViewBase } from '@portal-core/text-editor/util/plugin-view.base';
import { PopupTriggerDirective } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.directive';

@Component({
  selector: 'mc-node-toolbar-popup-plugin-view',
  templateUrl: './node-toolbar-popup-plugin-view.component.html',
  styleUrls: ['./node-toolbar-popup-plugin-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NodeToolbarPopupPluginViewComponent extends PluginViewBase {
  @Input() isEditable: boolean;
  @Output() popupClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() editNode: EventEmitter<void> = new EventEmitter<void>();
  @Output() openNode: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeNode: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('popupTrigger', { static: true, read: PopupTriggerDirective }) popupTrigger: PopupTriggerDirective;

  popupPosition: { left: string, top: string } = { left: '0px', top: '0px' };

  get popupOpen(): boolean {
    return this.popupTrigger?.opened ?? false;
  }

  constructor(changeDetectorRef: ChangeDetectorRef, private domService: DomService, @Inject(DOCUMENT) private document: Document) {
    super(changeDetectorRef);
  }

  onPopupClosed() {
    this.popupClosed.emit();
  }

  onEditNode() {
    this.editNode.emit();
  }

  onOpenNode() {
    this.openNode.emit();
  }

  onRemoveNode() {
    this.removeNode.emit();
  }

  openPopup(x: number, y: number) {
    // The x/y coordinates are relative to the viewport, so we need to offset them by the overlay container's position
    const overlayPos = this.domService.offsetFrom(this.editorOverlayContainer, this.document.body);
    x -= overlayPos.left;
    y -= overlayPos.top;

    // Only open the popup if its not open or the position has changed
    if (this.popupTrigger && (!this.popupTrigger.opened || this.popupPosition.left !== `${x}px` || this.popupPosition.top !== `${y}px`)) {
      this.popupPosition = { left: `${x}px`, top: `${y}px` };
      this.changeDetectorRef.detectChanges();

      this.popupTrigger.updatePosition();
      this.popupTrigger.open();
      this.changeDetectorRef.detectChanges();
    }
  }

  closePopup() {
    if (this.popupOpen) {
      this.popupTrigger?.close();
      this.changeDetectorRef.detectChanges();
    }
  }
}
