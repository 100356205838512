<div class="mc-errors-expansion-panel-icon-container" *ngIf="showIcon">
  <mat-icon class="mc-image-icon mc-image-icon-x-small mc-error-message" fontSet="project-titan" fontIcon="icon-alert"></mat-icon>
</div>

<p class="mc-errors-expansion-panel-general-message mc-text-warn">
  {{ generalError }}
  <button *ngIf="detailedErrors && detailedErrors.length > 0" type="button" class="mc-link-button mc-errors-expansion-panel-general-details-button" (click)="toggleDetailErrorsExpansionPanel()">Details</button>
</p>

<mat-accordion displayMode="flat" class="accordion-flat accordion-transparent accordion-centered-headers">
  <mat-expansion-panel  *ngIf="detailedErrors?.length > 0"  #detailedErrorsExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        More Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list dense matExpansionPanelContent class="mc-errors-expansion-panel-error-list">
      <mat-list-item *ngFor="let error of detailedErrors" class="text-light-weight">
        {{error}}
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>

<button *ngIf="showRetry" type="button" mat-button color="link" class="mc-errors-expansion-panel-retry-button" (click)="retry.emit()">Retry</button>
