import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSortModule } from '@angular/material/sort';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { GridHeaderFilterFormModule } from '@portal-core/ui/grid/directives/grid-header-filter-form/grid-header-filter-form.module';
import { GridHeaderFilterMenuModule } from '@portal-core/ui/grid/directives/grid-header-filter-menu/grid-header-filter-menu.module';
import { GridHeaderFilterComponent } from './grid-header-filter.component';

@NgModule({
  declarations: [
    GridHeaderFilterComponent
  ],
  imports: [
    A11yModule,
    CommonModule,
    GridHeaderFilterFormModule,
    GridHeaderFilterMenuModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    StopPropagationModule
  ],
  exports: [
    GridHeaderFilterComponent
  ]
})
export class GridHeaderFilterModule { }
