import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { DateModule } from '@portal-core/general/pipes/date/date.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseAvatarModule } from '@portal-core/licenses/components/license-avatar/license-avatar.module';
import { NotificationMessageModule } from '@portal-core/notifications/components/notification-message/notification-message.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ReviewFileAvatarModule } from '@portal-core/reviews/review-files/components/review-file-avatar/review-file-avatar.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  declarations: [
    NotificationComponent
  ],
  imports: [
    CommonModule,
    DateModule,
    LicenseAvatarModule,
    LicenseUserAvatarModule,
    MatTooltipModule,
    NotificationMessageModule,
    ProjectAvatarModule,
    ReviewFileAvatarModule,
    RouterModule,
    TeamAvatarModule
  ],
  exports: [
    NotificationComponent
  ]
})
export class NotificationModule { }
