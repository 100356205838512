import { fromDOM } from '@common/html/simple-dom/from-dom';
import { SimpleDomNode } from '@common/html/simple-dom/node';
import { omit } from 'lodash';
import { ContentMatch, DOMParser, NodeType, ParseOptions, ProseMirrorNode, Schema, Slice } from 'prosemirror-model';

/**
 * A ProseMirror DOM parser that uses a FlareSchema to parse the document.
 * Ensures the DOM being parsed is a SimpleDomNode and uses mcCentralContainer as the default block node.
 */
export class ProsemirrorFlareDOMParser extends DOMParser {
  /**
   * Construct a DOM parser using the parsing rules listed in a schema's node specs, reordered by priority.
   * @param schema The schema to use when parsing.
   * @returns The DOM parser.
   */
  static fromSchema(schema: Schema): ProsemirrorFlareDOMParser {
    return schema.cached['mcDOMParser'] as ProsemirrorFlareDOMParser ??
      // DOMParser.schemaRules is marked as internal so we need to cast DOMParser to any to use it
      (schema.cached['mcDOMParser'] = new ProsemirrorFlareDOMParser(schema, (DOMParser as any).schemaRules(schema)))
  }

  /**
   * Parse a document from the content of a DOM node.
   * @param dom The DOM node to parse.
   * @param options The options to use when parsing.
   * @returns The parsed document as a ProseMirror node.
   */
  parse(dom: Node | SimpleDomNode, options: ParseOptions = {}): ProseMirrorNode {
    return this.runParse(dom, options, super.parse.bind(this));
  }

  /**
   * Parses the content of the given DOM node, like ProsemirrorFlareDOMParser.parse and takes the same set of and takes the same set of options.
   * But unlike that method, which produces a whole node, this one returns a slice that is open at the sides, meaning that the schema constraints aren't applied to the start of nodes to the left of the input and the end of nodes at the end.
   * @param dom The DOM node to parse.
   * @param options The options to use when parsing.
   * @returns The parsed document as a Slice.
   */
  parseSlice(dom: Node | SimpleDomNode, options: ParseOptions = {}): Slice {
    return this.runParse(dom, options, super.parseSlice.bind(this));
  }

  /**
   * Parses the content of the given DOM node converting the DOM node into a SimpleDomNode if necessary and using mcCentralContainer as the default block node.
   * @param dom The DOM node to parse.
   * @param options The options to use when parsing.
   * @param func The function to call to parse the DOM node. Either super.parse or super.parseSlice.
   * @returns The parsed document as a ProseMirror node or a Slice.
   */
  private runParse<T>(dom: Node | SimpleDomNode, options: ParseOptions, func: (dom: Node | SimpleDomNode, options: ParseOptions) => T) {
    // Convert the dom node to a SimpleDOM node because the schema expects SimpleDOM. Use text/xml because Flare docs are xml.
    dom = dom instanceof SimpleDomNode ? dom : fromDOM(dom, 'text/xml');

    // During a parse we want mcCentralContainer to be inserted as the default block node so that the original document structure is preserved
    // aka plain text remains plain text
    this.updateDefaultBlockNode('mcCentralContainer');

    try {
      return func(dom, options);
    } finally {
      // Restore the default block node to paragraph
      this.updateDefaultBlockNode('paragraph');
    }
  }

  /**
   * Updates the schema to use the given default block node.
   * Modifies the nodes and their content matches to use the new default block node.
   * The results are cached so that the schema can be switched more quickly.
   * @param defaultBlockNodeName The name of the default block node to use. Either 'paragraph' or 'mcCentralContainer'.
   */
  private updateDefaultBlockNode(defaultBlockNodeName: 'paragraph' | 'mcCentralContainer') {
    const cacheKey = this.getDefaultNodeCacheKey(defaultBlockNodeName);
    let newNodes: Dictionary<NodeType> = this.schema.cached[cacheKey];

    // If the nodes have not been built for this default block node
    if (!newNodes) {
      // Create a node dictionary with the default block node at the beginning so that it is used as the default by ProseMirror
      newNodes = {
        [defaultBlockNodeName]: this.schema.nodes[defaultBlockNodeName],
        ...omit(this.schema.nodes, [defaultBlockNodeName])
      };

      // Cache the set of nodes
      this.schema.cached[cacheKey] = newNodes;
    }

    // Update the schema with the set of nodes
    this.schema.nodes = newNodes;

    // Rebuild the content matches. It is necessary to update the content match so that the default block node gets updated
    let contentExprCache: Dictionary<ContentMatch> = {};

    for (let prop in this.schema.nodes) {
      const type = this.schema.nodes[prop];
      const contentExpr = type.spec.content ?? '';

      // ContentMatch.parse is marked as internal so we need to cast ContentMatch to any to use it
      type.contentMatch = contentExprCache[contentExpr] ?? (contentExprCache[contentExpr] = (ContentMatch as any).parse(contentExpr, this.schema.nodes));
    }
  }

  /**
   * Returns the cache key for the default block node.
   * @param defaultBlockNodeName The name of the default block node. Either 'paragraph' or 'mcCentralContainer'.
   * @returns The cache key for the default block node.
   */
  private getDefaultNodeCacheKey(defaultBlockNodeName: 'paragraph' | 'mcCentralContainer') {
    return `mcDefaultNode-${defaultBlockNodeName}`;
  }
}
