import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ElementPosition } from '@portal-core/general/models/element-position.model';

@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  /**
   * Returns the coordinates of the element relative to the given fromElement. The fromElement must be positioned.
   * An element is said to be positioned if it has a CSS position attribute of relative, absolute, or fixed.
   */
  offsetFrom(element: HTMLElement, fromElement: HTMLElement): ElementPosition {
    const elementRect = element.getBoundingClientRect();
    const fromElementRect = fromElement.getBoundingClientRect();

    return {
      left: elementRect.left - fromElementRect.left + fromElement.scrollLeft,
      top: elementRect.top - fromElementRect.top + fromElement.scrollTop
    };
  }

  /**
   * Returns the coordinates of the element relative to the document element.
   */
  offsetFromPage(element: HTMLElement): ElementPosition {
    return this.offsetFrom(element, this.document.documentElement);
  }

  /**
   * Returns true if the given element is within an element with the given class.
   * If the given element itself has the class then true is returned.
   * Optionally provide an element to look within. Meaning only look at elements at and within the withinElement.
   * If the withElement has the class then true is returned.
   */
  withinClass(element: Element, className: string, withinElement?: Element): boolean {
    // If the element does not exist then we definitely are not within the class name
    if (!element) {
      return false;
    }

    // If this element has the class then were within the class name
    if (element.classList.contains(className)) {
      return true;
    }

    // If this element is the withinElement then look no further up the dom tree
    if (element === withinElement) {
      return false;
    }

    // Check up the dom tree
    return this.withinClass(element.parentNode as Element, className, withinElement);
  }
}
