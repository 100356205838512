import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { CommentBoxModule } from '@portal-core/tasks/components/comment-box/comment-box.module';
import { TaskCommentComponent } from '@portal-core/tasks/components/task-comment/task-comment.component';

@NgModule({
  imports: [
    CommentBoxModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    LicenseUserAvatarModule
  ],
  declarations: [
    TaskCommentComponent
  ],
  exports: [
    TaskCommentComponent
  ]
})
export class TaskCommentModule { }
