import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FlareSchema } from '@common/flare/flare-schema';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { FileService } from '@portal-core/general/services/file.service';
import { StringService } from '@portal-core/general/services/string.service';
import { SnippetFileExtension } from '@portal-core/project-files/constants/file-extensions.constant';
import { ImagesFilter, SnippetFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFileType } from '@portal-core/project-files/enums/project-file-type.enum';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { ProjectFilesService } from '@portal-core/project-files/services/project-files.service';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { TextEditorApiService } from '@portal-core/text-editor/services/text-editor-api.service';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import { LoadingState } from '@portal-core/util/loading-state';
import { Base64 } from 'js-base64';
import { Cancelable } from 'lodash';
import { Plugin } from 'prosemirror-state';
import { NodeViewConstructor } from 'prosemirror-view';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-project-files-tree-item-preview',
  templateUrl: './project-files-tree-item-preview.component.html',
  styleUrls: ['./project-files-tree-item-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilesTreeItemPreviewComponent implements OnInit {
  @Input() commitId: string;
  @Input() projectId: number;
  @Input() defaultPath: string;
  @Input() branchName: string;
  @Input() treeFileFilter?: string;
  // For snippet preview
  @Input() schema?: FlareSchema;
  @Input() debouncedReflowGutterLayout?: Function & Cancelable;
  @Input() metaData$?: Observable<MetaDataValues>;
  @Input() viewPluginOverlay$?: BehaviorSubject<HTMLElement>;
  @Input() editorScrollable?: CdkScrollable;
  @Input() initialFilePath?: string;
  // General
  ProjectFileType: typeof ProjectFileType = ProjectFileType;
  previewLoadingState: LoadingState<ErrorCode> = new LoadingState<ErrorCode>();
  previewSrc: string;
  fileType: ProjectFileType;
  fileTypeLabel: string;

  public selectedFilePath: string; // For parent to use

  // For snippet preview
  content$: Observable<string>;
  nodeViews: Dictionary<NodeViewConstructor>;
  private flareFile$: BehaviorSubject<{ src: string, isSnippet: boolean }> = new BehaviorSubject<{ src: string, isSnippet: boolean }>(null);
  metaDataPlugins: Plugin<any>[];

  constructor(
    private projectFilesService: ProjectFilesService,
    private fileService: FileService,
    private textEditorApiService: TextEditorApiService,
    private textEditorService: TextEditorService,
    private stringService: StringService
  ) { }

  ngOnInit() {
    if (this.treeFileFilter === ImagesFilter) {
      this.fileTypeLabel = 'Images';
    } else if (this.treeFileFilter === SnippetFileFilter) {
      this.fileTypeLabel = 'Snippets';
    } else {
      this.fileTypeLabel = 'Files';
    }
    this.content$ = this.flareFile$.pipe(
      switchMap(flareFile => {
        let content$: Observable<string>;

        if (flareFile.src) {
          if (flareFile.isSnippet) {
            content$ = this.textEditorApiService.getFlareSnippet$(flareFile.src);
          } else {
            content$ = this.projectFilesService.getProjectFile$(this.projectId, flareFile.src, this.branchName).pipe(
              map((projectFile: ProjectFile) => {
                return this.stringService.stripBOM(Base64.decode(projectFile.Content))
              }
              ));
          }
        } else {
          this.onPreviewError();
          content$ = of(null);
        }
        return content$.pipe(
          tap(() => {
            this.onPreviewLoad();
          }),
          catchError(error => {
            this.onPreviewError();
            return of(null);
          })
        )
      })
    );

    if (this.schema && this.metaData$) {
      this.metaDataPlugins = this.textEditorService.createFlarePlugins(this.schema, { metaData: { metaData$: this.metaData$ } })
    }

    if (this.initialFilePath) {
      this.onProjectFileSelected({path: this.initialFilePath});
    }
  }

  /** Updates the preview image and enables the save button */
  onProjectFileSelected(fileNode: {path: string}) {
    const newSrc = this.projectFilesService.getProjectFileRawPath(this.projectId, this.commitId, fileNode.path);

    if (newSrc === this.previewSrc) {
      return;
    }

    this.previewLoadingState.update(true);
    this.fileType = this.projectFilesService.getFileType(fileNode.path);
    if (this.fileType === ProjectFileType.Flare) {
      const isSnippet = this.fileService.getFileExtension(fileNode.path).toLowerCase() === SnippetFileExtension;
      this.nodeViews = this.textEditorService.createCommonNodeViews(this.schema, fileNode.path, this.projectId, this.commitId, this.debouncedReflowGutterLayout, this.metaData$, null, this.viewPluginOverlay$, this.editorScrollable);
      this.flareFile$.next({ src: isSnippet ? newSrc : fileNode.path, isSnippet: isSnippet });
    }

    this.selectedFilePath = fileNode.path;
    this.previewSrc = newSrc;
  }

  onPreviewLoad() {
    this.previewLoadingState.update(false);
  }

  onPreviewError() {
    this.previewLoadingState.update(false, ErrorCode.ProjectFileNotFoundError);
  }
}
