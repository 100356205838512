export class CancelableEvent {
  /** Indicates that this event can be canceled. */
  readonly cancelable: boolean = true;

  /** Indicates whether or not the call to CancelableEvent.preventDefault canceled the event. */
  private _defaultPrevented: boolean = false;
  get defaultPrevented(): boolean {
    return this._defaultPrevented;
  }

  /** If called then the event's default action should not be taken. */
  preventDefault() {
    this._defaultPrevented = true;
  }
}
