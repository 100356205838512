import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnippetNodeViewComponent } from './snippet-node-view.component';
import { SoloFileTextEditorModule } from '@portal-core/text-editor/components/solo-file-text-editor/solo-file-text-editor.module';

@NgModule({
  declarations: [
    SnippetNodeViewComponent
  ],
  imports: [
    CommonModule,
    SoloFileTextEditorModule
  ],
  exports: [
    SnippetNodeViewComponent
  ]
})
export class SnippetNodeViewModule { }
