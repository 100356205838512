import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { cache } from '@common/util/cache.operator';
import { basename, extname } from '@common/util/path';
import { PropertyObservable } from '@common/util/property-observable.decorator';
import { SubscriptionProperty } from '@common/util/subscription-property.decorator';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Build } from '@portal-core/builds/models/build.model';
import { BuildsService } from '@portal-core/builds/services/builds.service';
import { BuildPickerValue } from '@portal-core/builds/types/build-picker-value.type';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseHostMapsService } from '@portal-core/license-host-maps/services/license-host-maps.service';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { ContentSecurityPolicy } from '@portal-core/sites/models/content-security-policy.model';
import { SiteGridItem } from '@portal-core/sites/models/site-grid-item.model';
import { SiteStyles } from '@portal-core/sites/models/site-styles.model';
import { SiteUrl } from '@portal-core/sites/models/site-url.model';
import { ContentSecurityPolicyService } from '@portal-core/sites/services/content-security-policy/content-security-policy.service';
import { SiteStylesService } from '@portal-core/sites/services/site-styles/site-styles.service';
import { SiteUrlsService } from '@portal-core/sites/services/site-urls/site-urls.service';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription, catchError, combineLatest, first, map, merge, of, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'mc-site-settings-form',
  templateUrl: './site-settings-form.component.html',
  styleUrls: ['./site-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SiteSettingsFormComponent implements OnInit, OnChanges {
  @Input() licenseId: number;
  @Input() site: SiteGridItem;
  @Input() liveSiteUrl: string;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<number> = new EventEmitter<number>();

  @PropertyObservable('licenseId') licenseId$: Observable<number>;

  @ViewChild('destinationMenuTrigger', { static: true, read: MatMenuTrigger }) menuTrigger: MatMenuTrigger;

  siteSettingsForm: UntypedFormGroup;

  private isLiveChangedSubscription: Subscription;
  private isPrivateChangedSubscription: Subscription;
  @SubscriptionProperty() private optionsChangedSubscription: Subscription;
  siteStyles$: Observable<SiteStyles[]>;
  siteUrls$: Observable<SiteUrl[]>;
  previewUrl$: Observable<string>;
  contentSecurityPolicies$: Observable<ContentSecurityPolicy[]>;
  loadingState: LoadingState<string> = new LoadingState<string>();
  savingState: LoadingState<string> = new LoadingState<string>();
  readonly latestBuildStr: string = 'Latest Build';

  get requirePromptOnClose(): boolean {
    return (this.siteSettingsForm && this.siteSettingsForm.dirty);
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sitesService: SitesService,
    private siteUrlsService: SiteUrlsService,
    private siteStylesService: SiteStylesService,
    private contentSecurityPolicyService: ContentSecurityPolicyService,
    private errorService: ErrorService,
    private licenseHostMapsService: LicenseHostMapsService,
    private buildsService: BuildsService,
    private licensesService: LicensesService,
    private apiService: ApiService
  ) {
    this.buildForm();
  }

  getDomain(url: string) {
    return this.sitesService.getDomainFromUrl(url);
  }

  private getPreviewUrl(license: License, build: Build): string {
    if (!license || !build) {
      return null;
    }

    let url = '';
    const hostMapId = this.siteSettingsForm.get('domain').value;
    const startupFile = build.PublishStartupFile || `${basename(build.TargetPath, extname(build.TargetPath))}_${build.Id}.zip`;

    if (this.siteSettingsForm.get('isLive').value) {
      const hostMap = hostMapId > 0 ? this.licenseHostMapsService.getItemById(hostMapId) : null;
      if (hostMap) {
        url += `https://${hostMap.MappedDomain}/`;
      } else {
        url += `${this.liveSiteUrl}/`;
      }

      url += this.siteSettingsForm.get('vanityUrl').value ? `${this.siteSettingsForm.get('vanityUrl').value}` :
        this.site ? this.site.Id : 'new_site_id';
      url += `/${startupFile}`;
    } else {
      // url += this.sitesService.isLocalOrigin(document.location.origin) ? this.apiService.centralApiBaseUri : document.location.origin;
      // url += `/publish/${license.VanityBaseUrl}/${build.PublishKey.replace(/-/g, '')}/${build.PublishStartupFile}`;
      url = `${document.location.origin}/${this.apiService.centralPublishBaseUri}/${license.VanityBaseUrl}/${build.PublishKey.replace(/-/g, '')}/${startupFile}`;
    }

    return url;
  }

  ngOnInit() {
    const license$: Observable<License> = this.licenseId$.pipe(
      switchMap(licenseId => typeof licenseId === 'number' ? this.licensesService.getItemById$(licenseId, { forceApiRequest: true }) : of(null)),
      cache()
    );

    // Create an observable for the preview url
    this.previewUrl$ = combineLatest([
      license$,
      this.siteSettingsForm.get('build').valueChanges.pipe(
        startWith(this.siteSettingsForm.get('build').value),
        switchMap((value: BuildPickerValue) => {
          if (value && typeof value.buildId === 'number' && typeof value.branchName === 'string') {
            if (value.buildId > 0) {
              return this.buildsService.getItemById$(value.buildId, { allowApiRequest: false });
            } else if (value.branchName) {
              return this.buildsService.getBuildByTargetPath$(value.projectId, value.branchName, value.targetPath);
            }
          } else if (value && typeof value.projectId === 'number' && typeof value.targetPath === 'string') {
            return this.buildsService.getBuildByProjectIdAndPath$(value.projectId, value.targetPath);
          } else {
            return of(null);
          }
        })
      ),
      merge(
        this.siteSettingsForm.get('domain').valueChanges,
        this.siteSettingsForm.get('isLive').valueChanges,
        this.siteSettingsForm.get('vanityUrl').valueChanges
      ).pipe(
        startWith(false)
      )
    ]).pipe(
      map(([license, build]) => this.getPreviewUrl(license, build))
    );

    // Create an observable for the license's site styles
    this.siteStyles$ = this.licenseId$.pipe(
      switchMap(licenseId => typeof licenseId === 'number' ? this.siteStylesService.getSiteStylesByLicense$(licenseId, { forceApiRequest: true }) : of(null)),
      cache()
    );

    // Create an observable for the license's site urls
    this.siteUrls$ = this.licenseId$.pipe(
      switchMap(licenseId => typeof licenseId === 'number' ? this.siteUrlsService.getSiteUrlsByLicenseId$(licenseId, false, { forceApiRequest: true }) : of(null)),
      cache()
    );

    // Create an observable for the license's content security policies
    this.contentSecurityPolicies$ = this.licenseId$.pipe(
      switchMap(licenseId => typeof licenseId === 'number' ? this.contentSecurityPolicyService.getContentSecurityPoliciesByLicenseId$(licenseId, { forceApiRequest: true }) : of(null)),
      cache()
    );

    // Listen for changes to isLive and isPrivate to enable/disable isCrawlable.
    this.optionsChangedSubscription = merge(
      this.siteSettingsForm.get('isLive').valueChanges.pipe(
        startWith(this.siteSettingsForm.get('isLive').value)
      ),
      this.siteSettingsForm.get('isPrivate').valueChanges.pipe(
        startWith(this.siteSettingsForm.get('isPrivate').value)
      )
    ).subscribe(() => {
      const isLive = this.siteSettingsForm.get('isLive').value;
      const isPrivate = this.siteSettingsForm.get('isPrivate').value;

      if (!isLive || isPrivate) {
        this.siteSettingsForm.get('isCrawlable').disable();
      } else {
        this.siteSettingsForm.get('isCrawlable').enable();
      }
    });

    // Listen to changes to isLive to update isCrawlable
    this.isLiveChangedSubscription = this.siteSettingsForm.get('isLive').valueChanges.subscribe(isLive => {
      this.siteSettingsForm.get('isCrawlable').setValue(isLive && !this.siteSettingsForm.get('isPrivate').value);
    });

    // Listen to changes to isPrivate to update isCrawlable
    this.isPrivateChangedSubscription = this.siteSettingsForm.get('isPrivate').valueChanges.subscribe(isPrivate => {
      if (isPrivate) {
        this.siteSettingsForm.get('isCrawlable').setValue(false);
      }
    });

    // Load all the data needed to create/update a site
    this.loadingState.update(true);

    combineLatest([
      this.siteUrls$,
      this.siteStyles$,
      this.contentSecurityPolicies$
    ]).pipe(
      first()
    ).subscribe(() => {
      this.loadingState.update(false);
    }, error => {
      this.loadingState.update(false, 'Unable to load the site.', this.errorService.getErrorMessages(error));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.site) {
      this.resetForm(this.site || {} as SiteGridItem);
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSubmit() {
    this.savingState.update(true);

    const buildPickerValue: BuildPickerValue = this.siteSettingsForm.get('build').value;

    const siteData = {
      BranchName: buildPickerValue?.branchName ?? null,
      BuildId: buildPickerValue?.buildId > 0 ? buildPickerValue.buildId : null,
      IsCrawlable: this.siteSettingsForm.get('isCrawlable').value ?? false,
      IsLive: this.siteSettingsForm.get('isLive').value ?? false,
      IsPrivate: this.siteSettingsForm.get('isPrivate').value ?? false,
      // IsReviewable: this.siteSettingsForm.get('isReviewable').value,
      LicenseHostMapId: this.siteSettingsForm.get('domain').value === -1 ? null : this.siteSettingsForm.get('domain').value,
      Name: this.siteSettingsForm.get('name').value,
      LastCompletedBuildId: buildPickerValue?.buildId > 0 ? buildPickerValue.buildId : null,
      ProjectId: buildPickerValue?.projectId ?? null,
      SiteStylesId: this.siteSettingsForm.get('siteStylesId').value,
      TargetPath: buildPickerValue?.targetPath ?? null,
      ContentSecurityPolicyId: this.siteSettingsForm.get('contentSecurityPolicyId').value,
      VanityUrl: this.siteSettingsForm.get('vanityUrl').value
    };
    if (this.site?.Id) {
      this.sitesService.updateSiteSettings$({
        ...siteData,
        Id: this.site.Id,
      }).subscribe(() => {
        this.savingState.update(false);
        this.siteSettingsForm.markAsPristine();
        this.saved.emit(this.site.Id);
      }, error => {
        this.savingState.update(false, 'Unable to save the site.', this.errorService.getErrorMessages(error));
      });
    } else {
      this.sitesService.createSite$(this.licenseId, siteData).subscribe(result => {
        this.siteSettingsForm.markAsPristine();
        this.saved.emit(result.Result.Id);
      }, error => {
        this.savingState.update(false, 'Unable to create the site.', this.errorService.getErrorMessages(error));
      });
    }
  }

  private createVanityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control.dirty && control.value && control.value !== '') {
        const hostMapId = this.siteSettingsForm.value.domain === -1 ? null : this.siteSettingsForm.value.domain;

        if (this.hasVanityChanged(control.value, hostMapId)) {
          return this.sitesService.getVanityValidation$(this.licenseId, control.value, hostMapId).pipe(
            map(response => {
              return response.Succeeded ? null : {
                is_integer: this.errorService.hasErrorCode(response, ErrorCode.VanityUrlIsIntegerError),
                is_guid: this.errorService.hasErrorCode(response, ErrorCode.VanityUrlIsGuidError),
                api_pattern: this.errorService.hasErrorCode(response, ErrorCode.VanityUrlPatternError),
                exists: this.errorService.hasErrorCode(response, ErrorCode.VanityUrlExistsError),
                is_reserved: this.errorService.hasErrorCode(response, ErrorCode.VanityUrlIsReservedError)
              };
            }),
            catchError(error => of({ unknown: true }))
          );
        }
      }
      return of(null);
    };
  }

  private hasVanityChanged(newVanity: string, hostMapId: number): boolean {
    if (this.site && typeof newVanity === 'string') {
      const vanity = this.site.VanityUrl ? this.site.VanityUrl.Url : null;
      return newVanity.trim().toLowerCase() !== vanity || hostMapId !== this.site.LicenseHostMapId;
    }

    return false;
  }

  private buildForm() {
    this.siteSettingsForm = this.formBuilder.group({
      build: new UntypedFormControl(),
      domain: new UntypedFormControl(-1),
      isCrawlable: new UntypedFormControl(),
      isLive: new UntypedFormControl(),
      isPrivate: new UntypedFormControl(),
      isReviewable: new UntypedFormControl(),
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128)]),
      siteStylesId: new UntypedFormControl(),
      contentSecurityPolicyId: new UntypedFormControl(),
      vanityUrl: new UntypedFormControl(null, { asyncValidators: [this.createVanityValidator()], updateOn: 'blur' })
    });
  }

  private resetForm(site: SiteGridItem) {
    let buildId: number = null;
    if (typeof site.BuildId === 'number') {
      buildId = site.BuildId;
    } else if (typeof site.LastCompletedBuildId === 'number') {
      buildId = 0;
    } else if (typeof site.ProjectId === 'number' && site.TargetPath) {
      // If the project id and target path are somehow configured without a build then assume the site is configured to use the latest build
      buildId = 0;
    }

    this.siteSettingsForm.reset({
      build: {
        branchName: site.BranchName,
        buildId,
        projectId: site.ProjectId,
        targetPath: site.TargetPath
      },
      domain: site.LicenseHostMapId ?? -1,
      isCrawlable: site.IsCrawlable ?? null,
      isLive: site.IsLive ?? null,
      isPrivate: site.IsPrivate ?? null,
      isReviewable: site.IsReviewable ?? null,
      name: site.Name ?? null,
      siteStylesId: site.SiteStylesId,
      contentSecurityPolicyId: site.ContentSecurityPolicyId,
      vanityUrl: this.site.VanityUrl?.Url ?? null
    }, {
      emitEvent: false // Do not emit event so that the subscriber for setting isCrawlable does not run on programmatic changes
    });
  }
}
