import { isAbsoluteUrl, isCentralUrl, isDataUrl } from '@common/util/path';
import { ImageNodeViewComponent } from '@portal-core/text-editor/components/image-node-view/image-node-view.component';
import { NodeViewType } from '@portal-core/text-editor/enums/node-view-type.enum';
import { ContentNodeViewOptions } from '@portal-core/text-editor/models/content-node-view-options.model';
import { ComponentNodeView } from '@portal-core/text-editor/node-views/component.node-view';
import { DynamicViewComponentInjector } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ProseMirrorNode } from 'prosemirror-model';
import { Decoration, EditorView } from 'prosemirror-view';

export class ImageNodeView extends ComponentNodeView {
  constructor(
    node: ProseMirrorNode,
    view: EditorView,
    getPos: GetPosForNodeView,
    decorations: Decoration[],
    create: DynamicViewComponentInjector,
    protected options?: ContentNodeViewOptions
  ) {
    super(ImageNodeViewComponent, node, view, getPos, decorations, create, options);
  }

  onComponentCreated() {
    this.outputs.load = () => this.emitLoaded({ type: NodeViewType.Image });
  }

  updateComponent() {
    // Determine what the src should be for the image
    let src: string;

    if (isDataUrl(this.node.attrs.src)) {
      src = this.node.attrs.src;
    } else if (isCentralUrl(this.node.attrs.src)) {
      if (typeof this.options?.resolveCentralUrl === 'function') {
        src = this.options.resolveCentralUrl(this.node.attrs.src);
      } else {
        throw new Error('resolveCentralUrl is not defined in ContentNodeViewOptions');
      }
    } else if (isAbsoluteUrl(this.node.attrs.src)) {
      src = this.node.attrs.src;
    } else if (this.node.attrs.src && this.options && typeof this.options.buildContentApiUri === 'function') {
      src = this.options.buildContentApiUri(this.node.attrs.src);
    } else {
      src = null;
    }

    // Update the component's data
    this.inputs.alt = this.node.attrs.alt || '';
    this.inputs.src = src;
    this.inputs.title = this.node.attrs.title || '';
    this.inputs.width = this.node.attrs.style?.width || '';
    this.inputs.height = this.node.attrs.style?.height || '';
  }
}
