import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapDropdownSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapdropdown: {
      group: 'block',
      isolating: true,
      content: 'madcapdropdownhead madcapdropdownbody',
      parseDOM: [{ tag: 'MadCap\\:dropDown' }],
      toDOM() { return ['div', { class: 'mc-madcap-dropdown' }, 0]; },
      tagName: 'MadCap:dropDown',
      specContent: 'madcapdropdownhead madcapdropdownbody'
    },

    madcapdropdownhead: {
      content: 'block+',
      isolating: true,
      fillIfContentDeleted: 'mcCentralContainer',
      linkBucket: 'dropdown',
      parseDOM: [{ tag: 'MadCap\\:dropDownHead' }],
      toDOM() { return ['span', { class: 'mc-madcap-dropdown-head' }, 0]; },
      tagName: 'MadCap:dropDownHead',
      specContext: 'madcapdropdown'
    },

    madcapdropdownbody: {
      content: 'block+',
      isolating: true,
      fillIfContentDeleted: 'mcCentralContainer',
      linkBucket: 'dropdown',
      parseDOM: [{ tag: 'MadCap\\:dropDownBody' }],
      toDOM() { return ['div', { class: 'mc-madcap-dropdown-body' }, 0]; },
      tagName: 'MadCap:dropDownBody',
      specContext: 'madcapdropdown'
    },

    madcapdropdownhotspot: {
      group: 'block',
      content: 'block+',
      isolating: true,
      fillIfContentDeleted: 'mcCentralContainer',
      linkBucket: 'dropdown',
      parseDOM: [{
        tag: 'MadCap\\:dropDownHotspot',
        context: 'madcapdropdownhead//' // Must be within a madcapdropdownhead
      }],
      toDOM() { return ['span', { class: 'mc-madcap-dropdown-hotspot' }, 0]; },
      tagName: 'MadCap:dropDownHotspot',
      specContext: 'madcapdropdownhead'
    }
  };
}
