import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { NoItemPlaceholderModule } from '@portal-core/general/components/no-item-placeholder/no-item-placeholder.module';
import { SelectionListHeaderModule } from '@portal-core/general/components/selection-list-header/selection-list-header.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { LicenseUserAsyncModule } from '@portal-core/license-users/pipes/license-user-async/license-user-async.module';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { SiteAvatarModule } from '@portal-core/sites/components/site-avatar/site-avatar.module';
import { SiteAsyncModule } from '@portal-core/sites/pipes/site-async/site-async.module';
import { TeamAccessFormComponent } from './team-access-form.component';

@NgModule({
  declarations: [TeamAccessFormComponent],
  exports: [TeamAccessFormComponent],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    FormsModule,
    LoaderModule,
    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    NoItemPlaceholderModule,
    ProjectAvatarModule,
    SelectionListHeaderModule,
    SiteAvatarModule,
    ProjectAsyncModule,
    SiteAsyncModule,
    LicenseUserAsyncModule,
    LicenseUserAvatarModule
  ]
})
export class TeamAccessFormModule { }
