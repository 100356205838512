import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  inject$(rel: string, href: string): Observable<any> {
    return new Observable(observer => {
      try {
        const link = this.document.createElement('link');
        link.rel = rel;
        link.href = href;

        link.onload = function() {
          observer.next(link);
          observer.complete();
        };

        link.onerror = function(error: Event | string) {
          observer.error(error);
          observer.complete();
        };

        const head = this.document.getElementsByTagName('head')[0];
        head.appendChild(link);
      } catch (ex) {
        observer.error(ex);
        observer.complete();
      }
    });
  }
}
