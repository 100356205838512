import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { Commit } from '@portal-core/commits/models/commit.model';

@CollectionStateSelectors({
  stateType: CommitsState
})
@State<CommitsState>({
  name: CommitsState.source
})
@Injectable()
export class CommitsState extends CollectionStateBase {
  static source = 'Commits';
  getSource(): string {
    return CommitsState.source;
  }

}

@Injectable({
  providedIn: 'root'
})
export class CommitsDataService extends CollectionDataServiceBase<Commit> {
  constructor(protected store: Store) {
    super(store, CommitsState);
  }
}
