import { ErrorCode } from '@common/http/enums/error-code.enum';

export class McError extends Error {
  code: ErrorCode;

  constructor(message: string, code: ErrorCode) {
    // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.code = code;
  }
}
