import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SubNavbarModule } from '@portal-core/layout/components/sub-navbar/sub-navbar.module';
import { SubPageFillComponent } from './sub-page-fill.component';

@NgModule({
  declarations: [
    SubPageFillComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    SubNavbarModule
  ],
  exports: [
    SubPageFillComponent
  ]
})
export class SubPageFillModule { }
