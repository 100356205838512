import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FlareSchema } from '@common/flare/flare-schema';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { CollabSchema } from '@common/prosemirror/model/collab-schema';
import { dirname, relative } from '@common/util/path';
import { ProjectFilesTreeItemPreviewComponent } from '@portal-core/project-files/components/project-files-tree-item-preview/project-files-tree-item-preview.component';
import { SnippetFileFilter } from '@portal-core/project-files/constants/file-filters.constants';
import { ProjectFolder } from '@portal-core/project-files/enums/project-folder.enum';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { Cancelable } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ProjectFilesAddSnippetDialogData {
  commitId: string;
  projectId: number;
  branchName: string;
  filePath: string;
  schema: CollabSchema;
  debouncedReflowGutterLayout: Function & Cancelable;
  metaData$: Observable<MetaDataValues>;
  viewPluginOverlay$: BehaviorSubject<HTMLElement>;
  editorScrollable: CdkScrollable;
}

export interface ProjectFilesAddSnippetDialogResult {
  path: string;
  isInlineContent: boolean;
}

@Component({
  selector: 'mc-project-files-add-snippet-dialog',
  templateUrl: './project-files-add-snippet-dialog.component.html',
  styleUrls: ['./project-files-add-snippet-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilesAddSnippetDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '85rem',
    height: '45rem'
  };

  @ViewChild('snippetPreview', { static: true }) snippetPreview: ProjectFilesTreeItemPreviewComponent;

  // Dialog data
  commitId: string;
  projectId: number;
  branchName: string;
  filePath: string;
  schema: FlareSchema;
  debouncedReflowGutterLayout?: Function & Cancelable;
  metaData$?: Observable<MetaDataValues>;
  viewPluginOverlay$?: BehaviorSubject<HTMLElement>;
  editorScrollable?: CdkScrollable;
  ProjectFolder: typeof ProjectFolder = ProjectFolder;

  treeFileFilter: string = SnippetFileFilter;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ProjectFilesAddSnippetDialogComponent>
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.commitId = this.data.commitId;
    this.projectId = this.data.projectId;
    this.branchName = this.data.branchName;
    this.filePath = this.data.filePath;
    this.schema = this.data.schema;
    this.debouncedReflowGutterLayout = this.data.debouncedReflowGutterLayout;
    this.metaData$ = this.data.metaData$;
    this.viewPluginOverlay$ = this.data.viewPluginOverlay$;
    this.editorScrollable = this.data.editorScrollable;
  }

  onInsertClicked(snippetPath: string) {
    if (!snippetPath) {
      return;
    }

    // Examine the snippet content for a block node to help determine if it should be a snippetBlock or snippetText node
    this.snippetPreview.content$.subscribe(content => {
      // Couldn't find snippet
      if (content === null) {
        return;
      }
      const doc = this.schema.codeToDoc(content);

      let isInlineContent = true;
      doc.descendants(node => {
        if (node.type.name === 'body') {
          isInlineContent = node.childCount === 1 && (node.firstChild.isInline || node.firstChild.type === this.schema.nodes.mcCentralContainer); // mcCentralContainers are just our block wrapping for inline content
          return false;
        }
      });

      const relativeSrc: string = relative(dirname(this.filePath), snippetPath);

      const result: ProjectFilesAddSnippetDialogResult = { path: relativeSrc, isInlineContent: isInlineContent };
      this.closeDialog(result);
    });
  }
}
