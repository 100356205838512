import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

/**
 * HrSchemaPlugin
 */
export class HrSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    horizontal_rule: {
      group: 'block',
      parseDOM: [{ tag: 'hr' }],
      toDOM() { return ['hr']; },
      tagName: 'hr',
      isVoid: true
    }
  };
}
