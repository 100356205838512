import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnalyticsService } from '@portal-core/analytics/services/analytics.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskBoardsService } from '@portal-core/task-boards/services/task-boards.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { InputObservable } from '@portal-core/util/input-observable.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable, Subscription, catchError, filter, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'mc-task-board-settings-form',
  templateUrl: './task-board-settings-form.component.html',
  styleUrls: ['./task-board-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class TaskBoardSettingsFormComponent implements OnInit, OnChanges {
  @Input() taskBoard: TaskBoard;
  @Input() userId: string;
  @InputObservable('taskBoard') taskBoard$: Observable<TaskBoard>;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() created: EventEmitter<void> = new EventEmitter<void>();

  taskBoardFormGroup: UntypedFormGroup;
  licenseUsers$: Observable<LicenseUser[]>;
  savingState: LoadingState<string> = new LoadingState<string>();
  loadingState: LoadingState<string> = new LoadingState<string>();

  private saveSub: Subscription;
  constructor(
    private taskBoardsService: TaskBoardsService,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private licenseUsersService: LicenseUsersService,
    private analyticsService: AnalyticsService,
  ) {
    this.buildForm();
  }

  get dirty(): boolean {
    return this.taskBoardFormGroup.dirty;
  }

  ngOnInit(): void {
    this.licenseUsers$ = this.taskBoard$.pipe(
      switchMap(taskBoard => {
        if (taskBoard) {
          this.loadingState.update(true);
          return this.licenseUsersService.getAllLicenseUsersByLicenseId$(this.taskBoard.LicenseId, false).pipe(
            tap(() => this.loadingState.update(false)),
            catchError(error => {
              this.loadingState.update(false, 'There was a problem loading the task board.', this.errorService.getErrorMessages(error));
              return of(null);
            })
          );
        } else {
          return of(null);
        }
      }),
      filter(licenseUsers => !!licenseUsers),
      map(licenseUsers => {
        return licenseUsers.filter(licenseUser => licenseUser.SeatType === LicenseUserSeatType.Author && licenseUser.IsActive)
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskBoard) {
      this.resetForm(changes.taskBoard.currentValue);
    }
  }

  onCancelClicked() {
    this.cancel.emit();
  }

  onSaveClicked() {
    const savedBoard = <TaskBoard>{
      ...this.taskBoard,
      Title: this.taskBoardFormGroup.value.title,
      Description: this.taskBoardFormGroup.value.description,
      CreatedByUserId: this.userId,
      OwnerUserId: this.taskBoardFormGroup.value.owner
    };

    let saveTaskBoard$: Observable<any>;
    if (savedBoard.Id === 0) {
      saveTaskBoard$ = this.taskBoardsService.createTaskBoard$(savedBoard).pipe(tap(() => {
        // Sends the event to Google Analytics
        this.analyticsService.trackEvent('task_board_create', {
          'event_category': 'Task Board',
          'event_label': 'Task Board Created',
          'license_id': this.taskBoard.LicenseId
        });

        this.created.emit()
      }));
    } else {
      saveTaskBoard$ = this.taskBoardsService.updateTaskBoard$(savedBoard);
    }

    this.savingState.update(true);
    this.loadingState.detailedErrors$.next(null);

    this.saveSub = saveTaskBoard$.subscribe(
      () => {
        this.savingState.update(false);
        this.snackBar.open(`Task Board ${savedBoard.Id === 0 ? 'created' : 'updated'}`, 'OK', {
          duration: 2500
        });
      }, (error: HttpErrorResponse) => {
        this.savingState.update(false, 'Error Saving Task Board', this.errorService.getErrorMessages(error))
      });
  }

  private buildForm() {

    this.taskBoardFormGroup = this.formBuilder.group({
      title: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128)]),
      description: new UntypedFormControl(null, [Validators.maxLength(256)]),
      owner: new UntypedFormControl()
    });
  }

  private resetForm(taskBoard: TaskBoard) {
    if (!this.taskBoardFormGroup) {
      return;
    }
    this.taskBoardFormGroup.reset({
      title: taskBoard?.Title,
      description: taskBoard?.Description,
      owner: taskBoard?.OwnerUserId
    })
  }
}
