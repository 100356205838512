import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { LicenseAvatarModule } from '@portal-core/licenses/components/license-avatar/license-avatar.module';
import { LicenseBillingFormModule } from '@portal-core/licenses/components/license-billing-form/license-billing-form.module';
import { LicenseOpenAIFormModule } from '@portal-core/licenses/components/license-open-ai-form/license-open-ai-form.module';
import { LicenseOverviewModule } from '@portal-core/licenses/components/license-overview/license-overview.module';
import { LicensePurchasingFormModule } from '@portal-core/licenses/components/license-purchasing-form/license-purchasing-form.module';
import { LicenseSecurityFormModule } from '@portal-core/licenses/components/license-security-form/license-security-form.module';
import { LicenseSettingsFormModule } from '@portal-core/licenses/components/license-settings-form/license-settings-form.module';
import { LicenseSlackFormModule } from '@portal-core/licenses/components/license-slack-form/license-slack-form.module';
import { LicenseSsoFormModule } from '@portal-core/licenses/components/license-sso-form/license-sso-form.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { IfAuthorModule } from '@portal-core/user-access/directives/if-author/if-author.module';
import { LicenseProfileDialogComponent } from './license-profile-dialog.component';

@NgModule({
  declarations: [
    LicenseProfileDialogComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    IfAuthorModule,
    LicenseAvatarModule,
    LicenseBillingFormModule,
    LicenseOverviewModule,
    LicensePurchasingFormModule,
    LicenseSecurityFormModule,
    LicenseSettingsFormModule,
    LicenseSlackFormModule,
    LicenseSsoFormModule,
    LicenseOpenAIFormModule,
    LoaderModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule,
    PreventDefaultModule,
    SwitchBoardModule
  ],
  exports: [
    LicenseProfileDialogComponent
  ]
})
export class LicenseProfileDialogModule { }
