import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { PageFilter } from '@common/paged-data/types/page-filter.type';
import { Page } from '@common/paged-data/types/page.type';
import { ApiService } from '@portal-core/auth/services/api.service';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { ChangeTaskPosition } from '@portal-core/tasks/models/change-task-position.model';
import { Task } from '@portal-core/tasks/models/task.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getTasksByFilter$(licenseId: number, filter?: PageFilter): Observable<Page<Task>> {
    return this.http.post<Page>(`${this.apiService.centralApiBaseUri}/api/tasks/license/${licenseId}`, filter);
  }

  changeMilestones$(milestone: TaskMilestone, taskIds: number[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/tasks/${milestone}/changeMilestones`, taskIds);
  }

  changeStatuses$(status: TaskStatus, taskIds: number[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/tasks/${status}/changeStatuses`, taskIds);
  }

  changePositions$(changeTaskPositions: ChangeTaskPosition[]): Observable<MadCloudResult> {
    return this.http.put<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/tasks/changePositions`, changeTaskPositions);
  }

  getTaskAssetBlob$(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  getTask$(taskId: number): Observable<Task> {
    return this.http.get<Task>(`${this.apiService.centralApiBaseUri}/api/tasks/${taskId}`);
  }

  createTask$(task: FormData): Observable<Task> {
    return this.http.post<Task>(`${this.apiService.centralApiBaseUri}/api/tasks/create`, task);
  }

  updateTask$(taskId: number, task: FormData): Observable<Task> {
    return this.http.put<Task>(`${this.apiService.centralApiBaseUri}/api/tasks/${taskId}/update`, task);
  }

  deleteTasks$(taskIds: number[]): Observable<MadCloudResult> {
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/tasks/delete`, taskIds);
  }
}
