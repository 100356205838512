import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { SiteUrl } from '@portal-core/sites/models/site-url.model';

@CollectionStateSelectors({
  stateType: SiteUrlsState
})
@State<SiteUrlsState>({
  name: SiteUrlsState.source
})
@Injectable()
export class SiteUrlsState extends CollectionStateBase {
  static source: string = 'SiteUrls';
  getSource(): string {
    return SiteUrlsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SiteUrlsDataService extends CollectionDataServiceBase<SiteUrl> {
  constructor(protected store: Store) {
    super(store, SiteUrlsState);
  }
}
