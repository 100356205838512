import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { BranchAvatarModule } from '@portal-core/branches/components/branch-avatar/branch-avatar.module';
import { BuildAvatarModule } from '@portal-core/builds/components/build-avatar/build-avatar.module';
import { CommitAvatarModule } from '@portal-core/commits/components/commit-avatar/commit-avatar.module';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { PluralizeModule } from '@portal-core/general/pipes/pluralize/pluralize.module';
import { TranslationPackageNotificationListModule } from '@portal-core/notifications/components/translation-package-notification-list/translation-package-notification-list.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { ProjectLinkModule } from '@portal-core/projects/pipes/project-link/project-link.module';
import { TranslationPackageAvatarModule } from '@portal-core/translation-packages/components/translation-package-avatar/translation-package-avatar.module';
import { TranslationPackageDeletionFormModule } from '@portal-core/translation-packages/components/translation-package-deletion-form/translation-package-deletion-form.module';
import { TranslationPackageProfileDialogComponent } from '@portal-core/translation-packages/components/translation-package-profile-dialog/translation-package-profile-dialog.component';
import { TranslationPackageSettingsFormModule } from '@portal-core/translation-packages/components/translation-package-settings-form/translation-package-settings-form.module';
import { TranslationPackagesSummaryModule } from '@portal-core/translation-packages/components/translation-packages-summary/translation-packages-summary.module';
import { TranslationPackageStatusTextModule } from '@portal-core/translation-packages/pipes/translation-package-status-text/translation-package-status-text.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
@NgModule({
  declarations: [TranslationPackageProfileDialogComponent],
  imports: [
    BuildAvatarModule,
    BranchAvatarModule,
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    CommitAvatarModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    PluralizeModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    PreventDefaultModule,
    TranslationPackageStatusTextModule,
    TranslationPackageAvatarModule,
    TranslationPackagesSummaryModule,
    ProjectLinkModule,
    TranslationPackageDeletionFormModule,
    TranslationPackageNotificationListModule,
    TranslationPackageSettingsFormModule,
    SwitchBoardModule,
    MatTooltipModule,
    ProfileTabModule,
    RouterModule
  ],
  exports: [TranslationPackageProfileDialogComponent]
})
export class TranslationPackageProfileDialogModule { }
