import { Injectable } from '@angular/core';
import { CoreService } from '@portal-client/core/core.service';
import { CurrentConfigurator } from '@portal-core/current/services/current-module-config.interface';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { License } from '@portal-core/licenses/models/license.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentConfiguratorService implements CurrentConfigurator {
  constructor(private coreService: CoreService) { }

  getLicenseUser(): LicenseUser {
    return this.coreService.getLicenseUser();
  }

  getLicenseUser$(): Observable<LicenseUser> {
    return this.coreService.getLicenseUser$();
  }

  getLicense(): License {
    return this.coreService.getLicense();
  }

  getLicense$(): Observable<License> {
    return this.coreService.getLicense$();
  }

  getActiveLicense(): License {
    return this.coreService.getActiveLicense();
  }

  getActiveLicense$(): Observable<License> {
    return this.coreService.getActiveLicense$();
  }

  isSysAdmin(): boolean {
    return false;
  }
}
