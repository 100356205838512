import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CurrentService } from '@portal-core/current/services/current.service';
import { TaskBoardProfileDialogComponent } from '@portal-core/task-boards/components/task-board-profile-dialog/task-board-profile-dialog.component';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { AvatarBase } from '@portal-core/ui/avatar/util/avatar.base';

@Component({
  selector: 'mc-task-board-avatar',
  templateUrl: './task-board-avatar.component.html',
  styleUrls: ['./task-board-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoardAvatarComponent extends AvatarBase {
  @Input() color?: 'invert';
  @Input() nameLinksToPage?: boolean = false;
  @Input() taskBoard: TaskBoard;

  @HostBinding('class')
  get _color(): string {
    return this.color ? `mat-${this.color}` : '';
  }

  constructor(private dialog: MatDialog, private currentService: CurrentService) {
    super();
  }

  onAvatarClicked() {
    this.openTaskBoardProfileDialog();
  }

  hasAvatarImage(): boolean {
    return false;
  }

  hasAvatarSVG(): boolean {
    return true;
  }

  hasPermission(): boolean {
    return true;
  }

  isDeleted(): boolean {
    return this.taskBoard?.IsDeleted;
  }

  isEmpty(): boolean {
    return !this.taskBoard;
  }

  private openTaskBoardProfileDialog() {
    this.dialog.open(TaskBoardProfileDialogComponent, {
      ...TaskBoardProfileDialogComponent.DialogConfig,
      data: {
        taskBoardId: this.taskBoard.Id,
        userId: this.currentService.getUserId(),
        licenseId: this.taskBoard.LicenseId
      }
    });
  }
}
