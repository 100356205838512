import { Injectable } from '@angular/core';
import { stringInputToObject } from '@common/util/colors';
import { ColorInputFormat } from '@portal-core/ui/color-picker/models/color-input-format';
import { Color } from '@portal-core/ui/color-picker/models/color.model';

@Injectable()
export class ColorAdapter {
  sameColor(a: Color, b: Color) {
    if (a == null && b == null) return true;
    if (a != null && b != null) return a.rgba === b.rgba;
    return false;
  }

  format(c: Color, format: ColorInputFormat): string {
    return c.toString(format);
  }

  parse(value: string): Color | null {
    if (!value) {
      return null;
    }

    const obj = stringInputToObject(value);
    if (obj) {
      return new Color(obj.r, obj.g, obj.b, obj.a);
    }
  }
}
