import { cloneElementWithNewName, descendants, removeStyles, replaceStyles } from '@common/html/util/dom';

interface TagReplacement {
  styleIsMultiValued?: boolean;
  styleName: string;
  styleValue: string;
  tag: string;
}

/**
 * Normalizes spans with text styles in HTML by converting them to their appropriate tags.
 */
export class SpanNormalizer {
  /**
   * Normalizes spans with text styles in HTML by converting them to their appropriate tags.
   * @param doc The HTML doc to normalize.
   */
  normalize(doc: Document) {
    descendants(doc.documentElement, (element: HTMLElement) => {
      if (element.nodeName?.toLowerCase() === 'span') {
        // const newTags: string[] = [];
        const newTags: TagReplacement[] = [];

        // Convert <span> tags with font-weight 700 to <b> tags
        if (element.style['font-weight'] === '700') {
          newTags.push({
            tag: 'b',
            styleName: 'font-weight',
            styleValue: '700'
          });
        }

        // Convert <span> tags with font-style italic to <i> tags
        if (element.style['font-style'] === 'italic') {
          newTags.push({
            tag: 'i',
            styleName: 'font-style',
            styleValue: 'italic'
          });
        }

        // Convert <span> tags with text-decoration underline to <u> tags
        if (element.style['text-decoration']?.includes('underline')) {
          newTags.push({
            tag: 'u',
            styleIsMultiValued: true,
            styleName: 'text-decoration',
            styleValue: 'underline'
          });
        }

        // Convert <span> tags with text-decoration line-through to <s> tags
        if (element.style['text-decoration']?.includes('line-through')) {
          newTags.push({
            tag: 's',
            styleIsMultiValued: true,
            styleName: 'text-decoration',
            styleValue: 'line-through'
          });
        }

        // Convert <span> tags with vertical-align super to <sup> tags
        if (element.style['vertical-align'] === 'super') {
          newTags.push({
            tag: 'sup',
            styleName: 'vertical-align',
            styleValue: 'super'
          });
        }

        // Convert <span> tags with vertical-align sub to <sub> tags
        if (element.style['vertical-align'] === 'sub') {
          newTags.push({
            tag: 'sub',
            styleName: 'vertical-align',
            styleValue: 'sub'
          });
        }

        // Replace the <span> tag with the new tags
        if (newTags.length > 0) {
          this.replaceSpan(newTags, element, doc);
        }
        // Unwrap the <span> tag because it has no text styles
        else {
          element.replaceWith(...element.childNodes);
        }
      }
    }, { depthFirst: true, reverse: true });
  }

  /**
   * Replaces a span element with the provided tags. The span's children will be wrapped in the tags. And the span's attributes will be applied to the innermost tag.
   * @param tags The tags to replace the span with.
   * @param spanElement The span element to replace.
   * @param doc The document used to create the new elements.
   */
  private replaceSpan(replacements: TagReplacement[], spanElement: HTMLElement, doc: Document) {
    const newElement = replacements.map((replacement, index) => {
      this.removeReplacementStyle(spanElement, replacement);

      if (index === replacements.length - 1) {
        return cloneElementWithNewName(spanElement, replacement.tag);
      } else {
        return doc.createElement(replacement.tag);
      }
    }).reduceRight((child, parent) => {
      if (child) {
        parent.append(child);
      }
      return parent;
    });

    spanElement.replaceWith(newElement);
  }

  /**
   * Removes the style from the span element that was matched by the tag replacement.
   * @param spanElement The span element to remove the style from.
   * @param replacement The replacement that matched the span.
   */
  private removeReplacementStyle(spanElement: Element, replacement: TagReplacement) {
    if (!replacement.styleIsMultiValued) {
      removeStyles(spanElement, (name, value) => name === replacement.styleName && value === replacement.styleValue);
    } else {
      replaceStyles(spanElement, (name, value) => {
        if (name === replacement.styleName) {
          const values = value.split(' ');
          const index = values.indexOf(replacement.styleValue);
          if (index !== -1) {
            values.splice(index, 1);
            return values.join(' ');
          }
        }
      });
    }
  }
}
