<div #popupTrigger [style.position]="'relative'" [style.left]="popupPosition.left" [style.top]="popupPosition.top" [mcPopupScrollables]="scrollable ? [scrollable] : null" [mcPopupRestoreFocus]="false" [mcPopupTriggerAutoClose]="false" [mcPopupTriggerFor]="popup" [mcPopupClass]="['mc-popup-toolbar', 'mat-basic', 'mc-node-toolbar-popup-plugin-view-popup']"></div>

<mc-popup #popup [backdrop]="false" (closed)="onPopupClosed()">
  <mat-toolbar class="mc-toolbar-normal" color="basic">
    <button type="button" [disabled]="!isEditable" mat-icon-button matTooltip="Edit Snippet" (click)="onEditNode()">
      <mat-icon fontSet="project-titan" fontIcon="icon-pen"></mat-icon>
    </button>
    <button type="button" [disabled]="!isEditable" mat-icon-button matTooltip="Open Snippet File" (click)="onOpenNode()">
      <mat-icon fontSet="project-titan" fontIcon="icon-launch"></mat-icon>
    </button>
    <button type="button" mat-icon-button matTooltip="Delete" (click)="onRemoveNode()">
      <mat-icon fontSet="project-titan" fontIcon="icon-trash"></mat-icon>
    </button>
  </mat-toolbar>
</mc-popup>