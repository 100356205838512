import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupComponentModule } from '@portal-core/ui/popup/components/popup/popup.module';
import { PopupContentModule } from '@portal-core/ui/popup/directives/popup-content/popup-content.module';
import { PopupTriggerModule } from '@portal-core/ui/popup/directives/popup-trigger/popup-trigger.module';

@NgModule({
  exports: [
    CommonModule,
    PopupComponentModule,
    PopupContentModule,
    PopupTriggerModule
  ]
})
export class PopupModule { }
