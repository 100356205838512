import { Injectable } from '@angular/core';
import { OnLoginComplete } from '@portal-core/auth/interfaces/login-complete.interface';
import { CoreService } from '@portal-client/core/core.service';

@Injectable({
  providedIn: 'root'
})
export class LoginEventHandlerService implements OnLoginComplete {
  constructor(private coreService: CoreService) { }

  onLoginComplete() {
    this.coreService.checkAndShowGettingStartedDialog();
  }
}
