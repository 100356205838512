import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { ConfirmDialogModule } from '@portal-core/general/components/confirm-dialog/confirm-dialog.module';
import { ErrorDialogModule } from '@portal-core/general/components/error-dialog/error-dialog.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { FileSizeModule } from '@portal-core/general/pipes/file-size/file-size.module';
import { LicensePurchasingFormComponent } from './license-purchasing-form.component';

@NgModule({
  declarations: [
    LicensePurchasingFormComponent
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ErrorDialogModule,
    FileSizeModule,
    FormControlErrorMessageModule,
    LoaderModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    ReactiveFormsModule
  ],
  exports: [
    LicensePurchasingFormComponent
  ]
})
export class LicensePurchasingFormModule { }
