<div #gridTableContainer class="mc-grid-table-container" [ngClass]="{ 'mc-grid-show-header-row': showHeaderRow$ | async, 'mc-grid-x-small-paginator': useXSmallPaginator$ | async, 'mc-grid-small-paginator': useSmallPaginator$ | async, 'mc-grid-no-row-border': !showRowBorder, 'mc-grid-expandable-rows': expandableRows }">
  <table mat-table [dataSource]="items" [multiTemplateDataRows]="expandableRows" matSort [matSortActive]="orderBy" [matSortDirection]="orderDirection" (matSortChange)="onSortChanged($event)" class="mc-grid-table">
    <!-- select column -->
    <ng-container *ngIf="selectable" [matColumnDef]="GridColumnName.Select" sticky>
      <th mat-header-cell *matHeaderCellDef class="mc-grid-select-cell">
        <mat-checkbox color="primary-vivid" [checked]="selection.hasValue() && allItemsSelected" [indeterminate]="selectedItemCount > 0 && !allItemsSelected" (change)="$event ? onAllItemsSelectionChanged() : null"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="mc-grid-select-cell">
        <mat-checkbox *ngIf="row.Selectable === undefined || row.Selectable" color="primary-vivid" [checked]="selection.isSelected(row.Id)" (click)="$event.stopPropagation()" (change)="$event ? onItemSelectionChanged(row) : null"></mat-checkbox>
      </td>
    </ng-container>

    <!-- menu column -->
    <ng-container *ngIf="contextMenuItemsTemplate" [matColumnDef]="GridColumnName.Menu" sticky>
      <th mat-header-cell *matHeaderCellDef class="mc-grid-menu-cell"></th>
      <td mat-cell *matCellDef="let row" class="mc-grid-menu-cell">
        <button mat-icon-button mcStopPropagation="click" mcPreventDefault="click" (click)="onContextMenu($event, row)">
          <mat-icon fontSet="project-titan" fontIcon="icon-kebab"></mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- card column -->
    <ng-container *ngIf="showCardColumn$ | async; else allColumnLayout" [matColumnDef]="GridColumnName.Card">
      <th mat-header-cell *matHeaderCellDef>
        <mc-grid-header-cell [column]="getColumn(GridColumnName.Card)" [filterable]="filterable" (menuClosed)="onHeaderMenuClosed($event)" (menuOpened)="onHeaderMenuOpened($event)"></mc-grid-header-cell>
      </th>

      <td mat-cell *matCellDef="let row" class="mc-grid-content-cell" (contextmenu)="onContextMenu($event, row)">
        <ng-container [ngTemplateOutlet]="getGridCellTemplate(GridColumnName.Card)" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
        <div [style.position]="'fixed'" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ row: row }"></div>
      </td>
    </ng-container>

    <!-- data columns -->
    <ng-template #allColumnLayout>
      <ng-container *ngFor="let column of columns; trackBy: columnTrackBy" [matColumnDef]="column.name" [sticky]="column.sticky" [stickyEnd]="column.stickyEnd">
        <th mat-header-cell *matHeaderCellDef>
          <mc-grid-header-cell [column]="column" [columnInputs]="columnInputs && columnInputs[column.name]" [filterable]="filterable" (menuClosed)="onHeaderMenuClosed($event)" (menuOpened)="onHeaderMenuOpened($event)">
            <ng-template *ngIf="getGridHeaderMenuTemplate(column.name) as gridHeaderMenuTemplate" mcGridHeaderMenu>
              <ng-container [ngTemplateOutlet]="gridHeaderMenuTemplate" [ngTemplateOutletContext]="{ $implicit: column }"></ng-container>
            </ng-template>
          </mc-grid-header-cell>
        </th>

        <td mat-cell *matCellDef="let row" class="mc-grid-content-cell" (contextmenu)="onContextMenu($event, row)">
          <ng-container *ngIf="getGridCellTemplate(column.name, column.templateName) as gridCellTemplate; else textContent" [ngTemplateOutlet]="gridCellTemplate" [ngTemplateOutletContext]="{ $implicit: row, columnName: column.name }"></ng-container>
          <ng-template #textContent>
            <span class="mc-truncate" [matTooltip]="getPropByString(row, column.name)">{{ getPropByString(row, column.name) }}</span>
          </ng-template>
          <div [style.position]="'fixed'" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ row: row }"></div>
        </td>
      </ng-container>
    </ng-template>

    <!-- fill column -->
    <ng-container [matColumnDef]="GridColumnName.Fill">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" (contextmenu)="onContextMenu($event, row)">
        <div [style.position]="'fixed'" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ row: row }"></div>
      </td>
    </ng-container>

    <!-- expand column -->
    <ng-container *ngIf="expandableRows" [matColumnDef]="GridColumnName.Expand" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="mc-grid-expand-cell">
        <mat-icon fontSet="project-titan" fontIcon="icon-expand" color="primary-vivid" class="mc-grid-row-expansion-indicator" [class.mc-grid-row-expansion-indicator-expanded]="
            expandedRowId === row.Id
          "></mat-icon>
      </td>
    </ng-container>

    <!-- expanded content column - the detail row is made up of this one column that spans across all columns -->
    <ng-container *ngIf="expandableRows" [matColumnDef]="GridColumnName.ExpandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="(renderedColumnNames$ | async)?.length" (contextmenu)="onContextMenu($event, row)">
        <div *mcLazyRender="!lazyLoadExpandableRows || expandedRowId === row.Id" class="expanded-detail" [class.row-expanded]="expandedRowId === row.Id">
          <div class="expanded-detail-content">
            <ng-container *ngIf="expandedRowDetailTemplate; else errorDescription" [ngTemplateOutlet]="expandedRowDetailTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
            <ng-template #errorDescription>Error loading row details.</ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- header row-->
    <tr mat-header-row *matHeaderRowDef="renderedColumnNames$ | async; sticky: true"></tr>

    <!-- body rows -->
    <tr mat-row *matRowDef=" let row; let dataIndex = dataIndex; let index = index; let odd = odd; columns: renderedColumnNames$ | async" [class.mc-grid-row-striped]="getRowIsStriped(index, dataIndex)" [ngClass]="{ 'mc-grid-row-disabled': getDisabled(row) }" [class.expanded-row]="expandedRowId === row.Id" [class.expandable-row]="expandableRows" (click)="onBodyRowClicked(row)"></tr>

    <!-- expandable rows-->
    <ng-container *ngIf="expandableRows">
      <tr mat-row *matRowDef="let row; let dataIndex = dataIndex; let index = index; let odd = odd; columns: [GridColumnName.ExpandedDetail]" [class.mc-grid-row-striped]="getRowIsStriped(index, dataIndex)" class="expanded-detail-row"></tr>
    </ng-container>
  </table>

  <!-- empty pane -->
  <div class="mc-grid-empty" *ngIf="items?.length === 0">
    <ng-container [ngSwitch]="getEmptyGridDisplay()">
      <ng-container *ngSwitchCase="'filtered'">
        <ng-container *ngIf="getEmptyGridTemplate('filtered') as emptyGridTemplate; else defaultFilteredEmptyGridContent" [ngTemplateOutlet]="emptyGridTemplate" [ngTemplateOutletContext]="{ $implicit: filters }"></ng-container>
        <ng-template #defaultFilteredEmptyGridContent>
          <p>No items to display with the applied filters.</p>
          <button mat-stroked-button color="link" class="mc-button-normal mc-grid-empty-button" (click)="onClearAllFiltersClicked()">
            Clear All Filters
          </button>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'unfiltered'">
        <ng-container *ngIf="getEmptyGridTemplate('unfiltered') as unfilteredEmptyGridTemplate" [ngTemplateOutlet]="unfilteredEmptyGridTemplate" [ngTemplateOutletContext]="{ $implicit: filters }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- error pane -->
<div class="error-pane" *ngIf="errorMessage">
  <mc-errors-expansion-panel *ngIf="errorMessage" [generalError]="errorMessage" [detailedErrors]="detailedErrorMessages"></mc-errors-expansion-panel>

  <div>
    <button mat-stroked-button color="link" class="mc-button-normal mc-grid-empty-button" (click)="retryClicked.emit()">
      Retry Loading Grid
    </button>
    <button *ngIf="isFilteredOrSorted" mat-stroked-button color="link" class="mc-button-normal mc-grid-empty-button" (click)="onClearAllFiltersClicked()">
      Clear All Filters
    </button>
  </div>
</div>

<!-- loader -->
<mc-loader *ngIf="loadingData"></mc-loader>

<!-- footer -->
<div class="mc-grid-footer">
  <mc-grid-date-range *ngIf="showLastDays" [(days)]="lastDays" color="primary-vivid" (daysChange)="onLastDaysChanged($event)"></mc-grid-date-range>
  <mc-grid-rows-range *ngIf="showTopRows" [(rows)]="topRows" color="primary-vivid" (rowsChange)="onTopRowsChanged($event)"></mc-grid-rows-range>
  <span class="mc-fill-remaining-flex-space"></span>
  <ng-container *ngIf="showPagination && (!hideEmptyPagination || isItemCountLessThanMinPageSize)">
    <mc-indeterminate-paginator *ngIf="indeterminatePages; else paginator" color="primary-vivid" [length]="itemCount" [limitedLength]="limitedItemCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPageChanged($event)"></mc-indeterminate-paginator>
  </ng-container>
</div>

<ng-template #paginator>
  <mat-paginator color="primary-vivid" [length]="itemCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="onPageChanged($event)"></mat-paginator>
</ng-template>

<!-- menu -->
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-row="row">
    <ng-container *ngIf="contextMenuItemsTemplate" [ngTemplateOutlet]="contextMenuItemsTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
  </ng-template>
</mat-menu>