import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { ReviewPackageProfileModule } from '@portal-core/reviews/review-packages/components/review-package-profile/review-package-profile.module';
import { ReviewPackageProfileDialogComponent } from './review-package-profile-dialog.component';

@NgModule({
  declarations: [ReviewPackageProfileDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ReviewPackageProfileModule,
    LoaderModule,
    ErrorsExpansionPanelModule
  ],
  exports: [ReviewPackageProfileDialogComponent]
})
export class ReviewPackageProfileDialogModule { }
