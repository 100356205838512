import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';

export class ProjectFileFlatNode {
  constructor(
    public name: string,
    public id: number,
    public path: string,
    public level: number = 1,
    public expandable: boolean = false,
    public isLoading: boolean = false,
    public loaded: boolean = false,
    public selected: TriSelectedState =  TriSelectedState.NotSelected,
    public error: boolean = false
  ) { }
}
