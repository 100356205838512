import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownToFlareXMLPipe } from '@portal-core/project-files/pipes/markdown-to-flare-xml/markdown-to-flare-xml.pipe';


@NgModule({
    declarations: [
        MarkdownToFlareXMLPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MarkdownToFlareXMLPipe
    ]
})
export class MarkdownToFlareXMLModule { }