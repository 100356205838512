import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslationPackageLanguageBranchAvatar } from '@portal-core/translation-package-language-branches/components/translation-package-language-branch-avatar/translation-package-language-branch-avatar.component';
import { InlineButtonModule } from '@portal-core/ui/button/components/inline-button/inline-button.module';


@NgModule({
  declarations: [
    TranslationPackageLanguageBranchAvatar
  ],
  imports: [
    CommonModule,
    InlineButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    TranslationPackageLanguageBranchAvatar
  ]
})
export class TranslationPackageLanguageBranchAvatarModule { }
