import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { TaskProfileDialogComponent, TaskProfileDialogData } from '@portal-core/tasks/components/task-profile-dialog/task-profile-dialog.component';
import { TranslationPackageStatus } from '@portal-core/translation-packages/enums/translation-package-status.enum';

@Component({
  selector: 'mc-notification-message-tag',
  templateUrl: './notification-message-tag.component.html',
  styleUrls: ['./notification-message-tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageTagComponent {
  @Input() notification: Notification;
  @Input() tag: string;

  TranslationPackageStatus: typeof TranslationPackageStatus = TranslationPackageStatus;

  constructor(private dialog: MatDialog) { }

  openTaskDialog(taskId: number) {
    this.dialog.open<TaskProfileDialogComponent, TaskProfileDialogData>(TaskProfileDialogComponent, {
      ...TaskProfileDialogComponent.DialogConfig,
      data: { taskId }
    });
  }
}
