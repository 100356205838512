<mat-toolbar class="mc-toolbar-large" [ngClass]="toolbarClassName$ | async" [color]="toolbarColorName$ | async">
  <ng-container *ngIf="offline$ | async">
    <span>Documents cannot be edited while offline.</span>
  </ng-container>

  <ng-container *ngIf="collabSessionReadonly$ | async">
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
    <span>This file is no longer available for review. Click <a class="text-heavy-weight" href="https://documentation.madcapsoftware.com/central/Default.htm#cshid=Closed_Review_Files" target="_blank">here</a> for more info.</span>
  </ng-container>

  <ng-container *ngIf="showSizeWarning$ | async">
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
    <span>Document is approaching the size limit. Current size: {{ size | mcFileSize : 2 }}. Max size: {{ maxSize | mcFileSize : 0 }}</span>
  </ng-container>

  <ng-container *ngIf="showExceedsMaxSize$ | async">
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
    <span>Document is too large to save. Undo changes or click <button class="mc-link-button mc-inline-button mc-text-invert text-heavy-weight" (click)="onRevertDocClicked()">here</button> to revert to the last saved version. Current size: {{ size | mcFileSize : 2 }}. Max size: {{ maxSize | mcFileSize : 0 }}.</span>
  </ng-container>

  <ng-container *ngIf="showExceedsMongoDBMaxSize$ | async">
    <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
    <span>Document content and history are too large to save. Click <button class="mc-link-button mc-inline-button mc-text-invert text-heavy-weight" (click)="onRevertDocClicked()">here</button> to revert to the last saved version.</span>
  </ng-container>

  <ng-container *ngIf="collabSessionError$ | async as error">
    <ng-container [ngSwitch]="error">
      <ng-container *ngSwitchCase="CollabSessionError.UnableToReachServer">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        Unable to connect to server.
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.SchemaVersionIncompatible">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        The document's schema version is incompatible. Please refresh the page to get the latest schema. Your work will not be lost.
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.DocAccessDenied">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        Access denied.
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.DocLoadFailed">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        <ng-container [ngSwitch]="licenseUser.SeatType">
          <ng-container *ngSwitchCase="LicenseUserSeatType.Author">Central is unable to load the file. Please contact MadCap Software Technical Support.</ng-container>
          <ng-container *ngSwitchCase="LicenseUserSeatType.SME">Central is unable to load the file. An email notification has been sent to the owner, who should contact MadCap Software Technical Support for assistance.</ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.DocSizeTooLarge">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        <ng-container *ngIf="error$ | async as err">
          Document is too large to load. Current size: {{ size | mcFileSize : 2 }}. Max size: {{ maxSize | mcFileSize : 0 }}.
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.BadRequest">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        Unable to load document. Invalid parameters were sent to the server.
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.UnexpectedError">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        Unexpected server error. If the problem persists please contact your administrator.
      </ng-container>
      <ng-container *ngSwitchCase="CollabSessionError.MetaDataNotReady">
        <mat-icon fontSet="project-titan" fontIcon="icon-alert" class="mc-centered-text-icon"></mat-icon>
        The meta data is not ready to be used yet. Please wait then try again.
      </ng-container>
    </ng-container>
  </ng-container>
</mat-toolbar>