<mc-grid-header-filter [column]="column" [hasFilter]="hasFilter" (menuClosed)="onMenuClosed()" (menuClosing)="onMenuClosing()" (menuOpened)="onMenuOpened()">
  <ng-template mcGridHeaderFilterForm>
    <form #filterFormDirective="ngForm" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <mat-form-field color="primary-vivid">
        <mat-select placeholder="Condition" formControlName="filterType">
          <mat-option [value]="PageFilterType.Equals">Is equal to</mat-option>
          <mat-option [value]="PageFilterType.GreaterThan">Greater than</mat-option>
          <mat-option [value]="PageFilterType.LessThan">Less than</mat-option>
          <mat-option [value]="PageFilterType.Between">Between</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="primary-vivid">
        <input #fromInput type="number" matInput placeholder="Number" formControlName="from">
        <mat-error>
          <mc-form-control-error-code [form]="filterForm" controlName="from" type="mc_number"></mc-form-control-error-code>
        </mat-error>
      </mat-form-field>

      <div [ngClass]="{ 'mc-hidden': selectedFilterType !== PageFilterType.Between }">
        <p class="mc-form-title">And</p>
        <mat-form-field color="primary-vivid">
          <input type="number" matInput placeholder="Number" formControlName="to">
          <mat-error>
            <mc-form-control-error-code [form]="filterForm" controlName="to" type="mc_number"></mc-form-control-error-code>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mc-menu-button-group">
        <button type="button" mat-stroked-button color="warn" (click)="onClearFilterClicked()">Clear</button>
        <button type="submit" mat-flat-button color="primary">Filter</button>
      </div>
    </form>
  </ng-template>

  <ng-template *ngIf="menuTemplate" mcGridHeaderFilterMenu>
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </ng-template>
</mc-grid-header-filter>
