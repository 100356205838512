import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ProjectAvatarModule } from '@portal-core/projects/components/project-avatar/project-avatar.module';
import { ProjectAsyncModule } from '@portal-core/projects/pipes/project-async/project-async.module';
import { ClickModule } from '@portal-core/ui/clicking/directives/click/click.module';
import { FilterBarComponent } from './filter-bar.component';

@NgModule({
  declarations: [
    FilterBarComponent
  ],
  imports: [
    CommonModule,
    ClickModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    ProjectAvatarModule,
    ProjectAsyncModule
  ],
  exports: [
    FilterBarComponent
  ]
})
export class FilterBarModule { }
