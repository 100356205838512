<div class="mc-avatar-tooltip-container" [matTooltip]="tocPath" matTooltipClass="mc-tooltip-multiline" [matTooltipDisabled]="disabledTooltip || !tocPath">
  <a *ngIf="showAvatar && project && branchName && isLink() && !isImageDisabled()" class="mc-avatar-container" [routerLink]="routeCommands">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-reticle"></mat-icon>
  </a>

  <div *ngIf="showAvatar && (!project || !branchName || !isLink() || isImageDisabled())" class="mc-avatar-container">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-reticle"></mat-icon>
  </div>

  <a *ngIf="showName && project && branchName && isLink() && !isNameDisabled()" href="#" class="mc-avatar-name" [routerLink]="routeCommands"><span class="mc-toc-avatar-path-name mc-text-muted">{{ tocDirname }}</span>{{ tocBasename }}<span class="mc-toc-avatar-path-name mc-text-muted">{{ tocExtname }}</span></a>

  <span *ngIf="showName && (!project || !branchName || !isLink() || isNameDisabled())" class="mc-avatar-name"><span class="mc-toc-avatar-path-name mc-text-muted">{{ tocDirname }}</span><span class="mc-toc-avatar-base-name">{{ tocBasename }}</span><span class="mc-toc-avatar-path-name mc-text-muted">{{ tocExtname }}</span></span>
</div>
