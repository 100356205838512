import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { ContentSecurityPolicy } from '@portal-core/sites/models/content-security-policy.model';

@CollectionStateSelectors({
  stateType: ContentSecurityPolicyState
})
@State<ContentSecurityPolicyState>({
  name: ContentSecurityPolicyState.source,
})
@Injectable()
export class ContentSecurityPolicyState extends CollectionStateBase {
  static source = 'ContentSecurityPolicy';
  getSource(): string {
    return ContentSecurityPolicyState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContentSecurityPolicyDataService extends CollectionDataServiceBase<ContentSecurityPolicy> {
  constructor(protected store: Store) {
    super(store, ContentSecurityPolicyState);
  }
}
