import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { ReviewPackageUser } from '@portal-core/reviews/review-package-users/models/review-package-user.model';
import { ReviewPackageUsersService } from '@portal-core/reviews/review-package-users/services/review-package-users.service';
import { ReviewPackageUsersPageFilterConfig } from '@portal-core/reviews/review-package-users/util/review-package-users-page-filter-config';
import { ListComponent } from '@portal-core/ui/list/components/list/list.component';
import { ListControl } from '@portal-core/ui/list/util/list-control';
import { ListBase } from '@portal-core/ui/list/util/list.base';
import { uniq } from 'lodash';
import { map, of, switchMap } from 'rxjs';

@Component({
  selector: 'mc-review-package-user-list',
  templateUrl: './review-package-user-list.component.html',
  styleUrls: ['./review-package-user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewPackageUserListComponent extends ListBase<ReviewPackageUser> implements OnChanges {
  @Input() licenseId: number;
  @Input() reviewPackageId: number;

  @ViewChild(ListComponent, { static: true }) list: ListComponent;

  filterConfig: ReviewPackageUsersPageFilterConfig = new ReviewPackageUsersPageFilterConfig(['UserId', 'Status']);

  listControl: ListControl<ReviewPackageUser> = new ListControl<ReviewPackageUser>(this.reviewPackageUsersService, filter => {
    if (typeof this.reviewPackageId === 'number' && typeof this.licenseId === 'number') {
      const licenseId: number = this.licenseId;

      return this.reviewPackageUsersService.getReviewPackageUsersPage$(this.reviewPackageId, filter).pipe(
        switchMap(page => {
          if (Array.isArray(page.Items)) {
            // Bulk load all the users used by the review package user grid items
            return this.licenseUsersService.loadLicenseUsersByUserId$(licenseId,
              uniq(
                page.Items.flatMap(reviewPackageUser => reviewPackageUser.UserId ?? null).filter(userId => typeof userId === 'string')
              )
            ).pipe(map(() => page));
          }

          return of(page);
        })
      );
    } else {
      return of(null);
    }
  });

  constructor(private licenseUsersService: LicenseUsersService, private reviewPackageUsersService: ReviewPackageUsersService) {
    super(reviewPackageUsersService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId || changes.reviewPackageId) {
      this.hardReload();
    }
  }

  hardReload() {
    this.list.hardReload();
  }
}
