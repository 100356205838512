import { deleteSelectionAndFill, joinInlineNodeToTextBlock, splitBlock } from '@common/prosemirror/commands/node';
import { chainCommands, createParagraphNear, deleteSelection, exitCode, joinBackward, joinForward, liftEmptyBlock, newlineInCode, selectAll, selectNodeBackward, selectNodeForward } from 'prosemirror-commands';

const backspace = chainCommands(deleteSelectionAndFill, deleteSelection, joinBackward, joinInlineNodeToTextBlock, selectNodeBackward);
const del = chainCommands(deleteSelectionAndFill, deleteSelection, joinForward, selectNodeForward);

/**
 * A basic keymap containing bindings not specific to any schema.
 * Binds the following keys (when multiple commands are listed, they are chained with [`chainCommands`](#commands.chainCommands)):
 * **Enter** to `newlineInCode`, `createParagraphNear`, `liftEmptyBlock`, `splitBlock`
 * **Mod-Enter** to `exitCode`
 * **Backspace** and **Mod-Backspace** to `deleteSelection`, `joinBackward`, `selectNodeBackward`
 * **Delete** and **Mod-Delete** to `deleteSelection`, `joinForward`, `selectNodeForward`
 * **Mod-Delete** to `deleteSelection`, `joinForward`, `selectNodeForward`
 * **Mod-a** to `selectAll`
 */
export const pcBaseKeymap = {
  'Enter': chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlock),
  'Mod-Enter': exitCode,
  'Backspace': backspace,
  'Mod-Backspace': backspace,
  'Delete': del,
  'Mod-Delete': del,
  'Mod-a': selectAll
};

/**
 * A copy of `pcBaseKeymap` that also binds
 * **Ctrl-h** like Backspace
 * **Ctrl-d** like Delete
 * **Alt-Backspace** like Ctrl-Backspace
 * **Ctrl-Alt-Backspace**, **Alt-Delete**, and **Alt-d** like Ctrl-Delete
 */
export const macBaseKeymap = {
  'Ctrl-h': pcBaseKeymap['Backspace'],
  'Alt-Backspace': pcBaseKeymap['Mod-Backspace'],
  'Ctrl-d': pcBaseKeymap['Delete'],
  'Ctrl-Alt-Backspace': pcBaseKeymap['Mod-Delete'],
  'Alt-Delete': pcBaseKeymap['Mod-Delete'],
  'Alt-d': pcBaseKeymap['Mod-Delete'],
  ...pcBaseKeymap
};

const isMac = typeof navigator !== 'undefined' ? /Mac|iP(hone|[oa]d)/.test(navigator.platform) : false;

/**
 * The keymap for the detect platform (either Windows or MacOS)
 */
export const baseKeymap = isMac ? macBaseKeymap : pcBaseKeymap;
