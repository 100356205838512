import { ChangeAttrName } from '@common/flare/change-attr-name.enum';
import { SimpleDomNode } from '@common/html/simple-dom/node';
import { ChangeType } from '@common/prosemirror/changeset/change-type.enum';
import { TrackedChange } from '@common/prosemirror/changeset/tracked-change.type';
import { Mark, ProseMirrorNode } from 'prosemirror-model';

/**
 * Extracts tracked changes from Flare XML so they can be injected into a ProseMirror document.
 */
export class TrackedChangesImporter {
  private changeData: Dictionary<TrackedChange>;

  public extractChangeData(changeDataNode: SimpleDomNode) {
    const changeData: Dictionary<TrackedChange> = {
      // Initialize with an untracked change. All untracked changes have the same change data and do not appear in <MadCap:changeData>
      // This makes the data for an untracked change available when an untracked change is encountered in the <body> later in this function
      [ChangeType.Untracked]: {
        changeType: ChangeType.Untracked,
        id: ChangeType.Untracked
      }
    };

    // Store the <MadCap:changeData> info in map changeId => data
    for (let i = 0, length = changeDataNode.children.length; i < length; i += 1) {
      const changeElement = changeDataNode.children[i];
      let changeContent: string;
      let changeType: ChangeType;

      switch (changeElement.nodeName) {
        case 'MadCap:AddChange':
          changeType = ChangeType.Add;
          break;
        case 'MadCap:RemoveChange':
          changeType = ChangeType.Remove;
          break;
        case 'MadCap:ReplaceChange':
          changeType = ChangeType.Replace;
          changeContent = changeElement.innerHTML;
          break;
        case 'MadCap:BindChange':
          changeType = ChangeType.Bind;
          break;
        case 'MadCap:UnbindChange':
          changeType = ChangeType.Unbind;
          changeContent = changeElement.innerHTML;
          break;
        case 'MadCap:AttributesChange':
          changeType = ChangeType.Attributes;
          break;
        default:
          throw new Error('Unknown change element found. ' + changeElement.nodeName);
      }

      changeData[changeElement.getAttribute('MadCap:id')] = {
        attribute: changeElement.getAttribute(ChangeAttrName.Attribute) ?? undefined,
        changeContent: changeContent,
        changeType: changeType,
        creatorCentralUserId: changeElement.getAttribute(ChangeAttrName.CreatorCentralUserId) ?? undefined,
        id: changeElement.getAttribute('MadCap:id') ?? undefined,
        initials: changeElement.getAttribute(ChangeAttrName.Initials) ?? undefined,
        timestamp: changeElement.getAttribute(ChangeAttrName.Timestamp) ?? undefined,
        userName: changeElement.getAttribute(ChangeAttrName.UserName) ?? undefined,
        value: changeElement.getAttribute(ChangeAttrName.Value) ?? undefined
      };
    }

    this.changeData = changeData;
  }

  public injectChangeData(node: ProseMirrorNode) {
    if (!this.changeData) {
      return;
    }

    node.descendants(node => {
      this.updateChangeAttrs(node);

      node.marks.forEach(mark => {
        if (mark.type.name === 'madcapchange') {
          this.updateChangeAttrs(mark);
        }
      });
    });
  }

  private updateChangeAttrs(nodeOrMark: ProseMirrorNode | Mark) {
    const changeIds = (nodeOrMark.attrs.changeIds as string)?.split(',').map(changeId => changeId.trim());

    if (changeIds?.length > 0) {
      const changeList = changeIds.map(changeId => this.changeData[changeId]).filter(change => !!change);
      if (changeList?.length > 0) {
        // Cast as any to set a readonly property
        (nodeOrMark.attrs as any).changeList = changeList;
      }
    }
  }
}
