import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationMessageTagModule } from '@portal-core/notifications/components/notification-message-tag/notification-message-tag.module';
import { NotificationWrapperTagModule } from '@portal-core/notifications/components/notification-message-wrapper/notification-message-wrapper.module';
import { NotificationMessageComponent } from './notification-message.component';

@NgModule({
  declarations: [
    NotificationMessageComponent
  ],
  imports: [
    CommonModule,
    NotificationMessageTagModule,
    NotificationWrapperTagModule
  ],
  exports: [
    NotificationMessageComponent
  ]
})
export class NotificationMessageModule { }
