import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationExtras, Router } from '@angular/router';
import { CoreService } from '@portal-client/core/core.service';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthStatusMessage } from '@portal-core/auth/enums/auth-status-message.enum';
import { OnAuthError } from '@portal-core/auth/interfaces/auth-error.interface';
import { OnAuthRoute } from '@portal-core/auth/interfaces/auth-route.interface';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { AuthRedirect } from '@portal-core/auth/types/auth-redirect.type';
import { RouterService } from '@portal-core/general/services/router.service';
import { LicenseUserSeatType } from '@portal-core/license-users/enums/license-user-seat-type.enum';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Observable, first, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthEventHandlerService implements OnAuthError, OnAuthRoute {
  constructor(
    private authService: AuthService,
    private coreService: CoreService,
    private licenseUsersService: LicenseUsersService,
    private router: Router,
    private routerService: RouterService,
    private dialog: MatDialog
  ) { }

  onAuthError(type: AuthStatusMessage) {
    const currentLicense = this.coreService.getLicense();

    // Close any open dialogs when navigating to the alert page
    this.dialog.closeAll();

    this.router.navigate(['/alert'], {
      queryParams: {
        licenseId: currentLicense ? currentLicense.Id : null,
        type
      }
    });
  }

  onAuthRoute$(authRoute: AuthRoute, extras?: NavigationExtras): Observable<AuthRedirect> {
    switch (authRoute) {
      case AuthRoute.Home:
        // Try the return url
        const returnUrl = this.routerService.getCurrentQueryParams()['returnUrl'];
        if (returnUrl) {
          return of(returnUrl);
        }

        const licenseId = this.authService.getSubdomainLicense()?.Id;
        if (this.authService.isAuthenticated() && typeof licenseId === 'number') {
          // On a page load with an empty route the license user data hasn't been loaded yet so use an observable to get the license user data here
          return this.licenseUsersService.getLicenseUserByUserId$(this.authService.getUserId(), licenseId).pipe(
            first(licenseUser => !!licenseUser),
            // If the user is an SME then redirect to the reviews page, otherwise redirect to the home page
            map(licenseUser => {
              const page = licenseUser?.SeatType === LicenseUserSeatType.SME ? 'reviews' : 'home';
              return { commands: ['/', licenseId, page], extras };
            })
          );
        } else {
          return of({ commands: ['/'], extras });
        }
        break;
    }
  }
}
