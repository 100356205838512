import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppGettingStartedDialogComponent } from '@portal-client/app/components/app-getting-started-dialog/app-getting-started-dialog.component';
import { GetDataOptions } from '@portal-core/data/common/models/get-data-options.model';
import { DataService } from '@portal-core/data/common/services/data.service';
import { VersionService } from '@portal-core/general/services/version.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseActivationType } from '@portal-core/licenses/enums/license-activation-type.enum';
import { License } from '@portal-core/licenses/models/license.model';
import { UsersService } from '@portal-core/users/services/users.service';
import { IResettable, Resettable } from '@portal-core/util/resettable.decorator';
import { Observable, distinctUntilChanged, first, map } from 'rxjs';
import { CoreDataService } from './core-data.service';

@Injectable({
  providedIn: 'root'
})
@Resettable()
export class CoreService implements IResettable {
  constructor(
    private coreDataService: CoreDataService,
    private dataService: DataService,
    private versionService: VersionService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) { }

  reset$(): Observable<any> {
    return this.coreDataService.reset$();
  }

  // User Id
  getUserId(): string {
    return this.getLicenseUser()?.User?.Id;
  }

  getUserId$(): Observable<string> {
    return this.getLicenseUser$().pipe(
      map(licenseUser => licenseUser?.User?.Id)
    );
  }

  // License Id
  getLicenseId(): number {
    return this.coreDataService.getLicenseId();
  }

  getLicenseId$(): Observable<number> {
    return this.coreDataService.getLicenseId$();
  }

  setLicenseId$(licenseId: number): Observable<any> {
    return this.coreDataService.setLicenseId$(licenseId);
  }

  // License
  getLicense(): License {
    return this.coreDataService.getLicense();
  }

  getLicense$(): Observable<License> {
    return this.coreDataService.getLicense$();
  }

  // License User Id
  getLicenseUserId(): number {
    return this.coreDataService.getLicenseUserId();
  }

  getLicenseUserId$(): Observable<number> {
    return this.coreDataService.getLicenseUserId$();
  }

  setLicenseUserId$(licenseUserId: number): Observable<any> {
    return this.coreDataService.setLicenseUserId$(licenseUserId);
  }

  // LicenseUser
  getLicenseUser(): LicenseUser {
    return this.coreDataService.getLicenseUser();
  }

  getLicenseUser$(): Observable<LicenseUser> {
    return this.coreDataService.getLicenseUser$();
  }

  // Active License Id
  getActiveLicenseId(): number {
    const license = this.coreDataService.getLicense();
    return license && license.LicenseActivationStatus === LicenseActivationType.Active ? license.Id : undefined;
  }

  getActiveLicenseId$(): Observable<number> {
    return this.getLicense$().pipe(
      map(license => license && license.LicenseActivationStatus === LicenseActivationType.Active ? license.Id : undefined),
      distinctUntilChanged()
    );
  }

  // Active License
  getActiveLicense(): License {
    const license = this.coreDataService.getLicense();
    return license && license.LicenseActivationStatus === LicenseActivationType.Active ? license : null;
  }

  getActiveLicense$(): Observable<License> {
    return this.getLicense$().pipe(
      map(license => license && license.LicenseActivationStatus === LicenseActivationType.Active ? license : null)
    );
  }

  // Settings
  getSettingByName<T>(name: string): T {
    return this.coreDataService.getSettingByName(name);
  }

  getSettingByName$<T = any>(name: string): Observable<T> {
    return this.coreDataService.getSettingByName$(name);
  }

  setSettingByName$(name: string, value: any): Observable<any> {
    return this.coreDataService.setSettingByName$(name, value);
  }

  // Version and Build Date
  getVersionAndBuildDate(): string {
    return this.coreDataService.getVersionAndBuildDate();
  }

  getVersionAndBuildDate$(options: GetDataOptions = null): Observable<string> {
    return this.dataService.getData<string>({
      get: () => this.coreDataService.getVersionAndBuildDate$(),
      fetch: () => this.versionService.getVersionAndBuildDate$(),
      set: versionAndBuildDate => this.setVersionAndBuildDate$(versionAndBuildDate)
    }, options);
  }

  setVersionAndBuildDate$(versionAndBuildDate: string): Observable<any> {
    return this.coreDataService.setVersionAndBuildDate$(versionAndBuildDate);
  }

  // Getting Started Dialog
  checkAndShowGettingStartedDialog() {
    // Grab the current license user
    this.getLicenseUser$().pipe(
      first(licenseUser => !!licenseUser)
    ).subscribe(licenseUser => {
      // Check if the license user has disabled the getting started dialog
      if (licenseUser.User && !licenseUser.User.DisabledGettingStarted) {
        // Wait a second because its jarring to see the dialog immediately
        setTimeout(() => {
          // Open the getting started dialog
          const dialogRef = this.dialog.open(AppGettingStartedDialogComponent, {
            ...AppGettingStartedDialogComponent.DialogConfig
          });

          // Listen for the dialog closing in order to update the user's settings
          dialogRef.afterClosed().subscribe(() => {
            // If the dialog was set to disable the getting started dialog THEN save that in the user's settings
            if (dialogRef.componentInstance.disableGettingStartedDialog) {
              const currentLicenseUser = this.getLicenseUser();

              // Update the user settings. There is nothing to do on success or failure
              this.usersService.disableGettingStartedSetting$(currentLicenseUser.User.Id).subscribe();
            }
          });
        }, 1000);
      }
    });
  }
}
