import { OverlayContainer } from '@angular/cdk/overlay';

export class ElementOverlayContainer extends OverlayContainer {
  constructor(private containerElement: HTMLElement) {
    super(null, null);
  }

  getContainerElement(): HTMLElement {
    if (!this.containerElement) {
      throw new Error('Container element is not set. Please call setContainerElement first.');
    }

    return this.containerElement;
  }

  // Override _createContainer to do nothing because the container is given to us.
  protected _createContainer() { }
}
