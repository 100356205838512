import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ImageService } from '@portal-core/general/services/image.service';
import { TaskAssetDelete } from '@portal-core/tasks/models/task-asset-delete.model';
import { TaskAsset } from '@portal-core/tasks/models/task-asset.model';
import { TasksService } from '@portal-core/tasks/services/tasks.service';

@Component({
  selector: 'mc-task-asset',
  templateUrl: './task-asset.component.html',
  styleUrls: ['./task-asset.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAssetComponent implements OnInit {
  @Input() asset: TaskAsset;
  @Input() canEditAllTasks: boolean;
  @Output() delete: EventEmitter<TaskAssetDelete> = new EventEmitter();

  isNew: boolean = false;
  url: string;

  constructor(private tasksService: TasksService, private imageService: ImageService) { }

  ngOnInit() {
    this.isNew = this.asset.Id === 0;
    this.url = this.imageService.getMadCapImageUrl(this.asset.StorageId, this.asset.Extension);
  }

  onDownload() {
    this.tasksService.downloadTaskAsset(this.url, this.asset.FileName);
  }

  onDelete() {
    this.delete.emit({ Asset: this.asset });
  }
}
