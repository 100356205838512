import { madcapConditionsAttrName, madcapExcludeActionAttrName } from '@common/flare/schema/madcap-condition-schema-plugin';
import { FoundNodeInfo, deepestBlocksBetween, rangeNode } from '@common/prosemirror/model/node';
import { resolvedPosFind } from '@common/prosemirror/model/resolved-pos';
import { selectedTableNodes } from '@common/prosemirror/model/table';
import { restoreNodeSelection, selectNodeContent } from '@common/prosemirror/state/transaction';
import { inlineRangeForWrapping, isInlineRange, wrapInlineRange } from '@common/prosemirror/transform/inline-range';
import { unbind, updateNodeAttrs } from '@common/prosemirror/transform/node';
import { equalConditions, getSameConditionSet, splitConditions } from '@common/util/conditions';
import { Attrs, NodeType, ProseMirrorNode, ResolvedPos } from 'prosemirror-model';
import { AllSelection, EditorState, NodeSelection } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { Transform } from 'prosemirror-transform';
import { EditorView } from 'prosemirror-view';

export enum ExcludeAction {
  Remove = 'remove',
  Unbind = 'unbind'
}

/**
 * Document node conditions.
 */
export declare type Conditions = {
  readonly conditions?: string[],
  action?: ExcludeAction
};

/**
 * Editor command which applies conditions to the document selection.
 */
export declare type ApplyConditionsCommand = (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, conditions?: Conditions) => boolean;

/**
 * Gets the conditions applied to the document selection.
 */
export function selectedConditions(state: EditorState): Conditions {
  const sel = state.selection, { $from, $to, empty } = sel;
  // all document is selected, so read conditions from <html> node
  if (sel instanceof AllSelection) {
    return nodeConditions(state.doc.firstChild);
    // specific node is selected, so read conditions from it
  } else if (sel instanceof NodeSelection) {
    const info = nodeForCondition(state);
    return nodeConditions(info.node);
    // if is empty selection or blocks are selected then get conditions from selected blocks
  } else if (empty || !isInlineRange($from, $to)) {
    const blocks = blocksForConditions(state);
    if (!blocks.length) {
      return null;
    }
    if (blocks.length === 1) {
      return nodeConditions(blocks[0].node);
    }

    // compare applied conditions for all selected blocks
    const c = getSameConditionSet(blocks, (block) => nodeConditions(block.node)?.conditions);
    // action is specified only if the single node is selected
    return { conditions: c, action: null };
    // otherwise get applied conditions from selected inline range
  } else {
    const range = inlineRangeForConditions($from, $to);
    // read conditions if only node is selected
    const node = rangeNode(range.$from, range.$to);
    // specify action since a new node will be created
    return node ? nodeConditions(node) : { conditions: null, action: ExcludeAction.Remove };
  }
}

/**
 * Applies given conditions to the document selection.
 */
export function applyConditions(): ApplyConditionsCommand {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher, view?: EditorView, conditions?: Conditions): boolean {
    if (dispatch) {
      const hasConditions = !!conditions?.conditions?.length;
      const tr = state.tr, sel = state.selection, { $from, $to, empty } = sel;
      // all document is selected, so apply conditions to <html> node
      if (sel instanceof AllSelection) {
        updateConditionAttrs(tr, [{ node: tr.doc, pos: 0 }], conditions);
        // specific node is selected, so apply conditions to it
      } else if (sel instanceof NodeSelection) {
        const info = nodeForCondition(state);
        updateConditionAttrs(tr, [info], conditions);
        if (!hasConditions && info.node.type === info.node.type.schema.nodes.madcapconditionaltext) {
          // there was a conditional text node and we unbind it, so restore selection on its place
          restoreNodeSelection(tr, info.node, info.pos);
        } else {
          // otherwise just select the node
          tr.setSelection(NodeSelection.create(tr.doc, info.pos));
        }
      } else if (empty || !isInlineRange($from, $to)) {
        // if is empty selection or blocks are selected then apply condition attributes to selected blocks
        const blocks = blocksForConditions(state);
        updateConditionAttrs(tr, blocks, conditions);
      } else {
        // otherwise apply conditions to the selected inline range
        const range = inlineRangeForConditions($from, $to);
        const node = rangeNode(range.$from, range.$to);
        if (node && !node.isText) {
          const pos = range.$from.pos;
          // if a single node is selected then apply conditions to it
          updateConditionAttrs(tr, [{ node, pos }], conditions);
          restoreNodeSelection(tr, node, pos);
        } else if (hasConditions) {
          // if conditions are defined - wrap the selected inline range into the new 'MadCap:conditionalText' node
          const attrs = conditionAttrs(conditions);
          wrapInlineRange(tr, range, state.schema.nodes.madcapconditionaltext, attrs, (node, pos) => {
            // select the newly created node content
            selectNodeContent(tr, node, pos);
          });
        }
        // otherwise do nothing
      }
      if (tr.docChanged) {
        if (!(sel instanceof AllSelection)) {
          tr.scrollIntoView();
        }
        dispatch(tr);
      }
    }
    return true;
  }
}

/**
 * Gets a valid node to apply conditions on the node selection.
 */
function nodeForCondition(state: EditorState): FoundNodeInfo {
  const forbidden = forbiddenTypes(state);
  const isForbidden = (node: ProseMirrorNode): boolean => {
    return forbidden.has(node.type) && !hasConditions(node);
  };
  const { node, $from, from } = state.selection as NodeSelection;
  if (!isForbidden(node)) {
    return { node: node, pos: from };
  }
  // if the node is forbidden then get its valid ancestor
  const ancestor = resolvedPosFind($from, parent => !isForbidden(parent));
  return { node: ancestor.node, pos: ancestor.$nodePos.pos };
}

/**
 * Gets the correct inline range from the given range to read/apply conditions.
 */
function inlineRangeForConditions($from: ResolvedPos, $to: ResolvedPos): { $from: ResolvedPos, $to: ResolvedPos } {
  // some of inline node types has restrictions for conditions
  // conditions allowed only inside child nodes or must be applied on the whole node
  const nodeTypes = $from.doc.type.schema.nodes;
  const strictTypes = new Set([
    nodeTypes.madcapexpanding,
    nodeTypes.madcappopup,
  ]);

  let range = inlineRangeForWrapping($from, $to);
  const node = rangeNode(range.$from, range.$to);
  if (node && !hasConditions(node)) {
    // try to select the only descant node which has conditions
    const cn = singleDescantWithConditions(node, range.$from.pos);
    if (cn) {
      const doc = range.$from.doc;
      const end = cn.pos + cn.node.nodeSize;
      range = { $from: doc.resolve(cn.pos), $to: doc.resolve(end) };
    }
  }

  const strictNode = resolvedPosFind(range.$from, (node) => strictTypes.has(node.type));
  if (!strictNode) {
    return range;
  }

  const sharedDepth = range.$from.sharedDepth(range.$to.pos);
  for (let depth = sharedDepth; depth > strictNode.depth; depth--) {
    // if the range inside the single child node then it's ok
    if (range.$from.start(depth) === range.$to.start(depth)) {
      return range;
    }
  }

  // create a new range bounding the strict node
  const $before = strictNode.$nodePos;
  const $after = $before.doc.resolve($before.pos + strictNode.node.nodeSize);
  return { $from: $before, $to: $after };
}

/**
 * Gets block nodes from the given range to read/apply conditions.
 */
function blocksForConditions(state: EditorState): FoundNodeInfo[] {
  const sel = state.selection;
  if (sel instanceof CellSelection) {
    return selectedTableNodes(state);
  }
  const { $from, $to } = sel, forbidden = forbiddenTypes(state);
  return deepestBlocksBetween($from, $to, node => !forbidden.has(node.type) || hasConditions(node));
}

/**
 * Gets a descant node which has condition attributes from the specified node.
 * The returning node will be a single child for its parent and so on till for the given node.
 */
function singleDescantWithConditions(node: ProseMirrorNode, pos: number): FoundNodeInfo {
  if (node.childCount !== 1)
    return null;
  const child = { node: node.firstChild, pos: pos + 1 };
  if (hasConditions(child.node))
    return child;
  return singleDescantWithConditions(child.node, child.pos);
}

/**
 * Some of node types are forbidden to have conditions.
 */
function forbiddenTypes(state: EditorState): Set<NodeType> {
  const nodeTypes = state.schema.nodes;
  return new Set([
    nodeTypes.body,
    nodeTypes.mcCentralContainer,
    nodeTypes.madcapdropdownhead,
    nodeTypes.madcapdropdownbody,
    nodeTypes.madcapcodesnippetcaption,
    nodeTypes.madcapcodesnippetbody,
    nodeTypes.madcapquestion,
    nodeTypes.madcappopuphead,
    nodeTypes.madcappopupbody,
    nodeTypes.madcapexpandinghead,
    nodeTypes.madcapexpandingbody,
    // do not allow to apply conditions to table col nodes for text selection
    nodeTypes.col,
    nodeTypes.colgroup
  ]);
}

/**
 * Updates condition attributes for the given nodes.
 */
function updateConditionAttrs(tr: Transform, nodes: FoundNodeInfo[], conditions?: Conditions): Transform {
  const hasConditions = !!conditions?.conditions?.length;
  for (const { node, pos } of nodes) {
    const nc = nodeConditions(node);
    if (equalConditions(conditions?.conditions, nc?.conditions)
      // compare exclude action only if the given action is defined
      // otherwise leave the node exclude action untouched
      && (!conditions?.action || conditions.action === nc.action)) {
      continue;
    }
    if (!hasConditions && node.type === node.type.schema.nodes.madcapconditionaltext) {
      // in case the node is 'MadCap:conditionalText' and conditions are not defined - unbind the node
      unbindConditions(tr, node, pos);
    } else {
      const attrs = conditionAttrs(conditions, node);
      updateNodeAttrs(tr, node, pos, attrs);
    }
  }
  return tr;
}

/**
 * Unbinds the given condition text node from the document.
 */
function unbindConditions(tr: Transform, node: ProseMirrorNode, pos: number): Transform {
  return unbind(tr, node, pos, (left) => left.node.isInline);
}

function hasConditions(node: ProseMirrorNode): boolean {
  return !!nodeConditions(node)?.conditions?.length;
}

/**
 * Gets applied conditions to the given node.
 */
function nodeConditions(node: ProseMirrorNode): Conditions {
  // 'remove' is the default exclude action
  const action: ExcludeAction = node.attrs[madcapExcludeActionAttrName] ?? ExcludeAction.Remove;
  const conditions: string[] = splitConditions(node.attrs[madcapConditionsAttrName])?.sort();
  return { conditions, action };
}

/**
 * Gets applied conditions value to the given node.
 */
export function nodeConditionsValue(node: ProseMirrorNode): string {
  return node.attrs[madcapConditionsAttrName];
}

/**
 * Creates condition attributes with given conditions for the specified node.
 */
function conditionAttrs(conditions: Conditions, node?: ProseMirrorNode): Attrs {
  let action = conditions?.action ?? undefined;
  // if the action is not defined means that the node action should be unchanged
  if (!action && !!node) {
    action = nodeConditions(node).action ?? undefined;
  }
  // 'remove' is the default action and does not require to be stored in attributes
  if (action === ExcludeAction.Remove) {
    action = undefined;
  }
  const cs = !!conditions?.conditions?.length ? conditions.conditions.sort().toString() : undefined;
  // if conditions are not defined the action also should be removed from attributes
  if (!cs) {
    action = undefined;
  }
  return {
    [madcapConditionsAttrName]: cs,
    [madcapExcludeActionAttrName]: action
  };
}
