import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Conversation } from '@portal-core/messages/models/conversation.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'

})
export class ConversationsApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getConversationsByLicenseId$(licenseId: number): Observable<Conversation[]> {
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/conversations/license/${licenseId}`).pipe(
      map(data => data.Items)
    );
  }

  @SharePendingRequest()
  getConversationMessages$(conversationId: number, markRead: boolean, count: number, messageId?: number): Observable<Conversation> {
    return this.http.get<Conversation>(`${this.apiService.centralApiBaseUri}/api/conversations/${conversationId}/messages?markRead=${markRead}&count=${count}&messageId=${messageId}`);
  }

  @SharePendingRequest()
  getMyConversationUnreadCountByLicenseId$(licenseId: number): Observable<number> {
    return this.http.get<{ Count: number }>(`${this.apiService.centralApiBaseUri}/api/conversations/messages/${licenseId}/unread`).pipe(
      map(response => response.Count)
    );
  }

  addMessageToConversation$(conversationId: number, messageBody: string): Observable<Conversation> {
    return this.http.post<Conversation>(`${this.apiService.centralApiBaseUri}/api/conversations/${conversationId}/message`,
      '=' + messageBody,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    );
  }

  createConversation$(conversation: any): Observable<Conversation> {
    return this.http.post<Conversation>(`${this.apiService.centralApiBaseUri}/api/conversations`, conversation);
  }

  hideConversation$(conversationId: number): Observable<any> {
    return this.http.get<any>(`${this.apiService.centralApiBaseUri}/api/conversations/${conversationId}/hide`);
  }

  deleteMessage$(messageId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiService.centralApiBaseUri}/api/conversations/messages/${messageId}/delete`);
  }
}
