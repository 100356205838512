import { Injectable, Inject, Injector } from '@angular/core';
import { LayoutModuleConfigService } from '@portal-core/layout/services/layout-module-config.service';
import { LayoutModuleConfig } from '@portal-core/layout/models/layout-module-config';
import { OnOpenAboutDialog } from '@portal-core/layout/services/layout-event-handler.interface';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(@Inject(LayoutModuleConfigService) private config: LayoutModuleConfig, private injector: Injector) { }

  emitOpenAboutDialog() {
    // If any onOpenAboutDialog event handlers have been registered
    if (Array.isArray(this.config.onOpenAboutDialog)) {
      // Then loop through each handler and call its method
      this.config.onOpenAboutDialog.forEach(handlerClass => {
        const handler: OnOpenAboutDialog = this.injector.get<OnOpenAboutDialog>(handlerClass);
        if (handler.onOpenAboutDialog) {
          handler.onOpenAboutDialog();
        }
      });
    }
  }
}
