import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { RichTextSerializer } from '@common/rich-text/rich-text-serializer';
import { LinkSchemaPlugin } from '@common/rich-text/schema/link-schema.plugin';
import { UnderlineSchemaPlugin } from '@common/rich-text/schema/underline-schema.plugin';
import { ProseMirrorNode, Schema } from 'prosemirror-model';
import { schema as schemaBasic } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';

export class RichTextSchema extends Schema {
  get name(): string { return 'rich-text'; }
  get version(): string { return '1.0.0'; }

  richTextSerializer: RichTextSerializer;

  constructor() {
    const schemaPlugins: SchemaPlugin[] = [
      new LinkSchemaPlugin(),
      new UnderlineSchemaPlugin()
    ];

    // Build the default schema nodes using prosemirror's defaults
    let nodesMap = schemaBasic.spec.nodes.remove('image');
    nodesMap = addListNodes(schemaBasic.spec.nodes, 'paragraph block*', 'block');
    const nodes = nodesMap.toObject();

    // Build the default schema marks using prosemirror's defaults (remove the link mark because we define our own)
    const marks = schemaBasic.spec.marks.remove('link').toObject();

    // Add schema nodes and marks from the schema plugins
    schemaPlugins.forEach(plugin => {
      plugin.addNodes(nodes);
      plugin.addMarks(marks);
    });

    // Let the schema plugins modify the nodes and marks now that they are all added
    schemaPlugins.forEach(plugin => {
      plugin.modifyNodesAndMarks(nodes, marks);
    });

    super({ nodes, marks });

    this.richTextSerializer = new RichTextSerializer();

    // Add schema props from the schema modules
    schemaPlugins.forEach(plugin => {
      plugin.addProps(this);
    });
  }

  codeToDoc(code: string): ProseMirrorNode {
    return this.richTextSerializer.codeToDoc(code, this);
  }

  nodeToCode(node: ProseMirrorNode): string {
    return this.richTextSerializer.nodeToCode(node, this);
  }
}
