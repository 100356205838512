import { ESCAPE } from '@angular/cdk/keycodes';
import { FlareCommands } from '@common/flare/flare-commands';
import { FlareSchema } from '@common/flare/flare-schema';
import { selectionIsInNode } from '@common/prosemirror/state/selection';
import { NodeToolbarPopupPluginViewComponent } from '@portal-core/text-editor/components/node-toolbar-popup-plugin-view/node-toolbar-popup-plugin-view.component';
import { ComponentPluginView } from '@portal-core/text-editor/plugin-views/component.plugin-view';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { EditorState, NodeSelection } from 'prosemirror-state';
import { EditorProps, EditorView } from 'prosemirror-view';
import { Subscription } from 'rxjs';

export interface NodeToolbarPopupPluginOptions {
  onEdit?: (src: string) => void;
  onOpen?: (src: string) => void;
  isEditable: (src: string) => boolean;
  isReadonly: boolean;
}

export class NodeToolbarPopupPluginView extends ComponentPluginView<NodeToolbarPopupPluginViewComponent> {
  private flareCommands: FlareCommands;
  private nodeSrc: string;
  private options: NodeToolbarPopupPluginOptions;
  private subscriptions: Subscription;

  props: EditorProps = {
    // Listens for the escape key to be pressed to close the popup
    handleKeyDown: (editorView: EditorView, event: KeyboardEvent): boolean => {
      if (event.keyCode === ESCAPE) {
        event.stopPropagation();
        return true;
      } else {
        return false;
      }
    }
  };

  constructor(elementOverlayService: ElementOverlayService, options: NodeToolbarPopupPluginOptions) {
    super(NodeToolbarPopupPluginViewComponent, elementOverlayService);

    this.options = options;
  }

  updateComponent(editorView: EditorView, prevEditorState: EditorState) {
    const selection = editorView.state.selection as NodeSelection;

    // Find a valid node type that the selection is within
    const isInSnippet = selectionIsInNode(selection, editorView.state.schema.nodes.madcapsnippettext) || selectionIsInNode(selection, editorView.state.schema.nodes.madcapsnippetblock);

    if (isInSnippet) {
      this.nodeSrc = selection.node.attrs.src;

      this.component.isEditable = this.options.isEditable(this.nodeSrc);
      const nodeRect = (editorView as any).lastSelectedViewDesc?.nodeDOM?.querySelector('.mc-node-view')?.getBoundingClientRect();
      const popupMarginTop = 2;
      const popupArrowHeight = 7;

      const popupLeft = nodeRect.left;
      const popupTop = nodeRect.bottom + popupMarginTop + popupArrowHeight;

      this.component.openPopup(popupLeft, popupTop);
    } else {
      this.component.closePopup();
    }
  }

  onComponentCreated() {
    this.subscriptions = this.component.openNode.subscribe(this.onOpenNode.bind(this));
    this.subscriptions.add(this.component.removeNode.subscribe(this.onRemoveNode.bind(this)));
    this.subscriptions.add(this.component.editNode.subscribe(this.onEditNode.bind(this)));
  }

  onComponentDestroyed() {
    this.subscriptions.unsubscribe();
  }

  onEditNode() {
    this.options.onEdit(this.nodeSrc);
  }

  onOpenNode() {
    this.options.onOpen(this.nodeSrc);
    this.closePopup();
  }

  onRemoveNode() {
    this.flareCommands = this.flareCommands ?? new FlareCommands(this.editorView.state.schema as FlareSchema);
    this.flareCommands.deleteSelection(this.editorView.state, this.editorView.dispatch, this.editorView);
    this.closePopup();
  }

  closePopup() {
    this.editorView.focus();
    this.component.closePopup();
  }
}
