import { ToFlareXMLOptions } from '@common/flare/types/to-flare-xml-options.type';
import { nodeToFlareXML } from '@common/flare/util/prosemirror-flare-xml';
import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { getSchemaAttrs } from '@common/util/schema';
import { NodeSpec, ProseMirrorNode } from 'prosemirror-model';

export class ScriptSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    script: {
      group: 'inline',
      inline: true,
      attrs: {
        content: { default: undefined, skipExport: true },
        src: { default: undefined }
      },
      parseDOM: [{
        tag: 'script',
        preserveWhitespace: true,
        getAttrs: function (dom: HTMLElement) {
          return {
            ...getSchemaAttrs(dom, ['src']),
            content: dom.outerHTML
          };
        }
      }],
      toDOM() { return ['span', { class: 'mc-pm-script mc-pm-script-inline' }, 'Script']; },
      toFlareXML(node: ProseMirrorNode, options: ToFlareXMLOptions): string {
        return nodeToFlareXML(node, options, 'script', {
          xml: node.attrs.content
        });
      },
      tagName: 'script'
    }
  };
}
