import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

/**
 * TextSchemaPlugin
 */
export class TextSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    text: {
      group: 'inline'
    }
  };
}
