import { HtmlAttributeNormalizer } from '@common/clipboard/html/attributes';
import { HtmlEmptyNodeNormalizer } from '@common/clipboard/html/empty-nodes';
import { HtmlPageBreakNormalizer } from '@common/clipboard/html/page-breaks';
import { HtmlStyleNormalizer } from '@common/clipboard/html/styles';
import { HtmlPasteNormalizerData } from '@common/clipboard/types/html-paste-normalizer-data.type';
import { HtmlPasteNormalizerOptions } from '@common/clipboard/types/html-paste-normalizer-options.type';
import { HtmlPasteNormalizer } from '@common/clipboard/types/html-paste-normalizer.type';
import { descendants } from '@common/html/util/dom';

/**
 * Normalizes HTML.
 */
export class HtmlNormalizer implements HtmlPasteNormalizer {
  /**
   * Always returns true because this normalizer can run on any HTML.
   * @param html The HTML to check.
   * @returns Always returns true.
   */
  matches(html: string): boolean {
    return true;
  }

  /**
   * Normalizes the HTML.
   * @param doc The HTML doc to normalize.
   * @returns The normalized HTML document.
   */
  normalize(data: HtmlPasteNormalizerData, options?: HtmlPasteNormalizerOptions): Document {
    const doc = data.doc;

    // Remove <meta> tags. The <meta> tags are normally only in the <head> tag but some clipboard systems include them in the pasted HTML.
    // A DOMParser that follows the HTML spec will normally hoist the <meta> tags to the <head> tag but SimpleDom does not.
    // So just take care of the <meta> tags manually.
    descendants(doc.documentElement, (element: Element) => {
      if (element.nodeName?.toLowerCase() === 'meta') {
        element.remove();
      }
    }, { reverse: true });

    const pageBreakNormalizer = new HtmlPageBreakNormalizer();
    pageBreakNormalizer.normalize(doc, options?.pageBreakReplacement);

    const emptyNodeNormalizer = new HtmlEmptyNodeNormalizer();
    emptyNodeNormalizer.normalize(doc, options?.emptyNodeWhitelist, options?.emptyNodeWhitelistMatcher);

    const styleNormalizer = new HtmlStyleNormalizer();
    styleNormalizer.normalize(doc, options?.styleWhitelist);

    const attributeNormalizer = new HtmlAttributeNormalizer();
    attributeNormalizer.normalize(doc, options?.attributesWhitelist);

    return doc;
  }
}
