import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { Team } from '@portal-core/teams/models/team.model';
import { TeamsService } from '@portal-core/teams/services/teams.service';
import { LoadingState } from '@portal-core/util/loading-state';

@Component({
  selector: 'mc-team-deletion-form',
  templateUrl: './team-deletion-form.component.html',
  styleUrls: ['./team-deletion-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamDeletionFormComponent {
  @Input() team: Team;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  savingState: LoadingState<string> = new LoadingState<string>();

  constructor(private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private teamsService: TeamsService) { }

  onSubmit() {
    this.savingState.update(true);

    this.teamsService.deleteTeams$(this.team.LicenseId, [this.team.Id])
      .subscribe(() => {
        this.savingState.update(false);

        this.snackBar.open('The team has been deleted.', 'OK', { duration: 2500 });
        this.deleted.emit();
      }, error => {
        this.savingState.update(false, 'Unable to delete the team.', this.errorService.getErrorMessages(error));
      }
      );
  }

  onCancelClicked() {
    this.cancel.emit();
  }
}
