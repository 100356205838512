import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { WebhookChannel } from '@portal-core/slack/models/webhook-channel.model';
import { WebhookSubscriber } from '@portal-core/slack/models/webhook-subscriber.model';
import { WebhookSubscription } from '@portal-core/slack/models/webhook-subscription.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlackWebhooksApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  // Subscribers
  @SharePendingRequest()
  getSubscribers$(licenseId: number): Observable<WebhookSubscriber[]> {
    return this.http.get<WebhookSubscriber[]>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/License/${licenseId}`);
  }

  addSubscriber$(newWebhookSubscriber: WebhookSubscriber): Observable<WebhookSubscriber> {
    return this.http.post<WebhookSubscriber>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers`, newWebhookSubscriber);
  }

  updateSubscriber$(webhookSubscriber: WebhookSubscriber): Observable<WebhookSubscriber> {
    return this.http.put<WebhookSubscriber>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/${webhookSubscriber.Id}`, webhookSubscriber);
  }

  deleteSubscriber$(subscriberId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/${subscriberId}`);
  }

  // Webhook channels
  @SharePendingRequest()
  getChannels$(licenseId: number): Observable<WebhookChannel[]> {
    return this.http.get<WebhookChannel[]>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/WebhookChannels/${licenseId}`);
  }

  confirmAuthChannel$(licenseId: number, code: string): Observable<WebhookChannel | any> {
    return this.http.put<WebhookChannel>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/ConfirmAuthChannel/${licenseId}`, { Code: code });
  }

  deleteChannel$(channelId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/DeleteChannel/${channelId}`);
  }

  // Subscribers' Subscriptions
  @SharePendingRequest()
  getSubscriptions$(subscriberId: number): Observable<WebhookSubscription[]> {
    return this.http.get<WebhookSubscription[]>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/${subscriberId}/Subscriptions`);
  }

  addSubscription$(subscriberId: number, auditCategoryId: number): Observable<WebhookSubscription> {
    return this.http.post<WebhookSubscription>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/${subscriberId}/Subscription/${auditCategoryId}`, null);
  }

  // deleteSubscription$(subscriberId: number, auditCategoryId: number): Observable<MadCloudResult> {
  //   return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/${subscriberId}/Subscription/${auditCategoryId}`);
  // }

  clearAllSubscriptions$(subscriberId: number): Observable<MadCloudResult> {
    return this.http.delete<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/WebhookSubscribers/ClearSubscriptions/${subscriberId}`);
  }
}
