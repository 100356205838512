import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { VariablePickerInputModule } from '@portal-core/project-files/variables/components/variable-picker-input/variable-picker-input.module';
import { VariableSetsListModule } from '@portal-core/project-files/variables/components/variable-sets-list/variable-sets-list.module';
import { VariablesGridModule } from '@portal-core/project-files/variables/components/variables-grid/variables-grid.module';
import { DialogAutoFocusModule } from '@portal-core/ui/dialog/directives/dialog-auto-focus/dialog-auto-focus.module';
import { InsertVariableDialogComponent } from './insert-variable-dialog.component';

@NgModule({
  declarations: [InsertVariableDialogComponent],
  imports: [
    CommonModule,
    VariablesGridModule,
    VariableSetsListModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    VariablePickerInputModule,
    LoaderModule,
    ErrorsExpansionPanelModule,
    MatButtonModule,
    DialogAutoFocusModule
  ],
  exports: [
    InsertVariableDialogComponent
  ]
})
export class InsertVariableDialogModule { }
