import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { DataStreamService } from '@portal-core/data-stream/services/data-stream.service';

export const dataStreamInitResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot): boolean => {
  const dataStreamService = inject(DataStreamService);

  dataStreamService.init();

  return true;
}
