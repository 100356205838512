<div class="mc-avatar-tooltip-container" [matTooltip]="reviewPackage?.Status | mcReviewPackageAvatarTooltip : reviewPackage?.Name" [matTooltipDisabled]="disabledTooltip || !reviewPackage">
  <button *ngIf="showAvatar" type="button" class="mc-avatar-container" [ngClass]="reviewPackage | mcReviewPackageColorClass : 'bg'" [disabled]="isImageDisabled()" (click)="onAvatarClicked()">
    <mat-icon class="mc-avatar-icon mc-avatar-icon-bordered" fontSet="project-titan" fontIcon="icon-review"></mat-icon>
  </button>

  <ng-container *ngIf="showName">
    <ng-container *ngIf="nameLinksToPage && !isNameDisabled() && reviewPackage | mcReviewPackageLink as reviewPackageLink; else nameAsButton">
      <a href="#" class="mc-avatar-name" [routerLink]="reviewPackageLink">{{ reviewPackage?.Name }}<span *ngIf="showId" class="mc-text-muted"> ({{ reviewPackage?.Id }})</span></a>
    </ng-container>
    <ng-template #nameAsButton>
      <mc-inline-button class="mc-avatar-name" [disabled]="isNameDisabled()" (clicked)="onAvatarClicked()">{{ reviewPackage?.Name }}<span *ngIf="showId" class="mc-text-muted"> ({{ reviewPackage?.Id }})</span></mc-inline-button>
    </ng-template>
  </ng-container>
</div>