<div class="mc-system-message-card" [ngClass]="getMessageColorClassName()" (click)="onCardClick()">
  <button class="mc-system-message-card-close-button mc-button-small" mat-icon-button color="secondary-invert" mcStopPropagation="click" (click)="onCloseClick()">
    <mat-icon fontSet="project-titan" fontIcon="icon-close"></mat-icon>
  </button>
  <div class="mc-system-message-card-icon-container">
    <mat-icon class="mc-self-centered-icon text-xxxx-large" fontSet="project-titan" [fontIcon]="icon"></mat-icon>
  </div>
  <div class="mc-system-message-card-content-container">
    <h4 class="mc-system-message-card-title">{{title}}</h4>
    <p class="mc-system-message-card-content">{{message}}</p>
  </div>
</div>
