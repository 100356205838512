// Commented out proxies are page layouts and V2
export enum ProjectFileProxyType {
  Body = 'Body',
  Breadcrumbs = 'Breadcrumbs',
  TopicToolbar = 'TopicToolbar',
  // TOC = 'TOC',
  MiniTOC = 'MiniTOC',
  // Index = 'Index',
  Glossary = 'Glossary',
  // Endnotes = 'Endnotes',
  // Concepts = 'Concepts',
  // ListOf = 'ListOf',
  // Relationships = 'Relationships',
  SearchBar = 'SearchBar',
  Menu = 'Menu',
  // SearchResults = 'SearchResults',
  CentralAccount = 'CentralAccount',
  ELearningToolbar = 'ELearningToolbar'
}
