import { NodeType } from '@common/html/enums/node-type.enum';
import { parseFromString } from '@common/html/simple-dom/parse';
import { ValidationOptions } from '@common/html/types/serialization-options.type';
import { encodeXML } from 'entities';

export interface AttributeNameValue {
  name: string;
  value: string;
}

export interface XmlSerializerOptions {
  deep?: boolean;
  encodeAttr?: (attr: string) => string;
  transformAttr?: (name: string, value: string, tagName: string) => AttributeNameValue;
  transformTagName?: (name: string) => string;
  voidTags?: string[];
}

// const nbspNameRegex = new RegExp(nbspEntityName, 'g');

function encodeText(text: string, doc: Document): string {
  return encodeXML(text);
  // If the text is an nbsp then make sure to encode it as the entity number
  // if (text === domhelpers.nbsp) {
  //     return domhelpers.nbspEntityNumber;
  // }

  // const div = doc.createElement('div');
  // div.textContent = text;
  // // Encode nbsp as an entity number, not as an entity name
  // return (div.innerHTML || '').replace(nbspNameRegex, nbspEntityNumber);
}

function nodeToText(node: Element, options: XmlSerializerOptions): string {
  let text = '';

  if (node) {
    // Build the node string
    if (node.nodeType === NodeType.TEXT_NODE) {
      const parentNode = node.parentNode as Element;
      if (parentNode.nodeName && parentNode.nodeName.toLowerCase() === 'script') {
        text += node.nodeValue;
      } else {
        text += encodeText(node.nodeValue, node.ownerDocument);
      }
    } else if (node.nodeType === NodeType.CDATA_SECTION_NODE) {
      text += `<![CDATA[${encodeText(node.nodeValue, node.ownerDocument)}]]>`;
    } else if (node.nodeType === NodeType.COMMENT_NODE) {
      text += '<!--' + node.nodeValue + '-->';
    } else {
      const nodeName = options.transformTagName(node.nodeName);
      const nodeNameLowerCase = nodeName.toLowerCase();
      const isVoid = Array.isArray(options.voidTags) && options.voidTags.includes(nodeNameLowerCase);

      // Open the tag
      text += '<' + nodeName;

      // Add the attributes
      for (let i = 0, attrCount = node.attributes.length; i < attrCount; i += 1) {
        const attr = options.transformAttr(node.attributes[i].nodeName, node.attributes[i].nodeValue, nodeName);
        if (attr) {
          text += ' ' + attr.name + '="' + options.encodeAttr(attr.value) + '"';
        }
      }

      if (isVoid) {
        // Void tags are self-closing
        text += ' />';
      } else {
        text += '>';

        // Process the children
        if (options.deep) {
          let childNodes: NodeListOf<ChildNode>;

          // The template tag's children are in a DocumentFragment in the content property
          if (nodeNameLowerCase === 'template' && (node as HTMLTemplateElement).content) {
            childNodes = (node as HTMLTemplateElement).content?.childNodes;
          } else {
            childNodes = node.childNodes;
          }

          if (childNodes) {
            for (let i = 0, childCount = childNodes.length; i < childCount; i += 1) {
              text += nodeToText(childNodes[i] as Element, options);
            }
          }
        }

        // Close the tag
        text += '</' + nodeName + '>';
      }
    }
  }

  return text;
}

export const BOMChar = 0xFEFF;
export const BOMCharCode = String.fromCharCode(BOMChar);

// Returns true if the string has a leading byte order mark
export function hasBOM(str: string): boolean {
  return typeof str === 'string' && str.charCodeAt(0) === BOMChar;
}

// Returns the string without a leading byte order mark
export function stripBOM(str: string): string {
  return hasBOM(str) ? str.slice(1) : str;
}

// Define void tags (self-closing)
export const voidTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'frame', 'hr', 'img', 'input', 'keygen', 'link', 'menuitem', 'meta', 'param', 'source', 'track', 'wbr'];
export const voidTagSet = new Set(voidTags);

// Define the transformers used in serializeToString by default
export const xmlTransformers = {
  transformTagName: function (name: string): string {
    // If the name appears to be for an html tag (aka if the tag name is not namespaced) or an mml tag
    if (name.indexOf(':') === -1 || name.toLocaleLowerCase().startsWith('mml:')) {
      // Then make it lowercase
      return name.toLowerCase();
    }

    return name;
  },

  transformAttr: function (name: string, value: string, tagName: string): AttributeNameValue {
    return {
      name,
      value
    };
  },

  encodeAttr: function (attr: string): string {
    return attr
      .replace(/&/g, '&amp;') // Replace the & first
      // .replace(/'/g, '&apos;') // Not necessary because the attribute quotes are always double quotes
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }
};

// Converts a dom node into an xml string
export function serializeElementToString(node: Element, options?: XmlSerializerOptions): string {
  // Set the default options
  options = Object.assign({}, {
    deep: true,
    voidTags,
    transformTagName: xmlTransformers.transformTagName,
    transformAttr: xmlTransformers.transformAttr,
    encodeAttr: xmlTransformers.encodeAttr
  }, options);

  return nodeToText(node, options);
}

export function serializeElementsToString(nodes: Element[], options?: XmlSerializerOptions): string {
  return nodes.map(node => serializeElementToString(node, options)).join('');
}

// Validates xml code. Returns an error object if the code is invalid. Otherwise returns null.
export function validateXml(code: string, options?: ValidationOptions): { doc: Document | null, error: Error | null } {
  let doc: Document | null = null;
  let error: Error | string = null;

  // Strip out the BOM if it exists
  if (code) {
    code = stripBOM(code);
  }

  if (options?.validateXmlDeclaration && !code.startsWith('<?xml ')) {
    return {
      doc: null,
      error: new Error('The document must begin with an xml declaration. Please add <?xml version=\"1.0\" encoding=\"utf-8\"?> to the beginning of the document.')
    };
  }

  // Validate the code
  if (code) {
    // IE and Edge throw an error when there is a parse error so catch the exception
    try {
      doc = new DOMParser().parseFromString(code, 'text/xml');

      const parserError = doc.querySelector('parsererror');

      if (parserError) {
        if (parserError.querySelector('sourcetext')) {
          error = parserError.textContent.trim();
        } else if (parserError.querySelector('div')) {
          error = parserError.querySelector('div').textContent.trim();
        } else {
          error = parserError.textContent;
        }
      } else if (options?.validateXmlDocument) {
        error = options.validateXmlDocument(doc);
      }
    } catch (ex) {
      error = ex;
    }
  }

  if (typeof error === 'string') {
    // this error is not descriptive enough so we making our own.
    if (error.toLowerCase().includes('document is empty')) {
      error = new Error('Please add xml markup to this file');
    } else {
      error = new Error(error.replace(/[\r\n]+/g, ' '));
    }
  }

  return {
    doc,
    error
  };
}
