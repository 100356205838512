import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@portal-core/auth/services/api.service';
import { OpenAICompletion } from '@portal-core/project-files/models/open-ai/open-ai-completion.model';
import { OpenAIMessage } from '@portal-core/project-files/models/open-ai/open-ai-message.model';
import { ProjectFile } from '@portal-core/project-files/models/project-file.model';
import { SharePendingRequest } from '@portal-core/util/observables.decorators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectFilesApiService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  @SharePendingRequest()
  getProjectFileByPath$(projectId: number, filePath: string, branchName: string): Observable<ProjectFile> {
    return this.http.get<ProjectFile>(`${this.apiService.centralApiBaseUri}/api/projectFiles/getFile/${projectId}?filePath=${encodeURIComponent(filePath)}&branchName=${encodeURIComponent(branchName)}`);
  }

  @SharePendingRequest()
  getFirstNodeTextByPath$(projectId: number, filePath: string, branchName: string): Observable<string> {
    return this.http.get<string>(`${this.apiService.centralApiBaseUri}/api/projectFiles/getFirstNodeTextByPath/${projectId}?filePath=${encodeURIComponent(filePath)}&branchName=${encodeURIComponent(branchName)} `)
  }

  getProjectFileRawPath(projectId: number, branchName: string, filePath: string): string {
    return `${this.apiService.centralContentBaseUri}/${projectId}/${branchName}/${filePath}`;
  }

  @SharePendingRequest()
  getProjectFileTree$(projectId: number, branchName: string, treePath: string = '', includeFiles: boolean = false, fileFilter: string = '.*'): Observable<ProjectFile[]> {
    return this.http.get<ProjectFile[]>(`${this.apiService.centralApiBaseUri}/api/projects/${projectId}/getProjectFileTree?path=${encodeURIComponent(treePath)}&branchName=${encodeURIComponent(branchName)}&includeFiles=${includeFiles}&fileFilter=${fileFilter}`);
  }

  @SharePendingRequest()
  getOpenAIResponse$(projectId: number, messages: OpenAIMessage[]): Observable<OpenAICompletion> {
    return this.http.post<OpenAICompletion>(`${this.apiService.centralApiBaseUri}/api/projects/AI/${projectId}/`, { Messages: messages });
  }
}
