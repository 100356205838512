import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProjectVariableDefinition } from '@portal-core/project-files/variables/models/project-variable-definition.model';
import { ProjectVariableSet } from '@portal-core/project-files/variables/models/project-variable-set.model';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { LoadingState } from '@portal-core/util/loading-state';
import { Observable } from 'rxjs';

export interface InsertVariableDialogData {
  projectVariableSets$: Observable<ProjectVariableSet[]>;
  setName?: string;
  variableName?: string;
  hiddenSystemVariables?: string[];
}

@Component({
  selector: 'mc-insert-variable-dialog',
  templateUrl: './insert-variable-dialog.component.html',
  styleUrls: ['./insert-variable-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class InsertVariableDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '110rem',
    height: '60rem',
    restoreFocus: false
  };

  /** The selected variable set. */
  activeVariableSet: ProjectVariableSet;
  /** The selected variable. */
  activeVariable: ProjectVariableDefinition | null;
  /** The state of loading the variable sets. */
  loadingState: LoadingState<string> = new LoadingState<string>();
  /** The variable sets. */
  projectVariableSets: ProjectVariableSet[];
  /** The name of the set to initially select. */
  setName?: string;
  /** The name of the variable to initially select. */
  variableName?: string;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: InsertVariableDialogData,
    protected dialogRef: MatDialogRef<InsertVariableDialogComponent>
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setName = this.data.setName;
    this.variableName = this.data.variableName;

  }

  onInsertClicked() {
    if (this.activeVariable) {
      this.closeDialog(this.activeVariable.LongName);
    }
  }

  onVariableSelected(variableDefinition: ProjectVariableDefinition | null) {
    this.activeVariable = variableDefinition;
  }

  onVariableSetSelected(variableSet: ProjectVariableSet) {
    this.activeVariableSet = variableSet;
  }

}
