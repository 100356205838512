import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DataStreamService } from '@portal-core/data-stream/services/data-stream.service';
import { MyNotificationPage } from '@portal-core/notifications/models/my-notification-page.model';
import { Notification } from '@portal-core/notifications/models/notification.model';
import { NotificationsService } from '@portal-core/notifications/services/notifications.service';
import { NotificationList } from '@portal-core/notifications/util/notification-list';
import { filter } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'mc-my-notification-list',
  templateUrl: './my-notification-list.component.html',
  styleUrls: ['./my-notification-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyNotificationListComponent extends NotificationList implements OnChanges {
  @HostBinding('class.mc-notification-list') notificationListClass = true;
  @Input() licenseId: number;

  constructor(protected notificationsService: NotificationsService, protected dataStreamService: DataStreamService, cdr: ChangeDetectorRef) {
    super(notificationsService, dataStreamService, cdr);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.licenseId) {
      this.onConfigurationChanged();
    }
  }

  protected isConfigured(): boolean {
    return typeof this.licenseId === 'number';
  }

  protected fetchNotifications$(pageSize: number, pageIndex: number): Observable<MyNotificationPage> {
    return this.notificationsService.getMyNotifications$(this.licenseId, pageSize, pageIndex);
  }

  protected filterNotifications(notifications: { [id: string]: Notification }): Notification[] {
    return filter(notifications, (notification: Notification) => notification.IsMyNotification);
  }
}
