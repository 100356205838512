<ng-container [ngSwitch]="tag">
  <ng-container *ngSwitchCase="'BuildFailed'">
    <span class="mc-text-build-fail text-medium-weight">failed</span>
  </ng-container>

  <ng-container *ngSwitchCase="'BuildSuccess'">
    <span class="mc-text-build-success text-medium-weight">completed successfully</span>
  </ng-container>

  <ng-container *ngSwitchCase="'Checklist'">
    <a [routerLink]="notification?.Checklist | mcProjectChecklistLink">{{ notification?.Checklist?.Name }}</a>
    checklist
  </ng-container>

  <ng-container *ngSwitchCase="'CompanyName'">
    <span class="mc-text-build-success text-medium-weight">{{ notification?.License?.CompanyName }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'EndDate'">
    {{ notification?.EndDate | date: 'longDate' }}
  </ng-container>

  <ng-container *ngSwitchCase="'FailedGlyph'">
    <mat-icon fontSet="project-titan" fontIcon="icon-close" color="warn"></mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'InvokerUser'">
    <mc-license-user-avatar [licenseUser]="notification?.InvokerUser?.Id | mcLicenseUserForLicenseAsync: notification?.LicenseId | async" [showAvatar]="false"></mc-license-user-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'License'">
    <mc-license-avatar [license]="notification?.LicenseId | mcLicenseAsync | async" [showAvatar]="false"></mc-license-avatar>
    license
  </ng-container>

  <ng-container *ngSwitchCase="'Project'">
    <mc-project-avatar *ngIf="notification?.ProjectId | mcProjectAsync | async as project" [project]="project" [showAvatar]="false"></mc-project-avatar>
    project
  </ng-container>

  <ng-container *ngSwitchCase="'Project_NoSuffix'">
    <mc-project-avatar *ngIf="notification?.ProjectId | mcProjectAsync | async as project" [project]="project" [showAvatar]="false"></mc-project-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'Review'">
    <mc-review-file-avatar [nameLinksToPage]="true" [reviewFile]="notification?.ReviewFileId | mcReviewFileAsync | async" [showAvatar]="false"></mc-review-file-avatar>
    review file
  </ng-container>

  <ng-container *ngSwitchCase="'Reviews'">
    <a [routerLink]="notification?.ReviewPackage | mcReviewPackageLink">reviews</a>
  </ng-container>

  <ng-container *ngSwitchCase="'ReviewGrid'">
    <mc-review-file-avatar [reviewFile]="notification?.ReviewFileId | mcReviewFileAsync | async" [showAvatar]="false"></mc-review-file-avatar>
    review
  </ng-container>

  <ng-container *ngSwitchCase="'ReviewPackage'">
    <a [routerLink]="notification?.ReviewPackage | mcReviewPackageLink">{{ notification?.ReviewPackage?.Name }}</a>
  </ng-container>

  <ng-container *ngSwitchCase="'ScanFailed'">
    <span class="mc-text-build-fail text-medium-weight">failed</span>
  </ng-container>

  <ng-container *ngSwitchCase="'ScanSuccess'">
    <span class="mc-text-build-success text-medium-weight">completed successfully</span>
  </ng-container>

  <ng-container *ngSwitchCase="'Site'">
    <mc-site-avatar [showAvatar]="false" [site]="notification?.SiteId | mcSiteAsync | async"></mc-site-avatar>
    site
  </ng-container>

  <ng-container *ngSwitchCase="'SiteUrl'">
    <mc-site-avatar [showAvatar]="false" [site]="notification?.SiteId | mcSiteAsync | async"></mc-site-avatar>
    site
  </ng-container>

  <ng-container *ngSwitchCase="'Site_NoLink'">
    <mc-site-avatar [disabled]="true" [showAvatar]="false" [site]="notification?.SiteId | mcSiteAsync | async"></mc-site-avatar>
    site
  </ng-container>

  <ng-container *ngSwitchCase="'TranslationPackage'">
    <mc-translation-package-avatar [disabled]="notification?.TranslationPackage?.IsDeleted" [translationPackage]="notification?.TranslationPackageId | mcTranslationPackageAsync | async" [showAvatar]="false"></mc-translation-package-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'TranslationPackageLanguageBranch'">
    <mc-translation-package-language-branch-avatar [disabled]="notification?.TranslationPackageLanguageBranch?.IsDeleted" [translationPackageLanguageBranch]="notification?.TranslationPackageLanguageBranchId | mcTranslationPackageLanguageBranchAsync | async" [showAvatar]="false"></mc-translation-package-language-branch-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'StartDate'">
    {{ notification?.StartDate | date: 'longDate' }}
  </ng-container>

  <ng-container *ngSwitchCase="'SuccessGlyph'">
    <mat-icon fontSet="project-titan" fontIcon="icon-checkmark" color="notice"></mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'Support'">
    Please contact
    <a href="https://www.madcapsoftware.com/support/contact-options.aspx">MadCap Software Technical Support</a>
    for assistance.
  </ng-container>

  <ng-container *ngSwitchCase="'Target'">
    <mc-target-avatar [targetPath]="notification?.TargetPath" [branchName]="notification?.BranchName" [project]="notification?.ProjectId | mcProjectAsync: true | async" [showAvatar]="false"></mc-target-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'Task'">
    <mc-inline-button class="mc-avatar-name" [disabled]="notification?.Task?.IsDeleted" (clicked)="openTaskDialog(notification?.Task?.Id)">{{ notification?.Task?.Title }}</mc-inline-button>
    task
  </ng-container>

  <ng-container *ngSwitchCase="'Task_NoSuffix'">
    <mc-inline-button class="mc-avatar-name" [disabled]="notification?.Task?.IsDeleted" (clicked)="openTaskDialog(notification?.Task?.Id)">{{ notification?.Task?.Title }}</mc-inline-button>
  </ng-container>

  <ng-container *ngSwitchCase="'TaskBoard'">
    <mc-task-board-avatar [showAvatar]="false" [taskBoard]="notification?.TaskBoardId | mcTaskBoardAsync | async"></mc-task-board-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'Team'">
    <mc-team-avatar [team]="notification?.TeamId | mcTeamAsync | async" [showAvatar]="false"></mc-team-avatar>
  </ng-container>

  <ng-container *ngSwitchCase="'TeamSitesUrls'">
    <ng-container *ngIf="notification?.TeamId | mcTeamAsync | async as team">
      {{ team.TeamSites?.length }} sites
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'Time'">
    {{ notification?.Time | date: 'mediumTime' }}
  </ng-container>

  <ng-container *ngSwitchCase="'User'">
    <mc-license-user-avatar [licenseUser]="notification?.User?.Id | mcLicenseUserForLicenseAsync: notification?.LicenseId | async" [showAvatar]="false"></mc-license-user-avatar>
  </ng-container>

  <ng-container *ngSwitchDefault>
    [{{ tag }}]
  </ng-container>
</ng-container>