import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@portal-core/util/window.provider';

/**
 * Removes old auth data from local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthLocalStorageCleanupService {
  constructor(@Inject(WINDOW) private window: Window) {
    this.window.localStorage.removeItem('authState');
    this.window.localStorage.removeItem('authState.auth');
    this.window.localStorage.removeItem('authState.lastActivatedRoute');
  }
}
