import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';
import { ClipboardService } from '@portal-core/general/services/clipboard.service';

export interface LogDialogData {
  message: string;
  title?: string;
}

@Component({
  selector: 'mc-log-dialog',
  templateUrl: './log-dialog.component.html',
  styleUrls: ['./log-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogDialogComponent extends DialogBase implements OnInit {
  /** The default config for the dialog. */
  static DialogConfig: MatDialogConfig = {
    width: '80rem'
  };

  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LogDialogData,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<LogDialogComponent>,
    private snackBar: MatSnackBar,
    private clipboardService: ClipboardService
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = this.data?.title ?? 'Message';
  }

  copyMessage() {
    const isCopied = this.clipboardService.copy(this.data.message);
    let message: string;

    if (isCopied) {
      message = 'Copied to clipboard';
    } else {
      message = 'Copy failed';
    }

    this.snackBar.open(message, null, {
      duration: 2500,
    });
  }
}
