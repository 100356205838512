import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '@portal-core/sites/models/site.model';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'mcSiteAsync'
})
export class SiteAsyncPipe implements PipeTransform {
  constructor(private sitesService: SitesService) { }

  transform(siteId: number | null | undefined, allowApiRequest: boolean = false): Observable<Site | null> {
    if (!siteId) {
      return of(null);
    }

    return this.sitesService.getItemById$(siteId, {
      allowApiRequest
    });
  }
}
