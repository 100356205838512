import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(@Inject(DOCUMENT) private doc: Document) { }

  inject(src: string, async: boolean = false) {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    s.async = async;

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
}
