export enum CollabSessionError {
  BadRequest = 'Bad Request',
  DocAccessDenied = 'Doc Access Denied',
  DocLoadFailed = 'Doc Load Failed',
  DocReadOnly = 'Doc Read-Only',
  DocRevertFailed = 'Doc Revert Failed',
  DocSizeTooLarge = 'Doc Size Too Large',
  DocUpdatesLoadFailed = 'Doc Updates Load Failed',
  DocUpdateFailed = 'Doc Update Failed',
  DocVersionInvalid = 'Doc Version Invalid',
  MetaDataNotReady = 'Meta Data Not Ready',
  MongoDBDocumentSizeTooLarge = 'MongoDB Document Size Too Large',
  ReviewFileLoadFailed = 'Review File Load Failed',
  SchemaVersionIncompatible = 'Schema Version Incompatible',
  ThrottledSendChangesFailed = 'Throttled Send Changes Failed',
  UnableToReachServer = 'Unable To Reach Server',
  VersionMismatch = 'Version Mismatch',
  VersionTooOld = 'Version Too Old',
  UnexpectedError = 'Unexpected Error'
}
