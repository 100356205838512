import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AuthInstanceCodeCookieName, AuthRootTokenCookieName, AuthTokenCookieName, AuthTokenTTLDays } from '@portal-core/auth/constants/auth-cookies.constant';
import { AuthDataService, Login, Logout } from '@portal-core/auth/services/auth-data.service';
import { AuthUrlService } from '@portal-core/auth/services/auth-url.service';
import { CookieService } from 'ngx-cookie-service';

/**
 * Handles storing the user's auth token in cookies.
 * Listens to Login and Logout actions from the auth data store to update the 'BearerToken' cookie.
 * Loads the `BearerToken` value into the auth data store on load.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthCookieEffect {
  constructor(private actions$: Actions, private cookieService: CookieService, private authUrlService: AuthUrlService, private authDataService: AuthDataService) {
    // Load the cookie values into the auth data store
    this.authDataService.setAccessToken$(this.cookieService.get(AuthTokenCookieName));
    this.authDataService.setInstanceCode$(this.cookieService.get(AuthInstanceCodeCookieName));

    this.actions$.pipe(
      ofActionSuccessful(Logout)
    ).subscribe(() => {
      // Logging out of a license also logs the user out of the root domain
      this.cookieService.delete(AuthTokenCookieName, '/');
      this.cookieService.delete(AuthInstanceCodeCookieName, '/');
      this.cookieService.delete(AuthRootTokenCookieName, '/', `.${this.authUrlService.getRootDomainForCookies()}`);
    });

    this.actions$.pipe(
      ofActionSuccessful(Login)
    ).subscribe((action: Login) => {
      if (action.payload?.auth) {
        this.cookieService.set(AuthTokenCookieName, action.payload.auth.access_token, AuthTokenTTLDays, '/');
        this.cookieService.set(AuthInstanceCodeCookieName, action.payload.instanceCode, AuthTokenTTLDays, '/');
      }
    });
  }
}
