import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListOptionComponent } from './list-option.component';

@NgModule({
  declarations: [
    ListOptionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ListOptionComponent
  ]
})
export class ListOptionModule { }
