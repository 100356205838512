import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapFootnoteSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapfootnote: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      atom: true,
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:footnote'
      }],
      toDOM() {
        return ['span', { class: 'mc-madcap-footnote' }, 'Footnote'];
      },
      tagName: 'MadCap:footnote'
    }
  };
}
