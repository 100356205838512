import { Injectable } from '@angular/core';
import { State, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { ProjectTarget } from '@portal-core/project-targets/models/project-target.model';

@CollectionStateSelectors({
  stateType: ProjectTargetsState
})
@State<ProjectTargetsState>({
  name: ProjectTargetsState.source
})
@Injectable()
export class ProjectTargetsState extends CollectionStateBase {
  static source = 'ProjectTargets';
  getSource(): string {
    return ProjectTargetsState.source;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectTargetsDataService extends CollectionDataServiceBase<ProjectTarget> {
  constructor(protected store: Store) {
    super(store, ProjectTargetsState);
  }
}
