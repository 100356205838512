import { Pipe, PipeTransform } from '@angular/core';
import { ProjectChecklist } from '@portal-core/project-checklists/models/project-checklist.model';

/**
 * Returns the routerLink commands for a checklist.
 * Usage:
 *   checklist | mcProjectChecklistLink
 */
@Pipe({
  name: 'mcProjectChecklistLink'
})
export class ProjectChecklistLinkPipe implements PipeTransform {
  transform(checklist: ProjectChecklist): any[] {
    let commands: any[] = null;

    if (checklist) {
      commands = ['/', checklist.LicenseId, 'projects', checklist.ProjectId, 'checklists', checklist.Id];
    }

    return commands;
  }
}
