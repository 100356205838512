import { FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, ViewportRuler } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ElementRef } from '@angular/core';
import { ElementOverlayContainer } from '@portal-core/ui/overlay/util/element-overlay-container';

/** Equivalent of `ClientRect` without some of the properties we don't care about. */
type Dimensions = Omit<ClientRect, 'x' | 'y' | 'toJSON'>;

/**
 * A strategy for positioning overlays. Using this strategy, an overlay is given an implicit position relative some origin element.
 * The relative position is defined in terms of a point on the origin element that is connected to a point on the overlay element.
 * For example, a basic dropdown is connecting the bottom-left corner of the origin to the top-left corner of the overlay.
 *
 * Extends FlexibleConnectedPositionStrategy to allow for positioning relative to an element instead of the viewport.
 * Overrides _getOriginRect and _getNarrowedViewportRect to use the overlay element instead of the viewport.
 * If not using flexible positioning, then _getExactOverlayX and _getExactOverlayY will also need to be overridden.
 */
export class ElementFlexibleConnectedPositionStrategy extends FlexibleConnectedPositionStrategy {
  constructor(
    connectedTo: FlexibleConnectedPositionStrategyOrigin,
    viewportRuler: ViewportRuler,
    document: Document,
    platform: Platform,
    overlayContainer: ElementOverlayContainer,
  ) {
    super(connectedTo, viewportRuler, document, platform, overlayContainer);
  }
}

/**
 * Override _getOriginRect to use the overlay element instead of the viewport.
 * Cast as any and assign on the prototype to get around private access.
 */
(ElementFlexibleConnectedPositionStrategy as any).prototype._getOriginRect = function (): Dimensions {
  let origin = this._origin;

  if (origin instanceof ElementRef) {
    origin = origin.nativeElement;
  }

  // Check for Element so SVG elements are also supported.
  if (origin instanceof Element) {
    const bodyElementRect = document.body.getBoundingClientRect();
    const overlayContainerElementRect = this._overlayContainer.getContainerElement().getBoundingClientRect();

    const offset = {
      left: overlayContainerElementRect.left - bodyElementRect.left + document.body.scrollLeft,
      top: overlayContainerElementRect.top - bodyElementRect.top + document.body.scrollTop
    };

    const rect = origin.getBoundingClientRect();

    return new DOMRect(rect.left - offset.left, rect.top - offset.top, rect.width, rect.height);
  }

  const width = origin.width || 0;
  const height = origin.height || 0;

  // If the origin is a point, return a client rect as if it was a 0x0 element at the point.
  return {
    top: origin.y,
    bottom: origin.y + height,
    left: origin.x,
    right: origin.x + width,
    height,
    width,
  };
};

/**
 * Override _getNarrowedViewportRect to use the overlay element instead of the viewport.
 * Cast as any and assign on the prototype to get around private access.
 */
(ElementFlexibleConnectedPositionStrategy as any).prototype._getNarrowedViewportRect = function (): Dimensions {
  const containerElement = this._overlayContainer.getContainerElement();
  // We recalculate the viewport rect here ourselves, rather than using the ViewportRuler,
  // because we want to use the `clientWidth` and `clientHeight` as the base. The difference
  // being that the client properties don't include the scrollbar, as opposed to `innerWidth`
  // and `innerHeight` that do. This is necessary, because the overlay container uses
  // 100% `width` and `height` which don't include the scrollbar either.
  const width = containerElement!.clientWidth;
  const height = containerElement!.clientHeight;
  const scrollPosition = this._viewportRuler.getViewportScrollPosition();

  return {
    top: scrollPosition.top + this._viewportMargin,
    left: scrollPosition.left + this._viewportMargin,
    right: scrollPosition.left + width - this._viewportMargin,
    bottom: scrollPosition.top + height - this._viewportMargin,
    width: width - 2 * this._viewportMargin,
    height: height - 2 * this._viewportMargin,
  };
};
