import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ListModule } from '@portal-core/ui/list/list.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamsSelectListComponent } from './teams-select-list.component';

@NgModule({
  declarations: [
    TeamsSelectListComponent
  ],
  imports: [
    CommonModule,
    ListModule,
    MatListModule,
    TeamAvatarModule
  ],
  exports: [
    TeamsSelectListComponent
  ]
})
export class TeamsSelectListModule { }
