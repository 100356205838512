import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule, } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { TeamNotificationListModule } from '@portal-core/notifications/components/team-notification-list/team-notification-list.module';
import { PermissionsFormModule } from '@portal-core/permissions/components/permissions-form/permissions-form.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { TeamAccessFormModule } from '@portal-core/teams/components/team-access-form/team-access-form.module';
import { TeamAvatarModule } from '@portal-core/teams/components/team-avatar/team-avatar.module';
import { TeamDeletionFormModule } from '@portal-core/teams/components/team-deletion-form/team-deletion-form.module';
import { TeamSettingsFormModule } from '@portal-core/teams/components/team-settings-form/team-settings-form.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { TeamProfileComponent } from './team-profile.component';

@NgModule({
  declarations: [
    TeamProfileComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    PermissionsFormModule,
    PreventDefaultModule,
    ProfileTabModule,
    SwitchBoardModule,
    TeamAvatarModule,
    TeamAccessFormModule,
    TeamDeletionFormModule,
    TeamNotificationListModule,
    TeamSettingsFormModule
  ],
  exports: [
    TeamProfileComponent
  ]
})
export class TeamProfileModule { }
