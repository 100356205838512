import { SchemaPlugin } from '@common/prosemirror/model/schema-plugin';
import { NodeSpec } from 'prosemirror-model';

export class MadCapResponsiveContentSchemaPlugin extends SchemaPlugin {
  nodes: Dictionary<NodeSpec> = {
    madcapresponsivecontenttext: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:responsiveContentText',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-responsive-content-text' }, 0]; },
      tagName: 'MadCap:responsiveContentText'
    },
    madcapresponsivecontentitem: {
      group: 'inline',
      inline: true,
      content: 'inline*',
      definingForContent: true,
      parseDOM: [{
        tag: 'MadCap\\:responsiveContentItem',
        preserveWhitespace: true
      }],
      toDOM() { return ['span', { class: 'mc-madcap-responsive-content-item' }, 0]; },
      tagName: 'MadCap:responsiveContentItem'
    },
  };
}
