import { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 * Works just like switchMap but returns a tuple with the value before switchMap and the value after switchMap.
 * @return {Observable<[T, R]>} An observable that emits the value given to switchMap and the value emitted by switchMap.
 */
export function switchMapKeep<T, R>(project: (value: T) => Observable<R>): OperatorFunction<T, [T, R]> {
  return (source$) => source$.pipe(
    switchMap(firstValue => {
      return project(firstValue).pipe(
        map(secondValue => {
          return [firstValue, secondValue] as [T, R];
        })
      );
    })
  );
}
