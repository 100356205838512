import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { PreventDefaultModule } from '@portal-core/general/directives/prevent-default/prevent-default.module';
import { LicenseUserAvatarModule } from '@portal-core/license-users/components/license-user-avatar/license-user-avatar.module';
import { UserNotificationListModule } from '@portal-core/notifications/components/user-notification-list/user-notification-list.module';
import { PermissionsFormModule } from '@portal-core/permissions/components/permissions-form/permissions-form.module';
import { ProfileTabModule } from '@portal-core/profiles/directives/profile-tab/profile-tab.module';
import { TaskProfileDialogModule } from '@portal-core/tasks/components/task-profile-dialog/task-profile-dialog.module';
import { SwitchBoardModule } from '@portal-core/ui/switch-board/switch-board.module';
import { UserAccessFormModule } from '@portal-core/users/components/user-access-form/user-access-form.module';
import { UserDeactivationFormModule } from '@portal-core/users/components/user-deactivation-form/user-deactivation-form.module';
import { UserDeletionFormModule } from '@portal-core/users/components/user-deletion-form/user-deletion-form.module';
import { UserEmailFormModule } from '@portal-core/users/components/user-email-form/user-email-form.module';
import { UserNotificationsEditorModule } from '@portal-core/users/components/user-notifications-editor/user-notifications-editor.module';
import { UserPasswordFormModule } from '@portal-core/users/components/user-password-form/user-password-form.module';
import { UserPasswordResetFormModule } from '@portal-core/users/components/user-password-reset-form/user-password-reset-form.module';
import { UserSettingsFormModule } from '@portal-core/users/components/user-settings-form/user-settings-form.module';
import { UserProfileComponent } from './user-profile.component';

@NgModule({
  declarations: [
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    PermissionsFormModule,
    PreventDefaultModule,
    ProfileTabModule,
    SwitchBoardModule,
    TaskProfileDialogModule,
    LicenseUserAvatarModule,
    UserDeactivationFormModule,
    UserDeletionFormModule,
    UserEmailFormModule,
    UserNotificationListModule,
    UserNotificationsEditorModule,
    UserPasswordFormModule,
    UserPasswordResetFormModule,
    UserSettingsFormModule,
    UserAccessFormModule
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UserProfileModule { }
