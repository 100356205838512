<div *ngIf="showHeader" class="mc-build-progress-bar-header">
  <b>Build Progress</b>

  <span class="mc-fill-remaining-flex-space"></span>

  <span [ngClass]="textColorClassForBuildState">
    <ng-container [ngSwitch]="build.LastNotification?.ProcessState">
      <ng-container *ngSwitchCase="ProcessState.Complete">
        Completed
      </ng-container>
      <ng-container *ngSwitchCase="ProcessState.Cancelled">
        Canceled
      </ng-container>
      <ng-container *ngSwitchCase="ProcessState.Failed">
        Failed
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ build.LastNotification?.StateDisplayName }}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="build.IsStopped">
      after {{ build.RanFor | mcDuration }}
    </ng-container>
  </span>
</div>

<mat-progress-bar mode="determinate" [color]="colorForBuildState" [value]="build.Progress"></mat-progress-bar>

<div *ngIf="showFooter" class="mc-build-progress-bar-footer">
  <span class="mc-text-muted">
    {{ build.ErrorCount | number: '1.0-0' }} {{ build.ErrorCount === 1 ? 'Error' : 'Errors' }},
    {{ build.WarningCount | number: '1.0-0' }} {{ build.WarningCount === 1 ? 'Warning' : 'Warnings' }}
  </span>
  <span class="mc-fill-remaining-flex-space"></span>

  <span class="mc-text-muted">{{ build.Progress | number: '1.0-0' }}%</span>
</div>