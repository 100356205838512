import { filterMarksInRange } from '@common/prosemirror/model/mark';
import { MarkType } from 'prosemirror-model';
import { Command, EditorState } from 'prosemirror-state';

/**
 * Removes all marks in a selection. The entire mark is removed even if some of the mark is outside of the selection.
 * @param markType The type of mark to remove.
 */
export function removeMarks(markType: MarkType): Command {
  return (state: EditorState, dispatch?: ProsemirrorDispatcher) => {
    const { ranges } = state.selection;

    for (let i = 0; i < ranges.length; i++) {
      const { $from, $to } = ranges[i];

      const markInfos = filterMarksInRange($from.pos, $to.pos, state.doc, mark => mark.type === markType);

      if (markInfos?.length > 0) {
        if (dispatch) {
          const tr = state.tr;
          markInfos.forEach(markInfo => tr.removeMark(markInfo.pos, markInfo.pos + markInfo.size, markType));
          dispatch(tr.scrollIntoView());
        }

        return true;
      }
    }

    return false;
  };
}
