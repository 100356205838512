import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ContentSecurityPolicyState } from '@portal-core/sites/services/content-security-policy/content-security-policy-data.service';
import { SiteStylesState } from '@portal-core/sites/services/site-styles/site-styles-data.service';
import { SitesState } from '@portal-core/sites/services/sites-data.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([SitesState, SiteStylesState, ContentSecurityPolicyState])
  ]
})
export class SitesServicesModule { }
