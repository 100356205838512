import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRippleModule as MatRippleModule } from '@angular/material/legacy-core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ErrorsExpansionPanelModule } from '@portal-core/general/components/errors-expansion-panel/errors-expansion-panel.module';
import { LoaderModule } from '@portal-core/general/components/loader/loader.module';
import { StopPropagationModule } from '@portal-core/general/directives/stop-propagation/stop-propagation.module';
import { PermissionsTreeComponent } from './permissions-tree.component';

@NgModule({
  declarations: [
    PermissionsTreeComponent
  ],
  imports: [
    CommonModule,
    ErrorsExpansionPanelModule,
    LoaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    MatTreeModule,
    StopPropagationModule
  ],
  exports: [
    PermissionsTreeComponent
  ]
})
export class PermissionsTreeModule { }
