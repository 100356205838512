import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NodeViewBase } from '@portal-core/text-editor/util/node-view.base';

@Component({
  selector: 'mc-multiple-choice-item-node-view',
  templateUrl: './multiple-choice-item-node-view.component.html',
  styleUrls: ['./multiple-choice-item-node-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceItemNodeViewComponent extends NodeViewBase {
  @Input() checked?: boolean;
  @Input() checkDisabled?: boolean = false;
  @Input() multi?: boolean = false;
  @Output() load: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleChecked: EventEmitter<void> = new EventEmitter<void>();
  @Output() checkFocus: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('content') contentElementRef: ElementRef;
  @ViewChild('custom') customElementRef: ElementRef;

  public get parentClassNameSuffix(): string {
    return 'multiple-choice-item-node-view';
  }

  get showRemovalMark(): boolean {
    return false;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef, protected elementRef: ElementRef) {
    super(changeDetectorRef, elementRef);
  }

  onChange() {
    this.toggleChecked.emit();
  }

  onFocusIn() {
    this.checkFocus.emit();
  }
}
