import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '@env/environment';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { ConfirmDialogComponent } from '@portal-core/general/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '@portal-core/general/components/error-dialog/error-dialog.component';
import { ContactInfo } from '@portal-core/licenses/models/license-contact-info.model';
import { CreditCard } from '@portal-core/licenses/models/license-credit-card.model';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { AutoUnsubscribe } from '@portal-core/util/auto-unsubscribe.decorator';
import { isEqual } from 'lodash';
import { Subscription, filter, switchMap } from 'rxjs';

// Needed to use Accept.js
declare var Accept: any;

@Component({
  selector: 'mc-license-billing-form',
  templateUrl: './license-billing-form.component.html',
  styleUrls: ['./license-billing-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class LicenseBillingFormComponent implements OnChanges {
  @Input() license: License;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  changeBillingForm: UntypedFormGroup;
  createNewCard: boolean;
  ccValidation = {
    visa: '(^4[0-9]{12}(?:[0-9]{3})?$)',
    mastercard: '(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)',
    discover: '(^6(?:011|5[0-9]{2})[0-9]{12}$)',
    amex: '(^3[47][0-9]{13}$)'
  };
  billingAsShippingSubscription: Subscription;
  awaitingResponse: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private licensesService: LicensesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private errorService: ErrorService
  ) { }

  // Wait for contact info to be loaded
  ngOnChanges(changes: SimpleChanges) {
    if (changes.license && this.license) {
      this.createNewCard = !this.license.ContactInfo.creditCard;

      if (!this.changeBillingForm && this.license) {
        // Create the form
        this.changeBillingForm = this.formBuilder.group(this.buildForm());
        this.billingAsShippingSubscription = this.changeBillingForm.controls.useBillingAsShipping.valueChanges.subscribe(checked => {
          checked ? this.changeBillingForm.controls.shipping.disable() : this.changeBillingForm.controls.shipping.enable();
        });
      }
      if (this.changeBillingForm && this.license !== changes.license.previousValue) {
        // Update the form
        this.updateForm();
      }
    }
  }

  onCountrySelected(region: AbstractControl, countrySelected: string) {
    countrySelected === 'US' || countrySelected === 'CA' ? region.enable() : region.disable();
  }

  private createContactFormControlGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(80)]),
      companyName: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
      street: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      postalCode: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      region: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      phone: new UntypedFormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(30)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  private buildForm() {
    return {
      creditCard: this.formBuilder.group({
        cardNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(
          '((^(?:(\\*+[0-9]{4})$)|' + this.ccValidation.visa + '|' + this.ccValidation.mastercard + '|' + this.ccValidation.discover + '|' + this.ccValidation.amex + ')$)')]),
        expirationDate: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-1][0-9]{3}$')]),
        cardCode: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]),
      }),
      billing: this.createContactFormControlGroup(),
      useBillingAsShipping: new UntypedFormControl(false),
      shipping: this.createContactFormControlGroup(),
    };
  }

  private updateForm() {
    const contactInfo = this.license.ContactInfo;
    const billingWithoutId = Object.assign({}, contactInfo.billingContact, { Id: undefined });
    const shippingWithoutId = Object.assign({}, contactInfo.shippingContact, { Id: undefined });
    const useBillingAsShipping = isEqual(billingWithoutId, shippingWithoutId);

    if (contactInfo.creditCard) {
      this.changeBillingForm.controls.creditCard.patchValue({
        cardNumber: this.createNewCard ? '' : '************' + contactInfo.creditCard.last4Digits,
        expirationDate: this.createNewCard ? '' : contactInfo.creditCard.expirationDate,
        cardCode: this.createNewCard ? '' : '***'
      });
    }
    this.changeBillingForm.controls.useBillingAsShipping.patchValue(useBillingAsShipping);
    if (contactInfo.billingContact) {
      this.changeBillingForm.controls.billing.patchValue(contactInfo.billingContact);
      this.onCountrySelected(this.changeBillingForm.get('billing.region'), contactInfo.billingContact.country)
    }
    if (contactInfo.shippingContact) {
      this.changeBillingForm.controls.shipping.patchValue(contactInfo.shippingContact);
      this.onCountrySelected(this.changeBillingForm.get('shipping.region'), contactInfo.shippingContact.country)
    }

    contactInfo.creditCard ? this.changeBillingForm.controls.creditCard.disable() : this.changeBillingForm.controls.creditCard.enable();
    this.changeBillingForm.updateValueAndValidity();
  }

  saveBillingSettings(value) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        title: 'Save Credit Card',
        prompt: 'Are you sure you want to save this credit card?'
      }
    }).afterClosed().pipe(filter(confirmed => confirmed)).subscribe(
      () => {
        // Can't modify cc data
        if (this.createNewCard) {
          const secureData = {
            cardData: {
              cardNumber: value.creditCard.cardNumber,
              month: value.creditCard.expirationDate.substring(0, 2),
              year: value.creditCard.expirationDate.substring(2),
              cardCode: value.creditCard.cardCode
            },
            authData: {
              clientKey: environment.authorizeClientKey,
              apiLoginID: environment.authorizeApiLoginId
            }
          };
          Accept.dispatchData(secureData, this.responseHandler.bind(this));
        } else {
          this.updateContactInfo();
        }
      }
    );
  }

  private updateContactInfo(nonce?: string) {
    const formData = this.changeBillingForm.controls; // changeBillingForm.value doesn't list disabled inputs

    const newContact: ContactInfo = {
      billingContact: formData.billing.value,
      shippingContact: formData.useBillingAsShipping.value ? formData.billing.value : formData.shipping.value,
      creditCard: this.buildCreditCard(formData.creditCard.value.cardNumber, formData.creditCard.value.expirationDate,
        formData.billing.value.firstName + ' ' + formData.billing.value.lastName, nonce)
    };

    this.awaitingResponse = true;
    this.licensesService.updateContactInfo$(this.license.Id, newContact).subscribe(
      ok => {
        this.awaitingResponse = false;
        this.changeBillingForm.markAsPristine();
        this.showMessage('Credit Card Saved');
      },
      (errorResponse: HttpErrorResponse) => {
        this.awaitingResponse = false;
        this.displayError(
          'Error Saving',
          'There was a problem saving the billing information. Please contact us at sales@madcapsoftware.com or call +1 (858) 320-0387.',
          this.errorService.getErrorMessages(errorResponse)
        );
      }
    );
  }

  private buildCreditCard(cardNumber: string, expirationDate: string, cardholder: string, nonce?: string): CreditCard {
    const issuer = cardNumber ? ((new RegExp(this.ccValidation.visa)).test(cardNumber) ? 'Visa' : (
      (new RegExp(this.ccValidation.mastercard)).test(cardNumber) ? 'MasterCard' : (
        (new RegExp(this.ccValidation.discover)).test(cardNumber) ? 'Discover' : (
          (new RegExp(this.ccValidation.amex)).test(cardNumber) ? 'American Express' : 'Unknown')))) : 'Unknown';

    return {
      cardIssuer: issuer,
      last4Digits: cardNumber ? cardNumber.slice(-4) : '',
      cardholder: cardholder,
      expirationDate: expirationDate,
      nonce: nonce
    };
  }

  responseHandler(response) {
    if (response.messages.resultCode === 'Error') {
      this.awaitingResponse = false;
      this.displayError('Error Saving',
        'There was a problem validating the credit card. Please contact us at sales@madcapsoftware.com or call +1 (858) 320-0387.',
        response.messages.message.map(messages => messages.text)
      );
    } else {
      this.updateContactInfo(response.opaqueData.dataValue);
    }
  }

  deleteBillingSettings(formGroupDirective: FormGroupDirective) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '36rem',
      data: {
        title: 'Delete Card on File?',
        prompt: 'Are you sure you want to delete the card on file? If a card is not on file, the next monthly payment could fail, causing Central features to be inaccessible.',
        action: 'Delete'
      }
    }).afterClosed().pipe(
      filter(confirmed => confirmed),
      switchMap(() => {
        this.awaitingResponse = true;
        return this.licensesService.deleteContactInfo$(this.license.Id);
      })
    ).subscribe(
      ok => {
        this.awaitingResponse = false;
        this.showMessage('Credit Card Deleted');
        formGroupDirective.resetForm();
      },
      (errorResponse: HttpErrorResponse) => {
        this.awaitingResponse = false;
        this.displayError(
          'Error Deleting',
          'There was a problem deleting the billing information. Please contact us at sales@madcapsoftware.com or call +1 (858) 320-0387.',
          this.errorService.getErrorMessages(errorResponse)
        );
      }
    );
  }

  private showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  private displayError(title: string, message: string, errors: string[]) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.DialogConfig,
      data: {
        title: title,
        message: message,
        errors: errors
      }
    });
  }
}
