<div class="mc-spring-board">
  <div class="mc-spring-board-body" *ngIf="licenseStorage$ | async as licenseStorage">
    <div class="mc-license-overview-charts">
      <mc-doughnut-chart [options]="chartOptions" [data]="sourceFilesChartData$ | async" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
        <ng-template mcDoughnutChartHole let-hole>
          <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize : 0 }}</span>
          <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
        </ng-template>
      </mc-doughnut-chart>
      <mc-doughnut-chart [options]="chartOptions" [data]="buildsChartData$ | async" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
        <ng-template mcDoughnutChartHole let-hole>
          <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize : 0 }}</span>
          <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
        </ng-template>
      </mc-doughnut-chart>
      <mc-doughnut-chart [options]="chartOptions" [data]="tasksChartData$ | async" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
        <ng-template mcDoughnutChartHole let-hole>
          <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize : 0 }}</span>
          <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
        </ng-template>
      </mc-doughnut-chart>
      <mc-doughnut-chart [options]="chartOptions" [data]="miscChartData$ | async" [holeIndex]="0" [holeType]="DoughnutChartHoleType.Value">
        <ng-template mcDoughnutChartHole let-hole>
          <span class="mc-doughnut-chart-hole-value">{{ hole.value | mcFileSize : 0 }}</span>
          <span class="mc-doughnut-chart-hole-label">{{ hole.label }}</span>
        </ng-template>
      </mc-doughnut-chart>
    </div>

    <mc-storage-usage-bar [licenseStorage]="licenseStorage$ | async" [trialLicense]="isTrialLicense$ | async"></mc-storage-usage-bar>
    <mc-user-usage-bar [seat]="LicenseUserSeatType.Author" [licenseStorage]="licenseStorage$ | async" [trialLicense]="isTrialLicense$ | async"></mc-user-usage-bar>
    <mc-user-usage-bar [seat]="LicenseUserSeatType.SME" [licenseStorage]="licenseStorage$ | async" [trialLicense]="isTrialLicense$ | async"></mc-user-usage-bar>
    <mc-user-usage-bar [seat]="LicenseUserSeatType.Viewer" [licenseStorage]="licenseStorage$ | async" [trialLicense]="isTrialLicense$ | async"></mc-user-usage-bar>

    <div class="mc-license-overview-header">
      <b>Security</b>
    </div>
    <div class="mc-license-overview-security">
      <span class="mc-license-overview-security-value">{{ license.MaxFailedAccessAttempts || 'N/A' }}</span>

      <span class="mc-license-overview-security-value">{{ license.MaxIdleTimeMinutes || 'N/A' }}</span>

      <span class="mc-license-overview-security-value">{{ license.RequirePasswordChangeAfterDays || 'N/A' }}</span>

      <span class="mc-license-overview-security-value">{{ license.PasswordMinimumLength || 'N/A' }}</span>

      <span class="mc-text-muted">login attempts<br>allowed</span>
      <span class="mc-text-muted">minutes to<br>idle logout</span>
      <span class="mc-text-muted">days between<br>password resets</span>
      <span class="mc-text-muted">minimum password<br>length</span>
    </div>
  </div>
</div>
