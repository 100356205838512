import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CollectionDataServiceBase } from '@portal-core/data/collection/services/collection-data.service.base';
import { CollectionStateSelectors } from '@portal-core/data/collection/services/collection-state-selectors.decorator';
import { CollectionStateBase } from '@portal-core/data/collection/services/collection-state.base';
import { AddItems, RemoveItems, Reset } from '@portal-core/data/collection/types/collection-state.type';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { Task } from '@portal-core/tasks/models/task.model';
import { Observable, map } from 'rxjs';

class SetInitialization {
  static readonly type: string = '[TasksData] ResetInitialization';
  constructor(public taskBoardId: number, public status: TaskStatus, public isInit: boolean) { }
}

class TaskBoardStatusRef {
  Archive: number[] = [];
  Backlog: number[] = [];
  Active: number[] = [];
  ToDo: number[] = [];
  InProgress: number[] = [];
  Completed: number[] = [];
  IsActiveInitialized: boolean;
  IsArchiveInitialized: boolean;
  IsBacklogInitialized: boolean;

  updateTask(task: Task) {
    this.updateByCollection(task.Id, this.Archive, task.Status === TaskStatus.Archive);
    this.updateByCollection(task.Id, this.Backlog, task.Status === TaskStatus.Backlog);
    this.updateByCollection(task.Id, this.Active, task.Status === TaskStatus.Active);
    this.updateByCollection(task.Id, this.ToDo, task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.ToDo);
    this.updateByCollection(task.Id, this.InProgress, task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.InProgress);
    this.updateByCollection(task.Id, this.Completed, task.Status === TaskStatus.Active && task.Milestone === TaskMilestone.Completed);
  }

  updateByCollection(taskId: number, coll: number[], shouldBeInCollection: boolean) {
    if (!coll) {
      coll = [];
    }
    if (shouldBeInCollection === false) {
      const index = coll.indexOf(taskId);
      if (index > -1) {
        coll.splice(index, 1);
      }
    } else if (coll.indexOf(taskId) === -1) {
      coll.push(taskId);
    }
  }

  removeTaskId(taskId: number, coll: number[]): boolean {
    const index = coll.indexOf(taskId);
    if (index > -1) {
      coll.splice(index, 1);
      return true;
    }
    return false;
  }

  resetState() {
    this.IsActiveInitialized = false;
    this.IsArchiveInitialized = false;
    this.IsBacklogInitialized = false;
    this.Archive = [];
    this.Backlog = [];
    this.Active = [];
    this.ToDo = [];
    this.InProgress = [];
    this.Completed = [];
  }
}

@CollectionStateSelectors({
  stateType: TasksState
})
@State<TasksState>({
  name: TasksState.source
})
@Injectable()
export class TasksState extends CollectionStateBase {
  static source: string = 'Tasks';
  TaskBoards: Map<number, TaskBoardStatusRef>;

  @Action(SetInitialization)
  setInitialization(ctx: StateContext<TasksState>, action: SetInitialization) {
    let taskBoardRefs: TaskBoardStatusRef;
    const state = <TasksState>ctx.getState();
    if (state.TaskBoards === undefined || state.TaskBoards.has === undefined) {
      state.TaskBoards = new Map<number, TaskBoardStatusRef>();
    }
    if (!state.TaskBoards.has(action.taskBoardId)) {
      taskBoardRefs = new TaskBoardStatusRef();
      state.TaskBoards.set(action.taskBoardId, taskBoardRefs);
    } else {
      taskBoardRefs = state.TaskBoards.get(action.taskBoardId);
    }

    switch (action.status) {
      case TaskStatus.Active:
        taskBoardRefs.IsActiveInitialized = action.isInit;
        break;
      case TaskStatus.Archive:
        taskBoardRefs.IsArchiveInitialized = action.isInit;
        break;
      case TaskStatus.Backlog:
        taskBoardRefs.IsBacklogInitialized = action.isInit;
        break;
    }

    ctx.patchState({
      TaskBoards: state.TaskBoards
    });
  }

  getSource(): string {
    return TasksState.source;
  }

  getAddItemsState(ctx: StateContext<CollectionStateBase>, action: AddItems): any {
    const tasks = action.payload.items;
    const state = <TasksState>ctx.getState();
    if (state.TaskBoards === undefined || state.TaskBoards.has === undefined) {
      state.TaskBoards = new Map<number, TaskBoardStatusRef>();
    }

    for (const prop in tasks) {
      if (tasks[prop]
        && typeof tasks[prop] !== 'function') {
        const task = <Task>tasks[prop];
        let taskBoardRefs: TaskBoardStatusRef;
        if (!state.TaskBoards.has(task.TaskBoardId)) {
          taskBoardRefs = new TaskBoardStatusRef();
          state.TaskBoards.set(task.TaskBoardId, taskBoardRefs);
        } else {
          taskBoardRefs = state.TaskBoards.get(task.TaskBoardId);
        }
        taskBoardRefs.updateTask(task);
      }
    }

    return state;
  }

  getRemoveItemsState(ctx: StateContext<CollectionStateBase>, action: RemoveItems): any {
    const taskIds = { ...action.payload.itemIds };
    const state = <TasksState>ctx.getState();
    if (state.TaskBoards === undefined || state.TaskBoards.has === undefined) {
      state.TaskBoards = new Map<number, TaskBoardStatusRef>();
    }

    for (const taskBoardRefs of state.TaskBoards.values()) {
      for (const tProp in taskIds) {
        if (taskIds[tProp]
          && typeof taskIds[tProp] !== 'function') {
          const taskId = +taskIds[tProp];
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.Archive);
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.Backlog);
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.Active);
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.ToDo);
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.InProgress);
          taskBoardRefs.removeTaskId(taskId, taskBoardRefs.Completed);
        }
      }
    }

    return state;
  }

  getResetState(ctx: StateContext<CollectionStateBase>, action: Reset): any {
    const state = <TasksState>ctx.getState();
    if (state.TaskBoards === undefined || state.TaskBoards.has === undefined) {
      state.TaskBoards = new Map<number, TaskBoardStatusRef>();
    }
    if (state && state.TaskBoards) {
      for (const taskBoardRefs of state.TaskBoards.values()) {
        taskBoardRefs.resetState();
      }
    }
    return state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TasksDataService extends CollectionDataServiceBase<Task> {
  constructor(protected store: Store) {
    super(store, TasksState);
  }

  @Selector([TasksState])
  static getByStatusFn$(state: TasksState) {
    return (taskBoardId: number, status: TaskStatus) => {
      if (state === undefined || state.TaskBoards === undefined || state.TaskBoards.has === undefined || !state.TaskBoards.has(taskBoardId)) {
        return undefined;
      }
      const taskBoardRefs = state.TaskBoards.get(taskBoardId);
      if ((status === TaskStatus.Active && !taskBoardRefs.IsActiveInitialized)
        || (status === TaskStatus.Archive && !taskBoardRefs.IsArchiveInitialized)
        || (status === TaskStatus.Backlog && !taskBoardRefs.IsBacklogInitialized)) {
        return undefined;
      }

      const tasks = [];
      for (const prop in taskBoardRefs[TaskStatus[status]]) {
        if (taskBoardRefs[TaskStatus[status]][prop]
          && typeof (taskBoardRefs[TaskStatus[status]][prop]) !== 'function') {
          const task = state.items[(taskBoardRefs[TaskStatus[status]][prop])];
          if (task !== undefined && !task.IsDeleted) {
            tasks.push(task);
          }
        }
      }
      return tasks;
    };
  }

  @Selector([TasksState])
  static getByMilestoneFn$(state: TasksState) {
    return (taskBoardId: number, milestone: TaskMilestone) => {
      if (state === undefined || state.TaskBoards === undefined || state.TaskBoards.has === undefined || !state.TaskBoards.has(taskBoardId)) {
        return undefined;
      }
      const taskBoardRefs = state.TaskBoards.get(taskBoardId);
      if (!taskBoardRefs.IsActiveInitialized) {
        return undefined;
      }

      const tasks = [];
      for (const prop in taskBoardRefs[TaskMilestone[milestone]]) {
        if (taskBoardRefs[TaskMilestone[milestone]][prop]
          && typeof (taskBoardRefs[TaskMilestone[milestone]][prop]) !== 'function') {
          const task = state.items[(taskBoardRefs[TaskMilestone[milestone]][prop])];
          if (task !== undefined && !task.IsDeleted) {
            tasks.push(task);
          }
        }
      }
      return tasks;
    };
  }

  @Selector([TasksState])
  static getAllFn$(state: TasksState) {
    return (taskBoardId: number) => {
      if (state === undefined || state.TaskBoards === undefined || state.TaskBoards.has === undefined || !state.TaskBoards.has(taskBoardId)) {
        return undefined;
      }
      const taskBoardRefs = state.TaskBoards.get(taskBoardId);
      if (!taskBoardRefs.IsActiveInitialized
        || !taskBoardRefs.IsArchiveInitialized
        || !taskBoardRefs.IsBacklogInitialized) {
        return undefined;
      }

      const taskIds = [
        ...taskBoardRefs.Active,
        ...taskBoardRefs.Backlog,
        ...taskBoardRefs.Archive
      ];
      const tasks = [];
      for (const prop in state.items) {
        if (state.items[prop]
          && typeof (state.items[prop]) !== 'function') {
          const task = state.items[prop];
          if (task !== undefined
            && taskIds.some(id => task.Id === id)) {
            tasks.push(task);
          }
        }
      }
      return tasks;
    };
  }
  getItemsByStatus$(taskBoardId: number, status: TaskStatus): Observable<Task[]> {
    return this.store.select(TasksDataService.getByStatusFn$).pipe(map(getByStatusFn => getByStatusFn(taskBoardId, status)));
  }

  getItemsByMilestone$(taskBoardId: number, milestone: TaskMilestone): Observable<Task[]> {
    return this.store.select(TasksDataService.getByMilestoneFn$).pipe(map(getByMilestoneFn => getByMilestoneFn(taskBoardId, milestone)));
  }

  getTasks$(taskBoardId: number): Observable<Task[]> {
    return this.store.select(TasksDataService.getAllFn$).pipe(map(getAllFn => getAllFn(taskBoardId)));
  }

  setInitialized$(taskBoardId: number, status: TaskStatus, isInit: boolean): Observable<any> {
    return this.store.dispatch(new SetInitialization(taskBoardId, status, isInit));
  }
}
