<ng-container *ngIf="formControl$ | async as formControl">
  <ng-container *ngFor="let validation of validations$ | async">
    <mc-error-code *ngIf="formControl.invalid && formControl.errors[validation.Type] === true" class="error-message" [errorCode]="validation.ErrorCode"></mc-error-code>
  </ng-container>
  <ng-container *ngIf="formControl.invalid">
    <ng-container *ngFor="let error of customErrorMessages$ | async">
      <span>{{error}}</span>
    </ng-container>
  </ng-container>
</ng-container>
