export enum AlertType {
  AuthInitError = 'AuthInitError',
  BillingFailed = 'BillingFailed',
  DeactivatedLicense = 'DeactivatedLicense',
  ExpiredLicense = 'ExpiredLicense',
  ExpiredTrial = 'ExpiredTrial',
  LicenseAccessDenied = 'LicenseAccessDenied',
  LicenseNotFound = 'LicenseNotFound',
  NeedsRole = 'NeedsRole',
  NeedsAppRole = 'NeedsAppRole',
  NotAuthenticated = 'NotAuthenticated',
  PageNotFound = 'PageNotFound',
  ProjectAccessDenied = 'ProjectAccessDenied',
  ProjectArchived = 'ProjectArchived',
  ProjectDeleted = 'ProjectDeleted',
  ProjectInitError = 'ProjectInitError',
  ProjectNotFound = 'ProjectNotFound',
  InstanceNotFound = 'InstanceNotFound',
  SiteUrlNotFound = 'SiteUrlNotFound',
  UnknownError = 'UnknownError',
  UserHasNoLicenses = 'UserHasNoLicenses',
  UserNotActive = 'UserNotActive',
  UsersExceeded = 'UsersExceeded',
  UserSiteAccessDenied = 'UserSiteAccessDenied',
  UserLicenseAccessDenied = 'UserLicenseAccessDenied',
  // Client Only
  AuthLoginError = 'AuthLoginError',
  TaskBoardDeleted = 'TaskBoardDeleted',
  TaskBoardNotFound = 'TaskBoardNotFound',
  UserAccessDenied = 'UserAccessDenied',
  ReviewPackageNotFound = 'ReviewPackageNotFound'
}
