import { Component, DoCheck, EventEmitter, Input, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { Project } from '@portal-core/projects/models/project.model';
import { ProjectsService } from '@portal-core/projects/services/projects.service';
import { TaskBoard } from '@portal-core/task-boards/models/task-board.model';
import { TaskStatus } from '@portal-core/tasks/enums/task-status.enum';
import { TaskMilestone } from '@portal-core/tasks/enums/tasks-milestone.enum';
import { TaskPriority } from '@portal-core/tasks/enums/tasks-priority.enum';
import { Task } from '@portal-core/tasks/models/task.model';
import { TasksService } from '@portal-core/tasks/services/tasks.service';
import { RichTextEditorComponent } from '@portal-core/text-editor/components/rich-text-editor/rich-text-editor.component';
import { TextEditorService } from '@portal-core/text-editor/services/text-editor.service';
import dayjs from 'dayjs';
import { Cancelable, debounce } from 'lodash';
import { Observable, first } from 'rxjs';

@Component({
  selector: 'mc-task-profile-dialog-details',
  templateUrl: './task-profile-dialog-details.component.html',
  styleUrls: ['./task-profile-dialog-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskProfileDialogDetailsComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
  public TaskPriority: typeof TaskPriority = TaskPriority;
  public TaskMilestone: typeof TaskMilestone = TaskMilestone;
  public TaskStatus: typeof TaskStatus = TaskStatus;

  @Input() task: Task;
  @Input() project: Project;
  @Input() projects: Project[];
  @Input() licenseUsers: LicenseUser[];
  @Input() canEditAllTasks: boolean;
  @Input() taskBoard: TaskBoard;
  @Input() taskBoards: TaskBoard[];

  @Output() descriptionChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(RichTextEditorComponent, { static: false }) richTextEditor: RichTextEditorComponent;

  detailsFormGroup: UntypedFormGroup;
  createdByUser$: Observable<LicenseUser>;
  assignedUser$: Observable<LicenseUser>;
  project$: Observable<Project>;
  maxPosition: number = Number.MAX_VALUE;
  description: string;

  private startDateControl: AbstractControl;
  private startTimeControl: AbstractControl;
  private dueDateControl: AbstractControl;
  private dueTimeControl: AbstractControl;
  private positionControl: AbstractControl;
  private taskDiffer: KeyValueDiffer<string, any>;
  private taskPositionBuffer: number = 0;
  private updateDescriptionIntervalInMS: number = 500;
  private updateDescription: Function & Cancelable;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private licenseUsersService: LicenseUsersService,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private differs: KeyValueDiffers,
    private textEditorService: TextEditorService) { }

  ngOnInit() {
    if (this.task.Id === 0) {
      this.taskPositionBuffer = 1;
    }
    if (this.taskBoard?.Id && this.task.Status === TaskStatus.Active) {
      this.tasksService.getTasksByMilestone$(this.task.LicenseId, this.task.Milestone, this.taskBoard.Id)
        .pipe(first()).subscribe(tasks => {
          this.maxPosition = tasks.filter(t => t.TaskBoardId === this.taskBoard.Id).length + this.taskPositionBuffer;
          if (this.task.Id === 0) {
            this.task.Position = this.maxPosition - 1;
          }
        });
    }

    if (this.task.Id === 0) {
      this.task.EstHour = 0;
    }

    this.detailsFormGroup = this.formBuilder.group({
      estHour: [{ value: this.task.EstHour, disabled: !this.canEditAllTasks }, [this.onEstHourCustomValidation()]],
      position: [{ value: Math.max(this.task.Position + 1, 1), disabled: !this.canEditAllTasks }, [this.onPositionCustomValidation()]],
      start: [{ value: this.task.StartDate, disabled: !this.canEditAllTasks }, this.onDateCustomValidation()],
      startTime: [{ value: this.formatDate(this.task.StartDate), disabled: !this.canEditAllTasks || this.task.AllDay }],
      due: [{ value: this.task.DueDate, disabled: !this.canEditAllTasks }, this.onDateCustomValidation()],
      dueTime: [{ value: this.formatDate(this.task.DueDate), disabled: !this.canEditAllTasks || this.task.AllDay }]
    });

    this.positionControl = this.detailsFormGroup.get('position');
    this.startDateControl = this.detailsFormGroup.get('start');
    this.startTimeControl = this.detailsFormGroup.get('startTime');
    this.dueDateControl = this.detailsFormGroup.get('due');
    this.dueTimeControl = this.detailsFormGroup.get('dueTime');

    this.startDateControl.markAsTouched({ onlySelf: true });
    this.dueDateControl.markAsTouched({ onlySelf: true });
    this.detailsFormGroup.get('estHour').markAsTouched({ onlySelf: true });
    this.detailsFormGroup.get('position').markAsTouched({ onlySelf: true });

    this.createdByUser$ = this.getLicenseUser$(this.task.CreatedByUserId);
    this.assignedUser$ = this.getLicenseUser$(this.task.AssignedUserId);
    this.project$ = this.projectsService.getItemById$(this.task.ProjectId);
    this.taskDiffer = this.differs.find(this.task).create();

    this.updateDescription = debounce(newDescription => this.descriptionChanged.emit(newDescription), this.updateDescriptionIntervalInMS);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.detailsFormGroup && changes['canEditAllTasks'] && changes['canEditAllTasks'].currentValue) {
      Object.keys(this.detailsFormGroup.controls).forEach(key => {
        this.detailsFormGroup.get(key).enable();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.updateDescription) {
      this.updateDescription.flush();
      this.updateDescription = null;
    }
  }

  onEditorStateChange() {
    if (this.richTextEditor) {
      this.updateDescription(this.richTextEditor.getContent());
    }
  }

  private taskChanged(changes: KeyValueChanges<string, any>) {
    changes.forEachChangedItem(rec => {
      if (rec.key === 'Milestone') {
        this.tasksService.getTasksByMilestone$(this.task.LicenseId, this.task.Milestone, this.taskBoard.Id)
          .pipe(first()).subscribe(tasks => {
            this.maxPosition = tasks.length;

            if (!tasks.some(t => t.Id === this.task.Id)) {
              this.maxPosition++;
            }
            if (this.positionControl.value > this.maxPosition) {
              this.task.Position = this.maxPosition - 1;
              this.positionControl.setValue(this.maxPosition);
            }
            this.positionControl.updateValueAndValidity();
          });
      } else if (rec.key === 'Status' && this.task.Status !== TaskStatus.Active) {
        this.tasksService.getTasksByStatus$(this.task.LicenseId, this.task.Status, this.taskBoard.Id)
          .pipe(first()).subscribe(() => {
            // position does not matter outside of Active tasks
            this.maxPosition = Number.MAX_VALUE;
            this.positionControl.updateValueAndValidity();
          });
      }
    });
  }

  ngDoCheck(): void {
    const changes = this.taskDiffer.diff(this.task);
    if (changes) {
      this.taskChanged(changes);
    }
  }

  onStartDateValueChanged(date: Date) {
    this.dueDateControl.updateValueAndValidity();
    if (!date) {
      this.task.StartDate = null;
      this.startTimeControl.setValue(null);
      return;
    }
    if (!this.task.StartDate) {
      this.task.StartDate = date.toISOString();
      this.startTimeControl.setValue(this.formatDate(this.task.StartDate));
      return;
    }

    const newDate = new Date(this.task.StartDate);

    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    newDate.setFullYear(date.getFullYear());

    this.task.StartDate = newDate.toISOString();
    this.startTimeControl.setValue(this.formatDate(this.task.StartDate));

    if (this.task.AllDay
      && new Date(this.task.DueDate) < newDate) {
      this.task.DueDate = this.task.StartDate;
      this.dueDateControl.setValue(this.task.DueDate);
      this.startDateControl.updateValueAndValidity();
    }
  }

  onStartTimeValueChanged(time: string) {
    this.dueDateControl.updateValueAndValidity();
    if (time === '') {
      this.task.StartDate = null;
      this.startDateControl.setValue(null);
      return;
    }

    const date = (!this.task.StartDate
      ? new Date()
      : new Date(this.task.StartDate));

    const split = time.split(':');
    date.setHours(+split[0]);
    date.setMinutes(+split[1]);

    this.task.StartDate = date.toISOString();
  }

  onDueDateValueChanged(date: Date) {
    this.startDateControl.updateValueAndValidity();
    if (!date) {
      this.task.DueDate = null;
      this.dueTimeControl.setValue(null);
      return;
    }
    if (!this.task.DueDate) {
      this.task.DueDate = date.toISOString();
      this.dueTimeControl.setValue(this.formatDate(this.task.DueDate));
      return;
    }

    const newDate = new Date(this.task.DueDate);
    this.dueTimeControl.setValue(this.formatDate(this.task.DueDate));

    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    newDate.setFullYear(date.getFullYear());

    this.task.DueDate = newDate.toISOString();
  }

  onDueTimeValueChanged(time: string) {
    this.startDateControl.updateValueAndValidity();
    this.startTimeControl.updateValueAndValidity();
    if (time === '') {
      this.task.DueDate = null;
      this.dueDateControl.setValue(null);
      return;
    }

    const date = (!this.task.DueDate
      ? new Date()
      : new Date(this.task.DueDate));

    const split = time.split(':');
    date.setHours(+split[0]);
    date.setMinutes(+split[1]);

    this.task.DueDate = date.toISOString();
  }

  onAllDayChecked(isChecked: boolean) {
    this.task.AllDay = isChecked;

    if (isChecked) {
      let date = new Date();
      if (this.task.StartDate && !this.task.DueDate) {
        const start = new Date(this.task.StartDate);
        if (start > date) {
          date = start;
        }
      } else if (this.task.DueDate && !this.task.StartDate) {
        const due = new Date(this.task.DueDate);
        if (due < date) {
          date = due;
        }
      }
      if (this.task.StartDate) {
        this.task.StartDate = dayjs(this.task.StartDate).startOf('day').toISOString();
      } else {
        this.task.StartDate = dayjs(date).startOf('day').toISOString();
      }
      if (this.task.DueDate) {
        this.task.DueDate = dayjs(this.task.DueDate).endOf('day').second(0).millisecond(0).toISOString();
      } else {
        const start = new Date(this.task.StartDate);
        const due = start > date
          ? start
          : date;
        this.task.DueDate = dayjs(due).endOf('day').second(0).millisecond(0).toISOString();
      }

      this.startTimeControl.disable();
      this.dueTimeControl.disable();

      this.startDateControl.setValue(this.task.StartDate);
      this.dueDateControl.setValue(this.task.DueDate);
      this.startTimeControl.setValue(this.formatDate(this.task.StartDate));
      this.dueTimeControl.setValue(this.formatDate(this.task.DueDate));
    } else {
      this.startTimeControl.enable();
      this.dueTimeControl.enable();
    }
  }

  onLicenseUserClicked(isSettingAssignedUser: boolean, licenseUser: LicenseUser = null) {
    if (!licenseUser) {
      if (isSettingAssignedUser) {
        this.task.AssignedUserId = null;
        this.assignedUser$ = this.getLicenseUser$(this.task.AssignedUserId);
      }
    } else {
      if (isSettingAssignedUser) {
        this.task.AssignedUserId = licenseUser.User.Id;
        this.assignedUser$ = this.getLicenseUser$(this.task.AssignedUserId);
      } else {
        this.task.CreatedByUserId = licenseUser.User.Id;
        this.createdByUser$ = this.getLicenseUser$(this.task.CreatedByUserId);
      }
    }
  }

  onProjectClicked(project: Project = null) {
    if (!project) {
      this.task.ProjectId = null;
      this.project = null;
    } else {
      this.task.ProjectId = project.Id;
      this.project = project;
    }
    this.project$ = this.projectsService.getItemById$(this.task.ProjectId);
  }

  onStatusChanged(change: MatSelectChange) {
    if (change.value <= 2) {
      const milestone = <TaskMilestone>change.value;
      this.task.Status = TaskStatus.Active;
      this.task.Milestone = milestone;
    } else {
      const status = <TaskStatus>change.value;
      this.task.Status = status;
    }
  }

  private onEstHourCustomValidation() {
    return function matchEstHourValidation(control: UntypedFormControl) {
      if (!control || !control.parent) {
        return null;
      }

      if (control.value === undefined
        || control.value === null) {
        return {
          undefined: true
        };
      }

      if (control.value < 0) {
        return {
          invalidNegative: true
        };
      }

      if (control.value > 999) {
        return {
          invalidMax: true
        };
      }

      return null;
    };
  }

  private onPositionCustomValidation() {
    return function matchPositionValidation(control: UntypedFormControl) {
      if (!control || !control.parent) {
        return null;
      }

      if (control.value === undefined
        || control.value === null) {
        return {
          undefined: true
        };
      }

      if (control.value <= 0) {
        return {
          invalidNegative: true
        };
      }

      if (control.value > this.maxPosition) {
        return {
          invalidMax: true
        };
      }

      return null;
    }.bind(this);
  }

  private onDateCustomValidation() {
    return function matchDueDateValidation(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      const otherControl = (control === control.parent.get('start')
        ? control.parent.get('due')
        : control.parent.get('start'));
      if (!control || !otherControl) {
        return null;
      }

      if (control.value !== otherControl.value && !control.value) {
        return {
          mismatch: true
        };
      }

      const start = (control === control.parent.get('start')
        ? control
        : control.parent.get('start'));
      const due = (control === control.parent.get('start')
        ? control.parent.get('due')
        : control);

      if (start.value
        && due.value
        && new Date(start.value) > new Date(due.value)) {
        return {
          overlap: true
        };
      }

      return null;
    };
  }

  private getLicenseUser$(userId: string): Observable<LicenseUser> {
    return this.licenseUsersService.getLicenseUserByUserId$(userId, this.task.LicenseId);
  }

  private formatDate(dateStr?: string) {
    if (!dateStr) {
      return '';
    }

    const date = new Date(dateStr);
    return `${this.formatNumber(date.getHours())}:${this.formatNumber(date.getMinutes())}`;
  }
  private formatNumber(number: number) {
    return (number < 10 ? '0' : '') + number;
  }
}
