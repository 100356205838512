import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogBase } from '@portal-core/ui/dialog/util/dialog.base';

export interface RichTextLinkPropertiesDialogData {
  text: string;
  link: string;
}

export interface RichTextLinkPropertiesDialogResult {
  text: string;
  link: string;
}

interface RichTextLinkPropertiesDialogForm {
  text: FormControl<string>,
  link: FormControl<string>
}

@Component({
  selector: 'mc-rich-text-link-properties-dialog',
  templateUrl: './rich-text-link-properties-dialog.component.html',
  styleUrls: ['./rich-text-link-properties-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextLinkPropertiesDialogComponent extends DialogBase implements OnInit {
  static DialogConfig: MatDialogConfig = {
    width: '40rem',
    maxWidth: '40rem',
    maxHeight: '40rem'
  };

  linkForm: FormGroup<RichTextLinkPropertiesDialogForm>;

  constructor(
    protected dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: RichTextLinkPropertiesDialogData,
    protected dialogRef: MatDialogRef<RichTextLinkPropertiesDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    super(dialog, dialogRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.createLinkForm();
  }

  onSubmit() {
    const linkForm = this.linkForm.controls;
    this.closeDialog({ text: linkForm.text.value, link: linkForm.link.value });
  }

  protected createLinkForm() {
    this.linkForm = this.fb.group({
      text: new FormControl(this.data.text, Validators.required),
      link: new FormControl(this.data.link, Validators.required)
    });
  }
}

