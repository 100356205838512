import { Inject, Injectable, Injector } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { McError } from '@common/errors/mc-error';
import { ErrorCode } from '@common/http/enums/error-code.enum';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { OnAuthRoute } from '@portal-core/auth/interfaces/auth-route.interface';
import { AuthModuleConfig } from '@portal-core/auth/models/auth-module-config.model';
import { AuthDataService } from '@portal-core/auth/services/auth-data.service';
import { AuthModuleConfigService } from '@portal-core/auth/services/auth-module-config.service';
import { AuthRedirect } from '@portal-core/auth/types/auth-redirect.type';
import { AuthRouteData } from '@portal-core/auth/types/auth-route-data.type';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { WINDOW } from '@portal-core/util/window.provider';
import { Observable, from, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthRouteService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(AuthModuleConfigService) private config: AuthModuleConfig,
    private injector: Injector,
    private router: Router,
    private authDataService: AuthDataService
  ) { }

  getAuthRedirect$(authRoute: AuthRoute, extras?: NavigationExtras, data?: AuthRouteData): Observable<AuthRedirect> {
    if (this.config.onAuthRoute) {
      const handler: OnAuthRoute = this.injector.get<OnAuthRoute>(this.config.onAuthRoute);
      if (handler.onAuthRoute$) {
        const authRedirect$ = handler.onAuthRoute$(authRoute, extras, data);

        if (authRedirect$) {
          return authRedirect$;
        }
      }
    }

    switch (authRoute) {
      case AuthRoute.ExpiredPassword:
        return of({ commands: ['/expired-password'], extras });
      case AuthRoute.ForgotPassword:
        return of({ commands: ['/forgot-password'], extras });
      case AuthRoute.Home:
        return of({ commands: ['/'], extras });
      case AuthRoute.LastActivatedRoute:
        const lastActivatedRoute = this.authDataService.getLastActivatedRoute();
        // If there is a route then use it
        if (lastActivatedRoute) {
          return of({ commands: lastActivatedRoute });
        } else { // Else change to redirecting to the home route
          return this.getAuthRedirect$(AuthRoute.Home);
        }
      case AuthRoute.Login:
        return of({ commands: ['/login'], extras });
      case AuthRoute.Logout:
        return of({ commands: ['/logout'], extras });
      case AuthRoute.SubdomainGuardError:
        return of({ commands: ['/licenseNotFound'], extras });
      case AuthRoute.AuthLoginGuardError:
        return of({
          commands: ['/alert'], extras: {
            queryParams: { type: AlertType.AuthLoginError },
            ...extras
          }
        });
    }
  }

  navigateToAuthRoute$(authRoute: AuthRoute, extras?: NavigationExtras, data?: AuthRouteData): Observable<boolean> {
    const authRedirect$ = this.getAuthRedirect$(authRoute, extras, data);

    if (!authRedirect$) {
      return throwError(new McError(`Missing AuthRedirect for ${authRoute}`, ErrorCode.AuthRedirectRequired));
    }

    return authRedirect$.pipe(
      switchMap(redirect => {
        if (typeof redirect === 'string') {
          this.window.location.href = redirect;
          return of(true);
        } else if (redirect) {
          return from(this.router.navigate(redirect.commands, redirect.extras ?? {}));
        } else {
          return throwError(new McError(`Empty redirect for ${authRoute}`, ErrorCode.AuthRedirectRequired));
        }
      })
    );
  }

  navigateToCentralInstance$(instanceCode: string): Observable<boolean> {
    const accessToken = this.authDataService.getAccessTokensByInstanceCode()?.[instanceCode];

    if (accessToken) {
      return this.navigateToAuthRoute$(AuthRoute.Home, undefined, { instanceCode });
    } else {
      return this.navigateToAuthRoute$(AuthRoute.Login, undefined, { instanceCode });
    }
  }
}
