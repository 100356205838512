import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TreeService } from '@portal-core/general/services/tree.service';
import { ProjectFilesTreeItemDirective } from '@portal-core/project-files/directives/project-files-tree-item/project-files-tree-item.directive';
import { TriSelectedState } from '@portal-core/project-files/enums/tri-selected-state.enum';
import { ProjectFilesChecklistService } from '@portal-core/project-files/services/project-files-checklist.service';
import { ProjectFilesTreeDataService } from '@portal-core/project-files/services/project-files-tree-data.service';
import { ProjectFilesTreeType } from '@portal-core/project-files/types/project-files-tree.type';
import { ProjectFileFlatNode } from '@portal-core/project-files/util/project-file-flat-node';
import { ProjectFileNode } from '@portal-core/project-files/util/project-file-node';
import { first } from 'rxjs';

@Component({
  selector: 'mc-project-files-tree-folder',
  templateUrl: './project-files-tree-folder.component.html',
  styleUrls: ['./project-files-tree-folder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilesTreeFolderComponent {
  @Input() node: ProjectFileFlatNode;
  @Input() branchName: string;
  @Input() pathFilters: string[];
  @Input() isFolderExpanded: boolean;
  @Input() showIcons?: boolean = true;
  @Input() projectFilesTreeType: ProjectFilesTreeType = 'default';
  @Input() ignoreInitialFilters: boolean = false;
  @Input() disableSelectionTree: boolean = false;
  @Input() disableFolderCheckboxes: boolean = false;
  @Input() checked: boolean = false;

  @Output() pathFiltersChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() selectedDirectoryPathChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() nodeToggled: EventEmitter<ProjectFileFlatNode> = new EventEmitter<ProjectFileFlatNode>();

  @ContentChild(ProjectFilesTreeItemDirective) treeItemDirective: ProjectFilesTreeItemDirective;

  TriSelectedState: typeof TriSelectedState = TriSelectedState;

  constructor(private projectFilesTreeDataService: ProjectFilesTreeDataService, private cdr: ChangeDetectorRef, private projectFilesChecklistService: ProjectFilesChecklistService, private treeService: TreeService) { }

  onFolderClicked(node: ProjectFileFlatNode) {
    this.selectedDirectoryPathChanged.emit(node.path);
    this.loadFolder(node);
  }

  private loadFolder(node: ProjectFileFlatNode) {
    this.nodeToggled.emit(node);
    if (!node.loaded && !node.isLoading) {
      node.isLoading = true;
      this.projectFilesTreeDataService.getChildren$(node.path, this.branchName).pipe(first()).subscribe((nodes: ProjectFileNode[]) => {
        node.isLoading = false;
        this.cdr.markForCheck();
        if (nodes) {
          this.projectFilesTreeDataService.addChildrenToFolderNode(this.projectFilesTreeDataService.getNode(node.path), nodes, this.pathFilters, this.ignoreInitialFilters);
        }
      });
    }
  }

  onNodeCheckboxChanged(flatNode: ProjectFileFlatNode) {
    // don't test TriSelectedState.Indeterminate, it will toggle to Selected
    if (flatNode.selected === TriSelectedState.Selected) {
      flatNode.selected = TriSelectedState.NotSelected;
    } else {
      flatNode.selected = TriSelectedState.Selected;
    }
    const node: ProjectFileNode = this.projectFilesTreeDataService.getNode(flatNode.path);
    this.projectFilesChecklistService.checkParentNodes(node);
    // Set all children
    if (Array.isArray(node.children)) {
      this.treeService.forEach(node, 'children', child => child.flatFileNode.selected = flatNode.selected);
    }
    this.pathFiltersChanged.emit(this.projectFilesChecklistService.buildPathFilters(this.projectFilesTreeDataService.nodeMap, this.pathFilters));
  }
}
