import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReviewFileUserStatusTextPipe } from './review-file-user-status-text.pipe';

@NgModule({
  declarations: [ReviewFileUserStatusTextPipe],
  imports: [CommonModule],
  exports: [ReviewFileUserStatusTextPipe],
  providers: [ReviewFileUserStatusTextPipe]
})
export class ReviewFileUserStatusTextModule { }
