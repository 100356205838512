import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '@portal-core/layout/services/layout.service';

@Component({
  selector: 'mc-navbar-help-button',
  templateUrl: './navbar-help-button.component.html',
  styleUrls: ['./navbar-help-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarHelpButtonComponent {
  @Input() appVersionAndBuildDate?: string = '';

  constructor(private layoutService: LayoutService) { }

  onAboutClicked() {
    this.layoutService.emitOpenAboutDialog();
  }
}
